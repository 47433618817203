/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-curly-newline */
import React, { useRef, useState, useCallback, useEffect } from 'react';
import { FormHandles, Scope } from '@unform/core';
import { Form } from '@unform/web';
import { RiCloseLine, RiCheckLine } from 'react-icons/ri';

import { BsSearch } from 'react-icons/bs';
import Button from '../../../../../components/Button';
import DatePicker from '../../../../../components/DatePicker';
import Input from '../../../../../components/Input';
import InputSearchWithButton from '../../../../../components/InputSearchWithButton';
import Loading from '../../../../../components/Loading';

import Modal from '../../../../../components/Modal';

import { useToast } from '../../../../../context/ToastContext';
import api from '../../../../../services/api';

import { IDrawingsResponse } from '../../../../../interfaces/drawings';
import { ICommercial } from '../../../../../interfaces/commercial';

import {
  Container,
  Content,
  RevisionDateAndNumberAndQuantityAndActionModal,
  SearchArea,
  ItensArea,
  SplitDrawing,
  SnipeLoading,
  MessageError,
  ActionCloseModal,
} from './styles';
import InputHidden from '../../../../../components/InputHidden';
import convertDateStringToDate from '../../../../../utils/convertDateStringToDate';

interface IDrawingsResponseForm
  extends Omit<IDrawingsResponse, 'id' | 'drawingName' | 'drawingNumber'> {
  idDrawing: string;
  drawingName: { id: number; label: string; value: number | string };
  drawingNumber: { id: number; label: string; value: number | string };
  files: any;
}

interface ICommercialForm extends Omit<ICommercial, 'drawings'> {
  idDrawing: string;
  scopeDrawingsModal: Array<IDrawingsResponseForm>;
}

interface ModalDTO {
  indexProp: number;
  loadModalProp: boolean;
  // toggleLoadModalSale: () => void;
  handleInputChangeDrawingModal: (drawingFromDB: any, index: number) => void;
  closeAndClearModalFindDrawing: (index: number) => void;
}

const ModalFindDrawing: React.FC<ModalDTO> = ({
  indexProp,
  loadModalProp = false,
  handleInputChangeDrawingModal,
  closeAndClearModalFindDrawing,
}) => {
  const formSearchListItemRef = useRef<FormHandles>(null);
  const { addToast } = useToast();

  const [listDrawingsTheProject, setListDrawingsTheProject] = useState<
    ICommercialForm[]
  >([]);
  const [listDrawingsSelect, setListDrawingsSelect] = useState<any[]>([]);
  const [loadingDrawingsSelect, setLoadingDrawingsSelect] = useState<boolean>(
    false,
  );

  const [loading, setLoading] = useState(false);
  const [messageError, setMessageError] = useState('');
  const [loadingFile, setLoadingFile] = useState<boolean>(false);
  const [buttonClicked, setButtonClicked] = useState<string>('');

  const handleOpenFile = useCallback(
    (index: number, nameButtonClicked: string) => {
      setLoadingFile(true);
      setButtonClicked(nameButtonClicked);
      if (
        formSearchListItemRef.current &&
        formSearchListItemRef.current.getFieldValue(
          `scopeDrawingsModal[${index}].fileName`,
        )
      ) {
        const fileName = formSearchListItemRef.current?.getFieldValue(
          `scopeDrawingsModal[${index}].fileName`,
        );

        const extensionFile = fileName.substring(fileName.lastIndexOf('.') + 1);

        let typeBlob = '';

        switch (extensionFile) {
          case 'pdf':
            typeBlob = `application/${extensionFile}`;
            break;
          case 'tiff':
            typeBlob = `image/${extensionFile}`;
            break;
          default:
            typeBlob = `image/${extensionFile}`;
        }
        api
          .get(`drawings/file/${fileName}`, {
            responseType: 'arraybuffer',
          })
          .then((response: any) => {
            const url = window.URL.createObjectURL(
              new Blob([response.data], { type: typeBlob }),
            );

            window.open(url);
            setLoadingFile(false);
            setButtonClicked('');
          })
          .catch(error => {
            setLoadingFile(false);
            setButtonClicked('');

            if (error.response.data) {
              const { data } = error.response; // Error vindo do back está em data dentro de response
              addToast({
                type: 'error',
                title: data.message,
              });
              return;
            }

            addToast({
              type: 'error',
              title: 'Erro ao Exibir!',
              description: `Error: ${error}`,
            });
          });
      }
    },

    [addToast],
  );

  // handleSearchSubmit
  const handleSearchSubmit = useCallback(
    // eslint-disable-next-line consistent-return
    async (dataForm: any) => {
      try {
        setLoading(true);
        setListDrawingsTheProject([]);
        setMessageError('');
        if (dataForm) {
          const response: any = await api.get(
            `drawings/active/search/${dataForm.find}`,
          );

          setListDrawingsTheProject(response.data);
        }
        setLoading(false);
      } catch (erro: any) {
        setLoading(false);

        // eslint-disable-next-line no-shadow
        if (erro.response) {
          const { data } = erro.response; // Error vindo do back está em data dentro de response
          // addToast({
          //   type: 'error',
          //   title: data.message,
          // });
          setMessageError(data.message);
          // eslint-disable-next-line consistent-return
          return;
        }
        addToast({
          type: 'error',
          title: 'Ocorreu um erro interno',
        });
      }
    },
    [addToast],
  );
  // Fim handleSearchSubmit

  useEffect(() => {
    if (formSearchListItemRef.current && listDrawingsTheProject.length > 0) {
      listDrawingsTheProject.map((item: any, index: number) => {
        formSearchListItemRef.current?.setFieldValue(
          `scopeDrawingsModal[${index}].idDrawing`,
          item.id,
        );
        formSearchListItemRef.current?.setFieldValue(
          `scopeDrawingsModal[${index}].fileName`,
          item.fileName,
        );
        formSearchListItemRef.current?.setFieldValue(
          `scopeDrawingsModal[${index}].drawingName`,
          item.drawingName,
        );
        formSearchListItemRef.current?.setFieldValue(
          `scopeDrawingsModal[${index}].drawingNumber`,
          item.drawingNumber,
        );
        formSearchListItemRef.current?.setFieldValue(
          `scopeDrawingsModal[${index}].revisionNumber`,
          item.revisionNumber,
        );
        formSearchListItemRef.current?.setFieldValue(
          `scopeDrawingsModal[${index}].revisionDate`,
          convertDateStringToDate(item.revisionDate),
        );
        formSearchListItemRef.current?.setFieldValue(
          `scopeDrawingsModal[${index}].fileSelected`,
          'true',
        );

        return null;
      });
    }
  }, [listDrawingsTheProject]);

  return (
    <Modal visibleProp={loadModalProp}>
      <Container>
        <Content>
          <h1>
            Pesquise por <strong>Nome</strong> ou <strong>Número</strong> do
            desenho
          </h1>
          <Form ref={formSearchListItemRef} onSubmit={handleSearchSubmit}>
            <SearchArea>
              <InputSearchWithButton
                type="text"
                name="find"
                icon={BsSearch}
                maxLength={45}
              />
            </SearchArea>
            {loading && (
              <SnipeLoading>
                <Loading size={40} color="var(--azul-5bits)" />
              </SnipeLoading>
            )}
            <ItensArea>
              {(listDrawingsSelect && listDrawingsTheProject.length > 0 && (
                <>
                  {listDrawingsTheProject.map((item, index) => (
                    <Scope
                      path={`scopeDrawingsModal[${index}]`}
                      key={String(index)}
                    >
                      <SplitDrawing>
                        <InputHidden name="idDrawing" />
                        <InputHidden name="fileName" />
                        {loadingDrawingsSelect ? (
                          <p
                            style={{
                              height: '4.5rem',
                              textAlign: 'center',
                            }}
                          >
                            carregando ...
                          </p>
                        ) : (
                          <>
                            <Input
                              id="drawingName"
                              name="drawingName"
                              labelText={index === 0 ? 'Nome' : ''}
                              placeholder=""
                              maxLength={45}
                              disabled
                            />

                            <Input
                              id="drawingNumber"
                              name="drawingNumber"
                              labelText={index === 0 ? 'Número' : ''}
                              placeholder=""
                              maxLength={45}
                              disabled
                            />
                          </>
                        )}
                        <RevisionDateAndNumberAndQuantityAndActionModal>
                          <Input
                            id="revisionNumber"
                            name="revisionNumber"
                            type="text"
                            labelText={index === 0 ? 'Rev' : ''}
                            disabled
                          />
                          <DatePicker
                            id="revisionDate"
                            name="revisionDate"
                            labelText={index === 0 ? 'Data da Rev.' : ''}
                            disabled
                          />

                          <Button
                            name={`openFile${index}`}
                            type="button"
                            onClick={() =>
                              handleOpenFile(index, `openFile${index}`)
                            }
                          >
                            Exibir
                          </Button>
                          <div>
                            <RiCheckLine
                              size={24}
                              onClick={() => {
                                handleInputChangeDrawingModal(item, indexProp);
                                closeAndClearModalFindDrawing(indexProp);
                                setListDrawingsTheProject([]);
                              }}
                            />
                          </div>
                        </RevisionDateAndNumberAndQuantityAndActionModal>
                      </SplitDrawing>
                    </Scope>
                  ))}
                </>
              )) || (
                <>
                  {!loading && (
                    <MessageError>Nenhum desenho encontrado</MessageError>
                  )}
                </>
              )}
            </ItensArea>
          </Form>
        </Content>
        <ActionCloseModal>
          <RiCloseLine
            name="closeModal"
            size={36}
            onClick={() => {
              setListDrawingsTheProject([]);
              closeAndClearModalFindDrawing(indexProp);
            }}
          >
            Fechar
          </RiCloseLine>
        </ActionCloseModal>
      </Container>
    </Modal>
  );
};

export default ModalFindDrawing;
