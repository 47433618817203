import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  width: 50vw;
  height: 100%;
  /* max-height: 80vh; */
  padding: 3rem 3rem;
  background-color: var(--branco-puro-5bits);

  h1 {
    text-transform: none;
    font-weight: 500;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-top: 4.2rem;
  height: 100%;
  max-height: 100%;
  width: 100%;

  > div:first-child {
    width: 100%;
    margin-bottom: 4.2rem;
  }
`;

export const ActionSaveModal = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 60%;
  max-height: 100%;
  overflow: auto;

  margin-top: 3.2rem;

  > button {
    width: 10.7vw;
  }
`;
