/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import React, { useRef, useState, useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import typy from 'typy';
import api from '../../../services/api';

import getValidationErrors from '../../../utils/getValidationError';
import convertNumberFloatToDB from '../../../utils/convertNumberFloatToDB';
import { useToast } from '../../../context/ToastContext';

import Loading from '../../../components/Loading';
import Input from '../../../components/Input';
import InputCurrency from '../../../components/InputCurrency';
import Button from '../../../components/Button';
import SimpleSelect from '../../../components/SimpleSelect';

import localizationList from '../../../data/localizationList.json';

import { Container, Content, Split, UnitAndPrice } from './styles';

import InputHidden from '../../../components/InputHidden';
import convertNumberFloatToBr from '../../../utils/convertNumberFloatToBr';

import { IService, IServiceForm } from '../../../interfaces/service';

import { IManufacturing } from '../../../interfaces/manufacturing';

import CheckboxInput from '../../../components/CheckboxInput';

interface IServiceDTO extends Omit<IService, 'price'> {
  price: string;
}

const UpdateService: React.FC = () => {
  const { id }: any = useParams();
  const formRef = useRef<FormHandles>(null);
  const navigate = useNavigate();
  const { addToast } = useToast();

  const [saving, setSaving] = useState<boolean>(false);
  const [service, setService] = useState<IServiceDTO>({} as IServiceDTO);

  const [listManufacturing, setListManufacturing] = useState<IManufacturing[]>(
    [],
  );

  const [loadingManufacturing, setLoadingManufacturing] = useState<boolean>(
    false,
  );

  useEffect(() => {
    const getService = async (): Promise<void> => {
      try {
        setLoadingManufacturing(true);

        const response: any = await api.get(`services/${id}`);

        const newResponse: any = {
          ...response.data,
          price: convertNumberFloatToBr(response.data.price),
          manufacturing:
            response.data.manufacturing !== null
              ? {
                  value: response.data.manufacturing.id,
                  label: response.data.manufacturing.name,
                }
              : response.data.manufacturing,
        };

        setService(newResponse);

        formRef.current?.setData(newResponse);

        // Busca a lista de manufacturing
        const responseMaterials: any = await api.get('manufacturing/steps');

        const responseMaterialsFormatted = responseMaterials.data.map(
          (i: IManufacturing) => ({
            value: i.id,
            label: i.name,
          }),
        );

        setListManufacturing(responseMaterialsFormatted);

        setLoadingManufacturing(false);
      } catch (error: any) {
        setLoadingManufacturing(false);

        if (error.response) {
          const { data } = error.response; // Error vindo do back está em data dentro de response
          addToast({
            type: 'error',
            title: data.message,
          });
          return;
        }

        addToast({
          type: 'error',
          title: 'Erro ao listar!',
          description: `Error: ${error}`,
        });
      }
    };
    getService();
  }, [addToast, id]);

  const handleFormSubmit = useCallback(
    async (dataForm: IServiceForm, { reset }) => {
      try {
        setSaving(true);

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          id: Yup.string().required('O Id do Serviço é obrigatório'),
          name: Yup.string().required('O Nome do Serviço é obrigatório'),
          price: Yup.string().required('O preço é obrigatório'),
          localization: Yup.string().required('A localização é obrigatória'),
        });

        await schema.validate(dataForm, { abortEarly: false });

        const newData = {
          ...dataForm,
          id: parseInt(id, 10),
          unit: 'Hora',
          price: convertNumberFloatToDB(dataForm.price),
          manufacturingStepId: typy(dataForm.manufacturing).isNumber
            ? dataForm.manufacturing
            : null,
        };

        delete newData.manufacturing;

        await api.put('services', newData);

        addToast({
          type: 'success',
          title: 'Atualização realizado com sucesso',
        });

        reset();
        setSaving(false);

        navigate('/services');
      } catch (error: any) {
        setSaving(false);

        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);

          return;
        }

        if (error.response) {
          const { data } = error.response; // Error vindo do back está em data dentro de response

          addToast({
            type: 'error',
            title: 'Erro na atualização',
            description: data.message,
          });
          if (
            formRef.current?.getFieldRef(data.field) &&
            data.field.toString().indexOf('_id') === -1
          ) {
            formRef.current?.getFieldRef(data.field).focus(); // Foca o campo que deu erro de acordo com o field retornado do back
          }

          return;
        }

        addToast({
          type: 'error',
          title: 'Error',
          description: 'Erro ao atualizar!',
        });
      }
    },
    [addToast, navigate, id],
  );

  return (
    <Container>
      <Content>
        <header>
          <p>Atualização de Serviço</p>
        </header>
        <Form ref={formRef} initialData={service} onSubmit={handleFormSubmit}>
          <InputHidden type="hidden" name="id" />
          <Split>
            <Input
              id="name"
              name="name"
              type="text"
              labelText="Nome*"
              maxLength={100}
              disabled={saving}
            />

            <UnitAndPrice>
              <Input
                id="unit"
                name="unit"
                labelText="Unidade*"
                defaultValue="Hora"
                placeholder=""
                disabled
              />

              <InputCurrency
                id="price"
                name="price"
                type="text"
                labelText="Preço*"
                disabled={saving}
              />
            </UnitAndPrice>

            <SimpleSelect
              id="localization"
              name="localization"
              labelText="Localização*"
              placeholder=""
              options={localizationList}
              getOptionLabel={option => option.value}
              disabled={saving}
            />
            <Input
              id="observation"
              name="observation"
              type="text"
              labelText="Observação"
              maxLength={100}
              disabled={saving}
            />
            {loadingManufacturing ? (
              <p style={{ height: '4.5rem', textAlign: 'center' }}>
                carregando ...
              </p>
            ) : (
              <SimpleSelect
                id="manufacturing"
                name="manufacturing"
                labelText="Vinculado a etapa:"
                placeholder=""
                options={listManufacturing}
                isClearable
                disabled={saving}
              />
            )}
            <CheckboxInput
              id="isActive"
              name="isActive"
              label="Ativo?"
              position="left"
              disabled={saving}
            />
          </Split>

          <Button type="submit" style={{ width: '233px' }} disabled={saving}>
            {saving ? <Loading size={24} color="white" /> : 'Salvar'}
          </Button>
        </Form>
      </Content>
    </Container>
  );
};

export default UpdateService;
