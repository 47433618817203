import styled, { css } from 'styled-components';

import expandMore from '../../../../../assets/expandMore.svg';

interface IOSOnStage {
  colorBorderBlueProp: boolean;
}

interface IDescriptionOS {
  visibleDescriptionOSProp: boolean;
}

export const ContainerOSOnStage = styled.div`
  min-width: 264px;
  max-width: 264px;
  min-height: 112px;
  max-height: 268px;
  padding: 0 2rem 1rem 2rem;
  border-right: 1px solid rgba(171, 171, 171, 0.5);
`;

export const OSOnStage = styled.div<IOSOnStage>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 225px;
  min-height: 102px;
  max-height: 268px;
  border-right: 1px solid rgba(171, 171, 171, 0.5);

  position: relative;

  background: var(--branco-puro-5bits);

  ${props =>
    (props.colorBorderBlueProp &&
      css`
        border: 1px solid var(--azul-5bits);
      `) ||
    css`
      border: 1px solid var(--laranja-5bits);
    `}
  border-radius: 4px;

  > span {
    width: 90%;
    align-items: flex-start;

    margin-bottom: 0.6rem;

    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;

    color: var(--cinza-escuro-5bits);

    > span {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 19px;

      color: var(--cinza-claro-5bits);
    }
  }

  > span:first-child {
    margin-top: 1rem;

    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;

    color: var(--azul-5bits);
  }

  /* width: 225px;
  height: 13.29vh;

   */
`;

export const ButtonExpandDescriptionOS = styled.div<IDescriptionOS>`
  position: absolute;
  top: 10px;
  right: 12px;

  width: 24px;
  height: 24px;

  border-radius: 50%;

  background-image: url(${expandMore});
  background-repeat: no-repeat;
  background-position: center;
  background-color: var(--laranja-5bits);

  ${props =>
    (props.visibleDescriptionOSProp &&
      css`
        transform: rotate(180deg);
        transition: transform 500ms linear;
      `) ||
    css`
      transform: rotate(0);
      transition: transform 500ms linear;
    `}
`;

export const ContainerDescriptionOS = styled.div<IDescriptionOS>`
  display: block;
  width: 200px;

  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;

  ${props =>
    props.visibleDescriptionOSProp &&
    css`
      margin-bottom: 2.5rem;
      max-height: 142px;
    `}

  > span {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;

    color: var(--cinza-escuro-5bits);
  }
`;

export const DescriptionOS = styled.div`
  display: flex;
  flex-direction: column;

  width: 200px;
  height: 117px;

  padding: 1.2rem 0.8rem 1.2rem 1.2rem;

  overflow-y: auto;

  background: var(--branco-puro-5bits);
  border: 1px solid rgba(171, 171, 171, 0.5);
  box-sizing: border-box;
  border-radius: 4px;

  > span {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;

    color: var(--cinza-escuro-5bits);
  }
`;
