import styled, { css, keyframes } from 'styled-components';

interface ContentProps {
  openMenuHamburgerProp: boolean;
  closeMenuHamburgerProp: boolean;
}

const openAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateX(-100%);
  }

  to {
    opacity: 1;
  }
`;
const closeAnimation = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translateX(-100%);
  }
`;

export const HeaderContainer = styled.header`
  background: white;
  padding: 0 1.6rem;
  width: 100%;
  z-index: 99999;
`;

export const Content = styled.div<ContentProps>`
  height: 100%;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  nav {
    display: flex;
    align-items: center;
    justify-content: start;
    flex: 1;

    > div {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      flex: 1;
      padding: 0 1.6rem;
    }

    a {
      position: relative;
      text-decoration: none;
      font-weight: 400;
      font-size: 1.8rem;
      line-height: 2.1rem;
      color: var(--pages-link-text-color);

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      img {
        height: 4.43vh;
      }

      span.title {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 2.5rem;
      }

      &.active {
        span.title {
          color: var(--laranja-5bits);
        }
      }
    }

    footer {
      display: none;
    }
  }

  .menuHamburger {
    display: none;
    opacity: 0;
  }

  //Medias
  @media (min-width: 0px) and (max-width: 759px) {
    nav {
      display: none;
      opacity: 0;
      position: absolute;
      top: 10.55vh;
      left: 0;
      width: 100%;
      height: calc(var(--vh, 1vh) * 100 - 10.55vh);
      background-color: var(--branco-puro-5bits);

      ${props =>
        props.openMenuHamburgerProp &&
        css`
          display: block;
          opacity: 1;
          animation: ${openAnimation} 1s;
        `}

      ${props =>
        props.closeMenuHamburgerProp &&
        css`
          display: block;
          left: -100vw;
          transition: left 1s;
          animation: ${closeAnimation} 1s;
        `}

      > div {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        flex: 1;
        padding: 0;

        a {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
        }
      }

      footer {
        display: flex;
      }
    }

    .menuHamburger {
      display: block;
      opacity: 1;
      background-color: transparent;
      border: none;
    }
  }

  @media (min-width: 760px) and (max-width: 999px) {
  }
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;

  button {
    border-radius: 0;
    border: none;
    background-color: transparent;
    padding: 0;
    margin-right: 2.2rem;
    color: var(--azul-5bits);

    svg {
      stroke: var(--pages-link-text-color);
    }
  }

  button:first-child {
    position: relative;
    padding: 0 0 0 0.8rem;
    ${props =>
      css`
        &::after {
          position: absolute;
          left: 0.6rem;
          bottom: 1.1rem;
          width: 0.7rem;
          height: 0.7rem;
          background-color: #cc1c1c;
          box-shadow: 0px 0px 0.5rem #ff0000;
          content: '';
          border-radius: 50%;
        }
      `};
  }

  a {
    display: none;
    opacity: 0;
  }

  //Medias
  @media (min-width: 0px) and (max-width: 759px) {
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 1;
      text-align: center;

      img {
        width: 2.2rem;
        height: 2.2rem;
      }
    }
  }

  @media (min-width: 760px) and (max-width: 999px) {
  }
`;
