import styled, { css } from 'styled-components';

interface SvgProps {
  rotate?: number;
  activeColor: boolean;
}

export const ImageExpandMoreCircle = styled.div<SvgProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.5rem;
  height: 4.4rem;

  ${props => css`
    transform: rotate(${props.rotate}deg);
    transition-duration: 0.3s;
  `}

  svg {
    path:nth-child(1) {
      fill: var(--branco-puro-5bits);
    }
    path:nth-child(2) {
      fill: var(--azul-5bits);
    }
  }

  ${props =>
    props.activeColor &&
    css`
      svg {
        path:nth-child(1) {
          fill: var(--verde-5bits);
        }
        path:nth-child(2) {
          fill: var(--branco-puro-5bits);
        }
      }
    `}
`;
