import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  width: 100%;

  > input {
    display: none;
  }
`;

export const ButtonAndIcon = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  > button {
    width: 11.7rem;
    background: var(--azul-5bits);
    font-style: normal;
    font-weight: bold;
    font-size: 1.4rem;
    text-transform: capitalize;
    line-height: 1.9rem;
    margin-right: 1.38rem;
  }
`;
