import styled, { css } from 'styled-components';

interface ContainerProps {
  path?: string;
}

export const ContainerGridLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 10.55vh 85.67vh 3.78vh;
  grid-template-areas:
    'h'
    'm'
    'f';

  header {
    grid-area: h;

    width: 100%;
  }

  main {
    grid-area: m;

    width: 100%;
  }

  footer {
    grid-area: f;

    width: 100%;
  }

  //Medias
  @media (min-width: 0px) and (max-width: 759px) {
    grid-template-rows: 10.55vh calc(var(--vh, 1vh) * 100 - 10.55vh);
    footer {
      display: none;
    }
  }

  @media (min-width: 760px) and (max-width: 999px) {
  }
`;

export const Main = styled.main<ContainerProps>`
  margin: 2rem;
  border: 1px solid rgba(13, 78, 133, 0.1);
  border-radius: 4px;

  /* largura do container para componentes todas com as margens laterais */
  max-width: calc(100vw - 4rem);
  /* retirar o header, footer, as margens e as bordas */
  /* height: calc(100vh - 81px - 29px - 40px - 3px); */
  background-color: var(--branco-puro-5bits);

  > div:first-child {
    padding: 1.2rem 0 0 1.8rem;
  }

  overflow: auto;

  ${props =>
    props.path &&
    (props.path === '/production-flow' || '/projects-overview') &&
    css`
      border: none;
      border-radius: 4px;

      max-width: 100vw;

      margin: 0 0 0 0;

      /* retirar o header, footer, as margens e as bordas */
      /* height: calc(100vh - 81px - 29px - 20px - 3px); */

      background: none;

      overflow: hidden;

      > div:first-child {
        padding: 0 0 0 1.8rem;
      }
    `};

  //Medias
  @media (min-width: 0px) and (max-width: 759px) {
    margin: 1.4rem;
    max-width: calc(100vw - 2.8rem);
  }

  @media (min-width: 760px) and (max-width: 999px) {
  }
`;

/* @media (max-width: 1280px) { */
/* retirar o header, footer, as margens, as bordas e o tamanho do scroll */
/* height: calc(100vh - 81px - 29px - 20px - 3px - 17px); */

/* ${//props => */
/* props.path && */
/* props.path === '/production-flow' && */
// css`
/* retirar o header, footer, as margens, as bordas */
/* height: calc(100vh - 81px - 29px - 20px - 3px); */
/* `}; */
/* } */
