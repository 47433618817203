/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-curly-newline */
import React, { useEffect, useState, useCallback, useRef } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { isValid, compareAsc } from 'date-fns';

import { RiPlayFill, RiStopMiniFill, RiCloseLine } from 'react-icons/ri';
import { IoMdTime, IoMdClose } from 'react-icons/io';
import CheckmarkWhite from '../../../../../../assets/CheckmarkWhite.svg';

import api from '../../../../../../services/api';
import { useToast } from '../../../../../../context/ToastContext';
import getValidationErrors from '../../../../../../utils/getValidationError';

import Button from '../../../../../../components/Button';
import DatePicker from '../../../../../../components/DatePicker';
import Loading from '../../../../../../components/Loading';

import convertDateToBr from '../../../../../../utils/convertDateToBr';
import convertDateAndHoursToBr from '../../../../../../utils/convertDateAndHoursToBr';
import convertDateStringToDate from '../../../../../../utils/convertDateStringToDate';
import convertDateAndHoursToDB from '../../../../../../utils/convertDateAndHoursToDB';
import Modal from '../../../../../../components/Modal';
import ModalLoading from '../../../../../../components/ModalLoading';
import ModalConfirmStartAction from '../ModalConfirmStartAction';
import ModalStopAction from '../ModalStopAction';

import {
  Container,
  ModalLoadingForModal,
  Content,
  TitleAndOSNumber,
  HeaderContainer,
  HeaderTitle,
  HeaderInformation,
  HistoricContainer,
  HistoricTitle,
  ButtonExpandHistoric,
  HistoricTable,
  PrevisionContainer,
  PrevisionTitle,
  PrevisionDates,
  ActionsContainer,
  ActionsTitle,
  ActionsButtons,
  ActionCloseModal,
} from './styles';
import ModalEndAction from '../ModalEndAction';

interface ModalDTO {
  loadModalProp: boolean;
  projectId: number;
  manufacturingStepId: number;
  inDelay: boolean;
  closeModalServiceExecution: (removeMask: boolean) => void;
}

const ModalServiceExecution: React.FC<ModalDTO> = ({
  loadModalProp = false,
  projectId,
  manufacturingStepId,
  inDelay = false,
  closeModalServiceExecution,
}) => {
  const { addToast } = useToast();
  const formRefModal = useRef<FormHandles>(null);
  const [historicOfTheStopOs, setHistoricOfTheStopOs] = useState<Array<any>>(
    [],
  );
  const [registerIsLastSchedule, setRegisterIsLastSchedule] = useState<any>({});
  const [loadingProjectOS, setLoadingProjectOS] = useState(false);
  const [visible, setVisible] = useState(false);
  const [showButtonSave, setShowButtonSave] = useState(true);
  const [showButtonStart, setShowButtonStart] = useState(false);
  const [savingOS, setSavingOS] = useState(false);
  const [startingOS, setStartingOS] = useState(false);
  const [disabledPrevisionDates, setDisabledPrevisionDates] = useState(false);

  useEffect(() => {
    setVisible(loadModalProp);
  }, [loadModalProp]);

  const getProjectOS = useCallback(async (): Promise<void> => {
    try {
      setLoadingProjectOS(true);

      const response: any = await api.get(
        `manufacturing/workflow/${projectId}/${manufacturingStepId}`,
      );

      const responseFormatted = response.data.map((i: any) => ({
        // const responseFormatted = await arrayFake.map((i: any) => ({
        ...i,
        forecastStartDateTime: convertDateStringToDate(
          i.forecastStartDateTime === null
            ? undefined
            : i.forecastStartDateTime,
        ),
        forecastEndDateTime: convertDateStringToDate(
          i.forecastEndDateTime === null ? undefined : i.forecastEndDateTime,
        ),
      }));

      // Registro com a com as datas previstas válido
      const objectLastSchedule = responseFormatted.find(
        (e: any) => e.isLastSchedule === true,
      );

      if (objectLastSchedule.isStop === false) {
        // 1º if Previsão ainda não preenchida
        // 2º if Previsão salva mas não iniciada
        // 3º if Previsão salva e iniciada
        if (
          objectLastSchedule.startDateTime === null &&
          objectLastSchedule.forecastStartDateTime === undefined
        ) {
          setShowButtonSave(true);
          setShowButtonStart(false);
          setDisabledPrevisionDates(false);
        } else if (
          objectLastSchedule.startDateTime === null &&
          objectLastSchedule.forecastStartDateTime !== undefined
        ) {
          setShowButtonStart(true);
          setShowButtonSave(false);
          setDisabledPrevisionDates(true);
        } else if (
          objectLastSchedule.startDateTime !== null &&
          objectLastSchedule.forecastStartDateTime !== undefined
        ) {
          setShowButtonSave(false);
          setShowButtonStart(false);
          setDisabledPrevisionDates(true);
        }

        // if (objectLastSchedule.startDateTime === null) {
        //   setShowButtonStart(true);
        //   setShowButtonSave(false);
        //   setDisabledPrevisionDates(true);
        // }

        formRefModal.current?.setFieldValue(
          'forecastStartDateTime',
          objectLastSchedule.forecastStartDateTime,
        );
        formRefModal.current?.setFieldValue(
          'forecastEndDateTime',
          objectLastSchedule.forecastEndDateTime,
        );
      } else {
        setShowButtonSave(true);
        setDisabledPrevisionDates(false);
        setShowButtonStart(false);
      }

      setRegisterIsLastSchedule(objectLastSchedule);
      // Fim Registro com a com as datas previstas válido

      // Todos os registros da O.S que foram paradas. (Para tabela Histórico)
      const listOfTheOSStop = responseFormatted.filter(
        (register: any) => register.isStop === true,
      );

      setHistoricOfTheStopOs(listOfTheOSStop);

      setLoadingProjectOS(false);
    } catch (error: any) {
      setLoadingProjectOS(false);

      // eslint-disable-next-line no-shadow
      if (error.response) {
        const { data } = error.response; // Error vindo do back está em data dentro de response
        addToast({
          type: 'error',
          title: 'Ocorreu um erro!',
          description: `Erro: ${data.message}`,
        });
        return;
      }

      addToast({
        type: 'error',
        title: 'Ocorreu um erro interno!',
        description: `Error: ${error}`,
      });
    }
  }, [addToast, manufacturingStepId, projectId]);

  useEffect(() => {
    getProjectOS();
  }, [getProjectOS]);

  const handleAlterPrevision = useCallback(() => {
    setShowButtonSave(true);
    setDisabledPrevisionDates(false);
  }, []);

  const [visibleHistoricTable, setVisibleHistoricTable] = useState(false);
  const handleOpenHistoricTable = useCallback(() => {
    setVisibleHistoricTable(!visibleHistoricTable);
  }, [visibleHistoricTable]);

  // Modal start
  const [
    openModalConfirmStartAction,
    setOpenModalConfirmStartAction,
  ] = useState(false);
  const handleShowModalConfirmStartAction = useCallback(() => {
    setOpenModalConfirmStartAction(!openModalConfirmStartAction);
  }, [openModalConfirmStartAction]);

  // Modal Stop
  const [openModalStopAction, setOpenModalStopAction] = useState(false);
  const handleOpenModalStopAction = useCallback(() => {
    setOpenModalStopAction(true);
    setVisible(false);
  }, []);

  const handleCloseModalStopAction = useCallback(() => {
    setOpenModalStopAction(false);
    setVisible(true);
    getProjectOS();
  }, [getProjectOS]);

  // Modal End
  const [openModalEndAction, setOpenModalEndAction] = useState(false);
  const handleOpenModalEndAction = useCallback(() => {
    setOpenModalEndAction(true);
    setVisible(false);
  }, []);

  const handleCloseModalEndAction = useCallback(() => {
    setOpenModalEndAction(false);
    setVisible(true);
    getProjectOS();
  }, [getProjectOS]);

  // handleFormSubmit
  const handleFormSubmit = useCallback(async () => {
    try {
      setSavingOS(true);

      formRefModal.current?.setErrors({});

      const dataForm: any = formRefModal.current?.getData();

      const schema = Yup.object().shape({
        forecastStartDateTime: Yup.date()
          .test(
            'forecastStartDateTime',
            'Data inválida',
            (forecastStartDateTime: any) => {
              const validateDate = forecastStartDateTime;

              return isValid(validateDate);
            },
          )
          .nullable()
          .required('Data obrigatória'),
        forecastEndDateTime: Yup.date()
          .test(
            'forecastEndDateTime',
            'Data inválida',
            (forecastEndDateTime: any) => {
              const validateDate = forecastEndDateTime;

              return isValid(validateDate);
            },
          )
          .test(
            'forecastEndDateTime',
            'Data de fim não pode ser anterior a data de início',
            (forecastEndDateTime: any) => {
              const validateDate = forecastEndDateTime;

              return !(
                compareAsc(validateDate, dataForm.forecastStartDateTime) < 0
              );
            },
          )
          .nullable()
          .required('Data obrigatória'),
      });

      await schema.validate(dataForm, { abortEarly: false });

      const newDataForm = {
        projectId: registerIsLastSchedule.projectId,
        manufacturingStepId: registerIsLastSchedule.manufacturingStepId,
        forecastStartDateTime: convertDateAndHoursToDB(
          dataForm.forecastStartDateTime,
        ),
        forecastEndDateTime: convertDateAndHoursToDB(
          dataForm.forecastEndDateTime,
        ),
      };

      await api.post('manufacturing/workflow', newDataForm);

      addToast({
        type: 'success',
        title: 'Cadastro realizado com sucesso',
      });

      getProjectOS();
      // reset();
      setSavingOS(false);

      // history.push('/manufacturing/workflow');
    } catch (error: any) {
      setSavingOS(false);

      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formRefModal.current?.setErrors(errors);

        return;
      }

      if (error.response) {
        const { data } = error.response; // Error vindo do back está em data dentro de response

        addToast({
          type: 'error',
          title: 'Erro na atualização',
          description: data.message,
        });

        return;
      }

      addToast({
        type: 'error',
        title: 'Erro ao atualizar!',
        description: `Error: ${error}`,
      });
    }
  }, [
    addToast,
    getProjectOS,
    registerIsLastSchedule.manufacturingStepId,
    registerIsLastSchedule.projectId,
  ]);

  // handleSubmitStart
  const handleSubmitStart = useCallback(async () => {
    try {
      setStartingOS(true);

      formRefModal.current?.setErrors({});

      const dataForm: any = formRefModal.current?.getData();

      const schema = Yup.object().shape({
        forecastStartDateTime: Yup.date()
          .test(
            'forecastStartDateTime',
            'Data inválida',
            (forecastStartDateTime: any) => {
              const validateDate = forecastStartDateTime;

              return isValid(validateDate);
            },
          )
          .nullable()
          .required('Data obrigatória'),
      });

      await schema.validate(dataForm, { abortEarly: false });

      const newDataForm = {
        id: registerIsLastSchedule.id,
        startDateTime: convertDateAndHoursToDB(dataForm.forecastStartDateTime),
      };

      await api.post('manufacturing/workflow/start', newDataForm);

      addToast({
        type: 'success',
        title: 'Cadastro realizado com sucesso',
      });

      getProjectOS();

      setStartingOS(false);
      handleShowModalConfirmStartAction();
      closeModalServiceExecution(false);
    } catch (error: any) {
      setStartingOS(false);

      if (error.response) {
        const { data } = error.response; // Error vindo do back está em data dentro de response

        addToast({
          type: 'error',
          title: 'Erro na atualização',
          description: data.message,
        });

        return;
      }

      addToast({
        type: 'error',
        title: 'Erro ao atualizar!',
        description: `Error: ${error}`,
      });
    }
  }, [
    addToast,
    closeModalServiceExecution,
    getProjectOS,
    handleShowModalConfirmStartAction,
    registerIsLastSchedule.id,
  ]);

  return (
    <>
      <Modal visibleProp={visible}>
        <Container>
          <ModalLoadingForModal>
            <ModalLoading visible={loadingProjectOS}>
              <p>
                <i>Carregando os dados...</i>
              </p>
            </ModalLoading>
          </ModalLoadingForModal>
          <TitleAndOSNumber>
            <span>Execução de Serviço |</span>
            <span>Nº da O.S.:</span>
            <span>
              {Object.entries(registerIsLastSchedule).length > 0 &&
                registerIsLastSchedule.projectId}
            </span>
          </TitleAndOSNumber>
          <Content>
            <HeaderContainer>
              <HeaderTitle>
                <div>
                  <span>
                    Etapa:{' '}
                    <span>
                      {Object.entries(registerIsLastSchedule).length > 0 &&
                        registerIsLastSchedule.manufacturingStep.name}
                    </span>
                  </span>
                </div>
                <div>
                  <span>
                    Tempo Planejado:{' '}
                    <span>
                      {Object.entries(registerIsLastSchedule).length > 0 &&
                        registerIsLastSchedule.forecastTime}{' '}
                      horas
                    </span>
                  </span>
                </div>
                <div>
                  <span>
                    Prazo de entrega da etapa:{' '}
                    <span>
                      {Object.entries(registerIsLastSchedule).length > 0 &&
                        convertDateAndHoursToBr(
                          registerIsLastSchedule.forecastEndDateTime,
                        )}
                      h
                    </span>
                  </span>
                </div>
              </HeaderTitle>
              <HeaderInformation>
                <div>
                  <span>
                    Título:{' '}
                    <span>
                      {Object.entries(registerIsLastSchedule).length > 0 &&
                        registerIsLastSchedule.project.title}
                    </span>
                    &nbsp;&nbsp;-&nbsp;&nbsp;Previsão da entrega do projeto:{' '}
                    <span>
                      {Object.entries(registerIsLastSchedule).length > 0 &&
                        convertDateToBr(
                          registerIsLastSchedule.project.forecastEndDate,
                        )}
                    </span>
                    &nbsp;&nbsp;-&nbsp;&nbsp;Cliente:{' '}
                    <span>
                      {Object.entries(registerIsLastSchedule).length > 0 &&
                        registerIsLastSchedule.project.client.name}
                    </span>
                  </span>
                </div>
              </HeaderInformation>
            </HeaderContainer>
            <HistoricContainer onClick={handleOpenHistoricTable}>
              <HistoricTitle>
                <span>Histórico</span>
                <div>
                  <ButtonExpandHistoric
                    visibleHistoricTableProp={visibleHistoricTable}
                  />

                  <span>*</span>
                  <span>{historicOfTheStopOs.length} registro(s)</span>
                </div>
              </HistoricTitle>
              <HistoricTable visibleHistoricTableProp={visibleHistoricTable}>
                <table>
                  <thead>
                    <tr>
                      <th>Início</th>
                      <th>Fim</th>
                      <th>Justificativa</th>
                    </tr>
                  </thead>
                  <tbody>
                    {historicOfTheStopOs.length > 0 &&
                      historicOfTheStopOs.map(
                        (historicOfTheStopOsItem: any, index) => (
                          <tr key={String(index)}>
                            <td>
                              {convertDateAndHoursToBr(
                                historicOfTheStopOsItem.startDateTime,
                              )}
                            </td>
                            <td>
                              {convertDateAndHoursToBr(
                                historicOfTheStopOsItem.stopDateTime,
                              )}
                            </td>
                            <td>{historicOfTheStopOsItem.justifyStop}</td>
                          </tr>
                        ),
                      )}
                  </tbody>
                </table>
              </HistoricTable>
            </HistoricContainer>
            <PrevisionContainer>
              <PrevisionTitle>
                <span>Previsão</span>
              </PrevisionTitle>
              <PrevisionDates
                widthWithButtonAlterPrevision={
                  disabledPrevisionDates && showButtonStart
                }
              >
                <Form ref={formRefModal} onSubmit={handleFormSubmit}>
                  <DatePicker
                    id="forecastStartDateTime"
                    name="forecastStartDateTime"
                    labelText="Início:"
                    dateFormatProp="dd/MM/yyyy - HH:mm"
                    showTimeInput
                    timeInputLabel="Hora:"
                    popperPlacement="top-start"
                    shouldCloseOnSelect={false}
                    disabled={disabledPrevisionDates}
                  />
                  <DatePicker
                    id="forecastEndDateTime"
                    name="forecastEndDateTime"
                    labelText="Fim:"
                    dateFormatProp="dd/MM/yyyy - HH:mm"
                    showTimeInput
                    timeInputLabel="Hora:"
                    popperPlacement="top-start"
                    shouldCloseOnSelect={false}
                    disabled={disabledPrevisionDates}
                  />

                  {disabledPrevisionDates && showButtonStart && (
                    <Button
                      name="btAlterPrevision"
                      onClick={handleAlterPrevision}
                    >
                      <IoMdTime size={24} />
                      Alterar previsão
                    </Button>
                  )}
                </Form>
              </PrevisionDates>
            </PrevisionContainer>

            <ActionsContainer>
              <ActionsTitle>
                <span>Ações</span>
              </ActionsTitle>
              <ActionsButtons>
                {showButtonSave && (
                  <Button type="button" onClick={handleFormSubmit}>
                    {savingOS ? (
                      <Loading size={24} color="white" />
                    ) : (
                      <>
                        <img src={CheckmarkWhite} alt="Checkmark" /> Salvar
                      </>
                    )}
                  </Button>
                )}
                {!showButtonSave && (
                  <>
                    {showButtonStart && (
                      <Button
                        type="button"
                        onClick={handleShowModalConfirmStartAction}
                      >
                        <RiPlayFill size={24} />
                        Iniciar
                      </Button>
                    )}

                    {!showButtonStart && (
                      <>
                        <Button
                          type="button"
                          onClick={() => {
                            handleOpenModalStopAction();
                          }}
                        >
                          <RiStopMiniFill size={24} />
                          Parar
                        </Button>
                        <Button
                          type="button"
                          onClick={() => {
                            handleOpenModalEndAction();
                          }}
                        >
                          <IoMdClose size={30} />
                          Concluir
                        </Button>
                      </>
                    )}
                  </>
                )}
              </ActionsButtons>
            </ActionsContainer>
          </Content>
          <ActionCloseModal>
            <RiCloseLine
              name="closeModal"
              size={36}
              onClick={() => {
                closeModalServiceExecution(false);
              }}
            >
              Fechar
            </RiCloseLine>
          </ActionCloseModal>
        </Container>
      </Modal>
      <ModalConfirmStartAction
        loadModalProp={openModalConfirmStartAction}
        startingOSProp={startingOS}
        forecastStartDateTimeProp={
          Object.entries(registerIsLastSchedule).length
            ? convertDateAndHoursToBr(
                registerIsLastSchedule.forecastStartDateTime,
              )
            : ''
        }
        handleSubmitStartProp={handleSubmitStart}
        closeModalConfirmStartActionProp={handleShowModalConfirmStartAction}
      />

      {openModalStopAction && (
        <ModalStopAction
          loadModalProp={openModalStopAction}
          registerIsLastScheduleProp={registerIsLastSchedule}
          closeModalStopActionProp={handleCloseModalStopAction}
          closeModalServiceExecutionProp={closeModalServiceExecution}
        />
      )}

      {openModalEndAction && (
        <ModalEndAction
          loadModalProp={openModalEndAction}
          registerIsLastScheduleProp={registerIsLastSchedule}
          closeModalEndActionProp={handleCloseModalEndAction}
          closeModalServiceExecutionProp={closeModalServiceExecution}
        />
      )}
    </>
  );
};

export default ModalServiceExecution;
