import React, { HTMLProps } from 'react';
import { Container } from './styles';

/*
O title será a mensagem de erro
Como o styled components utiliza as class,
para que o Tooltip receba estilização de um
elemento superior, ele tem que receber a className.
O className não pode ser obrigatório pois será passada
pelos estilos que utilizarem.
*/
interface TooltipProps extends HTMLProps<HTMLDivElement> {
  title: string;
  colorSpan?: string;
  colorBefore?: string;
  spanLeft?: string;
  beforeLeft?: string;
  widthSpanWithContentProp?: string;
  tooltipReversedProp?: boolean;
}

const Tooltip: React.FC<TooltipProps> = ({
  title,
  colorSpan = '',
  colorBefore = '',
  spanLeft = '10%',
  beforeLeft = '50%',
  widthSpanWithContentProp = '100%',
  tooltipReversedProp = false,
  children,
  ...rest
}) => {
  return (
    <Container
      colorBefore={colorBefore}
      colorSpan={colorSpan}
      spanLeft={spanLeft}
      beforeLeft={beforeLeft}
      widthSpanWithContent={widthSpanWithContentProp}
      tooltipReversed={tooltipReversedProp}
    >
      <span {...rest}>{title}</span>
      {children}
    </Container>
  );
};

export default Tooltip;
