/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { isValid, parse } from 'date-fns';

import typy from 'typy';
import api from '../../../../services/api';
import getProjectFormatted from '../../../AuxiliaryFunctions/GetProject';
import getValidationErrors from '../../../../utils/getValidationError';
import { useToast } from '../../../../context/ToastContext';

import Loading from '../../../../components/Loading';

import Button from '../../../../components/Button';
import SimpleSelect from '../../../../components/SimpleSelect';
import Tabs from '../../../../components/Tabs';

import currentSectorList from '../../../../data/currentSectorList.json';

import {
  Container,
  Content,
  HeadOpenProject,
  SplitFooter,
  SelectSectorAndButtonSave,
} from './styles';

import HeadProject from '../../HeadProject';
import BodyCreateCommercial from '../BodyCreateCommercial/index';
import BodyPlanning from '../BodyPlanning';
import BodyPurchasing from '../../Purchasing/BodyPurchasing';
import BodyManufacturing from '../../Manufacturing/Projects/BodyManufacturing';

import ModalChecklist from '../../componentsSectors/ModalChecklist/modalChecklist';
import convertNumberFloatToDB from '../../../../utils/convertNumberFloatToDB';
import ChecklistProject from '../../componentsSectors/ChecklistProject';

import { IMaterialResponse } from '../../../../interfaces/material';
import { IServiceResponse } from '../../../../interfaces/service';
import { IProviderResponse } from '../../../../interfaces/provider';
import { IChecklist } from '../../../../interfaces/checklist';
import { IProject } from '../../../../interfaces/project';
import ModalLoading from '../../../../components/ModalLoading';
import convertDateToDB from '../../../../utils/convertDateToDB';
import ModalConfirmationOfExclusionMaterial from '../BodyPlanning/components/ModalConfirmationOfExclusionMaterial';
import ModalConfirmationOfExclusionService from '../BodyPlanning/components/ModalConfirmationOfExclusionService';
import ModalConfirmationFinishedQuote from '../components/ModalConfirmationFinishedQuote';
import ModalConfirmationFinishedProjectAndReleaseBilling from '../components/ModalConfirmationFinishedProjectAndReleaseBilling';
import InputHidden from '../../../../components/InputHidden';
import { IClient, IClientResponse } from '../../../../interfaces/client';
import { IUserResponse } from '../../../../interfaces/user';

const CreateProject: React.FC = () => {
  const { id }: any = useParams();
  const formRef = useRef<FormHandles>(null);
  const navigate = useNavigate();
  const { addToast } = useToast();

  const [project, setProject] = useState<IProject>({} as IProject);
  const [loading, setLoading] = useState<boolean>(false);

  const [saving, setSaving] = useState<boolean>(false);

  const [listUsers, setListUsers] = useState<
    Omit<IUserResponse, 'usersHasMenus'>[]
  >([]);
  const [listClients, setListClients] = useState<IClient[]>([]);
  const [listMaterials, setListMaterials] = useState<IMaterialResponse[]>([]);
  const [listServices, setListServices] = useState<IServiceResponse[]>([]);
  const [listManufacturingSteps, setListManufacturingSteps] = useState<any[]>(
    [],
  );

  const [listProviders, setListProviders] = useState<IProviderResponse[]>([]);
  const [listChecklist, setListChecklist] = useState<IChecklist[]>([]);

  const [loadingChecklist, setLoadingChecklist] = useState<boolean>(false);
  const [finishedProposal, setFinishedProposal] = useState<boolean>(false);

  // Get project
  const getProject = useCallback(async (): Promise<void> => {
    try {
      const projectResponse: any = await getProjectFormatted(id);

      setProject(projectResponse);
      setFinishedProposal(projectResponse.finishedProposal);

      // setListManufacturingSteps(projectResponse.projectHasManufacturingSteps);
      formRef.current?.setData(projectResponse);

      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      // eslint-disable-next-line no-shadow
      if (error.response) {
        const { data } = error.response; // Error vindo do back está em data dentro de response
        addToast({
          type: 'error',
          title: data.message,
        });
        return;
      }

      addToast({
        type: 'error',
        title: 'Ocorreu um erro interno',
        description: `${error}`,
      });
    }
  }, [addToast, id]);
  // End Get project

  const getMaterials = useCallback(async (): Promise<void> => {
    try {
      // Users
      const responseUsers = await api.get<
        Omit<IUserResponse, 'usersHasMenus'>[]
      >('users');

      const responseUsersFormatted: any = responseUsers.data.map(i => ({
        ...i,
        value: i.id,
        label: i.name,
      }));

      setListUsers(responseUsersFormatted);

      // Clientes
      const responseClients = await api.get<IClientResponse[]>('clients');

      const responseClientsFormatted: any = responseClients.data.map(i => ({
        value: i.id,
        label: i.name,
      }));

      setListClients(responseClientsFormatted);

      // Materials
      const responseMaterials = await api.get<IMaterialResponse[]>(
        'materials/active',
      );

      const responseMaterialsFormatted: any = responseMaterials.data.map(i => ({
        ...i,
        value: i.id,
        label: i.name,
      }));

      setListMaterials(responseMaterialsFormatted);
      // End Materials

      // Services
      // setLoadingServices(true);
      const responseServices = await api.get<IServiceResponse[]>(
        'services/active',
      );

      const responseServicesFormatted: any = responseServices.data.map(i => ({
        ...i,
        value: i.id,
        label: i.name,
      }));

      setListServices(responseServicesFormatted);
      // End Services

      // Providers
      const responseProviders = await api.get<IProviderResponse[]>(
        'providers/active',
      );

      const responseProvidersFormatted: any = responseProviders.data.map(i => ({
        ...i,
        value: i.id,
        label: i.fantasyName,
      }));

      setListProviders(responseProvidersFormatted);
      // End Providers

      // Checklist
      const responseChecklist = await api.get<any[]>('checklist');

      setListChecklist(responseChecklist.data);
      // End checklist

      // Project
      getProject();

      // setLoadingMaterials(false);
    } catch (error: any) {
      setLoading(false);

      if (error.response && error.response.data) {
        const { data } = error.response; // Error vindo do back está em data dentro de response
        addToast({
          type: 'error',
          title: data.message,
        });
        return;
      }

      addToast({
        type: 'error',
        title: 'Error',
        description: 'Erro ao listar!',
      });
    }
  }, [addToast, getProject]);

  // List Materials

  const [openModalChecklist, setOpenModalChecklist] = useState(false);
  const handleOpenModalChecklist = useCallback(() => {
    setOpenModalChecklist(!openModalChecklist);
    setSaving(false);
  }, [openModalChecklist]);

  const [
    openModalConfirmationFinishedQuote,
    setOpenModalConfirmationFinishedQuote,
  ] = useState(false);

  const [
    openModalFinishedProjectAndReleaseBilling,
    setOpenModalFinishedProjectAndReleaseBilling,
  ] = useState(false);

  const handleOpenModalConfirmationFinishedQuote = useCallback(() => {
    setOpenModalConfirmationFinishedQuote(!openModalConfirmationFinishedQuote);
    if (!openModalConfirmationFinishedQuote === false) {
      handleOpenModalChecklist();
    }
    setSaving(false);
  }, [handleOpenModalChecklist, openModalConfirmationFinishedQuote]);

  const handleOpenModalFinishedProjectAndReleaseBilling = useCallback(() => {
    setOpenModalFinishedProjectAndReleaseBilling(
      !openModalFinishedProjectAndReleaseBilling,
    );
    if (!openModalFinishedProjectAndReleaseBilling === false) {
      handleOpenModalChecklist();
    }
    setSaving(false);
  }, [handleOpenModalChecklist, openModalFinishedProjectAndReleaseBilling]);

  /**
   * Referência ao componente filho
   */
  const handleMaterialComponentRef = useRef<any>();
  const getUpdateListCompleteAndUpdatedOfTheMaterials = useCallback((): Array<any> => {
    const projectHasMaterialsFinal = handleMaterialComponentRef.current.getUpdateListCompleteAndUpdatedOfTheMaterials();

    return projectHasMaterialsFinal;
  }, []);

  // Modal Confirmation Of Exclusion Material
  const [
    loadModalConfirmationOfExclusionMaterial,
    setLoadModalConfirmationOfExclusionMaterial,
  ] = useState(false);

  const [indexMaterial, setIndexMaterial] = useState<number>(-1);

  const closeModalConfirmationOfExclusionMaterial = useCallback(() => {
    setLoadModalConfirmationOfExclusionMaterial(false);
  }, []);

  const showModalConfirmationOfExclusionMaterial = useCallback(
    (indexMaterialProp, showModal) => {
      setIndexMaterial(indexMaterialProp);
      setLoadModalConfirmationOfExclusionMaterial(showModal);
    },
    [],
  );

  const removeMaterial = (indexMaterialForRemove: number): void => {
    handleMaterialComponentRef.current?.removeMaterial(indexMaterialForRemove);
  };
  // End Modal Confirmation Of Exclusion Material

  const handleServiceComponentRef = useRef<any>();
  const getUpdateListCompleteAndUpdatedOfTheServices = useCallback((): Array<any> => {
    const projectHasServicesFinal = handleServiceComponentRef.current.getUpdateListCompleteAndUpdatedOfTheServices();

    return projectHasServicesFinal;
  }, []);

  // Modal Confirmation Of Exclusion Service
  const [
    loadModalConfirmationOfExclusionService,
    setLoadModalConfirmationOfExclusionService,
  ] = useState(false);

  const [indexService, setIndexService] = useState<number>(-1);

  const closeModalConfirmationOfExclusionService = useCallback(() => {
    setLoadModalConfirmationOfExclusionService(false);
  }, []);

  const showModalConfirmationOfExclusionService = useCallback(
    (indexServiceProp, showModal) => {
      setIndexService(indexServiceProp);
      setLoadModalConfirmationOfExclusionService(showModal);
    },
    [],
  );

  const removeService = (indexServiceForRemove: number): void => {
    handleServiceComponentRef.current?.removeService(indexServiceForRemove);
  };
  // End Modal Confirmation Of Exclusion Service

  // verifyPermissions
  const verifyPermissions = useCallback(async () => {
    try {
      const dataForm = formRef.current?.getData();
      const projectHasMaterialsFinal = getUpdateListCompleteAndUpdatedOfTheMaterials();
      const projectHasServicesFinal = getUpdateListCompleteAndUpdatedOfTheServices();

      const dataFormCorrect: any = {
        ...dataForm,
        projectHasMaterials: [...projectHasMaterialsFinal],
        projectHasServices: [...projectHasServicesFinal],
      };

      setSaving(true);
      formRef.current?.setErrors({});

      if (dataFormCorrect && Object.entries(dataFormCorrect).length > 0) {
        const schema = Yup.object().shape({
          projectHasMaterials: Yup.array().of(
            Yup.object().shape({
              quantity: Yup.string().when('materialId', {
                is: (materialId: string) => materialId !== '',
                then: Yup.string()
                  .test(
                    'quantityMaterial',
                    'Quantidade obrigatória',
                    (quantity: any) => {
                      return (
                        quantity !== '' && convertNumberFloatToDB(quantity) > 0
                      );
                    },
                  )
                  .required('Quantidade é obrigatório'),
              }),
              unitPrice: Yup.string().required('Valor unitário obrigatório'),
            }),
          ),
          projectHasServices: Yup.array().of(
            Yup.object().shape({
              quantity: Yup.string().when('serviceId', {
                is: (serviceId: string) => serviceId !== '',
                then: Yup.string()
                  .test(
                    'quantityService',
                    'Tempo obrigatório',
                    (quantity: any) => {
                      return quantity !== '00:00' && quantity !== '';
                    },
                  )
                  .required('Tempo obrigatório'),
              }),
              unitPrice: Yup.string().when('serviceId', {
                is: (serviceId: string) => serviceId !== '',
                then: Yup.string()
                  .test(
                    'unitPriceService',
                    'Valor hora obrigatório',
                    (unitPrice: any) => {
                      return (
                        unitPrice !== '' &&
                        convertNumberFloatToDB(unitPrice) > 0
                      );
                    },
                  )
                  .required('Valor hora obrigatório'),
              }),
            }),
          ),
          technicalValidatorDate:
            dataFormCorrect.userIdTechnicalValidator !== ''
              ? Yup.string()
                  .test(
                    'technicalValidatorDate',
                    'Data inválida',
                    (technicalValidatorDate: any) => {
                      const validateDate = parse(
                        technicalValidatorDate,
                        'dd/MM/yyyy',
                        new Date(),
                      );
                      return isValid(validateDate);
                    },
                  )
                  .required('Data obrigatória')
              : Yup.string().notRequired(),
          operationalValidatorDate:
            dataFormCorrect.userIdOperationalValidator !== ''
              ? Yup.string()
                  .test(
                    'operationalValidatorDate',
                    'Data inválida',
                    (operationalValidatorDate: any) => {
                      const validateDate = parse(
                        operationalValidatorDate,
                        'dd/MM/yyyy',
                        new Date(),
                      );
                      return isValid(validateDate);
                    },
                  )
                  .required('Data obrigatória')
              : Yup.string().notRequired(),
          projectValidatorDate:
            dataFormCorrect.userIdProjectValidator !== ''
              ? Yup.string()
                  .test(
                    'projectValidatorDate',
                    'Data inválida',
                    (projectValidatorDate: any) => {
                      const validateDate = parse(
                        projectValidatorDate,
                        'dd/MM/yyyy',
                        new Date(),
                      );
                      return isValid(validateDate);
                    },
                  )
                  .required('Data obrigatória')
              : Yup.string().notRequired(),
          currentSector: Yup.string().required(
            'O setor que receberá o projeto é obrigatório',
          ),
        });

        await schema.validate(dataFormCorrect, { abortEarly: false });

        if (
          (dataFormCorrect.currentSector === 'Planejamento' ||
            dataFormCorrect.currentSector === 'Comercial') &&
          finishedProposal === false
        ) {
          handleOpenModalConfirmationFinishedQuote();
        } else if (
          dataFormCorrect.currentSector === 'Comercial' &&
          finishedProposal === true
        ) {
          handleOpenModalFinishedProjectAndReleaseBilling();
        } else {
          handleOpenModalChecklist();
        }
      }
    } catch (error: any) {
      setSaving(false);

      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formRef.current?.setErrors(errors);

        return;
      }

      addToast({
        type: 'error',
        title: 'Error',
        description: `${error}`,
      });
    }
  }, [
    addToast,
    finishedProposal,
    getUpdateListCompleteAndUpdatedOfTheMaterials,
    getUpdateListCompleteAndUpdatedOfTheServices,
    handleOpenModalChecklist,
    handleOpenModalConfirmationFinishedQuote,
    handleOpenModalFinishedProjectAndReleaseBilling,
  ]);

  // handleFormSubmit
  const handleFormSubmit = useCallback(
    async (dataForm: any, { reset }) => {
      try {
        setSaving(true);

        const projectHasMaterialsFinal = getUpdateListCompleteAndUpdatedOfTheMaterials();

        const materialsCorrect = projectHasMaterialsFinal.filter(
          (itemMaterials: any) => {
            return itemMaterials.materialId !== '';
          },
        );

        const projectHasServicesFinal = getUpdateListCompleteAndUpdatedOfTheServices();

        const servicesCorrect = projectHasServicesFinal.filter(
          (itemServices: any) => {
            return itemServices.serviceId !== '';
          },
        );

        const manufacturingStepsCorrect = dataForm.projectHasManufacturingSteps.filter(
          (itemManufacturingSteps: any) => {
            return itemManufacturingSteps.id !== '';
          },
        );

        const materialsFormatted = materialsCorrect.map(
          (itemMaterials: any) => {
            const newItemMaterials = {
              id: typy(parseInt(itemMaterials.id, 10)).isNumber
                ? parseInt(itemMaterials.id, 10)
                : null,
              materialId: parseInt(itemMaterials.materialId, 10),
              providerId: typy(parseInt(itemMaterials.providerId, 10)).isNumber
                ? parseInt(itemMaterials.providerId, 10)
                : null,
              quantity: convertNumberFloatToDB(itemMaterials.quantity),
              unitPrice: convertNumberFloatToDB(itemMaterials.unitPrice),
              observation: itemMaterials.observation,
            };
            return newItemMaterials;
          },
        );

        const servicesFormatted = servicesCorrect.map((itemServices: any) => {
          const newItemServices = {
            serviceId: parseInt(itemServices.serviceId, 10),
            quantity: itemServices.quantity,
            unitPrice: convertNumberFloatToDB(itemServices.unitPrice),
            observation: itemServices.observation,
          };
          return newItemServices;
        });

        const manufacturingStepsFormatted = manufacturingStepsCorrect.map(
          (itemManufacturingSteps: any) => {
            const newItemManufacturingSteps = {
              manufacturingStepId: parseInt(itemManufacturingSteps.id, 10),
              forecastTime: itemManufacturingSteps.forecastTime,
              forecastDays:
                itemManufacturingSteps.forecastDays !== ''
                  ? parseInt(itemManufacturingSteps.forecastDays, 10)
                  : 0,
              // observation: itemManufacturingSteps.observation,
            };
            return newItemManufacturingSteps;
          },
        );

        /*
         *projectHasChecklist chega como array de objetos [{check1: true},{check14: false}]
         *Abaixo filtra apenas os true, depois no map retira a string 'check' e converte para
         * inteiro o número que é o id do item checklist no banco
         */
        // projectHasChecklist
        const onlyItemsWithTrueChecklist = dataForm.projectHasChecklist.filter(
          // eslint-disable-next-line consistent-return
          (itemProjectHasChecklist: any) => {
            if (Object.values(itemProjectHasChecklist)[0]) {
              const idOfCheckInString = Object.keys(
                itemProjectHasChecklist,
              )[0].replace('check', '');
              return parseInt(idOfCheckInString, 10);
            }
          },
        );

        const itemsConvertedInIntChecklist = onlyItemsWithTrueChecklist.map(
          (itemProjectHasChecklist: any) => {
            const idOfCheckInString = Object.keys(
              itemProjectHasChecklist,
            )[0].replace('check', '');
            return parseInt(idOfCheckInString, 10);
          },
        );

        const itemsChecklistFinal =
          itemsConvertedInIntChecklist.length > 0
            ? [...itemsConvertedInIntChecklist]
            : [];
        // End projectHasChecklist

        const newDataForm = {
          id: parseInt(id, 10),
          projectHasMaterials: [...materialsFormatted],
          projectHasServices: [...servicesFormatted],
          projectHasManufacturingSteps: [...manufacturingStepsFormatted],
          userIdTechnicalValidator:
            dataForm.userIdTechnicalValidator !== ''
              ? dataForm.userIdTechnicalValidator
              : null,
          technicalValidatorDate: convertDateToDB(
            dataForm.technicalValidatorDate,
          ),
          userIdOperationalValidator:
            dataForm.userIdOperationalValidator !== ''
              ? dataForm.userIdOperationalValidator
              : null,
          operationalValidatorDate: convertDateToDB(
            dataForm.operationalValidatorDate,
          ),
          userIdProjectValidator:
            dataForm.userIdProjectValidator !== ''
              ? dataForm.userIdProjectValidator
              : null,
          projectValidatorDate: convertDateToDB(dataForm.projectValidatorDate),
          projectHasChecklist: itemsChecklistFinal,
          currentSector: dataForm.currentSector,
          finishedQuote: dataForm.finishedQuote === 'true',
          isFinished: dataForm.finishedProjectAndReleaseBilling === 'true',
        };

        await api.put('planning', newDataForm);

        addToast({
          type: 'success',
          title: 'Cadastro realizado com sucesso',
        });

        reset();
        setSaving(false);
        handleOpenModalChecklist();
        navigate('/planning');
      } catch (error: any) {
        setSaving(false);
        handleOpenModalChecklist();

        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);

          return;
        }

        if (error.response) {
          const { data } = error.response; // Error vindo do back está em data dentro de response

          addToast({
            type: 'error',
            title: 'Erro no cadastro',
            description: data.message,
          });
          if (
            formRef.current?.getFieldRef(data.field) &&
            data.field.toString().indexOf('_id') === -1
          ) {
            formRef.current?.getFieldRef(data.field).focus(); // Foca o campo que deu erro de acordo com o field retornado do back
          }

          return;
        }

        addToast({
          type: 'error',
          title: 'Error',
          description: 'Erro ao cadastrar!',
        });
      }
    },
    [
      addToast,
      getUpdateListCompleteAndUpdatedOfTheMaterials,
      getUpdateListCompleteAndUpdatedOfTheServices,
      handleOpenModalChecklist,
      navigate,
      id,
    ],
  );
  // End handleFormSubmit

  useEffect(() => {
    setLoading(true);
    const getMaterialsInitial = async (): Promise<void> => {
      getMaterials();
    };
    getMaterialsInitial();
  }, [addToast, getMaterials, getProject]);
  // End List Materials

  return (
    <>
      <Container>
        <ModalLoading visible={loading}>
          <p>
            <i>Carregando os dados do Planejamento...</i>
          </p>
        </ModalLoading>
        {typy(project.id).isNumber && (
          <Content>
            <Form ref={formRef} onSubmit={handleFormSubmit}>
              {/* <Split> */}
              <header>
                <p>Planejamento</p>
                <span>Nº do Projeto: {id}</span>
              </header>

              <HeadOpenProject>
                <HeadProject
                  listClients={listClients}
                  showInformationInMobile
                  readOnlyProp
                />
              </HeadOpenProject>

              <Tabs
                tabLinksProp={[
                  'Comercial',
                  'Planejamento',
                  'Compras',
                  'Produção',
                ]}
                tabDefaultIndex={1}
              >
                {/* Comercial */}
                <div>
                  <BodyCreateCommercial
                    formRef={formRef}
                    listDrawingsTheProjectProp={project.drawings}
                  />
                </div>
                {/* Planejamento */}
                <div>
                  <BodyPlanning
                    formRef={formRef}
                    listMaterialsProp={listMaterials}
                    listServicesProp={listServices}
                    listUsersProp={listUsers}
                    listUpdatedByServiceProp={listManufacturingSteps}
                    projectProp={project}
                    disableTabProp={finishedProposal || false}
                    showModalConfirmationOfExclusionMaterial={
                      showModalConfirmationOfExclusionMaterial
                    }
                    showModalConfirmationOfExclusionService={
                      showModalConfirmationOfExclusionService
                    }
                    refMaterial={handleMaterialComponentRef}
                    refService={handleServiceComponentRef}
                  />
                </div>
                {/* Compras */}
                <div>
                  <BodyPurchasing
                    formRef={formRef}
                    listMaterialsProp={listMaterials}
                    listProvidersProp={listProviders}
                    // listUpdatedByServiceProp={listManufacturingSteps}
                    projectProp={project}
                    disableTabProp
                  />
                </div>
                {/* Produção */}
                <div>
                  <BodyManufacturing
                    formRef={formRef}
                    projectProp={project}
                    listManufacturingStepsProp={
                      listManufacturingSteps !== undefined
                        ? listManufacturingSteps
                        : []
                    }
                    disableTabProp
                  />
                </div>
              </Tabs>

              <ChecklistProject
                listChecklistProp={listChecklist}
                loadingChecklistProp={loadingChecklist}
                projectHasChecklist={project.projectHasChecklist}
              />

              <SplitFooter>
                <SelectSectorAndButtonSave>
                  <SimpleSelect
                    id="currentSector"
                    name="currentSector"
                    labelText="Enviar projeto para o setor*:"
                    placeholder=""
                    options={currentSectorList.filter(
                      item => item.value !== 'Produção',
                    )}
                    getOptionLabel={option => option.value}
                    menuPlacement="auto"
                    isDisabled={saving}
                    disabled={saving}
                    // isDisabled={finishedProposal || saving}
                    // disabled={finishedProposal || saving}
                  />
                  <div>
                    {/* {!finishedProposal && ( */}
                    <Button
                      type="button"
                      onClick={verifyPermissions}
                      disabled={saving}
                    >
                      {saving ? <Loading size={24} color="white" /> : 'Salvar'}
                    </Button>
                    {/* )} */}
                  </div>
                </SelectSectorAndButtonSave>
              </SplitFooter>

              <ModalConfirmationOfExclusionMaterial
                loadModalProp={loadModalConfirmationOfExclusionMaterial}
                indexMaterial={indexMaterial}
                removeMaterial={removeMaterial}
                closeModalConfirmationOfExclusionMaterialProp={
                  closeModalConfirmationOfExclusionMaterial
                }
              />

              <ModalConfirmationOfExclusionService
                loadModalProp={loadModalConfirmationOfExclusionService}
                indexService={indexService}
                removeService={removeService}
                closeModalConfirmationOfExclusionServiceProp={
                  closeModalConfirmationOfExclusionService
                }
              />

              <InputHidden id="finishedQuote" name="finishedQuote" />
              <InputHidden
                id="finishedProjectAndReleaseBilling"
                name="finishedProjectAndReleaseBilling"
              />
              <ModalConfirmationFinishedQuote
                formRef={formRef}
                loadModalProp={openModalConfirmationFinishedQuote}
                closeModalFinishedQuote={
                  handleOpenModalConfirmationFinishedQuote
                }
              />

              <ModalConfirmationFinishedProjectAndReleaseBilling
                formRef={formRef}
                loadModalProp={openModalFinishedProjectAndReleaseBilling}
                closeModalFinishedProjectAndReleaseBilling={
                  handleOpenModalFinishedProjectAndReleaseBilling
                }
              />

              <ModalChecklist
                saving={saving}
                loadModalProp={openModalChecklist}
                listChecklistProp={listChecklist}
                projectHasChecklist={project.projectHasChecklist}
                closeModalChecklist={handleOpenModalChecklist}
              />
            </Form>
          </Content>
        )}
      </Container>
    </>
  );
};

export default CreateProject;
