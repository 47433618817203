/* eslint-disable react/jsx-curly-newline */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import React, {
  Ref,
  forwardRef,
  useEffect,
  useState,
  useCallback,
  useImperativeHandle,
  memo,
} from 'react';
import { FormHandles } from '@unform/core';
import fincalc from 'fincalc';
import typy from 'typy';
import { v4 as uuidv4 } from 'uuid';

import { MdExpandMore } from 'react-icons/md';

import Button from '../../../../../../components/Button';
import ScopeMaterialPlanning from './components/ScopeMaterialPlanning';
import PaginationAndSetInputsOnComponent from './components/PaginationAndSetInputsOnComponent';

import {
  SplitMaterials,
  HeaderAndGrandTotal,
  ActionAndGrandTotal,
  GrandTotal,
} from './styles';

import { IMaterialResponse } from '../../../../../../interfaces/material';
import { IProjectResponse } from '../../../../../../interfaces/project';
import convertNumberFloatToDB from '../../../../../../utils/convertNumberFloatToDB';
import convertNumberFloatToBr from '../../../../../../utils/convertNumberFloatToBr';

interface IMaterialForm extends IMaterialResponse {
  totalPrice: string;
  quantity: string;
  providerId: number;
}

interface IHandleMaterialsProps {
  formRef: any | FormHandles;
  listMaterials: Array<any>;
  projectProp: IProjectResponse;
  disableTabProp: boolean;
  showModalConfirmationOfExclusionMaterial: (
    indexMaterial: number,
    show: boolean,
  ) => void;
}

interface IRef {
  removeMaterial: any;
  getUpdateListCompleteAndUpdatedOfTheMaterials: any;
}

const HandleMaterials = forwardRef<IRef, IHandleMaterialsProps>(
  (
    {
      formRef,
      listMaterials,
      projectProp,
      disableTabProp = false,
      showModalConfirmationOfExclusionMaterial,
    }: IHandleMaterialsProps,
    ref: Ref<IRef>,
  ) => {
    const [listMaterialsTheProject, setListMaterialsTheProject] = useState<
      Array<any>
    >([]);

    const [listMaterialPagination, setListMaterialPagination] = useState<
      Array<IMaterialForm>
    >([]);

    const [
      listCompleteAndUpdatedOfTheMaterials,
      setListCompleteAndUpdatedOfTheMaterials,
    ] = useState<Array<IMaterialForm>>([]);

    const [grandTotal, setGrandTotal] = useState(0);

    const [disabled, setDisabled] = useState<boolean>(false);

    useEffect(() => {
      setDisabled(disableTabProp);
    }, [disableTabProp]);

    const getIndexOfTheMaterialToBeUpdated = useCallback(
      (item: any) => {
        if (
          Object.entries(item).length > 0 &&
          typy(parseInt(item.id.toString(), 10)).isNumber
        ) {
          const indexOfTheMaterialToBeUpdated = listCompleteAndUpdatedOfTheMaterials.findIndex(
            itemFind => itemFind.id.toString() === item.id.toString(),
          );

          return indexOfTheMaterialToBeUpdated;
        }
        if (
          item.idNewItemPagination &&
          item.idNewItemPagination !== '' &&
          item.idNewItemPagination !== null
        ) {
          const indexOfTheMaterialToBeUpdated = listCompleteAndUpdatedOfTheMaterials.findIndex(
            (itemFind: any) =>
              itemFind.idNewItemPagination === item.idNewItemPagination,
          );

          return indexOfTheMaterialToBeUpdated;
        }

        return undefined;
      },
      [listCompleteAndUpdatedOfTheMaterials],
    );

    const handleUpdateListCompleteAndUpdatedOfTheMaterials = useCallback(
      (currentFormDataHasMaterialsOnPageActual: any): Array<IMaterialForm> => {
        const listCompleteUpdated: Array<any> = [
          ...listCompleteAndUpdatedOfTheMaterials,
        ];

        currentFormDataHasMaterialsOnPageActual.map(
          // eslint-disable-next-line array-callback-return
          (item: any): void => {
            const indexOfTheMaterialToBeUpdated =
              listCompleteUpdated.length === 1
                ? 0
                : getIndexOfTheMaterialToBeUpdated(item);

            if (indexOfTheMaterialToBeUpdated !== undefined)
              listCompleteUpdated[indexOfTheMaterialToBeUpdated] = item;
          },
        );

        return listCompleteUpdated;
      },
      [getIndexOfTheMaterialToBeUpdated, listCompleteAndUpdatedOfTheMaterials],
    );

    const [goToLastPage, setGoToLastPage] = useState(false);

    const handleGoToLastPage = useCallback(() => {
      // setGoToLastPage(!goToLastPage);
      setGoToLastPage(false);
    }, []);

    const addNewMaterial = useCallback(() => {
      const currentProjectHasMaterials = formRef.current.getData()
        .projectHasMaterials;

      const listMaterialsUpdated = [
        ...handleUpdateListCompleteAndUpdatedOfTheMaterials(
          currentProjectHasMaterials,
        ),
      ];

      setListMaterialsTheProject([
        ...listMaterialsUpdated,
        {
          id: null,
          idNewItemPagination: uuidv4(),
          providerId: null,
          materialId: null,
          quantity: 0,
          unitOfMeasurement: '',
          totalPrice: 0,
          observation: '',
          unitPrice: 0,
        },
      ]);

      setGoToLastPage(true);
    }, [formRef, handleUpdateListCompleteAndUpdatedOfTheMaterials]);

    const removeMaterial = useCallback(
      (index: number) => {
        if (formRef && formRef.current) {
          const currentProjectHasMaterials = formRef.current.getData()
            .projectHasMaterials;

          if (currentProjectHasMaterials.length > 0) {
            const listMaterialsUpdated = [
              ...handleUpdateListCompleteAndUpdatedOfTheMaterials(
                currentProjectHasMaterials,
              ),
            ];

            const indexItemOfTheListCompleted = getIndexOfTheMaterialToBeUpdated(
              currentProjectHasMaterials[index],
            );

            if (listMaterialsUpdated.length > 1) {
              if (indexItemOfTheListCompleted !== undefined) {
                listMaterialsUpdated.splice(indexItemOfTheListCompleted, 1);
                setListMaterialsTheProject(listMaterialsUpdated);

                // Leva para última página caso a página atual não tenha mais items
                if (currentProjectHasMaterials.length === 1)
                  setGoToLastPage(true);
              }
            } else {
              setListMaterialsTheProject([
                {
                  id: null,
                  idNewItemPagination: uuidv4(),
                  providerId: null,
                  materialId: null,
                  quantity: 0,
                  unitOfMeasurement: '',
                  totalPrice: 0,
                  observation: '',
                  unitPrice: 0,
                },
              ]);

              const inputRefQuantity = formRef.current?.getFieldRef(
                `projectHasMaterials[0].quantity`,
              );
              const inputRefUnitOfMeasurement = formRef.current?.getFieldRef(
                `projectHasMaterials[0].unitOfMeasurement`,
              );

              formRef.current?.clearField(`projectHasMaterials[0].name`);
              formRef.current?.clearField(`projectHasMaterials[0].quantity`);

              inputRefQuantity.style.color = 'var(--cinza-claro-5bits)';
              inputRefQuantity.parentNode.style.filter = 'brightness(0.9)';
              inputRefQuantity.readOnly = true;

              inputRefUnitOfMeasurement.style.color =
                'var(--cinza-claro-5bits)';
              inputRefUnitOfMeasurement.parentNode.style.filter =
                'brightness(0.9)';

              formRef.current?.clearField(
                `projectHasMaterials[0].unitOfMeasurement`,
              );
              formRef.current?.clearField(`projectHasMaterials[0].unitPrice`);
              formRef.current?.clearField(`projectHasMaterials[0].totalPrice`);
              formRef.current?.clearField(`projectHasMaterials[0].observation`);

              setGrandTotal(0);
            }
          }
        }
      },
      [
        formRef,
        getIndexOfTheMaterialToBeUpdated,
        handleUpdateListCompleteAndUpdatedOfTheMaterials,
      ],
    );

    const [showMaterials, setShowMaterials] = useState(false);

    const showSectionMaterials = useCallback(() => {
      const sectionMaterials: HTMLElement | null = document.querySelector(
        `#materialsArea`,
      );
      const iconExpandMore: HTMLElement | null = document.querySelector(
        `#iconExpandMoreMaterials`,
      );
      const grandTotalInHeader: HTMLElement | null = document.querySelector(
        `#grandTotalInHeaderMaterial`,
      );

      if (
        sectionMaterials !== null &&
        sectionMaterials !== undefined &&
        grandTotalInHeader !== null &&
        grandTotalInHeader !== undefined
      ) {
        if (showMaterials === true) {
          sectionMaterials.style.display = 'block';
          grandTotalInHeader.style.display = 'none';

          if (iconExpandMore !== null && iconExpandMore !== undefined) {
            iconExpandMore.style.transform = 'rotate(180deg)';
            iconExpandMore.style.transitionDuration = '0.3s';
          }
        } else {
          sectionMaterials.style.display = 'none';
          grandTotalInHeader.style.display = 'block';

          if (iconExpandMore !== null && iconExpandMore !== undefined) {
            iconExpandMore.style.transform = 'rotate(0deg)';
            iconExpandMore.style.transitionDuration = '0.3s';
          }
        }
      }

      setShowMaterials(!showMaterials);
    }, [showMaterials]);

    const sumGrandTotal = useCallback(() => {
      if (formRef && formRef.current) {
        const currentFormData = formRef.current.getData();

        const currentFormDataHasMaterialsOnPageActual =
          currentFormData.projectHasMaterials;

        if (
          listCompleteAndUpdatedOfTheMaterials &&
          listCompleteAndUpdatedOfTheMaterials.length > 0 &&
          currentFormDataHasMaterialsOnPageActual &&
          currentFormDataHasMaterialsOnPageActual.length > 0
        ) {
          const listCompleteUpdated = [
            ...handleUpdateListCompleteAndUpdatedOfTheMaterials(
              currentFormDataHasMaterialsOnPageActual,
            ),
          ];

          const currentProjectHasMaterials = [...listCompleteUpdated];

          const totalGeral = currentProjectHasMaterials.reduce(
            (sum: number, currentItem: any) => {
              // eslint-disable-next-line no-return-assign
              return fincalc(
                (sum += convertNumberFloatToDB(currentItem.totalPrice)),
              );
            },
            0,
          );

          setGrandTotal(totalGeral);
        }
      }
    }, [
      formRef,
      handleUpdateListCompleteAndUpdatedOfTheMaterials,
      listCompleteAndUpdatedOfTheMaterials,
    ]);

    const clearFields = useCallback(
      (newListPagination): void => {
        newListPagination.newListPageActual.map(
          // eslint-disable-next-line array-callback-return
          (item: any, index: number): void => {
            formRef.current.clearField(`projectHasMaterials[${index}].id`);
            formRef.current.clearField(
              `projectHasMaterials[${index}].idNewItemPagination`,
            );
            formRef.current.clearField(
              `projectHasMaterials[${index}].providerId`,
            );
            formRef.current.clearField(
              `projectHasMaterials[${index}].materialId`,
            );
            formRef.current.clearField(
              `projectHasMaterials[${index}].observation`,
            );
            formRef.current.clearField(
              `projectHasMaterials[${index}].quantity`,
            );
            formRef.current.clearField(
              `projectHasMaterials[${index}].totalPrice`,
            );
            formRef.current.clearField(
              `projectHasMaterials[${index}].unitOfMeasurement`,
            );
            formRef.current.clearField(
              `projectHasMaterials[${index}].unitPrice`,
            );

            setGrandTotal(0);
          },
        );
      },
      [formRef],
    );

    const handleMaterialsPagination = useCallback(
      newListPagination => {
        clearFields(newListPagination);

        setListMaterialPagination(newListPagination.newListPageActual);
        setListCompleteAndUpdatedOfTheMaterials(
          newListPagination.newListCompleteUpdated,
        );
      },
      [clearFields],
    );

    useEffect(() => {
      // formRef.current.setData({
      //   ...formRef.current.getData(),
      //   projectHasMaterials: [...listMaterialPagination],
      // });

      // eslint-disable-next-line array-callback-return
      listMaterialPagination.map((item: any, index: number): void => {
        formRef.current.setFieldValue(
          `projectHasMaterials[${index}].id`,
          item.id,
        );
        formRef.current.setFieldValue(
          `projectHasMaterials[${index}].idNewItemPagination`,
          item.idNewItemPagination,
        );
        formRef.current.setFieldValue(
          `projectHasMaterials[${index}].providerId`,
          item.providerId,
        );
        formRef.current.setFieldValue(
          `projectHasMaterials[${index}].materialId`,
          item.materialId,
        );
        formRef.current.setFieldValue(
          `projectHasMaterials[${index}].quantity`,
          item.quantity,
        );
        formRef.current.setFieldValue(
          `projectHasMaterials[${index}].unitPrice`,
          item.unitPrice,
        );
        formRef.current.setFieldValue(
          `projectHasMaterials[${index}].unitOfMeasurement`,
          item.unitOfMeasurement,
        );
        formRef.current.setFieldValue(
          `projectHasMaterials[${index}].totalPrice`,
          item.totalPrice,
        );
        formRef.current.setFieldValue(
          `projectHasMaterials[${index}].observation`,
          item.observation,
        );
      });

      sumGrandTotal();
      /**
       * Verifica se há ao menos um item com materialId. Caso não tenha
       * a função sumGrandTotal deve ser chamada. Isso porque se a página 2
       * por exemplo não tiver items os itens da página 1 não serão somados
       * já que a função sumGrandTotal estava sendo chamada apenas no onChange
       * do select de Materials que depende do materialId.
       */
      // const existMaterialId: boolean = listMaterialPagination.some(
      //   (material: any) => {
      //     return typy(parseInt(material.materialId, 10)).isNumber;
      //   },
      // );

      // if (disableTabProp || existMaterialId === false) {

      //   sumGrandTotal();
      // }
    }, [disableTabProp, formRef, listMaterialPagination, sumGrandTotal]);

    useEffect(() => {
      if (
        projectProp &&
        projectProp.projectHasMaterials &&
        projectProp.projectHasMaterials.length > 0
      ) {
        setListMaterialsTheProject(projectProp.projectHasMaterials);
        showSectionMaterials();
      } else {
        setListMaterialsTheProject([
          {
            id: null,
            idNewItemPagination: uuidv4(),
            providerId: null,
            materialId: null,
            quantity: 0,
            unitOfMeasurement: '',
            totalPrice: 0,
            observation: '',
            unitPrice: 0,
          },
        ]);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectProp]);

    /**
     * A função getUpdateListCompleteAndUpdatedOfTheMaterials é chamada
     * na Create de planning e pega a list completa e atualizada para
     * serem usados e enviados na função de Submit, já que a paginação
     * não contém todos os item ao pegá-los por formRef.current.data.
     * * A troca de informações bidirecionais é feita pelo useImperativeHandle.
     */
    const getUpdateListCompleteAndUpdatedOfTheMaterials = useCallback((): Array<any> => {
      const currentProjectHasMaterials = formRef.current.getData()
        .projectHasMaterials;

      const listMaterialsUpdated = [
        ...handleUpdateListCompleteAndUpdatedOfTheMaterials(
          currentProjectHasMaterials,
        ),
      ];

      return listMaterialsUpdated;
    }, [formRef, handleUpdateListCompleteAndUpdatedOfTheMaterials]);

    useImperativeHandle(ref, () => ({
      removeMaterial,
      getUpdateListCompleteAndUpdatedOfTheMaterials,
    }));

    return (
      <SplitMaterials>
        <HeaderAndGrandTotal
          onClick={showSectionMaterials}
          data-id="materialsArea"
        >
          <span>
            Materiais
            <MdExpandMore id="iconExpandMoreMaterials" size={30} />
          </span>
          <GrandTotal
            id="grandTotalInHeaderMaterial"
            style={{ display: 'none' }}
          >
            {(projectProp.finishedQuote && (
              <>
                Total Geral:{' '}
                <span>R$ {convertNumberFloatToBr(grandTotal)}</span>
              </>
            )) || (
              <>
                <strong style={{ color: 'var(--laranja-5bits)' }}>
                  Atenção:
                </strong>{' '}
                Total Parcial:{' '}
                <strong>R$ {convertNumberFloatToBr(grandTotal)}</strong>
              </>
            )}
          </GrandTotal>
        </HeaderAndGrandTotal>
        <section id="materialsArea">
          <ScopeMaterialPlanning
            formRef={formRef}
            listMaterials={listMaterials}
            listMaterialPlanningPagination={listMaterialPagination}
            removeMaterial={removeMaterial}
            sumGrandTotal={sumGrandTotal}
            showModalConfirmationOfExclusionMaterial={
              showModalConfirmationOfExclusionMaterial
            }
            disabled={disabled}
          />

          <ActionAndGrandTotal>
            <div>
              {!disabled && (
                <Button
                  name="newMaterial"
                  type="button"
                  onClick={e => {
                    e.preventDefault();
                    addNewMaterial();
                  }}
                >
                  Mais material
                </Button>
              )}
            </div>

            <GrandTotal>
              {(projectProp.finishedQuote && (
                <>
                  Total Geral:{' '}
                  <span>R$ {convertNumberFloatToBr(grandTotal)}</span>
                </>
              )) || (
                <>
                  <strong style={{ color: 'var(--laranja-5bits)' }}>
                    Atenção:
                  </strong>{' '}
                  Total Parcial:{' '}
                  <strong>R$ {convertNumberFloatToBr(grandTotal)}</strong>
                </>
              )}
            </GrandTotal>
          </ActionAndGrandTotal>
          <PaginationAndSetInputsOnComponent
            formRef={formRef}
            listItems={listMaterialsTheProject}
            perPageProp={10}
            goToLastPage={goToLastPage}
            onChangeListPaginatedItems={handleMaterialsPagination}
            handleGoToLastPage={handleGoToLastPage}
          />
        </section>
      </SplitMaterials>
    );
  },
);
export default memo(HandleMaterials);
