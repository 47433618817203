/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-param-reassign */
import React, {
  ChangeEvent,
  useRef,
  useEffect,
  useCallback,
  useState,
} from 'react';
import { useField } from '@unform/core';
import { FiAlertCircle } from 'react-icons/fi';
import Button from '../Button';
import Loading from '../Loading';

import Error from '../../styles/errorFieldGlobal';
import { Container, Content, ButtonAndIcon } from './styles';

interface Props {
  id: string;
  name: string;
}
// eslint-disable-next-line no-undef
type InputProps = JSX.IntrinsicElements['input'] & Props;

export default function InputFileButton({ id, name, ...rest }: InputProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [loadingFile, setLoadingFile] = useState<boolean>(false);
  const [buttonClicked, setButtonClicked] = useState<string>('');

  const {
    fieldName,
    registerField,
    clearError,
    defaultValue,
    error,
  } = useField(name);

  const [preview, setPreview] = useState(defaultValue);

  const handlePreview = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0];
      if (!file) {
        setPreview(null);
      }
      // const previewURL = URL.createObjectURL(file);
      const previewURL = file;
      setPreview(previewURL);
      if (error) {
        clearError();
      }
    },
    [clearError, error],
  );

  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'files[0]',
      clearValue(ref: HTMLInputElement) {
        ref.value = '';
        setPreview(null);
      },
      setValue(_: HTMLInputElement, value: any) {
        if (value) {
          setPreview(value);
          // Não é possível alterar um campo input file, por isso cria-se um novo desenho
          const list = new DataTransfer();

          list.items.add(value);
          _.files = list.files;
        }
      },
    });
  }, [fieldName, registerField]);

  const handleInputFocus = useCallback((): void => {
    setIsFocused(true);
    clearError();
  }, [clearError]);

  const handleOpenFile = useCallback(
    (nameButtonClicked: string) => {
      setLoadingFile(true);
      setButtonClicked(nameButtonClicked);

      const fileName = preview;

      const reader = new FileReader();
      reader.readAsDataURL(fileName);
      const extensionFile = fileName?.name.substring(
        fileName.name.lastIndexOf('.') + 1,
      );

      let typeBlob = '';

      switch (extensionFile) {
        case 'pdf':
          typeBlob = `application/${extensionFile}`;
          break;
        case 'tiff':
          typeBlob = `image/${extensionFile}`;
          break;
        default:
          typeBlob = `image/${extensionFile}`;
      }
      const url = window.URL.createObjectURL(
        new Blob([preview], { type: typeBlob }),
      );

      window.open(url);
      setLoadingFile(false);
      setButtonClicked('');
    },
    [preview],
  );

  return (
    <Container>
      <Content>
        {(preview && (
          <>
            {(loadingFile && buttonClicked === `openFile${id}` && (
              <Button
                name="buttonSpinner"
                type="button"
                onClick={e => {
                  e.preventDefault();
                }}
              >
                <Loading size={24} color="white" />
              </Button>
            )) || (
              <ButtonAndIcon>
                <Button
                  name={`openFile${id}`}
                  onClick={() => handleOpenFile(`openFile${id}`)}
                >
                  Exibir
                </Button>
              </ButtonAndIcon>
            )}
          </>
        )) || (
          <ButtonAndIcon>
            <Button
              id="btLoadNewDrawing"
              name="btLoadNewDrawing"
              type="button"
              onClick={() => {
                document.getElementById(`${id}-${name}`)?.click();
              }}
            >
              Carregar...
            </Button>
          </ButtonAndIcon>
        )}
        <input
          id={`${id}-${name}`}
          type="file"
          ref={inputRef}
          onChange={handlePreview}
          {...rest}
        />
      </Content>
      {error && (
        <Error>
          <FiAlertCircle color="var(--on-errored-message-bg-color)" size={20} />
          {error}
        </Error>
      )}
    </Container>
  );
}
