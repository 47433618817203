import { format, parseISO } from 'date-fns';

function convertDateAndHoursToDB(date: Date): string {
  if (date && (typeof date === 'string' || typeof date === 'object')) {
    const regexp = new RegExp(
      /^\d{4}(-)(((0)[0-9])|((1)[0-2]))(-)([0-2][0-9]|(3)[0-1])/i,
    );

    if (typeof date === 'object') {
      const dateFormatted = format(date, 'yyyy-MM-dd HH:mm');

      if (!regexp.test(dateFormatted)) {
        throw new Error('Erro na conversão da data');
      }

      return dateFormatted;
    }
  }
  return '';
}

export default convertDateAndHoursToDB;
