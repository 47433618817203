/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/require-default-props */
import React, { useRef, useState, useEffect, useCallback } from 'react';
import ReactDatePicker, {
  ReactDatePickerProps,
  registerLocale,
  setDefaultLocale,
} from 'react-datepicker';
import pt from 'date-fns/locale/pt';

import { useField } from '@unform/core';

import 'react-datepicker/dist/react-datepicker.css';

import { FiAlertCircle } from 'react-icons/fi';

import { IconBaseProps } from 'react-icons';

import Error from '../../styles/errorFieldGlobal';
import { Container, Content } from './styles';

interface Props extends Omit<ReactDatePickerProps, 'onChange'> {
  name: string;
  labelText?: string;
  dateFormatProp?: string;
  icon?: React.ComponentType<IconBaseProps>;
}

registerLocale('pt', pt);
setDefaultLocale('pt');
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export default function DatePicker({
  name,
  labelText,
  dateFormatProp = 'dd/MM/yyyy',
  icon: Icon,
  disabled,
  ...rest
}: Props) {
  const datepickerRef = useRef(null);
  // eslint-disable-next-line no-unused-vars
  const {
    fieldName,
    registerField,
    defaultValue,
    error,
    clearError,
  } = useField(name);

  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [isFilled, setIsFilled] = useState<boolean>(false);

  const [date, setDate] = useState(defaultValue || null);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: datepickerRef.current,
      path: 'props.selected',
      setValue: (ref: any, value) => {
        setDate(value);
      },
      clearValue: (ref: any) => {
        ref.clear();
      },
    });
  }, [fieldName, registerField]);

  const handleInputFocus = useCallback((): void => {
    setIsFocused(true);
    // Função do unform para limpar os erros do campo
    clearError();
  }, [clearError]);

  const handleChange = (dateValue: any): void => {
    setDate(dateValue);
    if (error) {
      clearError();
    }
  };

  return (
    <Container>
      {labelText && <label htmlFor={`id-${name}`}>{labelText}</label>}
      <Content
        isErrored={!!error}
        isFocused={isFocused}
        isFilled={isFilled}
        isDisabled={!!disabled}
      >
        {Icon && <Icon size={20} />}
        <ReactDatePicker
          ref={datepickerRef}
          dateFormat={dateFormatProp}
          selected={date}
          onChange={handleChange}
          onFocus={handleInputFocus}
          locale="pt"
          disabled={!!disabled}
          autoComplete="off"
          popperPlacement="top"
          {...rest}
        />
      </Content>
      {error && (
        <Error>
          <FiAlertCircle color="var(--on-errored-message-bg-color)" size={20} />
          {error}
        </Error>
      )}
    </Container>
  );
}
