import styled, { css } from 'styled-components';

interface ContentProps {
  isFocused: boolean;
  isFilled: boolean;
  isErrored: boolean;
  isDisabled: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  color: var(--cinza-escuro-5bits);

  label {
    font-weight: 700;
    font-size: 1.4rem;
    line-height: 2.4rem;
    text-align: center;
  }
`;

export const Content = styled.div<ContentProps>`
  background: var(--input-bg-color);
  border-radius: 25px 0 0 25px;
  border: 1px solid var(--input-border-color);
  padding: 4px 20px;
  width: 100%;
  height: 32px;
  color: var(--input-text-color);

  display: flex;
  align-items: center;
  justify-content: center;

  & + div {
    margin-top: 8px;
  }

  ${props =>
    props.isDisabled &&
    css`
      filter: brightness(0.9);

      &:hover {
        filter: brightness(0.9);
      }
    `}

  ${props =>
    props.isErrored &&
    css`
      border-color: var(--on-errored-input-border-color);
    `}

  ${props =>
    props.isFocused &&
    css`
      color: var(--on-focus-input-text-color);
      border-color: var(--on-focus-input-border-color);
    `}

  ${props =>
    props.isFilled &&
    css`
      color: var(--on-filled-input-text-color);
      border-width: 1px;
    `}


  input {
    flex: 1;
    background-color: var(--input-bg-color);
    border: 0;

    ${props =>
      (props.isDisabled &&
        css`
          color: var(--cinza-claro-5bits);
          font-weight: 500;
        `) ||
      css`
        color: var(--input-text-color);
        font-weight: 500;
      `}

    &::placeholder {
      color: var(--input-placeholder-color);
    }

    &:disabled {
      cursor: not-allowed;
    }
  }

  svg {
    margin-right: 16px;
  }

  //Medias
  @media (min-width: 0px) and (max-width: 759px) {
    width: 100%;
    padding: 0 1rem;
    svg {
      display: none;
    }
  }

  @media (min-width: 760px) and (max-width: 999px) {
  }
`;

export const ButtonSearch = styled.button`
  position: relative;
  background: var(--button-bg-color);
  height: 32px;
  border-radius: 0 25px 25px 0;
  border: 0;
  padding: 0 22.5px;
  color: var(--button-text-color);
  width: 100%;
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 2.4rem;
  text-align: center;
  text-transform: uppercase;
  transition: background-color 0.2s;

  display: flex;
  justify-content: center;
  align-items: center;

  &:disabled {
    filter: brightness(0.8);
    cursor: not-allowed;

    &:hover {
      filter: brightness(0.8);
    }
  }

  &:hover {
    filter: brightness(0.9);
  }

  svg {
    display: none;
  }

  //Medias
  @media (min-width: 0px) and (max-width: 759px) {
    width: fit-content;
    padding: 0 12px;

    span {
      display: none;
    }

    svg {
      display: block;
    }
  }

  @media (min-width: 760px) and (max-width: 999px) {
  }
`;
