import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import { IconBaseProps } from 'react-icons';
import { useField } from '@unform/core';
import { FiAlertCircle } from 'react-icons/fi';

import convertToNumber from '../../utils/convertToNumber';

import Error from '../../styles/errorFieldGlobal';
import { Container, Content } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  labelText?: string;
  icon?: React.ComponentType<IconBaseProps>;
}

const InputPhone: React.FC<InputProps & { autocomplete?: string }> = ({
  name,
  labelText,
  icon: Icon,
  disabled,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const {
    fieldName,
    defaultValue,
    error,
    clearError,
    registerField,
  } = useField(name);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [isFilled, setIsFilled] = useState<boolean>(false);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);

    if (inputRef.current) {
      setIsFilled(!!inputRef.current.value);
    }
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  const handleChange = useCallback(
    // eslint-disable-next-line consistent-return
    e => {
      if (error) {
        clearError();
      }

      if (inputRef.current) {
        let valueInput = convertToNumber(inputRef.current.value);
        const valueLength = valueInput.length;

        // Se digitar mais que 11 dígitos pegar só os primeiros 11 e formata
        if (valueLength > 11) {
          e.preventDefault();
          valueInput = valueInput.substring(0, 11);
        }

        if (valueLength <= 2) {
          // Se não tiver esse if não deixa apagar os parenteses()
          inputRef.current.value = `${valueInput}`;
        }
        // Gera a primeira parte da máscara (XX) XXXX
        else if (valueLength < 7) {
          inputRef.current.value = `(${valueInput.substring(
            0,
            2,
          )}) ${valueInput.substring(2, valueLength)}`;
        }
        // Gera a segunda parte da máscara (XX) XXXX-XXXX
        else if (valueLength > 5 && valueLength <= 10) {
          inputRef.current.value = `(${valueInput.substring(
            0,
            2,
          )}) ${valueInput.substring(2, 6)}-${valueInput.substring(
            6,
            valueLength,
          )}`;
        }
        // Gera a máscara para celular parte da máscara (XX) X XXXX-XXXX
        else if (valueLength > 5 && valueLength >= 11) {
          inputRef.current.value = `(${valueInput.substring(
            0,
            2,
          )}) ${valueInput.substring(2, 3)} ${valueInput.substring(
            3,
            7,
          )}-${valueInput.substring(7, valueLength)}`;
        }
      }
    },
    [clearError, error],
  );

  return (
    <Container>
      {labelText && <label htmlFor={`id-${name}`}>{labelText}</label>}
      <Content
        isErrored={!!error}
        isFocused={isFocused}
        isFilled={isFilled}
        isDisabled={!!disabled}
      >
        {Icon && <Icon size={20} />}
        <input
          id={`id-${name}`}
          onFocus={(): void => setIsFocused(true)}
          onBlur={handleInputBlur}
          defaultValue={defaultValue}
          ref={inputRef}
          type="text"
          maxLength={16}
          onChange={e => handleChange(e)}
          disabled={disabled}
          {...rest}
        />
      </Content>
      {error && (
        <Error>
          <FiAlertCircle color="var(--on-errored-message-bg-color)" size={20} />
          {error}
        </Error>
      )}
    </Container>
  );
};

export default InputPhone;
