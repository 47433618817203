import styled, { css } from 'styled-components';

import expandMore from '../../../../../../assets/expandMoreGray24px.svg';

interface IHistoric {
  visibleHistoricTableProp: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  width: 91vw;
  height: 100%;
  /* max-height: 80vh; */
  padding: 3rem 3rem;
  background-color: var(--branco-puro-5bits);

  h1 {
    text-transform: none;
    font-weight: 500;
  }
`;

export const ModalLoadingForModal = styled.div`
  /* Modal loading */
  > div:first-child {
    position: absolute;
    width: 91vw;
    height: 100%;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  height: 71vh;
  max-height: 100%;
  width: 100%;

  overflow: auto;
`;

export const TitleAndOSNumber = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
  height: 8.2rem;

  border-bottom: 1px solid var(--cinza-claro-5bits);

  span {
    font-style: normal;
    font-size: 2.4rem;
    line-height: 3.2rem;
  }
`;

export const Title = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  width: fit-content;

  > span:nth-child(2) {
    font-weight: bold;
    text-transform: uppercase;
    color: var(--azul-5bits);
  }
`;

export const OSNumber = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  width: fit-content;

  > span:nth-child(1) {
    margin: 0 0.5rem;
    font-weight: 500;
    color: var(--cinza-escuro-5bits);
  }

  > span:nth-child(2) {
    font-weight: bold;
    color: var(--laranja-5bits);
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;

  padding: 2rem 0;

  border-bottom: 1px solid var(--cinza-claro-5bits);
`;

export const HeaderTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;

  > div {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    width: fit-content;

    font-style: normal;
    font-size: 24px;
    line-height: 32px;
    color: var(--azul-5bits);

    span {
      font-weight: 500;

      > span {
        font-weight: bold;
      }
    }
  }

  /* Etapa */
  > div:first-child {
    justify-content: flex-start;

    padding-right: 2rem;
    border-right: 1px solid var(--cinza-claro-5bits);

    span {
      > span {
        text-transform: uppercase;
      }
    }
  }

  /* Tempo planejado */
  > div:last-child {
    justify-content: flex-start;
    padding-left: 2rem;
  }
`;

export const HeaderInformation = styled.div`
  width: 100%;

  margin-top: 2rem;

  > div {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    width: 100%;

    font-style: normal;
    font-size: 16px;
    line-height: 21px;
    color: var(--cinza-escuro-5bits);

    span {
      font-weight: 500;

      > span {
        font-weight: bold;
        text-transform: uppercase;
      }
    }
  }
`;

export const JustifyContainer = styled.div`
  width: 100%;
  height: fit-content;

  padding: 2rem 0;
  margin-bottom: 2.2rem;

  > span {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    color: var(--azul-5bits);
  }
`;

export const JustifyContent = styled.div`
  form {
    display: flex;

    width: 100%;
    margin-bottom: 2.2rem;

    > div {
      width: 50%;

      > div {
        label {
          margin-left: 2rem;
        }
      }
    }
  }
`;

export const TextareaContainer = styled.div`
  height: 18rem;
  > div {
    height: 100%;
    /*div do textArea. Quando há um erro a nth-child 2 é a div do erro*/
    > div:first-child {
      height: 100%;
      textarea {
        height: 100%;
      }
    }
  }
`;

export const DateAndTimeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  > div {
    width: 63%;
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;

  padding: 2rem 0 4.5rem;
`;

export const ActionsButtons = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  width: 100%;

  > button {
    width: 17.2rem;
    height: 3.7rem;

    margin-right: 3.1rem;

    svg {
      margin-right: 1rem;
    }
  }
`;

export const ActionCloseModal = styled.div`
  position: absolute;
  top: 1.3rem;
  right: 1.3rem;
  cursor: pointer;

  > svg {
    path {
      stroke: var(--azul-5bits);
    }
  }
`;
