import styled, { css, keyframes } from 'styled-components';

interface IProps {
  showInformation: boolean;
}

const rotateUp = keyframes`
  0% {
    transform: rotate(-225deg);
  }
  100% {
    transform: rotate(-45deg);
  }
`;
const rotateDown = keyframes`
  0% {
    transform: rotate(-45deg);
  }
  100% {
    transform: rotate(-225deg);
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;

  margin-top: 1.9rem;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 92%;

  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    header {
      width: 100%;
      font-weight: 500;
      font-size: 2.4rem;
      line-height: 3.2rem;

      /* margin-left: 1rem; */
      margin-bottom: 1.6875rem;
      /* padding-right: 4.6rem; */

      > span {
        color: var(--cinza-claro-5bits);
      }
    }
  }
`;

export const HeadOpenProject = styled.div<IProps>`
  width: 100%;
  padding-bottom: 2.6rem;
  margin-bottom: 3rem;
  border-bottom: 0.1rem solid var(--cinza-claro-5bits);

  //Medias
  /* @media (min-width: 0px) and (max-width: 759px) {
    ${props =>
    (props.showInformation &&
      css`
        > header a {
          color: var(--azul-5bits);
        }

        > header a::after {
          animation: ${rotateUp} 0.5s forwards;
        }
      `) ||
    css`
      > header a {
        color: var(--cinza-escuro-5bits);
      }

      > header a::after {
        animation: ${rotateDown} 0.5s forwards;
      }
    `}
  }

  @media (min-width: 760px) and (max-width: 999px) {
  } */
`;

export const TechnicalDrawings = styled.div<IProps>`
  width: 100%;
  margin-top: 3.125rem;
  border-bottom: 0.1rem solid var(--cinza-claro-5bits);

  //Medias
  @media (min-width: 0px) and (max-width: 759px) {
    margin-top: 2rem;

    ${props =>
      (props.showInformation &&
        css`
          > header a {
            color: var(--azul-5bits);
          }

          > header a::after {
            animation: ${rotateUp} 0.5s forwards;
          }
        `) ||
      css`
        > header a {
          color: var(--cinza-escuro-5bits);
        }

        > header a::after {
          animation: ${rotateDown} 0.5s forwards;
        }
      `}
  }

  @media (min-width: 760px) and (max-width: 999px) {
  }
`;

export const SectionTitle = styled.header`
  width: 100%;
  font-weight: 500;
  font-size: 2.4rem;
  line-height: 3.2rem;

  margin-bottom: 1.6875rem;

  a {
    text-decoration: none;
    cursor: default;
    /*O clique do mouse não tem evento em desktop*/
    pointer-events: none;
  }

  //Medias
  @media (min-width: 0px) and (max-width: 759px) {
    margin-bottom: 2rem;

    > a {
      display: flex;
      justify-content: space-between;
      align-items: center;
      /*O clique do mouse tem evento de ancora em mobile. O auto restaura os eventos ao normal*/
      pointer-events: auto;
    }

    > a::after {
      content: '';
      display: block;
      width: 1.1rem;
      height: 1.1rem;
      border-style: solid;
      border-width: 0.3rem 0.3rem 0 0;
      border-color: var(--cinza-escuro-5bits);

      /* transform-origin: center; */
      animation: ${rotateDown} 0.5s forwards;
    }
  }

  @media (min-width: 760px) and (max-width: 999px) {
  }
`;

export const SplitTechnicalDrawings = styled.div<IProps>`
  display: flex;
  flex-direction: column;
  gap: 2.8rem;

  width: 100%;
  padding-bottom: 3.125rem;

  > button:last-child {
    width: fit-content;
    background: var(--azul-5bits);
    font-style: normal;
    font-weight: bold;
    font-size: 1.4rem;
    text-transform: none;
    line-height: 1.9rem;

    padding: 1rem 5.75rem;
  }

  //Medias
  @media (min-width: 0px) and (max-width: 759px) {
    overflow: hidden;
    border-bottom: 0.1rem solid var(--cinza-claro-5bits);

    ${props =>
      (props.showInformation &&
        css`
          min-height: 30rem;
          height: fit-content;
          max-height: fit-content;
          visibility: visible;
          opacity: 1;
          transition: opacity 2s, height 0.5s linear;
        `) ||
      css`
        height: 0;
        visibility: hidden;
        opacity: 0;
        padding: 0;
        transition: opacity 2s, visibility 2s 2s linear, height 0.5s linear;
      `}
  }

  @media (min-width: 760px) and (max-width: 999px) {
  }
`;

// export const SplitTechnicalDrawings = styled.div`
//   display: flex;
//   flex-direction: column;

//   width: 100%;
//   padding-top: 3.125rem;
//   padding-bottom: 3.125rem;
//   border-bottom: 0.1rem solid var(--cinza-claro-5bits);

//   > button:last-child {
//     width: fit-content;
//     background: var(--azul-5bits);
//     font-style: normal;
//     font-weight: bold;
//     font-size: 1.4rem;
//     text-transform: none;
//     line-height: 1.9rem;

//     padding: 1rem 5.75rem;
//   }
// `;

export const SplitDrawing = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  gap: 1.4rem;

  width: 100%;

  > div {
    flex: 1;
    min-width: 30rem;

    label {
      margin-left: 1rem;
    }
  }

  /*
    * RevisionNumberAndRevisionDateAndFile este seletor sobrepõe o css
    * do item definido abaixo.
  */
  > div:last-child {
    min-width: 39.1rem;
  }

  //Medias
  @media (min-width: 0px) and (max-width: 759px) {
    flex-direction: column;
    align-items: flex-start;

    > div {
      width: 100%;
      min-width: auto;
      label {
        margin-left: 1rem;
      }
    }

    /*
    * RevisionNumberAndRevisionDateAndFile este seletor sobrepõe o css
    * do item definido abaixo.
    */
    > div:last-child {
      min-width: auto;
    }
  }

  @media (min-width: 760px) and (max-width: 999px) {
  }
`;

export const RevisionNumberAndRevisionDateAndFile = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 1.4rem;

  width: 100%;
  max-width: 39.1rem;

  //revisionNumber
  > div:nth-child(1) {
    width: 7.8rem;
  }

  //revisionDate
  > div:nth-child(2) {
    width: 15rem;
    min-width: 11rem;
  }

  //btLoadNewDrawing
  > div:last-child {
    width: 15rem;

    > button {
      width: 11.7rem;
      background: var(--azul-5bits);
      font-style: normal;
      font-weight: bold;
      font-size: 1.4rem;
      text-transform: capitalize;
      line-height: 1.9rem;
      margin-right: 1.38rem;
    }
  }

  /*
  O filho na posição 4 em nth-child é o container do inputFile oculto
  mas que conta. Os campos do tipo inputHidden não contam.
  */
  > div:nth-child(4) {
    width: 0;
  }

  /*
  Quando escolhe um arquivo  nth-child(4) não é mais o inputFile e sim o FiTrash2
  fazendo o width ficar com 0. Por isso o width auto.
  */
  //FiTrash2
  > div:last-child {
    display: flex;
    align-items: center;

    color: var(--cinza-claro-5bits);
  }

  //Medias
  @media (min-width: 0px) and (max-width: 358px) {
    flex-wrap: wrap;

    > div {
      width: 100%;
    }

    //revisionNumber
    > div:nth-child(1) {
      width: 100%;
    }

    //revisionDate
    > div:nth-child(2) {
      width: 100%;
    }
  }

  @media (min-width: 359px) and (max-width: 759px) {
    width: fit-content;
    flex-wrap: nowrap;
    gap: 0.5rem;

    //revisionNumber
    > div:nth-child(1) {
      width: 6.9rem;

      > label {
        margin-left: 0.8rem;
      }

      > div {
        padding: 0.9rem;
      }
    }

    //revisionDate
    > div:nth-child(2) {
      width: 13.5rem;

      > label {
        margin-left: 0.8rem;
      }

      > div {
        padding: 0.9rem;
      }
    }

    > div:last-child {
      > button {
        width: 9rem;
        margin-right: 0.6rem;
      }
    }
  }

  @media (min-width: 760px) and (max-width: 999px) {
  }
`;

export const ButtonAndIconFakes = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  > button {
    width: 11.7rem;
    font-style: normal;
    font-weight: bold;
    font-size: 1.4rem;
    text-transform: capitalize;
    line-height: 1.9rem;
    margin-right: 1.38rem;
  }
  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 4.5rem;
    width: fit-content;
  }
`;

// export const SplitRequirements = styled.div`
//   display: flex;
//   flex-direction: row;
//   flex-wrap: wrap;
//   justify-content: space-between;
//   align-items: flex-end;

//   width: 100%;
//   padding-top: 3.125rem;
//   padding-bottom: 3.125rem;
//   border-bottom: 0.1rem solid var(--cinza-claro-5bits);

//   > div {
//     width: 48%;
//     margin-bottom: 1rem;
//     label {
//       margin-left: 1rem;
//     }
//   }
// `;

export const SplitFooter = styled.div<IProps>`
  width: 100%;
  margin-top: 3.125rem;

  //Medias
  @media (min-width: 0px) and (max-width: 759px) {
    margin-top: 2rem;
    margin-bottom: 11rem;
    border-bottom: 0.1rem solid var(--cinza-claro-5bits);

    ${props =>
      (props.showInformation &&
        css`
          > header a {
            color: var(--azul-5bits);
          }

          > header a::after {
            animation: ${rotateUp} 0.5s forwards;
          }
        `) ||
      css`
        > header a {
          color: var(--cinza-escuro-5bits);
        }

        > header a::after {
          animation: ${rotateDown} 0.5s forwards;
        }
      `}
  }
`;

export const SplitFooterContent = styled.div<IProps>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;

  width: 100%;

  > div {
    /* flex: 50%; */
    /* padding-right: 4.2rem; */
    width: 48%;
    margin-bottom: 1rem;
    label {
      margin-left: 2.5rem;
    }
  }

  //Medias
  @media (min-width: 0px) and (max-width: 759px) {
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;

    > div {
      width: 100%;
    }

    > div:last-child {
      margin-bottom: 2rem;
    }

    overflow: hidden;
    ${props =>
      (props.showInformation &&
        css`
          min-height: 54rem;
          height: fit-content;
          max-height: fit-content;
          visibility: visible;
          opacity: 1;
          transition: opacity 2s, height 0.5s linear;
        `) ||
      css`
        height: 0;
        visibility: visible;
        opacity: 0;
        padding: 0;
        transition: opacity 2s, visibility 2s 2s linear, height 0.5s linear;
      `}
  }
`;

export const SelectSectorAndButtonSave = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  margin-top: 3.4rem;
  padding-bottom: 3.125rem;

  > div:first-child {
    width: 48%;
    > label {
      margin-left: 2.5rem;
    }
  }

  button {
    width: 37.3rem;
  }

  //Medias
  @media (min-width: 0px) and (max-width: 759px) {
    box-sizing: border-box;
    position: absolute;
    left: 0;
    bottom: 0;

    width: 100vw;

    padding: 1.3rem 0.8rem;

    background: #ffffff;
    border: 1px solid rgba(13, 78, 133, 0.1);
    box-shadow: 0px -10px 20px rgba(0, 0, 0, 0.05);

    > div:first-child {
      width: 63%;
      > label {
        margin-left: 0.8rem;
      }
    }

    button {
      width: 12.2rem;
    }
  }

  @media (min-width: 760px) and (max-width: 999px) {
  }
`;

// export const SelectSectorAndButtonSave = styled.div`
//   display: flex;
//   flex-direction: row;
//   flex-wrap: wrap;
//   justify-content: space-between;
//   align-items: flex-end;

//   width: 100%;
//   padding-top: 3.125rem;
//   padding-bottom: 3.125rem;

//   > div {
//     /* flex: 50%; */
//     /* padding-right: 4.2rem; */
//     width: 48%;
//     margin-bottom: 1rem;
//     label {
//       margin-left: 1rem;
//     }
//   }
//   > div:last-child {
//     display: flex;
//     justify-content: flex-end;

//     button {
//       width: 69%;
//     }
//   }
// `;
