/* eslint-disable react/jsx-curly-newline */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import React, { useEffect, useState, useCallback, memo } from 'react';
import { FormHandles, Scope } from '@unform/core';

import { MdExpandMore } from 'react-icons/md';

import Loading from '../../../../../../components/Loading';
import Input from '../../../../../../components/Input';
import InputHidden from '../../../../../../components/InputHidden';
import InputFieldAndText from '../../../../../../components/InputFieldAndText';
import InputTwoFieldsForHours from '../../../../../../components/InputTwoFieldsForHours';

import {
  SplitManufacturingSteps,
  HeaderAndGrandTotal,
  SplitManufacturingStepItem,
  ForecastTimeAndForecastDays,
} from './styles';

import { IManufacturingResponse } from '../../../../../../interfaces/manufacturing';

interface IManufacturingStepForm extends IManufacturingResponse {
  forecastTime: string;
  forecastDays: string;
}

interface IHandleManufacturingSteps {
  formRef: any | FormHandles;
  listUpdatedByService: Array<IManufacturingStepForm>;
  projectHasManufacturingStepsProp: Array<any>;
  disableTabProp: boolean;
}
const HandleManufacturingSteps: React.FC<IHandleManufacturingSteps> = ({
  formRef,
  listUpdatedByService,
  projectHasManufacturingStepsProp,
  disableTabProp = false,
}) => {
  const [
    listManufacturingStepsTheProject,
    setListManufacturingStepsTheProject,
  ] = useState<IManufacturingStepForm[]>([{} as IManufacturingStepForm]);

  const [disabled, setDisabled] = useState<boolean>(false);

  useEffect(() => {
    setDisabled(disableTabProp);
  }, [disableTabProp]);

  useEffect(() => {
    if (
      listUpdatedByService.length > 0 &&
      Object.entries(listUpdatedByService[0]).length > 0
    ) {
      const currentListManufacturingSteps: Array<IManufacturingStepForm> = formRef.current.getData()
        .projectHasManufacturingSteps;

      const listUpdatedByServiceComplete: Array<any> = listUpdatedByService.map(
        (itemByListService: IManufacturingStepForm) => {
          const itemFound: any = currentListManufacturingSteps.find(
            (itemCurrentList: IManufacturingStepForm) =>
              itemCurrentList.id === itemByListService.id,
          );

          let itemComplete;
          if (itemFound !== undefined) {
            itemComplete = {
              id: itemByListService.id,
              name: itemByListService.name,
              forecastTime: itemByListService.forecastTime,
              forecastDays: itemFound.forecastDays,
            };
          } else {
            itemComplete = {
              id: itemByListService.id,
              name: itemByListService.name,
              forecastTime: itemByListService.forecastTime,
            };
          }

          return itemComplete;
        },
      );

      setListManufacturingStepsTheProject(listUpdatedByServiceComplete);
    } else {
      setListManufacturingStepsTheProject([{} as IManufacturingStepForm]);

      const inputRefForecastDays = formRef.current?.getFieldRef(
        `projectHasManufacturingSteps[0].forecastDays`,
      );

      formRef.current?.clearField(`projectHasManufacturingSteps[0].id`);
      formRef.current?.clearField(`projectHasManufacturingSteps[0].name`);
      formRef.current?.clearField(
        `projectHasManufacturingSteps[0].forecastTime`,
      );
      formRef.current?.clearField(
        `projectHasManufacturingSteps[0].forecastDays`,
      );

      inputRefForecastDays.style.color = 'var(--cinza-claro-5bits)';
      inputRefForecastDays.parentNode.parentNode.style.filter =
        'brightness(0.9)';
      inputRefForecastDays.disabled = false;
    }
  }, [formRef, listUpdatedByService]);

  useEffect(() => {
    // eslint-disable-next-line array-callback-return
    listManufacturingStepsTheProject.map((item: any, index) => {
      formRef.current?.setFieldValue(
        `projectHasManufacturingSteps[${index}].id`,
        item.id,
      );
      formRef.current?.setFieldValue(
        `projectHasManufacturingSteps[${index}].name`,
        item.name,
      );
      formRef.current?.setFieldValue(
        `projectHasManufacturingSteps[${index}].forecastTime`,
        item.forecastTime,
      );

      if (item.id !== undefined) {
        const inputRefForecastDays = formRef.current?.getFieldRef(
          `projectHasManufacturingSteps[${index}].forecastDays`,
        );
        /* Volta a cor normal do componente. Para isso tem qua mudar o
      style filter duas divs acima, ou seja, no bisavo do input */
        if (!disabled) {
          inputRefForecastDays.style.color = 'var(--cinza-escuro-5bits)';
          inputRefForecastDays.parentNode.parentNode.style.filter = 'none';
          inputRefForecastDays.readOnly = false;
        } else {
          inputRefForecastDays.style.color = 'var(--cinza-claro-5bits)';
          inputRefForecastDays.parentNode.parentNode.style.filter =
            'brightness(0.9)';
        }
      }

      formRef.current?.setFieldValue(
        `projectHasManufacturingSteps[${index}].forecastDays`,
        item.forecastDays,
      );
    });
  }, [disabled, formRef, listManufacturingStepsTheProject]);

  const [showManufacturingSteps, setShowManufacturingSteps] = useState(false);

  const showSectionManufacturingSteps = useCallback(() => {
    const sectionManufacturingSteps: HTMLElement | null = document.querySelector(
      `#manufacturingStepsArea`,
    );
    const iconExpandMore: HTMLElement | null = document.querySelector(
      `#iconExpandMoreManufacturingSteps`,
    );

    if (
      sectionManufacturingSteps !== null &&
      sectionManufacturingSteps !== undefined
    ) {
      if (showManufacturingSteps === true) {
        sectionManufacturingSteps.style.display = 'block';

        if (iconExpandMore !== null && iconExpandMore !== undefined) {
          iconExpandMore.style.transform = 'rotate(180deg)';
          iconExpandMore.style.transitionDuration = '0.3s';
        }
      } else {
        sectionManufacturingSteps.style.display = 'none';

        if (iconExpandMore !== null && iconExpandMore !== undefined) {
          iconExpandMore.style.transform = 'rotate(0deg)';
          iconExpandMore.style.transitionDuration = '0.3s';
        }
      }
    }

    setShowManufacturingSteps(!showManufacturingSteps);
  }, [showManufacturingSteps]);

  useEffect(() => {
    // setTimeout(() => {
    if (
      projectHasManufacturingStepsProp &&
      projectHasManufacturingStepsProp.length > 0
    ) {
      // const newSteps: any = projectHasManufacturingStepsProp.map(item => {
      //   return {
      //     id: item.manufacturingStepId,
      //     name: '',
      //     forecastTime: item.forecastTime,
      //     forecastDays: item.forecastDays,
      //   };
      // });

      setListManufacturingStepsTheProject(projectHasManufacturingStepsProp);
      showSectionManufacturingSteps();
    }
    // }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectHasManufacturingStepsProp]);

  return (
    <SplitManufacturingSteps>
      <HeaderAndGrandTotal
        onClick={showSectionManufacturingSteps}
        data-id="manufacturingStepsArea"
      >
        <span>
          Etapas de Produção
          <MdExpandMore id="iconExpandMoreManufacturingSteps" size={30} />
        </span>
      </HeaderAndGrandTotal>
      <section id="manufacturingStepsArea">
        {listManufacturingStepsTheProject.length > 0 &&
          listManufacturingStepsTheProject.map((item, index) => (
            <Scope
              path={`projectHasManufacturingSteps[${index}]`}
              key={String(index)}
            >
              <SplitManufacturingStepItem>
                <InputHidden id="id" name="id" />
                <Input
                  id="name"
                  name="name"
                  type="text"
                  labelText={index === 0 ? 'Nome' : ''}
                  autoComplete="off"
                  readOnly
                  disabled={disabled}
                />

                <ForecastTimeAndForecastDays>
                  <InputTwoFieldsForHours
                    id="forecastTime"
                    name="forecastTime"
                    labelText={index === 0 ? 'Total' : ''}
                    placeholder=""
                    autoComplete="off"
                    maxLength={45}
                    disabled
                  />
                  <InputFieldAndText
                    id="forecastDays"
                    name="forecastDays"
                    labelText={index === 0 ? 'Previsão' : ''}
                    textSecondField="Dias"
                    placeholder=""
                    autoComplete="off"
                    maxLength={45}
                    readOnly
                    disabled={disabled}
                  />
                </ForecastTimeAndForecastDays>
              </SplitManufacturingStepItem>
            </Scope>
          ))}
      </section>
    </SplitManufacturingSteps>
  );
};

export default memo(HandleManufacturingSteps);
