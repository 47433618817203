import styled from 'styled-components';

export const Container = styled.div`
  input[type='checkbox'] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  label {
    display: block;
    position: relative;
    cursor: pointer;

    span {
      position: absolute;
      top: 1px;
      height: 18px;
      width: 18px;
      background-color: var(--branco-puro-5bits);
      border: 1px solid var(--cinza-escuro-5bits);
      border-radius: 0.4rem;

      &:after {
        content: '';
        position: absolute;
        display: none;
        left: 6px;
        top: 3px;
        width: 3px;
        height: 6px;
        border: solid white;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }
    }

    &:hover input ~ span {
      background-color: var(--cinza-claro-5bits);
    }

    input {
      &:checked ~ span {
        background-color: var(--azul-medio-5bits);
        border: 1px solid var(--azul-medio-5bits);
      }
      &:checked ~ span:after {
        display: block;
      }
    }

    & + label {
      margin-top: 30px;
    }
  }

  label[data-position='right'] {
    padding-right: 35px;
    span {
      right: 0;
    }
  }
  label[data-position='left'] {
    padding-left: 35px;
    span {
      left: 0;
    }
  }
`;
