/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-curly-newline */
import React, { useEffect, useState } from 'react';
import { RiCloseLine } from 'react-icons/ri';
import IconClose from '../../../../assets/Close.svg';
import Button from '../../../../components/Button';

import Loading from '../../../../components/Loading';

import Modal from '../../../../components/Modal';

import { Container, Content, ActionCloseModal } from './styles';
import TextArea from '../../../../components/TextArea';

interface ModalDTO {
  loadModalProp: boolean;
}

const ModalShowWhyCanceledProposals: React.FC<ModalDTO> = ({
  // formRef,
  loadModalProp = false,
}) => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setShowModal(loadModalProp);
  }, [loadModalProp]);

  return (
    <Modal visibleProp={showModal}>
      <Container>
        <h1>Motivo do cancelamento:</h1>
        <Content>
          <TextArea name="whyCanceled" />
          <ActionCloseModal>
            <RiCloseLine
              name="closeModal"
              size={36}
              onClick={() => {
                setShowModal(false);
              }}
            >
              Fechar
            </RiCloseLine>
          </ActionCloseModal>
        </Content>
      </Container>
    </Modal>
  );
};

export default ModalShowWhyCanceledProposals;
