/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { FormHandles } from '@unform/core';

import Loading from '../../../../../components/Loading';
import TextArea from '../../../../../components/TextArea';

import {
  SplitAdditionalInformationsProps,
  HeaderAndGrandTotal,
} from './styles';

interface IHandleAdditionalInformationsProps {
  disableTabProp: boolean;
}

const HandleAdditionalInformations: React.FC<IHandleAdditionalInformationsProps> = ({
  disableTabProp = false,
}) => {
  const [disabled, setDisabled] = useState<boolean>(false);

  useEffect(() => {
    setDisabled(disableTabProp);
  }, [disableTabProp]);

  return (
    <SplitAdditionalInformationsProps>
      <HeaderAndGrandTotal>
        <span>Informações Adicionais</span>
      </HeaderAndGrandTotal>
      <section id="resumeArea">
        <TextArea id="tax" name="tax" label="Impostos" disabled={disabled} />
        <TextArea
          id="transport"
          name="transport"
          label="Transporte"
          disabled={disabled}
        />
        <TextArea
          id="deadline"
          name="deadline"
          label="Prazo de Entrega"
          disabled={disabled}
        />
        <TextArea
          id="proposalValidity"
          name="proposalValidity"
          label="Validade da Proposta"
          disabled={disabled}
        />
        <TextArea
          id="paymentTerms"
          name="paymentTerms"
          label="Condições de Pagamento"
          disabled={disabled}
        />
        <TextArea
          id="finish"
          name="finish"
          label="Acabamento"
          disabled={disabled}
        />
        <TextArea
          id="responsibilitiesContracting"
          name="responsibilitiesContracting"
          label="Responsabilidade da Contratante"
          disabled={disabled}
        />
        <TextArea
          id="responsibilitiesContracted"
          name="responsibilitiesContracted"
          label="Responsabilidade da Contratada"
          disabled={disabled}
        />
        <TextArea
          id="finalConsiderations"
          name="finalConsiderations"
          label="Considerações Finais"
          disabled={disabled}
        />
      </section>
    </SplitAdditionalInformationsProps>
  );
};

export default HandleAdditionalInformations;
