/* eslint-disable react/jsx-curly-newline */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import React, {
  Ref,
  forwardRef,
  useEffect,
  useState,
  useCallback,
  useImperativeHandle,
} from 'react';
import { FormHandles } from '@unform/core';
import fincalc from 'fincalc';

import { MdExpandMore } from 'react-icons/md';

import typy from 'typy';

import {
  SplitMaterials,
  HeaderAndGrandTotal,
  ActionAndGrandTotal,
  GrandTotal,
} from './styles';

import { IMaterialResponse } from '../../../../../../interfaces/material';
import convertNumberFloatToDB from '../../../../../../utils/convertNumberFloatToDB';
import convertNumberFloatToBr from '../../../../../../utils/convertNumberFloatToBr';

import ScopeMaterialPurchasing from './components/ScopeMaterialPurchasing';
import PaginationAndSetInputsOnComponent from './components/PaginationAndSetInputsOnComponent';

interface IMaterialForm extends IMaterialResponse {
  totalPrice: string;
  quantity: string;
}

interface IHandleMaterialsProps {
  formRef: any | FormHandles;
  listMaterials: Array<any>;
  listProviders: Array<any>;
  projectProp: any;
  handleNewListTotalization: (newListTotalization: Array<any>) => void;
  disableTabProp: boolean;
  // eslint-disable-next-line react/require-default-props
  disabledImageExpandMoreCircle?: boolean;
}

interface IRef {
  getUpdateListCompleteAndUpdatedOfTheMaterialsPurchasing: any;
}

const HandleMaterialsPurchasing = forwardRef<IRef, IHandleMaterialsProps>(
  (
    {
      formRef,
      listMaterials,
      listProviders,
      projectProp,
      handleNewListTotalization,
      disableTabProp = false,
      disabledImageExpandMoreCircle = false,
    }: IHandleMaterialsProps,
    ref: Ref<IRef>,
  ) => {
    const [
      listMaterialsPurchasingTheProject,
      setListMaterialsPurchasingTheProject,
    ] = useState<Array<any>>([]);

    const [
      listMaterialPurchasingPagination,
      setListMaterialPurchasingPagination,
    ] = useState<Array<IMaterialForm>>([]);

    const [
      listCompleteAndUpdatedOfTheMaterialsPurchasing,
      setListCompleteAndUpdatedOfTheMaterialsPurchasing,
    ] = useState<Array<IMaterialForm>>([]);

    const [grandTotalPurchasing, setGrandTotalPurchasing] = useState(0);

    const [disabled, setDisabled] = useState<boolean>(false);

    const [finishedProposal, setFinishedProposal] = useState<boolean>(false);

    useEffect(() => {
      setDisabled(disableTabProp);
    }, [disableTabProp]);

    const getIndexOfTheMaterialPurchasingToBeUpdated = useCallback(
      (item: any) => {
        if (
          Object.entries(item).length > 0 &&
          typy(parseInt(item.id.toString(), 10)).isNumber
        ) {
          const indexOfTheMaterialPurchasingToBeUpdated = listCompleteAndUpdatedOfTheMaterialsPurchasing.findIndex(
            itemFind => itemFind.id.toString() === item.id.toString(),
          );

          return indexOfTheMaterialPurchasingToBeUpdated;
        }

        return undefined;
      },
      [listCompleteAndUpdatedOfTheMaterialsPurchasing],
    );

    const handleUpdateListCompleteAndUpdatedOfTheMaterialsPurchasing = useCallback(
      (
        currentFormDataHasMaterialsPurchasingOnPageActual: any,
      ): Array<IMaterialForm> => {
        const listCompleteUpdated: Array<any> = [
          ...listCompleteAndUpdatedOfTheMaterialsPurchasing,
        ];

        currentFormDataHasMaterialsPurchasingOnPageActual.map(
          // eslint-disable-next-line array-callback-return
          (item: any): void => {
            const indexOfTheMaterialPurchasingToBeUpdated = getIndexOfTheMaterialPurchasingToBeUpdated(
              item,
            );

            if (indexOfTheMaterialPurchasingToBeUpdated !== undefined)
              listCompleteUpdated[
                indexOfTheMaterialPurchasingToBeUpdated
              ] = item;
          },
        );

        return listCompleteUpdated;
      },
      [
        getIndexOfTheMaterialPurchasingToBeUpdated,
        listCompleteAndUpdatedOfTheMaterialsPurchasing,
      ],
    );

    const groupByProvider = useCallback(
      async (currentProjectHasMaterialsPurchasing: any) => {
        const listMaterialsPurchasingUpdated = [
          ...handleUpdateListCompleteAndUpdatedOfTheMaterialsPurchasing(
            currentProjectHasMaterialsPurchasing,
          ),
        ];

        return listMaterialsPurchasingUpdated.reduce(
          (accumulated: any, currentItem: any) => {
            const itemIndexInAccumulated = accumulated.findIndex(
              (itemFind: any) => {
                if (
                  parseInt(currentItem.providerIdHidden, 10) ===
                  parseInt(itemFind.providerIdHidden, 10)
                ) {
                  return true;
                }
                return false;
              },
            );

            if (typy(parseInt(currentItem.providerIdHidden, 10)).isNumber) {
              if (itemIndexInAccumulated === -1) {
                accumulated.push({
                  providerIdHidden: currentItem.providerIdHidden,
                  total: currentItem.totalPrice,
                  deadline: currentItem.deliveryDays,
                });
              } else {
                const sumTotalQuantityProvider =
                  convertNumberFloatToDB(currentItem.totalPrice) +
                  convertNumberFloatToDB(
                    accumulated[itemIndexInAccumulated].total,
                  );

                accumulated[itemIndexInAccumulated] = {
                  ...accumulated[itemIndexInAccumulated],
                  total: convertNumberFloatToBr(sumTotalQuantityProvider),
                };

                // Encontra o item com quantidade de dias maior
                const currentItemDeliveryDays = parseInt(
                  currentItem.deliveryDays,
                  10,
                );
                const accumulatedItemDeliveryDays = parseInt(
                  accumulated[itemIndexInAccumulated].deadline,
                  10,
                );

                const currentItemDeliveryDaysForComparison = typy(
                  currentItemDeliveryDays,
                ).isNumber
                  ? currentItemDeliveryDays
                  : 0;

                const accumulatedItemDeliveryDaysForComparison = typy(
                  accumulatedItemDeliveryDays,
                ).isNumber
                  ? accumulatedItemDeliveryDays
                  : 0;

                if (
                  currentItemDeliveryDaysForComparison >
                  accumulatedItemDeliveryDaysForComparison
                ) {
                  const longerDeliveryDaysOfTheProvider = currentItemDeliveryDaysForComparison;
                  accumulated[itemIndexInAccumulated] = {
                    ...accumulated[itemIndexInAccumulated],
                    deadline: longerDeliveryDaysOfTheProvider,
                  };
                }
                // Fim Encontra o item com quantidade de dias maior
              }
            }

            return accumulated;
          },
          [],
        );
      },
      [handleUpdateListCompleteAndUpdatedOfTheMaterialsPurchasing],
    );

    const updateTotalization = useCallback(async () => {
      const currentProjectHasMaterialsPurchasing = formRef.current.getData()
        .projectHasMaterialsPurchasing;

      if (
        currentProjectHasMaterialsPurchasing.some(
          (item: any) => typy(parseInt(item.providerIdHidden, 10)).isNumber,
        )
      ) {
        const listTotalization: Array<any> = await groupByProvider(
          currentProjectHasMaterialsPurchasing,
        );

        if (listTotalization.length > 0) {
          const newListTotalization = listTotalization.map(item => {
            const providerInList = listProviders.find(
              provider => provider.id === parseInt(item.providerIdHidden, 10),
            );

            return {
              providerName:
                providerInList !== undefined ? providerInList.fantasyName : '',
              total: item.total,
              deadline: item.deadline,
            };
          });
          handleNewListTotalization([...newListTotalization]);
        } else {
          handleNewListTotalization([{}]);
        }
      } else {
        handleNewListTotalization([{}]);
      }
    }, [formRef, groupByProvider, handleNewListTotalization, listProviders]);

    const [showMaterialsPurchasing, setShowMaterialsPurchasing] = useState(
      false,
    );

    const showSectionMaterialsPurchasing = useCallback(() => {
      const sectionMaterialsPurchasing: HTMLElement | null = document.querySelector(
        `#materialsAreaPurchasing`,
      );
      const iconExpandMore: HTMLElement | null = document.querySelector(
        `#iconExpandMoreMaterialsPurchasing`,
      );
      const grandTotalPurchasingInHeader: HTMLElement | null = document.querySelector(
        `#grandTotalPurchasingInHeaderMaterialPurchasing`,
      );

      if (
        sectionMaterialsPurchasing !== null &&
        sectionMaterialsPurchasing !== undefined &&
        grandTotalPurchasingInHeader !== null &&
        grandTotalPurchasingInHeader !== undefined
      ) {
        if (showMaterialsPurchasing === true) {
          sectionMaterialsPurchasing.style.display = 'block';
          grandTotalPurchasingInHeader.style.display = 'none';

          if (iconExpandMore !== null && iconExpandMore !== undefined) {
            iconExpandMore.style.transform = 'rotate(180deg)';
            iconExpandMore.style.transitionDuration = '0.3s';
          }
        } else {
          sectionMaterialsPurchasing.style.display = 'none';
          grandTotalPurchasingInHeader.style.display = 'block';

          if (iconExpandMore !== null && iconExpandMore !== undefined) {
            iconExpandMore.style.transform = 'rotate(0deg)';
            iconExpandMore.style.transitionDuration = '0.3s';
          }
        }
      }

      setShowMaterialsPurchasing(!showMaterialsPurchasing);
    }, [showMaterialsPurchasing]);

    const sumGrandTotal = useCallback(() => {
      const currentFormData = formRef.current.getData();

      const currentProjectHasMaterialsPurchasingOnPageActual =
        currentFormData.projectHasMaterialsPurchasing;

      if (
        listCompleteAndUpdatedOfTheMaterialsPurchasing &&
        listCompleteAndUpdatedOfTheMaterialsPurchasing.length > 0 &&
        currentProjectHasMaterialsPurchasingOnPageActual.length > 0
      ) {
        const listCompleteUpdated = [
          ...handleUpdateListCompleteAndUpdatedOfTheMaterialsPurchasing(
            currentProjectHasMaterialsPurchasingOnPageActual,
          ),
        ];
        const currentProjectHasMaterialsPurchasing = [...listCompleteUpdated];

        const totalGeral = currentProjectHasMaterialsPurchasing.reduce(
          (sum: number, currentItem: any) => {
            // eslint-disable-next-line no-return-assign
            return fincalc(
              (sum += convertNumberFloatToDB(currentItem.totalPrice)),
            );
          },
          0,
        );

        setGrandTotalPurchasing(totalGeral);
        updateTotalization();
      }
    }, [
      formRef,
      handleUpdateListCompleteAndUpdatedOfTheMaterialsPurchasing,
      listCompleteAndUpdatedOfTheMaterialsPurchasing,
      updateTotalization,
    ]);

    const clearFields = useCallback(
      (newListPagination): void => {
        newListPagination.newListPageActual.map(
          // eslint-disable-next-line array-callback-return
          (item: any, index: number): void => {
            formRef.current.clearField(
              `projectHasMaterialsPurchasing[${index}].id`,
            );
            formRef.current.clearField(
              `projectHasMaterialsPurchasing[${index}].materialId`,
            );
            formRef.current.clearField(
              `projectHasMaterialsPurchasing[${index}].providerId`,
            );
            formRef.current.clearField(
              `projectHasMaterialsPurchasing[${index}].providerIdHidden`,
            );
            formRef.current.clearField(
              `projectHasMaterialsPurchasing[${index}].providerRadio`,
            );
            formRef.current.clearField(
              `projectHasMaterialsPurchasing[${index}].deliveryDays`,
            );
            formRef.current.clearField(
              `projectHasMaterialsPurchasing[${index}].quantity`,
            );
            formRef.current.clearField(
              `projectHasMaterialsPurchasing[${index}].totalPrice`,
            );
            formRef.current.clearField(
              `projectHasMaterialsPurchasing[${index}].unitOfMeasurement`,
            );
            formRef.current.clearField(
              `projectHasMaterialsPurchasing[${index}].unitPrice`,
            );

            setGrandTotalPurchasing(0);
          },
        );
      },
      [formRef],
    );

    const handleMaterialsPurchasingPagination = useCallback(
      newListPagination => {
        clearFields(newListPagination);

        setListMaterialPurchasingPagination(
          newListPagination.newListPageActual,
        );
        setListCompleteAndUpdatedOfTheMaterialsPurchasing(
          newListPagination.newListCompleteUpdated,
        );
      },
      [clearFields],
    );

    useEffect(() => {
      // formRef.current.setData({
      //   ...formRef.current.getData(),
      //   projectHasMaterialsPurchasing: [...listMaterialPurchasingPagination],
      // });

      if (
        listMaterialPurchasingPagination &&
        listMaterialPurchasingPagination.length > 0
      ) {
        listMaterialPurchasingPagination.map(
          // eslint-disable-next-line array-callback-return
          (item: any, index: number): void => {
            formRef.current.setFieldValue(
              `projectHasMaterialsPurchasing[${index}].id`,
              item.id,
            );
            formRef.current.setFieldValue(
              `projectHasMaterialsPurchasing[${index}].materialId`,
              item.materialId,
            );
            formRef.current.setFieldValue(
              `projectHasMaterialsPurchasing[${index}].providerId`,
              item.providerId,
            );
            formRef.current.setFieldValue(
              `projectHasMaterialsPurchasing[${index}].providerIdHidden`,
              item.providerIdHidden,
            );
            // formRef.current.setFieldValue(
            //   `projectHasMaterialsPurchasing[${index}].providerRadio`,
            //   item.providerRadio,
            // );
            formRef.current.setFieldValue(
              `projectHasMaterialsPurchasing[${index}].quantity`,
              item.quantity,
            );
            formRef.current.setFieldValue(
              `projectHasMaterialsPurchasing[${index}].totalPrice`,
              item.totalPrice,
            );
            formRef.current.setFieldValue(
              `projectHasMaterialsPurchasing[${index}].unitOfMeasurement`,
              item.unitOfMeasurement,
            );
            formRef.current.setFieldValue(
              `projectHasMaterialsPurchasing[${index}].unitPrice`,
              item.unitPrice,
            );
            formRef.current.setFieldValue(
              `projectHasMaterialsPurchasing[${index}].deliveryDays`,
              item.deliveryDays,
            );
          },
        );
        sumGrandTotal();
      }
    }, [formRef, listMaterialPurchasingPagination, sumGrandTotal]);

    useEffect(() => {
      if (
        projectProp.projectHasMaterials &&
        projectProp.projectHasMaterials.length > 0
      ) {
        setListMaterialsPurchasingTheProject(projectProp.projectHasMaterials);
        showSectionMaterialsPurchasing();
      }

      if (projectProp.finishedProposal) {
        setFinishedProposal(projectProp.finishedProposal);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectProp]);

    /**
     * A função getUpdateListCompleteAndUpdatedOfTheMaterialsPurchasing é chamada
     * na Create de purchasing e pega a list completa e atualizada para
     * serem usados e enviados na função de Submit, já que a paginação
     * não contém todos os item ao pegá-los por formRef.current.data.
     * A troca de informações bidirecionais é feita pelo useImperativeHandle.
     */
    const getUpdateListCompleteAndUpdatedOfTheMaterialsPurchasing = useCallback((): Array<any> => {
      const currentProjectHasMaterialsPurchasing = formRef.current.getData()
        .projectHasMaterialsPurchasing;

      const listMaterialsUpdated = [
        ...handleUpdateListCompleteAndUpdatedOfTheMaterialsPurchasing(
          currentProjectHasMaterialsPurchasing,
        ),
      ];

      return listMaterialsUpdated;
    }, [formRef, handleUpdateListCompleteAndUpdatedOfTheMaterialsPurchasing]);

    useImperativeHandle(ref, () => ({
      getUpdateListCompleteAndUpdatedOfTheMaterialsPurchasing,
    }));

    return (
      <SplitMaterials>
        <HeaderAndGrandTotal
          onClick={showSectionMaterialsPurchasing}
          data-id="materialsAreaPurchasing"
        >
          <span>
            Materiais
            <MdExpandMore id="iconExpandMoreMaterialsPurchasing" size={30} />
          </span>
          <GrandTotal
            id="grandTotalPurchasingInHeaderMaterialPurchasing"
            style={{ display: 'none' }}
          >
            Total Geral:{' '}
            <span>R$ {convertNumberFloatToBr(grandTotalPurchasing)}</span>
          </GrandTotal>
        </HeaderAndGrandTotal>
        <section id="materialsAreaPurchasing">
          <ScopeMaterialPurchasing
            formRef={formRef}
            listMaterials={listMaterials}
            listMaterialPurchasingPagination={listMaterialPurchasingPagination}
            disabledImageExpandMoreCircle={disabledImageExpandMoreCircle}
            disabled={disabled}
            finishedProposal={finishedProposal}
            sumGrandTotal={sumGrandTotal}
            updateTotalization={updateTotalization}
          />
          <ActionAndGrandTotal>
            <GrandTotal>
              Total Geral:{' '}
              <span>R$ {convertNumberFloatToBr(grandTotalPurchasing)}</span>
            </GrandTotal>
          </ActionAndGrandTotal>
          <PaginationAndSetInputsOnComponent
            formRef={formRef}
            listItems={listMaterialsPurchasingTheProject}
            perPageProp={10}
            // goToLastPage={goToLastPage}
            onChangeListPaginatedItems={handleMaterialsPurchasingPagination}
            // handleGoToLastPage={handleGoToLastPage}
          />
        </section>
      </SplitMaterials>
    );
  },
);

export default HandleMaterialsPurchasing;
