/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { isValid } from 'date-fns';

import typy from 'typy';
import api from '../../../services/api';
import getProjectFormatted from '../../AuxiliaryFunctions/GetProject';
import getValidationErrors from '../../../utils/getValidationError';
import { useToast } from '../../../context/ToastContext';

import Loading from '../../../components/Loading';

import Button from '../../../components/Button';
import HeadProject from '../../Sectors/HeadProject';
import convertNumberFloatToDB from '../../../utils/convertNumberFloatToDB';
import convertNumberFloatToBr from '../../../utils/convertNumberFloatToBr';
import ModalLoading from '../../../components/ModalLoading';
import Input from '../../../components/Input';
import InputCurrency from '../../../components/InputCurrency';
import DatePicker from '../../../components/DatePicker';
import CheckboxInput from '../../../components/CheckboxInput';
import ToggleOnOff from '../../../components/ToggleOnOff';

import convertDateToDB from '../../../utils/convertDateToDB';
import convertDateStringToDate from '../../../utils/convertDateStringToDate';
import { IClient, IClientResponse } from '../../../interfaces/client';
import { IProject } from '../../../interfaces/project';
import * as IInvoice from '../../../interfaces/invoice';

import {
  Container,
  Content,
  HeadOpenProject,
  SplitFooter,
  SelectSectorAndButtonSave,
  SplitInvoiceItem,
  QuantityPriceSubtotalInvoice,
  ObservationAndTrashIcon,
  SplitInvoices,
  HeaderAndGrandTotal,
  PaydayAndIsPay,
} from './styles';

const UpdateInvoice: React.FC = () => {
  const { id }: any = useParams();
  const formRef = useRef<FormHandles>(null);
  const navigate = useNavigate();
  const { addToast } = useToast();
  const [payDayDisabled, setPayDayDisabled] = useState<boolean>(true);

  const [loading, setLoading] = useState<boolean>(false);

  const [saving, setSaving] = useState<boolean>(false);
  const [projectIdInInvoice, setProjectIdInInvoice] = useState<
    number | undefined
  >(undefined);
  const [project, setProject] = useState<IProject>({} as IProject);
  const [listClients, setListClients] = useState<IClient[]>([]);
  const [invoice, setInvoice] = useState<IInvoice.IInvoiceItemResponse>(
    {} as IInvoice.IInvoiceItemResponse,
  );

  // Get project
  const getProject = useCallback(async (): Promise<void> => {
    try {
      if (projectIdInInvoice !== undefined) {
        const projectResponse: any = await getProjectFormatted(
          projectIdInInvoice,
        );

        setProject(projectResponse);
        formRef.current?.setData({ ...projectResponse, ...invoice });
        setLoading(false);
      }
    } catch (error: any) {
      setLoading(false);
      // eslint-disable-next-line no-shadow
      if (error.response) {
        const { data } = error.response; // Error vindo do back está em data dentro de response
        addToast({
          type: 'error',
          title: data.message,
        });
        return;
      }

      addToast({
        type: 'error',
        title: 'Ocorreu um erro interno',
        description: `${error}`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addToast, projectIdInInvoice]);
  // End Get project

  // Get
  const getInvoices = useCallback(async (): Promise<void> => {
    try {
      const invoiceResponse = await api.get<any>(`invoices/${id}`);

      const invoiceFormatted = {
        id: invoiceResponse.data.id,
        projectId: typy(invoiceResponse.data.projectId).isNumber
          ? invoiceResponse.data.projectId
          : undefined,
        dueDate: convertDateStringToDate(invoiceResponse.data.dueDate),
        price: convertNumberFloatToBr(invoiceResponse.data.price),
        payday: convertDateStringToDate(invoiceResponse.data.payday),
        isPay: invoiceResponse.data.isPay,
        observation: invoiceResponse.data.observation,
      };

      setInvoice(invoiceFormatted);
      setProjectIdInInvoice(invoiceFormatted.projectId);

      getProject();
    } catch (error: any) {
      setLoading(false);
      // eslint-disable-next-line no-shadow
      if (error.response) {
        const { data } = error.response; // Error vindo do back está em data dentro de response
        addToast({
          type: 'error',
          title: data.message,
        });
        return;
      }

      addToast({
        type: 'error',
        title: 'Ocorreu um erro interno',
        description: `${error}`,
      });
    }
  }, [addToast, getProject, id]);
  // End Get invoices

  // get Clients
  const getClients = useCallback(async (): Promise<void> => {
    try {
      const responseClients = await api.get<IClientResponse[]>('clients');

      const responseClientsFormatted: any = responseClients.data.map(i => ({
        value: i.id,
        label: i.name,
      }));

      setListClients(responseClientsFormatted);
      getInvoices();
    } catch (error: any) {
      setLoading(false);

      if (error.response && error.response.data) {
        const { data } = error.response; // Error vindo do back está em data dentro de response
        addToast({
          type: 'error',
          title: data.message,
        });
        return;
      }

      addToast({
        type: 'error',
        title: 'Error',
        description: 'Erro ao listar!',
      });
    }
  }, [addToast, getInvoices]);
  // End get List Clients

  // handleFormSubmit
  const handleFormSubmit = useCallback(
    async (dataForm: any, { reset }) => {
      try {
        setSaving(true);

        const schema = Yup.object().shape({
          dueDate: Yup.string()
            .test('dueDate', 'Data inválida', (dueDateItem: any) => {
              return isValid(new Date(dueDateItem));
            })
            .required('Data obrigatória')
            .nullable(),
          price: Yup.string()
            .test('price', 'Valor obrigatório', (priceItem: any) => {
              return priceItem !== '' && convertNumberFloatToDB(priceItem) > 0;
            })
            .required('Valor obrigatório'),
          isPay: Yup.boolean(),
          payday: Yup.string().when('isPay', {
            is: true,
            then: Yup.string()
              .test('dueDate', 'Data inválida', (dueDateItem: any) => {
                return isValid(new Date(dueDateItem));
              })
              .required('Data obrigatória')
              .nullable(),
          }),
        });

        await schema.validate(dataForm, { abortEarly: false });

        const dataFormCorrect: any = {
          // projectId: parseInt(projectIdInInvoice, 10),
          id: parseInt(id, 10),
          dueDate: convertDateToDB(dataForm.dueDate),
          price: convertNumberFloatToDB(dataForm.price),
          payday: dataForm.isPay ? convertDateToDB(dataForm.payday) : '',
          isPay: dataForm.isPay,
          observation: dataForm.observation,
        };

        await api.put('invoices', dataFormCorrect);

        addToast({
          type: 'success',
          title: 'Atualização realizada com sucesso',
        });

        reset();
        setSaving(false);
        navigate('/invoices');

        setSaving(false);
      } catch (error: any) {
        setSaving(false);

        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);

          return;
        }

        if (error.response) {
          const { data } = error.response; // Error vindo do back está em data dentro de response

          addToast({
            type: 'error',
            title: 'Erro na atualização',
            description: data.message,
          });

          return;
        }

        addToast({
          type: 'error',
          title: 'Error',
          description: 'Erro ao atualizar!',
        });
      }
    },
    [addToast, id, navigate],
  );
  // End handleFormSubmit

  const handleClickIsPay = useCallback(() => {
    setPayDayDisabled(!payDayDisabled);
  }, [payDayDisabled]);

  useEffect(() => {
    setLoading(true);
    const getProjectAndClients = async (): Promise<void> => {
      getClients();
    };
    getProjectAndClients();
  }, [addToast, getClients, getProject]);

  return (
    <>
      <Container>
        {(typy(project.id).isNumber && listClients.length > 0 && (
          <Content>
            <Form ref={formRef} onSubmit={handleFormSubmit}>
              <header>
                <p>Faturamento</p>
                <span>Nº do Projeto: {projectIdInInvoice}</span>
              </header>

              <HeadOpenProject>
                <HeadProject
                  listClients={listClients}
                  showInformationInMobile
                  readOnlyProp
                />
              </HeadOpenProject>

              <SplitInvoices>
                <HeaderAndGrandTotal>
                  <span>Parcelas</span>
                </HeaderAndGrandTotal>
                <section>
                  <SplitInvoiceItem>
                    <QuantityPriceSubtotalInvoice>
                      <DatePicker
                        id="dueDate"
                        name="dueDate"
                        labelText="Data Vencimento"
                        dateFormat="dd/MM/yyyy"
                        disabled={invoice.isPay}
                      />

                      <InputCurrency
                        id="price"
                        name="price"
                        type="text"
                        labelText="Valor"
                        autoComplete="off"
                        disabled={invoice.isPay}
                      />
                    </QuantityPriceSubtotalInvoice>

                    <ObservationAndTrashIcon>
                      <Input
                        id="observation"
                        name="observation"
                        type="text"
                        labelText="Observação"
                        autoComplete="off"
                        disabled={invoice.isPay}
                      />
                    </ObservationAndTrashIcon>
                    <PaydayAndIsPay>
                      <div>
                        <span>Baixar</span>
                        <ToggleOnOff
                          name="isPay"
                          id="isPay"
                          label=""
                          onClick={handleClickIsPay}
                          disabled={invoice.isPay}
                        />
                      </div>

                      <DatePicker
                        id="payday"
                        name="payday"
                        labelText="Data Pagamento"
                        disabled={invoice.isPay ? true : payDayDisabled}
                      />
                    </PaydayAndIsPay>
                  </SplitInvoiceItem>
                </section>
              </SplitInvoices>

              <SplitFooter>
                <SelectSectorAndButtonSave>
                  <div>
                    <Button type="submit" disabled={saving}>
                      {saving ? <Loading size={24} color="white" /> : 'Salvar'}
                    </Button>
                  </div>
                </SelectSectorAndButtonSave>
              </SplitFooter>
            </Form>
          </Content>
        )) || (
          <ModalLoading visible={loading}>
            <p>
              <i>Carregando os dados do Faturamento...</i>
            </p>
          </ModalLoading>
        )}
      </Container>
    </>
  );
};

export default UpdateInvoice;
