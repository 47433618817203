/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import React, { useState, useCallback } from 'react';
import { FormHandles } from '@unform/core';

import HandleMaterials from '../../Sectors/Purchasing/BodyPurchasing/components/HandleMaterials';
import HandleTotalization from '../../Sectors/Purchasing/BodyPurchasing/components/HandleTotalization';
import HandleServices from '../../Sectors/Planning/BodyPlanning/components/HandleServices';

import HandleResume from './components/HandleResume';
import HandleAdditionalInformation from './components/HandleAdditionalInformation';

import { IMaterialResponse } from '../../../interfaces/material';
import { IServiceResponse } from '../../../interfaces/service';
import { IProviderResponse } from '../../../interfaces/provider';

import { Container } from './styles';
import { IPermission } from '../../../interfaces/permission';

interface BodyBusinessProposalProp {
  formRef: any | FormHandles;
  listMaterialsProp: Array<IMaterialResponse>;
  listServicesProp: Array<IServiceResponse>;
  listProvidersProp: Array<IProviderResponse>;
  projectProp: any;
  disabledHandleResume: boolean;
  disabledHandleAdditionalInformation: boolean;
  permissions: IPermission;
}

const BodyBusinessProposal: React.FC<BodyBusinessProposalProp> = ({
  formRef,
  listMaterialsProp,
  listServicesProp,
  listProvidersProp,
  projectProp,
  disabledHandleResume = false,
  disabledHandleAdditionalInformation = false,
  permissions,
}) => {
  const [listTotalization, setListTotalization] = useState<any[]>([]);

  const addNewListTotalization = useCallback((newList: any) => {
    setListTotalization(newList);
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const justToMeetTheServiceRequirement = useCallback((newList: any) => {}, []);

  return (
    <Container>
      <HandleMaterials
        formRef={formRef}
        listMaterials={listMaterialsProp}
        listProviders={listProvidersProp}
        projectProp={projectProp}
        handleNewListTotalization={addNewListTotalization}
        disableTabProp
        disabledImageExpandMoreCircle
      />
      <HandleTotalization
        formRef={formRef}
        listUpdatedByMaterialPurchasing={listTotalization}
        projectHasTotalizationProp={projectProp.projectHasTotalization}
        disableTabProp
      />
      <HandleServices
        formRef={formRef}
        listServices={listServicesProp}
        projectHasServicesProp={projectProp.projectHasServices}
        handleNewListManufacturingSteps={justToMeetTheServiceRequirement}
        disableTabProp
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        showModalConfirmationOfExclusionService={() => {}}
      />
      <HandleResume
        formRef={formRef}
        projectProp={projectProp}
        permissions={permissions}
        disableTabProp={disabledHandleResume}
      />
      <HandleAdditionalInformation
        disableTabProp={disabledHandleAdditionalInformation}
      />
    </Container>
  );
};

export default BodyBusinessProposal;
