import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: fit-content;
  width: fit-content;

  .switch {
    position: relative;
    display: inline-block;
    width: 4.5rem;
    height: 2.5rem;
  }

  .switch input {
    display: none;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 2.5rem;
    background-color: var(--preto-5bits-rgba);
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 2rem;
    width: 2rem;
    left: 0.3rem;
    bottom: 0.3rem;
    border-radius: 50%;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: var(--azul-medio-5bits);
  }

  input:focus + .slider {
    box-shadow: 0 0 1px var(--azul-medio-5bits);
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(2rem);
    -ms-transform: translateX(2rem);
    transform: translateX(2rem);
  }
`;
