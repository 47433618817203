/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { BsSearch } from 'react-icons/bs';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import { useToast } from '../../../context/ToastContext';
// import Input from '../../Input';
import InputHidden from '../../InputHidden';
import InputSearchWithButton from '../../InputSearchWithButton';
import DatePicker from '../../DatePicker';
import SimpleSelect from '../../SimpleSelect';
import Button from '../../Button';
import Checkbox from '../../CheckboxInput';
import Pagination from '../../Pagination';

// import Modal from '../../Modal';

import getValidationErrors from '../../../utils/getValidationError';

import convertDateToDB from '../../../utils/convertDateToDB';

import api from '../../../services/api';

import { IPermission } from '../../../interfaces/permission';

import {
  Container,
  Content,
  SearchArea,
  FilterDateArea,
  ContentChildren,
} from './styles';

interface ListingTemplateProps {
  title: string;
  module: string;
  moduleForButtonNew?: string;
  moduleForSelectFind?: string;
  moduleSearchPerDate?: string;
  listItemsProps: Array<any>;
  // eslint-disable-next-line no-unused-vars
  handleListItemsPaginatedItems: (newList: Array<any>) => void;
  filterDate?: boolean;
  searchWithoutActive?: boolean;
  searchTheLocalObject?: boolean;
  displayButtonNew?: boolean;
  placeHolderProp?: string;
  displaySearchArea?: boolean;
  disabled?: boolean;
  permissions: IPermission;
  perPageForPaginationProp?: number;
  permissionSpecificOfTheModuleForNewButton?: boolean;
}

const ListingTemplate: React.FC<ListingTemplateProps> = ({
  title,
  module,
  moduleForButtonNew = module,
  moduleForSelectFind = '',
  moduleSearchPerDate = '',
  listItemsProps,
  handleListItemsPaginatedItems,
  filterDate = false,
  searchWithoutActive = false,
  searchTheLocalObject = false,
  displayButtonNew = true,
  placeHolderProp = 'Pesquisar',
  displaySearchArea = true,
  disabled = false,
  children,
  permissions = {
    create: false,
    read: false,
    update: false,
    delete: false,
  } as IPermission,
  perPageForPaginationProp = 10,
  permissionSpecificOfTheModuleForNewButton = true,
}) => {
  const formSearchListItemRef = useRef<FormHandles>(null);
  const formSearchDateListItemRef = useRef<FormHandles>(null);
  const [listAllItems, setListAllItems] = useState<any[]>([]);
  const [listItemsOriginal, setListItemsOriginal] = useState<any[]>([]);

  const [loading, setLoading] = useState<boolean>(false);
  // const [loadModalWait, setLoadModalWait] = useState(false);
  const [isCheckIsPaid, setIsCheckIsPaid] = useState(false);

  const { addToast } = useToast();

  const toggleAddIsCheckIsPaid = useCallback(() => {
    setIsCheckIsPaid(!isCheckIsPaid);
  }, [isCheckIsPaid]);

  useEffect(() => {
    setLoading(disabled);
    const getListAllItems = (): void => {
      setListAllItems(listItemsProps);
      setListItemsOriginal(listItemsProps);
    };
    getListAllItems();
  }, [listItemsProps, disabled]);

  const filterPerNameOrId = useCallback((obj, find) => {
    if (Number(find)) {
      return obj.id === Number.parseInt(find, 10);
    }
    return obj.name.toLowerCase().includes(find.toLowerCase());
  }, []);

  // handleSearchSubmit
  const handleSearchSubmit = useCallback(
    async (dataForm: any, { reset }) => {
      try {
        // setLoadModalWait(true);
        setLoading(true);
        formSearchListItemRef.current?.setErrors({});

        let response: any = [];
        // Pesquisa todos os registros
        if (dataForm.find === '') {
          response = await api.get(`${module}`);
          handleListItemsPaginatedItems(response.data);
          setListAllItems(response.data);
          setListItemsOriginal(response.data);

          if (filterDate) {
            formSearchDateListItemRef.current?.setErrors({});
            formSearchDateListItemRef.current?.reset();
            setIsCheckIsPaid(false);
          }
          // Pesquisa na lista local
        } else if (searchTheLocalObject) {
          response = listItemsOriginal.filter(obj =>
            filterPerNameOrId(obj, dataForm.find),
          );
          handleListItemsPaginatedItems(response);
          setListAllItems(response);
          // Pesquisa com parâmetros
        } else if (searchWithoutActive) {
          response = await api.get(`${module}/search/${dataForm.find}`);

          handleListItemsPaginatedItems([...response.data]);
          setListAllItems([...response.data]);
          setListItemsOriginal([...response.data]);
        } else {
          response = await api.get(`${module}/active/search/${dataForm.find}`);

          handleListItemsPaginatedItems([...response.data]);
          setListAllItems([...response.data]);
          setListItemsOriginal([...response.data]);
        }

        reset();

        // setLoadModalWait(false);
        setLoading(false);
      } catch (error: any) {
        // setLoadModalWait(false);
        setLoading(false);
        // Verifica se o error é do Yup ou seja dos campos
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formSearchListItemRef.current?.setErrors(errors);

          return;
        }

        // eslint-disable-next-line no-shadow
        if (error.response) {
          const { data } = error.response; // Error vindo do back está em data dentro de response
          addToast({
            type: 'error',
            title: data.message,
          });
          if (formSearchListItemRef.current?.getFieldRef(data.field)) {
            formSearchListItemRef.current?.getFieldRef(data.field).focus(); // Foca o campo que deu erro de acordo com o field retornado do back
          }

          return;
        }

        addToast({
          type: 'error',
          title: 'Ocorreu um erro interno',
        });
      }
    },
    [
      addToast,
      handleListItemsPaginatedItems,
      filterPerNameOrId,
      module,
      filterDate,
      searchTheLocalObject,
      listItemsOriginal,
    ],
  );
  // Fim handleSearchSubmit

  // handleSearchDateSubmit
  const handleSearchDateSubmit = useCallback(
    async (dataFormFilter: any) => {
      try {
        // setLoadModalWait(true);
        setLoading(true);
        formSearchDateListItemRef.current?.setErrors({});

        const schema = Yup.object().shape({
          start_date: Yup.string()
            .required('Data inicial obrigatória')
            .nullable(),
          end_date: Yup.string().required('Data final obrigatória').nullable(),
        });

        await schema.validate(dataFormFilter, {
          abortEarly: false,
        });

        const newData = {
          ...dataFormFilter,
          start_date: convertDateToDB(dataFormFilter.start_date),
          end_date: convertDateToDB(dataFormFilter.end_date),
          is_paid: dataFormFilter.isPaid === 'true',
        };

        delete newData.isPaid;

        const response: any = await api.get(
          `${moduleSearchPerDate}/${newData.start_date}/${newData.end_date}/${newData.is_paid}`,
        );

        handleListItemsPaginatedItems(response.data);
        setListAllItems(response.data);
        setListItemsOriginal(response.data);

        // setLoadModalWait(false);
        setLoading(false);
        // reset();
      } catch (error: any) {
        // setLoadModalWait(false);
        setLoading(false);
        // Verifica se o error é do Yup ou seja dos campos
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formSearchDateListItemRef.current?.setErrors(errors);

          return;
        }

        // eslint-disable-next-line no-shadow
        if (error.response) {
          const { data } = error.response; // Error vindo do back está em data dentro de response
          addToast({
            type: 'error',
            title: data.message,
          });

          return;
        }

        addToast({
          type: 'error',
          title: `Ocorreu um erro interno`,
        });
      }
    },
    [addToast, handleListItemsPaginatedItems, moduleSearchPerDate],
  );
  // Fim handleSearchDateSubmit

  const handleSearchOnListLocal = useCallback(
    value => {
      if (value && listItemsOriginal) {
        const newListAllItems = listItemsOriginal.filter(
          item =>
            item.outgoingFinancialAccount.name.toLowerCase() ===
              value.label.toLowerCase() ||
            item.incomingFinancialAccount.name.toLowerCase() ===
              value.label.toLowerCase(),
        );
        if (
          !newListAllItems ||
          (newListAllItems && newListAllItems.length < 1)
        ) {
          addToast({
            type: 'error',
            title: 'Nenhum registro encontrado',
          });
        } else {
          handleListItemsPaginatedItems(newListAllItems);
          setListAllItems(newListAllItems);
        }
      } else {
        formSearchListItemRef.current?.clearField('find');
        formSearchListItemRef.current?.submitForm();
      }
    },
    [addToast, listItemsOriginal, handleListItemsPaginatedItems],
  );

  return (
    <Container>
      <Content>
        <header>
          <p>{title}</p>
          <SearchArea>
            <Form ref={formSearchListItemRef} onSubmit={handleSearchSubmit}>
              {permissions.read && (
                <>
                  {displaySearchArea && (
                    <>
                      {(!moduleForSelectFind && (
                        <InputSearchWithButton
                          type="text"
                          name="find"
                          icon={BsSearch}
                          placeholder={placeHolderProp}
                          aria-label={placeHolderProp}
                          maxLength={45}
                          disabled={loading}
                        />
                      )) || (
                        <>
                          <InputHidden type="hidden" name="find" />
                          <SimpleSelect
                            module={moduleForSelectFind}
                            name="findSelect"
                            icon={BsSearch}
                            placeholder={placeHolderProp}
                            aria-label={`Pesquisar por ${placeHolderProp}`}
                            isClearable
                            onChange={e => handleSearchOnListLocal(e)}
                          />
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </Form>
            {(permissionSpecificOfTheModuleForNewButton &&
              permissions.create && (
                <>
                  {displayButtonNew && (
                    <Link to={`/${moduleForButtonNew}/create`}>Novo</Link>
                  )}
                </>
              )) || (
              <>
                {displayButtonNew && (
                  <a
                    href="/"
                    style={{
                      pointerEvents: 'none',
                      color: 'var(--branco-gelo-5bits)',
                      background: 'var(--cinza-claro-5bits)',
                    }}
                  >
                    Novo
                  </a>
                )}
              </>
            )}
          </SearchArea>

          {permissions.read && (
            <>
              {filterDate && (
                <FilterDateArea>
                  <Form
                    ref={formSearchDateListItemRef}
                    className="d-flex flex-row justify-content-end align-items-center mr-3"
                    onSubmit={handleSearchDateSubmit}
                  >
                    <span className="d-flex justify-content-end text-uppercase w-100 mr-2">
                      Vencimento de:
                    </span>
                    <DatePicker name="start_date" className="w-50" />
                    <span className="text-uppercase mx-2">até</span>
                    <DatePicker name="end_date" className="w-50 mr-2" />
                    <Checkbox
                      name="isPaid"
                      position="left"
                      onClick={toggleAddIsCheckIsPaid}
                      className="p-0 mr-2 w-auto text-nowrap"
                    >
                      Exibir Quitados
                    </Checkbox>

                    <Button type="submit" className="text-uppercase">
                      Filtrar
                    </Button>
                  </Form>
                </FilterDateArea>
              )}
            </>
          )}
        </header>
        {loading ? (
          <p>carregando ...</p>
        ) : (
          <>
            <ContentChildren>{children}</ContentChildren>

            <Pagination
              listItems={listAllItems}
              perPageProp={perPageForPaginationProp}
              onChangeListPaginatedItems={handleListItemsPaginatedItems}
            />
          </>
        )}
      </Content>
    </Container>
  );
};

export default ListingTemplate;
