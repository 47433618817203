import { DraggableProvidedDraggableProps } from 'react-beautiful-dnd';
import styled, { css } from 'styled-components';

interface IContainerProps {
  isDragging: boolean;
  dragProps: DraggableProvidedDraggableProps;
  isOpen: boolean;
  sizeH: number;
}

interface IContentProps {
  isOpen: boolean;
}

export const Container = styled.div.attrs<IContainerProps>(props => ({
  style: {
    ...props.dragProps.style,
    background: `${props.isDragging ? '#efefef' : 'var(--branco-puro-5bits)'}`,
  },
}))<IContainerProps>`
  position: relative;
  user-select: none;

  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  margin-top: 16px;
  padding: 12px;
  background: var(--branco-puro-5bits);
  border: 1px solid rgba(13, 78, 133, 0.1);
  border-radius: 4px;

  height: 102px;
  width: 225px;
  overflow: hidden;

  transition: height 500ms;
  ${props =>
    props.isOpen &&
    css`
      height: ${props.sizeH + 102}px;
    `}
`;

export const Content = styled.div<IContentProps>`
  opacity: ${props => (props.isOpen ? 1 : 0)};
  transition: opacity 500ms;
`;

export const FrameHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const FrameTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-content: center;

  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.9rem;

  p {
    align-self: center;
  }

  button {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: var(--laranja-5bits);
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:hover {
      filter: brightness(0.9);
    }
  }
`;

export const FrameInfo = styled.div`
  display: flex;
  flex-direction: column;

  p {
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 1.9rem;

    color: var(--cinza-claro-5bits);

    span {
      font-size: 1.4rem;
      line-height: 1.9rem;

      color: var(--cinza-escuro-5bits);
    }

    margin-top: 6px;
  }
`;

export const FrameDescription = styled.div`
  margin-top: 17px;

  p {
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 1.9rem;
    color: var(--cinza-escuro-5bits);
  }

  > div {
    height: 117px;
    padding: 11px;
    background: var(--branco-puro-5bits);
    border: 1px solid rgba(171, 171, 171, 0.5);
    box-sizing: border-box;
    border-radius: 4px;

    overflow-y: scroll;

    /*Firefox*/
    scrollbar-width: thin;
    scrollbar-color: #c4c4c4 transparent;

    /*Chrome*/
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #c4c4c4;
      border-radius: 20px;
      border: 3px solid transparent;
      background-clip: content-box;

      &:hover {
        background-color: #a8bbbf;
      }
    }

    > p {
      font-weight: normal;
      font-size: 1.4rem;
      line-height: 1.9rem;
      color: var(--cinza-escuro-5bits);
    }
  }
`;
