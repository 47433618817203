/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Navigate, Route, RouteProps } from 'react-router-dom';

import { useAuth } from '../context/AuthContext';

import Wraper from './components/Wraper';

import { IPermission } from '../interfaces/permission';

interface IComponentWithPermission {
  permissions: IPermission;
}

type IRouteProps = RouteProps & {
  component: React.ComponentType<IComponentWithPermission>;
  checkPermissions?: boolean;
  isMenu?: boolean;
  originalRouteOfDB: string;
};

const PrivateRoute: React.FC<IRouteProps> = ({
  component: Component,
  checkPermissions = false,
  isMenu = false,
  originalRouteOfDB = '',
  ...rest
}) => {
  const { user, permission } = useAuth();

  // Retira a primeira barra Ex: /proposals/create para proposals/create
  const pathWithoutFirstBar = (rest.path as string)?.slice(1);

  let objectWithAllPermissionOfThePath: any;
  let specificPermissionOfTheRoute = false;

  /*
   * checkPermissions vem das rotas que devem ter suas permissões verificadas, senão é
   * uma página do sistema que não tem permissões vindas do banco.
   */
  if (checkPermissions && permission && permission.length > 0) {
    /*
     * Retira a route original e deixa apenas a permissão e os parametros (se houver).
     * Se permissionsAndParamsWithBar for igual a "" a rota é de listagem.
     * Ex: /proposals para "" ou /proposals/create para /create ou /proposals/update/:id para /update/:id
     */
    const permissionsAndParamsWithBar = pathWithoutFirstBar.replace(
      originalRouteOfDB,
      '',
    );

    /*
     * Se permissionsAndParamsWithBar for igual a "" a rota é de listagem e recebe permissão de read
     * Retira as barras e os parametros e deixa apenas a permissão.
     * Ex: /create para create ou /update/:id para update
     * A posição é 1 em permissionsAndParamsWithBar.split('/')[1], pois a primeira barra gera uma posição no array:
     * Ex: /create para ["", create] ou /update/:id para ["", update, :id]
     */
    const onlyPermissionsOfThePath =
      permissionsAndParamsWithBar === ''
        ? 'read'
        : permissionsAndParamsWithBar.split('/')[1];

    objectWithAllPermissionOfThePath = permission?.find(
      i => `${i.route}` === originalRouteOfDB,
    );

    if (objectWithAllPermissionOfThePath === undefined) {
      specificPermissionOfTheRoute = false;
    } else {
      specificPermissionOfTheRoute =
        objectWithAllPermissionOfThePath[onlyPermissionsOfThePath];
    }
  } else if (
    // Verifica se é uma página do sistema que não tem permissões vindas do banco. No caso apenas a home
    pathWithoutFirstBar === 'home' &&
    !checkPermissions &&
    permission &&
    permission.length > 0
  ) {
    specificPermissionOfTheRoute = true;
  }

  return user && specificPermissionOfTheRoute ? (
    <>
      {((rest.path === '/manufacturing/workflow' ||
        rest.path === '/projects-overview') && (
        <Wraper pathProp={rest.path}>
          <Component permissions={objectWithAllPermissionOfThePath} />
        </Wraper>
      )) || (
        <Wraper>
          <Component permissions={objectWithAllPermissionOfThePath} />
        </Wraper>
      )}
    </>
  ) : (
    <Navigate to="/" />
  );
};

export default PrivateRoute;
