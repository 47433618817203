import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { FiEdit } from 'react-icons/fi';

import api from '../../../services/api';
import { useAuth } from '../../../context/AuthContext';
import { IUser, IUserResponse } from '../../../interfaces/user';

import { Container, Content } from './styles';
import ListingTemplate from '../../../components/Template/Listing';

import { IPermission } from '../../../interfaces/permission';

interface PermissionDTO {
  permissions: IPermission;
}

const ListUsers: React.FC<PermissionDTO> = ({ permissions }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [users, setUsers] = useState<IUser[]>([]);
  const [listUsersPaginated, setListUsersPaginated] = useState<IUser[]>([]);

  const { user, permission } = useAuth();

  const hasPermissions: {
    toCreate: boolean;
    toUpdate: boolean;
    toRead: boolean;
  } = useMemo(() => {
    const permissionItem = permission.find(i => i.route === 'users');

    return {
      toCreate: permissionItem && permissionItem.create,
      toUpdate: permissionItem && permissionItem.update,
      toRead: permissionItem && permissionItem.read,
    };
  }, [permission]);

  useEffect(() => {
    setLoading(true);

    api
      .get<IUserResponse[]>('users')
      .then(response => {
        setUsers(response.data);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        console.error(error);
      });
  }, []);

  const handleListUsersPaginatedItems = useCallback(newList => {
    setListUsersPaginated(newList);
  }, []);

  return (
    <ListingTemplate
      title="Listagem de Usuários"
      module="users"
      listItemsProps={users}
      handleListItemsPaginatedItems={handleListUsersPaginatedItems}
      placeHolderProp="Cód. ou Nome"
      displaySearchArea={false}
      disabled={loading}
      permissions={permissions}
      permissionSpecificOfTheModuleForNewButton={user.isAdmin}
    >
      <Container>
        <Content>
          {loading ? (
            <p>carregando ...</p>
          ) : (
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Nome</th>
                  <th>Cognome</th>
                  <th>Username</th>
                  <th>Administrador?</th>
                  <th>Ativo?</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {hasPermissions.toRead ? (
                  listUsersPaginated.map(u => (
                    <tr key={String(u.id)}>
                      <td>{u.id}</td>
                      <td>{u.name}</td>
                      <td>{u.nickname}</td>
                      <td>{u.username}</td>
                      <td>{u.isAdmin ? 'Sim' : 'Não'}</td>
                      <td>{u.isActive ? 'Sim' : 'Não'}</td>
                      <td>
                        {user.id === u.id ? (
                          <Link to={`/users/update/${u.id}`}>
                            <FiEdit size={16} color="var(--azul-5bits)" />
                          </Link>
                        ) : null}

                        {user.id !== u.id && hasPermissions.toUpdate ? (
                          <Link to={`/users/update/${u.id}`}>
                            <FiEdit size={16} color="var(--azul-5bits)" />
                          </Link>
                        ) : null}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>{user.id}</td>
                    <td>{user.name}</td>
                    <td>{user.nickname}</td>
                    <td>{user.username}</td>
                    <td>{user.isAdmin ? 'Sim' : 'Não'}</td>
                    <td>{user.isActive ? 'Sim' : 'Não'}</td>
                    <td>
                      {(permissions.update && (
                        <Link to={`/users/update/${user.id}`}>
                          <FiEdit size={16} color="var(--azul-5bits)" />
                        </Link>
                      )) || (
                        <span>
                          <FiEdit size={16} color="var(--cinza-escuro-5bits)" />
                        </span>
                      )}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </Content>
      </Container>
    </ListingTemplate>
  );
};

export default ListUsers;
