import styled, { css } from 'styled-components';

import expandMore from '../../../../../../assets/expandMoreGray24px.svg';

interface IHistoric {
  visibleHistoricTableProp: boolean;
}

interface IPrevisionDatesProp {
  widthWithButtonAlterPrevision: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  width: 91vw;
  height: 100%;
  /* max-height: 80vh; */
  padding: 3rem 3rem;
  background-color: var(--branco-puro-5bits);

  h1 {
    text-transform: none;
    font-weight: 500;
  }
`;

export const ModalLoadingForModal = styled.div`
  /* Modal loading */
  > div:first-child {
    position: absolute;
    width: 91vw;
    height: 100%;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  height: 71vh;
  max-height: 100%;
  width: 100%;

  overflow: auto;
`;

export const TitleAndOSNumber = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
  height: 8.2rem;

  border-bottom: 1px solid var(--cinza-claro-5bits);

  > span {
    font-style: normal;
    font-size: 2.4rem;
    line-height: 3.2rem;
  }

  > span:nth-child(1) {
    font-weight: bold;
    color: var(--azul-5bits);
  }

  > span:nth-child(2) {
    margin: 0 0.5rem;
    font-weight: 500;
    color: var(--cinza-escuro-5bits);
  }

  > span:nth-child(3) {
    font-weight: bold;
    color: var(--laranja-5bits);
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;

  padding: 2rem 0;

  border-bottom: 1px solid var(--cinza-claro-5bits);
`;

export const HeaderTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;

  > div {
    display: flex;
    justify-content: center;
    align-items: flex-start;

    width: fit-content;

    padding-right: 3.8rem;

    font-style: normal;
    font-size: 16px;
    line-height: 21px;
    color: var(--azul-5bits);

    span {
      font-weight: 500;

      > span {
        font-weight: bold;
      }
    }
  }

  /* Etapa */
  > div:nth-child(1) {
    justify-content: flex-start;
    border-right: 1px solid var(--cinza-claro-5bits);

    span {
      > span {
        text-transform: uppercase;
      }
    }
  }

  /* Tempo Planejado */
  > div:nth-child(2) {
    justify-content: flex-start;
    height: 100%;
    padding-left: 1rem;
    border-right: 1px solid var(--cinza-claro-5bits);
  }

  /* Prazo de entrega da etapa */
  > div:nth-child(3) {
    justify-content: flex-start;
    padding-left: 1rem;
  }
`;

export const HeaderInformation = styled.div`
  width: 100%;

  margin-top: 2rem;

  > div {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    width: 100%;

    font-style: normal;
    font-size: 16px;
    line-height: 21px;
    color: var(--cinza-escuro-5bits);

    span {
      font-weight: 500;

      > span {
        font-weight: bold;
        text-transform: uppercase;
      }
    }
  }
`;

export const HistoricContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;

  padding: 2rem 0;

  border-bottom: 1px solid var(--cinza-claro-5bits);

  cursor: pointer;
`;

export const HistoricTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;

  > span {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    color: var(--azul-5bits);
  }

  > div {
    display: flex;
    align-items: center;
    > span {
      font-style: normal;
      font-weight: 900;
      font-size: 16px;
      line-height: 21px;
      text-align: right;

      color: var(--cinza-escuro-5bits);
    }

    //Asterisco
    > span:nth-child(2) {
      color: var(--laranja-5bits);
    }
  }
`;

export const ButtonExpandHistoric = styled.div<IHistoric>`
  width: 1.8rem;
  height: 1.1rem;
  margin-right: 1rem;

  border-radius: 50%;

  background-image: url(${expandMore});
  background-repeat: no-repeat;
  background-position: center;

  cursor: pointer;

  ${props =>
    (props.visibleHistoricTableProp &&
      css`
        transform: rotate(180deg);
        transition: transform 500ms linear;
      `) ||
    css`
      transform: rotate(0);
      transition: transform 500ms linear;
    `}
`;

export const HistoricTable = styled.div<IHistoric>`
  display: block;
  width: 100%;
  max-height: 0;

  overflow: auto;

  transition: max-height 0.2s ease-out;

  ${props =>
    props.visibleHistoricTableProp &&
    css`
      max-height: 16.4rem;

      margin-top: 2.2rem;
      margin-bottom: 2.5rem;
    `}

  table {
    background: var(--branco-puro-5bits);
    font-size: 1.4rem;

    width: 100%;
    max-width: 1100px;

    border-spacing: 0;
    border-collapse: collapse;
    border-radius: 4px;

    table,
    th,
    td {
      border: 1px solid var(--cinza-claro-5bits);
    }

    thead th {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      color: var(--cinza-escuro-5bits);
      padding: 10px 8px;
    }

    tbody {
      color: var(--cinza-escuro-5bits);

      /* Linhas Pares */
      tr:nth-child(even) {
        background-color: var(--branco-gelo-5bits);
      }
      /* Linhas Impares */
      tr:nth-child(odd) {
        background-color: var(--branco-puro-5bits);
      }

      tr:hover {
        background-color: #f0f0f0;
      }

      tr {
        td {
          padding: 0.9rem 0.8rem;

          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 21px;
        }
      }
    }

    tr {
      th {
        text-align: left;
        background-color: var(--branco-gelo-5bits);
      }
      //Início
      th:nth-child(1) {
        text-align: right;
        width: 20.1rem;
      }
      td:nth-child(1) {
        text-align: right;
      }
      //Fim
      th:nth-child(2) {
        text-align: right;
        width: 20.1rem;
      }
      td:nth-child(2) {
        text-align: right;
      }
      //Justificativa
      th:nth-child(3) {
        text-align: left;
        width: auto;
      }
      td:nth-child(3) {
        text-transform: none;
      }
    }
  }
`;

export const PrevisionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;

  padding: 2rem 0 4.5rem;

  border-bottom: 1px solid var(--cinza-claro-5bits);
`;

export const PrevisionTitle = styled.div`
  width: 100%;
  margin-bottom: 2.2rem;

  > span {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    color: var(--azul-5bits);
  }
`;

export const PrevisionDates = styled.div<IPrevisionDatesProp>`
  width: 100%;

  form {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 66%;

    > div {
      width: 34.8rem;
      > label {
        margin-left: 2rem;
      }
    }

    > button {
      width: fit-content;
      height: 3.7rem;

      margin-top: 1.8rem;
      margin-right: 3.1rem;

      svg {
        margin-right: 1rem;
      }
    }

    ${props =>
      props.widthWithButtonAlterPrevision &&
      css`
        width: 95%;
      `}
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;

  padding: 2rem 0 4.5rem;
`;

export const ActionsTitle = styled.div`
  width: 100%;
  margin-bottom: 2.2rem;

  > span {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    color: var(--azul-5bits);
  }
`;
export const ActionsButtons = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  width: 100%;

  > button {
    width: 17.2rem;
    height: 3.7rem;

    margin-right: 3.1rem;

    img {
      margin-right: 1rem;
    }

    svg {
      margin-right: 1rem;
    }
  }
`;

export const ActionCloseModal = styled.div`
  position: absolute;
  top: 1.3rem;
  right: 1.3rem;
  cursor: pointer;

  > svg {
    path {
      stroke: var(--azul-5bits);
    }
  }
`;
