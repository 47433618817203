import React, { useState, useEffect, useCallback, HTMLAttributes } from 'react';

import {
  ContainerOSOnStage,
  OSOnStage,
  ButtonExpandDescriptionOS,
  ContainerDescriptionOS,
  DescriptionOS,
} from './styles';

interface IOSOnStage {
  id: number;
  stageName: string;
  numberOS: number;
  deliveryDate: string;
  descriptions: Array<any>;
}

interface ICardOSOnStage extends HTMLAttributes<HTMLDivElement> {
  idCompanyProp: number;
  idCompanyClicked?: number;
  companyName: string;
  itemOS: IOSOnStage;
  colorBorderBlue: boolean;
}

const CardOSOnStage: React.FC<ICardOSOnStage> = ({
  idCompanyProp,
  idCompanyClicked = idCompanyProp,
  companyName,
  itemOS,
  colorBorderBlue,
  ...rest
}) => {
  const [idCompany, setIdCompany] = useState<number>();
  const [visibleDescriptionOS, setVisibleDescriptionOS] = useState(false);

  useEffect(() => {
    setIdCompany(idCompanyProp);
  }, []);

  useEffect(() => {
    if (idCompany !== idCompanyClicked) setVisibleDescriptionOS(false);
  }, [idCompany, idCompanyClicked]);

  const handleOpenDescriptionOS = useCallback(() => {
    setVisibleDescriptionOS(!visibleDescriptionOS);
  }, [visibleDescriptionOS]);

  return (
    <ContainerOSOnStage {...rest}>
      <OSOnStage colorBorderBlueProp={colorBorderBlue}>
        <span>{companyName}</span>
        <ButtonExpandDescriptionOS
          onClick={handleOpenDescriptionOS}
          visibleDescriptionOSProp={visibleDescriptionOS}
        />
        <span>
          <span>Nº da OS:</span> {itemOS.stageName}
        </span>
        <span>
          <span>Data de entrega:</span> {itemOS.numberOS}
        </span>
        <ContainerDescriptionOS visibleDescriptionOSProp={visibleDescriptionOS}>
          <span>Descrição:</span>
          <DescriptionOS>
            {itemOS.descriptions.map((itemDescription: any, index: number) => (
              <span key={String(index)}>{itemDescription.description}</span>
            ))}
          </DescriptionOS>
        </ContainerDescriptionOS>
      </OSOnStage>
    </ContainerOSOnStage>
  );
};

export default CardOSOnStage;
