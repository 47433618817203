import styled from 'styled-components';

export const Container = styled.button`
  position: relative;
  background: var(--button-bg-color);
  height: 45px;
  border-radius: 25px;
  border: 0;
  padding: 0 22.5px;
  color: var(--button-text-color);
  width: 100%;
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 2.4rem;
  text-align: center;
  text-transform: uppercase;
  transition: background-color 0.2s;

  display: flex;
  justify-content: center;
  align-items: center;

  &:disabled {
    filter: brightness(0.8);
    cursor: not-allowed;

    &:hover {
      filter: brightness(0.8);
    }
  }

  &:hover {
    filter: brightness(0.9);
  }
`;
