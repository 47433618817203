import { format, parseISO } from 'date-fns';

function convertDateToDB(date: string | Date): string {
  if (date && (typeof date === 'string' || typeof date === 'object')) {
    const regexp = new RegExp(
      /^\d{4}(-)(((0)[0-9])|((1)[0-2]))(-)([0-2][0-9]|(3)[0-1])/i,
    );

    if (typeof date === 'string') {
      let dateEnObjDate: Date;

      const regexpBR = new RegExp(
        /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}/i,
      );

      if (regexpBR.test(date)) {
        const [day, month, year] = date.split('/');

        dateEnObjDate = parseISO(`${year}-${month}-${day}`);
      } else {
        dateEnObjDate = parseISO(date);
      }

      const dateFormatted = format(dateEnObjDate, 'yyyy-MM-dd');

      if (!regexp.test(dateFormatted)) {
        throw new Error('Erro na conversão da data');
      }

      return dateFormatted;
    }
    if (typeof date === 'object') {
      const dateFormatted = format(date, 'yyyy-MM-dd');

      if (!regexp.test(dateFormatted)) {
        throw new Error('Erro na conversão da data');
      }

      return dateFormatted;
    }
  }
  return '';
}

export default convertDateToDB;
