import styled, { css } from 'styled-components';

interface IContainerCompanyStage {
  colorHRBlue: boolean;
}

export const Container = styled.div`
  height: 78vh;

  h1 {
    margin-bottom: 2.7rem;

    text-transform: capitalize;
    font-style: normal;
    font-weight: 500;
    font-size: 2.4rem;
    line-height: 3.2rem;
  }
`;

export const Content = styled.div`
  /*100vh - header - margin-top - area botão voltar - h1 com margin bottom somada - margin-bottom - footer */
  height: calc(100vh - 81px - 20px - 33px - 59px - 6px - 29px);

  width: 100vw;
  padding-right: 1.7rem;
  overflow: auto;

  /* :after {
    content: '';
    box-shadow: 27px 0 16px 63px #e5e5;
    top: 0;
    right: 0;
    height: 100%;
    position: fixed;
    z-index: 0;
  } */
`;

export const ContainerProductionStages = styled.div`
  display: flex;
  flex-direction: column;

  width: fit-content;
  /* min-height: 69vh;
  max-height: 69vh; */

  position: relative;

  /* Sem o translateZ o scroll fica escondido abaixo da div com o shadow */
  /* transform: translateZ(0); */

  /* > div:last-child {
    box-shadow: 27px 0 16px 63px #e5e5;
    right: 0;
    height: 100%;
    position: fixed;
    z-index: 1;
  } */

  /* transform: translateZ(0);
  :before {
    content: '';
    right: 0;
    height: 100%;
    position: fixed;
    z-index: 1;
    width: 4vw;
    /* retirar o header, footer, as margens e as bordas*/
  /* height: calc(100vh - 81px - 29px - 40px - 3px);
    box-shadow: 27px 0 16px 63px #e5e5;
    -webkit-box-shadow: inset 0 -17px 0px 0px #e5e5e5,
      inset 0 -16px 0px 0px #e5e5e5, inset -39px 0 0px -40px #e5e5,
      inset -44px -8px 16px -5px #e5e5;
    -moz-box-shadow: inset 0 -17px 0px 0px #e5e5e5,
      inset 0 -16px 0px 0px #e5e5e5, inset -39px 0 0px -40px #e5e5,
      inset -44px -8px 16px -5px #e5e5;
    box-shadow: inset -39px 0 0px -40px #e5e5, inset -44px -8px 16px -5px #e5e5;
  }  */
`;

export const ContainerTitleStages = styled.div`
  display: flex;
`;

export const TitleStage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  min-width: 264px;
  max-width: 264px;
  height: 100%;
  border-right: 1px solid rgba(171, 171, 171, 0.5);

  > span {
    width: 100%;
    align-items: flex-start;

    padding: 0 2rem;
    margin: 2.6rem 0 2.6rem 0;

    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    color: var(--laranja-5bits);
  }
`;

export const ContainerCompanyStage = styled.div<IContainerCompanyStage>`
  display: flex;
  position: relative;

  /* Pega a largura do conteúdo interno */
  width: fit-content;

  margin-right: 4rem;

  hr {
    z-index: -1;
    position: absolute;
    top: 6.64vh;
    width: 97%;
    ${props =>
      (props.colorHRBlue &&
        css`
          border: 1px solid var(--azul-5bits);
        `) ||
      css`
        border: 1px solid var(--laranja-5bits);
      `}

    margin: 0 2rem;
  }

  /* justify-content: initial;

  width: 100%;
  height: 13.29vh;

   */
`;

export const ContainerOSOnStageEmpty = styled.div`
  min-width: 264px;
  max-width: 264px;
  min-height: 112px;
  max-height: 268px;
  padding: 0 2rem 1rem 2rem;
  border-right: 1px solid rgba(171, 171, 171, 0.5);
`;
