/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState, useCallback } from 'react';

import { Link } from 'react-router-dom';
import { FiEdit } from 'react-icons/fi';
import { useToast } from '../../../../../context/ToastContext';

import { Container, Content } from './styles';
import api from '../../../../../services/api';

import ListingTemplate from '../../../../../components/Template/Listing';

import { IPermission } from '../../../../../interfaces/permission';
import { IManufacturingList } from '../../../../../interfaces/manufacturingList';

import convertInitToUppercase from '../../../../../utils/convertInitToUppercase';
import convertDateToBr from '../../../../../utils/convertDateToBr';

interface PermissionDTO {
  permissions: IPermission;
}

const ListManufacturingProjects: React.FC<PermissionDTO> = ({
  permissions,
}) => {
  const { addToast } = useToast();
  const [loading, setLoading] = useState<boolean>(false);
  const [listManufacturingProjects, setListManufacturingProjects] = useState<
    IManufacturingList[]
  >([]);
  const [
    listManufacturingProjectsPaginated,
    setListManufacturingProjectsPaginated,
  ] = useState<IManufacturingList[]>([]);

  useEffect(() => {
    const getProvider = async (): Promise<void> => {
      try {
        setLoading(true);

        const response: any = await api.get(`manufacturing/active`);

        if (response.data.length > 0) {
          const manufacturingProjectsList: IManufacturingList[] = [
            ...response.data,
          ];

          setListManufacturingProjects(manufacturingProjectsList);
          setListManufacturingProjectsPaginated(manufacturingProjectsList);
        }
        setLoading(false);
      } catch (error: any) {
        setLoading(false);

        if (error.response) {
          const { data } = error.response; // Error vindo do back está em data dentro de response
          addToast({
            type: 'error',
            title: data.message,
          });
          return;
        }

        addToast({
          type: 'error',
          title: 'Erro ao listar!',
          description: `Error: ${error}`,
        });
      }
    };
    getProvider();
  }, [addToast]);

  const handleListManufacturingProjectsPaginatedItems = useCallback(newList => {
    setListManufacturingProjectsPaginated(newList);
  }, []);

  return (
    <ListingTemplate
      title="Produção"
      module="manufacturing/active"
      listItemsProps={listManufacturingProjects}
      handleListItemsPaginatedItems={
        handleListManufacturingProjectsPaginatedItems
      }
      displaySearchArea={false}
      displayButtonNew={false}
      disabled={loading}
      permissions={permissions}
      perPageForPaginationProp={30}
    >
      <Container>
        <Content>
          {loading ? (
            <p>carregando ...</p>
          ) : (
            <table>
              <thead>
                <tr>
                  <th>Nº</th>
                  <th>Título</th>
                  <th>Descrição</th>
                  <th>Cliente</th>
                  <th>Entrega</th>
                  <th>Atraso</th>
                  <th>Status</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {listManufacturingProjectsPaginated.map(
                  (manufacturingItem: IManufacturingList) => (
                    <tr key={String(manufacturingItem.id)}>
                      <td>{manufacturingItem.id}</td>
                      <td>{manufacturingItem.title}</td>
                      <td>{manufacturingItem.description}</td>
                      <td>
                        {convertInitToUppercase(manufacturingItem.client)}
                      </td>
                      <td>
                        {convertDateToBr(manufacturingItem.forecastEndDate)}
                      </td>
                      <td>{manufacturingItem.longestDelayDays}</td>
                      {(manufacturingItem.isManufacturingBlocked && (
                        <td style={{ color: 'var(--azul-5bits)' }}>
                          EXECUTANDO
                        </td>
                      )) || (
                        <td style={{ color: 'var(--verde-5bits)' }}>
                          LIBERADO{' '}
                        </td>
                      )}

                      <td>
                        <>
                          {(permissions.update && (
                            <Link
                              to={`/manufacturing/update/${manufacturingItem.id}`}
                              state={{
                                isManufacturingBlocked: manufacturingItem.isManufacturingBlocked
                                  ? 1
                                  : 0,
                              }}
                            >
                              <FiEdit size={16} color="var(--azul-5bits)" />
                            </Link>
                          )) || (
                            <FiEdit
                              size={16}
                              color="var(--cinza-escuro-5bits)"
                            />
                          )}
                        </>
                      </td>
                    </tr>
                  ),
                )}
              </tbody>
            </table>
          )}
        </Content>
      </Container>
    </ListingTemplate>
  );
};

export default ListManufacturingProjects;
