import React, {
  useRef,
  useState,
  useEffect,
  InputHTMLAttributes,
  KeyboardEvent,
  useCallback,
} from 'react';
import { IconBaseProps } from 'react-icons';
import { useField } from '@unform/core';
import fincalc from 'fincalc';
import { FiAlertCircle } from 'react-icons/fi';
import convertNumberFloatToDB from '../../utils/convertNumberFloatToDB';
import Error from '../../styles/errorFieldGlobal';
import { Container, Content } from './styles';
import { useToast } from '../../context/ToastContext';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  labelText?: string;
  icon?: React.ComponentType<IconBaseProps>;
}

const VALID_FIRST = /^[1-9]{1}$/;
const VALID_NEXT = /^[0-9]{1}$/;
const DELETE_KEY_CODE = 'Backspace';
const TAB_KEY_CODE = 'Tab';

const InputTwoFieldsForHours: React.FC<
  InputProps & { autocomplete?: string }
> = ({
  name,
  labelText,
  icon: Icon,
  disabled,
  max = Number.MAX_SAFE_INTEGER,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { addToast } = useToast();

  const {
    fieldName,
    defaultValue,
    error,
    clearError,
    registerField,
  } = useField(name);

  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [isFilled, setIsFilled] = useState<boolean>(false);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);

    if (inputRef.current) {
      setIsFilled(!!inputRef.current.value);
    }
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue: (ref, value: string) => {
        if (value) {
          if (
            value.indexOf(':') !== -1 &&
            value.length - 1 - value.indexOf(':') === 2
          ) {
            const valueWithoutPoint = value.replace('.', '');
            const valueArrayOfTheHourAndMinute = valueWithoutPoint.split(':');

            const hourInInt = parseInt(valueArrayOfTheHourAndMinute[0], 10);

            const valueDisplay =
              hourInInt < 10
                ? `0${hourInInt}:${valueArrayOfTheHourAndMinute[1]}`
                : `${hourInInt}:${valueArrayOfTheHourAndMinute[1]}`;

            // eslint-disable-next-line no-param-reassign
            ref.value = `${valueDisplay}`;
            return;
          }
          // eslint-disable-next-line no-param-reassign
          ref.value = ``;
          addToast({
            type: 'error',
            title: 'Error',
            description: `Formato incorreto campo ${name}!`,
          });
        }
      },
    });
  }, [addToast, fieldName, name, registerField]);

  const handleKeyDown = useCallback(
    // eslint-disable-next-line consistent-return
    (e: KeyboardEvent<HTMLDivElement>) => {
      clearError();

      const { key } = e;
      let valueActual = 0;

      if (key === TAB_KEY_CODE) {
        return;
      }

      e.preventDefault(); // Evita que o campo receba o valor digitado (0,011 evita duplicar o digito)

      if (inputRef.current) {
        const valueFormattedToComma = inputRef.current.value
          .toString()
          .replace(':', ',');

        valueActual = parseInt(
          fincalc(
            convertNumberFloatToDB(valueFormattedToComma) * 100,
          ).toString(),
          10,
        );

        if (
          (valueActual === 0 && !VALID_FIRST.test(key)) ||
          (valueActual !== 0 &&
            !VALID_NEXT.test(key) &&
            key !== DELETE_KEY_CODE)
        ) {
          e.preventDefault();
          return;
        }

        const valueString = valueActual.toString();
        let nextValue: number;
        if (key !== DELETE_KEY_CODE) {
          const nextValueString: string =
            valueActual === 0 ? key : `${valueString}${key}`;
          nextValue = Number.parseInt(nextValueString, 10);
        } else {
          const nextValueString = valueString.slice(0, -1);
          nextValue =
            nextValueString === '' ? 0 : Number.parseInt(nextValueString, 10);
        }
        if (nextValue > max) {
          return;
        }

        const valueResult = fincalc(nextValue / 100).toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
        });

        const valueWithTwoPoints = valueResult.toString().replace(',', ':');
        const valueWithoutPoint = valueWithTwoPoints
          .toString()
          .replace('.', '');
        const valueArrayOfTheHourAndMinute = valueWithoutPoint.split(':');

        const hourInInt = parseInt(valueArrayOfTheHourAndMinute[0], 10);

        const valueDisplay =
          hourInInt < 10
            ? `0${hourInInt}:${valueArrayOfTheHourAndMinute[1]}`
            : `${hourInInt}:${valueArrayOfTheHourAndMinute[1]}`;

        inputRef.current.value = `${valueDisplay}`;
      }
    },
    [clearError, max],
  );

  const handleChange = useCallback((): void => {
    if (error) {
      clearError();
    }
  }, [clearError, error]);

  return (
    <Container>
      {labelText && <label htmlFor={`id-${name}`}>{labelText}</label>}
      <Content
        isErrored={!!error}
        isFocused={isFocused}
        isFilled={isFilled}
        isDisabled={!!disabled}
      >
        <div>
          {Icon && <Icon size={20} />}
          <input
            id={`id-${name}`}
            type="text"
            defaultValue={defaultValue}
            ref={inputRef}
            onFocus={(): void => setIsFocused(true)}
            onBlur={handleInputBlur}
            onKeyDown={handleKeyDown}
            onChange={handleChange}
            disabled={disabled}
            {...rest}
          />
        </div>
        <div>
          <span>hrs</span>
        </div>
      </Content>
      {error && (
        <Error>
          <FiAlertCircle color="var(--on-errored-message-bg-color)" size={20} />
          {error}
        </Error>
      )}
    </Container>
  );
};

export default InputTwoFieldsForHours;
