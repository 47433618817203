import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  width: 90vw;
  max-height: 80vh;
  padding: 4.2rem 2rem 2rem 2rem;
  background-color: var(--branco-puro-5bits);

  //Medias
  @media (min-width: 0px) and (max-width: 759px) {
    max-height: none;
    padding: 0;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  height: 50%;
  max-height: 50%;
  width: 100%;

  h1 {
    text-transform: none;
    font-weight: 500;
    margin-top: 3rem;
    margin-bottom: 4.2rem;
  }

  > form {
    width: 100%;
  }

  //Medias
  @media (min-width: 0px) and (max-width: 759px) {
    max-height: 100%;

    h1 {
      margin-top: 3rem;
      margin-bottom: 2rem;
    }
  }
`;

export const SearchArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  margin-bottom: 7.3rem;

  > div {
    max-width: 42rem;

    button {
      width: 30%;
      background-color: var(--azul-5bits);
      text-transform: capitalize;
    }
  }

  //Medias
  @media (min-width: 0px) and (max-width: 759px) {
    margin-bottom: 4rem;
  }
`;

export const ItensArea = styled.div`
  width: 100%;
  max-height: 50vh;
  overflow: auto;

  //Medias
  @media (min-width: 0px) and (max-width: 759px) {
    margin-bottom: 4.2rem;
  }
`;

export const SnipeLoading = styled.div`
  display: flex;
  justify-content: center;
`;
export const MessageError = styled.div`
  display: flex;
  justify-content: center;
`;

export const SplitDrawing = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  gap: 1.4rem;

  width: 100%;
  margin-bottom: 1.6rem;

  > div {
    flex: 1;
    min-width: 30rem;
    label {
      margin-left: 2.5rem;
    }
  }

  /*
    * RevisionNumberAndRevisionDateAndFile este seletor sobrepõe o css
    * do item definido abaixo.
  */
  > div:last-child {
    min-width: 39.1rem;
  }

  //Medias
  @media (min-width: 0px) and (max-width: 759px) {
    flex-direction: column;
    align-items: flex-start;

    > div {
      width: 100%;
      min-width: auto;
      label {
        margin-left: 1rem;
      }
    }

    /*
    * RevisionNumberAndRevisionDateAndFile este seletor sobrepõe o css
    * do item definido abaixo.
    */
    > div:last-child {
      min-width: auto;
    }
  }

  @media (min-width: 760px) and (max-width: 999px) {
  }
`;

export const RevisionDateAndNumberAndQuantityAndActionModal = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  width: 100%;
  gap: 1.4rem;

  //revisionNumber
  > div:nth-child(1) {
    width: 7.8rem;
  }

  //revisionDate
  > div:nth-child(2) {
    width: 13.5rem;
  }

  //btLoadNewDrawing
  > button:nth-child(3) {
    width: fit-content;

    width: 11.7rem;
    background: var(--azul-5bits);
    font-style: normal;
    font-weight: bold;
    font-size: 1.4rem;
    text-transform: capitalize;
    line-height: 1.9rem;
  }

  //RiCheckLine
  > div:last-child {
    display: flex;
    align-items: center;
    height: 4.5rem;
    width: auto;
    cursor: pointer;

    > svg {
      path {
        stroke: var(--azul-5bits);
      }
    }
  }
`;

export const ActionCloseModal = styled.div`
  position: absolute;
  top: 0rem;
  right: 1.2rem;
  cursor: pointer;

  > svg {
    path {
      stroke: var(--azul-5bits);
    }
  }

  //Medias
  @media (min-width: 0px) and (max-width: 759px) {
    right: 0rem;
  }
`;
