/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  useRef,
  useState,
  useEffect,
  FC,
  InputHTMLAttributes,
  KeyboardEvent,
  useCallback,
} from 'react';
import { IconBaseProps } from 'react-icons';
import { useField } from '@unform/core';
import accounting from 'accounting';

import { FiAlertCircle } from 'react-icons/fi';
import convertNumberFloatToDB from '../../utils/convertNumberFloatToDB';

import Error from '../../styles/errorFieldGlobal';
import { Container, Content } from './styles';
import convertNumberFloatToBr from '../../utils/convertNumberFloatToBr';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  labelText?: string;
  icon?: React.ComponentType<IconBaseProps>;
}

const VALID_FIRST = /^[1-9]{1}$/;
const VALID_NEXT = /^[0-9]{1}$/;
const DELETE_KEY_CODE = 'Backspace';
const TAB_KEY_CODE = 'Tab';

const CurrencyInput: FC<Props> = ({
  name,
  labelText,
  icon: Icon,
  disabled,
  readOnly,
  max = Number.MAX_SAFE_INTEGER,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  const {
    fieldName,
    defaultValue = '0,000',
    error,
    clearError,
    registerField,
  } = useField(name);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);

    if (inputRef.current) {
      setIsFilled(!!inputRef.current.value);
    }
  }, []);

  function formatValueOnSetValue(value: any): string {
    let valueActual = 0;
    if (value) {
      valueActual = parseInt(value.toString().replaceAll(/[^0-9]/g, ''), 10);

      const valueDisplay = (valueActual / 1000).toLocaleString('pt-BR', {
        minimumFractionDigits: 3,
      });

      return valueDisplay;
    }
    return '0';
  }

  // Registra input no Form
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue: (ref, value) => {
        // eslint-disable-next-line no-param-reassign
        ref.value = formatValueOnSetValue(value);
      },
    });
  }, [fieldName, registerField]);

  const handleChange = useCallback((): void => {
    if (error) {
      clearError();
    }
  }, [clearError, error]);

  const handleKeyDown = useCallback(
    // eslint-disable-next-line consistent-return
    (e: KeyboardEvent<HTMLDivElement>) => {
      const { key } = e;
      let valueActual = 0;

      if (key === TAB_KEY_CODE) {
        return;
      }

      e.preventDefault(); // Evita que o campo receba o valor digitado (0,011 evita duplicar o digito)

      if (inputRef.current) {
        valueActual = parseInt(
          inputRef.current.value.toString().replaceAll(/[^0-9]/g, ''),
          10,
        );

        if (
          (valueActual === 0 && !VALID_FIRST.test(key)) ||
          (valueActual !== 0 &&
            !VALID_NEXT.test(key) &&
            key !== DELETE_KEY_CODE)
        ) {
          e.preventDefault();
          return;
        }

        const valueString = valueActual.toString();
        let nextValue: number;
        if (key !== DELETE_KEY_CODE) {
          const nextValueString: string =
            valueActual === 0 ? key : `${valueString}${key}`;
          nextValue = Number.parseInt(nextValueString, 10);
        } else {
          const nextValueString = valueString.slice(0, -1);
          nextValue =
            nextValueString === '' ? 0 : Number.parseInt(nextValueString, 10);
        }
        if (nextValue > max) {
          return;
        }

        const valueDisplay = (nextValue / 1000).toLocaleString('pt-BR', {
          minimumFractionDigits: 3,
        });

        inputRef.current.value = `${valueDisplay}`;
      }
    },
    [max],
  );

  return (
    <Container>
      {labelText && <label htmlFor={`id-${name}`}>{labelText}</label>}
      <Content
        isErrored={!!error}
        isFocused={isFocused}
        isFilled={isFilled}
        isDisabled={!!disabled}
        isReadOnly={!!readOnly}
      >
        {Icon && <Icon size={20} />}

        <input
          id={`id-${name}`}
          type="text"
          defaultValue={defaultValue}
          ref={inputRef}
          aria-describedby="basic-addon1"
          onFocus={(): void => setIsFocused(true)}
          onBlur={handleInputBlur}
          onKeyDown={handleKeyDown}
          onChange={handleChange}
          disabled={disabled}
          readOnly={readOnly}
          {...rest}
        />
      </Content>
      {error && (
        <Error>
          <FiAlertCircle color="var(--on-errored-message-bg-color)" size={20} />
          {error}
        </Error>
      )}
    </Container>
  );
};

export default CurrencyInput;
