/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState, useCallback } from 'react';

import { useToast } from '../../../context/ToastContext';

import api from '../../../services/api';

import ContainerOSOnStage from './components/ContainerOSOnStage';

import {
  Container,
  Content,
  ContainerProductionStages,
  ContainerTitleStages,
  TitleStage,
  ContainerCompanyStage,
  ContainerOSOnStageEmpty,
} from './styles';

import {
  IManufacturing,
  IManufacturingResponse,
} from '../../../interfaces/manufacturing';
import convertDateStringToDate from '../../../utils/convertDateStringToDate';

const etapas = [
  { id: 1, name: 'Corte' },
  { id: 2, name: 'Funilaria' },
  { id: 3, name: 'Usinagem' },
  { id: 4, name: 'Solda' },
  { id: 5, name: 'Montagem' },
];
const empresasFake = [
  {
    id: 1,
    name: 'Empresa 1',
    etapas: [
      {
        id: 1,
        stageName: 'Corte',
        numberOS: 123456,
        deliveryDate: '2021-06-23',
        descriptions: [
          {
            description:
              'Serviço de recuperação de rotor DES 0461-SR-0150-0005-01',
          },
          {
            description: 'Serviço de recuperação em rotor de dosador 4 peças',
          },
          {
            description: 'Serviço de recuperação em dosador 78 peças',
          },
        ],
      },
      {
        id: 2,
        stageName: 'Funilaria',
        numberOS: 233,
        deliveryDate: '2021-06-24',
        descriptions: [
          {
            description: 'Descrição 1 Funilaria',
          },
          {
            description: 'Descrição 2 Funilaria',
          },
        ],
      },
      {
        id: 4,
        stageName: 'Solda',
        numberOS: 233,
        deliveryDate: '2021-06-24',
        descriptions: [
          {
            description: 'Descrição 1 Solda',
          },
          {
            description: 'Descrição 2 Solda',
          },
        ],
      },
    ],
  },
  {
    id: 2,
    name: 'Empresa 2',
    etapas: [
      {
        id: 1,
        stageName: 'Corte',
        numberOS: 789,
        deliveryDate: '2021-06-23',
        descriptions: [
          {
            description: 'Descrição 1 Corte',
          },
          {
            description: 'Descrição 2 Corte',
          },
        ],
      },
      {
        id: 3,
        stageName: 'Usinagem',
        numberOS: 888,
        deliveryDate: '2021-06-24',
        descriptions: [
          {
            description: 'Descrição 1 Usinagem',
          },
          {
            description: 'Descrição 2 Usinagem',
          },
        ],
      },
      {
        id: 5,
        stageName: 'Montagem',
        numberOS: 888,
        deliveryDate: '2021-06-24',
        descriptions: [
          {
            description: 'Descrição 1 Montagem',
          },
          {
            description: 'Descrição 2 Montagem',
          },
        ],
      },
    ],
  },
  {
    id: 3,
    name: 'Empresa 3',
    etapas: [
      {
        id: 1,
        stageName: 'Corte',
        numberOS: 789,
        deliveryDate: '2021-06-23',
        descriptions: [
          {
            description: 'Descrição 1 Corte',
          },
          {
            description: 'Descrição 2 Corte',
          },
        ],
      },
      {
        id: 3,
        stageName: 'Usinagem',
        numberOS: 888,
        deliveryDate: '2021-06-24',
        descriptions: [
          {
            description: 'Descrição 1 Usinagem',
          },
          {
            description: 'Descrição 2 Usinagem',
          },
        ],
      },
      {
        id: 5,
        stageName: 'Montagem',
        numberOS: 888,
        deliveryDate: '2021-06-24',
        descriptions: [
          {
            description: 'Descrição 1 Montagem',
          },
          {
            description: 'Descrição 2 Montagem',
          },
        ],
      },
    ],
  },
];

const ListProductionFlow: React.FC = () => {
  const { addToast } = useToast();
  const [loading, setLoading] = useState<boolean>(false);
  const [listSteps, setListSteps] = useState<any>([]);
  const [listCompaniesOrdered, setListCompaniesOrdered] = useState<any>([]);
  const [idCompanyClicked, setIdCompanyClicked] = useState(0);

  useEffect(() => {
    setLoading(true);

    api
      .get<IManufacturingResponse[]>('manufacturing/steps')
      .then(response => {
        const responseFormatted = response.data.map(i => ({
          ...i,
          createdAt: convertDateStringToDate(i.createdAt),
          updatedAt: convertDateStringToDate(i.updatedAt),
        }));

        setListSteps(responseFormatted);

        setLoading(false);
      })
      .catch(error => {
        setLoading(false);

        if (error.response && error.response.data) {
          const { data } = error.response; // Error vindo do back está em data dentro de response
          addToast({
            type: 'error',
            title: data.message,
          });
          return;
        }

        addToast({
          type: 'error',
          title: 'Error',
          description: 'Erro ao listar!',
        });
      });
  }, [addToast]);

  useEffect(() => {
    setLoading(true);

    // const stagesFormatted: any = [...etapas];

    const companiesFormatted: any = [...empresasFake];

    // setListSteps(stagesFormatted);
    setListCompaniesOrdered(companiesFormatted);

    setLoading(false);
  }, [addToast]);

  const handleCloseDescriptionOfCompany = useCallback(
    (id: number) => {
      if (idCompanyClicked === 0) {
        setIdCompanyClicked(id);
      }
      setIdCompanyClicked(id);
    },
    [idCompanyClicked],
  );

  return (
    <Container>
      <h1>Produção</h1>
      <Content>
        <ContainerProductionStages>
          {listSteps && (
            <ContainerTitleStages>
              <>
                {listSteps.map((itemEtapa: any, index: number) => (
                  <TitleStage key={String(index)}>
                    <span>{itemEtapa.name}</span>
                  </TitleStage>
                ))}
              </>
            </ContainerTitleStages>
          )}
          {listCompaniesOrdered.map((itemEmpresa: any, index: number) => (
            <ContainerCompanyStage
              key={String(index)}
              colorHRBlue={index % 2 === 0}
            >
              {listSteps.map((itemEtapa: any, subIndex: number) => {
                const item = itemEmpresa.etapas.find(
                  (itemEtapasEmpresa: any) =>
                    itemEtapasEmpresa.id === itemEtapa.id,
                );

                return item !== undefined ? (
                  <ContainerOSOnStage
                    key={String(subIndex)}
                    idCompanyProp={itemEmpresa.id}
                    idCompanyClicked={idCompanyClicked}
                    companyName={itemEmpresa.name}
                    itemOS={item}
                    colorBorderBlue={index % 2 === 0}
                    onClick={() => {
                      handleCloseDescriptionOfCompany(itemEmpresa.id);
                    }}
                  />
                ) : (
                  <ContainerOSOnStageEmpty key={String(subIndex)} />
                );
              })}
              <hr />
            </ContainerCompanyStage>
          ))}
          <div />
        </ContainerProductionStages>
      </Content>
    </Container>
  );
};

export default ListProductionFlow;
