/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState, useCallback } from 'react';

import { Link } from 'react-router-dom';
import { FiEdit } from 'react-icons/fi';
import { AiOutlineFileAdd } from 'react-icons/ai';
import { RiDownloadCloud2Line, RiEyeLine } from 'react-icons/ri';
import { HiOutlineMail } from 'react-icons/hi';
import { FaRegCheckCircle } from 'react-icons/fa';
import { TiCancel } from 'react-icons/ti';
import { useToast } from '../../../context/ToastContext';

import api from '../../../services/api';

import ListingTemplate from './components/Listing';

import { businessProposalStatusValid } from '../../../data/businessProposalStatusValid.enum';

import { IPermission } from '../../../interfaces/permission';
import { IProject } from '../../../interfaces/project';
import { IBusinessProposalResponse } from '../../../interfaces/businessProposal';
import Button from '../../../components/Button';
import Loading from '../../../components/Loading';
import ModalConfirmationSendEmail from '../components/ModalConfirmationSendEmail';
import LinkWithTooltip from '../../../components/LinkWithTooltip';

import convertInitToUppercase from '../../../utils/convertInitToUppercase';

import {
  Container,
  Content,
  InfoPurchaseOrder,
  Actions,
  ItemActions,
} from './styles';

interface PermissionDTO {
  permissions: IPermission;
}

interface IBusinessProposalForList {
  id: number | null;
  projectId: number;
  clientName: string;
  description: string;
  purchaseOrderFileName: string;
  purchaseOrderNumber: string;
  currentSector: string;
  status: string;
  isCanceled: boolean;
}

const ListBusinessProposals: React.FC<PermissionDTO> = ({ permissions }) => {
  const { addToast } = useToast();
  const [loading, setLoading] = useState<boolean>(false);
  const [
    listProjectsWithBusinessProposals,
    setListProjectsWithBusinessProposals,
  ] = useState<IBusinessProposalForList[]>([]);
  const [
    listProjectsWithBusinessProposalsPaginated,
    setListProjectsWithBusinessProposalsPaginated,
  ] = useState<IBusinessProposalForList[]>([]);

  const getProvider = useCallback(async (): Promise<void> => {
    try {
      setLoading(true);

      const response: any = await api.get(`projects`);

      if (response.data.length > 0) {
        const projectWithFinishedQuoteAndInPlanningSector = response.data.filter(
          (item: IProject) => {
            return !!(
              item.finishedQuote === true &&
              (item.currentSector === 'Planejamento' ||
                item.currentSector === 'Comercial' ||
                item.currentSector === 'Produção')
            );
          },
        );

        const responseBusinessListing = [] as Array<IBusinessProposalForList>;
        projectWithFinishedQuoteAndInPlanningSector.map((i: IProject) => {
          if (i.businessProposals.length > 0) {
            i.businessProposals.forEach(
              (
                itemBusinessProposal: Omit<
                  IBusinessProposalResponse,
                  'project'
                >,
              ) => {
                responseBusinessListing.push({
                  id: itemBusinessProposal.id,
                  projectId: i.id,
                  clientName: i.client.name,
                  description: i.description,
                  purchaseOrderFileName: i.purchaseOrderFileName,
                  purchaseOrderNumber: i.purchaseOrderNumber,
                  currentSector: i.currentSector,
                  status: itemBusinessProposal.status,
                  isCanceled: itemBusinessProposal.isCanceled,
                });
              },
            );

            // Se todos as propostas estiverem canceladas cria mais uma linha lista para criar nova proposta
            if (
              i.businessProposals.every(
                itemBusinessProposal =>
                  itemBusinessProposal.status ===
                  businessProposalStatusValid.Cancelada,
              )
            ) {
              responseBusinessListing.push({
                id: null,
                projectId: i.id,
                clientName: i.client.name,
                description: i.description,
                purchaseOrderFileName: i.purchaseOrderFileName,
                purchaseOrderNumber: i.purchaseOrderNumber,
                currentSector: i.currentSector,
                status: businessProposalStatusValid.Elaborar,
                isCanceled: false,
              });
            }
          } else {
            responseBusinessListing.push({
              id: null,
              projectId: i.id,
              clientName: i.client.name,
              description: i.description,
              purchaseOrderFileName: i.purchaseOrderFileName,
              purchaseOrderNumber: i.purchaseOrderNumber,
              currentSector: i.currentSector,
              status: businessProposalStatusValid.Elaborar,
              isCanceled: false,
            });
          }

          return responseBusinessListing;
        });

        setListProjectsWithBusinessProposals(responseBusinessListing);

        setListProjectsWithBusinessProposalsPaginated(responseBusinessListing);
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);

      if (error.response) {
        const { data } = error.response; // Error vindo do back está em data dentro de response
        addToast({
          type: 'error',
          title: data.message,
        });
        return;
      }

      addToast({
        type: 'error',
        title: 'Erro ao listar!',
        description: `Error: ${error}`,
      });
    }
  }, [addToast]);

  useEffect(() => {
    getProvider();
  }, [getProvider]);

  const [loadingPDF, setLoadingPDF] = useState(-1);
  // handlePrintPDF
  const handlePrintPDF = useCallback(
    async (idBusinessProposal): Promise<void> => {
      try {
        setLoadingPDF(idBusinessProposal);

        const response: any = await api.put(
          `proposals/document-pdf`,
          { id: idBusinessProposal },
          {
            responseType: 'arraybuffer',
          },
        );

        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: 'application/pdf' }),
        );
        setLoadingPDF(-1);
        window.open(url);
      } catch (error: any) {
        setLoadingPDF(-1);
        // eslint-disable-next-line no-shadow
        if (error.response) {
          const { data } = error.response; // Error vindo do back está em data dentro de response
          addToast({
            type: 'error',
            title: data.message,
          });
          return;
        }

        addToast({
          type: 'error',
          title: `Ocorreu um erro interno`,
          description: `${error}`,
        });
      }
    },
    [addToast],
  );
  // Fim handlePrintPDF

  const [loadingFilePurchaseOrder, setLoadingFilePurchaseOrder] = useState('');
  // Open file Purchase Order
  const handleOpenFilePurchaseOrder = useCallback(
    (fileName: string, nameButtonClicked: string) => {
      setLoadingFilePurchaseOrder(nameButtonClicked);

      const extensionFile = fileName.substring(fileName.lastIndexOf('.') + 1);

      let typeBlob = '';

      switch (extensionFile) {
        case 'pdf':
          typeBlob = `application/${extensionFile}`;
          break;
        case 'tiff':
          typeBlob = `image/${extensionFile}`;
          break;
        default:
          typeBlob = `image/${extensionFile}`;
      }

      api
        .get(`files-purchase-order/${fileName}`, {
          responseType: 'arraybuffer',
        })
        .then((response: any) => {
          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: typeBlob }),
          );

          window.open(url);

          setLoadingFilePurchaseOrder('');
        })
        .catch(error => {
          setLoadingFilePurchaseOrder('');

          if (error.response.data) {
            const { data } = error.response; // Error vindo do back está em data dentro de response
            addToast({
              type: 'error',
              title: data.message,
            });
            return;
          }

          addToast({
            type: 'error',
            title: 'Erro ao Exibir!',
            description: `Error: ${error}`,
          });
        });
    },
    [addToast],
  );
  // Fim Open file Purchase Order

  const handleListProjectsWithPurchasingActivePaginatedItems = useCallback(
    newList => {
      setListProjectsWithBusinessProposalsPaginated(newList);
    },
    [],
  );

  const handleColorStatus = useCallback((status): string => {
    let colorStatus = 'var(--cinza-escuro-5bits)';
    if (
      status === businessProposalStatusValid.Concluida ||
      status === businessProposalStatusValid.Aprovada
    ) {
      colorStatus = 'var(--verde-5bits)';
    } else if (status === businessProposalStatusValid.Executando) {
      colorStatus = 'var(--azul-5bits)';
    } else if (status === businessProposalStatusValid.Cancelada) {
      colorStatus = 'var(--vermelho-5bits)';
    }

    return colorStatus;
  }, []);

  const [
    loadingModalConfirmationSendEmail,
    setLoadingModalConfirmationSendEmail,
  ] = useState<string>('modalSendClose');

  const openModalConfirmationSendEmail = useCallback(modalSendEmail => {
    setLoadingModalConfirmationSendEmail(modalSendEmail);
  }, []);

  const closeModalConfirmationSendEmail = useCallback(() => {
    setLoadingModalConfirmationSendEmail('modalSendClose');
    getProvider();
  }, [getProvider]);

  const closeModalConfirmationSendEmailWithOutUpdateScreenListProposals = useCallback(() => {
    setLoadingModalConfirmationSendEmail('modalSendClose');
  }, []);

  return (
    <ListingTemplate
      title="Listagem de Propostas Comerciais"
      placeHolderProp="Nº do Projeto ou Cliente"
      module="proposals"
      listItemsProps={listProjectsWithBusinessProposals}
      handleListItemsPaginatedItems={
        handleListProjectsWithPurchasingActivePaginatedItems
      }
      displaySearchArea
      displayButtonNew={false}
      disabled={loading}
      permissions={permissions}
      perPageForPaginationProp={30}
    >
      <Container>
        <Content>
          {loading ? (
            <p>carregando ...</p>
          ) : (
            <table>
              <thead>
                <tr>
                  <th>Nº</th>
                  <th>Cliente</th>
                  <th>Descrição</th>
                  <th>Setor</th>
                  <th>PC</th>
                  <th>Status</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {listProjectsWithBusinessProposalsPaginated.map(
                  (businessProposalItem, index) => (
                    <tr key={String(index)}>
                      <td>
                        {(businessProposalItem.id &&
                          `${businessProposalItem.projectId} - ${businessProposalItem.id}`) ||
                          `${businessProposalItem.projectId}`}
                      </td>
                      <td>
                        {convertInitToUppercase(
                          businessProposalItem.clientName,
                        )}
                      </td>
                      <td>{businessProposalItem.description}</td>
                      <td>{businessProposalItem.currentSector}</td>
                      <td>
                        {businessProposalItem.purchaseOrderFileName &&
                          businessProposalItem.purchaseOrderFileName !== '' && (
                            <InfoPurchaseOrder>
                              {(loadingFilePurchaseOrder ===
                                `openFile${index}` && (
                                <Button
                                  name="buttonSpinner"
                                  type="button"
                                  onClick={e => {
                                    e.preventDefault();
                                  }}
                                >
                                  <Loading
                                    size={24}
                                    color="var(--azul-5bits)"
                                  />
                                </Button>
                              )) || (
                                <Button
                                  name={`openFile${index}`}
                                  type="button"
                                  onClick={e => {
                                    e.preventDefault();
                                    handleOpenFilePurchaseOrder(
                                      businessProposalItem.purchaseOrderFileName,
                                      `openFile${index}`,
                                    );
                                  }}
                                >
                                  Exibir
                                </Button>
                              )}
                            </InfoPurchaseOrder>
                          )}
                      </td>
                      <td
                        style={{
                          color: handleColorStatus(businessProposalItem.status),
                        }}
                      >
                        {businessProposalItem.status}
                      </td>
                      <td>
                        <Actions>
                          {/* Update Icon */}
                          {permissions.update && (
                            <>
                              {(businessProposalItem.status ===
                                businessProposalStatusValid.Parcial &&
                                businessProposalItem.id !== null) === true && (
                                <ItemActions>
                                  <Link
                                    to={`/proposals/update/${businessProposalItem.id}`}
                                    title="Editar"
                                  >
                                    <FiEdit
                                      size={16}
                                      color="var(--azul-5bits)"
                                    />
                                  </Link>
                                </ItemActions>
                              )}
                            </>
                          )}

                          {/* Create Icon */}
                          {permissions.create && (
                            <>
                              {(businessProposalItem.status ===
                                businessProposalStatusValid.Elaborar &&
                                businessProposalItem.id === null) === true && (
                                <ItemActions>
                                  <Link
                                    to={`/proposals/create/${businessProposalItem.projectId}`}
                                    title="Criar"
                                  >
                                    <AiOutlineFileAdd
                                      size={16}
                                      color="var(--azul-5bits)"
                                    />
                                  </Link>
                                </ItemActions>
                              )}
                            </>
                          )}

                          {/* Visualization Icon */}
                          {permissions.read && (
                            <>
                              {businessProposalItem.status !==
                                businessProposalStatusValid.Elaborar &&
                                businessProposalItem.id !== null && (
                                  <ItemActions>
                                    <Link
                                      to={`/proposals/read/${businessProposalItem.id}`}
                                      title="Visualizar"
                                    >
                                      <RiEyeLine
                                        size={16}
                                        color="var(--azul-5bits)"
                                      />
                                    </Link>
                                  </ItemActions>
                                )}
                            </>
                          )}

                          {/* Download PDF Icon */}
                          {permissions.read && (
                            <>
                              {businessProposalItem.status !==
                                businessProposalStatusValid.Elaborar &&
                                businessProposalItem.status !==
                                  businessProposalStatusValid.Parcial &&
                                businessProposalItem.status !==
                                  businessProposalStatusValid.Cancelada &&
                                businessProposalItem.id !== null && (
                                  <ItemActions>
                                    {(loadingPDF ===
                                      businessProposalItem.id && (
                                      <Loading
                                        size={16}
                                        color="var(--azul-5bits)"
                                      />
                                    )) || (
                                      <RiDownloadCloud2Line
                                        title="PDF"
                                        size={16}
                                        color="var(--azul-5bits)"
                                        onClick={e => {
                                          e.preventDefault();
                                          handlePrintPDF(
                                            businessProposalItem.id,
                                          );
                                        }}
                                      />
                                    )}
                                  </ItemActions>
                                )}
                            </>
                          )}

                          {/* Send email Icon */}
                          {permissions.read && (
                            <>
                              {(businessProposalItem.status ===
                                businessProposalStatusValid.Concluida ||
                                businessProposalItem.status ===
                                  businessProposalStatusValid.Enviada) &&
                                businessProposalItem.id !== null && (
                                  <ItemActions>
                                    <HiOutlineMail
                                      title="E-mail"
                                      size={16}
                                      color="var(--azul-5bits)"
                                      onClick={e => {
                                        e.preventDefault();
                                        openModalConfirmationSendEmail(
                                          `modalSendEmail${businessProposalItem.projectId}${businessProposalItem.id}`,
                                        );
                                      }}
                                    />

                                    <ModalConfirmationSendEmail
                                      loadModalProp={
                                        loadingModalConfirmationSendEmail ===
                                        `modalSendEmail${businessProposalItem.projectId}${businessProposalItem.id}`
                                      }
                                      idProjectProp={
                                        businessProposalItem.projectId
                                      }
                                      idProposalProp={businessProposalItem.id}
                                      closeModalConfirmationSendEmail={
                                        closeModalConfirmationSendEmail
                                      }
                                      closeModalConfirmationSendEmailWithOutUpdateScreenListProposalsProp={
                                        closeModalConfirmationSendEmailWithOutUpdateScreenListProposals
                                      }
                                    />
                                  </ItemActions>
                                )}
                            </>
                          )}

                          {/* Cancel Icon */}
                          {permissions.delete && (
                            <>
                              {businessProposalItem.status !==
                                businessProposalStatusValid.Elaborar &&
                                businessProposalItem.status !==
                                  businessProposalStatusValid.Arquivada &&
                                businessProposalItem.status !==
                                  businessProposalStatusValid.Cancelada &&
                                businessProposalItem.id !== null && (
                                  <ItemActions>
                                    <Link
                                      to={`/proposals/delete/${businessProposalItem.id}`}
                                      title="Cancelar"
                                    >
                                      <TiCancel
                                        size={16}
                                        color="var(--azul-5bits)"
                                      />
                                    </Link>
                                  </ItemActions>
                                )}
                            </>
                          )}

                          {/* Approval Icon */}
                          {permissions.update && (
                            <>
                              {(businessProposalItem.status ===
                                businessProposalStatusValid.Enviada ||
                                businessProposalItem.status ===
                                  businessProposalStatusValid.Aprovada ||
                                businessProposalItem.status ===
                                  businessProposalStatusValid.Executando) &&
                                businessProposalItem.id !== null && (
                                  <ItemActions>
                                    <Link
                                      to={`/proposals/update/approval/${businessProposalItem.projectId}/${businessProposalItem.id}`}
                                      title={`${
                                        businessProposalItem.status ===
                                        businessProposalStatusValid.Executando
                                          ? 'Executando'
                                          : 'Aprovar'
                                      }`}
                                    >
                                      <FaRegCheckCircle
                                        size={13}
                                        color="var(--azul-5bits)"
                                      />
                                    </Link>
                                  </ItemActions>
                                )}
                            </>
                          )}
                        </Actions>
                      </td>
                    </tr>
                  ),
                )}
              </tbody>
            </table>
          )}
        </Content>
      </Container>
    </ListingTemplate>
  );
};

export default ListBusinessProposals;
