import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { FiPlus } from 'react-icons/fi';

import { IStep, IStepFrame } from '../../interfaces';

import StepFrame from '../StepFrame';

import { Container, StepFrames } from './styles';

interface IStepProps {
  step: IStep;
}

const Step: React.FC<IStepProps> = ({ step }) => {
  return (
    <Container key={String(step.id)}>
      <header>
        <h2>{step.title}</h2>
        <button type="button" onClick={console.log}>
          <FiPlus color="#DADADA" size={24} />
        </button>
      </header>

      <Droppable droppableId={step.id}>
        {(provided, snapshot): React.ReactElement<HTMLElement> => (
          <StepFrames
            ref={provided.innerRef}
            isDragging={snapshot.isDraggingOver}
          >
            {step.frames.map((stepFrame: IStepFrame, index: number) => (
              <StepFrame
                key={String(stepFrame.id)}
                stepFrame={stepFrame}
                index={index}
              />
            ))}
            {provided.placeholder}
          </StepFrames>
        )}
      </Droppable>
    </Container>
  );
};

export default Step;
