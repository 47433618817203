/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef, useEffect, useCallback, useState } from 'react';
import ReactSelect, {
  OptionTypeBase,
  Props as SelectProps,
} from 'react-select';

import { useField } from '@unform/core';
import { IconBaseProps } from 'react-icons';
import { FiAlertCircle } from 'react-icons/fi';

import Error from '../../styles/errorFieldGlobal';
import { Container, Content } from './styles';

interface Props extends SelectProps<OptionTypeBase> {
  name: string;
  labelText?: string;
  icon?: React.ComponentType<IconBaseProps>;
}

export default function Select({
  name,
  labelText,
  icon: Icon,
  disabled,
  ...rest
}: Props): any {
  const selectRef = useRef(null);
  const {
    fieldName,
    defaultValue,
    registerField,
    error,
    clearError,
  } = useField(name);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [isFilled, setIsFilled] = useState<boolean>(false);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: (ref: any) => {
        if (rest.isMulti) {
          if (!ref.state.value) {
            return [];
          }
          return ref.state.value.map((option: OptionTypeBase) => option.value);
        }
        if (!ref.state.value) {
          return '';
        }
        return ref.state.value.value;
      },
      setValue: (ref: any, value: any) => {
        ref.select.setValue(
          ref.select.props.options.find(
            (option: any) => option.value === value,
          ),
        );
      },
      clearValue: ref => {
        // eslint-disable-next-line no-param-reassign
        ref.select.setValue('');
      },
    });
  }, [fieldName, registerField, rest.isMulti]);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
    // if (inputRef.current) {
    //   setIsFilled(!!inputRef.current?.value);
    // }
  }, []);

  const handleChange = useCallback((): void => {
    if (error) {
      clearError();
    }
  }, [clearError, error]);

  const customStyles = {
    container: (provided: any) => ({
      ...provided,
      width: '100%',
    }),
    control: (provided: any) => ({
      ...provided,
      backgroundColor: 'var(--branco-gelo-5bits)',
      borderRadius: '25px',
      paddingLeft: '5px',
    }),
    menu: (provided: any) => ({
      ...provided,
      color: '#232129',
    }),
    clearIndicator: (provided: any) => ({
      ...provided,
      color: 'var(--cinza-escuro-5bits)',
    }),
    dropdownIndicator: (provided: any) => ({
      ...provided,
      color: 'var(--branco-puro-5bits)',
      borderRadius: ' 100%',
      background: 'var(--laranja-5bits)',
      margin: '8px 11px 8px 11px',
      padding: '2px',
    }),
  };

  return (
    <Container>
      {labelText && <label htmlFor={`id-${name}`}>{labelText}</label>}
      <Content
        isErrored={!!error}
        isFocused={isFocused}
        isFilled={isFilled}
        isDisabled={!!disabled}
      >
        {Icon && <Icon size={20} />}
        <ReactSelect
          id={`id-${name}`}
          ref={selectRef}
          defaultValue={defaultValue}
          onFocus={(): void => setIsFocused(true)}
          onBlur={handleInputBlur}
          onChange={handleChange}
          styles={customStyles}
          disabled={disabled}
          classNamePrefix="react-select"
          {...rest}
        />
      </Content>
      {error && (
        <Error>
          <FiAlertCircle color="var(--on-errored-message-bg-color)" size={20} />
          {error}
        </Error>
      )}
    </Container>
  );
}
