/* eslint-disable no-shadow */
/* eslint-disable import/prefer-default-export */

/**
 * Enum de possiveis valores para status da proposta comercial
 * @Elaborar: Projeto planejado e com todos materiais cotados
 * @Parcial: Valor da Proposta definida, porem restante da proposta incompleta
 * @Concluída: Proposta pronta para ser enviada
 * @Enviada: Proposta já enviada para o cliente
 * @Aprovada: Proposta aprovada pelo cliente
 * @Executando: Projeto em fase de produção
 * @Arquivada: Projeto concluído e entregue ao cliente
 * @Cancelada: Proposta cancela por algum motivo
 */
export enum businessProposalStatusValid {
  Elaborar = 'Elaborar',
  Parcial = 'Parcial',
  Concluida = 'Concluída',
  Enviada = 'Enviada',
  Aprovada = 'Aprovada',
  Executando = 'Executando',
  Arquivada = 'Arquivada',
  Cancelada = 'Cancelada',
}
