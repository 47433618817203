import styled from 'styled-components';

export const Container = styled.div`
  h3 {
    font-size: 2.4rem;
    line-height: 3.2rem;
    color: var(--azul-5bits);
    margin-bottom: 27px;
    font-weight: 400;
  }

  p {
    font-size: 1.4rem;
    line-height: 1.9rem;
    color: var(--cinza-escuro-5bits);

    span {
      margin-right: 8px;
    }
  }
`;

export const TableBox = styled.div`
  margin: 0 auto;
  margin-top: 35px;
  width: 560px;

  > div > div {
    background-color: var(--laranja-5bits);
    border-radius: 4px 4px 0px 0px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-content: center;

    > p {
      color: white;
      font-size: 1.8rem;
      text-transform: uppercase;
      align-self: center;
    }
  }

  table {
    border-spacing: 0;
    background-color: white;
    padding: 25px 35px;
    width: 100%;

    tbody {
      tr {
        td {
          padding-top: 8px;
        }

        td:first-child {
          border-right: 1px solid rgba(171, 171, 171, 0.5);
          padding-right: 10px;
          text-align: right;
        }

        td:not(:nth-child(1)) {
          padding-left: 10px;
          padding-right: 10px;

          div label {
            padding-right: 20px;
          }
        }
      }
    }
  }

  .hidden {
    opacity: 0;
    display: none;
  }
`;

export const ButtonsGroup = styled.div`
  margin: 30px 0;

  button {
    margin-top: 14px;
    margin-right: 10px;

    border: none;
    padding: 8px 16px;
    background: var(--azul-5bits);
    border-radius: 25px;

    font-weight: 700;
    font-size: 1.4rem;

    text-align: center;
    text-transform: uppercase;

    color: var(--branco-puro-5bits);

    &.active {
      background: var(--laranja-5bits);
    }

    &:hover {
      filter: brightness(0.8);
    }
  }
`;

export const CheckboxHidden = styled.div`
  div {
    display: none;
  }
`;
