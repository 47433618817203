import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  InputHTMLAttributes,
} from 'react';
import { useField } from '@unform/core';

import { FiAlertCircle } from 'react-icons/fi';

// Icone de erro que irá no lugar da menssagem de erro dentro do container

import { Container } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
}
const InputFile: React.FC<InputProps> = ({ name, ...rest }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isFocused, setIsFocused] = useState(false);

  const { fieldName, error, clearError, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'files[0]',
      clearValue(ref: HTMLInputElement) {
        // eslint-disable-next-line no-param-reassign
        ref.value = '';
      },
    });
  }, [fieldName, registerField]);

  const handleInputFocus = useCallback((): void => {
    setIsFocused(true);
    // Função do unform para limpar os erros do campo
    clearError();
  }, [clearError]);

  return (
    <Container isErrored={!!error} isFocused={isFocused}>
      <input
        type="file"
        id={`id-${name}`}
        onFocus={handleInputFocus}
        ref={inputRef}
        {...rest}
      />
    </Container>
  );
};
export default InputFile;
