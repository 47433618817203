import styled, { keyframes } from 'styled-components';

export const TabsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const TabsLinks = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  background-color: var(--branco-puro-5bits);

  button {
    width: fit-content;

    margin: 0 0.5rem;
    padding: 0 0.5rem;
    border-radius: 0%;

    background-color: white;
    text-transform: capitalize;
    font-style: normal;
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 2.1rem;
    text-align: center;
    color: var(--azul-5bits);

    position: relative;

    ::after {
      position: absolute;
      bottom: 0;
      left: 0;

      content: '';
      height: 0.2rem;
      width: 0%;

      background-color: var(--laranja-5bits);

      transition: 0.4s;
    }
  }

  button:hover::after,
  button.active::after {
    width: 100%;
  }

  //Medias
  @media (min-width: 0px) and (max-width: 759px) {
    flex-wrap: wrap;
  }

  @media (min-width: 760px) and (max-width: 999px) {
  }
`;

const upKeyFrame = keyframes`
  from {
    opacity: 0;
    transform: translateY(1.5rem);
  }

  to {
    opacity: 1;
  }
`;

export const TabContent = styled.div`
  width: 100%;
  background-color: var(--branco-puro-5bits);

  section {
    /*
    * Ao usar overflow: hidden no css BodyPurchasing - components -
    * HandleMaterials - styles - FiledTwoInputsWithSelect todo o texto
    * da página estava ficando embaçado. Para corrigir:
    * backface-visibility: hidden;
    * transform: translateZ(0);
    * Adicionado antes da animação.
     */
    backface-visibility: hidden;
    transform: translateZ(0);
    animation: ${upKeyFrame} 1s;
  }
`;
