/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import React, { useRef, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { cpf as cpfValidator, cnpj as cnpjValidator } from 'cpf-cnpj-validator';

import api from '../../../services/api';

import getValidationErrors from '../../../utils/getValidationError';
import validationCPF from '../../../utils/validationCPF';
import convertToNumber from '../../../utils/convertToNumber';
import { useToast } from '../../../context/ToastContext';

import Loading from '../../../components/Loading';
import Input from '../../../components/Input';
import SimpleSelect from '../../../components/SimpleSelect';
import Button from '../../../components/Button';

import ufList from '../../../data/ufList.json';

import {
  Container,
  Content,
  Split,
  SplitLeft,
  VerticalLine,
  SplitRight,
  CityAndUF,
} from './styles';

import { IClientResponse } from '../../../interfaces/client';
import InputPhone from '../../../components/InputPhone';

const CreateClient: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const navigate = useNavigate();
  const { addToast } = useToast();

  const [saving, setSaving] = useState<boolean>(false);

  const handleFormSubmit = useCallback(
    async (dataForm: IClientResponse, { reset }) => {
      try {
        setSaving(true);

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('O Nome do Cliente é obrigatório'),
          cpfCnpj: Yup.string()
            .min(11)
            .test('cpfCnpj', 'CPF/CNPJ inválido', () => {
              return validationCPF(dataForm.cpfCnpj);
            })
            .required('O CPF/CNPJ é obrigatório'),
          contact: Yup.string().required('O Contato é obrigatório'),
        });

        await schema.validate(dataForm, { abortEarly: false });

        const newData = {
          ...dataForm,
          cpfCnpj: convertToNumber(dataForm.cpfCnpj),
        };

        await api.post('clients', newData);

        addToast({
          type: 'success',
          title: 'Cadastro realizado com sucesso',
        });

        reset();
        setSaving(false);

        navigate('/clients');
      } catch (error: any) {
        setSaving(false);

        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);

          return;
        }

        if (error.response) {
          const { data } = error.response; // Error vindo do back está em data dentro de response

          addToast({
            type: 'error',
            title: 'Erro no cadastro',
            description: data.message,
          });
          if (
            formRef.current?.getFieldRef(data.field) &&
            data.field.toString().indexOf('_id') === -1
          ) {
            formRef.current?.getFieldRef(data.field).focus(); // Foca o campo que deu erro de acordo com o field retornado do back
          }

          return;
        }

        addToast({
          type: 'error',
          title: 'Error',
          description: 'Erro ao cadastrar!',
        });
      }
    },
    [addToast, navigate],
  );

  const handleCpfCnpj = useCallback(value => {
    let cpfCnpjFormat = '';
    const valueWithoutMask = convertToNumber(value);

    if (valueWithoutMask.length === 11) {
      cpfCnpjFormat = cpfValidator.format(valueWithoutMask);
    } else if (valueWithoutMask.length === 14) {
      cpfCnpjFormat = cnpjValidator.format(valueWithoutMask);
    }

    formRef.current?.setFieldValue('cpfCnpj', cpfCnpjFormat);
  }, []);

  return (
    <Container>
      <Content>
        <header>
          <p>Cadastro de Clientes</p>
        </header>
        <Form ref={formRef} onSubmit={handleFormSubmit}>
          <Split>
            <SplitLeft>
              <Input
                id="name"
                name="name"
                type="text"
                labelText="Nome*"
                disabled={saving}
              />
              <Input
                id="cpfCnpj"
                name="cpfCnpj"
                type="text"
                labelText="CPF/CNPJ*"
                onBlur={e => handleCpfCnpj(e.target.value)}
                disabled={saving}
              />
              <Input
                id="contact"
                name="contact"
                type="text"
                labelText="Contato"
                disabled={saving}
              />
              <Input
                id="address"
                name="address"
                type="text"
                labelText="Endereço"
                disabled={saving}
              />
            </SplitLeft>

            <VerticalLine />

            <SplitRight>
              <Input
                id="neighborhood"
                name="neighborhood"
                type="text"
                labelText="Bairro"
                disabled={saving}
              />
              <CityAndUF>
                <Input
                  id="city"
                  name="city"
                  type="text"
                  labelText="Cidade"
                  disabled={saving}
                />
                <SimpleSelect
                  id="uf"
                  name="uf"
                  labelText="UF"
                  placeholder=""
                  options={ufList}
                  getOptionLabel={option => option.value}
                  disabled={saving}
                />
              </CityAndUF>
              <InputPhone
                id="phone"
                name="phone"
                type="text"
                labelText="Telefone"
                disabled={saving}
              />

              <Input
                id="email"
                name="email"
                type="text"
                labelText="E-mail"
                disabled={saving}
              />
            </SplitRight>
          </Split>

          <Button type="submit" style={{ width: '233px' }} disabled={saving}>
            {saving ? <Loading size={24} color="white" /> : 'Salvar'}
          </Button>
        </Form>
      </Content>
    </Container>
  );
};

export default CreateClient;
