/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import api from '../../../services/api';
import getProjectFormatted from '../../AuxiliaryFunctions/GetProject';
import getValidationErrors from '../../../utils/getValidationError';
import { useToast } from '../../../context/ToastContext';

import Loading from '../../../components/Loading';

import Button from '../../../components/Button';

import HeadProjectOnlyText from '../components/HeadProjectOnlyText';

import ModalChecklist from '../../Sectors/componentsSectors/ModalChecklist/modalChecklist';
import ChecklistProject from '../../Sectors/componentsSectors/ChecklistProject';

import { IChecklist } from '../../../interfaces/checklist';
import { IProject } from '../../../interfaces/project';
import ModalLoading from '../../../components/ModalLoading';

import { IPermission } from '../../../interfaces/permission';

import { Container, Content, ContentSendEmail, SplitFooter } from './styles';
import Input from '../../../components/Input';
import TextArea from '../../../components/TextArea';

interface PermissionDTO {
  permissions: IPermission;
}

interface ISendEmail {
  id: number;
  alreadySent: string;
  to: string;
  subject: string;
  contents: string;
  projectHasChecklist: Array<number>;
}

const SendEmailBusinessProposal: React.FC<PermissionDTO> = ({
  permissions,
}) => {
  const { projectId }: any = useParams();
  const { proposalId }: any = useParams();
  const formRef = useRef<FormHandles>(null);
  const navigate = useNavigate();
  const { addToast } = useToast();

  const [project, setProject] = useState<IProject>({} as IProject);
  const [loading, setLoading] = useState<boolean>(false);

  const [saving, setSaving] = useState<boolean>(false);

  const [listChecklist, setListChecklist] = useState<IChecklist[]>([]);

  const [loadingChecklist, setLoadingChecklist] = useState<boolean>(false);

  // Get project
  const getProject = useCallback(async (): Promise<void> => {
    try {
      const projectResponse: any = await getProjectFormatted(projectId);

      setProject(projectResponse);

      // setListManufacturingSteps(projectResponse.projectHasManufacturingSteps);
      formRef.current?.setData(projectResponse);
      formRef.current?.setFieldValue('to', projectResponse.client.email);
      formRef.current?.setFieldValue(
        'subject',
        `Proposta Comercial nº ${projectId} - ${proposalId}`,
      );

      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      // eslint-disable-next-line no-shadow
      if (error.response) {
        const { data } = error.response; // Error vindo do back está em data dentro de response
        addToast({
          type: 'error',
          title: data.message,
        });
        return;
      }

      addToast({
        type: 'error',
        title: 'Ocorreu um erro interno',
        description: `${error}`,
      });
    }
  }, [addToast, projectId, proposalId]);
  // End Get project

  // List Checklist
  useEffect(() => {
    setLoading(true);
    const getChecklist = async (): Promise<void> => {
      try {
        // Checklist
        const responseChecklist = await api.get<any[]>('checklist');

        setListChecklist(responseChecklist.data);
        // End checklist

        // Project
        getProject();
      } catch (error: any) {
        setLoading(false);

        if (error.response && error.response.data) {
          const { data } = error.response; // Error vindo do back está em data dentro de response
          addToast({
            type: 'error',
            title: data.message,
          });
          return;
        }

        addToast({
          type: 'error',
          title: 'Error',
          description: 'Erro ao listar Checklist!',
        });
      }
    };
    getChecklist();
  }, [addToast, getProject]);
  // End List Checklist

  const [openModalChecklist, setOpenModalChecklist] = useState(false);
  const handleOpenModalChecklist = useCallback(() => {
    setOpenModalChecklist(!openModalChecklist);
    setSaving(false);
  }, [openModalChecklist]);

  // handleFormSubmit
  const handleFormSubmit = useCallback(
    async (dataForm: ISendEmail, { reset }) => {
      try {
        setSaving(true);

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          subject: Yup.string().required('Assunto do e-mail obrigatório'),
          to: Yup.string().required('E-mail do Cliente obrigatório'),
          contents: Yup.string().required('Mensagem do e-mail obrigatória'),
        });

        await schema.validate(dataForm, { abortEarly: false });

        /*
         *projectHasChecklist chega como array de objetos [{check1: true},{check14: false}]
         *Abaixo filtra apenas os true, depois no map retira a string 'check' e converte para
         * inteiro o número que é o id do item checklist no banco
         */
        // projectHasChecklist
        const onlyItemsWithTrueChecklist = dataForm.projectHasChecklist.filter(
          // eslint-disable-next-line consistent-return
          (itemProjectHasChecklist: any) => {
            if (Object.values(itemProjectHasChecklist)[0]) {
              const idOfCheckInString = Object.keys(
                itemProjectHasChecklist,
              )[0].replace('check', '');
              return parseInt(idOfCheckInString, 10);
            }
          },
        );

        const itemsConvertedInIntChecklist = onlyItemsWithTrueChecklist.map(
          (itemProjectHasChecklist: any) => {
            const idOfCheckInString = Object.keys(
              itemProjectHasChecklist,
            )[0].replace('check', '');
            return parseInt(idOfCheckInString, 10);
          },
        );

        const itemsChecklistFinal =
          itemsConvertedInIntChecklist.length > 0
            ? [...itemsConvertedInIntChecklist]
            : [];
        // End projectHasChecklist

        const newDataForm: any = {
          id: parseInt(proposalId, 10),
          alreadySent: false,
          to: dataForm.to,
          subject: dataForm.subject,
          contents: dataForm.contents,
          projectHasChecklist: itemsChecklistFinal,
        };

        const response = await api.put('proposals/send-email', newDataForm);

        addToast({
          type: 'success',
          title: `${response.data}`,
        });

        reset();
        setSaving(false);
        handleOpenModalChecklist();
        navigate('/proposals');
      } catch (error: any) {
        setSaving(false);
        handleOpenModalChecklist();

        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);

          return;
        }

        if (error.response) {
          const { data } = error.response; // Error vindo do back está em data dentro de response

          addToast({
            type: 'error',
            title: 'Erro no envio',
            description: data.message,
          });
          if (
            formRef.current?.getFieldRef(data.field) &&
            data.field.toString().indexOf('_id') === -1
          ) {
            formRef.current?.getFieldRef(data.field).focus(); // Foca o campo que deu erro de acordo com o field retornado do back
          }

          return;
        }

        addToast({
          type: 'error',
          title: 'Error',
          description: 'Erro ao enviar!',
        });
      }
    },
    [addToast, handleOpenModalChecklist, navigate, proposalId],
  );
  // End handleFormSubmit

  return (
    <>
      <Container>
        <ModalLoading visible={loading}>
          <p>
            <i>Carregando os dados da Proposta...</i>
          </p>
        </ModalLoading>
        <Content>
          <Form ref={formRef} onSubmit={handleFormSubmit}>
            {/* <Split> */}
            <header>
              <p>Envio de Proposta Comercial</p>
              <span>
                Nº da proposta: {projectId} - {proposalId}
              </span>
            </header>

            <HeadProjectOnlyText projectProp={project} />

            <ContentSendEmail>
              <Input
                id="subject"
                name="subject"
                labelText="Assunto"
                type="text"
                autoComplete="off"
                disabled={saving}
              />
              <Input
                id="to"
                name="to"
                labelText="E-mail do Cliente"
                type="text"
                autoComplete="off"
                disabled={saving}
              />
              <TextArea
                id="contents"
                name="contents"
                label="Mensagem"
                disabled={saving}
              />
            </ContentSendEmail>

            <ChecklistProject
              listChecklistProp={listChecklist}
              loadingChecklistProp={loadingChecklist}
              projectHasChecklist={project.projectHasChecklist}
            />

            <SplitFooter>
              <div>
                <Button
                  type="button"
                  onClick={handleOpenModalChecklist}
                  disabled={saving}
                >
                  {saving ? <Loading size={24} color="white" /> : 'Enviar'}
                </Button>
              </div>
            </SplitFooter>

            <ModalChecklist
              saving={saving}
              loadModalProp={openModalChecklist}
              listChecklistProp={listChecklist}
              projectHasChecklist={project.projectHasChecklist}
              closeModalChecklist={handleOpenModalChecklist}
            />
          </Form>
        </Content>
      </Container>
    </>
  );
};

export default SendEmailBusinessProposal;
