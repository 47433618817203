import styled, { css } from 'styled-components';

interface IContainerCompanyStage {
  colorHRBlue: boolean;
}

export const Container = styled.div`
  height: 78vh;
  width: 100vw;
`;

export const Content = styled.div`
  height: 100%;
  width: 100vw;

  h1 {
    margin-bottom: 2.7rem;

    text-transform: capitalize;
    font-style: normal;
    font-weight: 500;
    font-size: 2.4rem;
    line-height: 3.2rem;
  }
`;

export const ContainerProductionStages = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  /*100vh - header - margin-top - area botão voltar - h1 com margin bottom somada - margin-bottom - footer */
  height: calc(100vh - 81px - 20px - 33px - 59px - 6px - 29px);

  overflow: auto;
  position: relative;
`;

export const ContainerTitleStages = styled.div`
  display: flex;

  > div:last-child {
    border-right: none;
  }
`;

export const TitleStage = styled.div`
  display: flex;
  align-items: center;

  min-width: 264px;
  max-width: 264px;
  border-right: 1px solid rgba(171, 171, 171, 0.5);

  > span {
    width: 100%;
    align-items: flex-start;

    padding: 0 2rem;
    margin: 2.6rem 0 2.6rem 0;

    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    color: var(--laranja-5bits);
  }
`;

export const CardsCompany = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContainerCompanyStage = styled.div<IContainerCompanyStage>`
  display: flex;
  position: relative;

  /* Pega a largura do conteúdo interno */
  width: fit-content;

  hr {
    z-index: -1;
    position: absolute;
    top: 6.64vh;
    width: 97%;
    ${props =>
      (props.colorHRBlue &&
        css`
          border: 1px solid var(--azul-5bits);
        `) ||
      css`
        border: 1px solid var(--laranja-5bits);
      `}

    margin: 0 2rem;
  }

  > div:last-child {
    border-right: none;
  }
`;

export const ContainerOSOnStageEmpty = styled.div`
  min-width: 264px;
  max-width: 264px;
  min-height: 112px;
  max-height: 268px;
  padding: 0 2rem 1rem 2rem;
  border-right: 1px solid rgba(171, 171, 171, 0.5);
`;
