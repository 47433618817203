import React from 'react';

import { AuthProvider } from './AuthContext';
import { ToastProvider } from './ToastContext';

/*
A ordem dos provides só importaria se um dependesse do outro
como por exemplo estar autenticado para usar alguma funcionalidade.
Neste caso aqui a ordem não importa.
*/
const AppProvider: React.FC = ({ children }) => (
  <AuthProvider>
    <ToastProvider>{children}</ToastProvider>
  </AuthProvider>
);

export default AppProvider;
