/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useState, memo } from 'react';
import { FormHandles, Scope } from '@unform/core';
import fincalc from 'fincalc';
import typy from 'typy';

import Tooltip from '../../../../../../../../components/Tooltip';
import Information from '../../../../../../../../assets/Information.svg';
import ImageExpandMoreCircle from '../../../../../components/ImageExpandMoreCircle';

import SimpleSelect from '../../../../../../../../components/SimpleSelect';
import InputHidden from '../../../../../../../../components/InputHidden';
import InputCurrencyThreeDigits from '../../../../../../../../components/InputCurrencyThreeDigits';
import Input from '../../../../../../../../components/Input';
import InputCurrency from '../../../../../../../../components/InputCurrency';
import RadioButton from '../../../../../../../../components/RadioButton';

import convertDateToBr from '../../../../../../../../utils/convertDateToBr';
import convertNumberFloatToBr from '../../../../../../../../utils/convertNumberFloatToBr';
import convertNumberFloatToDB from '../../../../../../../../utils/convertNumberFloatToDB';

import { IProvider } from '../../../../../../../../interfaces/provider';
import { IMaterialForm } from '../../../../../../../../interfaces/material';

import {
  SplitMaterialItem,
  SplitFiledTwoInputsWithSelect,
  FiledTwoInputsWithSelect,
  FiledTwoInputs,
  SplitProvidersAndPhoneNumber,
  DeliveryDaysAndImageExpandMoroCircle,
  ProvidersAndPhoneNumber,
  TableProviders,
} from './styles';

type Props = {
  formRef: any | FormHandles;
  listMaterialPurchasingPagination: any[];
  listMaterials: any[];
  disabledImageExpandMoreCircle: boolean;
  disabled: boolean;
  finishedProposal: boolean;
  sumGrandTotal: () => void;
  updateTotalization: () => void;
};

function ScopeMaterialPurchasing({
  formRef,
  listMaterialPurchasingPagination,
  listMaterials,
  disabledImageExpandMoreCircle,
  disabled,
  finishedProposal,
  sumGrandTotal,
  updateTotalization,
}: Props): any {
  const calculateMaterialPurchasing = useCallback(
    async (indexInScope: number) => {
      const quantityFloat = convertNumberFloatToDB(
        formRef.current.getFieldValue(
          `projectHasMaterialsPurchasing[${indexInScope}].quantity`,
        ),
      );

      const unitPriceFloat = convertNumberFloatToDB(
        formRef.current.getFieldValue(
          `projectHasMaterialsPurchasing[${indexInScope}].unitPrice`,
        ),
      );

      const totalPrice = fincalc(quantityFloat * unitPriceFloat);

      formRef.current.setFieldValue(
        `projectHasMaterialsPurchasing[${indexInScope}].totalPrice`,
        convertNumberFloatToBr(totalPrice),
      );
    },
    [formRef],
  );

  const calculateMaterialPurchasingPrice = useCallback(
    async (indexInScope: number) => {
      await calculateMaterialPurchasing(indexInScope);
      sumGrandTotal();
    },
    [calculateMaterialPurchasing, sumGrandTotal],
  );

  const handleSumPrice = useCallback(
    (e, indexInScope: number) => {
      if (e.key !== 'Tab') {
        calculateMaterialPurchasingPrice(indexInScope);
      }
    },
    [calculateMaterialPurchasingPrice],
  );

  const handleInputChangeProvider = useCallback(
    (inputValue: any, index) => {
      const deliveryDaysRef = formRef.current?.getFieldRef(
        `projectHasMaterialsPurchasing[${index}].deliveryDays`,
      );

      if (inputValue) {
        formRef.current?.setFieldValue(
          `projectHasMaterialsPurchasing[${index}].providerIdHidden`,
          inputValue.id,
        );

        if (!finishedProposal && !disabled) {
          deliveryDaysRef.style.color = 'var(--cinza-escuro-5bits)';
          deliveryDaysRef.parentNode.style.filter = 'none';
          deliveryDaysRef.disabled = false;
        }
      } else {
        formRef.current?.clearField(
          `projectHasMaterialsPurchasing[${index}].providerIdHidden`,
        );
        formRef.current?.clearField(
          `projectHasMaterialsPurchasing[${index}].deliveryDays`,
        );

        deliveryDaysRef.style.color = 'var(--cinza-claro-5bits)';
        deliveryDaysRef.parentNode.style.filter = 'brightness(0.9)';
        deliveryDaysRef.disabled = true;
      }

      updateTotalization();
    },
    [disabled, finishedProposal, formRef, updateTotalization],
  );

  const handleInputChangeMaterialsPurchasing = useCallback(
    (inputValue: any, index, providerId: any) => {
      if (inputValue) {
        formRef.current?.setFieldValue(
          `projectHasMaterialsPurchasing[${index}].unitOfMeasurement`,
          inputValue.unit.abbreviation,
        );

        formRef.current?.setFieldValue(
          `projectHasMaterialsPurchasing[${index}].unitPrice`,
          convertNumberFloatToBr(inputValue.price),
        );

        /*
         * Só pode calcular o subtotal se não tiver providerId, pois assim não
         * sobrescreve o valor vindo do banco já que poderá ser digitado um pelo usuário
         */
        if (!typy(parseInt(providerId, 10)).isNumber) {
          calculateMaterialPurchasingPrice(index);
        }
      }
    },
    [calculateMaterialPurchasingPrice, formRef],
  );

  // findLastQuoteProvider;
  const findLastQuoteProviderAndReturnInputUnitPrice = useCallback(
    (materialIdOfItemOfProjectHasMaterialsPurchasing, index): any => {
      const materialOfListActivesWithLastQuoteProvider = listMaterials.find(
        materialItemOfListActives =>
          materialItemOfListActives.id ===
          materialIdOfItemOfProjectHasMaterialsPurchasing,
      );

      if (
        materialOfListActivesWithLastQuoteProvider !== undefined &&
        materialOfListActivesWithLastQuoteProvider.providers.length > 0
      ) {
        const lastQuoteProvider = materialOfListActivesWithLastQuoteProvider.providers.find(
          (provider: any) =>
            provider.id ===
            materialOfListActivesWithLastQuoteProvider.lastQuoteProviderId,
        );

        if (lastQuoteProvider !== undefined) {
          const lastQuoteProviderName = lastQuoteProvider.fantasyName;

          // return `Última Cotação \n Forn.: ${lastQuoteProviderName} \n Date: ${convertDateToBr(
          //   materialOfListActivesWithLastQuoteProvider.dateLastQuote,
          // )}`;
          const textTooltip = `Última Cotação \n Forn.: ${lastQuoteProviderName} \n Date: ${convertDateToBr(
            materialOfListActivesWithLastQuoteProvider.dateLastQuote,
          )}`;

          return (
            <Tooltip
              style={{
                textTransform: 'capitalize',
                whiteSpace: 'pre',
                width: 'fit-content',
                left: '50%',
                bottom: `${
                  index === 0 ? 'calc(100% + -13px)' : 'calc(100% + 8px)'
                }`,
              }}
              colorBefore="var(--azul-5bits)"
              colorSpan="var(--azul-5bits)"
              title={textTooltip}
            >
              <InputCurrency
                id="unitPrice"
                name="unitPrice"
                type="text"
                labelText={index === 0 ? 'Valor UN*' : ''}
                onKeyUp={e => handleSumPrice(e, index)}
                autoComplete="off"
                readOnly={disabled}
              />
            </Tooltip>
          );
        }
      }

      return (
        <InputCurrency
          id="unitPrice"
          name="unitPrice"
          type="text"
          labelText={index === 0 ? 'Valor UN*' : ''}
          onKeyUp={e => handleSumPrice(e, index)}
          autoComplete="off"
          readOnly={disabled}
        />
      );
    },
    [disabled, handleSumPrice, listMaterials],
  );

  const handleListProviderOfMaterialPurchasing = useCallback(
    materialId => {
      if (listMaterials && listMaterials.length > 0 && materialId) {
        const providersOfMaterialPurchasing = listMaterials.find(
          material => material.id === materialId,
        ).providers;

        const listProvidersOfMaterialPurchasing = providersOfMaterialPurchasing.map(
          (i: any) => ({
            ...i,
            value: i.id,
            label: i.fantasyName,
          }),
        );

        return listProvidersOfMaterialPurchasing;
      }
      return [];
    },
    [listMaterials],
  );

  const [providerSelectedOnRadio, setProviderSelectedOnRadio] = useState();

  const handleProviderSelectedOnRadio = useCallback(providerId => {
    setProviderSelectedOnRadio(providerId);
  }, []);

  const [checkedRadio, setCheckedRadio] = useState(true);

  const createProvidersTable = useCallback(
    // eslint-disable-next-line consistent-return
    materialId => {
      const providersListOfMaterialPurchasing = handleListProviderOfMaterialPurchasing(
        materialId,
      );

      if (providersListOfMaterialPurchasing.length > 0) {
        const trForTableLeft: Array<any> = [];
        const trForTableRight: Array<any> = [];
        providersListOfMaterialPurchasing.map(
          // eslint-disable-next-line array-callback-return
          (provider: IProvider, index: number) => {
            if (index % 2 === 0) {
              trForTableLeft.push(
                <tr key={String(index)}>
                  <td>
                    <RadioButton
                      name="providerRadio"
                      sizeInputRadio="2rem"
                      onClick={e => {
                        if (
                          checkedRadio &&
                          e.currentTarget.value === `${providerSelectedOnRadio}`
                        ) {
                          e.currentTarget.checked = false;
                          setCheckedRadio(false);
                          handleProviderSelectedOnRadio(0);
                        } else {
                          setCheckedRadio(true);
                          handleProviderSelectedOnRadio(provider.id);
                        }
                      }}
                      options={[
                        {
                          id: provider.id.toString(),
                          value: provider.id.toString(),
                          option: '',
                        },
                      ]}
                    />
                  </td>
                  <td>{provider.fantasyName}</td>
                  <td>{provider.mainPhone}</td>
                </tr>,
              );
            } else {
              trForTableRight.push(
                <tr key={String(index)}>
                  <td>
                    <RadioButton
                      name="providerRadio"
                      sizeInputRadio="2rem"
                      onClick={e => {
                        if (
                          checkedRadio &&
                          e.currentTarget.value === `${providerSelectedOnRadio}`
                        ) {
                          e.currentTarget.checked = false;
                          setCheckedRadio(false);
                          handleProviderSelectedOnRadio(0);
                        } else {
                          setCheckedRadio(true);
                          handleProviderSelectedOnRadio(provider.id);
                        }
                      }}
                      options={[
                        {
                          id: provider.id.toString(),
                          value: provider.id.toString(),
                          option: '',
                        },
                      ]}
                    />
                  </td>
                  <td>{provider.fantasyName}</td>
                  <td>{provider.mainPhone}</td>
                </tr>,
              );
            }
          },
        ); // End map

        return (
          <>
            <TableProviders>
              <tbody>{trForTableLeft}</tbody>
            </TableProviders>
            <TableProviders>
              <tbody>{trForTableRight}</tbody>
            </TableProviders>
          </>
        );
      }
    },
    [
      checkedRadio,
      providerSelectedOnRadio,
      handleListProviderOfMaterialPurchasing,
      handleProviderSelectedOnRadio,
    ],
  );

  const [expandMoreCircleClicked, setExpandMoreCircleClicked] = useState('');

  return (
    <>
      {listMaterialPurchasingPagination.length > 0 &&
        listMaterialPurchasingPagination.map((item: any, index) => (
          <Scope
            path={`projectHasMaterialsPurchasing[${index}]`}
            key={String(index)}
          >
            <InputHidden id="id" name="id" />
            <InputHidden id="providerIdHidden" name="providerIdHidden" />
            <SplitMaterialItem>
              {(item && item.observation && (
                <SplitFiledTwoInputsWithSelect>
                  <span>{index === 0 ? 'Nome' : ''}</span>
                  <FiledTwoInputsWithSelect>
                    <div>
                      <Tooltip
                        id={`toolInfo${index}`}
                        // viewPortHeightProp={viewPortHeight}
                        style={{
                          textTransform: 'capitalize',
                          width: '25rem',
                          left: '11.5rem',
                          bottom: 'calc(100% + 12px)',
                        }}
                        colorBefore="var(--azul-5bits)"
                        colorSpan="var(--azul-5bits)"
                        beforeLeft="8%"
                        title={item.observation}
                      >
                        <img src={Information} alt="Information" />
                      </Tooltip>
                    </div>
                    <SimpleSelect
                      id="materialId"
                      name="materialId"
                      // labelText={index === 0 ? 'Nome' : ''}
                      placeholder=""
                      options={listMaterials}
                      onChange={option => {
                        handleInputChangeMaterialsPurchasing(
                          option,
                          index,
                          item.providerId,
                        );
                      }}
                      isDisabled
                      disabled
                    />
                  </FiledTwoInputsWithSelect>
                </SplitFiledTwoInputsWithSelect>
              )) || (
                <SimpleSelect
                  id="materialId"
                  name="materialId"
                  labelText={index === 0 ? 'Nome' : ''}
                  placeholder=""
                  options={listMaterials}
                  onChange={option => {
                    handleInputChangeMaterialsPurchasing(
                      option,
                      index,
                      item.providerId,
                    );
                  }}
                  isDisabled
                  disabled
                />
              )}

              <FiledTwoInputs>
                <InputCurrencyThreeDigits
                  id="quantity"
                  name="quantity"
                  type="text"
                  labelText={index === 0 ? 'Quantidade' : ''}
                  autoComplete="off"
                  readOnly
                  disabled
                />
                {/* Label apenas para manter o mesmo tamanho de quantity. visibility: hidden */}
                <Input
                  id="unitOfMeasurement"
                  name="unitOfMeasurement"
                  labelText={index === 0 ? 'un' : ''}
                  type="text"
                  autoComplete="off"
                  disabled
                />
              </FiledTwoInputs>

              <>
                {findLastQuoteProviderAndReturnInputUnitPrice(
                  item.materialId,
                  index,
                )}
              </>

              <InputCurrency
                id="totalPrice"
                name="totalPrice"
                type="text"
                labelText={index === 0 ? 'Subtotal*' : ''}
                onKeyUp={sumGrandTotal}
                autoComplete="off"
                readOnly={disabled}
              />

              <SimpleSelect
                id="providerId"
                name="providerId"
                labelText={index === 0 ? 'Fornecedor*' : ''}
                placeholder=""
                options={handleListProviderOfMaterialPurchasing(
                  item.materialId,
                )}
                onChange={option => handleInputChangeProvider(option, index)}
                isClearable
                isDisabled={disabled}
                disabled={disabled}
              />

              <DeliveryDaysAndImageExpandMoroCircle>
                <Input
                  id="deliveryDays"
                  name="deliveryDays"
                  labelText={index === 0 ? 'Prazo' : ''}
                  placeholder="dias"
                  onChange={updateTotalization}
                  autoComplete="off"
                  readOnly={finishedProposal || disabled}
                  disabled={finishedProposal || item.providerId || disabled}
                />

                {!disabledImageExpandMoreCircle && (
                  <ImageExpandMoreCircle
                    onClick={() => {
                      expandMoreCircleClicked === `expandMoreCircle${item.id}`
                        ? setExpandMoreCircleClicked(`expandMoreCircleClose`)
                        : setExpandMoreCircleClicked(
                            `expandMoreCircle${item.id}`,
                          );
                    }}
                    rotate={
                      expandMoreCircleClicked === `expandMoreCircle${item.id}`
                        ? 180
                        : 0
                    }
                    activeColor={
                      handleListProviderOfMaterialPurchasing(
                        item.materialId,
                      ).find(
                        (provider: IProvider) =>
                          provider.id === providerSelectedOnRadio,
                      ) !== undefined
                    }
                  />
                )}
              </DeliveryDaysAndImageExpandMoroCircle>
            </SplitMaterialItem>

            <SplitProvidersAndPhoneNumber
              showProvidersAndPhone={
                expandMoreCircleClicked === `expandMoreCircle${item.id}`
              }
            >
              <ProvidersAndPhoneNumber>
                {createProvidersTable(item.materialId)}
              </ProvidersAndPhoneNumber>
            </SplitProvidersAndPhoneNumber>
          </Scope>
        ))}
    </>
  );
}

export default memo(ScopeMaterialPurchasing);
