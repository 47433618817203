import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`

:root {
    --azul-5bits: #0F4C82;
    --laranja-5bits: #FF8441;
    --cinza-escuro-5bits: #515151;
    --cinza-claro-5bits: #ABABAB;
    --branco-gelo-5bits: #F7F7F7;
    --branco-puro-5bits: #FFFFFF;
    --verde-5bits: #048c55;
    --vermelho-5bits: #c53030;
    --azul-claro-5bits: #e7eef3;

    --azul-medio-5bits: #1684E1;
    --cinza-medio-5bits:#666360;

    --azul-bebe-5bits: #E7EDF3;
    --verde-bebe-5bits: #E6F3EE;
    --preto-5bits-rgba: rgba(0, 0, 0, 0.2);

    --preto-igual-a-rgba: #C6C6C6;

    /*
    --input-text-color-5bits: #888888;
    --header-bg-color: #232129;
    --header-border-color: #232129;

--checkbox-bg-color: #232129;
--checkbox-text-color: var(--cinza-medio-5bits);
    --before-checkbox-border-color: var(--cinza-medio-5bits);
    --ischecked-checkbox-text-color: var(--laranja-5bits);
    --ischecked-before-checkbox-border-color: var(--laranja-5bits);
    --ischecked-after-checkbox-border-color: var(--laranja-5bits);
    */

    --input-bg-color: var(--branco-gelo-5bits);
    --input-border-color: var(--cinza-claro-5bits);
    --input-text-color: var(--cinza-escuro-5bits);
    --input-placeholder-color: var(--cinza-claro-5bits);

    --on-focus-input-border-color: var(--laranja-5bits);
    --on-focus-input-text-color: var(--laranja-5bits);

    --on-filled-input-text-color: var(--laranja-5bits);

    --on-errored-input-border-color: var(--vermelho-5bits);

    --on-errored-message-bg-color: var(--vermelho-5bits);
    --on-errored-message-border-color: var(--vermelho-5bits);
    --on-errored-message-text-color: var(--branco-puro-5bits);

    --on-errored-icon-color: var(--vermelho-5bits);

    --button-bg-color: var(--laranja-5bits);
    --button-text-color: var(--branco-gelo-5bits);

    --buttonlink-bg-color: var(--laranja-5bits);
    --buttonlink-text-color: var(--branco-gelo-5bits);
    --size-buttonlink-height: 56px;
    --size-buttonlink-width: 200px;


    --message-toast-info-bg-color: #ebf8ff;
    --message-toast-info-text-color: var(--azul-5bits);

    --message-toast-success-bg-color: #e6fffa;
    --message-toast-success-text-color: #2e656a;

    --message-toast-error-bg-color: #fddede;
    --message-toast-error-text-color: var(--vermelho-5bits);

    --message-tooltip-span-bg-color: var(--laranja-5bits);
    --message-tooltip-span-text-color: var(--branco-gelo-5bits);
    --message-tooltip-span-before-border-color: var(--laranja-5bits);

    --modal-bg-color: var(--cinza-medio-5bits);
    --pages-link-text-color: var(--azul-5bits);
    --pages-listusers-div-border-color: var(--laranja-5bits);
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }

  #root {
    min-width: fit-content;
  }

  /*Trocar o estilo do scroll apenas nos elementos dentro da body*/
    /*Scroll - Works on Chrome, Edge, and Safari */
    * {
      ::-webkit-scrollbar {
        width: 8px;
        margin-left: 6px;
      }

      /*Caminho do scroll*/
      ::-webkit-scrollbar-track {
        background: var(--branco-puro-5bits);
      }
      ::-webkit-scrollbar-track-piece{

      }
      ::-webkit-scrollbar-thumb {
        background-color: #C4C4C4;
        border-radius: 20px;
      }

      /* Buttons estão desabilitados para este layout*/
      ::-webkit-scrollbar-button{

      }
      ::-webkit-scrollbar-button:single-button {
        display: none;
        background-color: #bbbbbb;
        border-style: solid;
        height: 13px;
        width: 16px;
      }
      /* Up */
      ::-webkit-scrollbar-button:single-button:vertical:decrement {
        border-width: 0 8px 8px 8px;
        border-color: transparent transparent #555555 transparent;
      }

      ::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
        border-color: transparent transparent #777777 transparent;
      }
      /* Down */
      ::-webkit-scrollbar-button:single-button:vertical:increment {
        border-width: 8px 8px 0 8px;
        border-color: #555555 transparent transparent transparent;
      }

      ::-webkit-scrollbar-button:vertical:single-button:increment:hover {
        border-color: #777777 transparent transparent transparent;
      }
    }
    /*End Scroll - Works on Chrome, Edge, and Safari */

html {
  /*
  A font padrão do root(html) é 16px. Para definir
  que a cada 1rem o tamanho seja considerado 10px,
  o calculo é (10px / 16px) * 100% = 62,5%
  Agora para definir um tamanho de 14px basta colocar
  1.4rem.
  */
  font-size: 62.5%;
}

  body {
    background: var(--branco-gelo-5bits);
    color: var(--azul-5bits);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body, input, textarea, button {
    font-family: 'Red Hat Display', Verdana, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1.6rem; //Define a fonte-size em 16px
  }

  a {
    color: var(--azul-5bits);
  }

  h1 {
    font-size: 2.4rem;
    line-height: 3.2rem;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    text-align: center;
  }

  button {
    cursor: pointer;
  }
`;
