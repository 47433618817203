/* eslint-disable react/jsx-curly-newline */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import React, { useEffect, useState, useCallback } from 'react';
import { FormHandles, Scope } from '@unform/core';

import { MdExpandMore } from 'react-icons/md';

import Loading from '../../../../../../components/Loading';
import Input from '../../../../../../components/Input';
import InputFieldAndText from '../../../../../../components/InputFieldAndText';

import {
  SplitTotalization,
  HeaderAndGrandTotal,
  SplitTotalizationItem,
} from './styles';

interface ITotalization {
  providerName: string;
  total: string;
  deadline: string;
}

interface IHandleTotalization {
  formRef: any | FormHandles;
  listUpdatedByMaterialPurchasing: Array<ITotalization>;
  projectHasTotalizationProp: Array<any>;
  disableTabProp: boolean;
}
const HandleTotalization: React.FC<IHandleTotalization> = ({
  formRef,
  listUpdatedByMaterialPurchasing,
  projectHasTotalizationProp,
  disableTabProp = false,
}) => {
  const [listTotalizationProject, setListTotalizationProject] = useState<
    ITotalization[]
  >([{} as ITotalization]);

  const [disabled, setDisabled] = useState<boolean>(false);

  useEffect(() => {
    setDisabled(disableTabProp);
  }, [disableTabProp]);

  useEffect(() => {
    // eslint-disable-next-line array-callback-return
    listTotalizationProject.map((item, index) => {
      formRef.current?.setFieldValue(
        `projectHasTotalization[${index}].providerName`,
        item.providerName,
      );
      formRef.current?.setFieldValue(
        `projectHasTotalization[${index}].total`,
        item.total,
      );
      formRef.current?.setFieldValue(
        `projectHasTotalization[${index}].deadline`,
        item.deadline,
      );
    });
  }, [formRef, listTotalizationProject]);

  const [showTotalization, setShowTotalization] = useState(false);

  const handleSectionTotalization = useCallback(() => {
    setShowTotalization(!showTotalization);
  }, [showTotalization]);

  const showSectionTotalization = useCallback(() => {
    const sectionTotalization: HTMLElement | null = document.querySelector(
      `#totalizationAreaPurchasing`,
    );
    const iconExpandMore: HTMLElement | null = document.querySelector(
      `#iconExpandMoreTotalizationPurchasing`,
    );

    if (sectionTotalization !== null && sectionTotalization !== undefined) {
      if (showTotalization === true) {
        sectionTotalization.style.display = 'block';

        if (iconExpandMore !== null && iconExpandMore !== undefined) {
          iconExpandMore.style.transform = 'rotate(180deg)';
          iconExpandMore.style.transitionDuration = '0.3s';
        }
      } else {
        sectionTotalization.style.display = 'none';

        if (iconExpandMore !== null && iconExpandMore !== undefined) {
          iconExpandMore.style.transform = 'rotate(0deg)';
          iconExpandMore.style.transitionDuration = '0.3s';
        }
      }
    }
  }, [showTotalization]);

  useEffect(() => {
    showSectionTotalization();
  }, [showSectionTotalization, showTotalization]);

  useEffect(() => {
    if (projectHasTotalizationProp && projectHasTotalizationProp.length > 0) {
      setListTotalizationProject(projectHasTotalizationProp);
      setShowTotalization(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectHasTotalizationProp]);

  useEffect(() => {
    if (
      listUpdatedByMaterialPurchasing.length > 0 &&
      Object.entries(listUpdatedByMaterialPurchasing[0]).length > 0
    ) {
      setListTotalizationProject(listUpdatedByMaterialPurchasing);
      setShowTotalization(false);
    } else {
      setListTotalizationProject([{} as ITotalization]);
      setShowTotalization(true);
      formRef.current?.clearField(`projectHasTotalization[0].providerName`);
      formRef.current?.clearField(`projectHasTotalization[0].total`);
      formRef.current?.clearField(`projectHasTotalization[0].deadline`);
    }
  }, [formRef, listUpdatedByMaterialPurchasing]);

  window.onresize = () => {
    // console.log(window.visualViewport.width);
  };

  return (
    <SplitTotalization>
      <HeaderAndGrandTotal
        onClick={handleSectionTotalization}
        data-id="totalizationAreaPurchasing"
      >
        <span>
          Totalização
          <MdExpandMore id="iconExpandMoreTotalizationPurchasing" size={30} />
        </span>
      </HeaderAndGrandTotal>
      <section id="totalizationAreaPurchasing">
        {listTotalizationProject.length > 0 &&
          listTotalizationProject.map((item, index) => (
            <Scope
              path={`projectHasTotalization[${index}]`}
              key={String(index)}
            >
              <SplitTotalizationItem>
                <Input
                  id="providerName"
                  name="providerName"
                  type="text"
                  labelText="Fornecedor"
                  autoComplete="off"
                  readOnly
                  disabled={disabled}
                />
                <Input
                  id="total"
                  name="total"
                  type="text"
                  labelText={index === 0 ? 'Total' : ''}
                  autoComplete="off"
                  readOnly
                  disabled={disabled}
                />
                <InputFieldAndText
                  id="deadline"
                  name="deadline"
                  labelText={index === 0 ? 'Prazo Máximo' : ''}
                  textSecondField="Dias"
                  placeholder=""
                  autoComplete="off"
                  maxLength={45}
                  readOnly
                  disabled={disabled}
                />
              </SplitTotalizationItem>
            </Scope>
          ))}
      </section>
    </SplitTotalization>
  );
};

export default HandleTotalization;
