import React, { useEffect, useState, useCallback } from 'react';

import { useToast } from '../../../context/ToastContext';

import { Container, Content } from './styles';
import api from '../../../services/api';

import convertDateToBr from '../../../utils/convertDateToBr';

import ListingTemplate from '../../../components/Template/Listing';

import { IDrawings, IDrawingsResponse } from '../../../interfaces/drawings';
import Button from '../../../components/Button';
import Loading from '../../../components/Loading';

import { IPermission } from '../../../interfaces/permission';
import convertDateStringToDate from '../../../utils/convertDateStringToDate';

interface PermissionDTO {
  permissions: IPermission;
}

const ListDrawings: React.FC<PermissionDTO> = ({ permissions }) => {
  const { addToast } = useToast();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingFile, setLoadingFile] = useState<boolean>(false);
  const [buttonClicked, setButtonClicked] = useState<string>('');
  const [listDrawings, setListDrawings] = useState<IDrawings[]>([]);
  const [listDrawingsPaginated, setListDrawingsPaginated] = useState<
    IDrawings[]
  >([]);

  useEffect(() => {
    setLoading(true);

    api
      .get<IDrawingsResponse[]>('drawings')
      .then(response => {
        const responseFormatted = response.data.map((i: IDrawingsResponse) => ({
          ...i,
          createdAt: convertDateStringToDate(i.createdAt),
          updatedAt: convertDateStringToDate(i.updatedAt),
        }));

        setListDrawings(responseFormatted);
        setListDrawingsPaginated(responseFormatted);

        setLoading(false);
      })
      .catch(error => {
        setLoading(false);

        if (error.response && error.response.data) {
          const { data } = error.response; // Error vindo do back está em data dentro de response
          addToast({
            type: 'error',
            title: data.message,
          });
          return;
        }

        addToast({
          type: 'error',
          title: 'Erro ao listar!',
          description: `Error: ${error}`,
        });
      });
  }, [addToast]);

  const handleOpenFile = useCallback(
    (fileName: string, nameButtonClicked: string) => {
      setLoadingFile(true);
      setButtonClicked(nameButtonClicked);

      const extensionFile = fileName.substring(fileName.lastIndexOf('.') + 1);

      let typeBlob = '';

      switch (extensionFile) {
        case 'pdf':
          typeBlob = `application/${extensionFile}`;
          break;
        case 'tiff':
          typeBlob = `image/${extensionFile}`;
          break;
        default:
          typeBlob = `image/${extensionFile}`;
      }

      api
        .get(`drawings/file/${fileName}`, {
          responseType: 'arraybuffer',
        })
        .then((response: any) => {
          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: typeBlob }),
          );

          window.open(url);
          setLoadingFile(false);
          setButtonClicked('');
        })
        .catch(error => {
          setLoadingFile(false);
          setButtonClicked('');

          if (error.response.data) {
            const { data } = error.response; // Error vindo do back está em data dentro de response
            addToast({
              type: 'error',
              title: data.message,
            });
            return;
          }

          addToast({
            type: 'error',
            title: 'Erro ao Exibir!',
            description: `Error: ${error}`,
          });
        });
    },
    [addToast],
  );

  const handleListDrawingsPaginatedItems = useCallback(newList => {
    setListDrawingsPaginated(newList);
  }, []);

  return (
    <ListingTemplate
      title="Desenhos Técnicos"
      module="drawings"
      listItemsProps={listDrawings}
      handleListItemsPaginatedItems={handleListDrawingsPaginatedItems}
      placeHolderProp="Número ou Nome"
      displayButtonNew={false}
      disabled={loading}
      permissions={permissions}
    >
      <Container>
        <Content>
          {loading ? (
            <p>carregando ...</p>
          ) : (
            <table>
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>Número</th>
                  <th>Revisão</th>
                  <th>Data</th>
                  <th>Arquivo</th>
                  {/* <th>Ações</th> */}
                </tr>
              </thead>
              <tbody>
                {listDrawingsPaginated.map((s, index) => (
                  <tr key={String(s.id)}>
                    <td>{s.drawingName}</td>
                    <td>{s.drawingNumber}</td>
                    <td>{s.revisionNumber}</td>
                    <td>{convertDateToBr(s.revisionDate)}</td>
                    <td>
                      {(loadingFile &&
                        buttonClicked === `openFile${index}` && (
                          <Button
                            name="buttonSpinner"
                            type="button"
                            onClick={e => {
                              e.preventDefault();
                            }}
                          >
                            <Loading size={24} color="white" />
                          </Button>
                        )) || (
                        <Button
                          name={`openFile${index}`}
                          type="button"
                          onClick={e => {
                            e.preventDefault();
                            handleOpenFile(s.fileName, `openFile${index}`);
                          }}
                        >
                          Exibir
                        </Button>
                      )}
                    </td>
                    {/* <td>
                      <Link to={`/drawings/update/${s.id}`}>
                        <FiEdit size={16} color="var(--azul-5bits)" />
                      </Link>
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </Content>
      </Container>
    </ListingTemplate>
  );
};

export default ListDrawings;
