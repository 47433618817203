import styled from 'styled-components';

export const SplitResume = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  padding-top: 3.125rem;
  padding-bottom: 3.125rem;
  border-bottom: 0.1rem solid var(--cinza-claro-5bits);

  > section {
    margin-top: 3.8rem;

    display: flex;
    justify-content: space-between;
  }
`;

export const HeaderAndGrandTotal = styled.div`
  display: flex;
  justify-content: space-between;

  width: 100%;

  cursor: pointer;
  :hover {
    background: var(--branco-gelo-5bits);
  }

  > span {
    display: flex;
    justify-content: center;
    align-items: center;

    width: fit-content;

    font-style: normal;
    font-weight: 500;
    font-size: 2.4rem;
    line-height: 3.2rem;
    text-transform: capitalize;

    color: var(--cinza-escuro-5bits);
  }
`;

export const SplitLeft = styled.div`
  width: 100%;

  > div {
    width: fit-content;

    > label {
      margin-left: 1rem;
    }
  }
`;

export const SplitRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;

  width: 100%;
  margin-bottom: 1.7rem;

  > div {
    width: fit-content;
    margin-bottom: 2.7rem;
    color: var(--cinza-escuro-5bits);

    > span {
      margin-left: 0.5rem;

      font-weight: 900;
      font-size: 2.4rem;
      line-height: 3.2rem;

      color: var(--laranja-5bits);
    }
  }

  /* Lucro bruto do projeto */
  > div:nth-child(2) {
    > span {
      color: var(--verde-5bits);
    }
  }

  /* Preço final cobrado pelo projeto */
  > div:last-child {
    padding-top: 3.1rem;
    border-top: 1px solid var(--cinza-claro-5bits);

    font-weight: bold;
    color: var(--azul-5bits);
  }
`;
