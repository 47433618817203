import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin: 19px 46px 0;

  header {
    font-weight: 500;
    font-size: 2.4rem;

    margin-left: 1rem;
    margin-bottom: 1.6875rem;
  }

  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  //Medias
  @media (min-width: 0px) and (max-width: 759px) {
    margin: 12px 12px;
  }

  @media (min-width: 760px) and (max-width: 999px) {
  }
`;

export const Split = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 50%;
  margin-bottom: 3.125rem;

  > div {
    margin-bottom: 1rem;
    label {
      margin-left: 1rem;
    }
  }

  //Medias
  @media (min-width: 0px) and (max-width: 759px) {
    width: 100%;
  }

  @media (min-width: 760px) and (max-width: 999px) {
  }
`;

export const UnitAndPrice = styled.div`
  display: flex;
  width: 100%;

  > div:last-child {
    margin-left: 1.5rem;
    width: 37%;
  }

  //Medias
  @media (min-width: 0px) and (max-width: 759px) {
    flex-wrap: wrap;

    /* Preço */
    > div:last-child {
      margin-left: 0;
      margin-top: 10px;
      width: 100%;
    }
  }

  @media (min-width: 760px) and (max-width: 999px) {
  }
`;
