import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;

  margin-top: 1.9rem;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 92%;
  /* margin: 1.9rem 0 0 4.6rem; */

  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    header {
      width: 100%;
      font-weight: 500;
      font-size: 2.4rem;
      line-height: 3.2rem;

      /* margin-left: 1rem; */
      margin-bottom: 1.6875rem;
      /* padding-right: 4.6rem; */

      > span {
        color: var(--cinza-claro-5bits);
      }
    }
  }
`;

export const SplitFooter = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;

  width: 100%;
  padding-top: 3.125rem;
  padding-bottom: 3.125rem;

  > div {
    /* flex: 50%; */
    /* padding-right: 4.2rem; */
    width: 48%;
    margin-bottom: 1rem;
    label {
      margin-left: 1rem;
    }
  }
  > div:last-child {
    display: flex;
    justify-content: flex-end;

    button {
      width: 69%;
    }
  }
`;
