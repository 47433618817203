/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
import React, { useState, useCallback, useRef, useEffect } from 'react';
import { BsSearch } from 'react-icons/bs';
import { IoIosFunnel } from 'react-icons/io';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';

import { useToast } from '../../../../../context/ToastContext';

import InputHidden from '../../../../../components/InputHidden';
import InputSearchWithButton from '../../../../../components/InputSearchWithButton';

import SimpleSelect from '../../../../../components/SimpleSelect';
import Button from '../../../../../components/Button';
import CheckboxInput from '../../../../../components/CheckboxInput';
import Pagination from '../../../../../components/Pagination';
import { businessProposalStatusValid } from '../../../../../data/businessProposalStatusValid.enum';

import api from '../../../../../services/api';
import Tooltip from '../../../../../components/Tooltip';

import { IPermission } from '../../../../../interfaces/permission';
import { IBusinessProposalResponse } from '../../../../../interfaces/businessProposal';

import {
  Container,
  Content,
  ButtonFilterAndInputSearchWithButton,
  SearchArea,
  FilterSearchArea,
  CheckListArea,
  ContentChildren,
} from './styles';
import { IProject } from '../../../../../interfaces/project';

interface ListingTemplateProps {
  title: string;
  module: string;
  moduleForButtonNew?: string;
  moduleForSelectFind?: string;
  moduleSearchPerDate?: string;
  listItemsProps: Array<any>;
  // eslint-disable-next-line no-unused-vars
  handleListItemsPaginatedItems: (newList: Array<any>) => void;
  filterDate?: boolean;
  searchTheLocalObject?: boolean;
  displayButtonNew?: boolean;
  placeHolderProp?: string;
  displaySearchArea?: boolean;
  disabled?: boolean;
  permissions: IPermission;
  perPageForPaginationProp?: number;
}

interface IBusinessProposalForList {
  id: number | null;
  projectId: number;
  clientName: string;
  description: string;
  purchaseOrderFileName: string;
  purchaseOrderNumber: string;
  currentSector: string;
  status: string;
  isCanceled: boolean;
}

const ListingTemplate: React.FC<ListingTemplateProps> = ({
  title,
  module,
  moduleForSelectFind = '',

  listItemsProps,
  handleListItemsPaginatedItems,

  placeHolderProp = 'Pesquisar',
  displaySearchArea = true,
  disabled = false,
  children,
  permissions = {
    create: false,
    read: false,
    update: false,
    delete: false,
  } as IPermission,
  perPageForPaginationProp = 10,
}) => {
  const formInputSearchButton = useRef<FormHandles>(null);
  const formSearchListItemRef = useRef<FormHandles>(null);

  const [listAllItems, setListAllItems] = useState<any[]>([]);
  const [listItemsOriginal, setListItemsOriginal] = useState<any[]>([]);

  const [loading, setLoading] = useState<boolean>(false);
  // const [loadModalWait, setLoadModalWait] = useState(false);
  const [showFilter, setShowFilter] = useState(false);

  const { addToast } = useToast();

  const toggleShowFilter = useCallback(() => {
    setShowFilter(!showFilter);
  }, [showFilter]);

  useEffect(() => {
    setLoading(disabled);
    const getListAllItems = (): void => {
      setListAllItems(listItemsProps);
      setListItemsOriginal(listItemsProps);
    };
    getListAllItems();
  }, [listItemsProps, disabled]);

  // handleSearchSubmit
  const handleSearchSubmit = useCallback(
    async (find: string): Promise<Array<any>> => {
      try {
        setLoading(true);
        let response: any;
        if (find === '') {
          response = await api.get(`projects`);
        } else {
          response = await api.get(`proposals/search/${find}`);
        }

        if (response.data.length > 0) {
          const projectWithFinishedQuoteAndInPlanningSector = response.data.filter(
            (item: IProject) => {
              return !!(
                item.finishedQuote === true &&
                (item.currentSector === 'Planejamento' ||
                  item.currentSector === 'Comercial' ||
                  item.currentSector === 'Produção')
              );
            },
          );

          const responseBusinessListing = [] as Array<IBusinessProposalForList>;
          projectWithFinishedQuoteAndInPlanningSector.map((i: IProject) => {
            if (i.businessProposals.length > 0) {
              i.businessProposals.forEach(
                (
                  itemBusinessProposal: Omit<
                    IBusinessProposalResponse,
                    'project'
                  >,
                ) => {
                  responseBusinessListing.push({
                    id: itemBusinessProposal.id,
                    projectId: i.id,
                    clientName: i.client.name,
                    description: i.description,
                    purchaseOrderFileName: i.purchaseOrderFileName,
                    purchaseOrderNumber: i.purchaseOrderNumber,
                    currentSector: i.currentSector,
                    status: itemBusinessProposal.status,
                    isCanceled: itemBusinessProposal.isCanceled,
                  });
                },
              );
            } else {
              responseBusinessListing.push({
                id: null,
                projectId: i.id,
                clientName: i.client.name,
                description: i.description,
                purchaseOrderFileName: i.purchaseOrderFileName,
                purchaseOrderNumber: i.purchaseOrderNumber,
                currentSector: i.currentSector,
                status: businessProposalStatusValid.Elaborar,
                isCanceled: false,
              });
            }

            return responseBusinessListing;
          });

          setLoading(false);
          return responseBusinessListing;
        }

        setLoading(false);
        return [];
      } catch (error: any) {
        setLoading(false);

        if (error.response) {
          const { data } = error.response; // Error vindo do back está em data dentro de response
          addToast({
            type: 'error',
            title: data.message,
          });
          return [];
        }

        addToast({
          type: 'error',
          title: 'Erro ao listar!',
          description: `Error: ${error}`,
        });
      }
      return [];
    },
    [addToast],
  );
  // Fim handleSearchSubmit

  const handleSearchOnListLocal = useCallback(
    value => {
      if (value && listItemsOriginal) {
        const newListAllItems = listItemsOriginal.filter(
          item =>
            item.outgoingFinancialAccount.name.toLowerCase() ===
              value.label.toLowerCase() ||
            item.incomingFinancialAccount.name.toLowerCase() ===
              value.label.toLowerCase(),
        );
        if (
          !newListAllItems ||
          (newListAllItems && newListAllItems.length < 1)
        ) {
          addToast({
            type: 'error',
            title: 'Nenhum registro encontrado',
          });
        } else {
          handleListItemsPaginatedItems(newListAllItems);
          setListAllItems(newListAllItems);
        }
      } else {
        formSearchListItemRef.current?.clearField('find');
        formSearchListItemRef.current?.submitForm();
      }
    },
    [addToast, listItemsOriginal, handleListItemsPaginatedItems],
  );

  const handleSearchOnListLocalFilter = useCallback(async () => {
    const dataFormInputSearchButton = formInputSearchButton.current?.getData();
    const dataForm = formSearchListItemRef.current?.getData();

    if (
      dataFormInputSearchButton !== undefined &&
      dataFormInputSearchButton !== null &&
      dataForm !== undefined &&
      dataForm !== null
    ) {
      const onlyCheckboxWithTrue: Array<any> = Object.entries(dataForm).filter(
        item => item[1] === true,
      );

      if (listItemsOriginal) {
        if (
          dataFormInputSearchButton.find !== '' ||
          onlyCheckboxWithTrue.length > 0
        ) {
          let newListAllItems: any = [];

          if (
            dataFormInputSearchButton.find !== '' &&
            onlyCheckboxWithTrue.length === 0
          ) {
            newListAllItems = await handleSearchSubmit(
              dataFormInputSearchButton.find,
            );
          } else if (
            dataFormInputSearchButton.find !== '' &&
            onlyCheckboxWithTrue.length > 0
          ) {
            const projectSearchedByInputFind: any = await handleSearchSubmit(
              dataFormInputSearchButton.find,
            );

            if (
              projectSearchedByInputFind &&
              projectSearchedByInputFind.length > 0
            ) {
              newListAllItems = projectSearchedByInputFind.filter((item: any) =>
                onlyCheckboxWithTrue.some(
                  itemFilter => itemFilter[0] === item.status,
                ),
              );
            }
          } else {
            newListAllItems = listItemsOriginal.filter(item =>
              onlyCheckboxWithTrue.some(
                itemFilter => itemFilter[0] === item.status,
              ),
            );
          }

          if (
            !newListAllItems ||
            (newListAllItems && newListAllItems.length < 1)
          ) {
            handleListItemsPaginatedItems([]);
            setListAllItems([]);
          } else {
            handleListItemsPaginatedItems(newListAllItems);
            setListAllItems(newListAllItems);
          }
        } else {
          const newListAllProjects = await handleSearchSubmit('');

          handleListItemsPaginatedItems(newListAllProjects);
          setListAllItems(newListAllProjects);
          setListItemsOriginal(newListAllProjects);
        }
      } else {
        formInputSearchButton.current?.clearField('find');
      }
    }
  }, [listItemsOriginal, handleSearchSubmit, handleListItemsPaginatedItems]);

  return (
    <Container>
      <Content>
        <header>
          <p>{title}</p>
          <ButtonFilterAndInputSearchWithButton showSearchArea={showFilter}>
            <Button type="button" onClick={toggleShowFilter}>
              <IoIosFunnel size="1.6rem" />
              Filtrar
            </Button>
            <Form
              ref={formInputSearchButton}
              onSubmit={handleSearchOnListLocalFilter}
            >
              <InputSearchWithButton
                type="text"
                name="find"
                icon={BsSearch}
                placeholder={placeHolderProp}
                aria-label={placeHolderProp}
                maxLength={45}
                disabled={loading}
              />
            </Form>
          </ButtonFilterAndInputSearchWithButton>

          <SearchArea showSearchArea={showFilter}>
            <Form
              ref={formSearchListItemRef}
              onSubmit={() => {
                null;
              }}
            >
              {permissions.read && (
                <>
                  {displaySearchArea && (
                    <>
                      {(!moduleForSelectFind && (
                        <>
                          <FilterSearchArea>
                            <CheckListArea>
                              {/* Elaborar */}
                              <Tooltip
                                style={{
                                  textTransform: 'none',
                                }}
                                colorBefore="var(--azul-5bits)"
                                colorSpan="var(--azul-5bits)"
                                spanLeft="60%"
                                beforeLeft="70%"
                                tooltipReversedProp
                                widthSpanWithContentProp="23rem"
                                title="Criar proposta"
                              >
                                <CheckboxInput
                                  name={`${businessProposalStatusValid.Elaborar}`}
                                  id={`${businessProposalStatusValid.Elaborar}`}
                                  position="left"
                                  label={businessProposalStatusValid.Elaborar}
                                  onClick={handleSearchOnListLocalFilter}
                                />
                              </Tooltip>

                              {/* Parcial */}
                              <Tooltip
                                style={{
                                  textTransform: 'none',
                                }}
                                colorBefore="var(--azul-5bits)"
                                colorSpan="var(--azul-5bits)"
                                tooltipReversedProp
                                widthSpanWithContentProp="23rem"
                                title="Proposta somente com preço definido"
                              >
                                <CheckboxInput
                                  name={`${businessProposalStatusValid.Parcial}`}
                                  id={`${businessProposalStatusValid.Parcial}`}
                                  position="left"
                                  label={businessProposalStatusValid.Parcial}
                                  onClick={handleSearchOnListLocalFilter}
                                />
                              </Tooltip>

                              {/* Concluida */}
                              <Tooltip
                                style={{
                                  textTransform: 'none',
                                }}
                                colorBefore="var(--azul-5bits)"
                                colorSpan="var(--azul-5bits)"
                                tooltipReversedProp
                                widthSpanWithContentProp="23rem"
                                title="Proposta liberada pra enviar"
                              >
                                <CheckboxInput
                                  name={`${businessProposalStatusValid.Concluida}`}
                                  id={`${businessProposalStatusValid.Concluida}`}
                                  position="left"
                                  label={businessProposalStatusValid.Concluida}
                                  onClick={handleSearchOnListLocalFilter}
                                />
                              </Tooltip>

                              {/* Enviada */}
                              <Tooltip
                                style={{
                                  textTransform: 'none',
                                }}
                                colorBefore="var(--azul-5bits)"
                                colorSpan="var(--azul-5bits)"
                                tooltipReversedProp
                                widthSpanWithContentProp="23rem"
                                title="Proposta enviada ao cliente"
                              >
                                <CheckboxInput
                                  name={`${businessProposalStatusValid.Enviada}`}
                                  id={`${businessProposalStatusValid.Enviada}`}
                                  position="left"
                                  label={businessProposalStatusValid.Enviada}
                                  onClick={handleSearchOnListLocalFilter}
                                />
                              </Tooltip>

                              {/* Aprovada */}
                              <Tooltip
                                style={{
                                  textTransform: 'none',
                                }}
                                colorBefore="var(--azul-5bits)"
                                colorSpan="var(--azul-5bits)"
                                tooltipReversedProp
                                widthSpanWithContentProp="23rem"
                                title="Proposta aprovada pelo cliente"
                              >
                                <CheckboxInput
                                  name={`${businessProposalStatusValid.Aprovada}`}
                                  id={`${businessProposalStatusValid.Aprovada}`}
                                  position="left"
                                  label={businessProposalStatusValid.Aprovada}
                                  onClick={handleSearchOnListLocalFilter}
                                />
                              </Tooltip>

                              {/* Executando */}
                              <Tooltip
                                style={{
                                  textTransform: 'none',
                                }}
                                colorBefore="var(--azul-5bits)"
                                colorSpan="var(--azul-5bits)"
                                tooltipReversedProp
                                widthSpanWithContentProp="23rem"
                                title="Projeto em produção"
                              >
                                <CheckboxInput
                                  name={`${businessProposalStatusValid.Executando}`}
                                  id={`${businessProposalStatusValid.Executando}`}
                                  position="left"
                                  label={businessProposalStatusValid.Executando}
                                  onClick={handleSearchOnListLocalFilter}
                                />
                              </Tooltip>

                              {/* Arquivada */}
                              <Tooltip
                                style={{
                                  textTransform: 'none',
                                }}
                                colorBefore="var(--azul-5bits)"
                                colorSpan="var(--azul-5bits)"
                                tooltipReversedProp
                                widthSpanWithContentProp="23rem"
                                title="Projeto concluído"
                              >
                                <CheckboxInput
                                  name={`${businessProposalStatusValid.Arquivada}`}
                                  id={`${businessProposalStatusValid.Arquivada}`}
                                  position="left"
                                  label={businessProposalStatusValid.Arquivada}
                                  onClick={handleSearchOnListLocalFilter}
                                />
                              </Tooltip>

                              {/* Cancelada */}
                              <Tooltip
                                style={{
                                  textTransform: 'none',
                                }}
                                colorBefore="var(--azul-5bits)"
                                colorSpan="var(--azul-5bits)"
                                tooltipReversedProp
                                widthSpanWithContentProp="23rem"
                                title="Proposta cancelada"
                              >
                                <CheckboxInput
                                  name={`${businessProposalStatusValid.Cancelada}`}
                                  id={`${businessProposalStatusValid.Cancelada}`}
                                  position="left"
                                  label={businessProposalStatusValid.Cancelada}
                                  onClick={handleSearchOnListLocalFilter}
                                />
                              </Tooltip>
                            </CheckListArea>
                          </FilterSearchArea>
                        </>
                      )) || (
                        <>
                          <InputHidden type="hidden" name="find" />
                          <SimpleSelect
                            module={moduleForSelectFind}
                            name="findSelect"
                            icon={BsSearch}
                            placeholder={placeHolderProp}
                            aria-label={`Pesquisar por ${placeHolderProp}`}
                            isClearable
                            onChange={e => handleSearchOnListLocal(e)}
                          />
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </Form>
          </SearchArea>
        </header>
        {loading ? (
          <p>carregando ...</p>
        ) : (
          <>
            <ContentChildren>{children}</ContentChildren>

            <Pagination
              listItems={listAllItems}
              perPageProp={perPageForPaginationProp}
              onChangeListPaginatedItems={handleListItemsPaginatedItems}
            />
          </>
        )}
      </Content>
    </Container>
  );
};

export default ListingTemplate;
