/* eslint-disable react/jsx-curly-newline */
import React from 'react';
import { Scope } from '@unform/core';
import { RiCloseLine } from 'react-icons/ri';
import IconCheckmark from '../../../../assets/Checkmark.svg';
import Button from '../../../../components/Button';
import Loading from '../../../../components/Loading';

import Modal from '../../../../components/Modal';

import {
  Container,
  Content,
  ChecksItensArea,
  ActionSaveModal,
  ActionCloseModal,
  CheckboxHidden,
} from './styles';
import CheckboxInput from '../../../../components/CheckboxInput';

import { IChecklist } from '../../../../interfaces/checklist';

interface IProjectHasChecklist {
  projectId: number;
  checklistId: number;
  userId: number;
  isConcluded: boolean;
  dateConcluded: string;
  createdAt: string;
  updatedAt: string;
}

interface ModalDTO {
  saving: boolean;
  loadModalProp: boolean;
  listChecklistProp: Array<IChecklist>;
  projectHasChecklist: Array<IProjectHasChecklist>;
  closeModalChecklist: () => void;
}

const ModalChecklist: React.FC<ModalDTO> = ({
  saving = false,
  loadModalProp = false,
  listChecklistProp,
  projectHasChecklist,
  closeModalChecklist,
}) => {
  return (
    <Modal visibleProp={loadModalProp}>
      <Container>
        <h1>Checklist de Processos</h1>
        <Content>
          <ChecksItensArea>
            {listChecklistProp &&
              listChecklistProp.length > 0 &&
              listChecklistProp.map((item, index) => (
                <Scope
                  path={`projectHasChecklist[${index}]`}
                  key={String(index)}
                >
                  <div key={String(index)}>
                    {(projectHasChecklist &&
                      projectHasChecklist.length > 0 &&
                      projectHasChecklist.find(
                        projectCheckItem =>
                          projectCheckItem.checklistId === item.id &&
                          projectCheckItem.isConcluded === true,
                      ) !== undefined && (
                        <>
                          <img src={IconCheckmark} alt="Checkmark" />
                          <span>{item.name}</span>
                          <CheckboxHidden>
                            <CheckboxInput
                              name={`check${item.id}`}
                              id={`check${item.id}`}
                              position="left"
                              label={item.name}
                              checked
                            />
                          </CheckboxHidden>
                        </>
                      )) || (
                      <>
                        <CheckboxInput
                          name={`check${item.id}`}
                          id={`check${item.id}`}
                          position="left"
                          label={item.name}
                        />
                      </>
                    )}
                  </div>
                </Scope>
              ))}
          </ChecksItensArea>
          <ActionSaveModal>
            <Button type="submit" disabled={saving}>
              {saving ? <Loading size={24} color="white" /> : 'Salvar'}
            </Button>
          </ActionSaveModal>
        </Content>
        <ActionCloseModal>
          <RiCloseLine
            name="closeModal"
            size={36}
            onClick={() => {
              closeModalChecklist();
            }}
          >
            Fechar
          </RiCloseLine>
        </ActionCloseModal>
      </Container>
    </Modal>
  );
};

export default ModalChecklist;
