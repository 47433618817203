/* eslint-disable react/jsx-curly-newline */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import React, {
  useEffect,
  useState,
  useCallback,
  memo,
  ReactElement,
} from 'react';
import { FormHandles } from '@unform/core';
import { MdExpandMore } from 'react-icons/md';
import { differenceInCalendarDays } from 'date-fns';
import { IManufacturingSteps } from '../../../../../../../interfaces/manufacturingSteps';
import convertDateAndHoursToBr from '../../../../../../../utils/convertDateAndHoursToBr';
import convertDateStringToDate from '../../../../../../../utils/convertDateStringToDate';
import api from '../../../../../../../services/api';
import { useToast } from '../../../../../../../context/ToastContext';

import {
  SplitManufacturingSteps,
  ContentManufacturingStep,
  HeaderAndGrandTotal,
  HistoricContainer,
  HistoricTitle,
  HistoricTable,
  MoreInfo,
  DelayDaysInfo,
  PrevisionContainer,
  PrevisionTitle,
  PrevisionDates,
} from './styles';

interface IStepsInManufacturing {
  createdAt: string;
  endDateTime: string;
  forecastDays: string;
  forecastEndDateTime: string;
  forecastStartDateTime: string;
  forecastTime: string;
  id: number;
  name: string;
  isFinished: boolean;
  isLastSchedule: boolean;
  isStop: boolean;
  justifyDelay: string;
  justifyStop: string;
  manufacturingStepId: number;
  projectId: number;
  startDateTime: string;
  stopDateTime: string;
  totalDelayDays: number;
  updatedAt: string;
}

interface IListStepsInManufacturing extends IStepsInManufacturing {
  createdAt: string;
  endDateTime: string;
  forecastDays: string;
  forecastEndDateTime: string;
  forecastStartDateTime: string;
  forecastTime: string;
  id: number;
  name: string;
  isFinished: boolean;
  isLastSchedule: boolean;
  isStop: boolean;
  justifyDelay: string;
  justifyStop: string;
  manufacturingStepId: number;
  projectId: number;
  startDateTime: string;
  stopDateTime: string;
  totalDelayDays: number;
  updatedAt: string;
  historicOfTheStep: IStepsInManufacturing[];
}

interface IHandleManufacturingSteps {
  formRef: any | FormHandles;
  projectHasManufacturingStepsProp: Array<any>;
  listManufacturingStepsProp: IManufacturingSteps[];
  disableTabProp: boolean;
}
const HandleManufacturingSteps: React.FC<IHandleManufacturingSteps> = ({
  projectHasManufacturingStepsProp,
  listManufacturingStepsProp,
  disableTabProp = false,
}) => {
  const { addToast } = useToast();
  const [
    listManufacturingStepsTheProject,
    setListManufacturingStepsTheProject,
  ] = useState<IListStepsInManufacturing[]>([{} as IListStepsInManufacturing]);

  const [listManufacturingSteps, setListManufacturingSteps] = useState<
    IManufacturingSteps[]
  >();

  const [disabled, setDisabled] = useState<boolean>(false);

  useEffect(() => {
    setDisabled(disableTabProp);
  }, [disableTabProp]);

  // const [showManufacturingSteps, setShowManufacturingSteps] = useState(false);

  const showSectionManufacturingSteps = useCallback(idElement => {
    const sectionManufacturingSteps: HTMLElement | null = document.querySelector(
      `#manufacturingStepsAreaInProject${idElement}`,
    );
    const iconExpandMore: HTMLElement | null = document.querySelector(
      `#iconExpandMoreManufacturingStepsInProject${idElement}`,
    );

    if (
      sectionManufacturingSteps !== null &&
      sectionManufacturingSteps !== undefined
    ) {
      if (sectionManufacturingSteps.style.display !== 'block') {
        sectionManufacturingSteps.style.display = 'block';

        if (iconExpandMore !== null && iconExpandMore !== undefined) {
          iconExpandMore.style.transform = 'rotate(180deg)';
          iconExpandMore.style.transitionDuration = '0.3s';
        }
      } else {
        sectionManufacturingSteps.style.display = 'none';

        if (iconExpandMore !== null && iconExpandMore !== undefined) {
          iconExpandMore.style.transform = 'rotate(0deg)';
          iconExpandMore.style.transitionDuration = '0.3s';
        }
      }
    }

    // setShowManufacturingSteps(!showManufacturingSteps);
  }, []);

  // handleDelayOS
  const handleDelayOS = useCallback(
    (stepInManufacturing: IStepsInManufacturing): ReactElement => {
      const forecastEndDateTimeAsObjectDate = convertDateStringToDate(
        stepInManufacturing.forecastEndDateTime,
      );

      if (forecastEndDateTimeAsObjectDate !== undefined) {
        const dateActual = new Date();

        // Dias atrasado
        const totalDelayDays = differenceInCalendarDays(
          dateActual,
          forecastEndDateTimeAsObjectDate,
        );

        if (totalDelayDays > 0) {
          return (
            <DelayDaysInfo>
              <span>{totalDelayDays}</span>
              dia{totalDelayDays > 1 ? 's' : ''} em atraso
            </DelayDaysInfo>
          );
        }
      }

      return <></>;
    },
    [],
  );

  // List Manufacturing Steps
  useEffect(() => {
    const getManufacturingSteps = async (): Promise<void> => {
      if (listManufacturingStepsProp.length > 0) {
        setListManufacturingSteps(listManufacturingStepsProp);
      } else {
        try {
          const responseManufacturingSteps = await api.get<
            IManufacturingSteps[]
          >('/manufacturing/steps');

          setListManufacturingSteps(responseManufacturingSteps.data);
        } catch (error: any) {
          if (error.response && error.response.data) {
            const { data } = error.response; // Error vindo do back está em data dentro de response
            addToast({
              type: 'error',
              title: data.message,
            });
            return;
          }

          addToast({
            type: 'error',
            title: 'Error',
            description: 'Erro ao listar!',
          });
        }
      }
    };
    getManufacturingSteps();
  }, [addToast, listManufacturingStepsProp, setListManufacturingSteps]);
  // End List Manufacturing

  useEffect(() => {
    if (
      projectHasManufacturingStepsProp &&
      projectHasManufacturingStepsProp.length > 0 &&
      listManufacturingSteps !== undefined &&
      listManufacturingSteps.length > 0
    ) {
      // Todos os registros da O.S que foram paradas. (Para tabela Histórico)
      const listOfTheOSStop = projectHasManufacturingStepsProp.filter(
        (register: any) => register.isStop === true,
      );

      // Insere o nome da etapa no array de listas das etapas do projeto
      const listManufacturingStepsWithNameStep = projectHasManufacturingStepsProp.map(
        (item: any) => {
          const itemFromListManufacturingStep:
            | IManufacturingSteps
            | undefined = listManufacturingSteps.find(
            (itemProp: IManufacturingSteps) =>
              itemProp.id === item.manufacturingStepId,
          );

          return {
            ...item,
            name:
              itemFromListManufacturingStep !== undefined
                ? itemFromListManufacturingStep.name
                : '',
          };
        },
      );

      // Remove etapas iguais. Isso porque quando se tem um stop na etapa vem dois registros da mesma etapa com a
      const listManufacturingStepsWithNameStepWithoutRepetitions: IListStepsInManufacturing[] = listManufacturingStepsWithNameStep.reduce(
        (listWithoutRepetition, currentItem) => {
          if (
            listWithoutRepetition.find(
              (element: IListStepsInManufacturing) =>
                element.manufacturingStepId === currentItem.manufacturingStepId,
            ) === undefined
          ) {
            return [...listWithoutRepetition, currentItem];
          }

          return listWithoutRepetition;
        },
        [],
      );

      const listManufacturingStepsComplete = listManufacturingStepsWithNameStepWithoutRepetitions.map(
        item => {
          const allHistoricOfTheStep = listOfTheOSStop.filter(
            (itemOsStop: IStepsInManufacturing) =>
              itemOsStop.manufacturingStepId === item.manufacturingStepId,
          );
          return { ...item, historicOfTheStep: [...allHistoricOfTheStep] };
        },
      );

      setListManufacturingStepsTheProject([...listManufacturingStepsComplete]);
    }
  }, [
    listManufacturingSteps,
    listManufacturingStepsProp,
    projectHasManufacturingStepsProp,
  ]);

  return (
    <SplitManufacturingSteps>
      {listManufacturingStepsTheProject.length > 0 &&
        listManufacturingStepsTheProject.map((item, index) => (
          <ContentManufacturingStep key={String(index)}>
            <HeaderAndGrandTotal
              onClick={() => showSectionManufacturingSteps(item.id)}
              data-id={`manufacturingStepsAreaInProject${item.id}`}
            >
              <span>
                {item.name}
                <div>
                  {handleDelayOS(item)}
                  <MoreInfo>
                    Tempo Planejado: <span>{item.forecastTime} horas</span>
                  </MoreInfo>
                  <MoreInfo>
                    Prazo de entrega da etapa:{' '}
                    <span>
                      {convertDateAndHoursToBr(item.forecastEndDateTime)}h
                    </span>
                  </MoreInfo>
                  <MdExpandMore
                    id={`iconExpandMoreManufacturingStepsInProject${item.id}`}
                    size={30}
                  />
                </div>
              </span>
            </HeaderAndGrandTotal>
            <section id={`manufacturingStepsAreaInProject${item.id}`}>
              <HistoricContainer>
                <HistoricTitle>
                  <span>Histórico</span>
                  <div>
                    <span>*</span>
                    <span>
                      {item.historicOfTheStep !== undefined
                        ? item.historicOfTheStep.length
                        : '0'}{' '}
                      registro(s)
                    </span>
                  </div>
                </HistoricTitle>
                <HistoricTable>
                  <table>
                    <thead>
                      <tr>
                        <th>Início</th>
                        <th>Fim</th>
                        <th>Justificativa</th>
                      </tr>
                    </thead>
                    <tbody>
                      {item.historicOfTheStep !== undefined &&
                        item.historicOfTheStep.length > 0 &&
                        item.historicOfTheStep.map(
                          (historicOfTheStopOsItem: any, indexHistoric) => (
                            <tr key={String(indexHistoric)}>
                              <td>
                                {convertDateAndHoursToBr(
                                  historicOfTheStopOsItem.startDateTime,
                                )}
                              </td>
                              <td>
                                {convertDateAndHoursToBr(
                                  historicOfTheStopOsItem.stopDateTime,
                                )}
                              </td>
                              <td>{historicOfTheStopOsItem.justifyStop}</td>
                            </tr>
                          ),
                        )}
                    </tbody>
                  </table>
                </HistoricTable>
              </HistoricContainer>

              <PrevisionContainer>
                <PrevisionTitle>
                  <span>Previsão</span>
                </PrevisionTitle>
                <PrevisionDates>
                  <div>
                    <span>
                      Início:{' '}
                      {Object.entries(item).length > 0 &&
                        convertDateAndHoursToBr(item.forecastStartDateTime)}
                    </span>
                    <span>
                      Fim:{' '}
                      {Object.entries(item).length > 0 &&
                        convertDateAndHoursToBr(item.forecastEndDateTime)}
                    </span>
                  </div>
                </PrevisionDates>
              </PrevisionContainer>
            </section>
          </ContentManufacturingStep>
        ))}
    </SplitManufacturingSteps>
  );
};

export default memo(HandleManufacturingSteps);
