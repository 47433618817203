export default function verifyIfHasScrollInElement(
  idElement: string,
): {
  hasScrollVertical: boolean;
  hasScrollHorizontal: boolean;
  width: string;
  height: string;
} {
  const divContainerWhitScroll = document.getElementById(idElement);

  if (divContainerWhitScroll !== null) {
    let hasScrollHorizontal = false;
    let hasScrollVertical = false;
    let widthForReturn = '';
    let heightForReturn = '';

    if (
      divContainerWhitScroll.scrollWidth > divContainerWhitScroll.clientWidth
    ) {
      hasScrollHorizontal = true;
      widthForReturn = `${divContainerWhitScroll.scrollWidth}px`;
    } else {
      widthForReturn = '100%';
    }

    if (
      divContainerWhitScroll.scrollHeight > divContainerWhitScroll.clientHeight
    ) {
      hasScrollVertical = true;
      heightForReturn = `${divContainerWhitScroll.scrollHeight}px`;
    } else {
      heightForReturn = '100%';
    }

    return {
      hasScrollHorizontal,
      hasScrollVertical,
      width: widthForReturn,
      height: heightForReturn,
    };
  }
  return {
    hasScrollHorizontal: false,
    hasScrollVertical: false,
    width: '100%',
    height: '100%',
  };
}
