/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-curly-newline */
import React from 'react';

import Button from '../../../../../../components/Button';
import Loading from '../../../../../../components/Loading';

import Modal from '../../../../../../components/Modal';

import { Container, Content, ActionSaveModal } from './styles';

interface ModalDTO {
  loadModalProp: boolean;
  indexMaterial: number;
  removeMaterial: (index: number) => void;
  closeModalConfirmationOfExclusionMaterialProp: () => void;
}

const ModalConfirmationOfExclusionMaterial: React.FC<ModalDTO> = ({
  loadModalProp = false,
  indexMaterial,
  closeModalConfirmationOfExclusionMaterialProp,
  removeMaterial,
}) => {
  return (
    <Modal visibleProp={loadModalProp}>
      <Container>
        <h1>Deseja excluir este material?</h1>
        <Content>
          <ActionSaveModal>
            <Button
              type="button"
              onClick={() => {
                removeMaterial(indexMaterial);
                closeModalConfirmationOfExclusionMaterialProp();
              }}
            >
              Sim
            </Button>
            <Button
              type="button"
              onClick={() => {
                closeModalConfirmationOfExclusionMaterialProp();
              }}
            >
              Não
            </Button>
          </ActionSaveModal>
        </Content>
      </Container>
    </Modal>
  );
};

export default ModalConfirmationOfExclusionMaterial;
