import React from 'react';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import GoBackNavigationButton from '../../components/GoBackNavigationButton';

import { ContainerGridLayout, Main } from './styles';

interface ContainerProps {
  pathProp?: string;
}

const Wraper: React.FC<ContainerProps> = ({ pathProp, children }) => {
  return (
    <ContainerGridLayout>
      <Header />
      <Main path={pathProp}>
        {pathProp === undefined && <GoBackNavigationButton />}

        {children}
      </Main>
      <Footer />
    </ContainerGridLayout>
  );
};

export default Wraper;
