import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import AppProvider from './context';
import ApplicationRoutes from './routes';

import GlobalStyles from './styles/global';
import GetRealViewportSizeOnMobile from './utils/getRealViewportSizeOnMobile';
import './App.scss';

const App: React.FC = () => {
  GetRealViewportSizeOnMobile();
  return (
    <BrowserRouter>
      <AppProvider>
        <ApplicationRoutes />
      </AppProvider>

      <GlobalStyles />
    </BrowserRouter>
  );
};

export default App;
