/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-curly-newline */
import React from 'react';
import { FormHandles } from '@unform/core';

import Button from '../../../../components/Button';
import { businessProposalStatusValid } from '../../../../data/businessProposalStatusValid.enum';
import Loading from '../../../../components/Loading';

import Modal from '../../../../components/Modal';

import { Container, Content, ActionSaveModal } from './styles';

interface ModalDTO {
  loadModalProp: boolean;
  handleOpenModalConfirmationStartProduction: (confirmation: boolean) => void;
}

const ModalConfirmationStartProduction: React.FC<ModalDTO> = ({
  loadModalProp = false,
  handleOpenModalConfirmationStartProduction,
}) => {
  return (
    <Modal visibleProp={loadModalProp}>
      <Container>
        <h1>
          Ao enviar o projeto para produção, será iniciada a fabricação do
          projeto.
        </h1>
        <h1>Deseja enviar o projeto para produção?</h1>
        <Content>
          <ActionSaveModal>
            <Button
              type="button"
              onClick={() => {
                handleOpenModalConfirmationStartProduction(true);
              }}
            >
              Sim
            </Button>
            <Button
              type="button"
              onClick={() => {
                handleOpenModalConfirmationStartProduction(false);
              }}
            >
              Não
            </Button>
          </ActionSaveModal>
        </Content>
      </Container>
    </Modal>
  );
};

export default ModalConfirmationStartProduction;
