import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { FiEdit } from 'react-icons/fi';

import { useToast } from '../../../context/ToastContext';

import { Container, Content } from './styles';
import api from '../../../services/api';

import ListingTemplate from '../../../components/Template/Listing';

import { IProvider } from '../../../interfaces/provider';

import { IPermission } from '../../../interfaces/permission';
import convertDateStringToDate from '../../../utils/convertDateStringToDate';
import convertInitToUppercase from '../../../utils/convertInitToUppercase';

interface IProviderDTO extends Omit<IProvider, 'createdAt' | 'updatedAt'> {
  createdAt: string;
  updatedAt: string;
}

interface PermissionDTO {
  permissions: IPermission;
}

const ListProviders: React.FC<PermissionDTO> = ({ permissions }) => {
  const { addToast } = useToast();
  const [loading, setLoading] = useState<boolean>(false);
  const [providers, setProviders] = useState<IProvider[]>([]);
  const [listProvidersPaginated, setListProvidersPaginated] = useState<
    IProvider[]
  >([]);

  useEffect(() => {
    setLoading(true);

    api
      .get<IProviderDTO[]>('providers')
      .then(response => {
        setProviders(
          response.data.map(i => ({
            ...i,
            createdAt: convertDateStringToDate(i.createdAt),
            updatedAt: convertDateStringToDate(i.updatedAt),
          })),
        );
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);

        if (error.response) {
          const { data } = error.response; // Error vindo do back está em data dentro de response
          addToast({
            type: 'error',
            title: 'Ocorreu um erro!',
            description: `Erro: ${data.message}`,
          });
          return;
        }

        addToast({
          type: 'error',
          title: 'Erro ao listar!',
          description: `Erro: ${error}`,
        });
      });
  }, [addToast]);

  const handleListProvidersPaginatedItems = useCallback(newList => {
    setListProvidersPaginated(newList);
  }, []);

  return (
    <ListingTemplate
      title="Listagem de Fornecedores"
      module="providers"
      listItemsProps={providers}
      handleListItemsPaginatedItems={handleListProvidersPaginatedItems}
      disabled={loading}
      permissions={permissions}
    >
      <Container>
        <Content>
          {loading ? (
            <p>carregando ...</p>
          ) : (
            <table>
              <thead>
                <tr>
                  <th>Nome Fantasia</th>
                  <th>Telefone</th>
                  <th>WhatsApp</th>
                  <th>Contato</th>
                  <th>Ativo</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {listProvidersPaginated.map(p => (
                  <tr key={String(p.id)}>
                    <td>{convertInitToUppercase(p.fantasyName)}</td>
                    <td>{p.mainPhone}</td>
                    <td>{p.secondaryPhone}</td>
                    <td>{convertInitToUppercase(p.contactName as string)}</td>
                    <td>{p.isActive ? 'Sim' : 'Não'}</td>
                    <td>
                      {(permissions.update && (
                        <Link to={`/providers/update/${p.id}`}>
                          <FiEdit size={16} color="var(--azul-5bits)" />
                        </Link>
                      )) || (
                        <span>
                          <FiEdit size={16} color="var(--cinza-escuro-5bits)" />
                        </span>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </Content>
      </Container>
    </ListingTemplate>
  );
};

export default ListProviders;
