/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-curly-newline */
import React, { useEffect, useState, useCallback, useRef } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { isValid } from 'date-fns';

import { RiCloseLine } from 'react-icons/ri';

import api from '../../../../../../services/api';
import { useToast } from '../../../../../../context/ToastContext';
import getValidationErrors from '../../../../../../utils/getValidationError';
import Button from '../../../../../../components/Button';
import TextArea from '../../../../../../components/TextArea';
import DatePicker from '../../../../../../components/DatePicker';

import Modal from '../../../../../../components/Modal';
import convertDateAndHoursToDB from '../../../../../../utils/convertDateAndHoursToDB';

import {
  Container,
  ModalLoadingForModal,
  Content,
  TitleAndOSNumber,
  Title,
  OSNumber,
  HeaderContainer,
  HeaderTitle,
  HeaderInformation,
  JustifyContainer,
  JustifyContent,
  TextareaContainer,
  DateAndTimeContainer,
  ActionsContainer,
  ActionsButtons,
  ActionCloseModal,
} from './styles';
import ModalLoading from '../../../../../../components/ModalLoading';
import Loading from '../../../../../../components/Loading';

interface ModalDTO {
  loadModalProp: boolean;
  registerIsLastScheduleProp: any;
  closeModalStopActionProp: () => void;
  closeModalServiceExecutionProp: (removeMask: boolean) => void;
}

const ModalStopAction: React.FC<ModalDTO> = ({
  loadModalProp = false,
  registerIsLastScheduleProp,
  closeModalStopActionProp,
  closeModalServiceExecutionProp,
}) => {
  const { addToast } = useToast();
  const formRefModal = useRef<FormHandles>(null);
  const [registerIsLastSchedule, setRegisterIsLastSchedule] = useState<any>({});
  const [loadingModal, setLoadingModal] = useState<boolean>(false);
  const [savingStopOS, setSavingStopOS] = useState<boolean>(false);

  useEffect(() => {
    setLoadingModal(true);
    setRegisterIsLastSchedule(registerIsLastScheduleProp);
    setLoadingModal(false);
  }, [registerIsLastScheduleProp]);

  const handleFormSubmit = useCallback(async () => {
    try {
      setSavingStopOS(true);

      formRefModal.current?.setErrors({});

      const dataForm: any = formRefModal.current?.getData();

      const schema = Yup.object().shape({
        justifyStop: Yup.string().required('Justificativa é obrigatória'),
        stopDateTime: Yup.date()
          .test('stopDateTime', 'Data inválida', (stopDateTime: any) => {
            const validateDate = stopDateTime;

            return isValid(validateDate);
          })
          .nullable()
          .required('Data obrigatória'),
      });

      await schema.validate(dataForm, { abortEarly: false });

      const newDataForm = {
        id: registerIsLastSchedule.id,
        justifyStop: dataForm.justifyStop,
        stopDateTime: convertDateAndHoursToDB(dataForm.stopDateTime),
      };

      await api.put('manufacturing/workflow/stop', newDataForm);

      addToast({
        type: 'success',
        title: 'Cadastro realizado com sucesso',
      });

      setSavingStopOS(false);
      closeModalStopActionProp();
      closeModalServiceExecutionProp(false);
    } catch (error: any) {
      setSavingStopOS(false);

      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formRefModal.current?.setErrors(errors);

        return;
      }

      if (error.response) {
        const { data } = error.response; // Error vindo do back está em data dentro de response

        addToast({
          type: 'error',
          title: 'Erro na atualização',
          description: data.message,
        });

        return;
      }

      addToast({
        type: 'error',
        title: 'Erro ao atualizar!',
        description: `Error: ${error}`,
      });
    }
  }, [
    addToast,
    closeModalServiceExecutionProp,
    closeModalStopActionProp,
    registerIsLastSchedule.id,
  ]);

  return (
    <Modal visibleProp={loadModalProp}>
      <Container>
        <ModalLoadingForModal>
          <ModalLoading visible={loadingModal}>
            <p>
              <i>Carregando os dados...</i>
            </p>
          </ModalLoading>
        </ModalLoadingForModal>
        <TitleAndOSNumber>
          <Title>
            <span>Ação:</span>
            <span>Parar etapa |</span>
          </Title>
          <OSNumber>
            <span>Nº da O.S.:</span>
            <span>
              {Object.entries(registerIsLastSchedule).length > 0 &&
                registerIsLastSchedule.projectId}
            </span>
          </OSNumber>
        </TitleAndOSNumber>
        <Content>
          <HeaderContainer>
            <HeaderTitle>
              <div>
                <span>
                  Etapa:{' '}
                  <span>
                    {Object.entries(registerIsLastSchedule).length > 0 &&
                      registerIsLastSchedule.manufacturingStep.name}
                  </span>
                </span>
              </div>
              <div>
                <span>
                  Tempo Planejado:{' '}
                  <span>
                    {Object.entries(registerIsLastSchedule).length > 0 &&
                      registerIsLastSchedule.forecastTime}{' '}
                    horas
                  </span>
                </span>
              </div>
            </HeaderTitle>
            <HeaderInformation>
              <div>
                <span>
                  Título:{' '}
                  <span>
                    {Object.entries(registerIsLastSchedule).length > 0 &&
                      registerIsLastSchedule.project.title}
                  </span>
                  &nbsp;&nbsp;-&nbsp;&nbsp;Cliente:{' '}
                  <span>
                    {Object.entries(registerIsLastSchedule).length > 0 &&
                      registerIsLastSchedule.project.client.name}
                  </span>
                </span>
              </div>
            </HeaderInformation>
          </HeaderContainer>
          <JustifyContainer>
            <JustifyContent>
              <Form ref={formRefModal} onSubmit={handleFormSubmit}>
                <TextareaContainer>
                  <TextArea
                    id="justifyStop"
                    name="justifyStop"
                    label="Justificativa: *"
                  />
                </TextareaContainer>
                <DateAndTimeContainer>
                  <DatePicker
                    id="stopDateTime"
                    name="stopDateTime"
                    labelText="Data e hora: *"
                    dateFormatProp="dd/MM/yyyy - HH:mm"
                    showTimeInput
                    timeInputLabel="Hora:"
                    popperPlacement="top-start"
                    shouldCloseOnSelect={false}
                  />
                </DateAndTimeContainer>
              </Form>
            </JustifyContent>
          </JustifyContainer>

          <ActionsContainer>
            <ActionsButtons>
              <Button type="button" onClick={handleFormSubmit}>
                {savingStopOS ? (
                  <Loading size={24} color="white" />
                ) : (
                  'Confirmar'
                )}
              </Button>
              <Button type="button" onClick={closeModalStopActionProp}>
                Voltar
              </Button>
            </ActionsButtons>
          </ActionsContainer>
        </Content>
        <ActionCloseModal>
          <RiCloseLine
            name="closeModal"
            size={36}
            onClick={() => {
              closeModalStopActionProp();
              closeModalServiceExecutionProp(false);
            }}
          >
            Fechar
          </RiCloseLine>
        </ActionCloseModal>
      </Container>
    </Modal>
  );
};

export default ModalStopAction;
