import styled from 'styled-components';

export const Split = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;

  width: 100%;
  padding-bottom: 4.8rem;
  border-bottom: 0.1rem solid var(--cinza-claro-5bits);

  font-style: normal;
  font-size: 16px;
  line-height: 21px;

  color: var(--cinza-escuro-5bits);

  > div {
    width: 100%;
    label {
      margin-left: 1rem;
    }
  }
`;

export const TitleProject = styled.div`
  width: 100%;
`;

export const OtherInformation = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;

  margin-top: 2rem;
  > span {
    padding: 0 1rem;
    border-right: 1px solid var(--cinza-escuro-5bits);
  }

  > span:first-child {
    padding-left: 0;
  }
  > span:last-child {
    padding-right: 0;
    border: none;
  }
`;

export const DescriptionProject = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  margin-top: 0.4rem;

  > span:first-child {
    margin-bottom: 0.4rem;
  }
`;
