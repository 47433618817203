/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import api from '../../../../../services/api';

import getValidationErrors from '../../../../../utils/getValidationError';
import { useToast } from '../../../../../context/ToastContext';

import Loading from '../../../../../components/Loading';
import InputHidden from '../../../../../components/InputHidden';
import Input from '../../../../../components/Input';
import Button from '../../../../../components/Button';

import { Container, Content } from './styles';

import { IManufacturing } from '../../../../../interfaces/manufacturing';

interface IManufacturingForm extends Omit<IManufacturing, 'id'> {
  id: string;
}

const CreateManufacturing: React.FC = () => {
  const { id }: any = useParams();
  const formRef = useRef<FormHandles>(null);
  const navigate = useNavigate();
  const { addToast } = useToast();

  const [saving, setSaving] = useState<boolean>(false);

  const [manufacturing, setManufacturing] = useState<IManufacturing>(
    {} as IManufacturing,
  );

  useEffect(() => {
    const getManufacturing = async (): Promise<void> => {
      try {
        const response: any = await api.get(`manufacturing/steps/${id}`);

        setManufacturing(response.data);

        // Para o campo tipo hidden receber o valor, pelo initialData não recebe valor
        formRef.current?.setData(response.data);
      } catch (error: any) {
        if (error.response) {
          const { data } = error.response; // Error vindo do back está em data dentro de response
          addToast({
            type: 'error',
            title: data.message,
          });
          return;
        }

        addToast({
          type: 'error',
          title: 'Erro ao listar!',
          description: `Error: ${error}`,
        });
      }
    };
    getManufacturing();
  }, [addToast, id]);

  const handleFormSubmit = useCallback(
    async (dataForm: IManufacturingForm, { reset }) => {
      try {
        setSaving(true);

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('O Nome do Material é obrigatório'),
        });

        await schema.validate(dataForm, { abortEarly: false });

        const newData = {
          ...dataForm,
          id: parseInt(dataForm.id, 10),
        };

        await api.put('manufacturing/steps', newData);

        addToast({
          type: 'success',
          title: 'Atualização realizada com sucesso',
        });

        reset();
        setSaving(false);

        navigate('/manufacturing/steps');
      } catch (error: any) {
        setSaving(false);

        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);

          return;
        }

        if (error.response) {
          const { data } = error.response; // Error vindo do back está em data dentro de response

          addToast({
            type: 'error',
            title: 'Erro na atualização',
            description: data.message,
          });
          if (
            formRef.current?.getFieldRef(data.field) &&
            data.field.toString().indexOf('_id') === -1
          ) {
            formRef.current?.getFieldRef(data.field).focus(); // Foca o campo que deu erro de acordo com o field retornado do back
          }

          return;
        }

        addToast({
          type: 'error',
          title: 'Error',
          description: 'Erro ao atualizar!',
        });
      }
    },
    [addToast, navigate],
  );

  return (
    <Container>
      <Content>
        <header>
          <p>Atualização de Etapa</p>
        </header>
        <Form
          ref={formRef}
          initialData={manufacturing}
          onSubmit={handleFormSubmit}
        >
          <div>
            <InputHidden name="id" />
            <Input
              id="name"
              name="name"
              type="text"
              labelText="Nome*"
              maxLength={22}
              disabled={saving}
            />
          </div>

          <Button type="submit" style={{ width: '233px' }} disabled={saving}>
            {saving ? <Loading size={24} color="white" /> : 'Salvar'}
          </Button>
        </Form>
      </Content>
    </Container>
  );
};

export default CreateManufacturing;
