import styled from 'styled-components';

export const SplitManufacturingSteps = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;

  width: 100%;
  padding-top: 3.125rem;
  padding-bottom: 3.125rem;
`;

export const ContentManufacturingStep = styled.div`
  > section {
    display: none;
    padding-bottom: 3rem;
    margin-top: 1rem;
    border-bottom: 0.1rem solid var(--cinza-claro-5bits);
  }
`;

export const HeaderAndGrandTotal = styled.div`
  display: flex;
  justify-content: space-between;

  width: 100%;

  cursor: pointer;
  :hover {
    background: var(--branco-gelo-5bits);
  }

  > span {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;

    font-style: normal;
    font-weight: 500;
    font-size: 2.4rem;
    line-height: 3.2rem;
    text-transform: none;

    color: var(--cinza-escuro-5bits);

    > div {
      display: flex;
      align-items: center;

      gap: 2rem;
    }

    > svg {
      margin-left: 1rem;
    }
  }
`;

export const SplitManufacturingStepItem = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  align-items: flex-end;

  width: 100%;
  margin-bottom: 1.7rem;

  > div {
    flex-grow: 1;
  }

  /* Nome */
  > div {
    width: 60%;
    label {
      margin-left: 1rem;
    }
  }

  /* ForecastTimeAndForecastDays */
  > div:nth-child(3) {
    flex-wrap: nowrap;

    width: 31.2rem;
    label {
      margin-left: 1rem;
    }
  }
  /* ForecastTimeAndForecastDays */
  > div:nth-child(4) {
    width: 35%;
  }

  /* Icon trash ManufacturingStep */
  /* > div:nth-child(4) {
    display: flex;
    align-items: center;
    flex-grow: 0;

    width: fit-content;
    height: 4rem;
    margin-left: 1.4rem;

    label {
      margin-left: 1rem;
    }
  } */
`;

export const ForecastTimeAndForecastDays = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;

  margin: 0 1.4rem 0 1.4rem;
  /* forecastTime */
  > div:nth-child(1) {
    width: 50%;
    margin-right: 1.5rem;
  }

  /* forecastDays */
  > div:nth-child(2) {
    width: 50%;
  }
`;

export const HistoricContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;

  padding: 1rem 0;
`;

export const HistoricTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;

  > span {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    color: var(--azul-5bits);
  }

  > div {
    display: flex;
    align-items: center;
    > span {
      font-style: normal;
      font-weight: 900;
      font-size: 16px;
      line-height: 21px;
      text-align: right;

      color: var(--cinza-escuro-5bits);
    }

    //Asterisco
    > span:nth-child(1) {
      color: var(--laranja-5bits);
    }
  }
`;

export const HistoricTable = styled.div`
  display: block;
  width: 100%;
  max-height: 0;

  overflow: auto;

  transition: max-height 0.2s ease-out;

  max-height: 16.4rem;

  margin-top: 2.2rem;
  margin-bottom: 2.5rem;

  table {
    background: var(--branco-puro-5bits);
    font-size: 1.4rem;

    width: 100%;

    border-spacing: 0;
    border-collapse: collapse;
    border-radius: 4px;

    table,
    th,
    td {
      border: 1px solid var(--cinza-claro-5bits);
    }

    thead th {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      color: var(--cinza-escuro-5bits);
      padding: 10px 8px;
    }

    tbody {
      color: var(--cinza-escuro-5bits);

      /* Linhas Pares */
      tr:nth-child(even) {
        background-color: var(--branco-gelo-5bits);
      }
      /* Linhas Impares */
      tr:nth-child(odd) {
        background-color: var(--branco-puro-5bits);
      }

      tr:hover {
        background-color: #f0f0f0;
      }

      tr {
        td {
          padding: 0.9rem 0.8rem;

          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 21px;
        }
      }
    }

    tr {
      th {
        text-align: left;
        background-color: var(--branco-gelo-5bits);
      }
      //Início
      th:nth-child(1) {
        text-align: right;
        width: 20.1rem;
      }
      td:nth-child(1) {
        text-align: right;
      }
      //Fim
      th:nth-child(2) {
        text-align: right;
        width: 20.1rem;
      }
      td:nth-child(2) {
        text-align: right;
      }
      //Justificativa
      th:nth-child(3) {
        text-align: left;
        width: auto;
      }
      td:nth-child(3) {
        text-transform: none;
      }
    }
  }
`;

export const MoreInfo = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2.1rem;

  color: var(--azul-5bits);

  > span {
    margin-left: 0.5rem;

    font-weight: 900;
    font-size: 1.6rem;
    line-height: 2.1rem;

    color: var(--laranja-5bits);
  }
`;

export const DelayDaysInfo = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2.1rem;

  color: var(--azul-5bits);

  > span {
    margin-left: 0.5rem;
    margin-right: 1rem;

    font-weight: 900;
    font-size: 1.6rem;
    line-height: 2.1rem;

    color: var(--vermelho-5bits);
  }
`;

export const PrevisionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
`;

export const PrevisionTitle = styled.div`
  width: 100%;
  margin-bottom: 2.2rem;

  > span {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    color: var(--azul-5bits);
  }
`;

export const PrevisionDates = styled.div`
  width: 100%;

  div {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    gap: 1rem;

    width: 66%;

    > span {
      font-size: 18px;
      line-height: 21px;
      font-weight: 600;
    }
  }
`;
