import styled from 'styled-components';

const Error = styled.div`
  display: flex;
  align-items: flex-start;

  width: 90%;

  margin: 0.5rem 0 0 1rem;
  align-self: flex-start;
  color: var(--on-errored-message-bg-color);

  svg {
    margin-right: 0.5rem;
  }

  span {
    background: var(--on-errored-message-bg-color);

    &::before {
      border-color: var(--on-errored-message-border-color) transparent;
    }
  }
`;

export default Error;
