/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import fincalc from 'fincalc';
import { isValid, parse } from 'date-fns';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { FiTrash2 } from 'react-icons/fi';
import api from '../../../services/api';
import getValidationErrors from '../../../utils/getValidationError';
import { useToast } from '../../../context/ToastContext';

import Loading from '../../../components/Loading';

import Button from '../../../components/Button';
import SimpleSelect from '../../../components/SimpleSelect';

import currentSectorList from '../../../data/currentSectorList.json';

import HeadProject from '../../Sectors/HeadProject';

import convertDateToBr from '../../../utils/convertDateToBr';
import ModalChecklist from '../../Sectors/componentsSectors/ModalChecklist/modalChecklist';
import convertNumberFloatToDB from '../../../utils/convertNumberFloatToDB';
import ChecklistProject from '../../Sectors/componentsSectors/ChecklistProject';

import {
  IBusinessProposal,
  IBusinessProposalForm,
} from '../../../interfaces/businessProposal';
import { IChecklist } from '../../../interfaces/checklist';
import { IProject, IProjectResponse } from '../../../interfaces/project';
import ModalLoading from '../../../components/ModalLoading';
import convertNumberFloatToBr from '../../../utils/convertNumberFloatToBr';
import convertDateStringToDate from '../../../utils/convertDateStringToDate';
import InputHidden from '../../../components/InputHidden';

import GetProject from '../../AuxiliaryFunctions/GetProject';
import { IPermission } from '../../../interfaces/permission';
import Input from '../../../components/Input';
import DatePicker from '../../../components/DatePicker';
import InputFileButton from '../../../components/InputFileButton';

import {
  Container,
  Content,
  SplitPurchaseOrder,
  ButtonFileAndIconTrash,
  SplitFooter,
} from './styles';
import convertDateToDB from '../../../utils/convertDateToDB';
import { IClient, IClientResponse } from '../../../interfaces/client';
import ModalConfirmationStartProduction from '../components/ModalConfirmationStartProducton';
import { businessProposalStatusValid } from '../../../data/businessProposalStatusValid.enum';

interface PermissionDTO {
  permissions: IPermission;
}

interface IApprovalBusinessProposals {
  id: number;
  dateApproved: string;
  forecastEndDate: string;
  purchaseOrderNumber: string;
  files: any;
  projectHasChecklist: Array<number>;
  currentSector: string;
}

const ApprovalBusinessProposal: React.FC<PermissionDTO> = ({ permissions }) => {
  const { idProject }: any = useParams();
  const { idBusinessProposal }: any = useParams();
  const formRef = useRef<FormHandles>(null);
  const navigate = useNavigate();
  const { addToast } = useToast();

  const [project, setProject] = useState<any>({} as any);
  const [loading, setLoading] = useState<boolean>(false);

  const [saving, setSaving] = useState<boolean>(false);
  const [disabledForUpdate, setDisabledForUpdate] = useState<boolean>(false);

  const [listClients, setListClients] = useState<IClient[]>([]);
  const [listChecklist, setListChecklist] = useState<IChecklist[]>([]);

  const [statusExecuting, setStatusExecuting] = useState<boolean>(false);
  const [loadingChecklist, setLoadingChecklist] = useState<boolean>(false);
  const [
    loadingModalListProvidersIncomplete,
    setLoadingModalListProvidersIncomplete,
  ] = useState<boolean>(false);

  const [showButtonOpenFile, setShowButtonOpenFile] = useState(false);

  useEffect(() => {
    if (Object.entries(project).length > 0) {
      setTimeout(() => {
        formRef.current?.setData({ ...project });
      }, 1000);
      // formRef.current?.setData({ ...project });
    }
  }, [project]);

  // Get proposals with project but incomplete
  const getProject = useCallback(async (): Promise<void> => {
    try {
      const response = await api.get<any>(`proposals/${idBusinessProposal}`);

      let projectFromBusinessProposal = {} as IProjectResponse;
      let newProjectHasMaterials: Array<any> = [];
      let newProjectHasServices: Array<any> = [];

      if (response.data && response.data.project) {
        projectFromBusinessProposal = response.data.project;

        // projectHasMaterials
        if (
          projectFromBusinessProposal.projectHasMaterials &&
          projectFromBusinessProposal.projectHasMaterials.length > 0
        ) {
          newProjectHasMaterials = projectFromBusinessProposal.projectHasMaterials.map(
            (item: any) => {
              const totalPriceCalculated =
                item.totalPrice !== null
                  ? item.totalPrice
                  : fincalc(
                      convertNumberFloatToDB(
                        convertNumberFloatToBr(item.quantity),
                      ) *
                        convertNumberFloatToDB(
                          convertNumberFloatToBr(item.unitPrice),
                        ),
                    );

              return {
                ...item,
                quantity: item.quantity,
                unitPrice: convertNumberFloatToBr(item.unitPrice),
                totalPrice: convertNumberFloatToBr(totalPriceCalculated),
              };
            },
          );
        }

        // projectHasServices
        if (
          projectFromBusinessProposal.projectHasServices &&
          projectFromBusinessProposal.projectHasServices.length > 0
        ) {
          newProjectHasServices = projectFromBusinessProposal.projectHasServices.map(
            item => {
              return {
                ...item,
                unitPrice: convertNumberFloatToBr(item.unitPrice),
                totalPrice: convertNumberFloatToBr(item.totalPrice),
              };
            },
          );
        }
      }

      const newBusinessProposals = {
        ...response.data,
        dateApproved: convertDateStringToDate(response.data.dateApproved),
      };

      delete newBusinessProposals.project;

      const newProject: any = {
        ...projectFromBusinessProposal,
        forecastEndDateForApproval: convertDateStringToDate(
          projectFromBusinessProposal.forecastEndDate,
        ),
        forecastEndDate: convertDateStringToDate(
          projectFromBusinessProposal.forecastEndDate,
        ),

        technicalValidatorDate: convertDateToBr(
          projectFromBusinessProposal.technicalValidatorDate,
        ),
        operationalValidatorDate: convertDateToBr(
          projectFromBusinessProposal.operationalValidatorDate,
        ),
        projectValidatorDate: convertDateToBr(
          projectFromBusinessProposal.projectValidatorDate,
        ),
        projectHasMaterials: [...newProjectHasMaterials],
        projectHasServices: [...newProjectHasServices],
        /*
         * projectHasMaterialsPurchasing é o mesmo que projectHasMaterials porém é usado na aba Compras e
         * não em Planejamento.
         * Tem que ser nomes diferentes por causa do scope das abas Planning e Purchasing. Mas a lista é a
         * mesma newProjectHasMaterials.
         */
        projectHasMaterialsPurchasing: [...newProjectHasMaterials],

        businessProposals: newBusinessProposals,
      };

      const existPurchaseOrderFile =
        newProject.purchaseOrderFileName !== null &&
        newProject.purchaseOrderFileName !== '';

      setShowButtonOpenFile(existPurchaseOrderFile);
      setDisabledForUpdate(existPurchaseOrderFile);

      // Se status executando o select deve ser desabilitado pois só poderá tirar o projeto de produção pelo menu Produção
      newProject.businessProposals.status ===
      businessProposalStatusValid.Executando
        ? setStatusExecuting(true)
        : setStatusExecuting(false);

      setProject(newProject);

      formRef.current?.setData(newProject);

      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      // eslint-disable-next-line no-shadow
      if (error.response) {
        const { data } = error.response; // Error vindo do back está em data dentro de response
        addToast({
          type: 'error',
          title: data.message,
        });
        return;
      }

      addToast({
        type: 'error',
        title: 'Ocorreu um erro interno',
        description: `${error}`,
      });
    }
  }, [addToast, idBusinessProposal]);
  // End Get project

  // List Checklist
  useEffect(() => {
    setLoading(true);
    const getChecklist = async (): Promise<void> => {
      try {
        // Clientes
        const responseClients = await api.get<IClientResponse[]>('clients');

        const responseClientsFormatted: any = responseClients.data.map(i => ({
          value: i.id,
          label: i.name,
        }));

        setListClients(responseClientsFormatted);

        // Checklist
        const responseChecklist = await api.get<any[]>('checklist');

        setListChecklist(responseChecklist.data);
        // End checklist

        // Project
        getProject();
      } catch (error: any) {
        setLoading(false);

        if (error.response && error.response.data) {
          const { data } = error.response; // Error vindo do back está em data dentro de response
          addToast({
            type: 'error',
            title: data.message,
          });
          return;
        }

        addToast({
          type: 'error',
          title: 'Error',
          description: 'Erro ao listar!',
        });
      }
    };
    getChecklist();
  }, [addToast, getProject]);
  // End List Checklist

  const [openModalChecklist, setOpenModalChecklist] = useState(false);
  const handleOpenModalChecklist = useCallback(() => {
    setOpenModalChecklist(!openModalChecklist);
    setSaving(false);
  }, [openModalChecklist]);

  // openModalConfirmationStartProduction
  const [
    openModalConfirmationStartProduction,
    setOpenModalConfirmationStartProduction,
  ] = useState(false);

  const handleOpenModalConfirmationStartProduction = useCallback(
    (confirmation: boolean) => {
      setOpenModalConfirmationStartProduction(false);
      confirmation ? handleOpenModalChecklist() : setSaving(false);
    },
    [handleOpenModalChecklist],
  );

  // openModalConfirmationStartProduction
  const showModalConfirmationStartProduction = useCallback(() => {
    setOpenModalConfirmationStartProduction(true);
  }, []);

  const verifyPermissions = useCallback(async () => {
    try {
      const dataForm = formRef.current?.getData();

      setSaving(true);
      formRef.current?.setErrors({});

      if (dataForm && Object.entries(dataForm).length > 0) {
        if (
          dataForm.files !== undefined ||
          dataForm.purchaseOrderNumber !== ''
        ) {
          const schema = Yup.object().shape({
            businessProposals: Yup.object().shape({
              dateApproved: Yup.date()
                .test('dateApproved', 'Data inválida', (approvedDate: any) => {
                  const validateDate = approvedDate;

                  return isValid(validateDate);
                })
                .nullable()
                .required('Data obrigatória'),
            }),
            forecastEndDateForApproval: Yup.date()
              .test(
                'forecastEndDateForApproval',
                'Data inválida',
                (forecastDate: any) => {
                  const validateDate = forecastDate;

                  return isValid(validateDate);
                },
              )
              .nullable()
              .required('Data obrigatória'),
            purchaseOrderNumber: Yup.string().required(
              'Nº do pedido de compra é obrigatório',
            ),
            files: Yup.mixed().test('files', 'Arquivo obrigatório!', () => {
              /*
               * purchaseOrderFileName é uma string com o nome do arquivo se já tiver arquivo salvo.
               * Se tiver arquivo salvo não poderá fazer a verificação do campo files.
               */
              if (dataForm.purchaseOrderFileName.length === 0) {
                if (dataForm.files) {
                  return true;
                }
                return false;
              }
              return true;
            }),
            currentSector: Yup.string().required(
              'O setor que receberá o projeto é obrigatório',
            ),
          });

          await schema.validate(dataForm, { abortEarly: false });
        } else {
          const schema = Yup.object().shape({
            currentSector: Yup.string().required(
              'O setor que receberá o projeto é obrigatório',
            ),
          });

          await schema.validate(dataForm, { abortEarly: false });
        }

        project.currentSector !== 'Produção' &&
        dataForm.currentSector === 'Produção'
          ? showModalConfirmationStartProduction()
          : handleOpenModalChecklist();
      }
    } catch (error: any) {
      setSaving(false);

      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formRef.current?.setErrors(errors);

        return;
      }

      addToast({
        type: 'error',
        title: 'Error',
        description: `${error}`,
      });
    }
  }, [
    addToast,
    handleOpenModalChecklist,
    project,
    showModalConfirmationStartProduction,
  ]);

  // handleFormSubmit
  const handleFormSubmit = useCallback(
    async (dataForm: any, { reset }) => {
      try {
        setSaving(true);

        /*
         *projectHasChecklist chega como array de objetos [{check1: true},{check14: false}]
         *Abaixo filtra apenas os true, depois no map retira a string 'check' e converte para
         * inteiro o número que é o id do item checklist no banco
         */
        // projectHasChecklist
        const onlyItemsWithTrueChecklist = dataForm.projectHasChecklist.filter(
          // eslint-disable-next-line consistent-return
          (itemProjectHasChecklist: any) => {
            if (Object.values(itemProjectHasChecklist)[0]) {
              const idOfCheckInString = Object.keys(
                itemProjectHasChecklist,
              )[0].replace('check', '');
              return parseInt(idOfCheckInString, 10);
            }
          },
        );

        const itemsConvertedInIntChecklist = onlyItemsWithTrueChecklist.map(
          (itemProjectHasChecklist: any) => {
            const idOfCheckInString = Object.keys(
              itemProjectHasChecklist,
            )[0].replace('check', '');
            return parseInt(idOfCheckInString, 10);
          },
        );

        const itemsChecklistFinal =
          itemsConvertedInIntChecklist.length > 0
            ? [...itemsConvertedInIntChecklist]
            : [];
        // End projectHasChecklist

        const config = {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        };

        const formData = new FormData();

        formData.append('id', `${idBusinessProposal}`);
        formData.append(
          'dateApproved',
          convertDateToDB(dataForm.businessProposals.dateApproved),
        );
        formData.append(
          'forecastEndDate',
          convertDateToDB(dataForm.forecastEndDateForApproval),
        );
        formData.append(
          'purchaseOrderNumber',
          `${dataForm.purchaseOrderNumber}`,
        );
        formData.append('files', dataForm.files);

        if (itemsChecklistFinal.length > 0) {
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < itemsChecklistFinal.length; i++) {
            formData.append(
              'projectHasChecklist[]',
              `${itemsChecklistFinal[i]}`,
            );
          }
        } else {
          formData.append('projectHasChecklist[]', ``);
        }

        formData.append('currentSector', `${dataForm.currentSector}`);

        await api.put('proposals/approval', formData, config);

        addToast({
          type: 'success',
          title: 'Cadastro realizado com sucesso',
        });

        reset();
        setSaving(false);
        handleOpenModalChecklist();
        navigate('/proposals');
      } catch (error: any) {
        setSaving(false);
        handleOpenModalChecklist();

        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);

          return;
        }

        if (error.response) {
          const { data } = error.response; // Error vindo do back está em data dentro de response

          addToast({
            type: 'error',
            title: 'Erro no cadastro',
            description: data.message,
          });

          return;
        }

        addToast({
          type: 'error',
          title: 'Error',
          description: 'Erro ao cadastrar!',
        });
      }
    },
    [addToast, handleOpenModalChecklist, navigate, idBusinessProposal],
  );
  // End handleFormSubmit

  const [loadingFile, setLoadingFile] = useState<boolean>(false);

  const handleOpenFile = useCallback(() => {
    setLoadingFile(true);

    if (
      formRef.current &&
      formRef.current.getFieldValue(`purchaseOrderFileName`)
    ) {
      const purchaseOrderFileName = formRef.current.getFieldValue(
        `purchaseOrderFileName`,
      );

      const extensionFile = purchaseOrderFileName.substring(
        purchaseOrderFileName.lastIndexOf('.') + 1,
      );

      let typeBlob = '';

      switch (extensionFile) {
        case 'pdf':
          typeBlob = `application/${extensionFile}`;
          break;
        case 'tiff':
          typeBlob = `image/${extensionFile}`;
          break;
        default:
          typeBlob = `image/${extensionFile}`;
      }
      api
        .get(`files-purchase-order/${purchaseOrderFileName}`, {
          responseType: 'arraybuffer',
        })
        .then((response: any) => {
          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: typeBlob }),
          );

          window.open(url);
          setLoadingFile(false);
        })
        .catch(error => {
          setLoadingFile(false);

          if (error.response.data) {
            const { data } = error.response; // Error vindo do back está em data dentro de response
            addToast({
              type: 'error',
              title: data.message,
            });
            return;
          }

          addToast({
            type: 'error',
            title: 'Erro ao Exibir!',
            description: `Error: ${error}`,
          });
        });
    }
  }, [addToast]);

  const handleClearErrorPurchaseOrderDeleted = useCallback(() => {
    formRef.current?.setFieldError(`businessProposals.dateApproved`, '');
    formRef.current?.setFieldError(`forecastEndDateForApproval`, '');
    formRef.current?.setFieldError(`purchaseOrderNumber`, '');
  }, []);

  const handleRemovePurchaseOrder = useCallback(() => {
    handleClearErrorPurchaseOrderDeleted();

    if (formRef.current) {
      formRef.current?.clearField(`businessProposals.dateApproved`);
      formRef.current?.clearField(`forecastEndDateForApproval`);
      formRef.current?.clearField(`purchaseOrderNumber`);
      formRef.current?.clearField(`purchaseOrderFileName`);
      formRef.current?.clearField(`files`);

      setShowButtonOpenFile(false);
      setDisabledForUpdate(false);
    }
  }, [handleClearErrorPurchaseOrderDeleted]);

  return (
    <>
      <Container>
        <ModalLoading visible={loading}>
          <p>
            <i>Carregando os dados da Aprovação da Proposta...</i>
          </p>
        </ModalLoading>
        <Content>
          <Form ref={formRef} onSubmit={handleFormSubmit}>
            {/* <Split> */}
            <header>
              <p>Aprovação da Proposta Comercial</p>
              <span>Nº do Projeto: {idProject}</span>
            </header>

            <HeadProject
              listClients={listClients}
              showInformationInMobile
              readOnlyProp
            />

            <SplitPurchaseOrder>
              <DatePicker
                id="businessProposals.dateApproved"
                name="businessProposals.dateApproved"
                labelText="Data da aprovação"
                readOnly={saving || disabledForUpdate}
                disabled={saving || disabledForUpdate}
              />
              <DatePicker
                id="forecastEndDateForApproval"
                name="forecastEndDateForApproval"
                labelText="Previsão de entrega"
                readOnly={saving || disabledForUpdate}
                disabled={saving || disabledForUpdate}
              />
              <Input
                id="purchaseOrderNumber"
                name="purchaseOrderNumber"
                labelText="Número do pedido de compra"
                placeholder=""
                autoComplete="off"
                maxLength={45}
                readOnly={saving || disabledForUpdate}
              />
              <InputHidden name="purchaseOrderFileName" />

              <ButtonFileAndIconTrash>
                {(project && showButtonOpenFile && (
                  <>
                    {(loadingFile && (
                      <>
                        <Button
                          name="buttonSpinner"
                          type="button"
                          onClick={e => {
                            e.preventDefault();
                          }}
                        >
                          <Loading size={24} color="white" />
                        </Button>
                        <div>
                          <Loading size={20} color="white" />
                        </div>
                      </>
                    )) || (
                      <>
                        <Button
                          name="openFile"
                          onClick={() => handleOpenFile()}
                        >
                          Exibir
                        </Button>
                        <div>
                          <FiTrash2
                            size={20}
                            onClick={() => handleRemovePurchaseOrder()}
                          />
                        </div>
                      </>
                    )}
                  </>
                )) || (
                  <>
                    <InputFileButton id="files" name="files" />
                    <div>
                      <FiTrash2
                        size={20}
                        onClick={() => handleRemovePurchaseOrder()}
                      />
                    </div>
                  </>
                )}
              </ButtonFileAndIconTrash>
            </SplitPurchaseOrder>

            <ChecklistProject
              listChecklistProp={listChecklist}
              loadingChecklistProp={loadingChecklist}
              projectHasChecklist={project.projectHasChecklist}
            />

            <SplitFooter>
              <SimpleSelect
                id="currentSector"
                name="currentSector"
                labelText="Enviar projeto para o setor*:"
                placeholder=""
                options={currentSectorList}
                getOptionLabel={option => option.value}
                menuPlacement="auto"
                isDisabled={saving || statusExecuting}
                disabled={saving || statusExecuting}
              />
              <div>
                {!loadingModalListProvidersIncomplete && (
                  <Button
                    type="button"
                    onClick={verifyPermissions}
                    disabled={saving}
                  >
                    {saving ? <Loading size={24} color="white" /> : 'Salvar'}
                  </Button>
                )}
              </div>
            </SplitFooter>

            <InputHidden id="status" name="status" />

            <ModalConfirmationStartProduction
              loadModalProp={openModalConfirmationStartProduction}
              handleOpenModalConfirmationStartProduction={
                handleOpenModalConfirmationStartProduction
              }
            />

            <ModalChecklist
              saving={saving}
              loadModalProp={openModalChecklist}
              listChecklistProp={listChecklist}
              projectHasChecklist={project.projectHasChecklist}
              closeModalChecklist={handleOpenModalChecklist}
            />
          </Form>
        </Content>
      </Container>
    </>
  );
};

export default ApprovalBusinessProposal;
