/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-curly-newline */
import React from 'react';

import Button from '../../../../components/Button';

import Loading from '../../../../components/Loading';

import Modal from '../../../../components/Modal';

import { Container, Content, ActionSaveModal } from './styles';
import TextArea from '../../../../components/TextArea';

interface ModalDTO {
  // formRef: any | FormHandles;
  saving: boolean;
  loadModalProp: boolean;
  closeModalCancelProposals: () => void;
}

const ModalConfirmationFinishedProposals: React.FC<ModalDTO> = ({
  // formRef,
  saving = false,
  loadModalProp = false,
  closeModalCancelProposals,
}) => {
  return (
    <Modal visibleProp={loadModalProp}>
      <Container>
        <h1>Declare o motivo do cancelamento:</h1>
        <Content>
          <TextArea name="whyCanceled" />
          <h1>Deseja cancelar a proposta comercial?</h1>
          <ActionSaveModal>
            <Button type="submit" disabled={saving}>
              {saving ? <Loading size={24} color="white" /> : 'Sim'}
            </Button>
            <Button
              type="button"
              onClick={() => {
                closeModalCancelProposals();
              }}
              disabled={saving}
            >
              {saving ? <Loading size={24} color="white" /> : 'Não'}
            </Button>
          </ActionSaveModal>
        </Content>
      </Container>
    </Modal>
  );
};

export default ModalConfirmationFinishedProposals;
