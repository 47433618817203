import styled from 'styled-components';
import Tooltip from '../../../../components/Tooltip';

export const Container = styled.div`
  margin-top: 1.6rem;
`;

export const Content = styled.div`
  table {
    background: var(--branco-puro-5bits);
    font-size: 1.4rem;

    width: 100%;
    max-width: 1100px;

    border-radius: 4px;

    table,
    th,
    td {
      border: 1px solid var(--cinza-claro-5bits);
      border-spacing: 0;
      border-collapse: collapse;
    }

    thead th {
      padding: 10px 8px;
    }

    tbody {
      color: var(--cinza-escuro-5bits);

      tr:nth-child(odd) {
        background-color: var(--branco-gelo-5bits);
      }

      tr:hover {
        background-color: #f0f0f0;
      }

      tr {
        td {
          padding: 0.9rem 0.8rem;

          font-weight: 500;
          font-size: 1.4rem;
          line-height: 1.9rem;
        }
      }
    }

    tr {
      th {
        text-align: left;
      }
      //Nº
      th:nth-child(1) {
        text-align: right;
        width: auto;
      }
      td:nth-child(1) {
        font-weight: bold;
      }
      //Cliente
      th:nth-child(2) {
        width: auto;
      }
      td:nth-child(2) {
      }
      //Descrição
      th:nth-child(3) {
      }
      td:nth-child(3) {
        width: auto;
        text-transform: lowercase;
      }
      //Quantidade
      th:nth-child(4) {
      }
      td:nth-child(4) {
        width: 6.3rem;
      }
      //Desenho
      th:nth-child(5) {
      }
      td:nth-child(5) {
        width: 30rem;
      }
      //Alterar
      th:nth-child(6) {
      }
      td:nth-child(6) {
        /* width: 250px; */
      }
    }

    button {
      width: 30px;
      height: 30px;
      background: none;
      border: none;
      border-radius: 50%;
      cursor: pointer;

      &:hover {
        filter: brightness(0.5);
      }
    }
  }
`;

export const ContainerInfoDrawing = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: initial;
  align-items: center;
`;

export const InfoDrawing = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    width: 90%;
    span {
      width: 40%;
      min-width: 9rem;
    }

    span:last-child {
      width: 50%;
      margin: 0 2rem;
    }
  }

  button {
    width: fit-content;
    color: var(--azul-5bits);
    text-transform: capitalize;
    padding: 0rem 1rem;
    font-size: 1.28rem;
    font-weight: 500;
  }
`;
