import React from 'react';

import Toast from './Toast';
/*
ToastMessage é a interface com os tipos.
useToast é o hook que dá acesso as funções do ToastContext
como a de removeToast
*/
import { ToastMessage } from '../../context/ToastContext';

import { Container } from './styles';

/*
Aqui recebe a interface com a tipagem vinda de
ToastContext
*/
interface ToastContainerProps {
  messages: ToastMessage[];
}

const ToastContainer: React.FC<ToastContainerProps> = ({ messages }) => {
  return (
    <Container>
      {messages.map(message => (
        <Toast key={message.id} message={message} />
      ))}
    </Container>
  );
};
export default ToastContainer;
