import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { FiEdit } from 'react-icons/fi';

import { IClient, IClientResponse } from '../../../interfaces/client';

import { useToast } from '../../../context/ToastContext';

import { Container, Content } from './styles';
import api from '../../../services/api';
import ListingTemplate from '../../../components/Template/Listing';

import { IPermission } from '../../../interfaces/permission';
import convertDateStringToDate from '../../../utils/convertDateStringToDate';
import convertInitToUppercase from '../../../utils/convertInitToUppercase';

interface PermissionDTO {
  permissions: IPermission;
}

const ListClients: React.FC<PermissionDTO> = ({ permissions }) => {
  const { addToast } = useToast();
  const [loading, setLoading] = useState<boolean>(false);
  const [listClients, setListClients] = useState<IClient[]>([]);
  const [listClientsPaginated, setListClientsPaginated] = useState<IClient[]>(
    [],
  );
  useEffect(() => {
    setLoading(true);

    api
      .get<IClientResponse[]>('clients')
      .then(response => {
        const responseFormatted = response.data.map(i => ({
          ...i,
          createdAt: convertDateStringToDate(i.createdAt),
          updatedAt: convertDateStringToDate(i.updatedAt),
        }));

        setListClients(responseFormatted);
        setListClientsPaginated(responseFormatted);

        setLoading(false);
      })
      .catch(error => {
        setLoading(false);

        if (error.response && error.response.data) {
          const { data } = error.response; // Error vindo do back está em data dentro de response
          addToast({
            type: 'error',
            title: data.message,
          });
          return;
        }

        addToast({
          type: 'error',
          title: 'Error',
          description: 'Erro ao listar!',
        });
      });
  }, [addToast]);

  const handleListClientsPaginatedItems = useCallback(newList => {
    setListClientsPaginated(newList);
  }, []);

  return (
    <ListingTemplate
      title="Listagem de Clientes"
      module="clients"
      listItemsProps={listClients}
      handleListItemsPaginatedItems={handleListClientsPaginatedItems}
      placeHolderProp="Cód. ou Nome"
      searchTheLocalObject
      disabled={loading}
      permissions={permissions}
    >
      <Container>
        <Content>
          {loading ? (
            <p>carregando ...</p>
          ) : (
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Nome</th>
                  <th>Contato</th>
                  <th>Endereço</th>
                  <th>Telefone</th>
                  <th>E-mail</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {listClientsPaginated.map(c => (
                  <tr key={String(c.id)}>
                    <td>{c.id}</td>
                    <td>{convertInitToUppercase(c.name)}</td>
                    <td>{convertInitToUppercase(c.contact)}</td>
                    <td>{convertInitToUppercase(c.address)}</td>
                    <td>{c.phone}</td>
                    <td>{c.email}</td>
                    <td>
                      {(permissions.update && (
                        <Link to={`/clients/update/${c.id}`}>
                          <FiEdit size={16} color="var(--azul-5bits)" />
                        </Link>
                      )) || (
                        <span>
                          <FiEdit size={16} color="var(--cinza-escuro-5bits)" />
                        </span>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </Content>
      </Container>
    </ListingTemplate>
  );
};

export default ListClients;
