import styled, { keyframes } from 'styled-components';

const slide = keyframes`
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
    width: 100%;
  }
`;

export const Container = styled.div`
  margin: 0;
  margin-left: 1.1rem;

  a {
    display: block;
    padding: 0.8rem 1.3rem;

    > span:last-child {
      margin-top: 1.1rem;
      border: 2px solid var(--laranja-5bits);
      opacity: 0;
    }

    .hover-mark {
      position: absolute;
      top: 22px;
      right: 0;
      width: 0;
      height: 1px;
    }

    &:hover {
      .hover-mark {
        animation: ${slide} 0.3s forwards;
      }
    }

    &.active {
      span.hover-mark {
        position: absolute;
        top: 22px;
        right: 0;
        width: 100%;
        height: 2px;
        animation: none;
      }
    }
  }

  //Medias
  @media (min-width: 0px) and (max-width: 759px) {
    width: 100%;
    margin: 0;

    a {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 0.8rem 1.6rem;
    }
  }

  @media (min-width: 760px) and (max-width: 999px) {
  }
`;
