/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useCallback } from 'react';

import IconCheckmark from '../../../../assets/Checkmark.svg';
import IconClose from '../../../../assets/Close.svg';
import IconExpandMoreHorizontal from '../../../../assets/ExpandMoreHorizontal.svg';

import Loading from '../../../../components/Loading';

import {
  ContainerChecklistAbsolute,
  ContainerChecklist,
  TextVertical,
  SplitRightProcessChecklist,
  ProcessChecklistItems,
  CheckItemsArea,
  TextChecked,
  TextUnChecked,
} from './styles';

import { IChecklist } from '../../../../interfaces/checklist';

interface IProjectHasChecklist {
  projectId: number;
  checklistId: number;
  userId: number;
  isConcluded: boolean;
  dateConcluded: string;
  createdAt: string;
  updatedAt: string;
}

interface ChecklistProjectProps {
  listChecklistProp: Array<IChecklist>;
  loadingChecklistProp: boolean;
  projectHasChecklist: Array<IProjectHasChecklist>;
}

const ChecklistProject: React.FC<ChecklistProjectProps> = ({
  listChecklistProp,
  loadingChecklistProp = false,
  projectHasChecklist,
}) => {
  const [show, setShow] = useState(false);

  const toggleShowChecklist = useCallback(() => {
    setShow(!show);
  }, [show]);

  return (
    <ContainerChecklistAbsolute
      showChecklist={show}
      onClick={toggleShowChecklist}
    >
      <ContainerChecklist>
        <TextVertical showChecklist={show}>
          <span>Checklist de Processos</span>
          <img src={IconExpandMoreHorizontal} alt="ExpandMore" />
        </TextVertical>
        {loadingChecklistProp ? (
          <p style={{ height: '4.5rem', textAlign: 'center' }}>
            carregando ...
          </p>
        ) : (
          <SplitRightProcessChecklist>
            <ProcessChecklistItems showChecklist={show}>
              <header>
                Checklist de Processos
                <img src={IconExpandMoreHorizontal} alt="ExpandMore" />
              </header>
              <CheckItemsArea>
                {listChecklistProp &&
                  listChecklistProp.length > 0 &&
                  listChecklistProp.map((item, index) => (
                    <div key={String(index)}>
                      {(projectHasChecklist &&
                        projectHasChecklist.length > 0 &&
                        projectHasChecklist.find(
                          projectCheckItem =>
                            projectCheckItem.checklistId === item.id &&
                            projectCheckItem.isConcluded === true,
                        ) !== undefined && (
                          <>
                            <img src={IconCheckmark} alt="Checkmark" />
                            <TextChecked>{item.name}</TextChecked>
                          </>
                        )) || (
                        <>
                          <img src={IconClose} alt="Close" />
                          <TextUnChecked>{item.name}</TextUnChecked>
                        </>
                      )}
                    </div>
                  ))}
              </CheckItemsArea>
            </ProcessChecklistItems>
          </SplitRightProcessChecklist>
        )}
      </ContainerChecklist>
    </ContainerChecklistAbsolute>
  );
};

export default ChecklistProject;
