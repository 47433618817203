import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  padding: 2rem;
  background-color: var(--branco-puro-5bits);
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.div`
  margin-bottom: 3.3rem;

  > span {
    font-size: 2.4rem;
    font-weight: normal;
    line-height: 3.1rem;

    color: var(--azul-5bits);
  }
`;

export const ActionsButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;

  > button {
    width: 17.2rem;
    height: 3.7rem;
  }

  > button:first-child {
    margin-right: 3rem;
  }
`;
