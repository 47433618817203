/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import React, { useState, useCallback, memo } from 'react';
import { FormHandles } from '@unform/core';

import HandleMaterials from './components/HandleMaterials';
import HandleTotalization from './components/HandleTotalization';

import { IMaterialResponse } from '../../../../interfaces/material';
import { IProviderResponse } from '../../../../interfaces/provider';

import { Container } from './styles';

interface BodyPurchasingProp {
  formRef: any | FormHandles;
  listMaterialsProp: Array<IMaterialResponse>;
  listProvidersProp: Array<IProviderResponse>;
  projectProp: any;
  disableTabProp: boolean;
  refMaterialPurchasing?: any;
}

const BodyPurchasing: React.FC<BodyPurchasingProp> = ({
  formRef,
  listMaterialsProp,
  listProvidersProp,
  projectProp,
  disableTabProp = false,
  refMaterialPurchasing,
}) => {
  const [listTotalization, setListTotalization] = useState<any[]>([]);

  const addNewListTotalization = useCallback((newList: any) => {
    setListTotalization(newList);
  }, []);

  return (
    <Container>
      <HandleMaterials
        formRef={formRef}
        listMaterials={listMaterialsProp}
        listProviders={listProvidersProp}
        projectProp={projectProp}
        handleNewListTotalization={addNewListTotalization}
        disableTabProp={disableTabProp}
        ref={refMaterialPurchasing}
      />

      <HandleTotalization
        formRef={formRef}
        listUpdatedByMaterialPurchasing={listTotalization}
        projectHasTotalizationProp={projectProp.projectHasTotalization}
        disableTabProp={disableTabProp}
      />
    </Container>
  );
};

export default BodyPurchasing;
