import styled, { css } from 'styled-components';

interface IRadioProps {
  fontSize: string;
  lineHeight: string;
  sizeInput: string;
}

export const Container = styled.div<IRadioProps>`
  ${props =>
    css`
      font-size: ${props.fontSize};
      line-height: ${props.lineHeight};
    `};

  > label {
    > input {
      ${props =>
        css`
          //Tamanho do input radio button é por width e height
          width: ${props.sizeInput};
          height: ${props.sizeInput};
        `};
    }
  }
`;
