/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { FiEdit } from 'react-icons/fi';

import { IMaterialResponse } from '../../../interfaces/material';

import { useToast } from '../../../context/ToastContext';

import { Container, Content } from './styles';
import api from '../../../services/api';
import convertNumberFloatToBr from '../../../utils/convertNumberFloatToBr';

import ListingTemplate from '../../../components/Template/Listing';
import Tooltip from './component/TooltipWithCoordinates';

import { IPermission } from '../../../interfaces/permission';
import convertDateStringToDate from '../../../utils/convertDateStringToDate';

interface Providers {
  id: number;
  fantasyName: string;
  mainPhone: string;
}

interface PermissionDTO {
  permissions: IPermission;
}

const ListMaterials: React.FC<PermissionDTO> = ({ permissions }) => {
  const { addToast } = useToast();
  const [loading, setLoading] = useState<boolean>(false);
  const [listMaterials, setListMaterials] = useState<IMaterialResponse[]>([]);
  const [listMaterialsPaginated, setListMaterialsPaginated] = useState<
    IMaterialResponse[]
  >([]);

  useEffect(() => {
    setLoading(true);

    api
      .get<IMaterialResponse[]>('materials')
      .then(response => {
        const responseFormatted: any = response.data.map(i => ({
          ...i,
          createdAt: convertDateStringToDate(i.createdAt),
          updatedAt: convertDateStringToDate(i.updatedAt),
        }));

        setListMaterials(responseFormatted);
        setListMaterialsPaginated(responseFormatted);

        setLoading(false);
      })
      .catch(error => {
        setLoading(false);

        if (error.response && error.response.data) {
          const { data } = error.response; // Error vindo do back está em data dentro de response
          addToast({
            type: 'error',
            title: data.message,
          });
          return;
        }

        addToast({
          type: 'error',
          title: 'Error',
          description: 'Erro ao listar!',
        });
      });
  }, [addToast]);

  const handleListMaterialsPaginatedItems = useCallback(newList => {
    setListMaterialsPaginated(newList);
  }, []);

  const handleTextForTooltip = useCallback((providers: Array<Providers>) => {
    const s: Array<{ name: string; phone: string }> = [];
    providers.map((item: Providers) => {
      return s.push({
        name: `${item.fantasyName}`,
        phone: `${item.mainPhone}`,
      });
    });

    return s;
  }, []);

  const [viewPortHeight, setViewPortHeight] = useState<{
    width: number;
    height: number;
  }>({} as { width: number; height: number });
  // Pega mudança na view port
  if (window !== null && window.visualViewport !== null) {
    const { visualViewport } = window;
    window.visualViewport.onresize = () => {
      setViewPortHeight({
        width: visualViewport.width,
        height: visualViewport.height,
      });
    };
  }

  return (
    <ListingTemplate
      title="Listagem de Materiais"
      module="materials"
      listItemsProps={listMaterials}
      handleListItemsPaginatedItems={handleListMaterialsPaginatedItems}
      placeHolderProp="Cód. ou Nome"
      searchTheLocalObject
      disabled={loading}
      permissions={permissions}
    >
      <Container>
        <Content>
          {loading ? (
            <p>carregando ...</p>
          ) : (
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Nome</th>
                  <th>Preço</th>
                  <th>Unidade</th>
                  <th>Peso Kg</th>
                  <th>Observação</th>
                  <th>Ativo</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {listMaterialsPaginated.map(m => (
                  <tr key={String(m.id)}>
                    <td>{m.id}</td>
                    <td>
                      {(m.providers.length > 0 && (
                        <Tooltip
                          id={`toolName${m.id}`}
                          viewPortHeightProp={viewPortHeight}
                          style={{
                            textTransform: 'capitalize',
                          }}
                          colorBefore="var(--azul-5bits)"
                          colorSpan="var(--azul-5bits)"
                          title={handleTextForTooltip(m.providers)}
                        >
                          {m.name}
                        </Tooltip>
                      )) ||
                        m.name}
                    </td>
                    <td>{convertNumberFloatToBr(m.price)}</td>
                    <td>{m.unit.name}</td>
                    <td>{m.weight}</td>
                    <td>{m.observation}</td>
                    <td>{m.isActive ? 'Sim' : 'Não'}</td>
                    <td>
                      {(permissions.update && (
                        <Link to={`/materials/update/${m.id}`}>
                          <FiEdit size={16} color="var(--azul-5bits)" />
                        </Link>
                      )) || (
                        <span>
                          <FiEdit size={16} color="var(--cinza-escuro-5bits)" />
                        </span>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </Content>
      </Container>
    </ListingTemplate>
  );
};

export default ListMaterials;
