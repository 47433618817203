/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-curly-newline */
import React, { useRef, useCallback, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { RiCloseLine } from 'react-icons/ri';

import api from '../../../../services/api';
import { useToast } from '../../../../context/ToastContext';

import Button from '../../../../components/Button';

import Loading from '../../../../components/Loading';

import Modal from '../../../../components/Modal';

import {
  Container,
  Content,
  ActionSaveModal,
  ActionCloseModal,
} from './styles';
import ButtonLink from '../../../../components/ButtonLink';

interface ModalDTO {
  idProjectProp: number;
  idProposalProp: number;
  loadModalProp: boolean;
  closeModalConfirmationSendEmail: () => void;
  closeModalConfirmationSendEmailWithOutUpdateScreenListProposalsProp: () => void;
}

const ModalConfirmationSendEmail: React.FC<ModalDTO> = ({
  idProjectProp,
  idProposalProp,
  loadModalProp = false,
  closeModalConfirmationSendEmail,
  closeModalConfirmationSendEmailWithOutUpdateScreenListProposalsProp,
}) => {
  const { addToast } = useToast();
  const formRefModal = useRef<FormHandles>(null);
  const navigate = useNavigate();
  const [saving, setSaving] = useState<boolean>(false);

  const [idProject, setIdProject] = useState<number>();
  const [idProposal, setIdProposal] = useState<number>();

  useEffect(() => {
    setIdProject(idProjectProp);
    setIdProposal(idProposalProp);
  }, [idProjectProp, idProposalProp]);

  // handleFormSubmit
  const handleFormSubmit = useCallback(async () => {
    try {
      setSaving(true);

      const newDataForm: any = {
        id: idProposal,
        alreadySent: true,
      };

      await api.put('proposals/send-email', newDataForm);

      addToast({
        type: 'success',
        title: 'Cadastro realizado com sucesso',
      });

      setSaving(false);
      closeModalConfirmationSendEmail();
      navigate('/proposals');
    } catch (error: any) {
      setSaving(false);
      closeModalConfirmationSendEmail();

      if (error.response) {
        const { data } = error.response; // Error vindo do back está em data dentro de response

        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: data.message,
        });
        if (
          formRefModal.current?.getFieldRef(data.field) &&
          data.field.toString().indexOf('_id') === -1
        ) {
          formRefModal.current?.getFieldRef(data.field).focus(); // Foca o campo que deu erro de acordo com o field retornado do back
        }

        return;
      }

      addToast({
        type: 'error',
        title: 'Error',
        description: 'Erro ao cadastrar!',
      });
    }
  }, [addToast, closeModalConfirmationSendEmail, navigate, idProposal]);
  // End handleFormSubmit

  return (
    <Modal visibleProp={loadModalProp}>
      <Container>
        <h1>Esta proposta já foi enviada?</h1>
        <Form ref={formRefModal} onSubmit={handleFormSubmit}>
          <Content>
            <ActionSaveModal>
              <Button type="submit" disabled={saving}>
                {saving ? <Loading size={24} color="white" /> : 'Sim'}
              </Button>
              {/*
              DESABILITADA A TELA DE ENVIO DE EMAIL DEVIDO AO CANCELAMENTO DO SERVIÇO DE EMAIL SMTP
              <ButtonLink
                to={`/proposals/create/send-email/${idProject}/${idProposal}`}
                disabled={saving}
              >
                {saving ? <Loading size={24} color="white" /> : 'Não'}
              </ButtonLink> */}
              <Button
                type="button"
                onClick={
                  closeModalConfirmationSendEmailWithOutUpdateScreenListProposalsProp
                }
                disabled={saving}
              >
                {saving ? <Loading size={24} color="white" /> : 'Não'}
              </Button>
            </ActionSaveModal>
          </Content>
        </Form>

        <ActionCloseModal>
          <RiCloseLine
            name="closeModal"
            size={36}
            onClick={() => {
              closeModalConfirmationSendEmailWithOutUpdateScreenListProposalsProp();
            }}
          >
            Fechar
          </RiCloseLine>
        </ActionCloseModal>
      </Container>
    </Modal>
  );
};

export default ModalConfirmationSendEmail;
