/* eslint-disable no-param-reassign */
import axios from 'axios';
/**
 * [x] criar um arquivo externo com as configurações
 * [ ] nome da aplicação para o token
 */
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'http://localhost:3333',
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use(async (config: any) => {
  const token = localStorage.getItem(
    `@${process.env.REACT_APP_COMPANY_NAME}:token`,
  );
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

/*
 * add delay for all requests, only for development purposes
 */
if (process.env.NODE_ENV !== 'production') {
  api.interceptors.request.use(
    config => new Promise(resolve => setTimeout(() => resolve(config), 500)),
  );
}

export default api;
