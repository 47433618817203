/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState, useCallback } from 'react';

import { useToast } from '../../../context/ToastContext';

import api from '../../../services/api';

import ContainerOSOnStage from './components/ContainerOSOnStage';

import {
  Container,
  Content,
  ContainerProductionStages,
  ContainerTitleStages,
  TitleStage,
  CardsCompany,
  ContainerCompanyStage,
} from './styles';

const etapas = [
  {
    id: 1,
    name: 'Comercial',
    empresasFake: [
      {
        id: 1,
        name: 'Empresa 1',
        etapa: {
          id: 1,
          stageName: 'Comercial',
          numberOS: 123456,
          deliveryDate: '2021-06-23',
          descriptions: [
            {
              description:
                'Serviço de recuperação de rotor DES 0461-SR-0150-0005-01',
            },
            {
              description: 'Serviço de recuperação em rotor de dosador 4 peças',
            },
            {
              description: 'Serviço de recuperação em dosador 78 peças',
            },
          ],
        },
      },
      {
        id: 2,
        name: 'Empresa 2',
        etapa: {
          id: 4,
          stageName: 'Produção',
          numberOS: 888,
          deliveryDate: '2021-06-24',
          descriptions: [
            {
              description: 'Descrição 1 Usinagem',
            },
            {
              description: 'Descrição 2 Usinagem',
            },
          ],
        },
      },
      {
        id: 3,
        name: 'Empresa 3',
        etapa: {
          id: 3,
          stageName: 'Compras',
          numberOS: 888,
          deliveryDate: '2021-06-24',
          descriptions: [
            {
              description: 'Descrição 1 Montagem',
            },
            {
              description: 'Descrição 2 Montagem',
            },
          ],
        },
      },
      {
        id: 4,
        name: 'Empresa 4',
        etapa: {
          id: 2,
          stageName: 'Desenvolvimento',
          numberOS: 888,
          deliveryDate: '2021-06-24',
          descriptions: [
            {
              description: 'Descrição 1 Montagem',
            },
            {
              description: 'Descrição 2 Montagem',
            },
          ],
        },
      },
    ],
  },
  {
    id: 2,
    name: 'Desenvolvimento',
    empresasFake: [
      {
        id: 2,
        name: 'Empresa 2',
        etapa: {
          id: 4,
          stageName: 'Produção',
          numberOS: 888,
          deliveryDate: '2021-06-24',
          descriptions: [
            {
              description: 'Descrição 1 Usinagem',
            },
            {
              description: 'Descrição 2 Usinagem',
            },
          ],
        },
      },
      {
        id: 3,
        name: 'Empresa 3',
        etapa: {
          id: 3,
          stageName: 'Compras',
          numberOS: 888,
          deliveryDate: '2021-06-24',
          descriptions: [
            {
              description: 'Descrição 1 Montagem',
            },
            {
              description: 'Descrição 2 Montagem',
            },
          ],
        },
      },
    ],
  },
  { id: 3, name: 'Compras', empresasFake: [] },
  {
    id: 4,
    name: 'Produção ',
    empresasFake: [
      {
        id: 2,
        name: 'Empresa 2',
        etapa: {
          id: 4,
          stageName: 'Produção',
          numberOS: 888,
          deliveryDate: '2021-06-24',
          descriptions: [
            {
              description: 'Descrição 1 Usinagem',
            },
            {
              description: 'Descrição 2 Usinagem',
            },
          ],
        },
      },
      {
        id: 3,
        name: 'Empresa 3',
        etapa: {
          id: 3,
          stageName: 'Compras',
          numberOS: 888,
          deliveryDate: '2021-06-24',
          descriptions: [
            {
              description: 'Descrição 1 Montagem',
            },
            {
              description: 'Descrição 2 Montagem',
            },
          ],
        },
      },
    ],
  },
];

const ListProjectsOverview: React.FC = () => {
  const { addToast } = useToast();
  const [loading, setLoading] = useState<boolean>(false);
  const [listStagesOrdered, setListStagesOrdered] = useState<any>([]);
  const [listCompaniesOrdered, setListCompaniesOrdered] = useState<any>([]);
  const [idCompanyClicked, setIdCompanyClicked] = useState(0);

  useEffect(() => {
    setLoading(true);

    const stagesFormatted: any = [...etapas];

    setListStagesOrdered(stagesFormatted);

    setLoading(false);
  }, [addToast]);

  const handleCloseDescriptionOfCompany = useCallback(
    (id: number) => {
      if (idCompanyClicked === 0) {
        setIdCompanyClicked(id);
      }
      setIdCompanyClicked(id);
    },
    [idCompanyClicked],
  );

  return (
    <Container>
      <Content>
        <h1>Visão Geral dos Projetos</h1>
        <ContainerProductionStages>
          {listStagesOrdered && (
            <ContainerTitleStages>
              <>
                {listStagesOrdered.map((itemEtapa: any, index: number) => (
                  <>
                    <CardsCompany>
                      <TitleStage key={String(index)}>
                        <span>{itemEtapa.name}</span>
                      </TitleStage>

                      {itemEtapa.empresasFake.map(
                        (itemEmpresa: any, subIndex: number) => (
                          <ContainerCompanyStage
                            key={String(index)}
                            colorHRBlue={index % 2 === 0}
                          >
                            <ContainerOSOnStage
                              key={String(subIndex)}
                              idCompanyProp={itemEmpresa.id}
                              idCompanyClicked={idCompanyClicked}
                              companyName={itemEmpresa.name}
                              itemOS={itemEmpresa.etapa}
                              colorBorderBlue={index % 2 === 0}
                              onClick={() => {
                                handleCloseDescriptionOfCompany(itemEmpresa.id);
                              }}
                            />
                          </ContainerCompanyStage>
                        ),
                      )}
                    </CardsCompany>
                  </>
                ))}
              </>
            </ContainerTitleStages>
          )}
        </ContainerProductionStages>
      </Content>
    </Container>
  );
};

export default ListProjectsOverview;
