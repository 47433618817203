/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-curly-newline */
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import typy from 'typy';
import Button from '../../../../../components/Button';
import Loading from '../../../../../components/Loading';
import Modal from '../../../../../components/Modal';
import { useToast } from '../../../../../context/ToastContext';
import api from '../../../../../services/api';
import { Container, Content, ActionSaveModal } from './styles';

interface ModalDTO {
  idProject: number | undefined;
  loadModalProp: boolean;
  closeModalConfirmationArchivedProject: () => void;
}

interface IArchived {
  id: number | undefined;
  isArchived: boolean;
}

const ModalConfirmationArchivedProject: React.FC<ModalDTO> = ({
  idProject,
  loadModalProp = false,
  closeModalConfirmationArchivedProject,
}) => {
  const { addToast } = useToast();
  const navigate = useNavigate();
  const [saving, setSaving] = useState(false);

  // handleFormSubmit
  const handleFormSubmit = useCallback(async () => {
    try {
      setSaving(true);

      if (typy(idProject).isNumber) {
        const newData: IArchived = {
          id: idProject,
          isArchived: true,
        };

        await api.put('projectsManager/archived', newData);

        addToast({
          type: 'success',
          title: 'Projeto arquivado com sucesso',
        });
      } else {
        addToast({
          type: 'error',
          title: 'O id do Projeto é obrigatório',
        });
      }

      setSaving(false);
      closeModalConfirmationArchivedProject();
      navigate(0);
    } catch (error: any) {
      setSaving(false);
      closeModalConfirmationArchivedProject();

      if (error.response) {
        const { data } = error.response; // Error vindo do back está em data dentro de response

        addToast({
          type: 'error',
          title: 'Erro no arquivamento do projeto.',
          description: data.message,
        });

        return;
      }

      addToast({
        type: 'error',
        title: 'Error',
        description: 'Erro ao arquivar o projeto!',
      });
    }
  }, [addToast, closeModalConfirmationArchivedProject, idProject, navigate]);
  // End handleFormSubmit

  return (
    <Modal visibleProp={loadModalProp}>
      <Container>
        <h1>Arquivar Projeto</h1>
        <Content>
          <h1>Deseja arquivar o projeto {idProject}?</h1>
          <ActionSaveModal>
            <Button type="button" onClick={handleFormSubmit} disabled={saving}>
              {saving ? <Loading size={24} color="white" /> : 'Sim'}
            </Button>
            <Button
              type="button"
              onClick={() => {
                closeModalConfirmationArchivedProject();
              }}
              disabled={saving}
            >
              {saving ? <Loading size={24} color="white" /> : 'Não'}
            </Button>
          </ActionSaveModal>
        </Content>
      </Container>
    </Modal>
  );
};

export default ModalConfirmationArchivedProject;
