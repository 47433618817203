import styled from 'styled-components';

export const Container = styled.div``;

export const Content = styled.div`
  table {
    background: var(--branco-puro-5bits);
    font-size: 1.4rem;

    width: 100%;
    max-width: 1100px;

    border-radius: 4px;

    table,
    th,
    td {
      border: 1px solid var(--cinza-claro-5bits);
      border-spacing: 0;
      border-collapse: collapse;
    }

    thead th {
      padding: 10px 8px;
    }

    tbody {
      color: var(--cinza-escuro-5bits);

      tr:nth-child(odd) {
        background-color: var(--branco-gelo-5bits);
      }

      tr:hover {
        background-color: #f0f0f0;
      }

      tr {
        td {
          padding: 9px 8px;
        }
      }
    }

    tr {
      th {
        text-align: left;
      }
      //Nome
      th:nth-child(1) {
        text-align: left;
        width: auto;
      }
      td:nth-child(1) {
        text-align: left;
      }
      //Número
      th:nth-child(2) {
      }
      td:nth-child(2) {
        /* width: 250px; */
      }
      //Revisão
      th:nth-child(3) {
      }
      td:nth-child(3) {
        width: 8rem;
      }
      //Data
      th:nth-child(4) {
      }
      td:nth-child(4) {
        width: 10rem;
      }
      //Arquivo
      th:nth-child(5) {
        text-align: center;
        width: 12rem;
      }
      td:nth-child(5) {
        button {
          width: 100%;
          height: 100%;
          background: var(--azul-5bits);
          border: none;
          border-radius: 25px;
          cursor: pointer;

          text-transform: capitalize;
          padding: 0rem 1rem;
          font-size: 1.28rem;
          font-weight: 500;

          &:hover {
            filter: brightness(0.5);
          }
        }
      }
      /* //Ações
      th:nth-child(6) {
        width: 50px;
      }
      td:nth-child(6) {
        //text-align: center;
      } */
    }

    button {
      width: 30px;
      height: 30px;
      background: none;
      border: none;
      border-radius: 50%;
      cursor: pointer;

      &:hover {
        filter: brightness(0.5);
      }
    }
  }
`;
