/* eslint-disable react/jsx-curly-newline */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect, useCallback } from 'react';
import { FormHandles, Scope } from '@unform/core';

import api from '../../../../services/api';

import { useToast } from '../../../../context/ToastContext';

import Loading from '../../../../components/Loading';
import Input from '../../../../components/Input';
import InputMask from '../../../../components/InputMask';
import TextArea from '../../../../components/TextArea';
import Button from '../../../../components/Button';

import InputHidden from '../../../../components/InputHidden';

import {
  SplitTechnicalDrawings,
  SplitDrawing,
  RevisionNumberAndRevisionDateAndFile,
  SplitFooter,
} from './styles';

import { IDrawingsResponse } from '../../../../interfaces/drawings';
import { ICommercial } from '../../../../interfaces/commercial';

interface IDrawingsResponseForm extends Omit<IDrawingsResponse, 'id'> {
  idDrawing: string;
  files: any;
}

interface ICommercialForm extends Omit<ICommercial, 'drawings'> {
  idDrawing: string;
  revisionDate: string;
  scopeDrawings: Array<IDrawingsResponseForm>;
}

interface IBodyCommercialProps {
  formRef: any | FormHandles;
  listDrawingsTheProjectProp: Array<any>;
}

const BodyCreateCommercial: React.FC<IBodyCommercialProps> = ({
  formRef,
  listDrawingsTheProjectProp,
}) => {
  const { addToast } = useToast();

  const [listDrawingsTheProject, setListDrawingsTheProject] = useState<
    ICommercialForm[]
  >([{} as ICommercialForm]);

  const [loadingFile, setLoadingFile] = useState<boolean>(false);
  const [buttonClicked, setButtonClicked] = useState<string>('');

  useEffect(() => {
    if (listDrawingsTheProjectProp && listDrawingsTheProjectProp.length > 0) {
      setListDrawingsTheProject([...listDrawingsTheProjectProp]);
    }
  }, [listDrawingsTheProjectProp]);

  const handleOpenFile = useCallback(
    (index: number, nameButtonClicked: string) => {
      setLoadingFile(true);
      setButtonClicked(nameButtonClicked);
      if (
        formRef.current &&
        formRef.current.getFieldValue(`scopeDrawings[${index}].fileName`)
      ) {
        const fileName = formRef.current.getFieldValue(
          `scopeDrawings[${index}].fileName`,
        );

        const extensionFile = fileName.substring(fileName.lastIndexOf('.') + 1);

        let typeBlob = '';

        switch (extensionFile) {
          case 'pdf':
            typeBlob = `application/${extensionFile}`;
            break;
          case 'tiff':
            typeBlob = `image/${extensionFile}`;
            break;
          default:
            typeBlob = `image/${extensionFile}`;
        }
        api
          .get(`drawings/file/${fileName}`, {
            responseType: 'arraybuffer',
          })
          .then((response: any) => {
            const url = window.URL.createObjectURL(
              new Blob([response.data], { type: typeBlob }),
            );

            window.open(url);
            setLoadingFile(false);
            setButtonClicked('');
          })
          .catch(error => {
            setLoadingFile(false);
            setButtonClicked('');

            if (error.response.data) {
              const { data } = error.response; // Error vindo do back está em data dentro de response
              addToast({
                type: 'error',
                title: data.message,
              });
              return;
            }

            addToast({
              type: 'error',
              title: 'Erro ao Exibir!',
              description: `Error: ${error}`,
            });
          });
      }
    },

    [addToast, formRef],
  );

  useEffect(() => {
    // Seta os campos a cada mudança na lista de drawings
    // eslint-disable-next-line array-callback-return
    listDrawingsTheProject.map((item: any, i) => {
      formRef.current?.setFieldValue(
        `scopeDrawings[${i}].idDrawing`,
        item.idDrawing,
      );
      formRef.current?.setFieldValue(
        `scopeDrawings[${i}].fileName`,
        item.fileName,
      );
      formRef.current?.setFieldValue(
        `scopeDrawings[${i}].drawingName`,
        item.drawingName,
      );
      formRef.current?.setFieldValue(
        `scopeDrawings[${i}].drawingNumber`,
        item.drawingNumber,
      );
      formRef.current?.setFieldValue(
        `scopeDrawings[${i}].revisionNumber`,
        item.revisionNumber,
      );
      formRef.current?.setFieldValue(
        `scopeDrawings[${i}].revisionDate`,
        item.revisionDate,
      );
      if (item.files && item.files !== undefined) {
        formRef.current?.setFieldValue(`scopeDrawings[${i}].files`, item.files);
      } else {
        formRef.current?.clearField(`scopeDrawings[${i}].files`);
      }
    });
  }, [formRef, listDrawingsTheProject]);

  return (
    <>
      <SplitTechnicalDrawings>
        <header>
          <p>Desenho Técnico</p>
        </header>

        {listDrawingsTheProject.map((item, index) => (
          <Scope path={`scopeDrawings[${index}]`} key={String(index)}>
            <SplitDrawing>
              <InputHidden name="idDrawing" />
              <InputHidden name="fileName" />
              <Input
                id="drawingName"
                name="drawingName"
                labelText={index === 0 ? 'Nome*' : ''}
                placeholder=""
                maxLength={45}
                autoComplete="off"
                disabled
              />

              <Input
                id="drawingNumber"
                name="drawingNumber"
                labelText={index === 0 ? 'Número*' : ''}
                placeholder=""
                autoComplete="off"
                maxLength={45}
                disabled
              />

              <RevisionNumberAndRevisionDateAndFile>
                <Input
                  id="revisionNumber"
                  name="revisionNumber"
                  type="text"
                  labelText={index === 0 ? 'Rev.*' : ''}
                  autoComplete="off"
                  disabled
                />
                <InputMask
                  id="revisionDate"
                  name="revisionDate"
                  labelText={index === 0 ? 'Data da Rev.*' : ''}
                  mask="99/99/9999"
                  disabled
                />

                <div>
                  {(loadingFile && buttonClicked === `openFile${index}` && (
                    <Button
                      name="buttonSpinner"
                      type="button"
                      onClick={e => {
                        e.preventDefault();
                      }}
                    >
                      <Loading size={24} color="white" />
                    </Button>
                  )) || (
                    <>
                      <Button
                        name={`openFile${index}`}
                        onClick={() =>
                          handleOpenFile(index, `openFile${index}`)
                        }
                      >
                        Exibir
                      </Button>
                    </>
                  )}
                </div>
              </RevisionNumberAndRevisionDateAndFile>
            </SplitDrawing>
          </Scope>
        ))}
      </SplitTechnicalDrawings>

      <SplitFooter>
        <TextArea
          id="requirementProduct"
          name="requirementProduct"
          label="Requerimento dos produtos"
          disabled
        />
        <TextArea
          id="requirementClient"
          name="requirementClient"
          label="Requerimento do cliente"
          disabled
        />
        <TextArea
          id="requirementLegal"
          name="requirementLegal"
          label="Requerimentos legais"
          disabled
        />
        <TextArea
          id="observation"
          name="observation"
          label="Observações gerais"
          disabled
        />
      </SplitFooter>
    </>
  );
};

export default BodyCreateCommercial;
