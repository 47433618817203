import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import { IMenu } from '../interfaces';

import { Container } from './styles';

interface IMenuProp extends IMenu {
  menuActiveProp: boolean;
  closeMenuHamburger: () => void;
  menuClickedId: (idMenuClicked: string) => void;
}

const MenuDropdown: React.FC<IMenuProp> = ({
  id,
  path,
  label,
  submenus = [],
  menuActiveProp = false,
  closeMenuHamburger,
  menuClickedId,
}) => {
  // const location = useLocation();

  // const isActiveRoute = (): boolean => {
  //   return new RegExp(path, 'i').test(location.pathname);
  // };

  // className={isActiveRoute() ? 'active' : ''}

  return (
    <Container menuActiveProp={menuActiveProp}>
      <div
        className="dropdown-header"
        onClick={() => {
          menuClickedId(id);
        }}
        aria-hidden="true"
      >
        <span>
          {label}
          <span className="chevron" />
        </span>
        <span className="hover-mark" />
      </div>

      <div className="dropdown-content">
        <ul>
          {submenus.map(sm => (
            <li key={String(`menu-${id}-submenu-${sm.id}`)}>
              <NavLink to={`/${sm.path}`} onClick={closeMenuHamburger}>
                {sm.label}
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
    </Container>
  );
};

export default MenuDropdown;
