/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';

import { useAuth } from '../../../../../context/AuthContext';

import Input from '../../../../../components/Input';
import CheckboxInput from '../../../../../components/CheckboxInput';

import { Container, RowTwoColumns } from './styles';
import InputPhone from '../../../../../components/InputPhone';

interface IEditUserProps {
  saving?: boolean;
}

const EditFormUser: React.FC<IEditUserProps> = ({ saving = false }) => {
  const { user } = useAuth();

  const [changePassword, setChangePassword] = useState<boolean>(false);

  const handleChangePassword = (): void => {
    setChangePassword(!changePassword);
  };

  return (
    <Container>
      <header>
        <h1>Atualização de Usuários</h1>
      </header>

      <div>
        <Input
          id="name"
          name="name"
          type="text"
          labelText="Nome Completo"
          disabled={saving}
          autoComplete="off"
        />
        <InputPhone
          id="phone"
          name="phone"
          type="text"
          labelText="Telefone"
          disabled={saving}
        />

        <Input
          id="email"
          name="email"
          type="text"
          labelText="E-mail"
          disabled={saving}
        />
        <Input
          id="nickname"
          name="nickname"
          type="text"
          labelText="Codnome"
          disabled={saving}
          autoComplete="off"
        />

        {user.isAdmin ? (
          <RowTwoColumns>
            <div className="checkboxes">
              <CheckboxInput
                name="isAdmin"
                position="left"
                label="Administrador?"
              />

              <CheckboxInput name="isActive" position="left" label="Ativo?" />
            </div>

            <div className="btn-change-password">
              <button
                type="button"
                id="btPassword"
                onClick={handleChangePassword}
              >
                Alterar senha
              </button>
              {changePassword ? (
                <Input
                  id="password"
                  name="password"
                  type="password"
                  disabled={saving}
                  placeholder="Nova senha"
                  autoComplete="new-password"
                />
              ) : null}
            </div>
          </RowTwoColumns>
        ) : (
          <div className="btn-change-password">
            <button
              type="button"
              id="btPassword"
              onClick={handleChangePassword}
            >
              Alterar senha
            </button>
            {changePassword ? (
              <Input
                id="password"
                name="password"
                type="password"
                disabled={saving}
                placeholder="Nova senha"
                autoComplete="new-password"
              />
            ) : null}
          </div>
        )}
      </div>
    </Container>
  );
};

export default EditFormUser;
