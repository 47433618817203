/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import React, { memo } from 'react';
import { FormHandles } from '@unform/core';

import HandleManufacturingSteps from './components/HandleManufacturingSteps';

import { Container } from './styles';
import { IManufacturingSteps } from '../../../../../interfaces/manufacturingSteps';

interface BodyManufacturingProp {
  formRef: any | FormHandles;
  projectProp: any;
  listManufacturingStepsProp: IManufacturingSteps[];
  disableTabProp: boolean;
}

const BodyManufacturing: React.FC<BodyManufacturingProp> = ({
  formRef,
  projectProp,
  listManufacturingStepsProp,
  disableTabProp = false,
}) => {
  return (
    <Container>
      <HandleManufacturingSteps
        formRef={formRef}
        projectHasManufacturingStepsProp={
          projectProp.projectHasManufacturingSteps
        }
        listManufacturingStepsProp={listManufacturingStepsProp}
        disableTabProp={disableTabProp}
      />
    </Container>
  );
};

export default memo(BodyManufacturing);
