/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-curly-newline */
import React from 'react';

import Button from '../../../../../../components/Button';
import Loading from '../../../../../../components/Loading';

import Modal from '../../../../../../components/Modal';

import { Container, Content, ActionSaveModal } from './styles';

interface ModalDTO {
  loadModalProp: boolean;
  indexService: number;
  removeService: (index: number) => void;
  closeModalConfirmationOfExclusionServiceProp: () => void;
}

const ModalConfirmationOfExclusionService: React.FC<ModalDTO> = ({
  loadModalProp = false,
  indexService,
  closeModalConfirmationOfExclusionServiceProp,
  removeService,
}) => {
  return (
    <Modal visibleProp={loadModalProp}>
      <Container>
        <h1>Deseja excluir este serviço?</h1>
        <Content>
          <ActionSaveModal>
            <Button
              type="button"
              onClick={() => {
                removeService(indexService);
                closeModalConfirmationOfExclusionServiceProp();
              }}
            >
              Sim
            </Button>
            <Button
              type="button"
              onClick={() => {
                closeModalConfirmationOfExclusionServiceProp();
              }}
            >
              Não
            </Button>
          </ActionSaveModal>
        </Content>
      </Container>
    </Modal>
  );
};

export default ModalConfirmationOfExclusionService;
