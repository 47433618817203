import styled from 'styled-components';

export const Container = styled.div`
  > button {
    color: var(--azul-5bits);
    font-size: 1.6rem;

    background-color: transparent;
    border: none;
    cursor: pointer;
  }

  //Medias
  @media (min-width: 0px) and (max-width: 759px) {
    display: none;
  }

  @media (min-width: 760px) and (max-width: 999px) {
  }
`;
