/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { format, isValid, parse } from 'date-fns';

import typy from 'typy';
import api from '../../../services/api';
import getProjectFormatted from '../../AuxiliaryFunctions/GetProject';
import getValidationErrors from '../../../utils/getValidationError';
import { useToast } from '../../../context/ToastContext';

import Loading from '../../../components/Loading';

import Button from '../../../components/Button';
import HeadProject from '../../Sectors/HeadProject';
import convertNumberFloatToDB from '../../../utils/convertNumberFloatToDB';
import ModalLoading from '../../../components/ModalLoading';
import ModalConfirmationOfExclusionInvoice from './components/HandleInvoices/components/ModalConfirmationOfExclusionInvoice';

import convertDateToDB from '../../../utils/convertDateToDB';
import { IClient, IClientResponse } from '../../../interfaces/client';
import { IProject } from '../../../interfaces/project';
import * as IInvoice from '../../../interfaces/invoice';

import {
  Container,
  Content,
  HeadOpenProject,
  SplitFooter,
  SelectSectorAndButtonSave,
} from './styles';
import HandleInvoices from './components/HandleInvoices';
import convertNumberFloatToBr from '../../../utils/convertNumberFloatToBr';

const CreateInvoice: React.FC = () => {
  const { id }: any = useParams();
  const formRef = useRef<FormHandles>(null);
  const navigate = useNavigate();
  const { addToast } = useToast();

  const [loading, setLoading] = useState<boolean>(false);

  const [saving, setSaving] = useState<boolean>(false);
  const [project, setProject] = useState<IProject>({} as IProject);
  const [finalPriceProject, setFinalPriceProject] = useState<string>('0');
  const [listClients, setListClients] = useState<IClient[]>([]);
  const [listInvoices, setListInvoices] = useState<
    IInvoice.IInvoiceCreateAndUpdate[]
  >([]);

  const refInvoice = useRef<any>();

  // Get project
  const getProject = useCallback(async (): Promise<void> => {
    try {
      const projectResponse: any = await getProjectFormatted(id);

      setProject(projectResponse);
      if (
        Object.entries(projectResponse).length > 0 &&
        projectResponse.businessProposals.length > 0
      ) {
        setFinalPriceProject(
          convertNumberFloatToBr(
            projectResponse.businessProposals[0].finalPrice,
          ),
        );
      }
      formRef.current?.setData(projectResponse);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      // eslint-disable-next-line no-shadow
      if (error.response) {
        const { data } = error.response; // Error vindo do back está em data dentro de response
        addToast({
          type: 'error',
          title: data.message,
        });
        return;
      }

      addToast({
        type: 'error',
        title: 'Ocorreu um erro interno',
        description: `${error}`,
      });
    }
  }, [addToast, id]);
  // End Get project

  // get Clients
  const getClients = useCallback(async (): Promise<void> => {
    try {
      const responseClients = await api.get<IClientResponse[]>('clients');

      const responseClientsFormatted: any = responseClients.data.map(i => ({
        value: i.id,
        label: i.name,
      }));

      setListClients(responseClientsFormatted);
      getProject();
    } catch (error: any) {
      setLoading(false);

      if (error.response && error.response.data) {
        const { data } = error.response; // Error vindo do back está em data dentro de response
        addToast({
          type: 'error',
          title: data.message,
        });
        return;
      }

      addToast({
        type: 'error',
        title: 'Error',
        description: 'Erro ao listar!',
      });
    }
  }, [addToast, getProject]);
  // End get List Clients

  // handleFormSubmit
  const handleFormSubmit = useCallback(
    async (dataForm: any, { reset }) => {
      try {
        setSaving(true);

        if (dataForm && Object.entries(dataForm).length > 0) {
          const schema = Yup.object().shape({
            scopeListInvoices: Yup.array().of(
              Yup.object().shape({
                dueDate: Yup.string()
                  .test('dueDate', 'Data inválida', (dueDateItem: any) => {
                    return isValid(new Date(dueDateItem));
                  })
                  .required('Data obrigatória')
                  .nullable(),
                price: Yup.string()
                  .test('price', 'Valor obrigatório', (priceItem: any) => {
                    return (
                      priceItem !== '' && convertNumberFloatToDB(priceItem) > 0
                    );
                  })
                  .required('Valor obrigatório'),
                isPay: Yup.boolean(),
                payday: Yup.string().when('isPay', {
                  is: true,
                  then: Yup.string()
                    .test('dueDate', 'Data inválida', (dueDateItem: any) => {
                      return isValid(new Date(dueDateItem));
                    })
                    .required('Data obrigatória')
                    .nullable(),
                }),
              }),
            ),
          });

          await schema.validate(dataForm, { abortEarly: false });

          const listInvoicesFinal = [...dataForm.scopeListInvoices];

          const dataFormCorrect: any = {
            projectId: parseInt(id, 10),
            invoices: listInvoicesFinal.map(item => {
              item.dueDate = convertDateToDB(item.dueDate);
              item.price = convertNumberFloatToDB(item.price);
              item.payday = item.isPay ? convertDateToDB(item.payday) : '';
              return item;
            }),
          };

          await api.post('invoices', dataFormCorrect);

          addToast({
            type: 'success',
            title: 'Cadastro realizado com sucesso',
          });

          reset();
          setSaving(false);
          navigate('/invoices');
        } else {
          addToast({
            type: 'error',
            title: 'Nenhuma parcela registrada',
            description: 'Registre pelo menos uma parcela',
          });
        }
        setSaving(false);
      } catch (error: any) {
        setSaving(false);

        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);

          return;
        }

        if (error.response) {
          const { data } = error.response; // Error vindo do back está em data dentro de response

          addToast({
            type: 'error',
            title: 'Erro no cadastro',
            description: data.message,
          });
          if (
            formRef.current?.getFieldRef(data.field) &&
            data.field.toString().indexOf('_id') === -1
          ) {
            formRef.current?.getFieldRef(data.field).focus(); // Foca o campo que deu erro de acordo com o field retornado do back
          }

          return;
        }

        addToast({
          type: 'error',
          title: 'Error',
          description: 'Erro ao cadastrar!',
        });
      }
    },
    [addToast, navigate, id],
  );
  // End handleFormSubmit

  // Modal Confirmation Of Exclusion Invoice
  const [
    loadModalConfirmationOfExclusionInvoice,
    setLoadModalConfirmationOfExclusionInvoice,
  ] = useState(false);

  const [indexInvoice, setIndexInvoice] = useState<number>(-1);

  const showModalConfirmationOfExclusionInvoice = useCallback(
    (indexInvoiceProp, showModal) => {
      setIndexInvoice(indexInvoiceProp);
      setLoadModalConfirmationOfExclusionInvoice(showModal);
    },
    [],
  );

  const closeModalConfirmationOfExclusionInvoice = useCallback(() => {
    setLoadModalConfirmationOfExclusionInvoice(false);
  }, []);

  const removeInvoice = (indexInvoiceForRemove: number): void => {
    refInvoice.current?.removeInvoice(indexInvoiceForRemove);
  };

  useEffect(() => {
    setLoading(true);
    const getProjectAndClients = async (): Promise<void> => {
      getClients();
    };
    getProjectAndClients();
  }, [addToast, getClients, getProject]);

  return (
    <>
      <Container>
        {(typy(project.id).isNumber && listClients.length > 0 && (
          <Content>
            <Form ref={formRef} onSubmit={handleFormSubmit}>
              <header>
                <p>Faturamento</p>
                <span>Nº do Projeto: {id}</span>
              </header>

              <HeadOpenProject>
                <HeadProject
                  listClients={listClients}
                  showInformationInMobile
                  readOnlyProp
                />
              </HeadOpenProject>

              <HandleInvoices
                formRef={formRef}
                listInvoices={listInvoices}
                finalPriceProject={finalPriceProject}
                showModalConfirmationOfExclusionInvoice={
                  showModalConfirmationOfExclusionInvoice
                }
                ref={refInvoice}
              />

              <SplitFooter>
                <SelectSectorAndButtonSave>
                  <div>
                    <Button type="submit" disabled={saving}>
                      {saving ? <Loading size={24} color="white" /> : 'Salvar'}
                    </Button>
                  </div>
                </SelectSectorAndButtonSave>
              </SplitFooter>

              <ModalConfirmationOfExclusionInvoice
                loadModalProp={loadModalConfirmationOfExclusionInvoice}
                indexInvoice={indexInvoice}
                removeInvoice={removeInvoice}
                closeModalConfirmationOfExclusionInvoiceProp={
                  closeModalConfirmationOfExclusionInvoice
                }
              />
            </Form>
          </Content>
        )) || (
          <ModalLoading visible={loading}>
            <p>
              <i>Carregando os dados do Faturamento...</i>
            </p>
          </ModalLoading>
        )}
      </Container>
    </>
  );
};

export default CreateInvoice;
