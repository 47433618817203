import styled, { css } from 'styled-components';

interface ContentProps {
  isFocused: boolean;
  isFilled: boolean;
  isErrored: boolean;
  isDisabled: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  color: var(--cinza-escuro-5bits);

  label {
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 2.1rem;
    text-align: center;
  }
`;

export const Content = styled.div<ContentProps>`
  width: 100%;
  height: 4rem;

  display: flex;
  align-items: flex-start;
  justify-content: center;

  border-radius: 2.5rem;
  border: 0.1rem solid var(--input-border-color);

  background: var(--input-bg-color);

  & + div {
    margin-top: 0.8rem;
  }

  ${props =>
    props.isDisabled &&
    css`
      filter: brightness(0.9);

      &:hover {
        filter: brightness(0.9);
      }
    `}

  ${props =>
    props.isErrored &&
    css`
      border-color: var(--on-errored-input-border-color);
    `}

  ${props =>
    props.isFocused &&
    css`
      color: var(--on-focus-input-text-color);
      border-color: var(--on-focus-input-border-color);
    `}

  ${props =>
    props.isFilled &&
    css`
      color: var(--on-filled-input-text-color);
      border-width: 0.1rem;
    `}


  > div {
    padding: 0.9rem 0.5rem 0.9rem 1.5rem;
    color: var(--input-text-color);
  }

  > div:nth-child(1) {
    width: 70%;

    border-right: 0.1rem solid var(--input-border-color);

    input {
      flex: 1;

      width: 100%;

      text-align: right;
      background-color: var(--input-bg-color);
      border: 0;

      ${props =>
        (props.isDisabled &&
          css`
            color: var(--cinza-claro-5bits);
            font-weight: 500;
          `) ||
        css`
          color: var(--input-text-color);
          font-weight: 500;
        `}

      &::placeholder {
        color: var(--input-placeholder-color);
      }

      &:disabled {
        cursor: not-allowed;
      }
    }

    svg {
      margin-right: 1.6rem;
    }
  }

  > div:nth-child(2) {
    width: 30%;
    padding-left: 0.5rem;
    padding-right: 1.5rem;

    span {
      width: 100%;

      font-style: normal;
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 2.1rem;

      ${props =>
        (props.isDisabled &&
          css`
            color: var(--cinza-claro-5bits);
            font-weight: 500;
          `) ||
        css`
          color: var(--input-text-color);
          font-weight: 500;
        `}
    }
  }
`;
