/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-curly-newline */
import React from 'react';
import { FormHandles } from '@unform/core';

import Button from '../../../../components/Button';
import { businessProposalStatusValid } from '../../../../data/businessProposalStatusValid.enum';
import Loading from '../../../../components/Loading';

import Modal from '../../../../components/Modal';

import { Container, Content, ActionSaveModal } from './styles';

interface ModalDTO {
  formRef: any | FormHandles;
  loadModalProp: boolean;
  closeModalFinishedProposals: () => void;
}

const ModalConfirmationFinishedProposals: React.FC<ModalDTO> = ({
  formRef,
  loadModalProp = false,
  closeModalFinishedProposals,
}) => {
  return (
    <Modal visibleProp={loadModalProp}>
      <Container>
        <h1>Proposta comercial concluída?</h1>
        <Content>
          <ActionSaveModal>
            <Button
              type="button"
              onClick={() => {
                formRef.current.setFieldValue(
                  'status',
                  businessProposalStatusValid.Concluida,
                );
                closeModalFinishedProposals();
              }}
            >
              Sim
            </Button>
            <Button
              type="button"
              onClick={() => {
                formRef.current.setFieldValue(
                  'status',
                  businessProposalStatusValid.Parcial,
                );
                closeModalFinishedProposals();
              }}
            >
              Não
            </Button>
          </ActionSaveModal>
        </Content>
      </Container>
    </Modal>
  );
};

export default ModalConfirmationFinishedProposals;
