import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import { IconBaseProps } from 'react-icons';
import { useField } from '@unform/core';
import { FiAlertCircle } from 'react-icons/fi';

import Error from '../../styles/errorFieldGlobal';
import { Container, Content } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  labelText?: string;
  icon?: React.ComponentType<IconBaseProps>;
}

const Input: React.FC<InputProps & { autocomplete?: string }> = ({
  name,
  labelText,
  icon: Icon,
  disabled,
  readOnly,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const {
    fieldName,
    defaultValue,
    error,
    clearError,
    registerField,
  } = useField(name);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [isFilled, setIsFilled] = useState<boolean>(false);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);

    if (inputRef.current) {
      setIsFilled(!!inputRef.current.value);
    }
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  const handleChange = useCallback((): void => {
    if (error) {
      clearError();
    }
  }, [clearError, error]);

  return (
    <Container>
      {labelText && <label htmlFor={`id-${name}`}>{labelText}</label>}
      <Content
        isErrored={!!error}
        isFocused={isFocused}
        isFilled={isFilled}
        isDisabled={!!disabled}
        isReadOnly={!!readOnly}
      >
        {Icon && <Icon size={20} />}
        <input
          id={`id-${name}`}
          onFocus={(): void => setIsFocused(true)}
          onBlur={handleInputBlur}
          defaultValue={defaultValue}
          ref={inputRef}
          type="text"
          onChange={handleChange}
          disabled={disabled}
          readOnly={readOnly}
          {...rest}
        />
      </Content>
      {error && (
        <Error>
          <FiAlertCircle color="var(--on-errored-message-bg-color)" size={20} />
          {error}
        </Error>
      )}
    </Container>
  );
};

export default Input;
