import React, { useEffect, useState, useCallback } from 'react';
import typy from 'typy';
import { Link } from 'react-router-dom';
import { FiEdit } from 'react-icons/fi';

import { isAfter } from 'date-fns';
import * as IInvoices from '../../../interfaces/invoice';

import { useToast } from '../../../context/ToastContext';

import api from '../../../services/api';
import ListingTemplate from '../../../components/Template/Listing';

import { IPermission } from '../../../interfaces/permission';
import convertDateStringToDate from '../../../utils/convertDateStringToDate';
import convertInitToUppercase from '../../../utils/convertInitToUppercase';
import convertNumberFloatToBr from '../../../utils/convertNumberFloatToBr';
import convertDateToBr from '../../../utils/convertDateToBr';

import { Container, Content } from './styles';
import convertDateToDB from '../../../utils/convertDateToDB';

interface PermissionDTO {
  permissions: IPermission;
}

const ListInvoices: React.FC<PermissionDTO> = ({ permissions }) => {
  const { addToast } = useToast();
  const [loading, setLoading] = useState<boolean>(false);
  const [listInvoices, setListInvoices] = useState<IInvoices.IInvoiceList[]>(
    [],
  );
  const [listInvoicesPaginated, setListInvoicesPaginated] = useState<
    IInvoices.IInvoiceList[]
  >([]);
  useEffect(() => {
    setLoading(true);

    api
      .get<IInvoices.IInvoiceResponse[]>('invoices')
      .then(response => {
        const responseFormatted = response.data.map((i: any) => ({
          ...i,
          client: i.project.client.name,
          createdAt: convertDateStringToDate(i.createdAt),
          updatedAt: convertDateStringToDate(i.updatedAt),
        }));

        setListInvoices(responseFormatted);
        setListInvoicesPaginated(responseFormatted);

        setLoading(false);
      })
      .catch(error => {
        setLoading(false);

        if (error.response && error.response.data) {
          const { data } = error.response; // Error vindo do back está em data dentro de response
          addToast({
            type: 'error',
            title: data.message,
          });
          return;
        }

        addToast({
          type: 'error',
          title: 'Error',
          description: 'Erro ao listar!',
        });
      });
  }, [addToast]);

  const handleListInvoicesPaginatedItems = useCallback(newList => {
    const newListPaginationFormatted = newList.map((i: any) => ({
      ...i,
      client: i.project.client.name,
      createdAt: convertDateStringToDate(i.createdAt),
      updatedAt: convertDateStringToDate(i.updatedAt),
    }));
    setListInvoicesPaginated(newListPaginationFormatted);
  }, []);

  const handleColorLineInvoice = useCallback(
    (isPay: boolean, payDayString: string, dueDateString: string): string => {
      const dueDate: Date | undefined = convertDateStringToDate(dueDateString);

      if (isPay && typy(convertDateStringToDate(payDayString)).isDate) {
        return 'var(--message-toast-success-bg-color)';
      }

      if (dueDate !== undefined && typy(dueDate).isDate) {
        if (isPay === false && isAfter(new Date(), dueDate)) {
          return 'var(--message-toast-error-bg-color)';
        }
      }

      return '';
    },
    [],
  );

  return (
    <ListingTemplate
      title="Listagem de Faturas"
      module="invoices"
      listItemsProps={listInvoices}
      handleListItemsPaginatedItems={handleListInvoicesPaginatedItems}
      placeHolderProp="Cód. do Projeto"
      searchWithoutActive
      displayButtonNew={false}
      disabled={loading}
      permissions={permissions}
    >
      <Container>
        <Content>
          {loading ? (
            <p>carregando ...</p>
          ) : (
            <table>
              <thead>
                <tr>
                  <th>Projeto</th>
                  <th>Cliente</th>
                  <th>Valor</th>
                  <th>Vencimento</th>
                  <th>Data Pagamento</th>
                  <th>Observação</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {listInvoicesPaginated.map(c => (
                  <tr
                    key={String(c.id)}
                    style={{
                      backgroundColor: handleColorLineInvoice(
                        c.isPay,
                        convertDateToDB(c.payday),
                        convertDateToDB(c.dueDate),
                      ),
                    }}
                  >
                    <td>{c.projectId}</td>
                    <td>{convertInitToUppercase(c.client)}</td>
                    <td>{convertNumberFloatToBr(c.price)}</td>
                    <td>{convertDateToBr(c.dueDate)}</td>
                    <td>{convertDateToBr(c.payday)}</td>
                    <td>{convertInitToUppercase(c.observation)}</td>
                    <td>
                      {(permissions.update && c.isPay === false && (
                        <Link to={`/invoices/update/${c.id}`}>
                          <FiEdit size={16} color="var(--azul-5bits)" />
                        </Link>
                      )) || (
                        <span>
                          <FiEdit size={16} color="var(--cinza-escuro-5bits)" />
                        </span>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </Content>
      </Container>
    </ListingTemplate>
  );
};

export default ListInvoices;
