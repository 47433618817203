/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import SignIn from '../pages/SignIn';
import Home from '../pages/Home';
import ContainerWorkflow from '../pages/Workflow';

// import NotFoundPage from '../pages/NotFoundPage';

import PrivateRoute from './PrivateRoutes';

import ListUsers from '../pages/User/List';
import CreateUser from '../pages/User/Create';
import UpdateUser from '../pages/User/Update';

import ListClients from '../pages/Client/List';
import CreateClient from '../pages/Client/Create';
import UpdateClient from '../pages/Client/Update';

import ListMaterials from '../pages/Materials/List';
import CreateMaterial from '../pages/Materials/Create';
import UpdateMaterial from '../pages/Materials/Update';

import ListServices from '../pages/Services/List';
import CreateService from '../pages/Services/Create';
import UpdateService from '../pages/Services/Update';

import ListProviders from '../pages/Providers/List';
import CreateProvider from '../pages/Providers/Create';
import UpdateProvider from '../pages/Providers/Update';

import ListProductionFlow from '../pages/ProductionFlow/List';
import ListProjectsOverview from '../pages/ProjectsOverview/List';

import TesteFile from '../pages/TesteFile';
import ListTechnicalDrawings from '../pages/TechnicalDrawings/List';

import CreateCommercialProject from '../pages/Sectors/Commercial/Create';
import UpdateCommercialProject from '../pages/Sectors/Commercial/Update';
import ListCommercialProject from '../pages/Sectors/Commercial/List';

import CreatePlanningProject from '../pages/Sectors/Planning/Create';
import ListPlanningProject from '../pages/Sectors/Planning/List';

import CreatePurchasingProject from '../pages/Sectors/Purchasing/Create';
import ListPurchasingProject from '../pages/Sectors/Purchasing/List';

import ListManufacturingProjects from '../pages/Sectors/Manufacturing/Projects/List';
import UpdateManufacturingProject from '../pages/Sectors/Manufacturing/Projects/Update';

import ListManufacturingSteps from '../pages/Sectors/Manufacturing/Steps/List';
import CreateManufacturingSteps from '../pages/Sectors/Manufacturing/Steps/Create';
import UpdateManufacturingSteps from '../pages/Sectors/Manufacturing/Steps/Update';
import WorkflowManufacturing from '../pages/Sectors/Manufacturing/Workflow';

import ListBusinessProposal from '../pages/BusinessProposal/List';
import CreateBusinessProposal from '../pages/BusinessProposal/Create';
import UpdateBusinessProposal from '../pages/BusinessProposal/Update';
import ReadBusinessProposal from '../pages/BusinessProposal/Read';
import CancelBusinessProposal from '../pages/BusinessProposal/Cancel';
import SendEmailBusinessProposal from '../pages/BusinessProposal/SendEmail';
import ApprovalProposal from '../pages/BusinessProposal/ApprovalProposal';

import ListInvoices from '../pages/Invoicing/List';
import CreateInvoice from '../pages/Invoicing/Create';
import UpdateInvoice from '../pages/Invoicing/Update';


function Bugets() {
  return <h2>Bugets</h2>;
}
function Incomes() {
  return <h2>Incomes</h2>;
}
function Reports() {
  return <h2>Reports</h2>;
}

const ApplicationRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<SignIn />} />
      <Route
        path="/home"
        element={(
          <PrivateRoute
            path="/home"
            component={Home}
            originalRouteOfDB="home"
          />
        )}
      />
      <Route
        path="/teste/file"
        element={(
          <PrivateRoute
            path="/teste/file"
            component={TesteFile}
        // checkPermissions
            originalRouteOfDB="teste"
          />
      )}
      />
      <Route
        path="/users/create"
        element={(
          <PrivateRoute
            path="/users/create"
            component={CreateUser}
            checkPermissions
            originalRouteOfDB="users"
          />
      )}
      />
      <Route
        path="/users/update/:id"
        element={(
          <PrivateRoute
            path="/users/update/:id"
            component={UpdateUser}
            checkPermissions
            originalRouteOfDB="users"
          />
      )}
      />
      <Route
        path="/users"
        element={(
          <PrivateRoute
            path="/users"
            component={ListUsers}
            checkPermissions
            isMenu
            originalRouteOfDB="users"
          />
      )}
      />
      <Route
        path="/clients/create"
        element={(
          <PrivateRoute
            path="/clients/create"
            component={CreateClient}
            checkPermissions
            originalRouteOfDB="clients"
          />
      )}
      />
      <Route
        path="/clients/update/:id"
        element={(
          <PrivateRoute
            path="/clients/update/:id"
            component={UpdateClient}
            checkPermissions
            originalRouteOfDB="clients"
          />
      )}
      />
      <Route
        path="/clients"
        element={(
          <PrivateRoute
            path="/clients"
            component={ListClients}
            checkPermissions
            isMenu
            originalRouteOfDB="clients"
          />
      )}
      />
      <Route
        path="/materials/create"
        element={(
          <PrivateRoute
            path="/materials/create"
            component={CreateMaterial}
            checkPermissions
            originalRouteOfDB="materials"
          />
      )}
      />
      <Route
        path="/materials/update/:id"
        element={(
          <PrivateRoute
            path="/materials/update/:id"
            component={UpdateMaterial}
            checkPermissions
            originalRouteOfDB="materials"
          />
      )}
      />
      <Route
        path="/materials"
        element={(
          <PrivateRoute
            path="/materials"
            component={ListMaterials}
            checkPermissions
            isMenu
            originalRouteOfDB="materials"
          />
      )}
      />
      <Route
        path="/services/create"
        element={(
          <PrivateRoute
            path="/services/create"
            component={CreateService}
            checkPermissions
            originalRouteOfDB="services"
          />
      )}
      />
      <Route
        path="/services/update/:id"
        element={(
          <PrivateRoute
            path="/services/update/:id"
            component={UpdateService}
            checkPermissions
            originalRouteOfDB="services"
          />
      )}
      />
      <Route
        path="/services"
        element={(
          <PrivateRoute
            path="/services"
            component={ListServices}
            checkPermissions
            isMenu
            originalRouteOfDB="services"
          />
      )}
      />
      <Route
        path="/providers/create"
        element={(
          <PrivateRoute
            path="/providers/create"
            component={CreateProvider}
            checkPermissions
            originalRouteOfDB="providers"
          />
      )}
      />
      <Route
        path="/providers/update/:id"
        element={(
          <PrivateRoute
            path="/providers/update/:id"
            component={UpdateProvider}
            checkPermissions
            originalRouteOfDB="providers"
          />
      )}
      />
      <Route
        path="/providers"
        element={(
          <PrivateRoute
            path="/providers"
            component={ListProviders}
            checkPermissions
            isMenu
            originalRouteOfDB="providers"
          />
      )}
      />
      <Route
        path="/production-flow"
        element={(
          <PrivateRoute
            path="/production-flow"
            component={ListProductionFlow}
        // checkPermissions
            isMenu
            originalRouteOfDB="production-flow"
          />
      )}
      />
      <Route
        path="/projects-overview"
        element={(
          <PrivateRoute
            path="/projects-overview"
            component={ListProjectsOverview}
        // checkPermissions
            isMenu
            originalRouteOfDB="projects-overview"
          />
      )}
      />
      <Route
        path="/drawings"
        element={(
          <PrivateRoute
            path="/drawings"
            component={ListTechnicalDrawings}
            checkPermissions
            isMenu
            originalRouteOfDB="drawings"
          />
      )}
      />
      <Route
        path="/workflow"
        element={(
          <PrivateRoute
            path="/workflow"
            component={ContainerWorkflow}
            checkPermissions
            isMenu
            originalRouteOfDB="workflow"
          />
      )}
      />
      <Route
        path="/commercial/create"
        element={(
          <PrivateRoute
            path="/commercial/create"
            component={CreateCommercialProject}
            checkPermissions
            originalRouteOfDB="commercial"
          />
      )}
      />
      <Route
        path="/commercial/update/:id"
        element={(
          <PrivateRoute
            path="/commercial/update/:id"
            component={UpdateCommercialProject}
            checkPermissions
            originalRouteOfDB="commercial"
          />
      )}
      />
      <Route
        path="/commercial"
        element={(
          <PrivateRoute
            path="/commercial"
            component={ListCommercialProject}
            checkPermissions
            isMenu
            originalRouteOfDB="commercial"
          />
      )}
      />
      <Route
        path="/planning/update/:id"
        element={(
          <PrivateRoute
            path="/planning/update/:id"
            component={CreatePlanningProject}
            checkPermissions
            originalRouteOfDB="planning"
          />
      )}
      />
      <Route
        path="/planning"
        element={(
          <PrivateRoute
            path="/planning"
            component={ListPlanningProject}
            checkPermissions
            isMenu
            originalRouteOfDB="planning"
          />
      )}
      />
      <Route
        path="/purchasing/update/:id"
        element={(
          <PrivateRoute
            path="/purchasing/update/:id"
            component={CreatePurchasingProject}
            checkPermissions
            originalRouteOfDB="purchasing"
          />
      )}
      />
      <Route
        path="/purchasing"
        element={(
          <PrivateRoute
            path="/purchasing"
            component={ListPurchasingProject}
            checkPermissions
            isMenu
            originalRouteOfDB="purchasing"
          />
      )}
      />
      <Route
        path="/manufacturing/steps/create"
        element={(
          <PrivateRoute
            path="/manufacturing/steps/create"
            component={CreateManufacturingSteps}
            checkPermissions
            originalRouteOfDB="manufacturing/steps"
          />
      )}
      />
      <Route
        path="/manufacturing/steps/update/:id"
        element={(
          <PrivateRoute
            path="/manufacturing/steps/update/:id"
            component={UpdateManufacturingSteps}
            checkPermissions
            originalRouteOfDB="manufacturing/steps"
          />
      )}
      />
      <Route
        path="/manufacturing/steps"
        element={(
          <PrivateRoute
            path="/manufacturing/steps"
            component={ListManufacturingSteps}
            checkPermissions
            isMenu
            originalRouteOfDB="manufacturing/steps"
          />
      )}
      />
      <Route
        path="/manufacturing/workflow"
        element={(
          <PrivateRoute
            path="/manufacturing/workflow"
            component={WorkflowManufacturing}
            checkPermissions
            originalRouteOfDB="manufacturing/workflow"
          />
      )}
      />
      <Route
        path="/manufacturing/update/:id"
        element={(
          <PrivateRoute
            path="/manufacturing/update/:id"
            component={UpdateManufacturingProject}
            checkPermissions
            originalRouteOfDB="manufacturing"
          />
      )}
      />
      <Route
        path="/manufacturing"
        element={(
          <PrivateRoute
            path="/manufacturing"
            component={ListManufacturingProjects}
            checkPermissions
            isMenu
            originalRouteOfDB="manufacturing"
          />
      )}
      />
      <Route
        path="/proposals/create/send-email/:projectId/:proposalId"
        element={(
          <PrivateRoute
            path="/proposals/create/send-email/:projectId/:proposalId"
            component={SendEmailBusinessProposal}
            checkPermissions
            originalRouteOfDB="proposals"
          />
      )}
      />
      <Route
        path="/proposals/update/approval/:idProject/:idBusinessProposal"
        element={(
          <PrivateRoute
            path="/proposals/update/approval/:idProject/:idBusinessProposal"
            component={ApprovalProposal}
            checkPermissions
            originalRouteOfDB="proposals"
          />
      )}
      />
      <Route
        path="/proposals/create/:id"
        element={(
          <PrivateRoute
            path="/proposals/create/:id"
            component={CreateBusinessProposal}
            checkPermissions
            originalRouteOfDB="proposals"
          />
      )}
      />
      <Route
        path="/proposals/update/:id"
        element={(
          <PrivateRoute
            path="/proposals/update/:id"
            component={UpdateBusinessProposal}
            checkPermissions
            originalRouteOfDB="proposals"
          />
      )}
      />
      <Route
        path="/proposals/read/:id"
        element={(
          <PrivateRoute
            path="/proposals/read/:id"
            component={ReadBusinessProposal}
            checkPermissions
            originalRouteOfDB="proposals"
          />
      )}
      />
      <Route
        path="/proposals/delete/:id"
        element={(
          <PrivateRoute
            path="/proposals/delete/:id"
            component={CancelBusinessProposal}
            checkPermissions
            originalRouteOfDB="proposals"
          />
      )}
      />
      <Route
        path="/proposals"
        element={(
          <PrivateRoute
            path="/proposals"
            component={ListBusinessProposal}
            checkPermissions
            isMenu
            originalRouteOfDB="proposals"
          />
      )}
      />
      <Route
        path="/invoices/create/:id"
        element={(
          <PrivateRoute
            path="/invoices/create/:id"
            component={CreateInvoice}
            checkPermissions
            originalRouteOfDB="invoices"
          />
      )}
      />
      <Route
        path="/invoices/update/:id"
        element={(
          <PrivateRoute
            path="/invoices/update/:id"
            component={UpdateInvoice}
            checkPermissions
            originalRouteOfDB="invoices"
          />
      )}
      />
      <Route
        path="/invoices"
        element={(
          <PrivateRoute
            path="/invoices"
            component={ListInvoices}
            checkPermissions
            isMenu
            originalRouteOfDB="invoices"
          />
      )}
      />
      <Route
        path="/budgets"
        element={(
          <PrivateRoute
            path="/budgets"
            component={Bugets}
            originalRouteOfDB="budgets"
          />
      )}
      />
      <Route
        path="/incomes"
        element={(
          <PrivateRoute
            path="/incomes"
            component={Incomes}
            originalRouteOfDB="incomes"
          />
      )}
      />
      <Route
        path="/reports"
        element={(
          <PrivateRoute
            path="/reports"
            component={Reports}
            originalRouteOfDB="reports"
          />
      )}
      />

      {/* o path="*" pega todas as rotas que não foram definidas acima. Pode fazer a 404 ou voltar para login (é o atual) */}
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default ApplicationRoutes;
