/* eslint-disable @typescript-eslint/ban-types */
import { parseISO, isValid } from 'date-fns';

function convertDateStringToDate(date: string): Date | undefined {
  if (date && typeof date === 'string') {
    const dateConverted = parseISO(date);

    if (!isValid(dateConverted)) {
      throw new Error('Erro na conversão da data');
    }
    return dateConverted;
  }
  return undefined;
}

export default convertDateStringToDate;
