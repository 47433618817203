/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import fincalc from 'fincalc';
import { FormHandles, Scope } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { isValid, parse } from 'date-fns';

import { FiTrash2 } from 'react-icons/fi';

import { BsSearch } from 'react-icons/bs';
import typy from 'typy';
import api from '../../../../services/api';
import getProjectFormatted from '../../../AuxiliaryFunctions/GetProject';
import getValidationErrors from '../../../../utils/getValidationError';
import convertDateToBr from '../../../../utils/convertDateToBr';
import { useToast } from '../../../../context/ToastContext';

import Loading from '../../../../components/Loading';
import Input from '../../../../components/Input';
import InputMask from '../../../../components/InputMask';
import TextArea from '../../../../components/TextArea';
import Button from '../../../../components/Button';
import SimpleSelect from '../../../../components/SimpleSelect';
import InputHidden from '../../../../components/InputHidden';
import InputFileButton from '../../../../components/InputFileButtonWithButtonTrashAndSearchForArray';
import DatePicker from '../../../../components/DatePicker';

import currentSectorList from '../../../../data/currentSectorList.json';

import ModalFindDrawing from './Modal/modalFindDrawing';

import {
  Container,
  Content,
  HeadOpenProject,
  TechnicalDrawings,
  SectionTitle,
  SplitTechnicalDrawings,
  SplitDrawing,
  RevisionNumberAndRevisionDateAndFile,
  ButtonAndIconFakes,
  SplitFooter,
  SplitFooterContent,
  SelectSectorAndButtonSave,
} from './styles';

import { IProject, IProjectResponse } from '../../../../interfaces/project';
import { IDrawingsResponse } from '../../../../interfaces/drawings';
import { ICommercial } from '../../../../interfaces/commercial';
import { IProviderResponse } from '../../../../interfaces/provider';
import { IChecklist } from '../../../../interfaces/checklist';

import convertDateToDB from '../../../../utils/convertDateToDB';

import HeadProject from '../../HeadProject';
import ChecklistProject from '../../componentsSectors/ChecklistProject';
import ModalChecklist from '../../componentsSectors/ModalChecklist/modalChecklist';
import convertNumberFloatToBr from '../../../../utils/convertNumberFloatToBr';
import convertDateStringToDate from '../../../../utils/convertDateStringToDate';
import convertNumberFloatToDB from '../../../../utils/convertNumberFloatToDB';
import Tabs from '../../../../components/Tabs';
import ModalLoading from '../../../../components/ModalLoading';

import { IMaterialResponse } from '../../../../interfaces/material';
import { IServiceResponse } from '../../../../interfaces/service';

import BodyPlanning from '../../Planning/BodyPlanning';
import BodyPurchasing from '../../Purchasing/BodyPurchasing';
import BodyManufacturing from '../../Manufacturing/Projects/BodyManufacturing';
import { IClient, IClientResponse } from '../../../../interfaces/client';
import { IUserResponse } from '../../../../interfaces/user';

interface IDrawingsResponseForm extends Omit<IDrawingsResponse, 'id'> {
  id: string;
  files: any;
}

interface ICommercialForm extends Omit<ICommercial, 'drawings' | 'id'> {
  id: string;
  revisionDate: string;
  drawings: Array<IDrawingsResponseForm>;
}

const UpdateProject: React.FC = () => {
  const { id }: any = useParams();
  const formRef = useRef<FormHandles>(null);
  const navigate = useNavigate();
  const { addToast } = useToast();

  const [listDrawingsTheProject, setListDrawingsTheProject] = useState<
    ICommercialForm[]
  >([{} as ICommercialForm]);

  const [percentCompleted, setPercentCompleted] = useState(0);
  const [loadingFile, setLoadingFile] = useState<boolean>(false);
  const [buttonClicked, setButtonClicked] = useState<string>('');
  const [saving, setSaving] = useState<boolean>(false);

  const [project, setProject] = useState<IProject>({} as IProject);
  const [loading, setLoading] = useState<boolean>(false);

  const [listChecklist, setListChecklist] = useState<IChecklist[]>([]);
  const [loadingChecklist, setLoadingChecklist] = useState<boolean>(false);

  const [listUsers, setListUsers] = useState<
    Omit<IUserResponse, 'usersHasMenus'>[]
  >([]);
  const [listClients, setListClients] = useState<IClient[]>([]);
  const [listMaterials, setListMaterials] = useState<IMaterialResponse[]>([]);
  const [listServices, setListServices] = useState<IServiceResponse[]>([]);
  const [listManufacturingSteps, setListManufacturingSteps] = useState<any[]>(
    [],
  );

  const [listProviders, setListProviders] = useState<IProviderResponse[]>([]);
  const [finishedProposal, setFinishedProposal] = useState<boolean>(false);

  // Get project
  const getProject = useCallback(async (): Promise<void> => {
    try {
      const projectResponse: any = await getProjectFormatted(id);

      // Daqui pra cima está igual
      setProject(projectResponse);
      setFinishedProposal(projectResponse.finishedProposal);

      projectResponse.drawings && projectResponse.drawings.length > 0
        ? setListDrawingsTheProject(projectResponse.drawings)
        : setListDrawingsTheProject([{} as ICommercialForm]);

      // setListManufacturingSteps(projectResponse.projectResponseHasManufacturingSteps);
      formRef.current?.setData(projectResponse);

      setLoading(false);
    } catch (error: any) {
      setLoading(false);

      // eslint-disable-next-line no-shadow
      if (error.response) {
        const { data } = error.response; // Error vindo do back está em data dentro de response
        addToast({
          type: 'error',
          title: data.message,
        });
        return;
      }

      addToast({
        type: 'error',
        title: 'Ocorreu um erro interno',
        description: `${error}`,
      });
    }
  }, [addToast, id]);
  // End Get project

  useEffect(() => {
    setLoadingChecklist(true);
    const getChecklist = async (): Promise<void> => {
      try {
        setLoading(true);
        // Checklist
        const responseChecklist = await api.get<any[]>('checklist');

        setListChecklist(responseChecklist.data);
        // End checklist
        setLoadingChecklist(false);
      } catch (error: any) {
        setLoadingChecklist(false);
        setLoading(false);

        if (error.response && error.response.data) {
          const { data } = error.response; // Error vindo do back está em data dentro de response
          addToast({
            type: 'error',
            title: data.message,
          });
          return;
        }

        addToast({
          type: 'error',
          title: 'Error',
          description: 'Erro ao listar!',
        });
      }
    };
    getChecklist();
  }, [addToast]);

  // List Materials
  useEffect(() => {
    setLoading(true);
    const getMaterials = async (): Promise<void> => {
      try {
        // Users
        const responseUsers = await api.get<
          Omit<IUserResponse, 'usersHasMenus'>[]
        >('users');

        const responseUsersFormatted: any = responseUsers.data.map(i => ({
          ...i,
          value: i.id,
          label: i.name,
        }));

        setListUsers(responseUsersFormatted);

        // Clientes
        const responseClients = await api.get<IClientResponse[]>('clients');

        const responseClientsFormatted: any = responseClients.data.map(i => ({
          value: i.id,
          label: i.name,
        }));

        setListClients(responseClientsFormatted);

        // Materials
        const responseMaterials = await api.get<IMaterialResponse[]>(
          'materials/active',
        );

        const responseMaterialsFormatted: any = responseMaterials.data.map(
          i => ({
            ...i,
            value: i.id,
            label: i.name,
          }),
        );

        setListMaterials(responseMaterialsFormatted);
        // End Materials

        // Services
        // setLoadingServices(true);
        const responseServices = await api.get<IServiceResponse[]>(
          'services/active',
        );

        const responseServicesFormatted: any = responseServices.data.map(i => ({
          ...i,
          value: i.id,
          label: i.name,
        }));

        setListServices(responseServicesFormatted);
        // End Services

        // Providers
        const responseProviders = await api.get<IProviderResponse[]>(
          'providers/active',
        );

        const responseProvidersFormatted: any = responseProviders.data.map(
          i => ({
            ...i,
            value: i.id,
            label: i.fantasyName,
          }),
        );

        setListProviders(responseProvidersFormatted);
        // End Providers

        // Checklist
        const responseChecklist = await api.get<any[]>('checklist');

        setListChecklist(responseChecklist.data);
        // End checklist

        // Project
        getProject();

        // setLoadingMaterials(false);
      } catch (error: any) {
        setLoading(false);

        if (error.response && error.response.data) {
          const { data } = error.response; // Error vindo do back está em data dentro de response
          addToast({
            type: 'error',
            title: data.message,
          });
          return;
        }

        addToast({
          type: 'error',
          title: 'Error',
          description: 'Erro ao listar!',
        });
      }
    };
    getMaterials();
  }, [addToast, getProject]);

  const [openModalChecklist, setOpenModalChecklist] = useState(false);
  const handleOpenModalChecklist = useCallback(() => {
    setOpenModalChecklist(!openModalChecklist);
    setSaving(false);
  }, [openModalChecklist]);

  const verifyPermissions = useCallback(async () => {
    try {
      const dataForm = formRef.current?.getData();

      setSaving(true);
      formRef.current?.setErrors({});

      formRef.current?.setErrors({});

      if (dataForm) {
        // Campos de desenho desabilitados por força de contrato. Se for necessário habilitá-los
        // basta remover estes comentários. No backend nada foi
        // alterado, portanto funcionando perfeitamente.

        /* Pode cadastrar um projeto sem desenho, mas se pelo menos um de drawings estiver
         * preenchido todos os outros campos de drawing deveram ser preenchidos.
         * Filtra os items que tenham pelo menos um campo preenchido, para depois validar no Yup.
         */
        /*   const newDrawings = dataForm.drawings.filter((item: any) => {
          const valuesItem = Object.values(item);
          return valuesItem.some(elem => elem !== '' && elem !== undefined);
        });
        */

        const newDataForm = {
          ...dataForm,
          // Campos de desenho desabilitados por força de contrato. Se for necessário habilitá-los
          // drawings: newDrawings,
        };
        const schema = Yup.object().shape({
          clientId: Yup.string().required('O cliente é obrigatório'),
          nameResponsible: Yup.string().required(
            'O responsável pelo projeto é obrigatório',
          ),
          title: Yup.string().required('O título do projeto é obrigatório'),
          description: Yup.string().required(
            'A descrição do projeto é obrigatória',
          ),
          quantity: Yup.string().required('A quantidade é obrigatória'),

          // Campos de desenho desabilitados por força de contrato. Se for necessário habilitá-los
          /*
          // Só valida se o array tiver pelo menos um item
          drawings:
            newDrawings.length > 0
              ? Yup.array().of(
                  Yup.object().shape({
                    drawingName: Yup.string().required('Nome é obrigatório'),
                    drawingNumber: Yup.string().required(
                      'Número é obrigatório',
                    ),
                    revisionNumber: Yup.string().required(
                      'Revisão obrigatório',
                    ),
                    revisionDate: Yup.string()
                      .test('revisionDate', 'Data inválida', (revDate: any) => {
                        const validateDate = parse(
                          revDate,
                          'dd/MM/yyyy',
                          new Date(),
                        );
                        return isValid(validateDate);
                      })
                      .required('Data obrigatória'),

                    files: Yup.mixed().when('id', {
                      is: '',
                      then: Yup.mixed().required('Arquivo é obrigatório'),
                    }),
                  }),
                )
              : Yup.array().of(Yup.object().shape({})),
              */
          currentSector: Yup.string().required(
            'O setor que receberá o projeto é obrigatório',
          ),
        });

        await schema.validate(newDataForm, { abortEarly: false });

        handleOpenModalChecklist();
      }
    } catch (error: any) {
      setSaving(false);

      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formRef.current?.setErrors(errors);

        return;
      }

      addToast({
        type: 'error',
        title: 'Error',
        description: `${error}`,
      });
    }
  }, [addToast, handleOpenModalChecklist]);

  // handleFormSubmit
  const handleFormSubmit = useCallback(
    async (dataForm: any, { reset }) => {
      try {
        setSaving(true);

        formRef.current?.setErrors({});

        // Campos de desenho desabilitados por força de contrato. Se for necessário habilitá-los

        /* Pode cadastrar um projeto sem desenho, mas se pelo menos um de drawings estiver
         * preenchido todos os outros campos de drawing deveram ser preenchidos.
         * Filtra os items que tenham pelo menos um campo preenchido, para depois validar no Yup.
         */
        /*
        const newDrawings = dataForm.drawings.filter((item: any) => {
          const valuesItem = Object.values(item);
          return valuesItem.some(elem => elem !== '' && elem !== undefined);
        });
      */

        /*
         *projectHasChecklist chega como array de objetos [{check1: true},{check14: false}]
         *Abaixo filtra apenas os true, depois no map retira a string 'check' e converte para
         * inteiro o número que é o id do item checklist no banco
         */
        // projectHasChecklist
        const onlyItemsWithTrueChecklist = dataForm.projectHasChecklist.filter(
          // eslint-disable-next-line consistent-return
          (itemProjectHasChecklist: any) => {
            if (Object.values(itemProjectHasChecklist)[0]) {
              const idOfCheckInString = Object.keys(
                itemProjectHasChecklist,
              )[0].replace('check', '');
              return parseInt(idOfCheckInString, 10);
            }
          },
        );

        const itemsConvertedInIntChecklist = onlyItemsWithTrueChecklist.map(
          (itemProjectHasChecklist: any) => {
            const idOfCheckInString = Object.keys(
              itemProjectHasChecklist,
            )[0].replace('check', '');
            return parseInt(idOfCheckInString, 10);
          },
        );

        const newDataForm = {
          ...dataForm,
          // Campos de desenho desabilitados por força de contrato. Se for necessário habilitá-los
          // drawings: newDrawings,
          projectHasChecklist: [...itemsConvertedInIntChecklist],
        };

        const config = {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress(progressEvent: any) {
            // Indica a porcentagem já enviada
            setPercentCompleted(
              Math.round((progressEvent.loaded * 100) / progressEvent.total),
            );
          },
        };

        const formData = new FormData();

        formData.append('id', `${parseInt(id, 10)}`);
        formData.append('clientId', `${newDataForm.clientId}`);
        formData.append('nameResponsible', newDataForm.nameResponsible);
        formData.append('title', newDataForm.title);
        formData.append('description', newDataForm.description);
        formData.append('quantity', `${newDataForm.quantity}`);

        // Campos de desenho desabilitados por força de contrato. Se for necessário habilitá-los
        /*
        if (newDataForm.drawings.length > 0) {
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < newDataForm.drawings.length; i++) {
            formData.append('idDrawing[]', `${newDataForm.drawings[i].id}`);
            formData.append(
              'drawingName[]',
              newDataForm.drawings[i].drawingName,
            );
            formData.append(
              'drawingNumber[]',
              newDataForm.drawings[i].drawingNumber,
            );
            formData.append(
              'revisionNumber[]',
              newDataForm.drawings[i].revisionNumber,
            );
            formData.append(
              'revisionDate[]',
              convertDateToDB(newDataForm.drawings[i].revisionDate),
            );

            formData.append('files', newDataForm.drawings[i].files);
          }
        }
        */

        if (newDataForm.projectHasChecklist.length > 0) {
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < newDataForm.projectHasChecklist.length; i++) {
            formData.append(
              'projectHasChecklist[]',
              `${newDataForm.projectHasChecklist[i]}`,
            );
          }
        } else {
          formData.append('projectHasChecklist[]', ``);
        }

        formData.append('requirementProduct', newDataForm.requirementProduct);
        formData.append('requirementClient', newDataForm.requirementClient);
        formData.append('requirementLegal', newDataForm.requirementLegal);
        formData.append(
          'forecastEndDate',
          convertDateToDB(newDataForm.forecastEndDate),
        );
        formData.append('observation', newDataForm.observation);
        formData.append('currentSector', newDataForm.currentSector);

        await api.put('commercial', formData, config);

        addToast({
          type: 'success',
          title: 'Cadastro realizado com sucesso',
        });

        reset();
        setPercentCompleted(0);
        setSaving(false);

        navigate('/commercial');
        handleOpenModalChecklist();
      } catch (error: any) {
        handleOpenModalChecklist();
        setPercentCompleted(0);
        setSaving(false);

        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);

          return;
        }

        if (error.response) {
          const { data } = error.response; // Error vindo do back está em data dentro de response

          addToast({
            type: 'error',
            title: 'Erro no cadastro',
            description: data.message,
          });
          // if (
          //   formRef.current?.getFieldRef(data.field) &&
          //   data.field.toString().indexOf('_id') === -1
          // ) {
          //   formRef.current?.getFieldRef(data.field).focus(); // Foca o campo que deu erro de acordo com o field retornado do back
          // }

          return;
        }

        addToast({
          type: 'error',
          title: 'Error',
          description: 'Erro ao cadastrar!',
        });
      }
    },
    [addToast, handleOpenModalChecklist, navigate, id],
  );

  const handleClearErrorDrawingDeleted = useCallback((index: number) => {
    formRef.current?.setFieldError(`drawings[${index}].drawingName`, '');
    formRef.current?.setFieldError(`drawings[${index}].drawingNumber`, '');
    formRef.current?.setFieldError(`drawings[${index}].revisionNumber`, '');
    formRef.current?.setFieldError(`drawings[${index}].revisionDate`, '');
  }, []);

  const handleInputChangeDrawing = useCallback(
    // eslint-disable-next-line consistent-return
    (inputValue: any, index: number) => {
      handleClearErrorDrawingDeleted(index);
      if (formRef.current) {
        if (inputValue && inputValue.id) {
          formRef.current.setFieldValue(`drawings[${index}].id`, inputValue.id);
          formRef.current.setFieldValue(
            `drawings[${index}].fileName`,
            inputValue.fileName,
          );
          formRef.current.setFieldValue(
            `drawings[${index}].drawingName`,
            inputValue.drawingName,
          );
          formRef.current.setFieldValue(
            `drawings[${index}].drawingNumber`,
            inputValue.drawingNumber,
          );
          formRef.current.setFieldValue(
            `drawings[${index}].revisionNumber`,
            inputValue.revisionNumber,
          );
          formRef.current.setFieldValue(
            `drawings[${index}].revisionDate`,
            convertDateToBr(inputValue.revisionDate),
          );

          setListDrawingsTheProject(formRef.current?.getData().drawings);

          // setDrawingFileSelected(inputValue.fileName);
        } else {
          formRef.current.setFieldValue(`drawings[${index}].id`, '');

          formRef.current.setFieldValue(
            `drawings[${index}].revisionNumber`,
            '',
          );
          formRef.current.setFieldValue(`drawings[${index}].revisionDate`, '');

          setListDrawingsTheProject(formRef.current?.getData().drawings);
        }
      }
    },
    [handleClearErrorDrawingDeleted],
  );

  const handleOpenFile = useCallback(
    (index: number, nameButtonClicked: string) => {
      setLoadingFile(true);
      setButtonClicked(nameButtonClicked);
      if (
        formRef.current &&
        formRef.current.getFieldValue(`drawings[${index}].fileName`)
      ) {
        const fileName = formRef.current.getFieldValue(
          `drawings[${index}].fileName`,
        );

        const extensionFile = fileName.substring(fileName.lastIndexOf('.') + 1);

        let typeBlob = '';

        switch (extensionFile) {
          case 'pdf':
            typeBlob = `application/${extensionFile}`;
            break;
          case 'tiff':
            typeBlob = `image/${extensionFile}`;
            break;
          default:
            typeBlob = `image/${extensionFile}`;
        }
        api
          .get(`drawings/file/${fileName}`, {
            responseType: 'arraybuffer',
          })
          .then((response: any) => {
            const url = window.URL.createObjectURL(
              new Blob([response.data], { type: typeBlob }),
            );

            window.open(url);
            setLoadingFile(false);
            setButtonClicked('');
          })
          .catch(error => {
            setLoadingFile(false);
            setButtonClicked('');

            if (error.response.data) {
              const { data } = error.response; // Error vindo do back está em data dentro de response
              addToast({
                type: 'error',
                title: data.message,
              });
              return;
            }

            addToast({
              type: 'error',
              title: 'Erro ao Exibir!',
              description: `Error: ${error}`,
            });
          });
      }
    },

    [addToast],
  );

  const addNewDrawing = useCallback(() => {
    const formAll = formRef.current?.getData();
    const newListDrawingsTheProject = [...formAll?.drawings];
    setListDrawingsTheProject(() => [
      ...newListDrawingsTheProject,
      {} as ICommercialForm,
    ]);
  }, []);

  useEffect(() => {
    // Seta os campos a cada mudança na lista de drawings
    // eslint-disable-next-line array-callback-return
    listDrawingsTheProject.map((item: any, i) => {
      formRef.current?.setFieldValue(`drawings[${i}].id`, item.id);
      formRef.current?.setFieldValue(`drawings[${i}].fileName`, item.fileName);
      formRef.current?.setFieldValue(
        `drawings[${i}].drawingName`,
        item.drawingName,
      );
      formRef.current?.setFieldValue(
        `drawings[${i}].drawingNumber`,
        item.drawingNumber,
      );
      formRef.current?.setFieldValue(
        `drawings[${i}].revisionNumber`,
        item.revisionNumber,
      );
      formRef.current?.setFieldValue(
        `drawings[${i}].revisionDate`,
        item.revisionDate,
      );
      if (item.files && item.files !== undefined) {
        formRef.current?.setFieldValue(`drawings[${i}].files`, item.files);
      } else {
        formRef.current?.clearField(`drawings[${i}].files`);
      }
    });
  }, [listDrawingsTheProject]);

  const handleRemoveDrawing = useCallback(
    (index: number) => {
      handleClearErrorDrawingDeleted(index);

      if (formRef.current && listDrawingsTheProject.length > 1) {
        const formAll = formRef.current.getData();
        const newListDrawingsTheProject = [...formAll.drawings];

        newListDrawingsTheProject.splice(index, 1);

        setListDrawingsTheProject([...newListDrawingsTheProject]);
      } else {
        formRef.current?.clearField(`drawings[0].id`);
        formRef.current?.clearField(`drawings[0].fileName`);
        formRef.current?.clearField(`drawings[0].drawingName`);
        formRef.current?.clearField(`drawings[0].drawingNumber`);
        formRef.current?.clearField(`drawings[0].revisionNumber`);
        formRef.current?.clearField(`drawings[0].revisionDate`);
        formRef.current?.clearField(`drawings[0].files`);

        setListDrawingsTheProject([{} as ICommercialForm]);
      }
    },
    [handleClearErrorDrawingDeleted, listDrawingsTheProject.length],
  );

  const [openModalFindDrawing, setOpenModalFindDrawing] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const handleOpenModalFindDrawing = useCallback(
    (selectedIndexProp: number) => {
      setOpenModalFindDrawing(!openModalFindDrawing);
      setSelectedIndex(selectedIndexProp);
    },
    [openModalFindDrawing],
  );

  const [divClicked, setDivClicked] = useState('HeadOpenProject');

  const handleDivClicked = useCallback(idDivClicked => {
    setDivClicked(oldValue => {
      return idDivClicked === oldValue ? '' : idDivClicked;
    });
  }, []);

  return (
    <Container>
      <ModalLoading visible={loading}>
        <p>
          <i>Carregando os dados do Comercial...</i>
        </p>
      </ModalLoading>
      {typy(project.id).isNumber && (
        <Content>
          <Form ref={formRef} onSubmit={handleFormSubmit}>
            <HeadOpenProject
              id="title_open_project"
              showInformation={divClicked === 'HeadOpenProject'}
            >
              <header>
                <p>Comercial</p>
                <span>Nº do Projeto: {id}</span>
              </header>

              <HeadProject
                listClients={listClients}
                showInformationInMobile
                readOnlyProp={finishedProposal}
              />
            </HeadOpenProject>
            <Tabs
              tabLinksProp={[
                'Comercial',
                'Planejamento',
                'Compras',
                'Produção',
              ]}
              tabDefaultIndex={0}
            >
              {/* Comercial */}
              <div>
                <TechnicalDrawings
                  id="title_technical_drawings"
                  showInformation={divClicked === 'TechnicalDrawings'}
                >
                  <SectionTitle
                    onClick={() => handleDivClicked('TechnicalDrawings')}
                  >
                    <a href="#title_technical_drawings">Desenho Técnico</a>
                  </SectionTitle>
                  <SplitTechnicalDrawings
                    showInformation={divClicked === 'TechnicalDrawings'}
                  >
                    {listDrawingsTheProject.map((item, index) => (
                      <Scope path={`drawings[${index}]`} key={String(index)}>
                        <SplitDrawing>
                          <InputHidden name="id" />
                          <InputHidden name="fileName" />
                          <Input
                            id="drawingName"
                            name="drawingName"
                            labelText={index === 0 ? 'Nome*' : ''}
                            placeholder=""
                            maxLength={45}
                            autoComplete="off"
                            readOnly={
                              finishedProposal || !!(item.id && item.id !== '')
                            }
                            disabled
                          />

                          <Input
                            id="drawingNumber"
                            name="drawingNumber"
                            labelText={index === 0 ? 'Número*' : ''}
                            placeholder=""
                            autoComplete="off"
                            maxLength={45}
                            readOnly={
                              finishedProposal || !!(item.id && item.id !== '')
                            }
                            disabled
                          />

                          <RevisionNumberAndRevisionDateAndFile>
                            <Input
                              id="revisionNumber"
                              name="revisionNumber"
                              type="text"
                              labelText={index === 0 ? 'Rev.*' : ''}
                              autoComplete="off"
                              readOnly={
                                finishedProposal ||
                                !!(item.id && item.id !== '')
                              }
                              disabled
                            />
                            <InputMask
                              id="revisionDate"
                              name="revisionDate"
                              labelText={index === 0 ? 'Data da Rev.*' : ''}
                              mask="99/99/9999"
                              readOnly={
                                finishedProposal ||
                                !!(item.id && item.id !== '')
                              }
                              disabled
                            />

                            {(item.id && item.id !== '' && (
                              <div>
                                {(loadingFile &&
                                  buttonClicked === `openFile${index}` && (
                                    <Button
                                      name="buttonSpinner"
                                      type="button"
                                      onClick={e => {
                                        e.preventDefault();
                                      }}
                                    >
                                      <Loading size={24} color="white" />
                                    </Button>
                                  )) || (
                                  <>
                                    <Button
                                      name={`openFile${index}`}
                                      onClick={() =>
                                        handleOpenFile(
                                          index,
                                          `openFile${index}`,
                                        )
                                      }
                                    >
                                      Exibir
                                    </Button>
                                    <div>
                                      {!finishedProposal && (
                                        <>
                                          {/*
                                  Botão de desenho desabilitado por força de contrato. Se for necessário habilitá-los
                                  basta remover este comentários e apagar o botão falso logo abaixo. No backend nada foi
                                  alterado, portanto funcionando perfeitamente.
                                   <FiTrash2
                                          size={20}
                                          onClick={() =>
                                            handleRemoveDrawing(index)
                                          }
                                        />
                                    */}
                                          <FiTrash2 size={20} />
                                        </>
                                      )}
                                    </div>
                                  </>
                                )}
                              </div>
                            )) || (
                              <>
                                {(!finishedProposal && (
                                  <>
                                    {/*
                                  Botão de desenho desabilitado por força de contrato. Se for necessário habilitá-los
                                  basta remover este comentários e apagar o botão falso logo abaixo. No backend nada foi
                                  alterado, portanto funcionando perfeitamente.
                                   <InputFileButton
                                    id={`files${index}`}
                                    name="files"
                                    indexProp={index}
                                    handleOpenModalFindDrawing={
                                      handleOpenModalFindDrawing
                                    }
                                    handleRemoveDrawing={handleRemoveDrawing}
                                  />
                                    */}
                                    <Button
                                      style={{
                                        width: '11.7rem',
                                        fontStyle: 'normal',
                                        fontWeight: 'bold',
                                        fontSize: '1.4rem',
                                        background: 'var(--azul-5bits)',
                                      }}
                                      disabled
                                    >
                                      Carregar...
                                    </Button>
                                  </>
                                )) || (
                                  <ButtonAndIconFakes>
                                    {/*
                                  Botão de desenho desabilitado por força de contrato. Se for necessário habilitá-los
                                  basta remover este comentários e apagar o botão falso logo abaixo. No backend nada foi
                                  alterado, portanto funcionando perfeitamente.
                                  <Button
                                      id="btLoadNewDrawingFake"
                                      name="btLoadNewDrawingFake"
                                      type="button"
                                      style={{
                                        background: 'var(--cinza-claro-5bits)',
                                      }}
                                      disabled
                                    >
                                      Carregar...
                                    </Button>
                                    <div>
                                      <BsSearch
                                        size={20}
                                        color="var(--cinza-claro-5bits)"
                                      />
                                    </div>
                                    */}
                                    <Button
                                      style={{
                                        width: '11.7rem',
                                        fontStyle: 'normal',
                                        fontWeight: 'bold',
                                        fontSize: '1.4rem',
                                        background: 'var(--azul-5bits)',
                                      }}
                                      disabled
                                    >
                                      Carregar...
                                    </Button>
                                  </ButtonAndIconFakes>
                                )}
                              </>
                            )}
                          </RevisionNumberAndRevisionDateAndFile>
                        </SplitDrawing>
                      </Scope>
                    ))}

                    {!finishedProposal && (
                      <>
                        {/*
                      Botão de desenho desabilitado por força de contrato. Se for necessário habilitá-los
                      basta remover este comentários e apagar o botão falso logo abaixo. No backend nada foi
                      alterado, portanto funcionando perfeitamente.
                      <Button
                        name="newDrawing"
                        type="button"
                        onClick={e => {
                          e.preventDefault();
                          addNewDrawing();
                        }}
                      >
                        Mais desenho
                      </Button>
                      */}
                        <Button name="newDrawing" type="button" disabled>
                          Mais desenho
                        </Button>
                      </>
                    )}
                  </SplitTechnicalDrawings>
                </TechnicalDrawings>

                <SplitFooter
                  id="title_split_footer"
                  showInformation={divClicked === 'SplitFooter'}
                >
                  <SectionTitle onClick={() => handleDivClicked('SplitFooter')}>
                    <a href="#title_split_footer">Informações Adicionais</a>
                  </SectionTitle>

                  <SplitFooterContent
                    showInformation={divClicked === 'SplitFooter'}
                  >
                    <TextArea
                      id="requirementProduct"
                      name="requirementProduct"
                      label="Requerimento dos produtos"
                      disabled={finishedProposal || saving}
                    />
                    <TextArea
                      id="requirementClient"
                      name="requirementClient"
                      label="Requerimento do cliente"
                      disabled={finishedProposal || saving}
                    />
                    <TextArea
                      id="requirementLegal"
                      name="requirementLegal"
                      label="Requerimentos legais"
                      disabled={finishedProposal || saving}
                    />
                    <TextArea
                      id="observation"
                      name="observation"
                      label="Observações gerais"
                      disabled={finishedProposal || saving}
                    />
                  </SplitFooterContent>
                </SplitFooter>
              </div>

              {/* Planejamento */}
              <div>
                <BodyPlanning
                  formRef={formRef}
                  listMaterialsProp={listMaterials}
                  listServicesProp={listServices}
                  listUsersProp={listUsers}
                  listUpdatedByServiceProp={listManufacturingSteps}
                  projectProp={project}
                  disableTabProp
                  // eslint-disable-next-line @typescript-eslint/no-empty-function
                  showModalConfirmationOfExclusionMaterial={() => {}}
                  // eslint-disable-next-line @typescript-eslint/no-empty-function
                  showModalConfirmationOfExclusionService={() => {}}
                />
              </div>
              {/* Compras */}
              <div>
                <BodyPurchasing
                  formRef={formRef}
                  listMaterialsProp={listMaterials}
                  listProvidersProp={listProviders}
                  // listUpdatedByServiceProp={listManufacturingSteps}
                  projectProp={project}
                  disableTabProp
                />
              </div>
              {/* Produção */}
              <div>
                <BodyManufacturing
                  formRef={formRef}
                  projectProp={project}
                  listManufacturingStepsProp={
                    listManufacturingSteps !== undefined
                      ? listManufacturingSteps
                      : []
                  }
                  disableTabProp
                />
              </div>
            </Tabs>

            <ChecklistProject
              listChecklistProp={listChecklist}
              loadingChecklistProp={loadingChecklist}
              projectHasChecklist={project.projectHasChecklist}
            />

            <SelectSectorAndButtonSave>
              <SimpleSelect
                id="currentSector"
                name="currentSector"
                labelText="Enviar projeto para o setor*:"
                placeholder=""
                options={currentSectorList.filter(
                  item => item.value !== 'Produção',
                )}
                getOptionLabel={option => option.value}
                menuPlacement="auto"
                isDisabled={saving}
                disabled={saving}
                // isDisabled={finishedProposal || saving}
                // disabled={finishedProposal || saving}
              />
              <div>
                {/* {!finishedProposal && ( */}
                <Button
                  type="button"
                  onClick={verifyPermissions}
                  disabled={saving}
                >
                  {saving ? <Loading size={24} color="white" /> : 'Salvar'}
                </Button>
                {/* )} */}
              </div>
            </SelectSectorAndButtonSave>

            <ModalChecklist
              saving={saving}
              loadModalProp={openModalChecklist}
              listChecklistProp={listChecklist}
              projectHasChecklist={project.projectHasChecklist}
              closeModalChecklist={handleOpenModalChecklist}
            />
          </Form>

          <ModalFindDrawing
            indexProp={selectedIndex}
            loadModalProp={openModalFindDrawing}
            handleInputChangeDrawingModal={handleInputChangeDrawing}
            closeAndClearModalFindDrawing={handleOpenModalFindDrawing}
          />
        </Content>
      )}
    </Container>
  );
};

export default UpdateProject;
