import styled from 'styled-components';

export const SplitManufacturingSteps = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  padding-top: 3.125rem;
  padding-bottom: 3.125rem;
  border-bottom: 0.1rem solid var(--cinza-claro-5bits);

  > section {
    margin-top: 3.8rem;
  }
`;

export const HeaderAndGrandTotal = styled.div`
  display: flex;
  justify-content: space-between;

  width: 100%;

  cursor: pointer;
  :hover {
    background: var(--branco-gelo-5bits);
  }

  > span {
    display: flex;
    justify-content: center;
    align-items: center;

    width: fit-content;

    font-style: normal;
    font-weight: 500;
    font-size: 2.4rem;
    line-height: 3.2rem;
    text-transform: none;

    color: var(--cinza-escuro-5bits);

    > svg {
      margin-left: 1rem;
    }
  }
`;

export const SplitManufacturingStepItem = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-end;
  gap: 1.4rem;

  width: 100%;
  margin-bottom: 1.7rem;

  > div {
    /* width: 60%; */
    label {
      margin-left: 1rem;
    }
  }

  /* Nome */
  > div:nth-child(2) {
    flex: 2;
    min-width: 50%;
  }
`;

export const ForecastTimeAndForecastDays = styled.div`
  flex: 1;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-end;
  gap: 1.4rem;

  min-width: 30rem;
  > div {
    flex: 1;
    min-width: 15rem;
  }

  //Medias
  @media (min-width: 0px) and (max-width: 375px) {
    flex-wrap: wrap;
    min-width: auto;
  }
`;
