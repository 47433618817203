function convertInitToUppercase(text: string): string {
  let textConverted = '';
  if (text !== '') {
    // remove duble space
    const textFormatted = text.trim().replace(/\s{2,}/g, ' ');

    const loweredText = textFormatted.toLowerCase();
    const words = loweredText.split(' ');
    for (let a = 0; a < words.length; a += 1) {
      let w = words[a];

      const firstLetter = w[0];

      // Only text > 2 char
      if (w.length > 2) {
        w = firstLetter.toUpperCase() + w.slice(1);
      } else {
        w =
          a === 0
            ? firstLetter.toUpperCase() + w.slice(1)
            : firstLetter + w.slice(1);
      }

      words[a] = w;
    }
    textConverted = words.join(' ');
  }
  return textConverted;
}

export default convertInitToUppercase;
