import styled, { css, keyframes } from 'styled-components';

interface ISearchAreaProps {
  showSearchArea: boolean;
}

export const Container = styled.div`
  height: 90%;
`;

export const Content = styled.div`
  margin: 20px 70px;
  height: 97%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  header {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: space-between;
    margin-bottom: 3.6rem;

    width: 100%;
    height: 7.6rem;

    p {
      display: flex;
      font-weight: 500;
      font-size: 2.4rem;
    }
  }

  > p {
    align-self: center;
    height: 50%;
  }

  table {
    background: var(--branco-puro-5bits);
    border: 1px solid var(--cinza-claro-5bits);
    border-radius: 4px;
    border-spacing: 0;

    font-size: 1.4rem;

    width: 100%;
    min-width: 100%;
    /* margin: 0 auto; */

    thead th {
      padding: 10px 8px;
    }

    tbody {
      color: var(--cinza-escuro-5bits);

      tr:nth-child(odd) {
        background-color: var(--branco-gelo-5bits);
      }

      tr:hover {
        background-color: #f0f0f0;
      }

      tr {
        td {
          padding: 5px 8px;
        }
      }
    }
  }
`;

const upKeyFrame = keyframes`
  from {
    opacity: 0;
    transform: translateY(1.5rem);
  }

  to {
    opacity: 1;
  }
`;

export const ButtonFilterAndInputSearchWithButton = styled.div<ISearchAreaProps>`
  display: flex;

  /* Button filter */
  > button {
    margin-right: 2rem;

    svg {
      margin-right: 2rem;
    }

    ${props =>
      (props.showSearchArea &&
        css`
          background: var(--cinza-claro-5bits);
        `) ||
      css`
        background: var(--azul-5bits);
      `}

    height: 45px;
    border-radius: 25px;
    border: 0;
    padding: 0 22.5px;
    color: var(--button-text-color);
    width: 100%;
    font-weight: 700;
    line-height: 2.4rem;
    text-align: center;
    text-transform: capitalize;
    transition: background-color 0.2s;
    display: flex;
    justify-content: center;
    align-items: center;

    width: 12.2rem;
    height: 3.2rem;
    font-size: 1.4rem;

    &:hover {
      filter: brightness(0.9);
    }
  }
  > form {
    //InputButton Search
    > div:last-child {
      width: 38rem;

      button {
        background: var(--azul-5bits);
        font-family: Red Hat Display;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 19px;
        text-transform: capitalize;

        color: var(--branco-puro-5bits);
      }
    }
  }
`;

export const SearchArea = styled.div<ISearchAreaProps>`
  ${props =>
    (props.showSearchArea &&
      css`
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: flex-start;
        width: 100%;
      `) ||
    css`
      display: none;
    `}
  animation: ${upKeyFrame} 1s;

  form {
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: top;

    width: 100%;
  }
`;

export const FilterSearchArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  margin-top: 2.5rem;
`;

export const CheckListArea = styled.div`
  display: flex;
  align-self: center;

  width: fit-content;

  //Checkbox
  > div {
    margin-right: 2.5rem;
    label {
      font-family: Red Hat Display;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 19px;

      color: var(--cinza-escuro-5bits);

      > span {
        background: var(--branco-puro-5bits);
      }
    }

    label[data-position='left'] {
      padding-left: 3rem;
    }
  }
`;

export const ContentChildren = styled.div`
  overflow: auto;
  height: auto;
  max-height: 55vh;
`;
