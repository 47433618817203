import styled, { css } from 'styled-components';

interface SpanProps {
  colorBefore: string;
  colorSpan: string;
  spanLeft: string;
  beforeLeft: string;
  widthSpanWithContent: string;
  tooltipReversed: boolean;
}

export const Container = styled.div<SpanProps>`
  position: relative;
  right: 0;
  width: 100%;
  bottom: calc(100% + -25px);

  > span {
    opacity: 0;
    transition: opacity 0.4s;
    visibility: hidden;

    width: ${props => props.widthSpanWithContent};
    background: ${props => props.colorSpan || 'var(--laranja-5bits)'};
    padding: 8px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    z-index: 1998;
    text-align: center;

    position: absolute;

    ${props =>
      (props.tooltipReversed &&
        css`
          top: calc(100% + 8px);
        `) ||
      css`
        bottom: calc(100% + 8px);
      `};

    left: ${props => props.spanLeft};
    transform: translateX(-50%);

    color: var(--message-tooltip-span-text-color);

    &::before {
      content: '';

      border-color: ${props => props.colorBefore || 'var(--laranja-5bits)'};

      border-style: solid;
      border-width: 0px 6px 6px 0px;
      position: absolute;
      padding: 3px;

      ${props =>
        (props.tooltipReversed &&
          css`
            bottom: 100%;
            right: ${props.beforeLeft};
            transform: rotate(225deg) translateX(-76%);
          `) ||
        css`
          top: 100%;
          left: ${props.beforeLeft};
          transform: rotate(45deg) translateX(-76%);
        `};
    }
  }

  &:hover span {
    opacity: 1;
    visibility: visible;
  }
`;
