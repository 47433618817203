import styled, { css } from 'styled-components';

interface IContainerCompanyStage {
  colorHRBlue: boolean;
}

interface IHasMaskOnTheContainer {
  hasMaskOnTheContainer: boolean;
}

interface IMaskOfTheCardsUnselected {
  displayMask?: boolean;
  dimensions: {
    hasScrollHorizontal: boolean;
    hasScrollVertical: boolean;
    width: string;
    height: string;
  };
}

export const Container = styled.div`
  position: relative;

  height: 85vh;
  max-height: 85vh;

  /*100vh - header - margin-top - area botão voltar - h1 com margin bottom somada - margin-bottom - footer */
  /* height: calc(100vh - 81px - 20px - 33px - 59px - 6px - 29px); */
  /*
   */
  width: 100vw;

  overflow: auto;

  ::-webkit-scrollbar {
    width: 8px;
    margin-left: 6px;
  }
`;

export const NameSectorAndLegend = styled.div<IHasMaskOnTheContainer>`
  position: sticky;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  top: 0vh;
  left: 0vw;
  z-index: 103;

  padding-top: 4.7rem;

  padding-bottom: 2.2rem;

  /* width: calc(100% + 1.8rem); */
  span {
    text-align: center;
    text-transform: capitalize;
    font-style: normal;
    font-weight: 500;
    font-size: 2.4rem;
    line-height: 3.2rem;
  }
  > div {
    position: absolute;
    right: 0vw;
  }

  transition: background-color 1s;

  ${props =>
    (props.hasMaskOnTheContainer &&
      css`
        background: var(--preto-igual-a-rgba);
      `) ||
    css`
      background: var(--branco-gelo-5bits);
    `}
`;

export const MaskOfTheCardsUnselected = styled.div<IMaskOfTheCardsUnselected>`
  transition: opacity 1s;

  ${props =>
    (props.displayMask &&
      css`
        opacity: 1;
        pointer-events: visiblePainted;
      `) ||
    css`
      opacity: 0;
      pointer-events: none;
    `};

  position: absolute;

  z-index: 100;

  background: var(--preto-5bits-rgba);

  ${props =>
    ((props.dimensions.hasScrollHorizontal ||
      props.dimensions.hasScrollVertical) &&
      css`
        width: ${props.dimensions.width};
        height: ${props.dimensions.height};
      `) ||
    css`
      width: 100%;
      height: 100%;
    `}
`;

export const Content = styled.div`
  padding-right: 1.7rem;
`;

export const ContainerProductionStages = styled.div`
  display: flex;
  flex-direction: column;

  width: fit-content;

  position: relative;
`;

export const ContainerTitleStages = styled.div`
  display: flex;
  > div:last-child {
    border-right: none;
  }
`;

export const ContainerTitleAndContainerOSOnStage = styled.div`
  border-right: 1px solid rgba(171, 171, 171, 0.5);
`;

export const TitleStage = styled.div<IHasMaskOnTheContainer>`
  position: sticky;
  top: 13vh;
  z-index: 103;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 32.4rem;
  min-width: 32.4rem;
  max-width: 32.4rem;

  min-height: 7.6rem;
  height: 7.6rem;
  max-height: 7.6rem;

  margin-bottom: 0.2rem;

  transition: background-color 1s;

  ${props =>
    (props.hasMaskOnTheContainer &&
      css`
        background: var(--preto-igual-a-rgba);
      `) ||
    css`
      background: var(--branco-gelo-5bits);
    `}

  > span {
    width: 100%;
    align-items: flex-start;

    padding: 0 2rem;

    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    color: var(--laranja-5bits);
  }
`;

export const ContainerCompanyStage = styled.div<IContainerCompanyStage>`
  display: flex;
  position: relative;

  /* Pega a largura do conteúdo interno */
  width: fit-content;

  margin-right: 4rem;

  hr {
    z-index: -1;
    position: absolute;
    top: 6.64vh;
    width: 97%;
    ${props =>
      (props.colorHRBlue &&
        css`
          border: 1px solid var(--azul-5bits);
        `) ||
      css`
        border: 1px solid var(--laranja-5bits);
      `}

    margin: 0 2rem;
  }
`;

export const ContainerOSOnStageEmpty = styled.div`
  min-width: 264px;
  max-width: 264px;
  min-height: 112px;
  max-height: 268px;
  padding: 0 2rem 1rem 2rem;
  border-right: 1px solid rgba(171, 171, 171, 0.5);
`;
