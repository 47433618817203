import styled, { css, keyframes } from 'styled-components';

interface IContainerProps {
  menuActiveProp: boolean;
}

const slide = keyframes`
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
    width: 100%;
  }
`;

const rotateRight = keyframes`
  0% {
    transform: rotate(-225deg);
  }
  100% {
    transform: rotate(-135deg);
  }
`;
const rotateDown = keyframes`
  0% {
    transform: rotate(-135deg);
  }
  100% {
    transform: rotate(-225deg);
  }
`;

export const Container = styled.div<IContainerProps>`
  position: relative;
  display: inline-block;

  border-radius: 2.5rem;

  background-color: transparent;
  margin: 0;
  margin-left: 1.1rem;

  text-decoration: none;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 2.1rem;
  color: var(--pages-link-text-color);

  .dropdown-header {
    display: flex;
    justify-content: start;
    align-content: center;

    //chevron and title menu
    > span:first-child {
      padding: 0.8rem 1.3rem;
      padding-right: 2rem;
    }

    //hover-mark
    > span:last-child {
      margin-top: 1.1rem;
      border: 2px solid var(--laranja-5bits);
      opacity: 0;
    }
  }

  .hover-mark {
    position: absolute;
    top: 22px;
    right: 0;
    width: 0;
    height: 1px;
  }
  &:hover {
    .hover-mark {
      animation: ${slide} 0.3s forwards;
    }
  }

  &.active {
    span.hover-mark {
      position: absolute;
      top: 22px;
      right: 0;
      width: 100%;
      height: 2px;
      animation: none;
    }
  }

  .dropdown-content {
    display: none;
    position: absolute;
    z-index: 1;
    width: 15.8rem;
    min-width: 15.8rem;

    > ul {
      background-color: var(--branco-gelo-5bits);

      border-top-left-radius: 0rem;
      border-top-right-radius: 0.4rem;
      border-bottom-right-radius: 0.4rem;
      border-bottom-left-radius: 0.4rem;

      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);

      li {
        list-style-type: none;

        a {
          display: block;
          padding: 12px 24px;

          &:hover {
            background-color: var(--branco-puro-5bits);
            cursor: pointer;
            color: var(--laranja-5bits);
          }
          &.active {
            /* font-weight: 700; */
            background-color: var(--branco-puro-5bits);
            color: var(--laranja-5bits);
          }
        }
      }
    }
  }

  &:hover .dropdown-content {
    display: block;
    left: 0;
    > ul {
      border-top-left-radius: 0rem;
      border-top-right-radius: 0.4rem;
      border-bottom-right-radius: 0.4rem;
      border-bottom-left-radius: 0.4rem;

      > li:first-child {
        a {
          border-radius: 0.4rem 0.4rem 0 0;
        }
      }

      li:last-child {
        a {
          border-radius: 0 0 0.4rem 0.4rem;
        }
      }
    }
  }

  .chevron::before {
    border-style: solid;
    border-width: 2px 2px 0 0;
    content: '';
    display: inline-block;
    width: 5px;
    height: 5px;
    position: relative;
    top: 7px;
    vertical-align: top;

    right: -7px;
    transform-origin: center;
    animation: ${rotateDown} 0.5s forwards;
  }

  &:hover {
    .chevron::before {
      animation: ${rotateRight} 0.5s forwards;
    }
  }

  //Medias
  @media (min-width: 0px) and (max-width: 759px) {
    width: 100%;
    margin: 0;

    .dropdown-header {
      justify-content: space-between;

      //chevron and title menu
      > span:first-child {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        padding: 0.8rem 1.6rem;
        padding-right: 2rem;
      }
    }

    .dropdown-content {
      display: none;
      position: relative;
      width: 100%;
      min-width: 100%;
      margin-bottom: 3rem;

      > ul {
        li {
          list-style-type: none;

          a {
            padding: 12px 40px;
          }
        }
      }
    }

    /*
      Desabilita o hover que abre o submenu no mobile
      No mobile será clique pois estava dando muito erros com o hover
    */
    .dropdown-content[disable]:hover {
      background-color: red;
    }

    .dropdown-content {
      ${props =>
        props.menuActiveProp &&
        css`
          display: block;
          left: 0;
          > ul {
            border-top-left-radius: 0rem;
            border-top-right-radius: 0.4rem;
            border-bottom-right-radius: 0.4rem;
            border-bottom-left-radius: 0.4rem;

            > li:first-child {
              a {
                border-radius: 0.4rem 0.4rem 0 0;
              }
            }

            li:last-child {
              a {
                border-radius: 0 0 0.4rem 0.4rem;
              }
            }
          }
        `}
    }
  }

  @media (min-width: 760px) and (max-width: 999px) {
  }
`;
