/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import fincalc from 'fincalc';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import typy from 'typy';
import api from '../../../../services/api';
import getProjectFormatted from '../../../AuxiliaryFunctions/GetProject';
import getValidationErrors from '../../../../utils/getValidationError';
import { useToast } from '../../../../context/ToastContext';

import Loading from '../../../../components/Loading';

import Button from '../../../../components/Button';
import SimpleSelect from '../../../../components/SimpleSelect';
import Tabs from '../../../../components/Tabs';

import currentSectorList from '../../../../data/currentSectorList.json';

import {
  Container,
  Content,
  SplitFooter,
  SelectSectorAndButtonSave,
} from './styles';

import HeadProject from '../../HeadProject';
import BodyCreateCommercial from '../../Planning/BodyCreateCommercial';
import BodyPlanning from '../../Planning/BodyPlanning';
import BodyManufacturing from '../../Manufacturing/Projects/BodyManufacturing';

import BodyPurchasing from '../BodyPurchasing';

import ModalChecklist from '../../componentsSectors/ModalChecklist/modalChecklist';
import ModalConfirmationFinishedQuote from '../components/ModalConfirmationFinishedQuote';
import convertNumberFloatToDB from '../../../../utils/convertNumberFloatToDB';
import ChecklistProject from '../../componentsSectors/ChecklistProject';

import { IMaterialResponse } from '../../../../interfaces/material';
import { IServiceResponse } from '../../../../interfaces/service';
import { IProviderResponse } from '../../../../interfaces/provider';
import { IChecklist } from '../../../../interfaces/checklist';
import { IProject } from '../../../../interfaces/project';
import ModalLoading from '../../../../components/ModalLoading';
import InputHidden from '../../../../components/InputHidden';
import { IClient, IClientResponse } from '../../../../interfaces/client';
import { IUserResponse } from '../../../../interfaces/user';

const CreatePurchasing: React.FC = () => {
  const { id }: any = useParams();
  const formRef = useRef<FormHandles>(null);
  const navigate = useNavigate();
  const { addToast } = useToast();

  const [project, setProject] = useState<IProject>({} as IProject);
  const [loading, setLoading] = useState<boolean>(false);

  const [saving, setSaving] = useState<boolean>(false);

  const [listUsers, setListUsers] = useState<
    Omit<IUserResponse, 'usersHasMenus'>[]
  >([]);
  const [listClients, setListClients] = useState<IClient[]>([]);
  const [listMaterials, setListMaterials] = useState<IMaterialResponse[]>([]);
  const [listServices, setListServices] = useState<IServiceResponse[]>([]);
  const [listManufacturingSteps, setListManufacturingSteps] = useState<any[]>(
    [],
  );

  const [listProviders, setListProviders] = useState<IProviderResponse[]>([]);

  const [listChecklist, setListChecklist] = useState<IChecklist[]>([]);

  const [loadingChecklist, setLoadingChecklist] = useState<boolean>(false);
  const [finishedProposal, setFinishedProposal] = useState<boolean>(false);

  // Get project
  const getProject = useCallback(async (): Promise<void> => {
    try {
      const projectResponse: any = await getProjectFormatted(id);

      setProject(projectResponse);
      setFinishedProposal(projectResponse.finishedProposal);

      // setListManufacturingSteps(projectResponse.projectHasManufacturingSteps);
      formRef.current?.setData(projectResponse);

      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      // eslint-disable-next-line no-shadow
      if (error.response) {
        const { data } = error.response; // Error vindo do back está em data dentro de response
        addToast({
          type: 'error',
          title: data.message,
        });
        return;
      }

      addToast({
        type: 'error',
        title: 'Ocorreu um erro interno',
        description: `${error}`,
      });
    }
  }, [addToast, id]);
  // End Get project

  // List Materials
  useEffect(() => {
    setLoading(true);
    const getMaterials = async (): Promise<void> => {
      try {
        // Users
        const responseUsers = await api.get<
          Omit<IUserResponse, 'usersHasMenus'>[]
        >('users');

        const responseUsersFormatted: any = responseUsers.data.map(i => ({
          ...i,
          value: i.id,
          label: i.name,
        }));

        setListUsers(responseUsersFormatted);

        // Clientes
        const responseClients = await api.get<IClientResponse[]>('clients');

        const responseClientsFormatted: any = responseClients.data.map(i => ({
          value: i.id,
          label: i.name,
        }));

        setListClients(responseClientsFormatted);

        // Materials
        const responseMaterials = await api.get<IMaterialResponse[]>(
          'materials/active',
        );

        const responseMaterialsFormatted: any = responseMaterials.data.map(
          i => ({
            ...i,
            value: i.id,
            label: i.name,
          }),
        );

        setListMaterials(responseMaterialsFormatted);
        // End Materials

        // Services
        // setLoadingServices(true);
        const responseServices = await api.get<IServiceResponse[]>(
          'services/active',
        );

        const responseServicesFormatted: any = responseServices.data.map(i => ({
          ...i,
          value: i.id,
          label: i.name,
        }));

        setListServices(responseServicesFormatted);
        // End Services

        // Providers
        const responseProviders = await api.get<IProviderResponse[]>(
          'providers/active',
        );

        const responseProvidersFormatted: any = responseProviders.data.map(
          i => ({
            ...i,
            value: i.id,
            label: i.fantasyName,
          }),
        );

        setListProviders(responseProvidersFormatted);
        // End Providers

        // Checklist
        const responseChecklist = await api.get<any[]>('checklist');

        setListChecklist(responseChecklist.data);
        // End checklist

        // Project
        getProject();

        // setLoadingMaterials(false);
      } catch (error: any) {
        setLoading(false);

        if (error.response && error.response.data) {
          const { data } = error.response; // Error vindo do back está em data dentro de response
          addToast({
            type: 'error',
            title: data.message,
          });
          return;
        }

        addToast({
          type: 'error',
          title: 'Error',
          description: 'Erro ao listar!',
        });
      }
    };
    getMaterials();
  }, [addToast, getProject]);
  // End List Materials

  const [openModalChecklist, setOpenModalChecklist] = useState(false);
  const handleOpenModalChecklist = useCallback(() => {
    setOpenModalChecklist(!openModalChecklist);
    setSaving(false);
  }, [openModalChecklist]);

  const [
    openModalConfirmationFinishedQuote,
    setOpenModalConfirmationFinishedQuote,
  ] = useState(false);

  const handleOpenModalConfirmationFinishedQuote = useCallback(() => {
    setOpenModalConfirmationFinishedQuote(!openModalConfirmationFinishedQuote);
    if (!openModalConfirmationFinishedQuote === false) {
      handleOpenModalChecklist();
    }
    setSaving(false);
  }, [handleOpenModalChecklist, openModalConfirmationFinishedQuote]);

  /**
   * Referência ao componente filho
   */
  const handleMaterialPurchasingComponentRef = useRef<any>();
  const getUpdateListCompleteAndUpdatedOfTheMaterialsPurchasing = useCallback((): Array<any> => {
    const projectHasMaterialsPurchasingFinal = handleMaterialPurchasingComponentRef.current.getUpdateListCompleteAndUpdatedOfTheMaterialsPurchasing();

    return projectHasMaterialsPurchasingFinal;
  }, []);

  const verifyPermissions = useCallback(async () => {
    try {
      const dataForm = formRef.current?.getData();

      const projectHasMaterialsPurchasingFinal = getUpdateListCompleteAndUpdatedOfTheMaterialsPurchasing();

      const dataFormCorrect: any = {
        ...dataForm,
        projectHasMaterialsPurchasing: [...projectHasMaterialsPurchasingFinal],
      };

      setSaving(true);
      formRef.current?.setErrors({});

      if (dataFormCorrect && Object.entries(dataFormCorrect).length > 0) {
        const schema = Yup.object().shape({
          projectHasMaterialsPurchasing: Yup.array().of(
            Yup.object().shape({
              unitPrice: Yup.string().required('Valor unitário obrigatório'),
              totalPrice: Yup.string().required('Valor total obrigatório'),
            }),
          ),
          currentSector: Yup.string().required(
            'O setor que receberá o projeto é obrigatório',
          ),
        });

        await schema.validate(dataFormCorrect, { abortEarly: false });

        // Para confirmar fim da cotação pelo menos um fornecedor deve estar preenchido nos materiais
        if (
          dataFormCorrect.currentSector !== 'Compras' &&
          dataFormCorrect.projectHasMaterialsPurchasing.some(
            (item: any) => item.providerId !== '',
          ) &&
          finishedProposal === false
        ) {
          handleOpenModalConfirmationFinishedQuote();
        } else {
          handleOpenModalChecklist();
        }
      }
    } catch (error: any) {
      setSaving(false);

      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formRef.current?.setErrors(errors);

        return;
      }

      addToast({
        type: 'error',
        title: 'Error',
        description: `${error}`,
      });
    }
  }, [
    addToast,
    finishedProposal,
    getUpdateListCompleteAndUpdatedOfTheMaterialsPurchasing,
    handleOpenModalChecklist,
    handleOpenModalConfirmationFinishedQuote,
  ]);

  // handleFormSubmit
  const handleFormSubmit = useCallback(
    async (dataForm: any, { reset }) => {
      try {
        setSaving(true);

        const projectHasMaterialsPurchasingFinal = getUpdateListCompleteAndUpdatedOfTheMaterialsPurchasing();

        const materialsCorrect = projectHasMaterialsPurchasingFinal.filter(
          (itemMaterials: any) => {
            return itemMaterials.materialId !== '';
          },
        );

        const materialsFormatted = materialsCorrect.map(
          (itemMaterials: any) => {
            const deliveryDays = parseInt(itemMaterials.deliveryDays, 10);
            const providerIdNumber = parseInt(itemMaterials.providerId, 10);

            const newItemMaterials = {
              id: parseInt(itemMaterials.id, 10),
              materialId: parseInt(itemMaterials.materialId, 10),
              providerId: providerIdNumber,
              unitPrice: convertNumberFloatToDB(itemMaterials.unitPrice),
              totalPrice: convertNumberFloatToDB(itemMaterials.totalPrice),
              deliveryDays:
                typy(deliveryDays).isNumber && typy(providerIdNumber).isNumber
                  ? deliveryDays
                  : 0,
            };
            return newItemMaterials;
          },
        );

        /*
         *projectHasChecklist chega como array de objetos [{check1: true},{check14: false}]
         *Abaixo filtra apenas os true, depois no map retira a string 'check' e converte para
         * inteiro o número que é o id do item checklist no banco
         */
        // projectHasChecklist
        const onlyItemsWithTrueChecklist = dataForm.projectHasChecklist.filter(
          // eslint-disable-next-line consistent-return
          (itemProjectHasChecklist: any) => {
            if (Object.values(itemProjectHasChecklist)[0]) {
              const idOfCheckInString = Object.keys(
                itemProjectHasChecklist,
              )[0].replace('check', '');
              return parseInt(idOfCheckInString, 10);
            }
          },
        );

        const itemsConvertedInIntChecklist = onlyItemsWithTrueChecklist.map(
          (itemProjectHasChecklist: any) => {
            const idOfCheckInString = Object.keys(
              itemProjectHasChecklist,
            )[0].replace('check', '');
            return parseInt(idOfCheckInString, 10);
          },
        );

        const itemsChecklistFinal =
          itemsConvertedInIntChecklist.length > 0
            ? [...itemsConvertedInIntChecklist]
            : [];
        // End projectHasChecklist

        const newDataForm = {
          id: parseInt(id, 10),
          projectHasMaterials: [...materialsFormatted],
          projectHasChecklist: itemsChecklistFinal,
          currentSector: dataForm.currentSector,
          finishedQuote: dataForm.finishedQuote === 'true',
        };

        await api.put('purchasing', newDataForm);

        addToast({
          type: 'success',
          title: 'Cadastro realizado com sucesso',
        });

        reset();
        setSaving(false);
        handleOpenModalChecklist();
        navigate('/purchasing');
      } catch (error: any) {
        setSaving(false);
        handleOpenModalChecklist();

        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);

          return;
        }

        if (error.response) {
          const { data } = error.response; // Error vindo do back está em data dentro de response

          addToast({
            type: 'error',
            title: 'Erro no cadastro',
            description: data.message,
          });
          if (
            formRef.current?.getFieldRef(data.field) &&
            data.field.toString().indexOf('_id') === -1
          ) {
            formRef.current?.getFieldRef(data.field).focus(); // Foca o campo que deu erro de acordo com o field retornado do back
          }

          return;
        }

        addToast({
          type: 'error',
          title: 'Error',
          description: 'Erro ao cadastrar!',
        });
      }
    },
    [
      addToast,
      getUpdateListCompleteAndUpdatedOfTheMaterialsPurchasing,
      handleOpenModalChecklist,
      navigate,
      id,
    ],
  );
  // End handleFormSubmit

  // useEffect(() => {
  //   if (Object.entries(project).length > 0) {
  //     setTimeout(() => {
  //       formRef.current?.setData({ ...project });
  //     }, 1000);
  //     // formRef.current?.setData({ ...project });
  //   }
  // }, [project]);

  return (
    <>
      <Container>
        <ModalLoading visible={loading}>
          <p>
            <i>Carregando os dados de Compras...</i>
          </p>
        </ModalLoading>
        {typy(project.id).isNumber && (
          <Content>
            <Form ref={formRef} onSubmit={handleFormSubmit}>
              {/* <Split> */}
              <header>
                <p>Compras</p>
                <span>Nº do Projeto: {id}</span>
              </header>

              <HeadProject
                listClients={listClients}
                showInformationInMobile
                readOnlyProp
              />

              <Tabs
                tabLinksProp={[
                  'Comercial',
                  'Planejamento',
                  'Compras',
                  'Produção',
                ]}
                tabDefaultIndex={2}
              >
                {/* Comercial */}
                <div>
                  <BodyCreateCommercial
                    formRef={formRef}
                    listDrawingsTheProjectProp={project.drawings}
                  />
                </div>
                {/* Planejamento */}
                <div>
                  <BodyPlanning
                    formRef={formRef}
                    listMaterialsProp={listMaterials}
                    listServicesProp={listServices}
                    listUsersProp={listUsers}
                    listUpdatedByServiceProp={listManufacturingSteps}
                    projectProp={project}
                    disableTabProp
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    showModalConfirmationOfExclusionMaterial={() => {}}
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    showModalConfirmationOfExclusionService={() => {}}
                  />
                </div>
                {/* Compras */}
                <div>
                  <BodyPurchasing
                    formRef={formRef}
                    listMaterialsProp={listMaterials}
                    listProvidersProp={listProviders}
                    // listUpdatedByServiceProp={listManufacturingSteps}
                    projectProp={project}
                    disableTabProp={finishedProposal || false}
                    refMaterialPurchasing={handleMaterialPurchasingComponentRef}
                  />
                </div>
                {/* Produção */}
                <div>
                  <BodyManufacturing
                    formRef={formRef}
                    projectProp={project}
                    listManufacturingStepsProp={
                      listManufacturingSteps !== undefined
                        ? listManufacturingSteps
                        : []
                    }
                    disableTabProp
                  />
                </div>
              </Tabs>

              <ChecklistProject
                listChecklistProp={listChecklist}
                loadingChecklistProp={loadingChecklist}
                projectHasChecklist={project.projectHasChecklist}
              />

              <SplitFooter>
                <SelectSectorAndButtonSave>
                  <SimpleSelect
                    id="currentSector"
                    name="currentSector"
                    labelText="Enviar projeto para o setor*:"
                    placeholder=""
                    options={currentSectorList.filter(
                      item => item.value !== 'Produção',
                    )}
                    getOptionLabel={option => option.value}
                    menuPlacement="auto"
                    isDisabled={saving}
                    disabled={saving}
                    // isDisabled={finishedProposal || saving}
                    // disabled={finishedProposal || saving}
                  />
                  <div>
                    {/* {!finishedProposal && ( */}
                    <Button
                      type="button"
                      onClick={verifyPermissions}
                      disabled={saving}
                    >
                      {saving ? <Loading size={24} color="white" /> : 'Salvar'}
                    </Button>
                    {/* )} */}
                  </div>
                </SelectSectorAndButtonSave>
              </SplitFooter>

              <InputHidden id="finishedQuote" name="finishedQuote" />
              <ModalConfirmationFinishedQuote
                formRef={formRef}
                loadModalProp={openModalConfirmationFinishedQuote}
                closeModalFinishedQuote={
                  handleOpenModalConfirmationFinishedQuote
                }
              />

              <ModalChecklist
                saving={saving}
                loadModalProp={openModalChecklist}
                listChecklistProp={listChecklist}
                projectHasChecklist={project.projectHasChecklist}
                closeModalChecklist={handleOpenModalChecklist}
              />
            </Form>
          </Content>
        )}
      </Container>
    </>
  );
};

export default CreatePurchasing;
