/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import fincalc from 'fincalc';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import Cookie from 'js-cookie';

import api from '../../../services/api';
import getValidationErrors from '../../../utils/getValidationError';
import { useToast } from '../../../context/ToastContext';

import Loading from '../../../components/Loading';

import Button from '../../../components/Button';
import SimpleSelect from '../../../components/SimpleSelect';

import currentSectorList from '../../../data/currentSectorList.json';

import { Container, Content, SplitFooter } from './styles';

import HeadProject from '../../Sectors/HeadProject';

import BodyBusinessProposal from '../BodyBusinessProposal';

import convertDateToBr from '../../../utils/convertDateToBr';
import ModalChecklist from '../../Sectors/componentsSectors/ModalChecklist/modalChecklist';
import ModalConfirmationFinishedProposals from '../components/ModalConfirmationFinishedProposals';
import convertNumberFloatToDB from '../../../utils/convertNumberFloatToDB';
import ChecklistProject from '../../Sectors/componentsSectors/ChecklistProject';

import { IMaterialResponse } from '../../../interfaces/material';
import { IServiceResponse } from '../../../interfaces/service';
import { IProviderResponse } from '../../../interfaces/provider';
import {
  IBusinessProposal,
  IBusinessProposalForm,
} from '../../../interfaces/businessProposal';
import { IChecklist } from '../../../interfaces/checklist';
import { IProject, IProjectResponse } from '../../../interfaces/project';
import ModalLoading from '../../../components/ModalLoading';
import convertNumberFloatToBr from '../../../utils/convertNumberFloatToBr';
import convertDateStringToDate from '../../../utils/convertDateStringToDate';
import InputHidden from '../../../components/InputHidden';

import GetProject from '../../AuxiliaryFunctions/GetProject';
import ModalListProvidersIncomplete from '../components/ModalListProvidersIncomplete';
import { IPermission } from '../../../interfaces/permission';
import { IClient, IClientResponse } from '../../../interfaces/client';
import ModalConfirmationStartProduction from '../components/ModalConfirmationStartProducton';

interface IBusinessProposalWithProject extends IBusinessProposal {
  project: IProjectResponse;
}

interface IBusinessProposalMixedProject extends Omit<IProject, 'id'> {
  projectId: number;
  finalPrice: number | string;
}

interface PermissionDTO {
  permissions: IPermission;
}

const UpdateBusinessProposal: React.FC<PermissionDTO> = ({ permissions }) => {
  const { id }: any = useParams();
  // Lembra que o id passado pelo urrl em Update é a da proposta e não a ado projeto igual em creter Mudar
  // no submit
  const formRef = useRef<FormHandles>(null);
  const navigate = useNavigate();
  const { addToast } = useToast();

  const [project, setProject] = useState<IBusinessProposalMixedProject>(
    {} as IBusinessProposalMixedProject,
  );
  const [loading, setLoading] = useState<boolean>(false);

  const [saving, setSaving] = useState<boolean>(false);

  const [listClients, setListClients] = useState<IClient[]>([]);
  const [listMaterials, setListMaterials] = useState<IMaterialResponse[]>([]);
  const [listServices, setListServices] = useState<IServiceResponse[]>([]);

  const [listProviders, setListProviders] = useState<IProviderResponse[]>([]);

  const [listChecklist, setListChecklist] = useState<IChecklist[]>([]);

  const [loadingChecklist, setLoadingChecklist] = useState<boolean>(false);
  const [
    loadingModalListProvidersIncomplete,
    setLoadingModalListProvidersIncomplete,
  ] = useState<boolean>(false);

  const cookieName = `cookieFinalConsiderationsProposals5Bits`;
  const cookieFinalConsiderationsProposals = Cookie.get(cookieName);

  useEffect(() => {
    if (Object.entries(project).length > 0) {
      setTimeout(() => {
        formRef.current?.setData({ ...project });
      }, 1000);
      // formRef.current?.setData({ ...project });
    }
  }, [project]);

  // Get project
  const getProject = useCallback(async (): Promise<void> => {
    try {
      const response = await api.get<IBusinessProposalWithProject>(
        `proposals/${id}`,
      );

      const projectInProposal: IProjectResponse = response.data.project;

      let newDrawings: Array<any> = [];
      let newProjectHasMaterials: Array<any> = [];
      let newProjectHasServices: Array<any> = [];

      if (response.data) {
        if (
          projectInProposal.drawings &&
          projectInProposal.drawings.length > 0
        ) {
          newDrawings = projectInProposal.drawings.map(item => {
            return {
              ...item,
              revisionDate: convertDateToBr(item.revisionDate),
            };
          });
        }

        // projectHasMaterials
        if (
          projectInProposal.projectHasMaterials &&
          projectInProposal.projectHasMaterials.length > 0
        ) {
          newProjectHasMaterials = projectInProposal.projectHasMaterials.map(
            item => {
              const totalPriceCalculated =
                item.totalPrice !== null
                  ? item.totalPrice
                  : fincalc(
                      convertNumberFloatToDB(
                        convertNumberFloatToBr(item.quantity),
                      ) *
                        convertNumberFloatToDB(
                          convertNumberFloatToBr(item.unitPrice),
                        ),
                    );

              return {
                ...item,
                quantity: item.quantity,
                unitPrice: convertNumberFloatToBr(item.unitPrice),
                totalPrice: convertNumberFloatToBr(totalPriceCalculated),
              };
            },
          );
        }

        // projectHasServices
        if (
          projectInProposal.projectHasServices &&
          projectInProposal.projectHasServices.length > 0
        ) {
          newProjectHasServices = projectInProposal.projectHasServices.map(
            item => {
              return {
                ...item,
                unitPrice: convertNumberFloatToBr(item.unitPrice),
                totalPrice: convertNumberFloatToBr(item.totalPrice),
              };
            },
          );
        }
      }

      const newProjectResponse: any = {
        ...projectInProposal,
        forecastEndDate: convertDateStringToDate(
          projectInProposal.forecastEndDate,
        ),
        technicalValidatorDate: convertDateToBr(
          projectInProposal.technicalValidatorDate,
        ),
        operationalValidatorDate: convertDateToBr(
          projectInProposal.operationalValidatorDate,
        ),
        projectValidatorDate: convertDateToBr(
          projectInProposal.projectValidatorDate,
        ),
        drawings: [...newDrawings],
        projectHasMaterials: [...newProjectHasMaterials],
        projectHasServices: [...newProjectHasServices],
        /*
         * projectHasMaterialsPurchasing é o mesmo que projectHasMaterials porém é usado na aba Compras e
         * não em Planejamento.
         * Tem que ser nomes diferentes por causa do scope das abas Planning e Purchasing. Mas a lista é a
         * mesma newProjectHasMaterials.
         */
        projectHasMaterialsPurchasing: [...newProjectHasMaterials],
      };

      // Deleta o id do projeto pois irá utilizar o projectId de proposals e o id tem que ser o de proposals
      delete newProjectResponse.id;

      const projectWithProposal = {
        ...newProjectResponse,
        ...response.data,
        finalPrice: convertNumberFloatToBr(response.data.finalPrice),
        costPrice: convertNumberFloatToBr(response.data.costPrice),
        grossProfit: convertNumberFloatToBr(response.data.grossProfit),
        percentageProfit: convertNumberFloatToBr(
          response.data.percentageProfit,
        ),
      };

      // Deleta o project que vem em proposals pois não utilizará mais
      delete projectWithProposal.project;

      setProject(projectWithProposal);

      // setListManufacturingSteps(newResponse.projectHasManufacturingSteps);
      formRef.current?.setData(projectWithProposal);

      if (
        projectWithProposal.projectHasMaterialsPurchasing.length > 0 &&
        projectWithProposal.projectHasMaterialsPurchasing.some(
          (item: any) => item.providerId === '',
        )
      ) {
        setLoadingModalListProvidersIncomplete(true);
      }

      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      // eslint-disable-next-line no-shadow
      if (error.response) {
        const { data } = error.response; // Error vindo do back está em data dentro de response
        addToast({
          type: 'error',
          title: data.message,
        });
        return;
      }

      addToast({
        type: 'error',
        title: 'Ocorreu um erro interno',
        description: `${error}`,
      });
    }
  }, [addToast, id]);
  // End Get project

  // List Materials
  useEffect(() => {
    setLoading(true);
    const getMaterials = async (): Promise<void> => {
      try {
        // Clientes
        const responseClients = await api.get<IClientResponse[]>('clients');

        const responseClientsFormatted: any = responseClients.data.map(i => ({
          value: i.id,
          label: i.name,
        }));

        setListClients(responseClientsFormatted);

        // Materials
        const responseMaterials = await api.get<IMaterialResponse[]>(
          'materials/active',
        );

        const responseMaterialsFormatted: any = responseMaterials.data.map(
          i => ({
            ...i,
            value: i.id,
            label: i.name,
          }),
        );

        setListMaterials(responseMaterialsFormatted);
        // End Materials

        // Services
        // setLoadingServices(true);
        const responseServices = await api.get<IServiceResponse[]>(
          'services/active',
        );

        const responseServicesFormatted: any = responseServices.data.map(i => ({
          ...i,
          value: i.id,
          label: i.name,
        }));

        setListServices(responseServicesFormatted);
        // End Services

        // Providers
        const responseProviders = await api.get<IProviderResponse[]>(
          'providers/active',
        );

        const responseProvidersFormatted: any = responseProviders.data.map(
          i => ({
            ...i,
            value: i.id,
            label: i.fantasyName,
          }),
        );

        setListProviders(responseProvidersFormatted);
        // End Providers

        // Checklist
        const responseChecklist = await api.get<any[]>('checklist');

        setListChecklist(responseChecklist.data);
        // End checklist

        // Project
        getProject();

        // setLoadingMaterials(false);
      } catch (error: any) {
        setLoading(false);

        if (error.response && error.response.data) {
          const { data } = error.response; // Error vindo do back está em data dentro de response
          addToast({
            type: 'error',
            title: data.message,
          });
          return;
        }

        addToast({
          type: 'error',
          title: 'Error',
          description: 'Erro ao listar!',
        });
      }
    };
    getMaterials();
  }, [addToast, getProject]);
  // End List Materials

  const [openModalChecklist, setOpenModalChecklist] = useState(false);
  const handleOpenModalChecklist = useCallback(() => {
    setOpenModalChecklist(!openModalChecklist);
    setSaving(false);
  }, [openModalChecklist]);

  const [
    openModalConfirmationFinishedProposal,
    setOpenModalConfirmationFinishedProposal,
  ] = useState(false);
  const handleOpenModalConfirmationFinishedProposal = useCallback(() => {
    setOpenModalConfirmationFinishedProposal(
      !openModalConfirmationFinishedProposal,
    );
    if (!openModalConfirmationFinishedProposal === false) {
      handleOpenModalChecklist();
    }
    setSaving(false);
  }, [handleOpenModalChecklist, openModalConfirmationFinishedProposal]);

  // openModalConfirmationStartProduction
  const [
    openModalConfirmationStartProduction,
    setOpenModalConfirmationStartProduction,
  ] = useState(false);

  const handleOpenModalConfirmationStartProduction = useCallback(
    (confirmation: boolean) => {
      setOpenModalConfirmationStartProduction(false);
      confirmation
        ? handleOpenModalConfirmationFinishedProposal()
        : setSaving(false);
    },
    [handleOpenModalConfirmationFinishedProposal],
  );

  // openModalConfirmationStartProduction
  const showModalConfirmationStartProduction = useCallback(() => {
    setOpenModalConfirmationStartProduction(true);
  }, []);

  const verifyPermissions = useCallback(async () => {
    try {
      const dataForm = formRef.current?.getData();

      setSaving(true);
      formRef.current?.setErrors({});

      /** *
       * Campos obrigatórios porém é o sistema que tem que preencher
       *  costPrice
          grossProfit
          percentageProfit
          status
       */

      if (dataForm && Object.entries(dataForm).length > 0) {
        const schema = Yup.object().shape({
          finalPrice: Yup.string()
            .test(
              'finalPrice',
              'Preço final cobrado pelo projeto obrigatório',
              () => {
                return convertNumberFloatToDB(dataForm.finalPrice) > 0;
              },
            )
            .required('Preço final cobrado pelo projeto obrigatório'),
          currentSector: Yup.string().required(
            'O setor que receberá o projeto é obrigatório',
          ),
        });

        await schema.validate(dataForm, { abortEarly: false });

        dataForm.currentSector === 'Produção'
          ? showModalConfirmationStartProduction()
          : handleOpenModalConfirmationFinishedProposal();
      }
    } catch (error: any) {
      setSaving(false);

      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formRef.current?.setErrors(errors);

        return;
      }

      addToast({
        type: 'error',
        title: 'Error',
        description: `${error}`,
      });
    }
  }, [
    addToast,
    handleOpenModalConfirmationFinishedProposal,
    showModalConfirmationStartProduction,
  ]);

  // handleFormSubmit
  const handleFormSubmit = useCallback(
    async (dataForm: IBusinessProposalForm, { reset }) => {
      try {
        setSaving(true);

        if (cookieFinalConsiderationsProposals !== undefined) {
          Cookie.remove(cookieName);
        }
        Cookie.set(cookieName, dataForm.finalConsiderations, { expires: 365 });

        /*
         *projectHasChecklist chega como array de objetos [{check1: true},{check14: false}]
         *Abaixo filtra apenas os true, depois no map retira a string 'check' e converte para
         * inteiro o número que é o id do item checklist no banco
         */
        // projectHasChecklist
        const onlyItemsWithTrueChecklist = dataForm.projectHasChecklist.filter(
          // eslint-disable-next-line consistent-return
          (itemProjectHasChecklist: any) => {
            if (Object.values(itemProjectHasChecklist)[0]) {
              const idOfCheckInString = Object.keys(
                itemProjectHasChecklist,
              )[0].replace('check', '');
              return parseInt(idOfCheckInString, 10);
            }
          },
        );

        const itemsConvertedInIntChecklist = onlyItemsWithTrueChecklist.map(
          (itemProjectHasChecklist: any) => {
            const idOfCheckInString = Object.keys(
              itemProjectHasChecklist,
            )[0].replace('check', '');
            return parseInt(idOfCheckInString, 10);
          },
        );

        const itemsChecklistFinal =
          itemsConvertedInIntChecklist.length > 0
            ? [...itemsConvertedInIntChecklist]
            : [];
        // End projectHasChecklist

        const newDataForm: IBusinessProposal = {
          id: parseInt(id, 10),
          projectId: project.projectId,
          costPrice: convertNumberFloatToDB(dataForm.costPrice),
          grossProfit: convertNumberFloatToDB(dataForm.grossProfit),
          percentageProfit: convertNumberFloatToDB(dataForm.percentageProfit),
          finalPrice: permissions.create
            ? convertNumberFloatToDB(dataForm.finalPrice)
            : convertNumberFloatToDB(project.finalPrice as string),
          tax: dataForm.tax,
          paymentTerms: dataForm.paymentTerms,
          proposalValidity: dataForm.proposalValidity,
          deadline: dataForm.deadline,
          transport: dataForm.transport,
          finish: dataForm.finish,
          responsibilitiesContracting: dataForm.responsibilitiesContracting,
          responsibilitiesContracted: dataForm.responsibilitiesContracted,
          finalConsiderations: dataForm.finalConsiderations,
          status: dataForm.status,
          projectHasChecklist: itemsChecklistFinal,
          currentSector: dataForm.currentSector,
        };

        await api.put('proposals', newDataForm);

        addToast({
          type: 'success',
          title: 'Atualização realizada com sucesso',
        });

        reset();
        setSaving(false);
        handleOpenModalChecklist();
        navigate('/proposals');
      } catch (error: any) {
        setSaving(false);
        handleOpenModalChecklist();

        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);

          return;
        }

        if (error.response) {
          const { data } = error.response; // Error vindo do back está em data dentro de response

          addToast({
            type: 'error',
            title: 'Erro na atualização',
            description: data.message,
          });
          if (
            formRef.current?.getFieldRef(data.field) &&
            data.field.toString().indexOf('_id') === -1
          ) {
            formRef.current?.getFieldRef(data.field).focus(); // Foca o campo que deu erro de acordo com o field retornado do back
          }

          return;
        }

        addToast({
          type: 'error',
          title: 'Error',
          description: 'Erro ao atualizar!',
        });
      }
    },
    [
      addToast,
      cookieFinalConsiderationsProposals,
      cookieName,
      handleOpenModalChecklist,
      navigate,
      id,
      permissions.create,
      project.finalPrice,
      project.projectId,
    ],
  );
  // End handleFormSubmit

  return (
    <>
      <Container>
        <ModalLoading visible={loading}>
          <p>
            <i>Carregando os dados da Proposta...</i>
          </p>
        </ModalLoading>
        <Content>
          <Form ref={formRef} onSubmit={handleFormSubmit}>
            {/* <Split> */}
            <header>
              <p>Elaboração de Proposta Comercial</p>
              <span>Nº do Projeto: {project.projectId}</span>
            </header>

            <HeadProject
              listClients={listClients}
              showInformationInMobile
              readOnlyProp
            />

            <BodyBusinessProposal
              formRef={formRef}
              listMaterialsProp={listMaterials}
              listServicesProp={listServices}
              listProvidersProp={listProviders}
              projectProp={project}
              permissions={permissions}
              disabledHandleResume={false}
              disabledHandleAdditionalInformation={false}
            />

            <ChecklistProject
              listChecklistProp={listChecklist}
              loadingChecklistProp={loadingChecklist}
              projectHasChecklist={project.projectHasChecklist}
            />

            <SplitFooter>
              <SimpleSelect
                id="currentSector"
                name="currentSector"
                labelText="Enviar projeto para o setor*:"
                placeholder=""
                options={currentSectorList}
                getOptionLabel={option => option.value}
                menuPlacement="auto"
                disabled={saving}
              />
              <div>
                {(loadingModalListProvidersIncomplete && (
                  <Button type="button">
                    {saving ? <Loading size={24} color="white" /> : 'Salvar'}
                  </Button>
                )) || (
                  <Button
                    type="button"
                    onClick={verifyPermissions}
                    disabled={saving}
                  >
                    {saving ? <Loading size={24} color="white" /> : 'Salvar'}
                  </Button>
                )}
              </div>
            </SplitFooter>

            <InputHidden id="status" name="status" />
            <ModalConfirmationFinishedProposals
              formRef={formRef}
              loadModalProp={openModalConfirmationFinishedProposal}
              closeModalFinishedProposals={
                handleOpenModalConfirmationFinishedProposal
              }
            />

            <ModalConfirmationStartProduction
              loadModalProp={openModalConfirmationStartProduction}
              handleOpenModalConfirmationStartProduction={
                handleOpenModalConfirmationStartProduction
              }
            />

            <ModalListProvidersIncomplete
              loadModalProp={loadingModalListProvidersIncomplete}
            />

            <ModalChecklist
              saving={saving}
              loadModalProp={openModalChecklist}
              listChecklistProp={listChecklist}
              projectHasChecklist={project.projectHasChecklist}
              closeModalChecklist={handleOpenModalChecklist}
            />
          </Form>
        </Content>
      </Container>
    </>
  );
};

export default UpdateBusinessProposal;
