/* eslint-disable no-nested-ternary */
import React, { useRef, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { FaHandRock } from 'react-icons/fa';

import { IStepFrame } from '../../interfaces';

import {
  Container,
  Content,
  FrameHeader,
  FrameTitle,
  FrameInfo,
  FrameDescription,
} from './styles';

interface IStepFrameProps {
  stepFrame: IStepFrame;
  index: number;
}

const StepFrame: React.FC<IStepFrameProps> = ({ stepFrame, index }) => {
  const nodeRefContent = useRef<HTMLDivElement>(null);

  const [open, setOpen] = useState(false);

  function toggleShowDescription(): void {
    setOpen(!open);
  }

  return (
    <Draggable draggableId={stepFrame.id} index={index} isDragDisabled={open}>
      {(provided, snapshot): React.ReactElement<HTMLElement> => (
        <Container
          ref={provided.innerRef}
          {...provided.draggableProps}
          isDragging={snapshot.isDragging}
          dragProps={provided.draggableProps}
          isOpen={open}
          sizeH={nodeRefContent?.current?.clientHeight || 90}
        >
          <FrameHeader>
            <FrameTitle>
              <p>{stepFrame.title}</p>
              <button
                type="button"
                onClick={toggleShowDescription}
                {...provided.dragHandleProps}
              >
                {snapshot.isDragging ? (
                  <FaHandRock size={14} color="white" />
                ) : open ? (
                  <FiChevronUp size={16} color="white" />
                ) : (
                  <FiChevronDown size={16} color="white" />
                )}
              </button>
            </FrameTitle>
            <FrameInfo>
              <p>
                Nº da OS: <span>{stepFrame.osNumber}</span>
              </p>
              <p>
                Data de entrega: <span>{stepFrame.deliveryAtFormatted}</span>
              </p>
            </FrameInfo>
          </FrameHeader>

          <Content ref={nodeRefContent} isOpen={open}>
            <FrameDescription>
              <p>Descrição:</p>
              <div>
                <p>{stepFrame.description}</p>
              </div>
            </FrameDescription>
          </Content>
        </Container>
      )}
    </Draggable>
  );
};

export default StepFrame;
