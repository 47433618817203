import styled from 'styled-components';

export const FooterContainer = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 99999;

  /* position: fixed; */
  bottom: 0;

  /* height: 29px; */
  width: 100%;

  background: var(--branco-puro-5bits);

  padding: 0 90px 0 90px;

  //Medias
  @media (min-width: 0px) and (max-width: 759px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0%;

    padding: 0.8rem 1.6rem;

    .separator {
      display: none;
    }

    > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  @media (min-width: 760px) and (max-width: 999px) {
  }
`;
