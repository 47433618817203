import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  /* Materiais */
  > div:nth-child(1) {
    z-index: 4;
  }

  /* Serviços */
  > div:nth-child(2) {
    z-index: 3;
  }

  /* Etapas de Produção */
  > div:nth-child(3) {
    z-index: 2;
  }

  /* Análise de Viabilidade */
  > div:nth-child(4) {
    z-index: 1;
  }
`;
