/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState, useCallback } from 'react';

import { Link } from 'react-router-dom';
import { FiEdit } from 'react-icons/fi';
import { RiMoneyDollarBoxFill } from 'react-icons/ri';
import { IoMdFiling } from 'react-icons/io';
import { useToast } from '../../../../context/ToastContext';
import convertInitToUppercase from '../../../../utils/convertInitToUppercase';

import {
  Container,
  Content,
  ContainerInfoDrawing,
  InfoDrawing,
  ActionsIcons,
} from './styles';
import api from '../../../../services/api';

import convertDateToBr from '../../../../utils/convertDateToBr';

import ListingTemplate from '../../../../components/Template/Listing';
import Button from '../../../../components/Button';
import Loading from '../../../../components/Loading';
import Tooltip from '../../../../components/Tooltip';

import { ICommercial } from '../../../../interfaces/commercial';

import { IPermission } from '../../../../interfaces/permission';
import ModalConfirmationArchivedProject from './ModalConfirmationArchivedProject';

interface PermissionDTO {
  permissions: IPermission;
}

const ListCommercialProjects: React.FC<PermissionDTO> = ({ permissions }) => {
  const { addToast } = useToast();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingFile, setLoadingFile] = useState<boolean>(false);
  const [buttonClicked, setButtonClicked] = useState<string>('');
  const [listCommercialProjects, setListCommercialProjects] = useState<
    ICommercial[]
  >([]);
  const [
    listCommercialProjectsPaginated,
    setListCommercialProjectsPaginated,
  ] = useState<ICommercial[]>([]);

  const [
    loadingModalConfirmationArchivedProject,
    setLoadingModalConfirmationArchivedProject,
  ] = useState<boolean>(false);
  const [idProject, setIdProject] = useState<number | undefined>(undefined);

  const toggleCloseModalConfirmationArchivedProject = useCallback(() => {
    setLoadingModalConfirmationArchivedProject(
      !loadingModalConfirmationArchivedProject,
    );
  }, [loadingModalConfirmationArchivedProject]);

  const handleIdProjectForModalConfirmation = useCallback(
    (idProjectProp: number) => {
      setIdProject(idProjectProp);
    },
    [],
  );

  useEffect(() => {
    const getProvider = async (): Promise<void> => {
      try {
        setLoading(true);

        const response: any = await api.get(`commercial/active`);

        if (response.data.length > 0) {
          const responseOrdered = response.data.map((i: ICommercial) => ({
            ...i,
            createdAt: i.createdAt,
            updatedAt: i.updatedAt,
          }));
          setListCommercialProjects(responseOrdered);
          setListCommercialProjectsPaginated(responseOrdered);
        }

        setLoading(false);
      } catch (error: any) {
        setLoading(false);

        if (error.response) {
          const { data } = error.response; // Error vindo do back está em data dentro de response
          addToast({
            type: 'error',
            title: data.message,
          });
          return;
        }

        addToast({
          type: 'error',
          title: 'Erro ao listar!',
          description: `Error: ${error}`,
        });
      }
    };
    getProvider();
  }, [addToast]);

  const handleOpenFile = useCallback(
    (fileName: string, nameButtonClicked: string) => {
      setLoadingFile(true);
      setButtonClicked(nameButtonClicked);

      const extensionFile = fileName.substring(fileName.lastIndexOf('.') + 1);

      let typeBlob = '';

      switch (extensionFile) {
        case 'pdf':
          typeBlob = `application/${extensionFile}`;
          break;
        case 'tiff':
          typeBlob = `image/${extensionFile}`;
          break;
        default:
          typeBlob = `image/${extensionFile}`;
      }

      api
        .get(`drawings/file/${fileName}`, {
          responseType: 'arraybuffer',
        })
        .then((response: any) => {
          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: typeBlob }),
          );

          window.open(url);
          setLoadingFile(false);
          setButtonClicked('');
        })
        .catch(error => {
          setLoadingFile(false);
          setButtonClicked('');

          if (error.response.data) {
            const { data } = error.response; // Error vindo do back está em data dentro de response
            addToast({
              type: 'error',
              title: data.message,
            });
            return;
          }

          addToast({
            type: 'error',
            title: 'Erro ao Exibir!',
            description: `Error: ${error}`,
          });
        });
    },
    [addToast],
  );

  const handleListCommercialProjectsPaginatedItems = useCallback(newList => {
    setListCommercialProjectsPaginated(newList);
  }, []);

  return (
    <ListingTemplate
      title="Comercial"
      module="commercial"
      listItemsProps={listCommercialProjects}
      handleListItemsPaginatedItems={handleListCommercialProjectsPaginatedItems}
      displaySearchArea={false}
      displayButtonNew
      disabled={loading}
      permissions={permissions}
      perPageForPaginationProp={30}
    >
      <Container>
        <Content>
          {loading ? (
            <p>carregando ...</p>
          ) : (
            <table>
              <thead>
                <tr>
                  <th>Nº</th>
                  <th>Cliente</th>
                  <th>Descrição</th>
                  <th>Quant.</th>
                  <th>Desenho</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {listCommercialProjectsPaginated.map(
                  (commercialItem, index) => (
                    <tr key={String(commercialItem.id)}>
                      <td>{commercialItem.id}</td>
                      <td>
                        {convertInitToUppercase(commercialItem.client.name)}
                      </td>
                      <td>{commercialItem.description}</td>
                      <td>{commercialItem.quantity}</td>
                      <td>
                        {commercialItem.drawings.map(item => (
                          <ContainerInfoDrawing key={String(item.id)}>
                            <Tooltip
                              style={{
                                textTransform: 'capitalize',
                              }}
                              colorBefore="var(--azul-5bits)"
                              colorSpan="var(--azul-5bits)"
                              title={`Rev.: ${
                                item.revisionNumber
                              } | Rev. Date: ${convertDateToBr(
                                item.revisionDate,
                              )}`}
                            >
                              <InfoDrawing>
                                <div>
                                  <span>
                                    {convertInitToUppercase(item.drawingName)}
                                  </span>
                                  <span>{item.drawingNumber}</span>
                                </div>
                                {(loadingFile &&
                                  buttonClicked === `openFile${index}` && (
                                    <Button
                                      name="buttonSpinner"
                                      type="button"
                                      onClick={e => {
                                        e.preventDefault();
                                      }}
                                    >
                                      <Loading size={24} color="white" />
                                    </Button>
                                  )) || (
                                  <Button
                                    name={`openFile${index}`}
                                    type="button"
                                    onClick={e => {
                                      e.preventDefault();
                                      handleOpenFile(
                                        item.fileName,
                                        `openFile${index}`,
                                      );
                                    }}
                                  >
                                    Exibir
                                  </Button>
                                )}
                              </InfoDrawing>
                            </Tooltip>
                          </ContainerInfoDrawing>
                        ))}
                      </td>
                      <td>
                        {(permissions.update && (
                          <ActionsIcons>
                            {/* Ícone editar projeto */}
                            <Link
                              to={`/commercial/update/${commercialItem.id}`}
                            >
                              <FiEdit size={16} color="var(--azul-5bits)" />
                            </Link>
                            {/* Ícone faturar projeto */}
                            {(commercialItem.isFinished && (
                              <Link
                                to={`/invoices/create/${commercialItem.id}`}
                              >
                                <RiMoneyDollarBoxFill
                                  size={20}
                                  color="var(--verde-5bits)"
                                />
                              </Link>
                            )) || (
                              <span>
                                <RiMoneyDollarBoxFill
                                  size={20}
                                  color="var(--cinza-escuro-5bits)"
                                />
                              </span>
                            )}
                            {/* Ícone arquivar projeto */}
                            {(commercialItem.isArchived === false && (
                              <span>
                                <IoMdFiling
                                  title="Arquivar"
                                  size={20}
                                  color="var(--azul-5bits)"
                                  cursor="pointer"
                                  onClick={e => {
                                    e.preventDefault();
                                    toggleCloseModalConfirmationArchivedProject();
                                    handleIdProjectForModalConfirmation(
                                      commercialItem.id,
                                    );
                                  }}
                                />
                              </span>
                            )) || (
                              <span>
                                <IoMdFiling
                                  size={20}
                                  color="var(--cinza-escuro-5bits)"
                                />
                              </span>
                            )}
                          </ActionsIcons>
                        )) || (
                          <>
                            <span>
                              <FiEdit
                                size={16}
                                color="var(--cinza-escuro-5bits)"
                              />
                            </span>
                            <span>
                              <RiMoneyDollarBoxFill
                                size={20}
                                color="var(--cinza-escuro-5bits)"
                              />
                            </span>
                            <span>
                              <IoMdFiling
                                size={20}
                                color="var(--cinza-escuro-5bits)"
                              />
                            </span>
                          </>
                        )}
                      </td>
                    </tr>
                  ),
                )}
              </tbody>
            </table>
          )}
        </Content>
      </Container>

      <ModalConfirmationArchivedProject
        idProject={idProject}
        loadModalProp={loadingModalConfirmationArchivedProject}
        closeModalConfirmationArchivedProject={
          toggleCloseModalConfirmationArchivedProject
        }
      />
    </ListingTemplate>
  );
};

export default ListCommercialProjects;
