import styled from 'styled-components';

export const SplitMaterialItem = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-end;
  gap: 1.4rem;

  width: 100%;
  margin-bottom: 1.7rem;

  > div {
    label {
      margin-left: 1rem;
    }
  }

  /* Select materialId */
  > div:first-child {
    flex: 1;
    min-width: 30rem;
  }

  //Medias
  @media (min-width: 0px) and (max-width: 759px) {
    /* Select materialId */
    > div:first-child {
      min-width: 100%;
    }
  }
`;

export const QuantityPriceSubtotalMaterial = styled.div`
  flex: 1;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-end;
  gap: 1.4rem;

  max-width: 39rem;
  min-width: 39rem;

  /* Quantity Material */
  > div:nth-child(1) {
    width: 15rem;
    min-width: 15rem;
  }

  /* Price Material */
  > div:nth-child(2) {
    width: 9.2rem;
    min-width: 9.2rem;
  }

  /* Subtotal Material */
  > div:nth-child(3) {
    width: 12rem;
    min-width: 12rem;
  }

  //Medias
  @media (min-width: 0px) and (max-width: 460px) {
    flex-wrap: wrap;
    min-width: auto;

    > div {
      flex: 1;
    }

    /* Subtotal Material */
    > div:nth-child(3) {
      min-width: 100%;
    }
  }
  @media (min-width: 461px) and (max-width: 759px) {
    flex-wrap: wrap;

    > div {
      flex: 1;
    }
  }

  @media (min-width: 760px) and (max-width: 999px) {
  }
`;

export const FiledTwoInputs = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;

  //Quantity
  > div:first-child {
    width: 70%;

    > div {
      padding: 0.9rem 0.5rem 0.9rem 1.5rem;

      border-top-left-radius: 2.5rem;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 2.5rem;

      > input {
        text-align: right;
      }
    }
  }

  //Unit
  > div:last-child {
    width: 30%;

    > label {
      visibility: hidden;
    }

    > div {
      padding: 0.9rem 0.5rem 0.9rem 0.5rem;

      border-left: none;
      border-top-left-radius: 0;
      border-top-right-radius: 2.5rem;
      border-bottom-right-radius: 2.5rem;
      border-bottom-left-radius: 0;

      > input {
        text-align: left;
      }
    }
  }
`;

export const ObservationAndTrashIcon = styled.div`
  flex: 1;
  min-width: 30rem;

  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 1.4rem;

  > div {
    label {
      margin-left: 1rem;
    }
  }

  /* Icon trash Material */
  > div:nth-child(2) {
    margin-top: 2.1rem;
  }

  //Medias
  @media (min-width: 0px) and (max-width: 759px) {
    min-width: 100%;
  }
`;
