import styled from 'styled-components';

export const SplitAdditionalInformationsProps = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  padding-top: 3.125rem;
  padding-bottom: 3.125rem;
  border-bottom: 0.1rem solid var(--cinza-claro-5bits);

  > section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    margin-top: 3.8rem;

    > div {
      width: 55.6rem;
      min-width: 55.6rem;
      margin: 0 0 3.9rem 0;

      > label {
        margin-left: 1rem;
      }
    }

    //Considerações Finais
    > div:last-child {
      flex-grow: 1;

      margin: 0;
    }
  }
`;

export const HeaderAndGrandTotal = styled.div`
  display: flex;
  justify-content: space-between;

  width: 100%;

  cursor: pointer;
  :hover {
    background: var(--branco-gelo-5bits);
  }

  > span {
    display: flex;
    justify-content: center;
    align-items: center;

    width: fit-content;

    font-style: normal;
    font-weight: 500;
    font-size: 2.4rem;
    line-height: 3.2rem;
    text-transform: capitalize;

    color: var(--cinza-escuro-5bits);
  }
`;
