import styled from 'styled-components';

export const SplitMaterials = styled.div`
  display: flex;
  flex-direction: column;

  z-index: 1;

  width: 100%;
  padding-top: 3.125rem;
  padding-bottom: 3.125rem;
  border-bottom: 0.1rem solid var(--cinza-claro-5bits);

  > section {
    margin-top: 3.8rem;
  }
`;

export const HeaderAndGrandTotal = styled.div`
  display: flex;
  justify-content: space-between;

  width: 100%;

  cursor: pointer;
  :hover {
    background: var(--branco-gelo-5bits);
  }

  > span {
    display: flex;
    justify-content: center;
    align-items: center;

    width: fit-content;

    font-style: normal;
    font-weight: 500;
    font-size: 2.4rem;
    line-height: 3.2rem;
    text-transform: capitalize;

    color: var(--cinza-escuro-5bits);

    > svg {
      margin-left: 1rem;
    }
  }
`;

export const ActionAndGrandTotal = styled.div`
  display: flex;
  justify-content: flex-end;

  width: 100%;
`;

export const GrandTotal = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2.1rem;

  color: var(--azul-5bits);

  > span {
    margin-left: 0.5rem;

    font-weight: 900;
    font-size: 2.4rem;
    line-height: 3.2rem;

    color: var(--laranja-5bits);
  }
`;
