/* eslint-disable react/jsx-curly-newline */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import React, {
  Ref,
  forwardRef,
  useEffect,
  useState,
  useCallback,
  useImperativeHandle,
  memo,
} from 'react';
import { FormHandles } from '@unform/core';
import { v4 as uuidv4 } from 'uuid';

import Button from '../../../../../components/Button';
import ScopeInvoice from './components/ScopeInvoice';
import * as IInvoices from '../../../../../interfaces/invoice';

import {
  SplitInvoices,
  HeaderAndGrandTotal,
  ActionAndGrandTotal,
  GrandTotal,
} from './styles';

interface IHandleInvoicesProps {
  formRef: any | FormHandles;
  listInvoices: Array<any>;
  finalPriceProject: string;
  showModalConfirmationOfExclusionInvoice: (
    indexInvoice: number,
    show: boolean,
  ) => void;
}

interface IRef {
  removeInvoice: any;
}

const HandleInvoices = forwardRef<IRef, IHandleInvoicesProps>(
  (
    {
      formRef,
      listInvoices,
      finalPriceProject,
      showModalConfirmationOfExclusionInvoice,
    }: IHandleInvoicesProps,
    ref: Ref<IRef>,
  ) => {
    const [listInvoicesActual, setListInvoicesActual] = useState<Array<any>>(
      [],
    );

    const [disabled, setDisabled] = useState<boolean>(false);

    const addNewInvoice = useCallback(() => {
      const currentListInvoices = formRef.current.getData().scopeListInvoices;

      setListInvoicesActual([
        ...currentListInvoices,
        {
          id: uuidv4(),
          dueDate: '',
          price: 0,
          payday: '',
          isPay: 0,
          observation: '',
        },
      ]);
    }, [formRef]);

    const removeInvoice = useCallback(
      (index: number) => {
        if (formRef && formRef.current) {
          const currentListInvoices = formRef.current.getData()
            .scopeListInvoices;

          if (currentListInvoices.length > 1) {
            currentListInvoices.splice(index, 1);
            setListInvoicesActual(currentListInvoices);
          } else {
            setListInvoicesActual([
              {
                id: uuidv4(),
                dueDate: '',
                price: 0,
                payday: '',
                isPay: 0,
                observation: '',
              },
            ]);

            // formRef.current?.clearField(`scopeListInvoices[0].dueDate`);
            // formRef.current?.clearField(`scopeListInvoices[0].payday`);
            // formRef.current?.clearField(`scopeListInvoices[0].price`);
            // formRef.current?.clearField(`scopeListInvoices[0].isPay`);
            // formRef.current?.clearField(`scopeListInvoices[0].observation`);
          }
        }
      },
      [formRef],
    );

    useEffect(() => {
      // eslint-disable-next-line array-callback-return
      listInvoicesActual.map((item: any, index: number): void => {
        formRef.current.setFieldValue(
          `scopeListInvoices[${index}].id`,
          item.id,
        );
        formRef.current.setFieldValue(
          `scopeListInvoices[${index}].dueDate`,
          item.dueDate,
        );
        formRef.current.setFieldValue(
          `scopeListInvoices[${index}].price`,
          item.price,
        );
        formRef.current.setFieldValue(
          `scopeListInvoices[${index}].payday`,
          item.payday,
        );
        formRef.current.setFieldValue(
          `scopeListInvoices[${index}].isPay`,
          item.isPay,
        );
        formRef.current.setFieldValue(
          `scopeListInvoices[${index}].observation`,
          item.observation,
        );
      });
    }, [formRef, listInvoicesActual]);

    useEffect(() => {
      if (listInvoices.length > 0) {
        setListInvoicesActual(listInvoices);
      } else {
        setListInvoicesActual([
          {
            id: uuidv4(),
            dueDate: '',
            price: 0,
            payday: '',
            isPay: 0,
            observation: '',
          },
        ]);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listInvoices]);

    useImperativeHandle(ref, () => ({
      removeInvoice,
    }));

    return (
      <SplitInvoices>
        <HeaderAndGrandTotal>
          <span>Parcelas</span>
          <GrandTotal>
            Valor do Projeto:
            <span>${finalPriceProject}</span>
          </GrandTotal>
        </HeaderAndGrandTotal>
        <section id="invoicesArea">
          <ScopeInvoice
            formRef={formRef}
            listInvoices={listInvoicesActual}
            removeInvoice={removeInvoice}
            showModalConfirmationOfExclusionInvoice={
              showModalConfirmationOfExclusionInvoice
            }
            disabled={disabled}
          />

          <ActionAndGrandTotal>
            <div>
              {!disabled && (
                <Button
                  name="newInvoice"
                  type="button"
                  onClick={e => {
                    e.preventDefault();
                    addNewInvoice();
                  }}
                >
                  Mais Parcelas
                </Button>
              )}
            </div>
          </ActionAndGrandTotal>
        </section>
      </SplitInvoices>
    );
  },
);
export default memo(HandleInvoices);
