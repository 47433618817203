import styled, { css } from 'styled-components';

interface ICheckProps {
  showChecklist: boolean;
}

export const ContainerChecklistAbsolute = styled.div<ICheckProps>`
  position: absolute;
  top: 16vh;
  z-index: 1501;

  height: 68.6vh;
  max-height: 68.6vh;

  ${props =>
    // Aberto left 0
    (props.showChecklist &&
      css`
        left: 0;
      `) ||
    css`
      left: -36rem;
    `}

  /* Faz a animação do menu deslizar em vez de aparecer logo aberto */
-webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;

  @media (min-width: 0px) and (max-width: 759px) {
    top: 12vh;
    height: calc(var(--vh, 1vh) * 85.5);
    max-height: calc(var(--vh, 1vh) * 85.5);

    ${props =>
      // Aberto left 0
      (props.showChecklist &&
        css`
          left: -3vh;
        `) ||
      css`
        left: -98vw;
      `}
  }

  @media (min-width: 760px) and (max-width: 999px) {
  }
`;

export const ContainerChecklist = styled.div`
  position: relative;

  width: 36rem;
  min-width: 36rem;
  max-width: 36rem;

  @media (min-width: 0px) and (max-width: 759px) {
    height: calc(var(--vh, 1vh) * 85.5);
    width: 98vw;
    min-width: 98vw;
    max-width: 98vw;
  }

  @media (min-width: 760px) and (max-width: 999px) {
  }
`;

export const TextVertical = styled.div<ICheckProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 33.5rem;
  transform: rotate(270deg);
  position: absolute;
  left: 60%;
  top: 50%;

  background: rgb(231, 238, 243);
  border: 1px solid rgb(15 76 130 / 27%);
  box-shadow: -4px 4px 10px rgb(15 76 130 / 25%);
  border-radius: 0px 0px 4px 4px;

  opacity: 1;
  transition: opacity 0.5s linear 0s;
  padding: 10px 35px 10px;

  > span {
    width: 100%;
    font-weight: 500;
    font-size: 2rem;
    line-height: 1.5rem;
    margin-bottom: 0;

    color: var(--azul-5bits);
  }

  img {
    transform: rotate(-90deg);
    transition-duration: 0.3s;
  }

  ${props =>
    // Aberto left 0
    (props.showChecklist &&
      css`
        opacity: 0;
        pointer-events: none;

        img {
          transform: rotate(90deg);
          transition-duration: 0.3s;
        }
      `) ||
    css`
      opacity: 1;
      transition: opacity 0.5s linear;
    `}

  @media (min-width: 0px) and (max-width: 759px) {
    width: 3.5rem;
    top: 7%;
    left: 98%;
    padding: 0 1.1rem 0.1rem;
    > span {
      display: none;
    }
  }

  @media (min-width: 760px) and (max-width: 999px) {
  }
`;

export const SplitRightProcessChecklist = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  width: 100%;
  margin-bottom: 1.7rem;

  background: var(--azul-claro-5bits);
  border: 1px solid var(--azul-claro-5bits);
  box-shadow: 4px 4px 10px rgba(15, 76, 130, 0.25);
  border-radius: 0px 4px 4px 0px;

  @media (min-width: 0px) and (max-width: 759px) {
    height: calc(var(--vh, 1vh) * 85.5);
  }

  @media (min-width: 760px) and (max-width: 999px) {
  }
`;

export const ProcessChecklistItems = styled.div<ICheckProps>`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;

  padding: 2.5rem 2.5rem;

  > header {
    display: flex;
    align-items: center;

    font-style: normal;
    font-weight: 500;
    font-size: 2.4rem;
    line-height: 3.2rem;

    width: fit-content;

    color: var(--azul-5bits);

    margin-bottom: 2.5rem;

    > img {
      margin-left: 4.1rem;
      transform: rotate(180deg);
      transition-duration: 0.3s;
    }

    ${props =>
      // Aberto left 0
      props.showChecklist &&
      css`
        img {
          transform: rotate(0deg);
          transition-duration: 0.3s;
        }
      `}
  }
`;

export const CheckItemsArea = styled.div`
  display: flex;
  flex-direction: column;

  overflow: auto;

  height: 57vh;
  max-height: 57vh;

  > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    margin-bottom: 1rem;

    svg {
      width: 2.1rem;
      height: 2.1rem;
      transform: scale(2.4);
    }
  }

  @media (min-width: 0px) and (max-width: 759px) {
    height: 100%;
    max-height: none;
  }

  @media (min-width: 760px) and (max-width: 999px) {
  }
`;

export const TextChecked = styled.span`
  margin-left: 1rem;

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;

  color: var(--azul-5bits);
`;
export const TextUnChecked = styled.span`
  margin-left: 1rem;

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;

  color: rgba(15, 76, 130, 0.42);
`;
