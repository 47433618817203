import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { RiNotificationLine, RiLogoutCircleRLine } from 'react-icons/ri';

import menu from '../../assets/menu.svg';
import logo from '../../assets/logo.png';
import smallLogo from '../../assets/small_logo.svg';

import Footer from '../Footer';

import { useAuth } from '../../context/AuthContext';

import Button from '../Button';
import Menu from './components/Menu';
import MenuDropdown from './components/MenuDropdown';

import { HeaderContainer, Content, Buttons } from './styles';

const Header: React.FC = () => {
  const { signOut, menus } = useAuth();
  const [openMenuHamburger, setOpenMenuHamburger] = useState(false);
  const [closeMenuHamburger, setCloseMenuHamburger] = useState(false);
  const [idMenuClicked, setIdMenuClicked] = useState('');

  const toggleOpenAndCloseMenuHamburger = useCallback(() => {
    setOpenMenuHamburger(!openMenuHamburger);
    setCloseMenuHamburger(!!openMenuHamburger);
  }, [openMenuHamburger]);

  const handleMenuClicked = useCallback((idMenu: string) => {
    setIdMenuClicked(idMenu);
  }, []);

  return (
    <HeaderContainer>
      <Content
        openMenuHamburgerProp={openMenuHamburger}
        closeMenuHamburgerProp={closeMenuHamburger}
      >
        <button
          type="button"
          className="menuHamburger"
          onClick={toggleOpenAndCloseMenuHamburger}
        >
          <img src={menu} alt="" />
        </button>
        <nav>
          <Link to="/home">
            <img src={logo} alt="5Bits logomarca" />
          </Link>

          <div>
            {menus.reduce((acc, item) => {
              if (item.submenus.length === 0) {
                acc.push(
                  <Menu
                    key={String(`menu-${item.id}`)}
                    id={String(`menu-${item.id}`)}
                    path={item.route}
                    label={item.label}
                    closeMenuHamburger={toggleOpenAndCloseMenuHamburger}
                  />,
                );
              } else {
                acc.push(
                  <MenuDropdown
                    key={String(`menu-${item.id}`)}
                    id={String(item.id)}
                    path={item.route}
                    label={item.label}
                    submenus={item.submenus.map((s: any) => ({
                      id: s.id,
                      label: s.label,
                      path: s.route,
                    }))}
                    menuActiveProp={String(item.id) === idMenuClicked}
                    closeMenuHamburger={toggleOpenAndCloseMenuHamburger}
                    menuClickedId={handleMenuClicked}
                  />,
                );
              }
              return acc;
            }, [])}
          </div>

          <Footer />
        </nav>

        <Buttons>
          <Button
            type="button"
            onClick={() => {
              console.log('Press on bell');
            }}
          >
            <RiNotificationLine size={22} />
          </Button>
          <Button type="button" onClick={signOut}>
            <RiLogoutCircleRLine size={22} />
          </Button>
          <Link to="/home">
            <img src={smallLogo} alt="5Bits logomarca" />
          </Link>
        </Buttons>
      </Content>
    </HeaderContainer>
  );
};

export default Header;
