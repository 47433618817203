/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

import Input from '../../../../../components/Input';
import CheckboxInput from '../../../../../components/CheckboxInput';

import { Container } from './styles';
import InputPhone from '../../../../../components/InputPhone';

interface ICreateFormUserProps {
  saving?: boolean;
  onChangeAdministratorValue: () => void;
}

const CreateFormUser: React.FC<ICreateFormUserProps> = ({
  saving = false,
  onChangeAdministratorValue,
}) => {
  return (
    <Container>
      <header>
        <h1>Cadastro de Usuários</h1>
      </header>

      <div>
        <Input
          id="name"
          name="name"
          type="text"
          labelText="Nome Completo"
          disabled={saving}
          autoComplete="off"
        />
        <InputPhone
          id="phone"
          name="phone"
          type="text"
          labelText="Telefone"
          disabled={saving}
        />

        <Input
          id="email"
          name="email"
          type="text"
          labelText="E-mail"
          disabled={saving}
        />
        <Input
          id="nickname"
          name="nickname"
          type="text"
          labelText="Codnome"
          disabled={saving}
          autoComplete="off"
        />
        <Input
          id="username"
          name="username"
          type="text"
          labelText="Login"
          disabled={saving}
          autoComplete="off"
        />
        <Input
          id="password"
          name="password"
          type="password"
          disabled={saving}
          labelText="Senha"
          autoComplete="new-password"
        />

        <CheckboxInput
          name="isAdmin"
          position="left"
          label="Administrador?"
          onChange={onChangeAdministratorValue}
        />
      </div>
    </Container>
  );
};

export default CreateFormUser;
