import styled from 'styled-components';

export const SplitInvoiceItem = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-end;
  gap: 1.4rem;

  width: 100%;
  margin-bottom: 1.7rem;

  > div {
    label {
      margin-left: 1rem;
    }
  }
`;

export const QuantityPriceSubtotalInvoice = styled.div`
  flex: 1;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-end;
  gap: 1.4rem;

  //Medias
  @media (min-width: 0px) and (max-width: 460px) {
    flex-wrap: wrap;
    min-width: auto;

    > div {
      flex: 1;
    }
  }
  @media (min-width: 461px) and (max-width: 759px) {
    flex-wrap: wrap;

    > div {
      flex: 1;
    }
  }

  @media (min-width: 760px) and (max-width: 999px) {
  }
`;

export const ObservationAndTrashIcon = styled.div`
  flex: 1;
  min-width: 30rem;

  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 1.4rem;

  > div {
    label {
      margin-left: 1rem;
    }
  }

  //Medias
  @media (min-width: 0px) and (max-width: 759px) {
    min-width: 100%;
  }
`;

export const PaydayAndIsPayAndTrash = styled.div`
  flex: 1;
  width: 30rem;
  min-width: 30rem;
  max-width: 30rem;

  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 1.4rem;

  > div {
    label {
      margin-left: 1rem;
    }
  }

  /* Checkbox */
  > div:nth-child(1) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    gap: 0.7rem;
    > span {
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 2.1rem;

      color: var(--cinza-escuro-5bits);
    }

    > div {
      label {
        margin-left: 0;
      }
    }
  }

  /* Icon trash Invoice */
  > div:nth-child(3) {
    margin-top: 2.1rem;
  }

  //Medias
  @media (min-width: 0px) and (max-width: 759px) {
    min-width: 100%;
  }
`;
