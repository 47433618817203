import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const Content = styled.div`
  margin: 1.9rem 4.6rem 2rem;

  header {
    width: fit-content;
    font-weight: 500;
    font-size: 2.4rem;

    margin-left: 1.6rem;
    margin-bottom: 2.7rem;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  //Medias
  @media (min-width: 0px) and (max-width: 759px) {
    margin: 12px 12px;
  }

  @media (min-width: 760px) and (max-width: 999px) {
  }
`;

export const Split = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;

  width: 100%;
  padding-bottom: 3.125rem;
  border-bottom: 0.1rem solid var(--cinza-claro-5bits);

  div {
    label {
      margin-left: 1.6rem;
    }
  }

  //Medias
  @media (min-width: 0px) and (max-width: 759px) {
    flex-wrap: wrap;
    margin-bottom: 30px;
  }

  @media (min-width: 760px) and (max-width: 999px) {
  }
`;

export const SplitLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 50%;
  padding-right: 3rem;
  /* border-right: 1px solid var(--cinza-claro-5bits); */
  hr {
    border: 0.1rem solid var(--cinza-claro-5bits);
    transform: rotate(90deg);
  }
  > div {
    margin-bottom: 1.6rem;
  }

  //Medias
  @media (min-width: 0px) and (max-width: 759px) {
    width: 100%;
    margin: 0;
    padding-right: 0;
  }

  @media (min-width: 760px) and (max-width: 999px) {
  }
`;

export const VerticalLine = styled.div`
  height: 350px;
  border-right: 1px solid var(--cinza-claro-5bits);
  margin-top: 19px;

  //Medias
  @media (min-width: 0px) and (max-width: 759px) {
    display: none;
  }

  @media (min-width: 760px) and (max-width: 999px) {
  }
`;

export const SplitRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 50%;

  padding-left: 3rem;
  > div {
    margin-bottom: 1.6rem;
  }

  /* Div checkbox isActive */
  > div:last-child {
    margin-top: 3rem;
  }

  //Medias
  @media (min-width: 0px) and (max-width: 759px) {
    width: 100%;
    margin: 0;
    padding-left: 0;
  }

  @media (min-width: 760px) and (max-width: 999px) {
  }
`;

export const CityAndUF = styled.div`
  display: flex;
  width: 100%;

  > div:last-child {
    width: 11vw;
    margin-left: 1.5rem;

    input {
      width: 100%;
    }
  }

  //Medias
  @media (min-width: 0px) and (max-width: 759px) {
    flex-wrap: wrap;
    /* Cidade */
    > div:first-child {
      width: 100%;
    }

    /* UF */
    > div:last-child {
      width: 100%;
      margin-left: 0;
      margin-top: 1.6rem;

      input {
        width: 100%;
      }
    }
  }

  @media (min-width: 760px) and (max-width: 999px) {
  }
`;

export const Phones = styled.div`
  display: flex;
  width: 100%;

  div:first-child {
    margin-right: 1.7rem;
  }

  @media only screen and (max-width: 1074px) {
    flex-direction: column;

    div:first-child {
      margin-right: 0;
      margin-bottom: 1.8rem;
    }
  }
`;

export const Materials = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 2.3rem;
  margin-bottom: 10rem;

  > span {
    color: var(--cinza-escuro-5bits);
    font-weight: 500;
    margin-bottom: 0.5rem;
  }
`;
