import React from 'react';
import { NavLink } from 'react-router-dom';

import { IMenu } from '../interfaces';

import { Container } from './styles';

interface IMenuProp extends IMenu {
  closeMenuHamburger: () => void;
}

const Menu: React.FC<IMenuProp> = ({ id, path, label, closeMenuHamburger }) => {
  return (
    <Container>
      <NavLink to={`/${path}`} onClick={closeMenuHamburger}>
        <span className="title">{label}</span>
        <span className="hover-mark" />
      </NavLink>
    </Container>
  );
};

export default Menu;
