import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { FiEdit } from 'react-icons/fi';

import {
  IManufacturing,
  IManufacturingResponse,
} from '../../../../../interfaces/manufacturing';

import { useToast } from '../../../../../context/ToastContext';

import { Container, Content } from './styles';
import api from '../../../../../services/api';

import ListingTemplate from '../../../../../components/Template/Listing';

import { IPermission } from '../../../../../interfaces/permission';
import convertDateStringToDate from '../../../../../utils/convertDateStringToDate';

interface PermissionDTO {
  permissions: IPermission;
}

const ListManufacturing: React.FC<PermissionDTO> = ({ permissions }) => {
  const { addToast } = useToast();
  const [loading, setLoading] = useState<boolean>(false);
  const [listManufacturing, setListManufacturing] = useState<IManufacturing[]>(
    [],
  );
  const [listManufacturingPaginated, setListManufacturingPaginated] = useState<
    IManufacturing[]
  >([]);

  useEffect(() => {
    setLoading(true);

    api
      .get<IManufacturingResponse[]>('manufacturing/steps')
      .then(response => {
        const responseFormatted = response.data.map(i => ({
          ...i,
          createdAt: convertDateStringToDate(i.createdAt),
          updatedAt: convertDateStringToDate(i.updatedAt),
        }));

        setListManufacturing(responseFormatted);
        setListManufacturingPaginated(responseFormatted);

        setLoading(false);
      })
      .catch(error => {
        setLoading(false);

        if (error.response && error.response.data) {
          const { data } = error.response; // Error vindo do back está em data dentro de response
          addToast({
            type: 'error',
            title: data.message,
          });
          return;
        }

        addToast({
          type: 'error',
          title: 'Error',
          description: 'Erro ao listar!',
        });
      });
  }, [addToast]);

  const handleListManufacturingPaginatedItems = useCallback(newList => {
    setListManufacturingPaginated(newList);
  }, []);

  return (
    <ListingTemplate
      title="Listagem de Etapas"
      module="manufacturing/steps"
      listItemsProps={listManufacturing}
      handleListItemsPaginatedItems={handleListManufacturingPaginatedItems}
      displaySearchArea={false}
      disabled={loading}
      permissions={permissions}
    >
      <Container>
        <Content>
          {loading ? (
            <p>carregando ...</p>
          ) : (
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Nome</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {listManufacturingPaginated.map(m => (
                  <tr key={String(m.id)}>
                    <td>{m.id}</td>
                    <td>{m.name}</td>
                    <td>
                      {(permissions.update && (
                        <Link to={`/manufacturing/steps/update/${m.id}`}>
                          <FiEdit size={16} color="var(--azul-5bits)" />
                        </Link>
                      )) || (
                        <span>
                          <FiEdit size={16} color="var(--cinza-escuro-5bits)" />
                        </span>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </Content>
      </Container>
    </ListingTemplate>
  );
};

export default ListManufacturing;
