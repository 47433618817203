/* eslint-disable @typescript-eslint/ban-types */
import { format, parseISO } from 'date-fns';

function convertDateToBr(date: string | Date): string {
  if (date && (typeof date === 'string' || typeof date === 'object')) {
    const regexp = new RegExp(
      /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}/i,
    );

    if (typeof date === 'string') {
      const dateString = parseISO(date);
      const dateFormatted = format(dateString, 'dd/MM/yyyy');

      if (!regexp.test(dateFormatted)) {
        throw new Error('Erro na conversão da data');
      }
      return dateFormatted;
    }
    if (typeof date === 'object') {
      const dateFormatted = format(date, 'dd/MM/yyyy');

      if (!regexp.test(dateFormatted)) {
        throw new Error('Erro na conversão da data');
      }
      return dateFormatted;
    }
  }
  return '';
}

export default convertDateToBr;
