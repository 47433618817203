import styled from 'styled-components';

interface IContentProps {
  isDragging: boolean;
}

export const Container = styled.div`
  padding: 0 17px;
  min-height: 100%;
  flex: 0 0 312px;

  & + div {
    border-left: 1px solid rgba(171, 171, 171, 0.5);
  }
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 42px;
    color: var(--laranja-5bits);
    h2 {
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 2.1rem;
    }
    button {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background: var(--azul-5bits);
      border: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 30px;
      cursor: pointer;
      &:hover {
        filter: brightness(0.9);
      }
    }
  }
`;

export const StepFrames = styled.div.attrs<IContentProps>(props => ({
  style: {
    background: `${props.isDragging ? '#dfdfdf' : 'transparent'}`,
  },
}))<IContentProps>`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  margin-top: 16px;
  padding: 0;
  border-radius: 8px;
  transition: background-color 0.3s ease;
`;
