import styled from 'styled-components';

export const Container = styled.div`
  height: 90%;
`;

export const SearchArea = styled.div`
  display: flex;
  justify-content: end;
  align-items: flex-start;

  //Medias
  @media (min-width: 0px) and (max-width: 759px) {
    width: 100%;
    flex-wrap: wrap;
    /* flex-direction: column; */
    justify-content: space-between;
  }

  @media (min-width: 760px) and (max-width: 999px) {
  }
`;

export const FilterDateArea = styled.div`
  display: flex;
  justify-content: end;
  align-items: flex-end;
`;

export const ContentChildren = styled.div`
  overflow: auto;
  height: auto;
  max-height: 55vh;
`;

export const Content = styled.div`
  margin: 2rem 7rem;
  height: 97%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  header {
    display: flex;
    align-content: center;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 3.6rem;

    form {
      display: flex;
      flex-direction: column;
      justify-content: end;
      align-items: top;

      margin-right: 3rem;
    }

    p {
      display: flex;
      font-weight: 500;
      font-size: 2.4rem;
      margin-right: 2rem;
    }

    a {
      background: var(--button-bg-color);
      height: 45px;
      border-radius: 25px;
      border: 0;
      padding: 0 22.5px;
      color: var(--button-text-color);
      width: 100%;
      font-weight: 700;
      line-height: 2.4rem;
      text-align: center;
      text-transform: uppercase;
      transition: background-color 0.2s;
      display: flex;
      justify-content: center;
      align-items: center;

      width: 116px;
      height: 32px;
      font-size: 1.4rem;
      text-decoration: none;

      &:hover {
        filter: brightness(0.9);
      }
    }
  }

  table {
    background: var(--branco-puro-5bits);
    border: 1px solid var(--cinza-claro-5bits);
    border-radius: 4px;
    border-spacing: 0;

    font-size: 1.4rem;

    width: 100%;
    min-width: 100%;
    /* margin: 0 auto; */

    thead th {
      padding: 10px 8px;
    }

    tbody {
      color: var(--cinza-escuro-5bits);

      tr:nth-child(odd) {
        background-color: var(--branco-gelo-5bits);
      }

      tr:hover {
        background-color: #f0f0f0;
      }

      tr {
        td {
          padding: 5px 8px;
        }
      }
    }

    tr {
      th {
        text-align: left;
      }
      //ID
      th:nth-child(1) {
        width: 40px;
        text-align: right;
      }
      td:nth-child(1) {
        text-align: right;
      }
      //Nome
      th:nth-child(2) {
      }
      td:nth-child(2) {
        width: 250px;
      }
      //Contato
      th:nth-child(3) {
      }
      td:nth-child(3) {
        width: 150px;
      }
      //Endereço
      th:nth-child(4) {
      }
      td:nth-child(4) {
        //width: 120px;
      }
      //Telefone?
      th:nth-child(5) {
        //width: 120px;
      }
      td:nth-child(5) {
        //text-align: center;
      }
      //E-mail
      th:nth-child(6) {
        //width: 70px;
      }
      td:nth-child(6) {
        //text-align: center;
      }
      //Ações
      th:nth-child(7) {
        width: 50px;
      }
      td:nth-child(7) {
        //text-align: center;
      }
    }

    button {
      width: 30px;
      height: 30px;
      background: none;
      border: none;
      border-radius: 50%;
      cursor: pointer;

      &:hover {
        filter: brightness(0.5);
      }
    }
  }

  //Medias
  @media (min-width: 0px) and (max-width: 759px) {
    margin: 2rem 2rem;

    header {
      flex-direction: column;
      flex-wrap: wrap;
      align-content: flex-start;
      margin-bottom: 1.6rem;

      /* Título */
      p {
        margin-bottom: 2rem;
      }

      form {
        width: 100%;
        margin-right: 0px;
        margin-bottom: 2rem;
      }
    }
  }

  @media (min-width: 760px) and (max-width: 999px) {
    margin: 2rem 2rem;

    header {
      margin-bottom: 1.6rem;

      /* Título */
      p {
        margin-bottom: 2rem;
      }
    }
  }
`;
