import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  header {
    display: flex;
    align-content: center;
    justify-content: space-between;
    margin-left: 10px;
    margin-top: 20px;

    h1 {
      display: flex;
      font-weight: 500;
      font-size: 2.4rem;
      color: var(--azul-5bits);
      text-transform: none;
    }
  }

  > div {
    padding: 24px 45px 24px 0;

    > div {
      > label {
        margin-left: 25px;
        margin-bottom: 8px;
      }

      margin-bottom: 18px;
    }

    > button {
      margin: 0 auto;
    }
  }
`;
