import React from 'react';

import { useAuth } from '../../context/AuthContext';
import packageVersion from '../../../package.json';

import convertDateToBr from '../../utils/convertDateToBr';

import { FooterContainer } from './style';

const Footer: React.FC = () => {
  const { configuration, user }: any = useAuth();
  const { version } = packageVersion;

  return (
    <FooterContainer>
      <span>
        {configuration.fantasyName} - Usuário: {user.nickname}
      </span>
      <div>
        <span className="date-implementation">
          Data da implantação: {convertDateToBr(configuration.implantationDate)}
        </span>
        <span className="separator"> - </span>
        <span className="version">Versão: {version}</span>
      </div>
    </FooterContainer>
  );
};

export default Footer;
