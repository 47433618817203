import React, { useCallback, useEffect, useState } from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';

import { StepsProvider, useSteps } from './context/steps';

import Step from './components/Step';

import { Container } from './styles';
import PageLoading from '../../components/PageLoading';

const Workflow: React.FC = () => {
  const {
    steps,
    fetchSteps,
    reorderStepFrameOnStep,
    moveStepFrameBetweenSteps,
  } = useSteps();

  const [loadingSteps, setLoadingSteps] = useState<boolean>(false);

  useEffect(() => {
    setLoadingSteps(true);
    fetchSteps().then(() => setLoadingSteps(false));
  }, [fetchSteps]);

  const onDragEnd = useCallback(
    (result: DropResult): void => {
      const { source, destination } = result;

      // setAllCardsShouldBeClosed(false);

      if (!destination) {
        return;
      }

      if (
        destination.droppableId === source.droppableId &&
        destination.index === source.index
      ) {
        return;
      }

      if (source.droppableId === destination.droppableId) {
        reorderStepFrameOnStep(source, source.index, destination.index);
      } else {
        moveStepFrameBetweenSteps(source, destination);
      }
    },
    [moveStepFrameBetweenSteps, reorderStepFrameOnStep],
  );

  const onBeforeCapture = useCallback(() => {
    // setAllCardsShouldBeClosed(true);
  }, []);

  return (
    <>
      <PageLoading visible={loadingSteps}>
        <p>
          <i>Carregando os dados do workflow...</i>
        </p>
      </PageLoading>
      <Container>
        <DragDropContext
          onDragEnd={onDragEnd}
          onBeforeCapture={onBeforeCapture}
        >
          {steps.map(step => (
            <Step key={String(step.id)} step={step} />
          ))}
        </DragDropContext>
      </Container>
    </>
  );
};

const ContainerWorkflow: React.FC = () => {
  return (
    <StepsProvider>
      <Workflow />
    </StepsProvider>
  );
};

export default ContainerWorkflow;
