/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useCallback, useEffect } from 'react';

import { FormHandles } from '@unform/core';

import typy from 'typy';
import Button from '../../../../../../../../components/Button';

import {
  Container,
  Content,
  ButtonNormal,
  ButtonHighlighted,
  LiButtonControlsArrow,
} from './styles';
import { IMaterialForm } from '../../../../../../../../interfaces/material';

interface PaginationProps {
  formRef: any | FormHandles;
  listItems: Array<any>;
  perPageProp?: number;
  goToLastPage?: boolean;
  // eslint-disable-next-line no-unused-vars
  onChangeListPaginatedItems: (UpdatedLists: {
    newListPageActual: Array<any>;
    newListCompleteUpdated: Array<any>;
  }) => void;
  handleGoToLastPage: () => void;
}

const PaginationAndSetInputsOnComponent: React.FC<PaginationProps> = ({
  formRef,
  listItems,
  perPageProp = 10,
  goToLastPage = false,
  onChangeListPaginatedItems,
  handleGoToLastPage,
}) => {
  const [
    listCompleteAndUpdatedOfTheMaterials,
    setListCompleteAndUpdatedOfTheMaterials,
  ] = useState<Array<IMaterialForm>>([]);
  const [pageActual, setPageActual] = useState(1);
  const [buttons, setButtons] = useState<number[]>([]);

  const perPage = perPageProp;

  const state = {
    perPage,
    totalPages: Math.ceil(listItems.length / perPage), // Arredonda para cima
    maxVisibleButtons: 5,
  };

  // calculateMaxVisibleButtonsPagination
  const calculateMaxVisibleButtonsPagination = useCallback(() => {
    const { maxVisibleButtons } = state;
    let maxLeft = pageActual - Math.floor(maxVisibleButtons / 2);
    let maxRight = pageActual + Math.floor(maxVisibleButtons / 2);

    if (maxLeft < 1) {
      maxLeft = 1;
      maxRight = maxVisibleButtons;
    }

    if (maxRight > state.totalPages) {
      maxLeft = state.totalPages - (maxVisibleButtons - 1);
      maxRight = state.totalPages;

      if (maxLeft < 1) maxLeft = 1;
    }

    return { maxLeft, maxRight };
  }, [pageActual, state]);
  // Fim calculateMaxVisibleButtonsPagination

  // updateButtonsPagination
  const updateButtonsPagination = useCallback((): Array<any> => {
    const newButtons = [];
    const { maxLeft, maxRight } = calculateMaxVisibleButtonsPagination();

    for (let page = maxLeft; page <= maxRight; page += 1) {
      newButtons.push(page);
    }
    return newButtons;
  }, [calculateMaxVisibleButtonsPagination]);
  // Fim updateButtonsPagination

  // update
  const updateButtons = useCallback(() => {
    setButtons(updateButtonsPagination());
  }, [updateButtonsPagination]);
  // Fim update

  const getListMaterialOfThePageActual = useCallback((): Array<any> => {
    const listMaterialsOfThePageActual = formRef.current.getData()
      .projectHasMaterials;

    return listMaterialsOfThePageActual;
  }, [formRef]);

  // updateListCompleteAndUpdatedOfTheMaterials
  const updateListCompleteAndUpdatedOfTheMaterials = useCallback((): void => {
    const listMaterialsOfThePageActual = getListMaterialOfThePageActual();
    // eslint-disable-next-line prefer-const
    let listUpdated: Array<any> = [...listCompleteAndUpdatedOfTheMaterials];

    if (
      listMaterialsOfThePageActual &&
      listMaterialsOfThePageActual.length > 0
    ) {
      // eslint-disable-next-line array-callback-return
      listMaterialsOfThePageActual.map((item: any): void => {
        if (
          Object.entries(item).length > 0 &&
          typy(parseInt(item.id.toString(), 10)).isNumber
        ) {
          const indexOfTheMaterialToBeUpdated = listUpdated.findIndex(
            itemFind => itemFind.id.toString() === item.id.toString(),
          );

          listUpdated[indexOfTheMaterialToBeUpdated] = item;
        } else if (
          item.idNewItemPagination &&
          item.idNewItemPagination !== '' &&
          item.idNewItemPagination !== null
        ) {
          const indexOfTheMaterialToBeUpdated = listUpdated.findIndex(
            itemFind =>
              itemFind.idNewItemPagination === item.idNewItemPagination,
          );

          listUpdated[indexOfTheMaterialToBeUpdated] = item;
        }
      });

      setListCompleteAndUpdatedOfTheMaterials(listUpdated);
      updateButtons();
    }
  }, [
    getListMaterialOfThePageActual,
    listCompleteAndUpdatedOfTheMaterials,
    updateButtons,
  ]);
  // Fim updateListCompleteAndUpdatedOfTheMaterials

  // updateListPaginatedItems
  const updateListPaginatedItems = useCallback((): {
    newListPageActual: Array<any>;
    newListCompleteUpdated: Array<any>;
  } => {
    const page = pageActual - 1;
    const start = page * state.perPage;
    const end = start + state.perPage;

    const paginatedItems = listCompleteAndUpdatedOfTheMaterials.slice(
      start,
      end,
    );

    return {
      newListPageActual: paginatedItems,
      newListCompleteUpdated: listCompleteAndUpdatedOfTheMaterials,
    };
  }, [listCompleteAndUpdatedOfTheMaterials, pageActual, state.perPage]);
  // Fim updateListPaginatedItems

  // Next items
  const next = useCallback(() => {
    if (pageActual + 1 > state.totalPages) {
      setPageActual(pageActual);
    } else {
      setPageActual(pageActual + 1);
    }
    updateListCompleteAndUpdatedOfTheMaterials();
  }, [
    pageActual,
    state.totalPages,
    updateListCompleteAndUpdatedOfTheMaterials,
  ]);
  // End Next Items

  // Previous items
  const prev = useCallback(() => {
    if (pageActual - 1 < 1) {
      setPageActual(pageActual);
    } else {
      setPageActual(pageActual - 1);
    }
    updateListCompleteAndUpdatedOfTheMaterials();
  }, [pageActual, updateListCompleteAndUpdatedOfTheMaterials]);
  // End Previous Items

  // GoTo items
  const goTo = useCallback(
    (page: number) => {
      if (page < 1) {
        // eslint-disable-next-line no-param-reassign
        page = 1;
      }

      setPageActual(page);

      if (page > state.totalPages) {
        setPageActual(state.totalPages);
      }
      updateListCompleteAndUpdatedOfTheMaterials();
    },
    [state.totalPages, updateListCompleteAndUpdatedOfTheMaterials],
  );
  // End GoTo items

  useEffect(() => {
    setListCompleteAndUpdatedOfTheMaterials(listItems);
  }, [listItems]);

  useEffect(() => {
    setButtons(updateButtonsPagination());
    const updatedLists = updateListPaginatedItems();
    onChangeListPaginatedItems(updatedLists);

    if (goToLastPage) {
      goTo(state.totalPages);
      handleGoToLastPage();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listCompleteAndUpdatedOfTheMaterials, pageActual]);

  return (
    <Container>
      <Content>
        <nav aria-label="Page navigation">
          <ul>
            <LiButtonControlsArrow>
              <Button onClick={() => goTo(1)}>
                <span aria-hidden="true">&laquo;</span>
              </Button>
            </LiButtonControlsArrow>
            <LiButtonControlsArrow>
              <Button onClick={prev}>
                <span aria-hidden="true">&lt;</span>
              </Button>
            </LiButtonControlsArrow>

            {buttons.map((item, index) => (
              <li key={index.toString()}>
                {(item === pageActual && (
                  <ButtonHighlighted>
                    <Button onClick={() => goTo(item)}>{item}</Button>
                  </ButtonHighlighted>
                )) || (
                  <ButtonNormal>
                    <Button onClick={() => goTo(item)}>{item}</Button>
                  </ButtonNormal>
                )}
              </li>
            ))}

            <LiButtonControlsArrow>
              <Button onClick={next}>
                <span aria-hidden="true">&gt;</span>
              </Button>
            </LiButtonControlsArrow>
            <LiButtonControlsArrow>
              <Button onClick={() => goTo(state.totalPages)}>
                <span aria-hidden="true">&raquo;</span>
              </Button>
            </LiButtonControlsArrow>
          </ul>
        </nav>
      </Content>
    </Container>
  );
};

export default PaginationAndSetInputsOnComponent;
