/* eslint-disable @typescript-eslint/no-explicit-any */
import fincalc from 'fincalc';
import api from '../../../services/api';

import convertDateStringToDate from '../../../utils/convertDateStringToDate';
import convertDateToBr from '../../../utils/convertDateToBr';
import convertNumberFloatToBr from '../../../utils/convertNumberFloatToBr';
import convertNumberFloatToDB from '../../../utils/convertNumberFloatToDB';

import { IProjectResponse } from '../../../interfaces/project';

async function getProject(projectId: number): Promise<void> {
  const response = await api.get<IProjectResponse>(`projects/${projectId}`);

  let newDrawings: Array<any> = [];
  let newProjectHasMaterials: Array<any> = [];
  let newProjectHasServices: Array<any> = [];

  if (response.data) {
    if (response.data.drawings && response.data.drawings.length > 0) {
      newDrawings = response.data.drawings.map(item => {
        return {
          ...item,
          revisionDate: convertDateToBr(item.revisionDate),
        };
      });
    }

    // projectHasMaterials
    if (
      response.data.projectHasMaterials &&
      response.data.projectHasMaterials.length > 0
    ) {
      newProjectHasMaterials = response.data.projectHasMaterials.map(item => {
        const totalPriceCalculated =
          item.totalPrice !== null
            ? item.totalPrice
            : fincalc(
                convertNumberFloatToDB(convertNumberFloatToBr(item.quantity)) *
                  convertNumberFloatToDB(
                    convertNumberFloatToBr(item.unitPrice),
                  ),
              );

        return {
          ...item,
          quantity: item.quantity,
          unitPrice: convertNumberFloatToBr(item.unitPrice),
          totalPrice: convertNumberFloatToBr(totalPriceCalculated),
        };
      });
    }

    // projectHasServices
    if (
      response.data.projectHasServices &&
      response.data.projectHasServices.length > 0
    ) {
      newProjectHasServices = response.data.projectHasServices.map(item => {
        return {
          ...item,
          unitPrice: convertNumberFloatToBr(item.unitPrice),
          totalPrice: convertNumberFloatToBr(item.totalPrice),
        };
      });
    }
  }

  const newResponse: any = {
    ...response.data,
    forecastEndDate: convertDateStringToDate(response.data.forecastEndDate),
    technicalValidatorDate: convertDateToBr(
      response.data.technicalValidatorDate,
    ),
    operationalValidatorDate: convertDateToBr(
      response.data.operationalValidatorDate,
    ),
    projectValidatorDate: convertDateToBr(response.data.projectValidatorDate),
    drawings: [...newDrawings],
    projectHasMaterials: [...newProjectHasMaterials],
    projectHasServices: [...newProjectHasServices],
    /*
     * projectHasMaterialsPurchasing é o mesmo que projectHasMaterials porém é usado na aba Compras e
     * não em Planejamento.
     * Tem que ser nomes diferentes por causa do scope das abas Planning e Purchasing. Mas a lista é a
     * mesma newProjectHasMaterials.
     */
    projectHasMaterialsPurchasing: [...newProjectHasMaterials],
  };

  return newResponse;
}

export default getProject;
