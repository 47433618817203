import React from 'react';

import { ModalBackground, ModalArea } from './styles';

interface ModalDTO {
  visibleProp: boolean;
  className?: string;
}

const Modal: React.FC<ModalDTO> = ({ visibleProp, children }) => {
  return (
    <>
      {visibleProp === true && (
        <ModalBackground>
          <ModalArea>{children}</ModalArea>
        </ModalBackground>
      )}
    </>
  );
};

export default Modal;
