/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState, useCallback } from 'react';

import { useToast } from '../../../../context/ToastContext';

import api from '../../../../services/api';

import SubtitleWorkflow from './components/SubtitleWorkflow';
import ContainerOSOnStage from './components/ContainerOSOnStage';

import {
  Container,
  MaskOfTheCardsUnselected,
  NameSectorAndLegend,
  Content,
  ContainerProductionStages,
  ContainerTitleStages,
  ContainerTitleAndContainerOSOnStage,
  TitleStage,
} from './styles';

import { IManufacturingResponse } from '../../../../interfaces/manufacturing';
import convertDateStringToDate from '../../../../utils/convertDateStringToDate';
import ModalLoading from '../../../../components/ModalLoading';
import verifyIfHasScrollInElement from '../../../../utils/verifyIfHasScrollInElement';

const ManufacturingWorkflow: React.FC = () => {
  const { addToast } = useToast();
  const [loading, setLoading] = useState<boolean>(false);
  const [listSteps, setListSteps] = useState<any>([]);
  const [listOSProjects, setListOSProjects] = useState<any>([]);
  const [idCompanyClicked, setIdCompanyClicked] = useState(0);

  useEffect(() => {
    setLoading(true);

    api
      .get<IManufacturingResponse[]>('manufacturing/steps')
      .then(response => {
        const responseFormatted = response.data.map(i => ({
          ...i,
          createdAt: convertDateStringToDate(i.createdAt),
          updatedAt: convertDateStringToDate(i.updatedAt),
        }));

        setListSteps(responseFormatted);

        setLoading(false);
      })
      .catch(error => {
        setLoading(false);

        addToast({
          type: 'error',
          title: 'Erro ao listar',
          description: `Erro: ${error}`,
        });
      });
  }, [addToast]);

  // Get list O.S
  const getListOS = useCallback(async () => {
    try {
      setLoading(true);

      const response: any = await api.get('manufacturing/workflow');

      if (Array.isArray(response.data)) {
        const responseFormatted: IManufacturingResponse[] = response.data.map(
          (i: IManufacturingResponse) => ({
            ...i,
            createdAt: convertDateStringToDate(i.createdAt),
            updatedAt: convertDateStringToDate(i.updatedAt),
          }),
        );

        /**
         * O sort altera o array original
         *
         * Essa função deve retornar um número negativo se o primeiro objeto tem forecastStartDateTime preenchido mantendo-o antes do segundo,
         * um número positivo se o segundo objeto tem forecastStartDateTime preenchido passando-o para antes do primeiro,
         * e zero se ambos são iguais, tanto com null ou com forecastStartDateTime preenchido.
         */
        responseFormatted.sort((previousObject: any, laterObject: any) => {
          if (
            previousObject.forecastStartDateTime !== null &&
            laterObject.forecastStartDateTime === null
          ) {
            return -1;
          }
          if (
            previousObject.forecastStartDateTime === null &&
            laterObject.forecastStartDateTime !== null
          ) {
            return 1;
          }
          return 0;
        });

        setListOSProjects(responseFormatted);
      } else {
        addToast({
          type: 'info',
          title: response.data.message,
        });
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);

      addToast({
        type: 'error',
        title: 'Erro ao listar',
        description: `Erro: ${error}`,
      });
    }
  }, [addToast]);

  useEffect(() => {
    getListOS();
  }, [getListOS]);

  const updateScreenWorkflow = useCallback(
    async (removeMask: boolean) => {
      if (removeMask === true) {
        setIdCompanyClicked(0);
      }
      await getListOS();
    },
    [getListOS],
  );

  const [colorOrangeBorderCardOS, setColorOrangeBorderCardOS] = useState(false);
  const [idCardOver, setIdCardOver] = useState(false);

  const colorOrangeBorderCard = useCallback(idCard => {
    setIdCardOver(idCard);
    setColorOrangeBorderCardOS(true);
  }, []);

  const handleCloseDescriptionOfCompany = useCallback(
    (e: any, id: number) => {
      /*
       * e.target.className.match('isNotTheParentContainer')); verifica se
       * o elemento clicado tem a className isNotTheParentContainer. Se
       * sim não redefine setIdCompanyClicked evitando por exemplo de
       * fechar a máscara transparente, pois esta deve ser fechada apenas
       * clicando na div do container. A função match é para o caso de ter mais
       * de uma class no elemento.
       * */
      if (
        idCompanyClicked === id &&
        e.target.className.match('isNotTheParentContainer') === null
      ) {
        setIdCompanyClicked(0);
        return;
      }

      setIdCompanyClicked(id);
      colorOrangeBorderCard(id);
    },
    [colorOrangeBorderCard, idCompanyClicked],
  );

  const [dimensions, setDimensions] = useState({
    hasScrollHorizontal: false,
    hasScrollVertical: false,
    width: '',
    height: '',
  });

  const handleDimensionScreen = useCallback(() => {
    setTimeout(() => {
      setDimensions(verifyIfHasScrollInElement('containerCards'));
    }, 200);
  }, []);

  return (
    <Container id="containerCards" style={{ padding: '0rem' }}>
      <ModalLoading visible={loading}>
        <p>
          <i>Carregando...</i>
        </p>
      </ModalLoading>

      <MaskOfTheCardsUnselected
        dimensions={dimensions}
        displayMask={idCompanyClicked !== 0}
      />

      <NameSectorAndLegend hasMaskOnTheContainer={idCompanyClicked !== 0}>
        <span>Produção</span>
        <SubtitleWorkflow />
      </NameSectorAndLegend>
      <Content>
        <ContainerProductionStages>
          {listSteps && listSteps.length > 0 && (
            <ContainerTitleStages>
              {listOSProjects &&
                listSteps.map((itemEtapa: any, index: number) => {
                  const item = listOSProjects.filter(
                    (itemEtapasEmpresa: any) =>
                      itemEtapasEmpresa.manufacturingStepId === itemEtapa.id,
                  );

                  return (
                    <ContainerTitleAndContainerOSOnStage key={String(index)}>
                      <TitleStage
                        hasMaskOnTheContainer={idCompanyClicked !== 0}
                      >
                        <span>{itemEtapa.name}</span>
                      </TitleStage>
                      <div>
                        {item.length > 0 &&
                          item.map((i: any, subIndex: number) => (
                            <ContainerOSOnStage
                              key={String(subIndex)}
                              idCompanyClicked={idCompanyClicked}
                              itemOS={i}
                              onClick={e => {
                                handleCloseDescriptionOfCompany(
                                  e,
                                  i.project.id,
                                );
                              }}
                              onMouseOver={() => {
                                if (idCompanyClicked === 0)
                                  colorOrangeBorderCard(i.project.id);
                              }}
                              onMouseOut={() => {
                                if (idCompanyClicked === 0)
                                  setColorOrangeBorderCardOS(false);
                              }}
                              colorOrangeBorderCardOSProp={
                                i.project.id === idCardOver
                                  ? colorOrangeBorderCardOS
                                  : false
                              }
                              handleDimensionScreen={handleDimensionScreen}
                              updateScreenWorkflowProp={updateScreenWorkflow}
                            />
                          ))}
                      </div>
                    </ContainerTitleAndContainerOSOnStage>
                  );
                })}
            </ContainerTitleStages>
          )}
        </ContainerProductionStages>
      </Content>
    </Container>
  );
};

export default ManufacturingWorkflow;
