/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useEffect, useRef, InputHTMLAttributes } from 'react';
import { useField } from '@unform/core';

import { Container } from './styles';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  fontSizeText?: string;
  lineHeight?: string;
  sizeInputRadio?: string;
  options: {
    id: string;
    value: string;
    option: string;
  }[];
}
export default function RadioInput({
  name,
  fontSizeText = '',
  lineHeight = '',
  sizeInputRadio = '',
  options,
  ...rest
}: Props) {
  const inputRefs = useRef<HTMLInputElement[]>([]);
  const { fieldName, registerField, defaultValue = '' } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs.current,
      getValue: (refs: HTMLInputElement[]) => {
        if (refs) {
          return refs.find(ref => (ref ? ref.checked : ''))?.value || '';
        }
        return '';
      },
      setValue: (refs: HTMLInputElement[], id: string) => {
        if (refs) {
          const inputRef = refs.find(ref => (ref ? ref.id === id : ''));
          if (inputRef) inputRef.checked = true;
        }
      },
      clearValue: (refs: HTMLInputElement[]) => {
        if (refs) {
          const inputRef = refs.find(ref => (ref ? ref.checked === true : ''));
          if (inputRef) inputRef.checked = false;
        }
      },
    });
  }, [defaultValue, fieldName, registerField]);

  return (
    <Container
      fontSize={fontSizeText}
      lineHeight={lineHeight}
      sizeInput={sizeInputRadio}
    >
      {options.map(option => (
        <label htmlFor={option.id} key={option.id}>
          <input
            ref={ref => inputRefs.current.push(ref as HTMLInputElement)}
            id={option.id}
            type="radio"
            name={name}
            defaultChecked={defaultValue.includes(option.id)}
            value={option.value}
            {...rest}
          />
          {option.option}
        </label>
      ))}
    </Container>
  );
}
