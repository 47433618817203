import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div``;

export const Content = styled.div`
  margin: 35px 70px;

  header {
    display: flex;
    align-content: center;
    justify-content: space-between;
    margin-bottom: 70px;

    p {
      display: flex;
      font-weight: 500;
      font-size: 2.4rem;
    }
  }

  table {
    background: var(--branco-puro-5bits);
    font-size: 1.4rem;

    width: 100%;
    max-width: 1100px;

    border-radius: 4px;
    border-spacing: 0;
    border: 1px solid var(--cinza-claro-5bits);
    table,
    th,
    td {
      border: 1px solid var(--cinza-claro-5bits);
      border-spacing: 0;
      border-collapse: collapse;
    }

    td {
      height: 39px;
    }

    thead th {
      padding: 10px 8px;
    }

    tbody {
      color: var(--cinza-escuro-5bits);

      tr:nth-child(odd) {
        background-color: var(--branco-gelo-5bits);
      }

      tr:hover {
        background-color: #f0f0f0;
      }

      tr {
        td {
          padding: 9px 8px;
        }
      }
    }

    tr {
      th {
        text-align: left;
      }
      //ID
      th:nth-child(1) {
        width: 40px;
        text-align: right;
      }
      td:nth-child(1) {
        text-align: right;
      }
      //Nome
      th:nth-child(2) {
      }
      td:nth-child(2) {
      }
      //Codnome
      th:nth-child(3) {
      }
      td:nth-child(3) {
      }
      //Username
      th:nth-child(4) {
      }
      td:nth-child(4) {
      }
      //Administrador?
      th:nth-child(5) {
        width: 120px;
      }
      td:nth-child(5) {
        //text-align: center;
      }
      //Ativo?
      th:nth-child(6) {
        width: 70px;
      }
      td:nth-child(6) {
        //text-align: center;
      }
      //Ações
      th:nth-child(7) {
        width: 50px;
      }
      td:nth-child(7) {
        //text-align: center;
      }
    }

    a {
      background: none;
      cursor: pointer;

      transition: background-color 0.2s;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        filter: brightness(0.5);
      }
    }
  }
`;

export const ButtonLink = styled(Link)<{ isDisabled: boolean }>`
  background: var(--button-bg-color);
  height: 45px;
  border-radius: 25px;
  border: 0;
  padding: 0 22.5px;
  color: var(--button-text-color);
  width: 100%;
  font-weight: 700;
  line-height: 2.4rem;
  text-align: center;
  text-transform: uppercase;
  transition: background-color 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 116px;
  height: 32px;
  font-size: 1.4rem;
  text-decoration: none;

  &:hover {
    filter: brightness(0.9);
  }

  ${props =>
    props.isDisabled &&
    css`
      /* https://developer.mozilla.org/en-US/docs/Web/CSS/pointer-events */
      pointer-events: none;
      opacity: 0.6;
      cursor: not-allowed;

      &:hover {
        filter: brightness(1);
      }
    `}
`;
