import React, { useEffect } from 'react';
import {
  FiAlertCircle,
  FiCheckCircle,
  FiInfo,
  FiXCircle,
} from 'react-icons/fi';

import { ToastMessage, useToast } from '../../../context/ToastContext';

import { Container } from './styles';

interface ToastProps {
  message: ToastMessage;
}

/*
Define o icone por tipo de type
*/
const icons = {
  info: <FiInfo size={24} />,
  error: <FiAlertCircle size={24} />,
  success: <FiCheckCircle size={24} />,
};

const Toast: React.FC<ToastProps> = ({ message }) => {
  const { removeToast } = useToast();
  /*
  Remove o toast depois de 3 segundos. Lembrando que
  o useEffect é executado assim que o componente é criado.
  Se dentro de qualquer useEffect estiver retornando uma função,
  ela será executada assim que o componente deixar de existir.
  Sendo assim retornará uma função que cancelará o timer
  se o usuário clicar no botão de fechar o toast.
  */

  useEffect(() => {
    if (message.type !== 'error') {
      const timer = setTimeout(() => {
        removeToast(message.id);
      }, 5000);

      return () => {
        clearTimeout(timer);
      };
    }
    return () => {
      clearTimeout(0);
    };
  }, [removeToast, message.id, message.type]);
  /**
   * hasDescription = um toast pode ter ou não uma descrição, isso será controlado
   * com a hasDescription. Quando não tiver a tag <p> quer dizer que não tem descrição
   */
  return (
    <Container type={message.type} hasDescription={!!message.description}>
      {icons[message.type || 'info']}

      <div>
        <strong>{message.title}</strong>
        {message.description && <p>{message.description}</p>}
      </div>

      <button type="button" onClick={() => removeToast(message.id)}>
        <FiXCircle size={18} />
      </button>
    </Container>
  );
};

export default Toast;
