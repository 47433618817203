import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 1.6rem;
`;

export const Content = styled.div`
  table {
    background: var(--branco-puro-5bits);
    font-size: 1.4rem;

    width: 100%;
    max-width: 1100px;

    border-radius: 4px;

    table,
    th,
    td {
      border: 1px solid var(--cinza-claro-5bits);
      border-spacing: 0;
      border-collapse: collapse;
    }

    thead th {
      padding: 10px 8px;
    }

    tbody {
      color: var(--cinza-escuro-5bits);

      tr:nth-child(odd) {
        background-color: var(--branco-gelo-5bits);
      }

      tr:hover {
        background-color: #f0f0f0;
      }

      tr {
        td {
          padding: 0.9rem 0.8rem;

          font-weight: 500;
          font-size: 1.4rem;
          line-height: 1.9rem;
        }
      }
    }

    tr {
      th {
        text-align: left;
      }
      //Nº
      th:nth-child(1) {
        text-align: right;
      }
      td:nth-child(1) {
        min-width: 6.4rem;
        width: 6.4rem;

        text-align: right;
        font-weight: bold;
        white-space: nowrap;
      }
      //Cliente
      th:nth-child(2) {
      }
      td:nth-child(2) {
        min-width: 29rem;
      }
      //Descrição
      th:nth-child(3) {
      }
      td:nth-child(3) {
        min-width: 34.6rem;
        text-transform: lowercase;
      }
      //Setor
      th:nth-child(4) {
      }
      td:nth-child(4) {
        width: 10.7rem;
        max-width: 10.7rem;
      }
      //PC
      th:nth-child(5) {
      }
      td:nth-child(5) {
        min-width: 5.1rem;
        width: 5.1rem;
        max-width: 5.1rem;
      }
      //Status
      th:nth-child(6) {
      }
      td:nth-child(6) {
        width: 9.5rem;
        max-width: 9.5rem;
      }
      //Ações
      th:nth-child(7) {
      }
      td:nth-child(7) {
        width: 13rem;
      }
    }

    > button {
      width: 30px;
      height: 30px;
      background: none;
      border: none;
      border-radius: 50%;
      cursor: pointer;

      &:hover {
        filter: brightness(0.5);
      }
    }
  }
`;

export const InfoPurchaseOrder = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  > button {
    width: fit-content;
    height: fit-content;
    color: var(--azul-5bits);
    text-transform: capitalize;
    padding: 0rem;
    font-size: 1.28rem;
    font-weight: 500;
    background: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const Actions = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
`;

export const ItemActions = styled.div`
  width: fit-content;
  margin-right: 0.7rem;

  > svg {
    cursor: pointer;
  }
`;
