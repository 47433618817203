import styled, { css } from 'styled-components';

interface ContentProps {
  isFocused: boolean;
  isFilled: boolean;
  isErrored: boolean;
  isDisabled: boolean;
  isReadOnly: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  color: var(--cinza-escuro-5bits);

  label {
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 2.1rem;
    text-align: center;
  }
`;

export const Content = styled.div<ContentProps>`
  background: var(--input-bg-color);
  border-radius: 25px;
  border: 1px solid var(--input-border-color);
  padding: 9px 20px;
  width: 100%;
  height: 40px;
  color: var(--input-text-color);

  display: flex;
  align-items: flex-start;
  justify-content: center;

  & + div {
    margin-top: 8px;
  }

  ${props =>
    (props.isDisabled || props.isReadOnly) &&
    css`
      filter: brightness(0.9);

      &:hover {
        filter: brightness(0.9);
      }
    `}

  ${props =>
    props.isErrored &&
    css`
      border-color: var(--on-errored-input-border-color);
    `}

  ${props =>
    props.isFocused &&
    css`
      color: var(--on-focus-input-text-color);
      border-color: var(--on-focus-input-border-color);
    `}

  ${props =>
    props.isFilled &&
    css`
      color: var(--on-filled-input-text-color);
      border-width: 1px;
    `}


  input {
    flex: 1;

    width: 100%;
    background-color: var(--input-bg-color);
    border: 0;

    ${props =>
      ((props.isDisabled || props.isReadOnly) &&
        css`
          color: var(--cinza-claro-5bits);
          font-weight: 500;
        `) ||
      css`
        color: var(--input-text-color);
        font-weight: 500;
      `}

    &::placeholder {
      color: var(--input-placeholder-color);
    }

    &:disabled {
      cursor: not-allowed;
    }
  }

  svg {
    margin-right: 16px;
  }
`;
