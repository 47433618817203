/* eslint-disable no-param-reassign */
import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { cpf as cpfValidator, cnpj as cnpjValidator } from 'cpf-cnpj-validator';

import api from '../../../services/api';

import getValidationErrors from '../../../utils/getValidationError';
import validationCPF from '../../../utils/validationCPF';
import { useToast } from '../../../context/ToastContext';

import Loading from '../../../components/Loading';
import InputHidden from '../../../components/InputHidden';
import Input from '../../../components/Input';
import SimpleSelect from '../../../components/SimpleSelect';
import CheckboxInput from '../../../components/CheckboxInput';
import Button from '../../../components/Button';

import ufList from '../../../data/ufList.json';

import { IClient, IClientResponse } from '../../../interfaces/client';

import {
  Container,
  Content,
  Split,
  SplitLeft,
  VerticalLine,
  SplitRight,
  CityAndUF,
} from './styles';
import InputPhone from '../../../components/InputPhone';
import convertDateStringToDate from '../../../utils/convertDateStringToDate';

const UpdateClient: React.FC = () => {
  const { id }: any = useParams();
  const formRef = useRef<FormHandles>(null);
  const navigate = useNavigate();
  const { addToast } = useToast();

  // const [loading, setLoading] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [clients, setClients] = useState<IClient>({} as IClient);

  useEffect(() => {
    // setLoading(true);

    api
      .get<IClientResponse>(`clients/${id}`)
      .then(response => {
        const newResponse = {
          ...response.data,
          cpfCnpj:
            response.data.cpfCnpj.length === 14
              ? cnpjValidator.format(response.data.cpfCnpj)
              : cpfValidator.format(response.data.cpfCnpj),
          createdAt: convertDateStringToDate(response.data.createdAt),
          updatedAt: convertDateStringToDate(response.data.updatedAt),
        };

        setClients(newResponse);

        formRef.current?.setData(newResponse);

        // setLoading(false);
      })
      .catch((error: any) => {
        // setLoading(false);

        addToast({
          type: 'error',
          title: 'Error',
          description: `Erro ao carregar!`,
        });
      });
  }, [addToast, id]);

  const handleFormSubmit = useCallback(
    async (dataForm: IClientResponse, { reset }) => {
      try {
        setSaving(true);

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('O Nome do Cliente é obrigatório'),
          cpfCnpj: Yup.string()
            .min(11)
            .test('cpfCnpj', 'CPF/CNPJ inválido', () => {
              return validationCPF(dataForm.cpfCnpj);
            })
            .required('O CPF/CNPJ é obrigatório'),
          contact: Yup.string().required('O Contato é obrigatório'),
        });

        await schema.validate(dataForm, { abortEarly: false });

        const newData: any = {
          ...dataForm,
          id: parseInt(id, 10),
        };

        delete newData.cpfCnpj;

        await api.put('clients', newData);

        addToast({
          type: 'success',
          title: 'Atualização realizada com sucesso',
        });

        reset();
        setSaving(false);

        navigate('/clients');
      } catch (error: any) {
        setSaving(false);

        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);

          return;
        }

        if (error.response) {
          const { data } = error.response; // Error vindo do back está em data dentro de response

          addToast({
            type: 'error',
            title: 'Erro na atualização',
            description: data.message,
          });
          if (
            formRef.current?.getFieldRef(data.field) &&
            data.field.toString().indexOf('_id') === -1
          ) {
            formRef.current?.getFieldRef(data.field).focus(); // Foca o campo que deu erro de acordo com o field retornado do back
          }

          return;
        }

        addToast({
          type: 'error',
          title: 'Error',
          description: `Erro ao atualizar!${error}`,
        });
      }
    },
    [addToast, navigate, id],
  );

  return (
    <Container>
      <Content>
        <header>
          <p>Atualização de Cliente</p>
        </header>
        <Form ref={formRef} initialData={clients} onSubmit={handleFormSubmit}>
          <InputHidden type="hidden" name="id" />
          <Split>
            <SplitLeft>
              <Input
                id="name"
                name="name"
                type="text"
                labelText="Nome"
                disabled={saving}
              />
              <Input
                id="cpfCnpj"
                name="cpfCnpj"
                type="text"
                labelText="CPF/CNPJ"
                disabled
              />
              <Input
                id="contact"
                name="contact"
                type="text"
                labelText="Contato"
                disabled={saving}
              />
              <Input
                id="address"
                name="address"
                type="text"
                labelText="Endereço"
                disabled={saving}
              />
            </SplitLeft>

            <VerticalLine />

            <SplitRight>
              <Input
                id="neighborhood"
                name="neighborhood"
                type="text"
                labelText="Bairro"
                disabled={saving}
              />
              <CityAndUF>
                <Input
                  id="city"
                  name="city"
                  type="text"
                  labelText="Cidade"
                  disabled={saving}
                />
                <SimpleSelect
                  id="uf"
                  name="uf"
                  labelText="UF"
                  placeholder=""
                  options={ufList}
                  getOptionLabel={option => option.value}
                  disabled={saving}
                />
              </CityAndUF>
              <InputPhone
                id="phone"
                name="phone"
                type="text"
                labelText="Telefone"
                disabled={saving}
              />

              <Input
                id="email"
                name="email"
                type="text"
                labelText="E-mail"
                disabled={saving}
              />
              <CheckboxInput name="isActive" position="left" label="Ativo?" />
            </SplitRight>
          </Split>
          <Button type="submit" disabled={saving}>
            {saving ? <Loading size={24} color="white" /> : 'Salvar'}
          </Button>
        </Form>
      </Content>
    </Container>
  );
};

export default UpdateClient;
