import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';

import { IconBaseProps } from 'react-icons';
import { FiAlertCircle } from 'react-icons/fi';
import { BsSearch } from 'react-icons/bs';
import { useField } from '@unform/core';

import Error from '../../styles/errorFieldGlobal';
import { Container, Content, ButtonSearch } from './styles';

interface InterfaceInputSearchWithButton
  extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  labelText?: string;
  icon?: React.ComponentType<IconBaseProps>;
  isHidden?: boolean;
  mask?: string;
}

const InputSearchWithButton: React.FC<InterfaceInputSearchWithButton> = ({
  name,
  labelText,
  icon: Icon,
  disabled,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [isFilled, setIsFilled] = useState<boolean>(false);

  const {
    fieldName,
    defaultValue,
    error,
    clearError,
    registerField,
  } = useField(name);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);

    if (inputRef.current) {
      setIsFilled(!!inputRef.current.value);
    }
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  const handleChange = useCallback((): void => {
    if (error) {
      clearError();
    }
  }, [clearError, error]);

  return (
    <Container>
      {labelText && <label htmlFor={`id-${name}`}>{labelText}</label>}
      <Content
        isErrored={!!error}
        isFocused={isFocused}
        isFilled={isFilled}
        isDisabled={!!disabled}
      >
        {Icon && <Icon size={16} />}
        <input
          id={`id-${name}`}
          type="text"
          ref={inputRef}
          defaultValue={defaultValue}
          aria-describedby="basic-addon1"
          onFocus={(): void => setIsFocused(true)}
          onBlur={handleInputBlur}
          onChange={handleChange}
          disabled={disabled}
          {...rest}
        />
      </Content>
      <ButtonSearch type="submit" className="text-uppercase">
        <span>Pesquisar</span>
        <BsSearch color="var(--branco-puro-5bits)" size={16} />
      </ButtonSearch>
      {error && (
        <Error>
          <FiAlertCircle color="var(--on-errored-message-bg-color)" size={20} />
          {error}
        </Error>
      )}
    </Container>
  );
};

export default InputSearchWithButton;
