import React, { useState, useEffect, useCallback, HTMLAttributes } from 'react';
import { RiTimeLine } from 'react-icons/ri';
import { differenceInHours, differenceInCalendarDays } from 'date-fns';
import convertDateToBr from '../../../../../../utils/convertDateToBr';
import Button from '../../../../../../components/Button';

import ModalServiceExecution from '../ModalServiceExecution';

import {
  ContainerOSOnStage,
  OSOnStage,
  ContainerInformationOS,
  ButtonExpandDescriptionOS,
  ContainerDescriptionOS,
  DescriptionOS,
  ButtonEditOS,
  FooterInformationContainer,
  FooterInformation,
} from './styles';
import convertDateStringToDate from '../../../../../../utils/convertDateStringToDate';
import convertDateAndHoursToBr from '../../../../../../utils/convertDateAndHoursToBr';

interface IOSOnStage {
  id: number;
  stageName: string;
  numberOS: number;
  deliveryDate: string;
  descriptions: Array<any>;
}

interface ICardOSOnStage extends HTMLAttributes<HTMLDivElement> {
  idCompanyClicked?: number;
  itemOS: any;
  colorOrangeBorderCardOSProp: boolean;
  handleDimensionScreen: () => void;
  updateScreenWorkflowProp: (removeMask: boolean) => void;
}

const CardOSOnStage: React.FC<ICardOSOnStage> = ({
  idCompanyClicked,
  itemOS,
  colorOrangeBorderCardOSProp = false,
  handleDimensionScreen,
  updateScreenWorkflowProp,
  ...rest
}) => {
  const [visibleDescriptionOS, setVisibleDescriptionOS] = useState(false);
  const [
    textOfDelayOfTheFooter,
    setTextOfDelayOfTheFooter,
  ] = useState<string>();
  const [openModalServiceExecution, setOpenModalServiceExecution] = useState(
    false,
  );
  const [zIndexAfterClicked, setZIndexAfterClicked] = useState(false);

  // handleOpenDescriptionOS
  const handleOpenDescriptionOS = useCallback(() => {
    setVisibleDescriptionOS(!visibleDescriptionOS);
  }, [visibleDescriptionOS]);

  useEffect(() => {
    handleDimensionScreen();
  }, [handleDimensionScreen, visibleDescriptionOS]);

  // handleOpenModalServiceExecution
  const handleOpenModalServiceExecution = useCallback(() => {
    setOpenModalServiceExecution(!openModalServiceExecution);
  }, [openModalServiceExecution]);

  // handleCloseModalServiceExecution
  const handleCloseModalServiceExecution = useCallback(
    (removeMask: boolean) => {
      setOpenModalServiceExecution(!openModalServiceExecution);
      updateScreenWorkflowProp(removeMask);
    },
    [openModalServiceExecution, updateScreenWorkflowProp],
  );

  // handleDelayOS
  const handleDelayOS = useCallback(() => {
    const forecastEndDateTimeAsObjectDate = convertDateStringToDate(
      itemOS.forecastEndDateTime,
    );

    if (forecastEndDateTimeAsObjectDate !== undefined) {
      const dateActual = new Date();

      // Dias atrasado
      const totalDelayDays = differenceInCalendarDays(
        dateActual,
        forecastEndDateTimeAsObjectDate,
      );

      if (totalDelayDays > 0) {
        const textWithTotalOfDaysInDelay = `${totalDelayDays}
        DIA${totalDelayDays > 1 ? 'S' : ''} EM ATRASO`;
        setTextOfDelayOfTheFooter(textWithTotalOfDaysInDelay);
        return;
      }

      // Horas atrasado
      const differenceInHoursBetweenForecastEndDateTimeAndDateActual = differenceInHours(
        dateActual,
        forecastEndDateTimeAsObjectDate,
      );

      differenceInHoursBetweenForecastEndDateTimeAndDateActual > 0
        ? setTextOfDelayOfTheFooter('EM ATRASO')
        : setTextOfDelayOfTheFooter(undefined);
    }
  }, [itemOS.forecastEndDateTime]);

  // const [colorOrangeBorderCardOS, setColorOrangeBorderCardOS] = useState(false);

  const [
    colorOfTheTitleAndExpandButton,
    setColorOfTheTitleAndExpandButton,
  ] = useState('--azul-5bits');

  // handleColorOfTheTitleAndExpandButton
  const handleColorOfTheTitleAndExpandButton = useCallback(() => {
    if (itemOS.forecastStartDateTime === null || itemOS.isStop === true) {
      setColorOfTheTitleAndExpandButton('--cinza-claro-5bits');
      return;
    }

    if (itemOS.startDateTime !== null) {
      setColorOfTheTitleAndExpandButton('--verde-5bits');
      return;
    }

    setColorOfTheTitleAndExpandButton('--azul-5bits');
  }, [itemOS.forecastStartDateTime, itemOS.isStop, itemOS.startDateTime]);

  useEffect(() => {
    if (Object.entries(itemOS).length > 0) {
      handleColorOfTheTitleAndExpandButton();
      handleDelayOS();

      if (itemOS.project.id !== idCompanyClicked) {
        setVisibleDescriptionOS(false);
        setZIndexAfterClicked(false);
      } else {
        setZIndexAfterClicked(true);
      }
    }
  }, [
    handleColorOfTheTitleAndExpandButton,
    handleDelayOS,
    idCompanyClicked,
    itemOS,
  ]);

  return (
    <>
      <ContainerOSOnStage applyZIndexProp={zIndexAfterClicked} {...rest}>
        <OSOnStage
          colorBorderOrangeProp={colorOrangeBorderCardOSProp}
          colorBorderProp={colorOfTheTitleAndExpandButton}
        >
          <ContainerInformationOS
            colorNameClientProp={
              colorOrangeBorderCardOSProp === true
                ? '--laranja-5bits'
                : colorOfTheTitleAndExpandButton
            }
          >
            <span>{itemOS.project.client.name}</span>
            <ButtonExpandDescriptionOS
              className="isNotTheParentContainer"
              colorButtonExpand={colorOfTheTitleAndExpandButton}
              onClick={handleOpenDescriptionOS}
              visibleDescriptionOSProp={visibleDescriptionOS}
            />
            <span>
              <span>Nº da OS:</span> {itemOS.projectId}
            </span>
            <span>
              <span>Data de início:</span>{' '}
              {itemOS.isStop === true
                ? ''
                : convertDateAndHoursToBr(itemOS.forecastStartDateTime)}
            </span>
            <span>
              <span>Data de entrega:</span>{' '}
              {convertDateAndHoursToBr(
                itemOS.isStop === true ? '' : itemOS.forecastEndDateTime,
              )}
            </span>

            <ContainerDescriptionOS
              visibleDescriptionOSProp={visibleDescriptionOS}
            >
              <span>Descrição:</span>
              <DescriptionOS>
                <span>{itemOS.project.description}</span>
              </DescriptionOS>
              <ButtonEditOS>
                <Button
                  type="button"
                  className="isNotTheParentContainer"
                  onClick={handleOpenModalServiceExecution}
                >
                  <RiTimeLine size={24} />
                  Editar
                </Button>
              </ButtonEditOS>
            </ContainerDescriptionOS>
          </ContainerInformationOS>
          <FooterInformationContainer>
            {textOfDelayOfTheFooter !== undefined && (
              <FooterInformation>{textOfDelayOfTheFooter}</FooterInformation>
            )}
          </FooterInformationContainer>
        </OSOnStage>
      </ContainerOSOnStage>
      {openModalServiceExecution && (
        <ModalServiceExecution
          loadModalProp={openModalServiceExecution}
          projectId={itemOS.projectId}
          manufacturingStepId={itemOS.manufacturingStepId}
          inDelay={textOfDelayOfTheFooter !== undefined}
          closeModalServiceExecution={handleCloseModalServiceExecution}
        />
      )}
    </>
  );
};

export default CardOSOnStage;
