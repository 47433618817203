import styled, { css } from 'styled-components';

interface IProps {
  showInformationInMobile: boolean;
}

export const Split = styled.div<IProps>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'itemClientId itemNameResponsible'
    'itemTitle itemDescription'
    'itemWeightAndPrice itemDescription';

  justify-content: space-between;
  align-items: flex-start;
  gap: 2.9rem 5.8rem;

  padding-bottom: 3.125rem;
  /* border-bottom: 0.1rem solid var(--cinza-claro-5bits); */

  width: 100%;

  .itemClientId {
    grid-area: itemClientId;
  }
  .itemNameResponsible {
    grid-area: itemNameResponsible;
  }
  .itemTitle {
    grid-area: itemTitle;
  }
  /*
    O grid-area: weightAndPrice está no componente abaixo
  */
  .itemDescription {
    grid-area: itemDescription;
  }

  > div {
    > div {
      width: 100%;
      label {
        margin-left: 2.5rem;
      }
    }
  }

  //Descrição do projeto
  > div:last-child {
    height: 100%;
    div {
      height: 100%;
      min-height: 11.8rem;
      textarea {
        height: 100%;
      }
    }
  }

  //Medias
  @media (min-width: 0px) and (max-width: 759px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      'itemClientId'
      'itemNameResponsible'
      'itemTitle'
      'itemDescription'
      'itemWeightAndPrice';

    overflow: hidden;
    ${props =>
      (props.showInformationInMobile &&
        css`
          min-height: 54rem;
          height: fit-content;
          max-height: fit-content;
          visibility: visible;
          opacity: 1;
          transition: opacity 2s, height 0.5s linear;
        `) ||
      css`
        height: 0;
        visibility: hidden;
        opacity: 0;
        padding: 0;
        transition: opacity 2s, visibility 2s 2s linear, height 0.5s linear;
      `}
  }

  @media (min-width: 760px) and (max-width: 999px) {
  }
`;

export const WeightAndPrice = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;

  width: 100%;
  min-width: 31rem;

  grid-area: itemWeightAndPrice;

  > div {
    flex: 1;
    max-width: 21.7rem;
  }

  /* forecastEndDate */
  > div:last-child {
    flex: 1.27;
  }

  //Medias
  @media (min-width: 0px) and (max-width: 354px) {
    flex-direction: column;
    gap: 1.4rem;

    min-width: auto;
    > div {
      max-width: none;
      width: 100%;
    }
  }

  @media (min-width: 355px) and (max-width: 759px) {
    min-width: auto;

    > div:first-child {
      width: 78%;

      label {
        margin-left: 1.5rem;
      }
    }
    > div:last-child {
      label {
        margin-left: 1.5rem;
      }
    }
  }

  @media (min-width: 760px) and (max-width: 999px) {
  }
`;
