import styled, { css, keyframes } from 'styled-components';

export const SplitMaterialItem = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-end;

  width: 100%;
  gap: 1rem;
  margin-bottom: 1.7rem;

  > div {
    /* flex: 1; */
    label {
      margin-left: 1rem;
    }
  }

  /* Nome */
  > div:nth-child(1) {
    flex: 3;
    width: 35rem;
    min-width: 30rem;
  }

  /* QuantityPrice */
  > div:nth-child(2) {
    flex: 2;
    width: 15rem;
    min-width: 15rem;
  }

  /* Valor Un */
  > div:nth-child(3) {
    flex: 1;
    width: 9.2rem;
    min-width: 9.2rem;
  }

  /* Subtotal */
  > div:nth-child(4) {
    flex: 2;
    width: 12rem;
    min-width: 12rem;
  }

  /* Fornecedor */
  > div:nth-child(5) {
    flex: 3;
    width: 20rem;
    min-width: 20rem;
  }

  //Medias
  @media (min-width: 0px) and (max-width: 1182px) {
    padding-bottom: 1.7rem;
    border-bottom: 1px solid var(--cinza-claro-5bits);
  }

  @media (min-width: 0px) and (max-width: 759px) {
    /* Nome */
    > div:nth-child(1) {
      min-width: 100%;
    }

    /* QuantityPrice */
    > div:nth-child(2) {
      min-width: 100%;
    }

    /* Fornecedor */
    > div:nth-child(5) {
      min-width: 100%;
    }
  }

  @media (min-width: 760px) and (max-width: 999px) {
  }
`;

export const DeliveryDaysAndImageExpandMoroCircle = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-end;
  width: 15rem;
  min-width: 13rem;
  max-width: 15rem;

  gap: 1rem;
`;

export const SplitFiledTwoInputsWithSelect = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;

  //Label
  > span {
    margin-left: 1rem;
    color: var(--cinza-escuro-5bits);
  }
`;
export const FiledTwoInputsWithSelect = styled.div`
  display: flex;
  width: 100%;

  /* overflow: hidden; */

  background: var(--input-bg-color);

  border-radius: 25px;
  border: 1px solid var(--input-border-color);
  color: var(--input-text-color);

  //Information
  > div:nth-child(1) {
    width: fit-content;
    height: 100%;

    padding: 0.9rem 0.8rem 0.9rem 1.5rem;

    background: var(--input-bg-color);
    filter: brightness(0.9);

    border: none;

    border-top-left-radius: 2.5rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 2.5rem;

    border-right: 2px solid var(--input-border-color);

    color: var(--input-text-color);

    > img {
      background: var(--branco-puro-5bits);
    }
  }

  //Select
  > div:last-child {
    > div {
      border: none;
      border-top-left-radius: 0;
      border-top-right-radius: 2.5rem;
      border-bottom-right-radius: 2.5rem;
      border-bottom-left-radius: 0;
      /* border-left: 1px solid var(--input-border-color); */

      div {
        border: none;
      }
      > input {
        text-align: right;
      }
    }
  }
`;
export const FiledTwoInputs = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;

  //Quantity
  > div:first-child {
    width: 70%;

    > div {
      padding: 0.9rem 0.5rem 0.9rem 1.5rem;

      border-top-left-radius: 2.5rem;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 2.5rem;

      > input {
        text-align: right;
      }
    }
  }

  //Unit
  > div:last-child {
    width: 30%;
    min-width: 5rem;

    > label {
      visibility: hidden;
    }

    > div {
      padding: 0.9rem 0.5rem 0.9rem 0.5rem;

      border-left: none;
      border-top-left-radius: 0;
      border-top-right-radius: 2.5rem;
      border-bottom-right-radius: 2.5rem;
      border-bottom-left-radius: 0;

      > input {
        text-align: left;
      }
    }
  }
`;

interface ISplitProvidersAndPhoneNumber {
  showProvidersAndPhone: boolean;
}

const upKeyFrame = keyframes`
  from {
    opacity: 0;
    transform: translateY(1.5rem);
  }

  to {
    opacity: 1;
  }
`;

export const SplitProvidersAndPhoneNumber = styled.div<ISplitProvidersAndPhoneNumber>`
  display: none;

  ${props =>
    props.showProvidersAndPhone &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;

      width: 100%;

      margin: 6.6rem 0 4.8rem 0;

      /*
    * Ao usar overflow: hidden no css BodyPurchasing - components -
    * HandleMaterials - styles - FiledTwoInputsWithSelect todo o texto
    * da página estava ficando embaçado. Para corrigir:
    * backface-visibility: hidden;
    * transform: translateZ(0);
    * Adicionado antes da animação.
     */
      backface-visibility: hidden;
      transform: translateZ(0);
      animation: ${upKeyFrame} 1s;
    `}
`;

export const ProvidersAndPhoneNumber = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: initial;

  width: 88%;
  padding-right: 3.5rem;
  max-height: 13rem;
  overflow: auto;
`;

export const TableProviders = styled.table`
  height: fit-content;
  border-collapse: collapse;

  width: 47%;
  /* border: 1px solid var(--cinza-claro-5bits);
  border-spacing: 0; */
  border-radius: 4px;
  /* table,
  td {
    border: 1px solid var(--cinza-claro-5bits);
    border-radius: 4px;
    border-spacing: 0;
  } */

  tbody {
    color: var(--cinza-escuro-5bits);
    background-color: var(--branco-puro-5bits);
    /* tr:nth-child(odd) {
      background-color: var(--branco-gelo-5bits);
    }

    tr:hover {
      background-color: #f0f0f0;
    } */

    tr {
      td {
        padding: 0.5rem 0.5rem;
        border-bottom: 1px solid var(--cinza-claro-5bits);
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 1.9rem;
      }

      //RadioButton
      td:nth-child(1) {
        /* width: 3.7rem; */
        //div do radioButton
        > div {
          width: fit-content;
          height: 2rem;
          > label {
            line-height: 0%;
          }
        }
      }
      //FantasyName
      td:nth-child(2) {
        width: 23.9rem;
        /* padding-left: 2.2rem; */
      }
      //PhoneNumber
      td:nth-child(3) {
        width: 20.5rem;
        padding-right: 2.2rem;
        text-align: right;
      }
    }
  }
`;
