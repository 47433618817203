import styled, { css } from 'styled-components';

import expandMore from '../../../../../../assets/expandMore.svg';

interface IContainerOSOnStage {
  applyZIndexProp: boolean;
}
interface IOSOnStage {
  colorBorderOrangeProp: boolean;
  colorBorderProp: string;
}

interface IContainerInformationOS {
  colorNameClientProp: string;
}

interface IButtonExpandDescriptionOS {
  visibleDescriptionOSProp: boolean;
  colorButtonExpand: string;
}

interface IDescriptionOS {
  visibleDescriptionOSProp: boolean;
}

export const ContainerOSOnStage = styled.div<IContainerOSOnStage>`
  position: relative;
  width: 32.4rem;
  min-width: 32.4rem;
  max-width: 32.4rem;
  /* min-width: 264px;
  max-width: 264px; */
  min-height: 112px;

  padding: 0 2rem 1rem 2rem;

  ${props =>
    (props.applyZIndexProp &&
      css`
        z-index: 101;
      `) ||
    css`
      z-index: auto;
    `}
`;

export const OSOnStage = styled.div<IOSOnStage>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 100%;
  /* width: 225px; */
  min-height: 12.1rem;

  position: relative;

  border: 1px solid var(--cinza-claro-5bits);
  background: var(--branco-puro-5bits);

  ${props =>
    (props.colorBorderOrangeProp &&
      css`
        border: 1px solid var(--laranja-5bits);
      `) ||
    (props.colorBorderProp &&
      css`
        border: 1px solid var(${props.colorBorderProp});
      `)}

  ${props =>
    (props.colorBorderProp === '--azul-5bits' &&
      css`
        background: var(--azul-bebe-5bits);
      `) ||
    (props.colorBorderProp === '--verde-5bits' &&
      css`
        background: var(--verde-bebe-5bits);
      `)}

  border-radius: 4px;
`;

export const ContainerInformationOS = styled.div<IContainerInformationOS>`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1.2rem 0.5rem 0 1.2rem;

  > span {
    width: 90%;
    align-items: flex-start;

    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;

    color: var(--cinza-escuro-5bits);

    > span {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 19px;

      color: var(--cinza-claro-5bits);
    }
  }

  /* TitleNameCliente */
  > span:first-child {
    align-content: flex-start;
    width: 85%;
    margin-bottom: 0.6rem;

    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;

    ${props =>
      css`
        color: var(${props.colorNameClientProp});
      `}
  }
`;

export const ButtonExpandDescriptionOS = styled.div<IButtonExpandDescriptionOS>`
  position: absolute;
  top: 10px;
  right: 12px;

  width: 2.4rem;
  height: 2.4rem;
  margin-left: 1rem;

  border-radius: 50%;

  background-image: url(${expandMore});
  background-repeat: no-repeat;
  background-position: center;

  cursor: pointer;

  ${props => css`
    background-color: var(${props.colorButtonExpand});
  `}

  ${props =>
    (props.visibleDescriptionOSProp &&
      css`
        transform: rotate(180deg);
        transition: transform 500ms linear;
      `) ||
    css`
      transform: rotate(0);
      transition: transform 500ms linear;
    `}
`;

export const ContainerDescriptionOS = styled.div<IDescriptionOS>`
  display: block;
  width: 100%;
  max-height: 0;

  margin-top: 0.6rem;
  overflow: hidden;
  transition: max-height 0.2s ease-out;

  ${props =>
    props.visibleDescriptionOSProp &&
    css`
      margin-bottom: 0.5rem;
      max-height: 20rem;
    `}

  > span {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;

    color: var(--cinza-escuro-5bits);
  }
`;

export const DescriptionOS = styled.div`
  display: flex;
  flex-direction: column;

  width: 98%;
  height: 11.7rem;

  padding: 1.2rem 0.8rem 1.2rem 1.2rem;

  overflow-y: auto;

  background: var(--branco-puro-5bits);
  border: 1px solid rgba(171, 171, 171, 0.5);
  box-sizing: border-box;
  border-radius: 0.4rem;

  > span {
    font-style: normal;
    font-weight: normal;
    font-size: 1.4rem;
    line-height: 1.9rem;

    color: var(--cinza-escuro-5bits);
  }
`;

export const ButtonEditOS = styled.div`
  margin-top: 1.8rem;
  margin-bottom: 0.2rem;

  button {
    width: 12.6rem;
    height: 3.7rem;

    text-transform: capitalize;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: var(--branco-puro-5bits);

    svg {
      margin-right: 1rem;
    }
  }
`;

export const FooterInformationContainer = styled.div`
  height: 1.6rem;
  border-radius: 0 0 0.4rem 0.4rem;
`;

export const FooterInformation = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  width: 100%;
  height: 100%;

  padding: 0 1rem;

  background-color: var(--vermelho-5bits);

  text-transform: uppercase;
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.3rem;
  color: var(--branco-puro-5bits);
`;
