import styled from 'styled-components';

interface SpanProps {
  viewPortHeight: number;
  coordinateX: number;
  coordinateY: number;
  colorBefore: string;
  colorSpan: string;
}

export const Container = styled.div<SpanProps>`
  /* position: relative; */
  right: 0;
  width: 100%;
  bottom: calc(100% + -25px);

  > div:first-child {
    background: ${props => props.colorSpan || 'var(--laranja-5bits)'};
    padding: 8px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    opacity: 0;
    transition: opacity 0.4s;
    visibility: hidden;
    z-index: 1998;
    text-align: center;

    position: absolute;

    /*
     * Altura da viewport menos a posição eixo Y do componente mais
     * 10px para dar um espaçamento do balão tooltip ao componente
    */
    bottom: calc(
      ${props => props.viewPortHeight}px - ${props => props.coordinateY}px +
        10px
    );

    left: ${props => props.coordinateX}px;

    /* bottom: calc(100% + 8px);
    left: 10%; */
    /* transform: translateX(-50%); */

    color: var(--message-tooltip-span-text-color);

    &::before {
      content: '';

      border-color: ${props => props.colorBefore || 'var(--laranja-5bits)'};

      border-style: solid;
      border-width: 0px 6px 6px 0px;
      top: 100%;
      position: absolute;
      padding: 3px;
      left: 3rem;
      transform: rotate(45deg) translateX(-76%);
    }

    > div {
      display: flex;
      justify-content: space-between;
      width: 100%;

      > span {
        text-align: left;
        /* Evita a quebra de linha do texto */
        white-space: pre;
      }

      > div {
        display: flex;
        justify-content: flex-start;
        width: 15rem;
        > span:nth-child(1) {
          margin: 0 1rem 0 1rem;
        }
        > span {
          white-space: pre;
        }
      }
    }
  }

  &:hover div {
    opacity: 1;
    visibility: visible;
  }
`;
