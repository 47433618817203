import React, { createContext, useCallback, useState, useContext } from 'react';
// eslint-disable-next-line import/no-duplicates
import { format, parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
// eslint-disable-next-line import/no-duplicates
import pt from 'date-fns/locale/pt-BR';

import { IStep, IStepResponse } from '../interfaces';
import api from '../../../services/api';
import convertDateStringToDate from '../../../utils/convertDateStringToDate';

interface IStepsContextData {
  steps: IStep[];
  fetchSteps(): Promise<void>;
  reorderStepFrameOnStep(
    droppableSource: { index: number; droppableId: string },
    startIndex: number,
    endIndex: number,
  ): Promise<void>;
  moveStepFrameBetweenSteps(
    droppableSource: { index: number; droppableId: string },
    droppableDestination: { index: number; droppableId: string },
  ): Promise<void>;
  errorStep: string | undefined;
}

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const StepsContext = createContext<IStepsContextData>({} as IStepsContextData);

const StepsProvider: React.FC = ({ children }) => {
  const [steps, setSteps] = useState<IStep[]>([]);
  const [error, setError] = useState<string | undefined>();

  const fetchSteps = useCallback(async () => {
    setError(undefined);

    try {
      const response = await api.get<IStepResponse[]>('/steps?_embed=frames');

      const stepsAdapted = response.data.map((step: IStepResponse) => ({
        ...step,
        frames: step.frames.map(frame => ({
          ...frame,
          deliveryAt: convertDateStringToDate(frame.deliveryAt),
          deliveryAtFormatted: format(
            utcToZonedTime(parseISO(frame.deliveryAt), timezone),
            "dd/MM/yyyy '",
            { locale: pt },
          ),
        })),
      }));

      setSteps(stepsAdapted);
    } catch (err: any) {
      setError(err);
    }
  }, []);

  const reorderStepFrameOnStep = useCallback(
    async (
      droppableSource: { index: number; droppableId: string },
      startIndex: number,
      endIndex: number,
    ) => {
      const original = Array.from(steps);
      const index = steps.findIndex(
        step => step.id === droppableSource.droppableId,
      );
      const orderStepFrame = steps[index].frames[startIndex];

      setSteps(state => {
        const [removed] = steps[index].frames.splice(startIndex, 1);
        steps[index].frames.splice(endIndex, 0, removed);
        steps[index].frames = steps[index].frames.map((item, _index) => ({
          ...item,
          order: _index + 1,
        }));

        return [...steps];
      });

      try {
        /* const { data: resData } = await api.put(
          `stepframe/${orderStepFrame.id}/statusorder`,
          {
            order: endIndex + 1,
          },
        ); */
        /* setSteps(state => {
          const stepFrameIndex = steps[index].frames.findIndex(
            t => t.id === resData.id,
          );

          if (stepFrameIndex > -1) {
            steps[index].frames[stepFrameIndex] = {
              ...resData,
            };
          }

          return [...steps];
        }); */
      } catch (err) {
        setSteps(original);
        throw new Error('Internal error: reorderStep on useSteps');
      }
    },
    [steps],
  );

  const moveStepFrameBetweenSteps = useCallback(
    async (
      droppableSource: { index: number; droppableId: string },
      droppableDestination: { index: number; droppableId: string },
    ) => {
      const original = Array.from(steps);

      const indexSource = steps.findIndex(
        step => step.id === droppableSource.droppableId,
      );
      const indexDestination = steps.findIndex(
        step => step.id === droppableDestination.droppableId,
      );

      const updateStepFrame = steps[indexSource].frames[droppableSource.index];

      setSteps(state => {
        const sourceClone = steps[indexSource].frames;
        const destClone = steps[indexDestination].frames;
        const [removed] = sourceClone.splice(droppableSource.index, 1);

        destClone.splice(droppableDestination.index, 0, removed);

        steps[indexSource].frames = sourceClone.map((item, _index) => ({
          ...item,
          order: _index + 1,
        }));

        steps[indexDestination].frames = destClone.map((item, _index) => ({
          ...item,
          order: _index + 1,
        }));

        return [...steps];
      });

      try {
        /* const { data: resData } = await api.put(
          `stepframe/${updateStepFrame.id}/statusorder`,
          {
            step_id: '',
            order: droppableDestination.index + 1,
          },
        );
        setSteps(state => {
          const taskIndex = steps[indexDestination].frames.findIndex(
            t => t.id === resData.id,
          );

          if (taskIndex > -1) {
            steps[indexDestination].frames[taskIndex] = {
              ...resData,
            };
          }

          return [...steps];
        }); */
      } catch (err) {
        setSteps(original);

        throw new Error('Internal error: moveTask on useTasks');
      }
    },
    [steps],
  );

  return (
    <StepsContext.Provider
      value={{
        steps,
        fetchSteps,
        reorderStepFrameOnStep,
        moveStepFrameBetweenSteps,
        errorStep: error,
      }}
    >
      {children}
    </StepsContext.Provider>
  );
};

function useSteps(): IStepsContextData {
  const context = useContext(StepsContext);

  if (!context) {
    throw new Error('useSteps must be within an StepsProvider');
  }

  return context;
}

export { StepsProvider, useSteps };
