import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;

  width: 50vw;
  height: 100%;
  /* max-height: 80vh; */
  padding: 5.6rem 6.3rem;
  background-color: var(--branco-puro-5bits);

  h1 {
    text-transform: none;
    font-weight: 500;
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;

  margin-top: 4.2rem;
  height: 100%;
  max-height: 100%;
  width: 100%;

  > form {
    width: 100%;
  }
`;

export const ChecksItensArea = styled.div`
  width: 100%;
  max-height: 60vh;
  overflow: auto;

  > div {
    padding-bottom: 1rem;

    svg {
      width: 2.1rem;
      height: 1.6rem;
      transform: scale(2.4);
    }

    > span {
      margin-left: 1rem;

      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      color: var(--azul-5bits);
    }

    //Checkbox
    > div {
      label {
        margin-left: 0rem;

        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        color: rgba(15, 76, 130, 0.42);
      }
    }
  }
`;

export const ActionSaveModal = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  width: 100%;
  max-height: 100%;
  overflow: auto;

  > button {
    width: 10.7vw;
  }
`;

export const ActionCloseModal = styled.div`
  position: absolute;
  top: 1.3rem;
  right: 1.3rem;
  cursor: pointer;

  > svg {
    path {
      stroke: var(--azul-5bits);
    }
  }
`;

export const CheckboxHidden = styled.div`
  div {
    display: none;
  }
`;
