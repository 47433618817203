/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { memo, useCallback, useState } from 'react';
import { FormHandles, Scope } from '@unform/core';
import typy from 'typy';

import { FiTrash2 } from 'react-icons/fi';
import { bool, boolean } from 'yup';
import InputHidden from '../../../../../../../components/InputHidden';
import Input from '../../../../../../../components/Input';
import InputCurrency from '../../../../../../../components/InputCurrency';

import {
  SplitInvoiceItem,
  QuantityPriceSubtotalInvoice,
  ObservationAndTrashIcon,
  PaydayAndIsPayAndTrash,
} from './styles';
import DatePicker from '../../../../../../../components/DatePicker';
import CheckboxInput from '../../../../../../../components/CheckboxInput';
import ToggleOnOff from '../../../../../../../components/ToggleOnOff';

type Props = {
  formRef: any | FormHandles;
  listInvoices: any[];
  disabled: boolean;
  showModalConfirmationOfExclusionInvoice: (
    indexInvoice: number,
    show: boolean,
  ) => void;
  removeInvoice: (index: number) => void;
};

function ScopeInvoice({
  formRef,
  listInvoices,
  disabled,
  showModalConfirmationOfExclusionInvoice,
  removeInvoice,
}: Props): any {
  const [elementClicked, setElementClicked] = useState<string>('empty');
  const [elementDisabled, setElementDisabled] = useState<boolean>(false);

  const handleClickIsPay = useCallback(
    (elementChecked: boolean, indexElement: number) => {
      setElementClicked(`scopeListInvoices[${indexElement}].payday`);
      setElementDisabled(elementChecked);
    },
    [],
  );
  const getDisabledActualTheElement = useCallback(
    (indexElement: number): boolean => {
      const element = document.getElementById(
        `payday${indexElement}`,
      ) as HTMLInputElement;

      if (element !== null) {
        return element.disabled;
      }

      return true;
    },
    [],
  );

  return (
    <>
      {listInvoices.length > 0 &&
        listInvoices.map((item: any, index) => (
          <Scope path={`scopeListInvoices[${index}]`} key={String(index)}>
            <InputHidden id="id" name="id" />
            <InputHidden id="idNewItem" name="idNewItem" />
            <SplitInvoiceItem>
              <QuantityPriceSubtotalInvoice>
                <DatePicker
                  id="dueDate"
                  name="dueDate"
                  labelText={index === 0 ? 'Data Vencimento' : ''}
                  dateFormat="dd/MM/yyyy"
                />

                <InputCurrency
                  id="price"
                  name="price"
                  type="text"
                  labelText={index === 0 ? 'Valor' : ''}
                  autoComplete="off"
                />
              </QuantityPriceSubtotalInvoice>

              <ObservationAndTrashIcon>
                <Input
                  id="observation"
                  name="observation"
                  type="text"
                  labelText={index === 0 ? 'Observação' : ''}
                  autoComplete="off"
                  disabled={
                    disabled ||
                    (item.providerId &&
                      typy(parseInt(item.providerId, 10)).isNumber)
                  }
                />
              </ObservationAndTrashIcon>
              <PaydayAndIsPayAndTrash>
                <div style={{ height: index === 0 ? '6.1rem' : 'fit-content' }}>
                  <span>{index === 0 ? 'Baixar' : ''}</span>
                  <ToggleOnOff
                    name="isPay"
                    id={`isPay${index}`}
                    label=""
                    onClick={e => {
                      handleClickIsPay(e.currentTarget.checked, index);
                    }}
                  />
                </div>

                <DatePicker
                  id={`payday${index}`}
                  name="payday"
                  labelText={index === 0 ? 'Data Pagamento' : ''}
                  disabled={
                    `scopeListInvoices[${index}].payday` === elementClicked
                      ? !elementDisabled
                      : getDisabledActualTheElement(index)
                  }
                />

                {!disabled && (
                  <div>
                    <FiTrash2
                      size={20}
                      color="var(--cinza-claro-5bits)"
                      onClick={() => {
                        formRef.current.getFieldValue(
                          `scopeListInvoices[${index}].id`,
                        )
                          ? showModalConfirmationOfExclusionInvoice(index, true)
                          : removeInvoice(index);
                      }}
                    />
                  </div>
                )}
              </PaydayAndIsPayAndTrash>
            </SplitInvoiceItem>
          </Scope>
        ))}
    </>
  );
}

export default memo(ScopeInvoice);
