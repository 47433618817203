/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/require-default-props */
import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
  TextareaHTMLAttributes,
} from 'react';

import { IconBaseProps } from 'react-icons';
import { useField } from '@unform/core';
import { FiAlertCircle } from 'react-icons/fi';

import { Container, Content, Error } from './styles';
/**
 * Textarea component for Unform.
 *
 * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/textarea
 */
interface Props {
  name: string;
  label?: string;
  icon?: React.ComponentType<IconBaseProps>;
}

type TextareaProps = TextareaHTMLAttributes<HTMLTextAreaElement> & Props;

function Textarea({
  name,
  label,
  icon: Icon,
  disabled,
  ...rest
}: TextareaProps) {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const {
    fieldName,
    defaultValue = '',
    registerField,
    error,
    clearError,
  } = useField(name);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [isFilled, setIsFilled] = useState<boolean>(false);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);

    if (textareaRef.current) {
      setIsFilled(!!textareaRef.current.value);
    }
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: textareaRef,
      getValue: ref => {
        return ref.current.value;
      },
      setValue: (ref, value) => {
        if (value !== undefined) ref.current.value = value;
      },
      clearValue: ref => {
        ref.current.value = '';
      },
    });
  }, [fieldName, registerField]);

  const handleChange = useCallback((): void => {
    if (error) {
      clearError();
    }
  }, [clearError, error]);
  /**
   * If you need to set a default value for the textarea,
   * use the initial data property on your form,
   * or set it dynamically (be aware of the differences).
   *
   * initial data: https://unform.dev/guides/initial-data
   * set field value: https://unform.dev/guides/get-set-field-value
   */

  return (
    <Container>
      {label && <label htmlFor={fieldName}>{label}</label>}

      <Content
        isErrored={!!error}
        isFocused={isFocused}
        isFilled={isFilled}
        isDisabled={!!disabled}
      >
        {Icon && <Icon size={20} />}
        <textarea
          ref={textareaRef}
          id={fieldName}
          onFocus={(): void => setIsFocused(true)}
          onBlur={handleInputBlur}
          defaultValue={defaultValue}
          onChange={handleChange}
          disabled={disabled}
          {...rest}
        />
      </Content>
      {error && (
        <Error>
          <FiAlertCircle color="var(--on-errored-message-bg-color)" size={20} />
          {error}
        </Error>
      )}
    </Container>
  );
}

export default Textarea;
