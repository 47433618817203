import styled from 'styled-components';

export const SplitTechnicalDrawings = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  padding-top: 3.125rem;
  padding-bottom: 3.125rem;
  border-bottom: 0.1rem solid var(--cinza-claro-5bits);
`;

export const SplitDrawing = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;

  width: 100%;
  margin-bottom: 1.7rem;

  > div {
    /* flex: 50%; */
    /* padding-right: 4.2rem; */
    width: 33%;
    label {
      margin-left: 1rem;
    }
  }
`;

export const RevisionNumberAndRevisionDateAndFile = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  width: 100%;

  //revisionNumber
  > div:nth-child(1) {
    width: 7.8rem;
  }

  //revisionDate
  > div:nth-child(2) {
    width: 13.5rem;
  }

  //btLoadNewDrawing
  > div:last-child {
    width: 15rem;

    > button {
      width: 11.7rem;
      background: var(--azul-5bits);
      font-style: normal;
      font-weight: bold;
      font-size: 1.4rem;
      text-transform: capitalize;
      line-height: 1.9rem;
      margin-right: 1.38rem;
    }
  }
`;

export const SplitFooter = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;

  width: 100%;
  padding-top: 3.125rem;
  padding-bottom: 3.125rem;
  border-bottom: 0.1rem solid var(--cinza-claro-5bits);

  > div {
    /* flex: 50%; */
    /* padding-right: 4.2rem; */
    width: 48%;
    margin-bottom: 1rem;
    label {
      margin-left: 1rem;
    }
  }
  > div:last-child {
    display: flex;
    justify-content: flex-end;

    button {
      width: 69%;
    }
  }
`;
