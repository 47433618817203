import React, { useEffect, useState, HTMLProps } from 'react';
import typy from 'typy';
import { Container } from './styles';

/*
O title será a mensagem de erro
Como o styled components utiliza as class,
para que o Tooltip receba estilização de um
elemento superior, ele tem que receber a className.
O className não pode ser obrigatório pois será passada
pelos estilos que utilizarem.
*/
interface TooltipProps extends Omit<HTMLProps<HTMLDivElement>, 'title'> {
  title: Array<string> | string | any;
  id: string;
  viewPortHeightProp: { width: number; height: number };
  colorSpan?: string;
  colorBefore?: string;
}

const Tooltip: React.FC<TooltipProps> = ({
  title,
  id,
  viewPortHeightProp,
  colorSpan = '',
  colorBefore = '',
  children,
  ...rest
}) => {
  const [viewPortHeight, setViewPortHeight] = useState<{
    width: number;
    height: number;
  }>({} as { width: number; height: number });
  const [coordinateX, setCoordinateX] = useState<any>(0);
  const [coordinateY, setCoordinateY] = useState<any>(0);

  useEffect(() => {
    if (Object.values(viewPortHeightProp).length < 1) {
      setViewPortHeight({
        width: window.visualViewport.width,
        height: window.visualViewport.height,
      });
    } else {
      setViewPortHeight(viewPortHeightProp);
    }
  }, [viewPortHeightProp]);

  useEffect(() => {
    const el = document.getElementById(id);
    /* Utiliza método getBoundingClientRect que retorna informações
     * de posição do elemento na tela
     */
    const elCoordenadas = el?.getBoundingClientRect();
    setCoordinateX(elCoordenadas?.x);
    setCoordinateY(elCoordenadas?.y);
  }, [id, viewPortHeight]);

  return (
    <Container
      coordinateX={coordinateX}
      coordinateY={coordinateY}
      viewPortHeight={viewPortHeight.height}
      colorBefore={colorBefore}
      colorSpan={colorSpan}
      id={id}
    >
      <div {...rest}>
        {(typy(title).isArray &&
          title.map((item: any, index: number) => (
            <div key={String(index)}>
              <span>{item.name}</span>
              <div>
                <span>|</span>
                <span>{item.phone}</span>
              </div>
            </div>
          ))) ||
          title}
      </div>
      <div>{children}</div>
    </Container>
  );
};

export default Tooltip;
