import React, { useState, useCallback, useEffect } from 'react';

import Button from '../Button';

import {
  Container,
  Content,
  ButtonNormal,
  ButtonHighlighted,
  LiButtonControlsArrow,
} from './styles';

interface PaginationProps {
  listItems: Array<any>;
  perPageProp?: number;
  // eslint-disable-next-line no-unused-vars
  onChangeListPaginatedItems: (newList: Array<any>) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  listItems,
  perPageProp = 10,
  onChangeListPaginatedItems,
}) => {
  const [pageActual, setPageActual] = useState(1);
  const [buttons, setButtons] = useState<number[]>([]);

  const perPage = perPageProp;

  const state = {
    perPage,
    totalPages: Math.ceil(listItems.length / perPage), // Arredonda para cima
    maxVisibleButtons: 5,
  };

  useEffect(() => {
    setPageActual(1);
  }, [listItems]);

  // updateListPaginatedItems
  const updateListPaginatedItems = useCallback((): Array<any> => {
    const page = pageActual - 1;
    const start = page * state.perPage;
    const end = start + state.perPage;

    const paginatedItems = listItems.slice(start, end);
    return paginatedItems;
  }, [listItems, pageActual, state.perPage]);
  // Fim updateListPaginatedItems

  // calculateMaxVisibleButtonsPagination
  const calculateMaxVisibleButtonsPagination = useCallback(() => {
    const { maxVisibleButtons } = state;
    let maxLeft = pageActual - Math.floor(maxVisibleButtons / 2);
    let maxRight = pageActual + Math.floor(maxVisibleButtons / 2);

    if (maxLeft < 1) {
      maxLeft = 1;
      maxRight = maxVisibleButtons;
    }

    if (maxRight > state.totalPages) {
      maxLeft = state.totalPages - (maxVisibleButtons - 1);
      maxRight = state.totalPages;

      if (maxLeft < 1) maxLeft = 1;
    }

    return { maxLeft, maxRight };
  }, [pageActual, state]);
  // Fim calculateMaxVisibleButtonsPagination

  // updateButtonsPagination
  const updateButtonsPagination = useCallback((): Array<any> => {
    const newButtons = [];
    const { maxLeft, maxRight } = calculateMaxVisibleButtonsPagination();

    for (let page = maxLeft; page <= maxRight; page += 1) {
      newButtons.push(page);
    }
    return newButtons;
  }, [calculateMaxVisibleButtonsPagination]);
  // Fim updateButtonsPagination

  // update
  const update = useCallback(() => {
    setButtons(updateButtonsPagination());
  }, [updateButtonsPagination]);
  // Fim update

  // Next items
  const next = useCallback(() => {
    if (pageActual + 1 > state.totalPages) {
      setPageActual(pageActual);
    } else {
      setPageActual(pageActual + 1);
    }
    update();
  }, [pageActual, state.totalPages, update]);
  // End Next Items

  // Previous items
  const prev = useCallback(() => {
    if (pageActual - 1 < 1) {
      setPageActual(pageActual);
    } else {
      setPageActual(pageActual - 1);
    }
    update();
  }, [pageActual, update]);
  // End Previous Items

  // GoTo items
  const goTo = useCallback(
    (page: number) => {
      if (page < 1) {
        // eslint-disable-next-line no-param-reassign
        page = 1;
      }

      setPageActual(page);

      if (page > state.totalPages) {
        setPageActual(state.totalPages);
      }
      update();
    },
    [state.totalPages, update],
  );
  // End GoTo items

  useEffect(() => {
    setButtons(updateButtonsPagination());
    onChangeListPaginatedItems(updateListPaginatedItems());
  }, [listItems, pageActual]);

  return (
    <Container>
      <Content>
        <nav aria-label="Page navigation">
          <ul>
            <LiButtonControlsArrow>
              <Button onClick={() => goTo(1)}>
                <span aria-hidden="true">&laquo;</span>
              </Button>
            </LiButtonControlsArrow>
            <LiButtonControlsArrow>
              <Button onClick={prev}>
                <span aria-hidden="true">&lt;</span>
              </Button>
            </LiButtonControlsArrow>

            {buttons.map((item, index) => (
              <li key={index.toString()}>
                {(item === pageActual && (
                  <ButtonHighlighted>
                    <Button onClick={() => goTo(item)}>{item}</Button>
                  </ButtonHighlighted>
                )) || (
                  <ButtonNormal>
                    <Button onClick={() => goTo(item)}>{item}</Button>
                  </ButtonNormal>
                )}
              </li>
            ))}

            <LiButtonControlsArrow>
              <Button onClick={next}>
                <span aria-hidden="true">&gt;</span>
              </Button>
            </LiButtonControlsArrow>
            <LiButtonControlsArrow>
              <Button onClick={() => goTo(state.totalPages)}>
                <span aria-hidden="true">&raquo;</span>
              </Button>
            </LiButtonControlsArrow>
          </ul>
        </nav>
      </Content>
    </Container>
  );
};

export default Pagination;
