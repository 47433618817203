/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState, useCallback } from 'react';

import { Link } from 'react-router-dom';
import { FiEdit } from 'react-icons/fi';
import { AiOutlineFileAdd } from 'react-icons/ai';
import { useToast } from '../../../../context/ToastContext';

import {
  Container,
  Content,
  ContainerInfoDrawing,
  InfoDrawing,
} from './styles';
import api from '../../../../services/api';

import convertDateToBr from '../../../../utils/convertDateToBr';

import ListingTemplate from '../../../../components/Template/Listing';
import Button from '../../../../components/Button';
import Loading from '../../../../components/Loading';
import Tooltip from '../../../../components/Tooltip';

import { IPermission } from '../../../../interfaces/permission';

import {
  IPlanning,
  IPlanningResponseList,
} from '../../../../interfaces/planning';
import convertInitToUppercase from '../../../../utils/convertInitToUppercase';

interface PermissionDTO {
  permissions: IPermission;
}

const ListPlanningProjects: React.FC<PermissionDTO> = ({ permissions }) => {
  const { addToast } = useToast();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingFile, setLoadingFile] = useState<boolean>(false);
  const [buttonClicked, setButtonClicked] = useState<string>('');
  const [listPlanningProjects, setListPlanningProjects] = useState<
    IPlanningResponseList[]
  >([]);
  const [
    listPlanningProjectsPaginated,
    setListPlanningProjectsPaginated,
  ] = useState<IPlanningResponseList[]>([]);

  useEffect(() => {
    const getProvider = async (): Promise<void> => {
      try {
        setLoading(true);

        const response: any = await api.get(`planning/active`);

        if (response.data.length > 0) {
          const responseOrdered = response.data.map((i: IPlanning) => ({
            ...i,
            createdAt: i.createdAt,
            updatedAt: i.updatedAt,
          }));
          setListPlanningProjects(responseOrdered);
          setListPlanningProjectsPaginated(responseOrdered);
        }
        setLoading(false);
      } catch (error: any) {
        setLoading(false);

        if (error.response) {
          const { data } = error.response; // Error vindo do back está em data dentro de response
          addToast({
            type: 'error',
            title: data.message,
          });
          return;
        }

        addToast({
          type: 'error',
          title: 'Erro ao listar!',
          description: `Error: ${error}`,
        });
      }
    };
    getProvider();
  }, [addToast]);

  const handleOpenFile = useCallback(
    (fileName: string, nameButtonClicked: string) => {
      setLoadingFile(true);
      setButtonClicked(nameButtonClicked);

      const extensionFile = fileName.substring(fileName.lastIndexOf('.') + 1);

      let typeBlob = '';

      switch (extensionFile) {
        case 'pdf':
          typeBlob = `application/${extensionFile}`;
          break;
        case 'tiff':
          typeBlob = `image/${extensionFile}`;
          break;
        default:
          typeBlob = `image/${extensionFile}`;
      }

      api
        .get(`drawings/file/${fileName}`, {
          responseType: 'arraybuffer',
        })
        .then((response: any) => {
          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: typeBlob }),
          );

          window.open(url);
          setLoadingFile(false);
          setButtonClicked('');
        })
        .catch(error => {
          setLoadingFile(false);
          setButtonClicked('');

          if (error.response.data) {
            const { data } = error.response; // Error vindo do back está em data dentro de response
            addToast({
              type: 'error',
              title: data.message,
            });
            return;
          }

          addToast({
            type: 'error',
            title: 'Erro ao Exibir!',
            description: `Error: ${error}`,
          });
        });
    },
    [addToast],
  );

  const handleListPlanningProjectsPaginatedItems = useCallback(newList => {
    setListPlanningProjectsPaginated(newList);
  }, []);

  return (
    <ListingTemplate
      title="Planejamento"
      module="planning"
      listItemsProps={listPlanningProjects}
      handleListItemsPaginatedItems={handleListPlanningProjectsPaginatedItems}
      displaySearchArea={false}
      displayButtonNew={false}
      disabled={loading}
      permissions={permissions}
      perPageForPaginationProp={30}
    >
      <Container>
        <Content>
          {loading ? (
            <p>carregando ...</p>
          ) : (
            <table>
              <thead>
                <tr>
                  <th>Nº</th>
                  <th>Cliente</th>
                  <th>Descrição</th>
                  <th>Quant.</th>
                  <th>Desenho</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {listPlanningProjectsPaginated.map(
                  (planningItem: any, index) => (
                    <tr key={String(planningItem.id)}>
                      <td>{planningItem.id}</td>
                      <td>
                        {convertInitToUppercase(planningItem.client.name)}
                      </td>
                      <td>{planningItem.description}</td>
                      <td>{planningItem.quantity}</td>
                      <td>
                        {planningItem.drawings.map((item: any) => (
                          <ContainerInfoDrawing key={String(item.id)}>
                            <Tooltip
                              style={{
                                textTransform: 'capitalize',
                              }}
                              colorBefore="var(--azul-5bits)"
                              colorSpan="var(--azul-5bits)"
                              title={`Rev.: ${
                                item.revisionNumber
                              } | Rev. Date: ${convertDateToBr(
                                item.revisionDate,
                              )}`}
                            >
                              <InfoDrawing>
                                <div>
                                  <span>
                                    {convertInitToUppercase(item.drawingName)}
                                  </span>
                                  <span>{item.drawingNumber}</span>
                                </div>
                                {(loadingFile &&
                                  buttonClicked === `openFile${index}` && (
                                    <Button
                                      name="buttonSpinner"
                                      type="button"
                                      onClick={e => {
                                        e.preventDefault();
                                      }}
                                    >
                                      <Loading size={24} color="white" />
                                    </Button>
                                  )) || (
                                  <Button
                                    name={`openFile${index}`}
                                    type="button"
                                    onClick={e => {
                                      e.preventDefault();
                                      handleOpenFile(
                                        item.fileName,
                                        `openFile${index}`,
                                      );
                                    }}
                                  >
                                    Exibir
                                  </Button>
                                )}
                              </InfoDrawing>
                            </Tooltip>
                          </ContainerInfoDrawing>
                        ))}
                      </td>
                      <td>
                        {(planningItem.projectHasServices &&
                          planningItem.projectHasServices.length > 0 && (
                            <>
                              {(permissions.update && (
                                <Link
                                  to={`/planning/update/${planningItem.id}`}
                                >
                                  <FiEdit size={16} color="var(--azul-5bits)" />
                                </Link>
                              )) || (
                                <FiEdit
                                  size={16}
                                  color="var(--cinza-escuro-5bits)"
                                />
                              )}
                            </>
                          )) || (
                          <>
                            {(permissions.create && (
                              <Link to={`/planning/update/${planningItem.id}`}>
                                <AiOutlineFileAdd
                                  size={16}
                                  color="var(--azul-5bits)"
                                />
                              </Link>
                            )) || (
                              <AiOutlineFileAdd
                                size={16}
                                color="var(--cinza-escuro-5bits)"
                              />
                            )}
                          </>
                        )}
                      </td>
                    </tr>
                  ),
                )}
              </tbody>
            </table>
          )}
        </Content>
      </Container>
    </ListingTemplate>
  );
};

export default ListPlanningProjects;
