/* eslint-disable react/jsx-curly-newline */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import React, {
  useEffect,
  Ref,
  forwardRef,
  useState,
  useCallback,
  useImperativeHandle,
  memo,
} from 'react';
import { FormHandles, Scope } from '@unform/core';
import fincalc from 'fincalc';
import typy from 'typy';
import { v4 as uuidv4 } from 'uuid';

import { FiTrash2 } from 'react-icons/fi';
import { MdExpandMore } from 'react-icons/md';

import Loading from '../../../../../../components/Loading';
import Input from '../../../../../../components/Input';
import InputHidden from '../../../../../../components/InputHidden';
import InputTwoFieldsForHours from '../../../../../../components/InputTwoFieldsForHours';
import InputCurrency from '../../../../../../components/InputCurrency';
import SimpleSelect from '../../../../../../components/SimpleSelect';
import Button from '../../../../../../components/Button';

import {
  SplitServices,
  HeaderAndGrandTotal,
  SplitServiceItem,
  QuantityUnitPriceSubtotalService,
  ActionAndGrandTotal,
  GrandTotal,
  ObservationAndTrashIcon,
} from './styles';

import { IServiceResponse } from '../../../../../../interfaces/service';

import convertNumberFloatToDB from '../../../../../../utils/convertNumberFloatToDB';
import convertNumberFloatToBr from '../../../../../../utils/convertNumberFloatToBr';
import PaginationAndSetInputsOnComponentService from './components/PaginationAndSetInputsOnComponentService';

interface IServiceForm extends IServiceResponse {
  totalPrice: string;
  quantity: string;
}
interface IHandleServices {
  formRef: any | FormHandles;
  listServices: Array<IServiceResponse>;
  projectHasServicesProp: Array<any>;
  handleNewListManufacturingSteps: (
    newListManufacturingSteps: Array<any>,
  ) => void;
  disableTabProp: boolean;
  showModalConfirmationOfExclusionService: (
    indexService: number,
    show: boolean,
  ) => void;
}

interface IRef {
  removeService: any;
  getUpdateListCompleteAndUpdatedOfTheServices: any;
}

const HandleServices = forwardRef<IRef, IHandleServices>(
  (
    {
      formRef,
      listServices,
      projectHasServicesProp,
      handleNewListManufacturingSteps,
      disableTabProp = false,
      showModalConfirmationOfExclusionService,
    }: IHandleServices,
    ref: Ref<IRef>,
  ) => {
    const [listServicesTheProject, setListServicesTheProject] = useState<
      Array<any>
    >([]);

    const [listServicePagination, setListServicePagination] = useState<
      Array<IServiceForm>
    >([]);

    const [
      listCompleteAndUpdatedOfTheServices,
      setListCompleteAndUpdatedOfTheServices,
    ] = useState<Array<IServiceForm>>([]);

    const [grandTotalService, setGrandTotalService] = useState(0);

    const [disabled, setDisabled] = useState<boolean>(false);

    useEffect(() => {
      setDisabled(disableTabProp);
    }, [disableTabProp]);

    const getIndexOfTheServiceToBeUpdated = useCallback(
      (item: any) => {
        if (
          Object.entries(item).length > 0 &&
          typy(parseInt(item.id, 10)).isNumber
        ) {
          const indexOfTheServiceToBeUpdated = listCompleteAndUpdatedOfTheServices.findIndex(
            itemFind => itemFind.id.toString() === item.id.toString(),
          );

          return indexOfTheServiceToBeUpdated;
        }

        if (
          item.idNewItemPagination &&
          item.idNewItemPagination !== '' &&
          item.idNewItemPagination !== null
        ) {
          const indexOfTheServiceToBeUpdated = listCompleteAndUpdatedOfTheServices.findIndex(
            (itemFind: any) =>
              itemFind.idNewItemPagination === item.idNewItemPagination,
          );

          return indexOfTheServiceToBeUpdated;
        }

        return undefined;
      },
      [listCompleteAndUpdatedOfTheServices],
    );

    const handleUpdateListCompleteAndUpdatedOfTheServices = useCallback(
      (currentFormDataHasServicesOnPageActual: any): Array<IServiceForm> => {
        const listCompleteUpdated: Array<any> = [
          ...listCompleteAndUpdatedOfTheServices,
        ];

        currentFormDataHasServicesOnPageActual.map(
          // eslint-disable-next-line array-callback-return
          (item: any): void => {
            const indexOfTheServiceToBeUpdated =
              listCompleteUpdated.length === 1
                ? 0
                : getIndexOfTheServiceToBeUpdated(item);

            if (indexOfTheServiceToBeUpdated !== undefined)
              listCompleteUpdated[indexOfTheServiceToBeUpdated] = item;
          },
        );

        // eslint-disable-next-line @typescript-eslint/ban-types
        const listUpdatedCorrect = listCompleteUpdated.map((item: any) => {
          // Cria uma nova propriedade no objeto item caso não tenha o providerIdHidden
          if (Object.keys(item).indexOf('idStepHidden') === -1) {
            const service: any = listServices.find(
              (serviceItem: any) => serviceItem.id === item.serviceId,
            );

            return {
              ...item,
              idStepHidden: service.manufacturing.id,
              nameStepHidden: service.manufacturing.name,
            };
          }
          return item;
        });

        return listUpdatedCorrect;
      },
      [
        getIndexOfTheServiceToBeUpdated,
        listCompleteAndUpdatedOfTheServices,
        listServices,
      ],
    );

    const [goToLastPage, setGoToLastPage] = useState(false);

    const handleGoToLastPage = useCallback(() => {
      // setGoToLastPage(!goToLastPage);
      setGoToLastPage(false);
    }, []);

    const addNewService = useCallback(() => {
      const currentProjectHasServices = formRef.current.getData()
        .projectHasServices;

      const listServicesUpdated = [
        ...handleUpdateListCompleteAndUpdatedOfTheServices(
          currentProjectHasServices,
        ),
      ];

      setListServicesTheProject([
        ...listServicesUpdated,
        {
          id: null,
          serviceId: null,
          idStepHidden: null,
          idNewItemPagination: uuidv4(),
          nameStepHidden: '',
          quantity: '00:00',
          unitPrice: 0,
          totalPrice: 0,
          observation: '',
        },
      ]);

      setGoToLastPage(true);
    }, [formRef, handleUpdateListCompleteAndUpdatedOfTheServices]);

    const removeService = useCallback(
      (index: number) => {
        if (formRef && formRef.current) {
          const currentProjectHasServices = formRef.current.getData()
            .projectHasServices;

          if (currentProjectHasServices.length > 0) {
            const listServicesUpdated = [
              ...handleUpdateListCompleteAndUpdatedOfTheServices(
                currentProjectHasServices,
              ),
            ];

            const indexItemOfTheListCompleted = getIndexOfTheServiceToBeUpdated(
              currentProjectHasServices[index],
            );

            if (listServicesUpdated.length > 1) {
              if (indexItemOfTheListCompleted !== undefined) {
                listServicesUpdated.splice(indexItemOfTheListCompleted, 1);
                setListServicesTheProject(listServicesUpdated);

                // Leva para última página caso a página atual não tenha mais items
                if (currentProjectHasServices.length === 1)
                  setGoToLastPage(true);
              }
            } else {
              setListServicesTheProject([
                {
                  id: null,
                  serviceId: null,
                  idStepHidden: null,
                  idNewItemPagination: uuidv4(),
                  nameStepHidden: '',
                  quantity: '00:00',
                  unitPrice: 0,
                  totalPrice: 0,
                  observation: '',
                },
              ]);

              // Limpa a lista de etapas
              handleNewListManufacturingSteps([{}]);

              const inputRefQuantity = formRef.current?.getFieldRef(
                `projectHasServices[0].quantity`,
              );

              formRef.current?.clearField(`projectHasServices[0].serviceId`);
              formRef.current?.clearField(`projectHasServices[0].idStepHidden`);
              formRef.current?.clearField(
                `projectHasServices[0].nameStepHidden`,
              );
              formRef.current?.clearField(`projectHasServices[0].quantity`);

              inputRefQuantity.style.color = 'var(--cinza-claro-5bits)';
              inputRefQuantity.parentNode.parentNode.style.filter =
                'brightness(0.9)';

              // span com o texto hrs do componente InputTwoFieldsForHours
              inputRefQuantity.parentNode.parentNode.lastChild.lastChild.style.color =
                'var(--cinza-claro-5bits)';

              inputRefQuantity.disabled = false;

              formRef.current?.clearField(`projectHasServices[0].unitPrice`);
              formRef.current?.clearField(`projectHasServices[0].totalPrice`);
              formRef.current?.clearField(`projectHasServices[0].observation`);

              setGrandTotalService(0);
            }
          }
        }
      },
      [
        formRef,
        getIndexOfTheServiceToBeUpdated,
        handleNewListManufacturingSteps,
        handleUpdateListCompleteAndUpdatedOfTheServices,
      ],
    );

    const convertHoursInMinutes = useCallback(timeInHour => {
      const quantityArray = timeInHour.split(':');
      const quantityTotalMinutes =
        parseInt(quantityArray[0], 10) * 60 + parseInt(quantityArray[1], 10);

      return quantityTotalMinutes;
    }, []);

    const convertMinutesInHours = useCallback(minutes => {
      const inteiro = Math.floor(minutes / 60);
      const resto = minutes % 60;

      const hoursString = inteiro < 100 ? `00${inteiro}`.slice(-2) : inteiro;
      const minutesString = `00${resto}`.slice(-2);

      const hoursFormatted = `${hoursString}:${minutesString}`;

      return hoursFormatted;
    }, []);

    const sumHours = useCallback(
      (firstHour: string, secondHour: string) => {
        const totalHours =
          convertHoursInMinutes(firstHour) + convertHoursInMinutes(secondHour);

        const hoursFormattedAndInString = convertMinutesInHours(totalHours);

        return hoursFormattedAndInString;
      },
      [convertHoursInMinutes, convertMinutesInHours],
    );

    // eslint-disable-next-line consistent-return
    const groupByStep = useCallback((): any => {
      const { projectHasServices } = formRef.current.getData();

      const listCompleteUpdated = [
        ...handleUpdateListCompleteAndUpdatedOfTheServices(projectHasServices),
      ];
      if (
        listCompleteAndUpdatedOfTheServices &&
        listCompleteAndUpdatedOfTheServices.length > 0 &&
        projectHasServices.length > 0
      ) {
        const currentProjectHasServices = [...listCompleteUpdated];

        return currentProjectHasServices.reduce(
          (accumulate: any, item: any) => {
            const index = accumulate
              .map((itemAccumulate: any) =>
                parseInt(itemAccumulate.idStepHidden, 10),
              )
              .indexOf(parseInt(item.idStepHidden, 10));

            if (typy(parseInt(item.idStepHidden, 10)).isNumber) {
              if (index === -1) {
                accumulate.push(item);
              } else {
                const hoursFormattedAndInString = sumHours(
                  accumulate[index].quantity,
                  item.quantity ? item.quantity : '00:00',
                );

                accumulate[index] = {
                  ...accumulate[index],
                  quantity: hoursFormattedAndInString,
                };
              }
            }
            return accumulate;
          },
          [],
        );
      }
    }, [
      formRef,
      handleUpdateListCompleteAndUpdatedOfTheServices,
      listCompleteAndUpdatedOfTheServices,
      sumHours,
    ]);

    const updateManufacturingSteps = useCallback(() => {
      const listSteps: Array<any> = groupByStep();

      if (listSteps && listSteps.length > 0) {
        const newSteps = listSteps.map(item => {
          return {
            id: item.idStepHidden,
            name: item.nameStepHidden,
            forecastTime: item.quantity,
          };
        });

        handleNewListManufacturingSteps([...newSteps]);
        return;
      }
      handleNewListManufacturingSteps([{}]);
    }, [groupByStep, handleNewListManufacturingSteps]);

    const [showServices, setShowServices] = useState(false);

    const showSectionServices = useCallback(() => {
      const sectionServices: HTMLElement | null = document.querySelector(
        `#servicesArea`,
      );
      const iconExpandMore: HTMLElement | null = document.querySelector(
        `#iconExpandMoreServices`,
      );
      const grandTotalInHeader: HTMLElement | null = document.querySelector(
        `#grandTotalInHeaderServices`,
      );

      if (
        sectionServices !== null &&
        sectionServices !== undefined &&
        grandTotalInHeader !== null &&
        grandTotalInHeader !== undefined
      ) {
        if (showServices === true) {
          sectionServices.style.display = 'block';
          grandTotalInHeader.style.display = 'none';

          if (iconExpandMore !== null && iconExpandMore !== undefined) {
            iconExpandMore.style.transform = 'rotate(180deg)';
            iconExpandMore.style.transitionDuration = '0.3s';
          }
        } else {
          sectionServices.style.display = 'none';
          grandTotalInHeader.style.display = 'block';

          if (iconExpandMore !== null && iconExpandMore !== undefined) {
            iconExpandMore.style.transform = 'rotate(0deg)';
            iconExpandMore.style.transitionDuration = '0.3s';
          }
        }
      }

      setShowServices(!showServices);
    }, [showServices]);

    const sumGrandTotal = useCallback(() => {
      if (formRef && formRef.current) {
        const currentFormData = formRef.current.getData();

        const currentFormDataHasServicesOnPageActual =
          currentFormData.projectHasServices;

        if (
          listCompleteAndUpdatedOfTheServices &&
          listCompleteAndUpdatedOfTheServices.length > 0 &&
          currentFormDataHasServicesOnPageActual &&
          currentFormDataHasServicesOnPageActual.length > 0
        ) {
          const listCompleteUpdated = [
            ...handleUpdateListCompleteAndUpdatedOfTheServices(
              currentFormDataHasServicesOnPageActual,
            ),
          ];

          const currentProjectHasServices = [...listCompleteUpdated];

          const totalGeral = currentProjectHasServices.reduce(
            (sum: number, currentItem: any) => {
              // eslint-disable-next-line no-return-assign
              return fincalc(
                (sum += convertNumberFloatToDB(currentItem.totalPrice)),
              );
            },
            0,
          );

          setGrandTotalService(totalGeral);
        }
      }
    }, [
      formRef,
      handleUpdateListCompleteAndUpdatedOfTheServices,
      listCompleteAndUpdatedOfTheServices,
    ]);

    const calculateService = useCallback(
      async (indexInScope: number) => {
        const time = formRef.current.getFieldValue(
          `projectHasServices[${indexInScope}].quantity`,
        );

        const quantityTotalMinutes = convertHoursInMinutes(time);

        const unitPrice = convertNumberFloatToDB(
          formRef.current.getFieldValue(
            `projectHasServices[${indexInScope}].unitPrice`,
          ),
        );

        const priceInMinutes = unitPrice / 60;
        const totalPriceFloat: number = fincalc(
          quantityTotalMinutes * priceInMinutes,
        );

        formRef.current.setFieldValue(
          `projectHasServices[${indexInScope}].totalPrice`,
          convertNumberFloatToBr(totalPriceFloat),
        );
      },
      [convertHoursInMinutes, formRef],
    );

    const calculateServicePrice = useCallback(
      async (indexInScope: number) => {
        await calculateService(indexInScope);
        sumGrandTotal();
        updateManufacturingSteps();
      },
      [calculateService, sumGrandTotal, updateManufacturingSteps],
    );

    const handleSumPrice = useCallback(
      (e, indexInScope: number) => {
        if (e.key !== 'Tab') {
          calculateServicePrice(indexInScope);
        }
      },
      [calculateServicePrice],
    );

    const handleInputChange = useCallback(
      (inputValue: any, index) => {
        const inputRefQuantity = formRef.current?.getFieldRef(
          `projectHasServices[${index}].quantity`,
        );

        if (inputValue) {
          formRef.current?.setFieldValue(
            `projectHasServices[${index}].idStepHidden`,
            inputValue.manufacturing.id,
          );
          formRef.current?.setFieldValue(
            `projectHasServices[${index}].nameStepHidden`,
            inputValue.manufacturing.name,
          );

          formRef.current?.setFieldValue(
            `projectHasServices[${index}].unitOfMeasurement`,
            'hrs',
          );

          /* Volta a cor normal do componente. Para isso tem qua mudar o
        style filter duas divs acima, ou seja, no avô do input */

          if (disabled === false) {
            inputRefQuantity.style.color = 'var(--cinza-escuro-5bits)';
            inputRefQuantity.parentNode.parentNode.style.filter = 'none';

            // span com o texto hrs do componente InputTwoFieldsForHours
            inputRefQuantity.parentNode.parentNode.lastChild.lastChild.style.color =
              'var(--cinza-escuro-5bits)';

            inputRefQuantity.disabled = false;
          } else {
            inputRefQuantity.style.color = 'var(--cinza-claro-5bits)';
            inputRefQuantity.parentNode.parentNode.style.filter =
              'brightness(0.9)';

            // span com o texto hrs do componente InputTwoFieldsForHours
            inputRefQuantity.parentNode.parentNode.lastChild.lastChild.style.color =
              'var(--cinza-claro-5bits)';
          }

          formRef.current?.setFieldValue(
            `projectHasServices[${index}].unitPrice`,
            convertNumberFloatToBr(inputValue.price),
          );

          calculateServicePrice(index);
        } else {
          inputRefQuantity.style.color = 'var(--cinza-claro-5bits)';
          inputRefQuantity.parentNode.parentNode.style.filter =
            'brightness(0.9)';

          // span com o texto hrs do componente InputTwoFieldsForHours
          inputRefQuantity.parentNode.parentNode.lastChild.lastChild.style.color =
            'var(--cinza-claro-5bits)';

          inputRefQuantity.disabled = true;
        }
      },
      [calculateServicePrice, disabled, formRef],
    );

    const clearFields = useCallback(
      (newListPagination): void => {
        newListPagination.newListPageActual.map(
          // eslint-disable-next-line array-callback-return
          (item: any, index: number): void => {
            formRef.current.clearField(`projectHasServices[${index}].id`);
            formRef.current.clearField(
              `projectHasServices[${index}].serviceId`,
            );
            formRef.current.clearField(
              `projectHasServices[${index}].idStepHidden`,
            );
            formRef.current.clearField(
              `projectHasServices[${index}].idNewItemPagination`,
            );
            formRef.current.clearField(
              `projectHasServices[${index}].nameStepHidden`,
            );
            formRef.current.clearField(`projectHasServices[${index}].quantity`);
            formRef.current.clearField(
              `projectHasServices[${index}].unitPrice`,
            );
            formRef.current.clearField(
              `projectHasServices[${index}].totalPrice`,
            );
            formRef.current.clearField(
              `projectHasServices[${index}].observation`,
            );
            setGrandTotalService(0);
          },
        );
      },
      [formRef],
    );

    const handleServicesPagination = useCallback(
      newListPagination => {
        clearFields(newListPagination);

        setListServicePagination(newListPagination.newListPageActual);
        setListCompleteAndUpdatedOfTheServices(
          newListPagination.newListCompleteUpdated,
        );
      },
      [clearFields],
    );

    useEffect(() => {
      // formRef.current.setData({
      //   ...formRef.current.getData(),
      //   projectHasServices: [...listServicePagination],
      // });

      // eslint-disable-next-line array-callback-return
      listServicePagination.map((item: any, index: number): void => {
        formRef.current.setFieldValue(
          `projectHasServices[${index}].id`,
          item.id,
        );
        formRef.current.setFieldValue(
          `projectHasServices[${index}].idNewItemPagination`,
          item.idNewItemPagination,
        );
        formRef.current.setFieldValue(
          `projectHasServices[${index}].serviceId`,
          item.serviceId,
        );
        formRef.current.setFieldValue(
          `projectHasServices[${index}].idStepHidden`,
          item.idStepHidden,
        );
        formRef.current.setFieldValue(
          `projectHasServices[${index}].nameStepHidden`,
          item.nameStepHidden,
        );
        formRef.current.setFieldValue(
          `projectHasServices[${index}].quantity`,
          item.quantity,
        );
        formRef.current.setFieldValue(
          `projectHasServices[${index}].unitPrice`,
          item.unitPrice,
        );
        formRef.current.setFieldValue(
          `projectHasServices[${index}].totalPrice`,
          item.totalPrice,
        );
        formRef.current.setFieldValue(
          `projectHasServices[${index}].observation`,
          item.observation,
        );
      });

      /**
       * Verifica se há ao menos um item com serviceId. Caso não tenha
       * a função sumGrandTotal deve ser chamada. Isso porque se a página 2
       * por exemplo não tiver items os itens da página 1 não serão somados
       * já que a função sumGrandTotal estava sendo chamada apenas no onChange
       * do select de Service que depende do serviceId.
       */
      const existServiceId: boolean = listServicePagination.some(
        (service: any) => {
          return typy(parseInt(service.serviceId, 10)).isNumber;
        },
      );

      if (disableTabProp || existServiceId === false) {
        // sumGrandTotal();
        // updateManufacturingSteps();
      }
    }, [disableTabProp, formRef, listServicePagination, sumGrandTotal]);

    useEffect(() => {
      // setTimeout(() => {
      if (projectHasServicesProp && projectHasServicesProp.length > 0) {
        setListServicesTheProject(projectHasServicesProp);
        showSectionServices();
      } else {
        setListServicesTheProject([
          {
            id: null,
            serviceId: null,
            idStepHidden: null,
            idNewItemPagination: uuidv4(),
            nameStepHidden: '',
            quantity: '00:00',
            unitPrice: 0,
            totalPrice: 0,
            observation: '',
          },
        ]);
      }
      // }, 1000);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectHasServicesProp]);

    /**
     * A função getUpdateListCompleteAndUpdatedOfTheServices é chamada
     * na Create de planning e pega a list completa e atualizada para
     * serem usados e enviados na função de Submit, já que a paginação
     * não contém todos os item ao pegá-los por formRef.current.data.
     * * A troca de informações bidirecionais é feita pelo useImperativeHandle.
     */
    const getUpdateListCompleteAndUpdatedOfTheServices = useCallback((): Array<any> => {
      const currentProjectHasServices = formRef.current.getData()
        .projectHasServices;

      const listServicesUpdated = [
        ...handleUpdateListCompleteAndUpdatedOfTheServices(
          currentProjectHasServices,
        ),
      ];

      return listServicesUpdated;
    }, [formRef, handleUpdateListCompleteAndUpdatedOfTheServices]);

    useImperativeHandle(ref, () => ({
      removeService,
      getUpdateListCompleteAndUpdatedOfTheServices,
    }));

    return (
      <SplitServices>
        <HeaderAndGrandTotal
          onClick={showSectionServices}
          data-id="servicesArea"
        >
          <span>
            Serviços
            <MdExpandMore id="iconExpandMoreServices" size={30} />
          </span>
          <GrandTotal
            id="grandTotalInHeaderServices"
            style={{ display: 'none' }}
          >
            Total Geral:{' '}
            <span>R$ {convertNumberFloatToBr(grandTotalService)}</span>
          </GrandTotal>
        </HeaderAndGrandTotal>
        <section id="servicesArea">
          {listServicePagination.length > 0 &&
            listServicePagination.map((item, index) => (
              <Scope path={`projectHasServices[${index}]`} key={String(index)}>
                <InputHidden id="id" name="id" />
                <InputHidden
                  id="idNewItemPagination"
                  name="idNewItemPagination"
                />
                <SplitServiceItem>
                  <SimpleSelect
                    id="serviceId"
                    name="serviceId"
                    labelText={index === 0 ? 'Nome*' : ''}
                    placeholder=""
                    options={listServices}
                    onChange={option => {
                      handleInputChange(option, index);
                    }}
                    isDisabled={disabled}
                    disabled={disabled}
                  />
                  <InputHidden name="idStepHidden" />
                  <InputHidden name="nameStepHidden" />

                  <QuantityUnitPriceSubtotalService>
                    <InputTwoFieldsForHours
                      id="quantity"
                      name="quantity"
                      type="text"
                      labelText={index === 0 ? 'Tempo*' : ''}
                      onKeyUp={e => handleSumPrice(e, index)}
                      autoComplete="off"
                      disabled
                    />

                    <InputCurrency
                      id="unitPrice"
                      name="unitPrice"
                      type="text"
                      labelText={index === 0 ? 'Valor/h*' : ''}
                      autoComplete="off"
                      disabled
                    />
                    <InputCurrency
                      id="totalPrice"
                      name="totalPrice"
                      type="text"
                      labelText={index === 0 ? 'Subtotal*' : ''}
                      autoComplete="off"
                      disabled
                    />
                  </QuantityUnitPriceSubtotalService>

                  <ObservationAndTrashIcon>
                    <Input
                      id="observation"
                      name="observation"
                      type="text"
                      labelText={index === 0 ? 'Observação' : ''}
                      autoComplete="off"
                      disabled={disabled}
                    />
                    {!disabled && (
                      <div>
                        <FiTrash2
                          size={20}
                          color="var(--cinza-claro-5bits)"
                          onClick={() => {
                            formRef.current.getFieldValue(
                              `projectHasServices[${index}].serviceId`,
                            )
                              ? showModalConfirmationOfExclusionService(
                                  index,
                                  true,
                                )
                              : removeService(index);
                          }}
                        />
                      </div>
                    )}
                  </ObservationAndTrashIcon>
                </SplitServiceItem>
              </Scope>
            ))}

          <ActionAndGrandTotal>
            <div>
              {!disabled && (
                <Button
                  name="newService"
                  type="button"
                  onClick={e => {
                    e.preventDefault();
                    addNewService();
                  }}
                >
                  Mais serviço
                </Button>
              )}
            </div>

            <GrandTotal>
              Total Geral:{' '}
              <span>R$ {convertNumberFloatToBr(grandTotalService)}</span>
            </GrandTotal>
          </ActionAndGrandTotal>
          <PaginationAndSetInputsOnComponentService
            formRef={formRef}
            listItems={listServicesTheProject}
            perPageProp={10}
            goToLastPage={goToLastPage}
            onChangeListPaginatedItems={handleServicesPagination}
            handleGoToLastPage={handleGoToLastPage}
          />
        </section>
      </SplitServices>
    );
  },
);
export default memo(HandleServices);
