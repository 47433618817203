/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import fincalc from 'fincalc';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import typy from 'typy';
import getValidationErrors from '../../../utils/getValidationError';

import api from '../../../services/api';
import { useToast } from '../../../context/ToastContext';

import Loading from '../../../components/Loading';

import SimpleSelect from '../../../components/SimpleSelect';

import currentSectorList from '../../../data/currentSectorList.json';

import { Container, Content, SplitFooter } from './styles';

import HeadProject from '../../Sectors/HeadProject';

import BodyBusinessProposal from '../BodyBusinessProposal';

import convertDateToBr from '../../../utils/convertDateToBr';
import convertNumberFloatToDB from '../../../utils/convertNumberFloatToDB';
import ChecklistProject from '../../Sectors/componentsSectors/ChecklistProject';

import { IMaterialResponse } from '../../../interfaces/material';
import { IServiceResponse } from '../../../interfaces/service';
import { IProviderResponse } from '../../../interfaces/provider';
import { IBusinessProposal } from '../../../interfaces/businessProposal';
import { IChecklist } from '../../../interfaces/checklist';
import { IProject, IProjectResponse } from '../../../interfaces/project';
import ModalLoading from '../../../components/ModalLoading';
import convertNumberFloatToBr from '../../../utils/convertNumberFloatToBr';
import convertDateStringToDate from '../../../utils/convertDateStringToDate';
import InputHidden from '../../../components/InputHidden';

import GetProject from '../../AuxiliaryFunctions/GetProject';

import { IPermission } from '../../../interfaces/permission';
import { businessProposalStatusValid } from '../../../data/businessProposalStatusValid.enum';
import ModalConfirmationCanceledProposals from '../components/ModalConfirmationCanceledProposals';
import Button from '../../../components/Button';
import { IClient, IClientResponse } from '../../../interfaces/client';

interface IBusinessProposalWithProject extends IBusinessProposal {
  project: IProjectResponse;
}

interface IBusinessProposalMixedProject extends Omit<IProject, 'id'> {
  projectId: number;
  finalPrice: number;
  status: string;
}

interface ICanceledProposals {
  id: number;
  whyCanceled: string;
}

interface PermissionDTO {
  permissions: IPermission;
}

const CancelBusinessProposal: React.FC<PermissionDTO> = ({ permissions }) => {
  const { id }: any = useParams();
  const navigate = useNavigate();
  // Lembra que o id passado pelo urrl em Update é a da proposta e não a ado projeto igual em creter Mudar
  // no submit
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();

  const [project, setProject] = useState<IBusinessProposalMixedProject>(
    {} as IBusinessProposalMixedProject,
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);

  const [listClients, setListClients] = useState<IClient[]>([]);
  const [listMaterials, setListMaterials] = useState<IMaterialResponse[]>([]);
  const [listServices, setListServices] = useState<IServiceResponse[]>([]);

  const [listProviders, setListProviders] = useState<IProviderResponse[]>([]);

  const [listChecklist, setListChecklist] = useState<IChecklist[]>([]);

  const [loadingChecklist, setLoadingChecklist] = useState<boolean>(false);
  const [
    loadingModalCancelProposal,
    setLoadingModalCancelProposal,
  ] = useState<boolean>(false);

  useEffect(() => {
    if (Object.entries(project).length > 0) {
      setTimeout(() => {
        formRef.current?.setData({ ...project });
      }, 1000);
      // formRef.current?.setData({ ...project });
    }
  }, [project]);

  // Get project
  const getProject = useCallback(async (): Promise<void> => {
    try {
      const response = await api.get<IBusinessProposalWithProject>(
        `proposals/${id}`,
      );

      const projectInProposal: IProjectResponse = response.data.project;

      let newDrawings: Array<any> = [];
      let newProjectHasMaterials: Array<any> = [];
      let newProjectHasServices: Array<any> = [];

      if (response.data) {
        if (
          projectInProposal.drawings &&
          projectInProposal.drawings.length > 0
        ) {
          newDrawings = projectInProposal.drawings.map(item => {
            return {
              ...item,
              revisionDate: convertDateToBr(item.revisionDate),
            };
          });
        }

        // projectHasMaterials
        if (
          projectInProposal.projectHasMaterials &&
          projectInProposal.projectHasMaterials.length > 0
        ) {
          newProjectHasMaterials = projectInProposal.projectHasMaterials.map(
            item => {
              const totalPriceCalculated =
                item.totalPrice !== null
                  ? item.totalPrice
                  : fincalc(
                      convertNumberFloatToDB(
                        convertNumberFloatToBr(item.quantity),
                      ) *
                        convertNumberFloatToDB(
                          convertNumberFloatToBr(item.unitPrice),
                        ),
                    );

              return {
                ...item,
                quantity: item.quantity,
                unitPrice: convertNumberFloatToBr(item.unitPrice),
                totalPrice: convertNumberFloatToBr(totalPriceCalculated),
              };
            },
          );
        }

        // projectHasServices
        if (
          projectInProposal.projectHasServices &&
          projectInProposal.projectHasServices.length > 0
        ) {
          newProjectHasServices = projectInProposal.projectHasServices.map(
            item => {
              return {
                ...item,
                unitPrice: convertNumberFloatToBr(item.unitPrice),
                totalPrice: convertNumberFloatToBr(item.totalPrice),
              };
            },
          );
        }
      }

      const newProjectResponse: any = {
        ...projectInProposal,
        forecastEndDate: convertDateStringToDate(
          projectInProposal.forecastEndDate,
        ),
        technicalValidatorDate: convertDateToBr(
          projectInProposal.technicalValidatorDate,
        ),
        operationalValidatorDate: convertDateToBr(
          projectInProposal.operationalValidatorDate,
        ),
        projectValidatorDate: convertDateToBr(
          projectInProposal.projectValidatorDate,
        ),
        drawings: [...newDrawings],
        projectHasMaterials: [...newProjectHasMaterials],
        projectHasServices: [...newProjectHasServices],
        /*
         * projectHasMaterialsPurchasing é o mesmo que projectHasMaterials porém é usado na aba Compras e
         * não em Planejamento.
         * Tem que ser nomes diferentes por causa do scope das abas Planning e Purchasing. Mas a lista é a
         * mesma newProjectHasMaterials.
         */
        projectHasMaterialsPurchasing: [...newProjectHasMaterials],
      };

      // Deleta o id do projeto pois irá utilizar o projectId de proposals e o id tem que ser o de proposals
      delete newProjectResponse.id;

      const projectWithProposal = {
        ...newProjectResponse,
        ...response.data,
        finalPrice: convertNumberFloatToBr(response.data.finalPrice),
        costPrice: convertNumberFloatToBr(response.data.costPrice),
        grossProfit: convertNumberFloatToBr(response.data.grossProfit),
        percentageProfit: convertNumberFloatToBr(
          response.data.percentageProfit,
        ),
      };

      // Deleta o project que vem em proposals pois não utilizará mais
      delete projectWithProposal.project;

      setProject(projectWithProposal);

      formRef.current?.setData(projectWithProposal);

      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      // eslint-disable-next-line no-shadow
      if (error.response) {
        const { data } = error.response; // Error vindo do back está em data dentro de response
        addToast({
          type: 'error',
          title: data.message,
        });
        return;
      }

      addToast({
        type: 'error',
        title: 'Ocorreu um erro interno',
        description: `${error}`,
      });
    }
  }, [addToast, id]);
  // End Get project

  // List Materials
  useEffect(() => {
    setLoading(true);
    const getMaterials = async (): Promise<void> => {
      try {
        // Clientes
        const responseClients = await api.get<IClientResponse[]>('clients');

        const responseClientsFormatted: any = responseClients.data.map(i => ({
          value: i.id,
          label: i.name,
        }));

        setListClients(responseClientsFormatted);

        // Materials
        const responseMaterials = await api.get<IMaterialResponse[]>(
          'materials/active',
        );

        const responseMaterialsFormatted: any = responseMaterials.data.map(
          i => ({
            ...i,
            value: i.id,
            label: i.name,
          }),
        );

        setListMaterials(responseMaterialsFormatted);
        // End Materials

        // Services
        // setLoadingServices(true);
        const responseServices = await api.get<IServiceResponse[]>(
          'services/active',
        );

        const responseServicesFormatted: any = responseServices.data.map(i => ({
          ...i,
          value: i.id,
          label: i.name,
        }));

        setListServices(responseServicesFormatted);
        // End Services

        // Providers
        const responseProviders = await api.get<IProviderResponse[]>(
          'providers/active',
        );

        const responseProvidersFormatted: any = responseProviders.data.map(
          i => ({
            ...i,
            value: i.id,
            label: i.fantasyName,
          }),
        );

        setListProviders(responseProvidersFormatted);
        // End Providers

        // Checklist
        const responseChecklist = await api.get<any[]>('checklist');

        setListChecklist(responseChecklist.data);
        // End checklist

        // Project
        getProject();

        // setLoadingMaterials(false);
      } catch (error: any) {
        setLoading(false);

        if (error.response && error.response.data) {
          const { data } = error.response; // Error vindo do back está em data dentro de response
          addToast({
            type: 'error',
            title: data.message,
          });
          return;
        }

        addToast({
          type: 'error',
          title: 'Error',
          description: 'Erro ao listar!',
        });
      }
    };
    getMaterials();
  }, [addToast, getProject]);
  // End List Materials

  const toggleCloseModalCancelProposals = useCallback(() => {
    setLoadingModalCancelProposal(!loadingModalCancelProposal);
  }, [loadingModalCancelProposal]);

  // handleFormSubmit
  const handleFormSubmit = useCallback(
    async (dataForm: ICanceledProposals, { reset }) => {
      try {
        setSaving(true);

        formRef.current?.setErrors({});

        // const schema = Yup.object().shape({
        //   whyCanceled: Yup.string().required(
        //     'O porque do cancelamento da proposta é obrigatório',
        //   ),
        // });

        // await schema.validate(dataForm, { abortEarly: false });

        if (typy(parseInt(id, 10)).isNumber) {
          const newDataForm = {
            id: parseInt(id, 10),
            whyCanceled: dataForm.whyCanceled,
          };

          await api.put('proposals/cancel', newDataForm);

          addToast({
            type: 'success',
            title: 'Proposta cancelada com sucesso',
          });
        } else {
          addToast({
            type: 'error',
            title: 'O id da Proposta é obrigatório',
          });
        }

        reset();
        setSaving(false);
        toggleCloseModalCancelProposals();
        navigate('/proposals');
      } catch (error: any) {
        setSaving(false);
        toggleCloseModalCancelProposals();

        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);

          return;
        }

        if (error.response) {
          const { data } = error.response; // Error vindo do back está em data dentro de response

          addToast({
            type: 'error',
            title: 'Erro no cancelamento da proposta',
            description: data.message,
          });
          if (
            formRef.current?.getFieldRef(data.field) &&
            data.field.toString().indexOf('_id') === -1
          ) {
            formRef.current?.getFieldRef(data.field).focus(); // Foca o campo que deu erro de acordo com o field retornado do back
          }

          return;
        }

        addToast({
          type: 'error',
          title: 'Error',
          description: 'Erro ao cancelar a proposta!',
        });
      }
    },
    [addToast, navigate, id, toggleCloseModalCancelProposals],
  );
  // End handleFormSubmit

  return (
    <>
      <Container>
        <ModalLoading visible={loading}>
          <p>
            <i>Carregando os dados da Proposta...</i>
          </p>
        </ModalLoading>
        <Content>
          <Form ref={formRef} onSubmit={handleFormSubmit}>
            {/* <Split> */}
            <header>
              <p>Cancelamento de Proposta Comercial</p>
              <span>Nº do Projeto: {project.projectId}</span>
            </header>

            <HeadProject
              listClients={listClients}
              showInformationInMobile
              readOnlyProp
            />

            <BodyBusinessProposal
              formRef={formRef}
              listMaterialsProp={listMaterials}
              listServicesProp={listServices}
              listProvidersProp={listProviders}
              projectProp={project}
              permissions={permissions}
              disabledHandleResume
              disabledHandleAdditionalInformation
            />

            <ChecklistProject
              listChecklistProp={listChecklist}
              loadingChecklistProp={loadingChecklist}
              projectHasChecklist={project.projectHasChecklist}
            />

            <SplitFooter>
              <SimpleSelect
                id="currentSector"
                name="currentSector"
                labelText="Enviar projeto para o setor*:"
                placeholder=""
                options={currentSectorList.filter(
                  item => item.value !== 'Produção',
                )}
                getOptionLabel={option => option.value}
                menuPlacement="auto"
                isDisabled
                disabled
              />
              {(project.status !== businessProposalStatusValid.Executando &&
                project.status !== businessProposalStatusValid.Arquivada) ===
                true && (
                <div>
                  <Button
                    type="button"
                    onClick={toggleCloseModalCancelProposals}
                    disabled={saving}
                  >
                    {saving ? (
                      <Loading size={24} color="white" />
                    ) : (
                      'Cancelar Proposta'
                    )}
                  </Button>
                </div>
              )}
            </SplitFooter>

            <ModalConfirmationCanceledProposals
              saving={saving}
              loadModalProp={loadingModalCancelProposal}
              closeModalCancelProposals={toggleCloseModalCancelProposals}
            />
          </Form>
        </Content>
      </Container>
    </>
  );
};

export default CancelBusinessProposal;
