/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef, useCallback, useState } from 'react';

import { Form } from '@unform/web';
import { FormHandles, Scope } from '@unform/core';
import * as Yup from 'yup';

import api from '../../services/api';
import { useToast } from '../../context/ToastContext';

import Input from '../../components/Input';
import InputFile from '../../components/InputFile';
import Button from '../../components/Button';

import getValidationErrors from '../../utils/getValidationError';

const InputNFe: React.FC = () => {
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const [disableButton, setDisableButton] = useState(false);
  const [load, setLoad] = useState(false);
  const [percentCompleted, setPercentCompleted] = useState(0);

  // Esta página é de teste para o envio de vários arquivos para o servidor

  // handleSubmit
  const handleSubmit = useCallback(
    async (dataForm: any, { reset }) => {
      try {
        setDisableButton(true);
        setLoad(true);

        formRef.current?.setErrors({});

        // /** A função mista do Yup pode corresponder a todos os tipos */
        // const schema = Yup.object().shape({
        //   file: Yup.mixed().test('file', 'Arquivo obrigatório!', () => {
        //     if (dataForm.file) {
        //       return true;
        //     }
        //     return false;
        //   }),
        // });

        // await schema.validate(dataForm, {
        //   abortEarly: false,
        // });

        const config = {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress(progressEvent: any) {
            // Indica a porcentagem já enviada
            setPercentCompleted(
              Math.round((progressEvent.loaded * 100) / progressEvent.total),
            );
          },
        };

        const formData = new FormData();

        // Fazer o for normal para não atrapalhar os indices
        // Os [] na frente dos nomes é vazio mesmo, para indicar que será um array.
        formData.append('name[]', 'NomeDesenho');
        formData.append('drawingNumber[]', dataForm.files[0].versionDrawing);
        formData.append('revisionNumber[]', dataForm.files[0].versionDrawing);
        formData.append('revisionDate[]', '2021-06-28');
        formData.append('files', dataForm.files[0].file);

        formData.append('name[]', 'NomeDesenho2');
        formData.append('drawingNumber[]', dataForm.files[1].versionDrawing);
        formData.append('revisionNumber[]', dataForm.files[1].versionDrawing);
        formData.append('revisionDate[]', '2021-06-29');
        formData.append('files', dataForm.files[1].file);

        const response = await api.post('drawings', formData, config);

        addToast({
          type: 'success',
          title: 'Importação realizada com sucesso',
        });

        reset();
        setDisableButton(false);
        setPercentCompleted(0);
        setLoad(false);
        // history.push('/home');
      } catch (error: any) {
        setDisableButton(false);
        setPercentCompleted(0);
        setLoad(false);

        // Verifica se o error é do Yup ou seja dos campos
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);

          return;
        }
        // eslint-disable-next-line no-shadow
        if (error.response) {
          const { data } = error.response; // Error vindo do back está em data dentro de response
          addToast({
            type: 'error',
            title: 'Erro na importação',
            description: data.message,
          });
          if (formRef.current?.getFieldRef(data.field)) {
            formRef.current?.getFieldRef(data.field).focus(); // Foca o campo que deu erro de acordo com o field retornado do back
          }

          return;
        }

        addToast({
          type: 'error',
          title: 'Ocorreu um erro interno',
        });
      }
    },
    [addToast],
  );
  // Fim handleSubmit

  return (
    <Form
      ref={formRef}
      className="mt-2 mb-4 d-md-flex flex-md-row align-items-md-end justify-content-md-center"
      onSubmit={handleSubmit}
    >
      <Input name="nameClient" labelText="Nome Cliente" />
      <hr />
      <br />
      <Scope path="files[0]">
        <Input name="versionDrawing" labelText="Versão" />
        <InputFile name="file" className="w-50 mb-4 mb-md-0 mr-md-3" />
      </Scope>
      <br />
      <hr />
      <Scope path="files[1]">
        <Input name="versionDrawing" labelText="Versão" />
        <InputFile name="file" className="w-50 mb-4 mb-md-0 mr-md-3" />
      </Scope>
      <br />

      <Button
        name="submit"
        type="submit"
        className="mt-5 mt-md-0 w-auto"
        disabled={disableButton}
      >
        Enviar
      </Button>
    </Form>
  );
};

export default InputNFe;
