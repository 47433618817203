import React, { useRef, useState, useEffect } from 'react';

import api from '../../../services/api';
import { useToast } from '../../../context/ToastContext';

import Loading from '../../../components/Loading';
import Input from '../../../components/Input';
import TextArea from '../../../components/TextArea';
import SimpleSelect from '../../../components/SimpleSelect';
import DatePicker from '../../../components/DatePicker';

import { Split, WeightAndPrice } from './styles';

import { IClient, IClientResponse } from '../../../interfaces/client';

interface HeaderProjectProp {
  id?: string;
  showInformationInMobile: boolean;
  readOnlyProp?: boolean;
  listClients: IClient[];
}

const HeaderProject: React.FC<HeaderProjectProp> = ({
  id = '',
  showInformationInMobile = false,
  readOnlyProp = false,
  listClients,
}) => {
  // const [loadingClients, setLoadingClients] = useState<boolean>(false);

  return (
    <Split id={id} showInformationInMobile={showInformationInMobile}>
      <div className="itemClientId">
        <SimpleSelect
          id="clientId"
          name="clientId"
          labelText="Cliente*"
          placeholder=""
          options={listClients}
          isDisabled={readOnlyProp}
          readOnly={readOnlyProp}
          disabled={readOnlyProp}
        />
      </div>

      <div className="itemNameResponsible">
        <Input
          id="nameResponsible"
          name="nameResponsible"
          type="text"
          labelText="Responsável pelo projeto*"
          maxLength={100}
          readOnly={readOnlyProp}
          autoComplete="off"
        />
      </div>
      <div className="itemTitle">
        <Input
          id="title"
          name="title"
          type="text"
          labelText="Título do Projeto*"
          maxLength={100}
          readOnly={readOnlyProp}
          autoComplete="off"
        />
      </div>

      <WeightAndPrice>
        <Input
          id="quantity"
          name="quantity"
          type="text"
          labelText="Qtd. de peças*"
          autoComplete="off"
          readOnly={readOnlyProp}
        />
        <DatePicker
          id="forecastEndDate"
          name="forecastEndDate"
          labelText="Previsão de entrega"
          readOnly={readOnlyProp}
          disabled={readOnlyProp}
        />
      </WeightAndPrice>

      <div className="itemDescription">
        <TextArea
          id="description"
          name="description"
          label="Descrição do projeto*"
          readOnly={readOnlyProp}
          disabled={readOnlyProp}
        />
      </div>
    </Split>
  );
};

export default HeaderProject;
