/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import api from '../../../services/api';
import { IUsersHasMenus } from '../../../interfaces/user';
import getValidationErrors from '../../../utils/getValidationError';
import { useToast } from '../../../context/ToastContext';
import { useAuth } from '../../../context/AuthContext';

import Button from '../../../components/Button';
import Loading from '../../../components/Loading';
import PermissionsForm from './components/PermissionsForm';
import CreateFormUser from './components/CreateForm';

import { Container, Content, Split, SplitLeft, SplitRight } from './styles';

import { IMenu, IMenuResponse } from '../../../interfaces/menu';
import { IPermission } from '../../../interfaces/permission';

interface PermissionDTO {
  permissions: IPermission;
}
interface ICreateUserFormData {
  name: string;
  phone: string;
  email: string;
  nickname: string;
  username: string;
  password?: string;
  isAdmin: boolean;
  iActive: boolean;
  usersHasMenus: IUsersHasMenus[];
}

const CreateUser: React.FC<PermissionDTO> = ({ permissions }) => {
  const fromRef = useRef<FormHandles>(null);
  const { addToast } = useToast();

  const navigate = useNavigate();
  const { user } = useAuth();

  const [saving, setSaving] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [listAllMenus, setListAllMenus] = useState<IMenu[]>([]);

  useEffect(() => {
    let isMounted = true;
    setLoading(true);

    api
      .get<IMenuResponse[]>('menus')
      .then(response => {
        if (isMounted) {
          setListAllMenus(
            response.data.map(i => ({
              id: i.id,
              groupMenu: i.group_menu,
              label: i.label,
              route: i.route,
              action: i.action,
              isSubmenu: i.is_submenu,
              submenus: i.submenus?.map(s => ({
                id: s.id,
                groupMenu: s.group_menu,
                label: s.label,
                route: s.route,
                action: s.action,
                isSubmenu: s.is_submenu,
              })),
            })),
          );
          setLoading(false);
        }
      })
      .catch(error => {
        setLoading(false);

        if (error.response) {
          const { data } = error.response; // Error vindo do back está em data dentro de response
          addToast({
            type: 'error',
            title: data.message,
          });
          return;
        }

        addToast({
          type: 'error',
          title: 'Erro ao listar!',
          description: `Error: ${error}`,
        });
      });

    return (): void => {
      isMounted = false;
    };
  }, [addToast]);

  const handleMainMenusThatHasSubMenus = useCallback(
    (listUsersHasMenus: any) => {
      let mainMenusThatHasSubMenusCorrect = [];

      // Filtra apenas os menus que tenham mainIdSubMenu e que tenham pelo menus uma permissão true
      const subMenusWithTrue = listUsersHasMenus.filter((item: any) => {
        if (item.mainIdSubMenu && Object.values(item).some(i => i === true)) {
          return item;
        }
        return null;
      });

      // Filtra apenas os menus que tenham menuMainId que são o menus com submenus
      const mainMenusThatHasSubMenus = listUsersHasMenus.filter((item: any) => {
        if (item.menuMainId) {
          return item;
        }
        return null;
      });

      //  Seta read true para os menus que tenham submenus com pelo menos uma permissão true
      if (mainMenusThatHasSubMenus.length > 0) {
        mainMenusThatHasSubMenusCorrect = mainMenusThatHasSubMenus.map(
          (item: any) => {
            if (
              subMenusWithTrue.some(
                (i: any) => i.mainIdSubMenu === item.menuMainId,
              )
            ) {
              return { menuId: item.menuMainId, read: true };
            }
            return { menuId: item.menuMainId, read: false };
          },
        );
      }

      return mainMenusThatHasSubMenusCorrect;
    },
    [],
  );
  const handleSubmit = useCallback(
    async (dataForm: ICreateUserFormData) => {
      try {
        setSaving(true);

        fromRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('O Nome Completo é obrigatório'),
          nickname: Yup.string().required('O Codnome é obrigatório'),
          username: Yup.string().required('O Username é obrigatório'),
          password: Yup.string().required('A senha é obrigatória'),
        });

        await schema.validate(dataForm, { abortEarly: false });

        const newMainMenusThatHasSubMenus = handleMainMenusThatHasSubMenus(
          dataForm.usersHasMenus,
        );

        const newUsersHasMenusWithoutMainMenu = dataForm.usersHasMenus.filter(
          (item: any) => !item.menuMainId,
        );

        const newUsersHasMenusWithSubMenuCorrect = newUsersHasMenusWithoutMainMenu.map(
          (item: any) => {
            if (item.mainIdSubMenu) {
              // eslint-disable-next-line no-param-reassign
              delete item.mainIdSubMenu;
            }
            return item;
          },
        );

        const usersHasMenusFinal = [
          ...newUsersHasMenusWithSubMenuCorrect,
          ...newMainMenusThatHasSubMenus,
        ];

        await api.post('users', {
          name: dataForm.name,
          phone: dataForm.phone,
          email: dataForm.email,
          nickname: dataForm.nickname,
          username: dataForm.username,
          password: dataForm.password,
          isAdmin: dataForm.isAdmin,
          usersHasMenus: usersHasMenusFinal,
        });

        navigate('/users');
      } catch (error: any) {
        setSaving(false);

        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          fromRef.current?.setErrors(errors);

          return;
        }

        if (error.response) {
          const { data } = error.response; // Error vindo do back está em data dentro de response
          addToast({
            type: 'error',
            title: data.message,
          });
          return;
        }

        addToast({
          type: 'error',
          title: 'Erro ao cadastrar!',
          description: `Error: ${error}`,
        });
      }
    },
    [addToast, handleMainMenusThatHasSubMenus, navigate],
  );

  const handleChangeAdministratorValue = (): void => {
    console.log('change');
  };

  return (
    <Container>
      {loading ? (
        <p>carregando ...</p>
      ) : (
        <Content>
          <Form ref={fromRef} onSubmit={handleSubmit}>
            <Split>
              <SplitLeft notShowBorder={!user.isAdmin}>
                <CreateFormUser
                  saving={saving}
                  onChangeAdministratorValue={handleChangeAdministratorValue}
                />

                <Button type="submit" disabled={saving}>
                  {saving ? <Loading size={24} color="white" /> : 'Salvar'}
                </Button>
              </SplitLeft>

              <SplitRight>
                <PermissionsForm listAllMenusProp={listAllMenus} />
              </SplitRight>
            </Split>
          </Form>
        </Content>
      )}
    </Container>
  );
};

export default CreateUser;
