import { cpf as cpfValidator, cnpj as cnpjValidator } from 'cpf-cnpj-validator';

export default function validationCPF(cpfForm: string): boolean {
  if (cpfForm) {
    const regexp = new RegExp(/[^\d]+/g);
    const removeMaskCpfCnpj = cpfForm.replace(regexp, '');

    if (
      (removeMaskCpfCnpj.length === 11 &&
        cpfValidator.isValid(removeMaskCpfCnpj)) ||
      (removeMaskCpfCnpj.length === 14 &&
        cnpjValidator.isValid(removeMaskCpfCnpj))
    ) {
      return true;
    }
    return false;
  }
  return false;
}
