import styled, { css } from 'styled-components';

export const Container = styled.div``;

export const Content = styled.div`
  margin: 35px 50px;
  form {
    font-style: normal;
    font-weight: normal;
    font-size: 1.6rem;
    color: var(--cinza-escuro-5bits);

    > div > div > button {
      width: 233px;
    }
  }
`;

export const Split = styled.div`
  display: flex;
  justify-content: center;
`;

export const SplitLeft = styled.div<{ notShowBorder: boolean }>`
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  width: 500px;
  justify-content: center;
  align-items: center;

  border-right: 1px solid rgba(171, 171, 171, 0.5);

  ${props =>
    props.notShowBorder &&
    css`
      border: none;

      header {
        justify-content: center;
      }

      > div {
        > div {
          padding: 24px;
          > div {
            > label {
              margin-left: 0;
              align-self: center;
            }
            > div > input {
              text-align: center;
            }

            &.btn-change-password {
              display: flex;
              flex-direction: column;
              align-items: center;

              width: 210px;
              height: 69px;
              margin: 0 auto;
              margin-bottom: 44px;

              input {
                text-align: center;
                &::placeholder {
                  font-style: italic;
                }
              }

              button {
                margin: 0 auto;
                margin-bottom: 8px;
                background-color: transparent;
                border: none;
                padding: 4px;
                cursor: pointer;
                border-radius: 4px;
                color: var(--cinza-escuro-5bits);

                font-weight: 500;
                font-size: 1.6rem;
                line-height: 2.1rem;
                text-align: center;

                &[id='password'] {
                  border: none;

                  &:hover {
                    filter: brightness(1);
                  }
                }
              }
            }
          }
        }
      }
    `}
`;

export const SplitRight = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  width: 630px;

  margin-left: 45px;

  padding: 24px 35px;
  background: rgba(13, 78, 133, 0.1);
  border: 1px solid rgba(13, 78, 133, 0.1);
  border-radius: 4px;
`;
