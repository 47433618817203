import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Container } from './styles';

const GoBackNavigationButton: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Container>
      {/* navigate(-1) era o go back do useHistory, ou seja, volta uma página */}
      <button type="button" onClick={() => navigate(-1)}>
        &lt; Voltar
      </button>
    </Container>
  );
};

export default GoBackNavigationButton;
