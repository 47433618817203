/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/ban-types */
import React, { createContext, useContext, useCallback, useState } from 'react';

import api from '../services/api';
import { IUser, IUserResponse } from '../interfaces/user';

interface AuthState {
  token: string;
  configuration: object;
  user: Partial<IUser>;
  menus: Array<any>;
  permission: Array<object>;
}

interface SignInCredentials {
  username: string;
  password: string;
}

interface AuthContextData {
  configuration: object;
  user: Partial<IUser>;
  menus: Array<any>;
  permission: Array<any>;
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut(): void;
}

interface ISessionResponse {
  token: any;
  configuration: any;
  user: Partial<IUserResponse>;
  menus: any;
  permission: any;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem(
      `@${process.env.REACT_APP_COMPANY_NAME}:token`,
    );
    const configuration = localStorage.getItem(
      `@${process.env.REACT_APP_COMPANY_NAME}:configuration`,
    );
    const user = localStorage.getItem(
      `@${process.env.REACT_APP_COMPANY_NAME}:user`,
    );
    const menus = localStorage.getItem(
      `@${process.env.REACT_APP_COMPANY_NAME}:menus`,
    );
    const permission = localStorage.getItem(
      `@${process.env.REACT_APP_COMPANY_NAME}:permission`,
    );

    if (token && configuration && user && menus && permission) {
      return {
        token,
        configuration: JSON.parse(configuration),
        user: JSON.parse(user),
        menus: JSON.parse(menus),
        permission: JSON.parse(permission),
      };
    }

    return {} as AuthState;
  });

  const signIn = useCallback(async ({ username, password }) => {
    const response = await api.post<ISessionResponse>('/sessions', {
      username,
      password,
    });

    const { token, configuration, user, menus, permission } = response.data;

    localStorage.setItem(`@${process.env.REACT_APP_COMPANY_NAME}:token`, token);
    localStorage.setItem(
      `@${process.env.REACT_APP_COMPANY_NAME}:configuration`,
      JSON.stringify(configuration),
    );
    localStorage.setItem(
      `@${process.env.REACT_APP_COMPANY_NAME}:user`,
      JSON.stringify(user),
    );
    localStorage.setItem(
      `@${process.env.REACT_APP_COMPANY_NAME}:menus`,
      JSON.stringify(menus),
    );
    localStorage.setItem(
      `@${process.env.REACT_APP_COMPANY_NAME}:permission`,
      JSON.stringify(permission),
    );

    setData({
      token,
      configuration,
      permission,
      menus,
      user,
    });
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem(`@${process.env.REACT_APP_COMPANY_NAME}:token`);
    localStorage.removeItem(
      `@${process.env.REACT_APP_COMPANY_NAME}:configuration`,
    );
    localStorage.removeItem(`@${process.env.REACT_APP_COMPANY_NAME}:user`);
    localStorage.removeItem(`@${process.env.REACT_APP_COMPANY_NAME}:menus`);
    localStorage.removeItem(
      `@${process.env.REACT_APP_COMPANY_NAME}:permission`,
    );

    setData({} as AuthState);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        configuration: data.configuration,
        user: data.user,
        menus: data.menus,
        permission: data.permission,
        signIn,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
