import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin: 19px 46px 0;

  header {
    font-weight: 500;
    font-size: 2.4rem;

    margin-left: 1rem;
    margin-bottom: 1.6875rem;
  }

  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  //Medias
  @media (min-width: 0px) and (max-width: 759px) {
    margin: 1.2rem 1.2rem;

    form {
      width: 100%;
    }
  }

  @media (min-width: 760px) and (max-width: 999px) {
  }
`;

export const Split = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 50%;
  padding-bottom: 3.125rem;
  border-bottom: 0.1rem solid var(--cinza-claro-5bits);

  > div {
    margin-bottom: 1rem;
    label {
      margin-left: 1rem;
    }
  }

  //Medias
  @media (min-width: 0px) and (max-width: 759px) {
    width: 100%;
  }

  @media (min-width: 760px) and (max-width: 999px) {
  }
`;

export const WeightAndPrice = styled.div`
  display: flex;
  width: 100%;

  > div:first-child {
    margin-right: 1.5rem;
  }

  //Medias
  @media (min-width: 0px) and (max-width: 759px) {
    flex-wrap: wrap;

    /* Preço */
    > div:last-child {
      margin-right: 0;
      margin-top: 1rem;
    }
  }

  @media (min-width: 760px) and (max-width: 999px) {
  }
`;

export const Providers = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  margin-top: 2.3rem;
  margin-bottom: 10rem;

  > span {
    color: var(--cinza-escuro-5bits);
    font-weight: 500;
    margin-bottom: 0.5rem;
  }

  //Medias
  @media (min-width: 0px) and (max-width: 759px) {
    width: 100%;
  }

  @media (min-width: 760px) and (max-width: 999px) {
  }
`;
