import styled from 'styled-components';

export const SplitServices = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  padding-top: 3.125rem;
  padding-bottom: 3.125rem;
  border-bottom: 0.1rem solid var(--cinza-claro-5bits);

  > section {
    margin-top: 3.8rem;
  }
`;

export const HeaderAndGrandTotal = styled.div`
  display: flex;
  justify-content: space-between;

  width: 100%;

  cursor: pointer;
  :hover {
    background: var(--branco-gelo-5bits);
  }

  > span {
    display: flex;
    justify-content: center;
    align-items: center;

    width: fit-content;

    font-style: normal;
    font-weight: 500;
    font-size: 2.4rem;
    line-height: 3.2rem;
    text-transform: capitalize;

    color: var(--cinza-escuro-5bits);

    > svg {
      margin-left: 1rem;
    }
  }
`;

export const SplitServiceItem = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-end;
  gap: 1.4rem;

  width: 100%;
  margin-bottom: 1.7rem;

  > div {
    label {
      margin-left: 1rem;
    }
  }

  /* Select serviceId */
  > div:first-child {
    flex: 1;
    min-width: 30rem;
  }

  //Medias
  @media (min-width: 0px) and (max-width: 759px) {
    /* Select serviceId */
    > div:first-child {
      min-width: 100%;
    }
  }
`;

export const QuantityUnitPriceSubtotalService = styled.div`
  flex: 1;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-end;
  gap: 1.4rem;

  max-width: 39rem;
  min-width: 39rem;

  /* Quantity Service */
  > div:nth-child(1) {
    width: 15rem;
    min-width: 15rem;
  }

  /* UnitPrice Service */
  > div:nth-child(2) {
    width: 9.2rem;
    min-width: 9.2rem;
  }

  /* Subtotal Service */
  > div:nth-child(3) {
    width: 12rem;
    min-width: 12rem;
  }

  //Medias
  @media (min-width: 0px) and (max-width: 460px) {
    flex-wrap: wrap;
    min-width: auto;

    > div {
      flex: 1;
    }

    /* Subtotal Service */
    > div:nth-child(3) {
      min-width: 100%;
    }
  }
  @media (min-width: 461px) and (max-width: 759px) {
    flex-wrap: wrap;

    > div {
      flex: 1;
    }
  }

  @media (min-width: 760px) and (max-width: 999px) {
  }
`;

export const ActionAndGrandTotal = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1.4rem;

  width: 100%;

  > div {
    > button {
      width: fit-content;
      background: var(--azul-5bits);
      font-style: normal;
      font-weight: bold;
      font-size: 1.4rem;
      text-transform: none;
      line-height: 1.9rem;

      padding: 1rem 5.75rem;
    }
  }
`;

export const GrandTotal = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2.1rem;

  color: var(--azul-5bits);

  > span {
    margin-left: 0.5rem;

    font-weight: 900;
    font-size: 2.4rem;
    line-height: 3.2rem;

    color: var(--laranja-5bits);
  }
`;

export const ObservationAndTrashIcon = styled.div`
  flex: 1;
  min-width: 30rem;

  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 1.4rem;

  > div {
    label {
      margin-left: 1rem;
    }
  }

  /* Icon trash Material */
  > div:nth-child(2) {
    margin-top: 2.1rem;
  }

  //Medias
  @media (min-width: 0px) and (max-width: 759px) {
    min-width: 100%;
  }
`;
