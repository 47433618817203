/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import api from '../../../services/api';

import getValidationErrors from '../../../utils/getValidationError';
import convertNumberFloatToDB from '../../../utils/convertNumberFloatToDB';
import { useToast } from '../../../context/ToastContext';

import Loading from '../../../components/Loading';
import Input from '../../../components/Input';
import InputCurrency from '../../../components/InputCurrency';
import InputCurrencyThreeDigits from '../../../components/InputCurrencyThreeDigits';
import Button from '../../../components/Button';
import SimpleSelect from '../../../components/SimpleSelect';
import SelectAnimated from '../../../components/SelectAnimated';

import { Container, Content, Split, WeightAndPrice, Providers } from './styles';

import { IProvider, IProviderResponse } from '../../../interfaces/provider';
import { IUnitMeasurement } from '../../../interfaces/unitMeasurement';
import { IMaterial, IMaterialForm } from '../../../interfaces/material';

const CreateMaterial: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const navigate = useNavigate();
  const { addToast } = useToast();

  const [listProviders, setListProviders] = useState<IProvider[]>([]);
  const [loadingProviders, setLoadingProviders] = useState<boolean>(false);

  const [unitMeasurementList, setUnitMeasurementList] = useState<
    Array<IUnitMeasurement>
  >([]);

  const [
    loadingUnitMeasurementList,
    setLoadingUnitMeasurementList,
  ] = useState<boolean>(false);

  const [saving, setSaving] = useState<boolean>(false);

  useEffect(() => {
    setLoadingProviders(true);

    api
      .get<IProviderResponse[]>('providers/active')
      .then(response => {
        const responseFormatted: any = response.data.map(i => ({
          value: i.id,
          label: i.fantasyName,
        }));

        setListProviders(responseFormatted);

        setLoadingProviders(false);
      })
      .catch(error => {
        setLoadingProviders(false);

        if (error.response && error.response.data) {
          const { data } = error.response; // Error vindo do back está em data dentro de response
          addToast({
            type: 'error',
            title: data.message,
          });
          return;
        }

        addToast({
          type: 'error',
          title: 'Error',
          description: 'Erro ao listar!',
        });
      });
  }, [addToast]);

  // List Unit Measurement
  useEffect(() => {
    const getUnitMeasurementList = async (): Promise<void> => {
      try {
        setLoadingUnitMeasurementList(true);

        const response = await api.get<IUnitMeasurement[]>('units/active');

        const responseFormatted: any = response.data.map(i => ({
          ...i,
          value: i.id,
          label: i.name,
        }));

        setUnitMeasurementList(responseFormatted);

        setLoadingUnitMeasurementList(false);
      } catch (error: any) {
        setLoadingUnitMeasurementList(false);

        if (error.response && error.response.data) {
          const { data } = error.response; // Error vindo do back está em data dentro de response
          addToast({
            type: 'error',
            title: data.message,
          });
          return;
        }

        addToast({
          type: 'error',
          title: 'Error',
          description: 'Erro ao listar!',
        });
      }
    };
    getUnitMeasurementList();
  }, [addToast]);
  // End List Unit Measurement

  const handleFormSubmit = useCallback(
    async (dataForm: IMaterialForm, { reset }) => {
      try {
        setSaving(true);

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('O Nome do Material é obrigatório'),
          unitId: Yup.string().required('A unidade é obrigatória'),
        });

        await schema.validate(dataForm, { abortEarly: false });

        const newData: Omit<
          IMaterial,
          'id' | 'isActive' | 'createdAt' | 'updatedAt'
        > = {
          ...dataForm,
          price: convertNumberFloatToDB(dataForm.price),
          weight: convertNumberFloatToDB(dataForm.weight),
        };

        await api.post('materials', newData);

        addToast({
          type: 'success',
          title: 'Cadastro realizado com sucesso',
        });

        reset();
        setSaving(false);

        navigate('/materials');
      } catch (error: any) {
        setSaving(false);

        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);

          return;
        }

        if (error.response) {
          const { data } = error.response; // Error vindo do back está em data dentro de response

          addToast({
            type: 'error',
            title: 'Erro no cadastro',
            description: data.message,
          });
          if (
            formRef.current?.getFieldRef(data.field) &&
            data.field.toString().indexOf('_id') === -1
          ) {
            formRef.current?.getFieldRef(data.field).focus(); // Foca o campo que deu erro de acordo com o field retornado do back
          }

          return;
        }

        addToast({
          type: 'error',
          title: 'Error',
          description: 'Erro ao cadastrar!',
        });
      }
    },
    [addToast, navigate],
  );

  return (
    <Container>
      <Content>
        <header>
          <p>Cadastro de Material</p>
        </header>
        <Form ref={formRef} onSubmit={handleFormSubmit}>
          <Split>
            <Input
              id="name"
              name="name"
              type="text"
              labelText="Nome*"
              maxLength={100}
              disabled={saving}
            />

            {loadingUnitMeasurementList ? (
              <p style={{ height: '4.5rem', textAlign: 'center' }}>
                carregando ...
              </p>
            ) : (
              <SimpleSelect
                id="unitId"
                name="unitId"
                labelText="Unidade*"
                placeholder=""
                options={unitMeasurementList}
                disabled={saving}
              />
            )}
            <WeightAndPrice>
              <InputCurrencyThreeDigits
                id="weight"
                name="weight"
                type="text"
                labelText="Peso Kg"
                disabled={saving}
              />
              <InputCurrency
                id="price"
                name="price"
                type="text"
                labelText="Preço"
                disabled={saving}
              />
            </WeightAndPrice>
            <Input
              id="observation"
              name="observation"
              type="text"
              labelText="Observação"
              maxLength={100}
              disabled={saving}
            />
          </Split>

          {loadingProviders ? (
            <p>carregando ...</p>
          ) : (
            <Providers>
              <span>Fornecedores desse Material</span>
              <SelectAnimated
                id="providersId"
                name="providersId"
                placeholder=""
                options={listProviders}
                isMulti
                disabled={saving}
              />
            </Providers>
          )}

          <Button type="submit" style={{ width: '233px' }} disabled={saving}>
            {saving ? <Loading size={24} color="white" /> : 'Salvar'}
          </Button>
        </Form>
      </Content>
    </Container>
  );
};

export default CreateMaterial;
