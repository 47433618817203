import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;

  margin-top: 1.9rem;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 92%;
  /* margin: 1.9rem 0 0 4.6rem; */

  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    header {
      width: 100%;
      font-weight: 500;
      font-size: 2.4rem;
      line-height: 3.2rem;

      /* margin-left: 1rem; */
      margin-bottom: 1.6875rem;
      /* padding-right: 4.6rem; */

      > span {
        color: var(--cinza-claro-5bits);
      }
    }
  }
`;

export const HeadOpenProject = styled.div`
  width: 100%;
  padding-bottom: 2.9rem;
  border-bottom: 0.1rem solid var(--cinza-claro-5bits);
`;

export const SplitFooter = styled.div`
  width: 100%;

  @media (min-width: 0px) and (max-width: 759px) {
    margin-bottom: 11rem;
  }
`;

export const SelectSectorAndButtonSave = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  margin-top: 3.4rem;
  padding-bottom: 3.125rem;

  > div:first-child {
    width: 48%;
    > label {
      margin-left: 2.5rem;
    }
  }

  button {
    width: 37.3rem;
  }

  //Medias
  @media (min-width: 0px) and (max-width: 759px) {
    box-sizing: border-box;
    position: absolute;
    left: 0;
    bottom: 0;

    width: 100vw;

    padding: 1.3rem 0.8rem;

    background: #ffffff;
    border: 1px solid rgba(13, 78, 133, 0.1);
    box-shadow: 0px -10px 20px rgba(0, 0, 0, 0.05);

    > div:first-child {
      width: 63%;
      > label {
        margin-left: 0.8rem;
      }
    }

    button {
      width: 12.2rem;
    }
  }

  @media (min-width: 760px) and (max-width: 999px) {
  }
`;

export const SplitInvoices = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  padding-top: 3.125rem;
  padding-bottom: 3.125rem;
  border-bottom: 0.1rem solid var(--cinza-claro-5bits);

  > section {
    margin-top: 3.8rem;
  }
`;

export const HeaderAndGrandTotal = styled.div`
  display: flex;
  justify-content: space-between;

  width: 100%;

  cursor: pointer;
  :hover {
    background: var(--branco-gelo-5bits);
  }

  > span {
    display: flex;
    justify-content: center;
    align-items: center;

    width: fit-content;

    font-style: normal;
    font-weight: 500;
    font-size: 2.4rem;
    line-height: 3.2rem;
    text-transform: capitalize;

    color: var(--cinza-escuro-5bits);

    > svg {
      margin-left: 1rem;
    }
  }
`;

export const SplitInvoiceItem = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-end;
  gap: 1.4rem;

  width: 100%;
  margin-bottom: 1.7rem;

  > div {
    label {
      margin-left: 1rem;
    }
  }
`;

export const QuantityPriceSubtotalInvoice = styled.div`
  flex: 1;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-end;
  gap: 1.4rem;

  //Medias
  @media (min-width: 0px) and (max-width: 460px) {
    flex-wrap: wrap;
    min-width: auto;

    > div {
      flex: 1;
    }
  }
  @media (min-width: 461px) and (max-width: 759px) {
    flex-wrap: wrap;

    > div {
      flex: 1;
    }
  }

  @media (min-width: 760px) and (max-width: 999px) {
  }
`;

export const ObservationAndTrashIcon = styled.div`
  flex: 1;
  min-width: 30rem;

  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 1.4rem;

  > div {
    label {
      margin-left: 1rem;
    }
  }

  /* Checkbox */
  > div:nth-child(2) {
    margin-top: 2.1rem;
  }

  /* Icon trash Invoice */
  > div:nth-child(3) {
    margin-top: 2.1rem;
  }

  //Medias
  @media (min-width: 0px) and (max-width: 759px) {
    min-width: 100%;
  }
`;

export const PaydayAndIsPay = styled.div`
  flex: 1;
  width: 30rem;
  min-width: 30rem;
  max-width: 30rem;

  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 1.4rem;

  > div {
    label {
      margin-left: 1rem;
    }
  }

  /* Checkbox */
  > div:nth-child(1) {
    /* margin-top: 2.1rem; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    height: 6.1rem;
    gap: 0.7rem;
    > span {
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 2.1rem;

      color: var(--cinza-escuro-5bits);
    }

    > div {
      label {
        margin-left: 0;
      }
    }
  }

  //Medias
  @media (min-width: 0px) and (max-width: 759px) {
    min-width: 100%;
  }
`;
