/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef, useEffect, useCallback, useState } from 'react';
import ReactInputMask, { Props as InputProps } from 'react-input-mask';

import { useField } from '@unform/core';
import { IconBaseProps } from 'react-icons';
import { FiAlertCircle } from 'react-icons/fi';

import Error from '../../styles/errorFieldGlobal';
import { Container, Content } from './styles';

interface Props extends InputProps {
  name: string;
  labelText?: string;
  icon?: React.ComponentType<IconBaseProps>;
}

export default function InputMask({
  name,
  labelText,
  icon: Icon,
  disabled,
  readOnly,
  ...rest
}: Props): any {
  const inputRef = useRef(null);
  const {
    fieldName,
    registerField,
    defaultValue,
    error,
    clearError,
  } = useField(name);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [isFilled, setIsFilled] = useState<boolean>(false);

  const handleInputBlur = useCallback(() => {
    // if (inputRef.current) {
    //   setIsFilled(!!inputRef.current.value);
    // }
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  const handleChange = useCallback((): void => {
    if (error) {
      clearError();
    }
  }, [clearError, error]);

  return (
    <Container>
      {labelText && <label htmlFor={`id-${name}`}>{labelText}</label>}
      <Content
        isErrored={!!error}
        isFocused={isFocused}
        isFilled={isFilled}
        isDisabled={!!disabled}
        isReadOnly={!!readOnly}
      >
        {Icon && <Icon size={20} />}
        <ReactInputMask
          id={`id-${name}`}
          type="text"
          ref={inputRef}
          defaultValue={defaultValue}
          onFocus={(): void => setIsFocused(true)}
          onBlur={handleInputBlur}
          onChange={handleChange}
          disabled={disabled}
          readOnly={readOnly}
          {...rest}
        />
      </Content>
      {error && (
        <Error>
          <FiAlertCircle color="var(--on-errored-message-bg-color)" size={20} />
          {error}
        </Error>
      )}
    </Container>
  );
}
