import styled, { css } from 'styled-components';

interface ContainerProps {
  isFocused: boolean;
  isErrored: boolean;
}

export const Container = styled.div<ContainerProps>`
  width: 100%;

  label {
    margin: 0;
  }

  & + div {
    color: var(--azul-5bits);
  }

  > div {
    input {
      font-style: italic;
    }

    span,
    label,
    input {
      ${props =>
        props.isFocused &&
        css`
          color: var(--laranja-5bits);
          border-color: var(--laranja-5bits);
          box-shadow: 0 0 0 0.2rem rgba(255, 132, 65, 0.1);
          background-color: var(--branco-puro-5bits);
        `}
      ${props =>
        props.isErrored &&
        css`
          border-color: var(--on-errored-input-border-color);
        `}
    }
  }
`;
