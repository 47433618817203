import React from 'react';
import * as styles from './styles';

import helpCircle from '../../../../../../assets/Help_Circle_24px.svg';

const SubtitleWorkflow: React.FC = () => {
  return (
    <styles.Container>
      <styles.Content>
        <styles.ImageAndSubtitle>
          <img src={helpCircle} alt="" />
          <styles.TextSubtitle>Legenda</styles.TextSubtitle>
        </styles.ImageAndSubtitle>

        <styles.Description>
          <styles.TextDescription colorDescription="--verde-5bits">
            <styles.CircleDescription colorDescription="--verde-5bits" />
            Em execução
          </styles.TextDescription>
          <styles.TextDescription colorDescription="--cinza-claro-5bits">
            <styles.CircleDescription colorDescription="--cinza-claro-5bits" />
            Parado
          </styles.TextDescription>
          <styles.TextDescription colorDescription="--azul-5bits">
            <styles.CircleDescription colorDescription="--azul-5bits" />
            Agendado
          </styles.TextDescription>
          <styles.TextDescription colorDescription="--laranja-5bits">
            <styles.CircleDescription colorDescription="--laranja-5bits" />
            Selecionado
          </styles.TextDescription>
        </styles.Description>
      </styles.Content>
    </styles.Container>
  );
};

export default SubtitleWorkflow;
