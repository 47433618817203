import styled from 'styled-components';

import BackgroundLogin from '../../assets/Background_Login.svg';
import BackgroundMobileLogin from '../../assets/Background_Mobile_Login.svg';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: var(--branco-puro-5bits);

  //Medias
  @media (min-width: 0px) and (max-width: 759px) {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: flex-end;
    height: calc(var(--vh, 1vh) * 100);
    background-color: var(--branco-puro-5bits);
  }

  @media (min-width: 760px) and (max-width: 999px) {
  }
`;

export const Content = styled.div`
  padding: 4.7rem;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 50vw;

  form {
    margin: 5.6rem 0 0;

    width: 28.6vw;
    height: 39.1vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;

    button {
      margin-top: 1.6rem;
    }

    > div > label {
      align-self: center;
    }
  }

  //Medias
  @media (min-width: 0px) and (max-width: 759px) {
    width: 100%;
    justify-content: center;
    form {
      width: 100%;

      justify-content: flex-start;

      //Input username
      > div:first-child {
        margin-bottom: 1.7rem;
      }

      //Button Entrar
      button {
        margin-top: 3.3rem;
      }

      > div > label {
        align-self: center;
      }
    }
  }

  @media (min-width: 760px) and (max-width: 999px) {
  }
`;

export const Logo = styled.div`
  margin-top: 1.1rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;

  font-weight: 500;
  font-size: 2.4rem;
  line-height: 3.2rem;

  img {
    width: 177px;
    height: 62px;

    margin-bottom: 2.1rem;
  }

  //Medias
  @media (min-width: 0px) and (max-width: 759px) {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    text-align: center;

    img {
      width: 121px;
      height: 42px;

      margin-bottom: 0.6rem;
    }
  }

  @media (min-width: 760px) and (max-width: 999px) {
  }
`;

export const ImageAndText = styled.div`
  display: flex;
  align-items: center;
  width: 50vw;
  height: 100vh;

  background-image: url(${BackgroundLogin});
  background-repeat: no-repeat;
  background-size: cover;

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding-left: 3rem;

    width: 45.6vw;
    height: 85.9vh;

    border: 1px solid var(--branco-puro-5bits);
    border-radius: 0px 25px 25px 0px;
    border-left: 0;

    > span:first-child {
      width: 35.78vw;
      /* height: 20.19vh; */

      font-weight: 500;
      font-size: 6.4rem;
      line-height: 8.5rem;

      color: var(--branco-puro-5bits);

      margin-bottom: 2.2rem;
    }
    > span:last-child {
      width: 35.78vw;
      /* height: 20.19vh; */

      font-weight: 500;
      font-size: 3.6rem;
      line-height: 4.8rem;

      color: var(--branco-puro-5bits);
    }

    @media only screen and (max-height: 900px), (max-width: 1099px) {
      > span:first-child {
        font-size: 4.2rem;
        line-height: 5.5rem;

        margin-bottom: 2.2rem;
      }

      > span:last-child {
        font-weight: 500;
        font-size: 2rem;
        line-height: 3.8rem;
      }
    }
  }

  //Medias
  @media (min-width: 0px) and (max-width: 759px) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: fit-content;

    padding: 2.8rem 2.4rem 0 2.4rem;

    background-image: url(${BackgroundMobileLogin});
    background-repeat: no-repeat;
    background-size: cover;

    > div {
      width: 100%;
      height: 18.2vh;

      padding: 0 2.3rem 0 2.3rem;

      border: 1px solid var(--branco-puro-5bits);
      border-radius: 14px 14px 0px 0px;

      > span:first-child {
        width: 100%;
        /* height: 20.19vh; */

        font-style: normal;
        font-weight: 500;
        font-size: 2.4rem;
        line-height: 3.2rem;
        text-align: center;

        color: var(--branco-puro-5bits);

        margin-bottom: 1.3rem;
      }
      > span:last-child {
        width: 100%;
        /* height: 20.19vh; */

        font-style: normal;
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 2.1rem;
        text-align: center;

        color: var(--branco-puro-5bits);
      }
    }
  }

  @media (min-width: 760px) and (max-width: 999px) {
  }
`;
