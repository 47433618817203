import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import { useField } from '@unform/core';

import { Container } from './styles';

interface CheckboxInputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
  position: 'left' | 'right';
  onChange?: (e: any) => void;
}

const CheckboxInput: React.FC<CheckboxInputProps> = ({
  name,
  label,
  position = 'left',
  onChange = (): void => undefined,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, defaultValue, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: (ref: HTMLInputElement) => {
        return ref.checked;
      },
    });
  }, [fieldName, registerField]);

  return (
    <Container>
      <label htmlFor={rest.id || name} data-position={position}>
        {label}
        <input
          id={name}
          type="checkbox"
          ref={inputRef}
          name={fieldName}
          defaultChecked={defaultValue}
          onChange={(): void => onChange(inputRef.current?.checked)}
          {...rest}
        />
        <span />
      </label>
    </Container>
  );
};

export default CheckboxInput;
