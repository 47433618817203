/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-curly-newline */
import React, { useEffect, useState } from 'react';
import { FormHandles } from '@unform/core';

import Button from '../../../../components/Button';
import { businessProposalStatusValid } from '../../../../data/businessProposalStatusValid.enum';
import Loading from '../../../../components/Loading';

import Modal from '../../../../components/Modal';

import { Container, Content, ActionSaveModal } from './styles';

interface ModalDTO {
  // formRef: any | FormHandles;
  loadModalProp: boolean;
  // closeModalFinishedProposals: () => void;
}

const ModalListProvidersIncomplete: React.FC<ModalDTO> = ({
  // formRef,
  loadModalProp = false,
  // closeModalFinishedProposals,
}) => {
  const [loadModal, setLoadModal] = useState(false);

  useEffect(() => {
    setLoadModal(loadModalProp);
  }, [loadModalProp]);

  return (
    <Modal visibleProp={loadModal}>
      <Container>
        <h1>Atenção: existe material sem fornecedor!</h1>
        <Content>
          <ActionSaveModal>
            <Button
              type="button"
              onClick={() => {
                setLoadModal(false);
              }}
            >
              OK
            </Button>
          </ActionSaveModal>
        </Content>
      </Container>
    </Modal>
  );
};

export default ModalListProvidersIncomplete;
