import { ValidationError } from 'yup';

interface IErrors {
  [key: string]: string;
}

export default function getValidationError(error: ValidationError): IErrors {
  const validationErrors: IErrors = {};

  error.inner.forEach((innerError: ValidationError) => {
    if (typeof innerError.path === 'string') {
      validationErrors[innerError.path] = innerError.message;
    }
  });

  return validationErrors;
}
