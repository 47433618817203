function getRealViewportSizeOnMobile(): void {
  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  const vh: number = window.innerHeight * 0.01;
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty('--vh', `${vh}px`);

  // We listen to the resize event
  window.addEventListener('resize', () => {
    // We execute the same script as before
    const vhResize = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vhResize}px`);
  });
}
export default getRealViewportSizeOnMobile;
