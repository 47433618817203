import styled, { css } from 'styled-components';

type IDescription = {
  colorDescription: string;
};

export const Container = styled.div`
  width: 48rem;

  margin-right: 4.7rem;
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  > div:nth-child(1) {
    opacity: 1;
    transition: all 1s ease-in-out;
  }
  > div:nth-child(2) {
    opacity: 0;
    transform: rotateX(180deg);
    transition: all 1s ease-in-out;
  }

  :hover {
    > div:nth-child(1) {
      opacity: 0;
      transform: rotateX(180deg);
      transition: all 1s ease-in-out;
    }

    > div:nth-child(2) {
      opacity: 1;
      transform: rotateX(0deg);
      transition: all 1s ease-in-out;
    }
  }
`;

export const ImageAndSubtitle = styled.div`
  position: absolute;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /* width: 461px; */
  height: 24px;
  gap: 0.5rem;
`;

export const TextSubtitle = styled.div`
  text-align: right;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
`;

export const Description = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 461px;
  height: 24px;
  gap: 1.5rem;
`;

export const CircleDescription = styled.div<IDescription>`
  width: 2rem;
  height: 2rem;

  border-radius: 50%;

  ${props => css`
    background-color: var(${props.colorDescription});
  `}
`;

export const TextDescription = styled.div<IDescription>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  text-align: right;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;

  ${props => css`
    color: var(${props.colorDescription});
  `}
`;
