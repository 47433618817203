import React, { useCallback, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';

import getValidationErrors from '../../utils/getValidationError';
import { useAuth } from '../../context/AuthContext';
import { useToast } from '../../context/ToastContext';

import Input from '../../components/Input';
import Button from '../../components/Button';
import Loading from '../../components/Loading';

import logo from '../../assets/logo.png';
import { Container, Content, Logo, ImageAndText } from './styles';

interface ISignInFormData {
  name: string;
  username: string;
  password: string;
}

const SignIn: React.FC = () => {
  const fromRef = useRef<FormHandles>(null);

  const { addToast } = useToast();
  const { signIn } = useAuth();
  const navigate = useNavigate();

  const [signInLoading, setSignInLoading] = useState<boolean>(false);

  const handleSubmit = useCallback(
    async (data: ISignInFormData) => {
      try {
        setSignInLoading(true);

        fromRef.current?.setErrors({});

        const schema = Yup.object().shape({
          username: Yup.string().required('O Nome do usuário é obrigatório'),
          password: Yup.string().required('A Senha é obrigatória'),
        });

        await schema.validate(data, { abortEarly: false });

        await signIn({ username: data.username, password: data.password });

        navigate('/home');
      } catch (error) {
        setSignInLoading(false);

        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          fromRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'A autenticação falhou!',
          description: `Error: ${error}`,
        });
      }
    },
    [signIn, navigate, addToast],
  );

  return (
    <Container>
      <Content>
        <Logo>
          <img src={logo} alt="5BIts logomarca" />
          <p>Sistema de gestão de OS</p>
        </Logo>
        <Form ref={fromRef} onSubmit={handleSubmit}>
          <Input
            id="name"
            name="username"
            type="text"
            labelText="Nome de usuário"
            disabled={signInLoading}
          />
          <Input
            id="password"
            name="password"
            type="password"
            labelText="Senha"
            disabled={signInLoading}
          />

          <Button
            type="submit"
            style={{ width: '233px' }}
            disabled={signInLoading}
          >
            {signInLoading ? <Loading size={24} color="white" /> : 'Entrar'}
          </Button>
        </Form>
      </Content>
      <ImageAndText>
        <div>
          <span>Muito mais possibilidades!</span>
          <span>Sua empresa na ponta dos seus dedos</span>
        </div>
      </ImageAndText>
    </Container>
  );
};

export default SignIn;
