import styled from 'styled-components';

export const SplitTotalization = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  padding-top: 3.125rem;
  padding-bottom: 3.125rem;
  border-bottom: 0.1rem solid var(--cinza-claro-5bits);

  > section {
    margin-top: 3.8rem;
  }

  /* Medias */
  @media (min-width: 0px) and (max-width: 759px) {
  }

  @media (min-width: 760px) and (max-width: 999px) {
  }
`;

export const HeaderAndGrandTotal = styled.div`
  display: flex;
  justify-content: space-between;

  width: 100%;

  cursor: pointer;
  :hover {
    background: var(--branco-gelo-5bits);
  }

  > span {
    display: flex;
    justify-content: center;
    align-items: center;

    width: fit-content;

    font-style: normal;
    font-weight: 500;
    font-size: 2.4rem;
    line-height: 3.2rem;
    text-transform: none;

    color: var(--cinza-escuro-5bits);

    > svg {
      margin-left: 1rem;
    }
  }
`;

interface SplitTotalizationItemProps {}
export const SplitTotalizationItem = styled.div<SplitTotalizationItemProps>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  align-items: flex-end;

  width: 100%;
  margin-bottom: 1.7rem;
  gap: 1.8rem;

  > div {
    flex: 1;
    min-width: 14.089rem;
    label {
      margin-left: 1rem;
    }
  }

  //providerName
  > div:first-child {
    flex: 4;
    width: 70%;

    label {
      margin-left: 1rem;
    }
  }

  /* Medias */
  @media (min-width: 0px) and (max-width: 759px) {
    > div {
      flex: 1;
      width: 100%;
      min-width: 100%;
    }
  }

  @media (min-width: 760px) and (max-width: 999px) {
  }
`;
