/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-curly-newline */
import React from 'react';

import Button from '../../../../../../components/Button';
import Loading from '../../../../../../components/Loading';

import Modal from '../../../../../../components/Modal';

import { Container, Content, Title, ActionsButtons } from './styles';

interface ModalDTO {
  loadModalProp: boolean;
  startingOSProp: boolean;
  forecastStartDateTimeProp: string;
  handleSubmitStartProp: () => void;
  closeModalConfirmStartActionProp: () => void;
}

const ModalStopAction: React.FC<ModalDTO> = ({
  loadModalProp,
  startingOSProp,
  forecastStartDateTimeProp = '',
  handleSubmitStartProp,
  closeModalConfirmStartActionProp,
}) => {
  return (
    <Modal visibleProp={loadModalProp}>
      <Container>
        <Content>
          <Title>
            <span>
              Confirma o início da etapa em{' '}
              <strong>{forecastStartDateTimeProp}</strong>?
            </span>
          </Title>

          <ActionsButtons>
            <Button type="button" onClick={handleSubmitStartProp}>
              {startingOSProp ? <Loading size={24} color="white" /> : 'Sim'}
            </Button>
            <Button type="button" onClick={closeModalConfirmStartActionProp}>
              Não
            </Button>
          </ActionsButtons>
        </Content>
      </Container>
    </Modal>
  );
};

export default ModalStopAction;
