/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import React, { useEffect, useState, useCallback, memo } from 'react';
import { FormHandles } from '@unform/core';

import HandleMaterials from './components/HandleMaterials';
import HandleServices from './components/HandleServices';
import HandleManufacturingSteps from './components/HandleManufacturingSteps';
import FeasibilityAnalysis from './components/FeasibilityAnalysis';

import { IMaterialResponse } from '../../../../interfaces/material';
import { IServiceResponse } from '../../../../interfaces/service';

import { Container } from './styles';
import { IUserResponse } from '../../../../interfaces/user';

interface BodyPlanningProp {
  formRef: any | FormHandles;
  listMaterialsProp: Array<IMaterialResponse>;
  listServicesProp: Array<IServiceResponse>;
  listUsersProp: Omit<IUserResponse, 'usersHasMenus'>[];
  listUpdatedByServiceProp: Array<any>;
  projectProp: any;
  disableTabProp: boolean;
  showModalConfirmationOfExclusionMaterial: (
    indexMaterial: number,
    show: boolean,
  ) => void;
  showModalConfirmationOfExclusionService: (
    indexService: number,
    show: boolean,
  ) => void;
  refMaterial?: any;
  refService?: any;
}

const BodyPlanning: React.FC<BodyPlanningProp> = ({
  formRef,
  listMaterialsProp,
  listServicesProp,
  listUsersProp,
  listUpdatedByServiceProp,
  projectProp,
  disableTabProp = false,
  showModalConfirmationOfExclusionMaterial,
  showModalConfirmationOfExclusionService,
  refMaterial,
  refService,
}) => {
  const [listManufacturingSteps, setListManufacturingSteps] = useState<any[]>(
    [],
  );

  useEffect(() => {
    if (listUpdatedByServiceProp && listUpdatedByServiceProp.length > 0) {
      setListManufacturingSteps(listUpdatedByServiceProp);
    }
  }, [listUpdatedByServiceProp]);

  const addNewListManufacturingSteps = useCallback((newList: any) => {
    setListManufacturingSteps(newList);
  }, []);

  return (
    <Container>
      <HandleMaterials
        formRef={formRef}
        listMaterials={listMaterialsProp}
        projectProp={projectProp}
        disableTabProp={disableTabProp}
        showModalConfirmationOfExclusionMaterial={
          showModalConfirmationOfExclusionMaterial
        }
        ref={refMaterial}
      />
      <HandleServices
        formRef={formRef}
        listServices={listServicesProp}
        projectHasServicesProp={projectProp.projectHasServices}
        handleNewListManufacturingSteps={addNewListManufacturingSteps}
        disableTabProp={disableTabProp}
        showModalConfirmationOfExclusionService={
          showModalConfirmationOfExclusionService
        }
        ref={refService}
      />

      <HandleManufacturingSteps
        formRef={formRef}
        listUpdatedByService={listManufacturingSteps}
        projectHasManufacturingStepsProp={
          projectProp.projectHasManufacturingSteps
        }
        disableTabProp={disableTabProp}
      />

      <FeasibilityAnalysis
        formRef={formRef}
        listUsers={listUsersProp}
        disableTabProp={disableTabProp}
      />
    </Container>
  );
};

export default memo(BodyPlanning);
