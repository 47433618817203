import styled from 'styled-components';

export const ContainerFeasibilityAnalysis = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  width: 100%;
  padding-top: 3.125rem;
  padding-bottom: 3.125rem;
  border-bottom: 0.1rem solid var(--cinza-claro-5bits);

  > span {
    margin-bottom: 3.8rem;

    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;

    color: var(--cinza-escuro-5bits);
  }
`;

export const SplitLeftValidationSignatures = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  width: 100%;

  margin-bottom: 1.7rem;

  > div {
    flex: 1;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > span {
      /* margin-left: 2.1rem; */
      margin-bottom: 1.5rem;

      font-family: Red Hat Display;
      font-style: normal;
      font-weight: bold;
      font-size: 1.6rem;
      line-height: 2.1rem;

      color: var(--cinza-escuro-5bits);
    }
  }

  > div:nth-child(1) {
    padding-right: 2.8rem;
  }
  > div:nth-child(2) {
    padding: 0 2.8rem;

    border: 1px solid rgba(171, 171, 171, 0.5);
    border-top: none;
    border-bottom: none;
  }
  > div:nth-child(3) {
    padding-left: 2.8rem;
  }

  //Medias
  @media (min-width: 0px) and (max-width: 759px) {
    flex-direction: column;
    gap: 3.8rem;

    > div {
      align-items: flex-start;
    }

    > div:nth-child(1) {
      padding-right: 0;
    }
    > div:nth-child(2) {
      padding: 0;

      border: none;
    }
    > div:nth-child(3) {
      padding-left: 0;
    }
  }

  @media (min-width: 760px) and (max-width: 999px) {
  }
`;

export const FieldsNameAndDate = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.4rem;

  width: 100%;

  label {
    margin-left: 2.1rem;
  }

  /* Nome*/
  > div:nth-child(1) {
    width: 100%;
    /* margin-right: 1.3rem; */
  }
  /* Date*/
  > div:nth-child(2) {
    width: 10vw;
    min-width: 14.3rem;

    label {
      margin-left: 1.5rem;
    }
  }

  //Medias
  @media (min-width: 0px) and (max-width: 759px) {
  }

  @media (min-width: 760px) and (max-width: 999px) {
  }
`;
