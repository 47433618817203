import React, { InputHTMLAttributes } from 'react';

import { ImageExpandMoreCircle } from './styles';

interface ImageProps extends InputHTMLAttributes<HTMLDivElement> {
  rotate?: number;
  activeColor: boolean;
}
const ImageExpandMoreCircleComponent: React.FC<ImageProps> = ({
  rotate = 0,
  activeColor = false,
  ...rest
}) => {
  return (
    <ImageExpandMoreCircle rotate={rotate} activeColor={activeColor} {...rest}>
      <svg
        width="34"
        height="33"
        viewBox="0 0 34 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 16.2918C0 7.29408 7.29408 0 16.2918 0H17.7085C26.7062 0 34.0002 7.29408 34.0002 16.2918V16.2918C34.0002 25.2895 26.7062 32.5836 17.7085 32.5836H16.2918C7.29407 32.5836 0 25.2895 0 16.2918V16.2918Z"
          fill="var(--branco-puro-5bits)"
        />
        <path
          d="M23.5026 11.2617L17.0001 17.4797L10.4975 11.2617L8.5 13.176L17.0001 21.3219L25.5001 13.176L23.5026 11.2617Z"
          fill="var(--azul-5bits)"
        />
      </svg>
    </ImageExpandMoreCircle>
  );
};

export default ImageExpandMoreCircleComponent;
