/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import React, { useCallback, useState, useEffect, ReactNode } from 'react';

import Button from '../Button';

import { TabsContainer, TabsLinks, TabContent } from './styles';

interface TabsProps {
  tabLinksProp: Array<string>;
  tabDefaultIndex: number;
}

const Tabs: React.FC<TabsProps> = ({
  tabLinksProp,
  tabDefaultIndex,
  children,
}) => {
  const [tabLinks, setTabLinks] = useState<any>([]);
  const [tabContent, setTabContent] = useState<any>([]);
  const [openTab, setOpenTab] = useState<HTMLElement>();

  const [childrenArray, setChildrenArray] = useState<Array<ReactNode>>();

  const convertChildrenToArray = useCallback(() => {
    setChildrenArray(React.Children.toArray(children));
  }, [children]);

  const removeAllActiveClass = useCallback(() => {
    if (tabLinks.length > 0) {
      tabLinks.forEach((tabLink: HTMLElement) => {
        tabLink.className = tabLink.className.replace(' active', '');
      });
    }
  }, [tabLinks]);

  const hideAllTabContent = useCallback(() => {
    if (tabContent.length > 0) {
      tabContent.forEach((section: HTMLElement) => {
        section.style.display = 'none';
      });
    }
  }, [tabContent]);

  const showCurrentTab = useCallback(
    idTabSelected => {
      removeAllActiveClass();
      hideAllTabContent();

      const tabSelected: HTMLElement | null = document.querySelector(
        `#${idTabSelected}`,
      );

      if (tabSelected !== null && tabSelected !== undefined)
        tabSelected.style.display = 'block';
    },
    [hideAllTabContent, removeAllActiveClass],
  );

  const selectTab = useCallback(
    e => {
      const target = e.currentTarget;

      showCurrentTab(target.dataset.id);
      e.currentTarget.style.display = 'block';

      target.className += ' active';
    },
    [showCurrentTab],
  );

  useEffect(() => {
    hideAllTabContent();
    openTab?.click();
  }, [hideAllTabContent, openTab]);

  useEffect(() => {
    if (children) {
      convertChildrenToArray();
      setTabLinks([...(document.querySelector('#tabLinks')?.children as any)]);

      setTabContent([
        ...(document.querySelector('#tabContent')?.children as any),
      ]);

      setOpenTab(document.querySelector('[data-default]') as HTMLElement);
    }
  }, [children, convertChildrenToArray]);

  return (
    <TabsContainer>
      <TabsLinks id="tabLinks">
        {tabLinksProp &&
          tabLinksProp.map(
            (tabLinkItem: string, index: number) =>
              (index === tabDefaultIndex && (
                <Button
                  data-default
                  key={String(index)}
                  data-id={`tab${index}`}
                  onClick={e => {
                    selectTab(e);
                  }}
                >
                  {tabLinkItem}
                </Button>
              )) || (
                <Button
                  key={String(index)}
                  data-id={`tab${index}`}
                  onClick={e => {
                    selectTab(e);
                  }}
                >
                  {tabLinkItem}
                </Button>
              ),
          )}
      </TabsLinks>

      <TabContent id="tabContent">
        {childrenArray &&
          childrenArray.map((childrenItem, index) => (
            <section key={String(index)} id={`tab${index}`}>
              {childrenItem}
            </section>
          ))}
        {/*
        <section id="tab2">{children}</section>
        <section id="tab3">Compras</section> */}
      </TabContent>
    </TabsContainer>
  );
};

export default Tabs;
