import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  header {
    display: flex;
    align-content: center;
    justify-content: space-between;
    margin-left: 10px;
    margin-top: 20px;
    margin-bottom: 40px;

    h1 {
      display: flex;
      font-weight: 500;
      font-size: 2.4rem;
      color: var(--azul-5bits);
      text-transform: none;
    }
  }

  > div {
    padding: 24px 45px 24px 0;

    > div {
      > label {
        margin-left: 25px;
        margin-bottom: 8px;
      }

      margin-bottom: 44px;
    }

    > button {
      margin: 0 auto;
    }
  }
`;

export const RowTwoColumns = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;

  .checkboxes {
    div {
      padding-left: 25px;
      & + div {
        margin-top: 20px;
      }
    }
  }

  > div {
    display: flex;
    flex-direction: column;

    &.btn-change-password {
      button {
        align-self: flex-end;
        display: flex;
        justify-content: center;
        align-content: center;
        margin: -8px 8px 15px 0;
        padding: 4px;
        cursor: pointer;
        background-color: white;
        border: 1px solid rgba(81, 81, 81, 0.5); //var(--cinza-escuro-5bits);
        border-radius: 4px;
        color: var(--cinza-escuro-5bits);

        &[id='password'] {
          border: none;

          &:hover {
            filter: brightness(1);
          }
        }
      }
    }

    & + div {
      width: 190px;
      min-height: 78px;
      margin-left: 10px;
      > p {
        text-align: right;
        margin: -8px 8px 15px 0;
      }
    }
  }

  input[id='password'] {
    width: 148px;
    &::placeholder {
      font-style: italic;
      color: rgba(81, 81, 81, 0.5);
    }
  }
`;
