import React, { useRef, useEffect } from 'react';
import { createPortal } from 'react-dom';

import { Container, Content, LoadingContainer } from './styles';

interface IModalLoadingProps {
  visible: boolean;
  color?: string;
  size?: number;
  stroke?: number;
  contentPadding?: number;
}

const ModalLoading: React.FC<IModalLoadingProps> = ({
  children,
  visible,
  color = '#444',
  size = 40,
  stroke = 2,
  contentPadding = 40,
}) => {
  return (
    <>
      {visible && (
        <Container>
          <Content padding={contentPadding}>
            <LoadingContainer color={color} size={size - 5}>
              <circle
                className="path"
                cx={(size - 5) / 2}
                cy={(size - 5) / 2}
                r={(size - 5) / 2 - stroke}
                fill="none"
                strokeWidth={stroke}
              />
            </LoadingContainer>
            <div>{children}</div>
          </Content>
        </Container>
      )}
    </>
  );
};

export default ModalLoading;
