/* eslint-disable react/jsx-curly-newline */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import React, { useEffect, useState, useCallback } from 'react';
import { FormHandles } from '@unform/core';

import SimpleSelect from '../../../../../../components/SimpleSelect';
import convertDateToBr from '../../../../../../utils/convertDateToBr';

import { IUserResponse } from '../../../../../../interfaces/user';

import {
  ContainerFeasibilityAnalysis,
  SplitLeftValidationSignatures,
  FieldsNameAndDate,
} from './styles';
import InputMask from '../../../../../../components/InputMask';

interface IFeasibilityAnalysisProps {
  formRef: any | FormHandles;
  listUsers: Omit<IUserResponse, 'usersHasMenus'>[];
  disableTabProp: boolean;
}

const FeasibilityAnalysis: React.FC<IFeasibilityAnalysisProps> = ({
  formRef,
  listUsers,
  disableTabProp = false,
}) => {
  const [disabled, setDisabled] = useState<boolean>(false);

  useEffect(() => {
    setDisabled(disableTabProp);
  }, [disableTabProp]);

  const handleInputChange = useCallback(
    (
      selectedUser: any,
      nameField: string,
      dateFieldLinkedToNameFiled: string,
    ) => {
      if (selectedUser && dateFieldLinkedToNameFiled !== '') {
        formRef.current?.setFieldValue(`${nameField}`, selectedUser.name);

        if (
          formRef.current?.getFieldValue(`${dateFieldLinkedToNameFiled}`) === ''
        ) {
          formRef.current?.setFieldValue(
            `${dateFieldLinkedToNameFiled}`,
            convertDateToBr(new Date()),
          );
        }
      } else if (selectedUser === null && dateFieldLinkedToNameFiled !== '') {
        formRef.current?.setFieldValue(`${dateFieldLinkedToNameFiled}`, '');
      }
    },
    [formRef],
  );

  return (
    <ContainerFeasibilityAnalysis>
      <span>Análise de Viabilidade</span>

      <SplitLeftValidationSignatures>
        <div>
          <span>Validação de dados técnicos</span>
          <FieldsNameAndDate>
            <SimpleSelect
              id="userIdTechnicalValidator"
              name="userIdTechnicalValidator"
              labelText="Nome"
              placeholder=""
              options={listUsers}
              onChange={option => {
                handleInputChange(
                  option,
                  'userIdTechnicalValidator',
                  'technicalValidatorDate',
                );
              }}
              isClearable
              isDisabled={disabled}
              disabled={disabled}
              maxMenuHeight={145}
            />

            <InputMask
              id="technicalValidatorDate"
              name="technicalValidatorDate"
              type="text"
              labelText="Data"
              mask="99/99/9999"
              autoComplete="off"
              disabled={disabled}
            />
          </FieldsNameAndDate>
        </div>

        <div>
          <span>Validação de planejamento operacional</span>
          <FieldsNameAndDate>
            <SimpleSelect
              id="userIdOperationalValidator"
              name="userIdOperationalValidator"
              labelText="Nome"
              placeholder=""
              options={listUsers}
              onChange={option => {
                handleInputChange(
                  option,
                  'userIdOperationalValidator',
                  'operationalValidatorDate',
                );
              }}
              isClearable
              isDisabled={disabled}
              disabled={disabled}
              maxMenuHeight={145}
            />

            <InputMask
              id="operationalValidatorDate"
              name="operationalValidatorDate"
              type="text"
              labelText="Data"
              mask="99/99/9999"
              autoComplete="off"
              disabled={disabled}
            />
          </FieldsNameAndDate>
        </div>

        <div>
          <span>Validação de planejamento do projeto</span>
          <FieldsNameAndDate>
            <SimpleSelect
              id="userIdProjectValidator"
              name="userIdProjectValidator"
              labelText="Nome"
              placeholder=""
              options={listUsers}
              onChange={option => {
                handleInputChange(
                  option,
                  'userIdProjectValidator',
                  'projectValidatorDate',
                );
              }}
              isClearable
              isDisabled={disabled}
              disabled={disabled}
              maxMenuHeight={145}
            />

            <InputMask
              id="projectValidatorDate"
              name="projectValidatorDate"
              type="text"
              labelText="Data"
              mask="99/99/9999"
              autoComplete="off"
              disabled={disabled}
            />
          </FieldsNameAndDate>
        </div>
      </SplitLeftValidationSignatures>
    </ContainerFeasibilityAnalysis>
  );
};

export default FeasibilityAnalysis;
