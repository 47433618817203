/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import React, { useEffect, useState, useCallback } from 'react';
import { FormHandles } from '@unform/core';
import fincalc from 'fincalc';

import Loading from '../../../../../components/Loading';
import InputCurrency from '../../../../../components/InputCurrency';
import InputHidden from '../../../../../components/InputHidden';

import {
  SplitResume,
  HeaderAndGrandTotal,
  SplitLeft,
  SplitRight,
} from './styles';

import { IProjectResponse } from '../../../../../interfaces/project';
import convertNumberFloatToDB from '../../../../../utils/convertNumberFloatToDB';
import convertNumberFloatToBr from '../../../../../utils/convertNumberFloatToBr';
import { IPermission } from '../../../../../interfaces/permission';

interface IHandleResumeProps {
  formRef: any | FormHandles;
  projectProp: any;
  disableTabProp: boolean;
  permissions: IPermission;
}

const HandleResume: React.FC<IHandleResumeProps> = ({
  formRef,
  projectProp,
  disableTabProp = false,
  permissions,
}) => {
  const [disabled, setDisabled] = useState<boolean>(false);

  // Preço de custo do projeto
  const [costPrice, setCostPrice] = useState(0);

  const sumCostPrice = useCallback(() => {
    if (Object.entries(projectProp).length > 0) {
      const currentMaterials = projectProp.projectHasMaterials;

      const currentServices = projectProp.projectHasServices;

      const totalMaterials = currentMaterials.reduce(
        (sum: number, currentItemMaterials: any) => {
          // eslint-disable-next-line no-return-assign
          return (sum += convertNumberFloatToDB(
            currentItemMaterials.totalPrice,
          ));
        },
        0,
      );

      const totalServices = currentServices.reduce(
        (sum: number, currentItemServices: any) => {
          // eslint-disable-next-line no-return-assign
          return (sum += convertNumberFloatToDB(
            currentItemServices.totalPrice,
          ));
        },
        0,
      );

      const costPriceCalculated = fincalc(totalMaterials + totalServices);

      setCostPrice(costPriceCalculated);
      formRef.current.setFieldValue(
        'costPrice',
        convertNumberFloatToBr(costPriceCalculated),
      );
    }
  }, [formRef, projectProp]);

  // Lucro bruto do projeto
  const [finalPrice, setFinalPrice] = useState(0);
  const [grossProfit, setGrossProfit] = useState(0);

  const sumGrossProfit = useCallback(() => {
    const currentFinalPrice = convertNumberFloatToDB(
      formRef.current.getFieldValue('finalPrice'),
    );

    setFinalPrice(currentFinalPrice);

    const grossProfitCalculated = fincalc(currentFinalPrice - costPrice);

    setGrossProfit(grossProfitCalculated);
    formRef.current.setFieldValue(
      'grossProfit',
      convertNumberFloatToBr(grossProfitCalculated),
    );
  }, [costPrice, formRef]);

  // Percentual de lucro do projeto
  const [percentageProfit, setPercentageProfit] = useState(0);

  const calculatePercentageProfit = useCallback(() => {
    const currentFinalPrice = convertNumberFloatToDB(
      formRef.current.getFieldValue('finalPrice'),
    );

    if (costPrice > 0) {
      const percentageProfitCalculated = fincalc.pervar(
        costPrice,
        currentFinalPrice,
      );
      // const percentageProfitCalculated = (grossProfit / costPrice) * 100;
      setPercentageProfit(percentageProfitCalculated);
      formRef.current.setFieldValue(
        'percentageProfit',
        convertNumberFloatToBr(percentageProfitCalculated),
      );
    } else if (currentFinalPrice > 0) {
      setPercentageProfit(100);
      formRef.current.setFieldValue(
        'percentageProfit',
        convertNumberFloatToBr(100),
      );
    } else {
      setPercentageProfit(0);
      formRef.current.setFieldValue(
        'percentageProfit',
        convertNumberFloatToBr(0),
      );
    }
  }, [costPrice, formRef]);

  useEffect(() => {
    calculatePercentageProfit();
  }, [calculatePercentageProfit, grossProfit]);

  useEffect(() => {
    setDisabled(disableTabProp);
    sumCostPrice();
  }, [disableTabProp, sumCostPrice]);

  useEffect(() => {
    setFinalPrice(convertNumberFloatToDB(projectProp.finalPrice));
    // const currentFinalPrice = convertNumberFloatToDB(
    //   formRef.current.getFieldValue('finalPrice'),
    // );

    setGrossProfit(convertNumberFloatToDB(projectProp.grossProfit));
    formRef.current.setFieldValue('grossProfit', projectProp.grossProfit);

    setPercentageProfit(convertNumberFloatToDB(projectProp.percentageProfit));
    formRef.current.setFieldValue(
      'percentageProfit',
      projectProp.percentageProfit,
    );
  }, [formRef, projectProp, sumGrossProfit]);

  return (
    <SplitResume>
      <HeaderAndGrandTotal>
        <span>Resumo</span>
      </HeaderAndGrandTotal>
      <section id="resumeArea">
        <SplitLeft>
          <InputCurrency
            id="finalPrice"
            name="finalPrice"
            onKeyUp={sumGrossProfit}
            type="text"
            labelText="Preço final cobrado pelo projeto:"
            autoComplete="off"
            disabled={!permissions.create || disabled}
          />
        </SplitLeft>
        <SplitRight>
          <div>
            Preço de custo do projeto:{' '}
            <span>R$ {convertNumberFloatToBr(costPrice)}</span>
            <InputHidden name="costPrice" />
          </div>
          <div>
            Lucro bruto do projeto:{' '}
            <span
              style={{
                color: `${grossProfit < 0 ? 'var(--cinza-escuro-5bits)' : ''}`,
              }}
            >
              R$ {convertNumberFloatToBr(grossProfit)}
            </span>
            <InputHidden name="grossProfit" />
          </div>
          <div>
            Percentual de lucro do projeto:{' '}
            <span
              style={{
                color: `${
                  percentageProfit < 0 ? 'var(--cinza-escuro-5bits)' : ''
                }`,
              }}
            >
              {convertNumberFloatToBr(percentageProfit)}%
            </span>
            <InputHidden name="percentageProfit" />
          </div>
          <div>
            Preço final cobrado pelo projeto:{' '}
            <span>R$ {convertNumberFloatToBr(finalPrice)}</span>
          </div>
        </SplitRight>
      </section>
    </SplitResume>
  );
};

export default HandleResume;
