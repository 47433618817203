/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormHandles, Scope } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { isValid, parse } from 'date-fns';

import { FiTrash2 } from 'react-icons/fi';

import api from '../../../../services/api';
import getValidationErrors from '../../../../utils/getValidationError';
import convertDateToBr from '../../../../utils/convertDateToBr';
import { useToast } from '../../../../context/ToastContext';

import Loading from '../../../../components/Loading';
import Input from '../../../../components/Input';
import InputMask from '../../../../components/InputMask';
import TextArea from '../../../../components/TextArea';
import Button from '../../../../components/Button';
import SimpleSelect from '../../../../components/SimpleSelect';
import InputHidden from '../../../../components/InputHidden';
import InputFileButton from '../../../../components/InputFileButtonWithButtonTrashAndSearchForArray';
import DatePicker from '../../../../components/DatePicker';

import currentSectorList from '../../../../data/currentSectorList.json';

import ModalFindDrawing from './Modal/modalFindDrawing';

import {
  Container,
  Content,
  HeadOpenProject,
  TechnicalDrawings,
  SectionTitle,
  SplitTechnicalDrawings,
  SplitDrawing,
  RevisionNumberAndRevisionDateAndFile,
  SelectSectorAndButtonSave,
  SplitFooter,
  SplitFooterContent,
} from './styles';

import { IClient, IClientResponse } from '../../../../interfaces/client';
import { IDrawingsResponse } from '../../../../interfaces/drawings';
import { ICommercial } from '../../../../interfaces/commercial';
import { IChecklist } from '../../../../interfaces/checklist';

import convertDateToDB from '../../../../utils/convertDateToDB';

import HeadProject from '../../HeadProject';
import ChecklistProject from '../../componentsSectors/ChecklistProject';
import ModalChecklist from '../../componentsSectors/ModalChecklist/modalChecklist';

interface IDrawingsResponseForm extends Omit<IDrawingsResponse, 'id'> {
  id: string;
  files: any;
}

interface ICommercialForm extends Omit<ICommercial, 'drawings' | 'id'> {
  id: string;
  revisionDate: string;
  scopeDrawings: Array<IDrawingsResponseForm>;
}

const CreateProject: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const navigate = useNavigate();
  const { addToast } = useToast();

  const [listDrawingsTheProject, setListDrawingsTheProject] = useState<
    ICommercialForm[]
  >([{} as ICommercialForm]);
  const [listDrawingsSelect, setListDrawingsSelect] = useState<any[]>([]);

  const [percentCompleted, setPercentCompleted] = useState(0);
  const [loadingFile, setLoadingFile] = useState<boolean>(false);
  const [buttonClicked, setButtonClicked] = useState<string>('');
  const [saving, setSaving] = useState<boolean>(false);

  const [listClients, setListClients] = useState<IClient[]>([]);
  const [listChecklist, setListChecklist] = useState<IChecklist[]>([]);
  const [loadingChecklist, setLoadingChecklist] = useState<boolean>(false);

  useEffect(() => {
    setLoadingChecklist(true);
    const getChecklist = async (): Promise<void> => {
      try {
        // Clientes
        const responseClients = await api.get<IClientResponse[]>('clients');

        const responseClientsFormatted: any = responseClients.data.map(i => ({
          value: i.id,
          label: i.name,
        }));

        setListClients(responseClientsFormatted);

        // Checklist
        const responseChecklist = await api.get<any[]>('checklist');

        setListChecklist(responseChecklist.data);
        // End checklist
        setLoadingChecklist(false);
      } catch (error: any) {
        setLoadingChecklist(false);

        if (error.response && error.response.data) {
          const { data } = error.response; // Error vindo do back está em data dentro de response
          addToast({
            type: 'error',
            title: data.message,
          });
          return;
        }

        addToast({
          type: 'error',
          title: 'Error',
          description: 'Erro ao listar!',
        });
      }
    };
    getChecklist();
  }, [addToast]);

  const [openModalChecklist, setOpenModalChecklist] = useState(false);
  const handleOpenModalChecklist = useCallback(() => {
    setOpenModalChecklist(!openModalChecklist);
    setSaving(false);
  }, [openModalChecklist]);

  const verifyPermissions = useCallback(async () => {
    try {
      const dataForm = formRef.current?.getData();

      setSaving(true);
      formRef.current?.setErrors({});

      formRef.current?.setErrors({});

      if (dataForm) {
        /* Pode cadastrar um projeto sem desenho, mas se pelo menos um de drawings estiver
         * preenchido todos os outros campos de drawing deveram ser preenchidos.
         * Filtra os items que tenham pelo menos um campo preenchido, para depois validar no Yup.
         */
        const newScopeDrawings = dataForm.scopeDrawings.filter((item: any) => {
          const valuesItem = Object.values(item);
          return valuesItem.some(elem => elem !== '' && elem !== undefined);
        });

        const newDataForm = {
          ...dataForm,
          scopeDrawings: newScopeDrawings,
        };

        const schema = Yup.object().shape({
          clientId: Yup.string().required('O cliente é obrigatório'),
          nameResponsible: Yup.string().required(
            'O responsável pelo projeto é obrigatório',
          ),
          title: Yup.string().required('O título do projeto é obrigatório'),
          description: Yup.string().required(
            'A descrição do projeto é obrigatória',
          ),
          quantity: Yup.string().required('A quantidade é obrigatória'),

          // Só valida se o array tiver pelo menos um item

          // Campos de desenho desabilitados por força de contrato. Se for necessário habilitá-los
          // basta remover estes comentários. No backend nada foi
          // alterado, portanto funcionando perfeitamente.
          //
          // scopeDrawings:
          //   newScopeDrawings.length > 0
          //     ? Yup.array().of(
          //         Yup.object().shape({
          //           drawingName: Yup.string().required('Nome é obrigatório'),
          //           drawingNumber: Yup.string().required(
          //             'Número é obrigatório',
          //           ),
          //           revisionNumber: Yup.string().required(
          //             'Revisão obrigatório',
          //           ),
          //           revisionDate: Yup.string()
          //             .test('revisionDate', 'Data inválida', (revDate: any) => {
          //               const validateDate = parse(
          //                 revDate,
          //                 'dd/MM/yyyy',
          //                 new Date(),
          //               );
          //               return isValid(validateDate);
          //             })
          //             .required('Data obrigatória'),

          //           files: Yup.mixed().when('id', {
          //             is: '',
          //             then: Yup.mixed().required('Arquivo é obrigatório'),
          //           }),
          //         }),
          //       )
          //     : Yup.array().of(Yup.object().shape({})),
          currentSector: Yup.string().required(
            'O setor que receberá o projeto é obrigatório',
          ),
        });

        await schema.validate(newDataForm, { abortEarly: false });

        handleOpenModalChecklist();
      }
    } catch (error: any) {
      setSaving(false);

      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formRef.current?.setErrors(errors);

        return;
      }

      addToast({
        type: 'error',
        title: 'Error',
        description: `${error}`,
      });
    }
  }, [addToast, handleOpenModalChecklist]);

  const handleFormSubmit = useCallback(
    async (dataForm: any, { reset }) => {
      try {
        setSaving(true);

        formRef.current?.setErrors({});

        // Campos de desenho desabilitados por força de contrato. Se for necessário habilitá-los
        // basta remover estes comentários. No backend nada foi
        // alterado, portanto funcionando perfeitamente.

        /* Pode cadastrar um projeto sem desenho, mas se pelo menos um de drawings estiver
         * preenchido todos os outros campos de drawing deveram ser preenchidos.
         * Filtra os items que tenham pelo menos um campo preenchido, para depois validar no Yup.
        const newScopeDrawings = dataForm.scopeDrawings.filter((item: any) => {
          const valuesItem = Object.values(item);
          return valuesItem.some(elem => elem !== '' && elem !== undefined);
        });
        */

        /*
         *projectHasChecklist chega como array de objetos [{check1: true},{check14: false}]
         *Abaixo filtra apenas os true, depois no map retira a string 'check' e converte para
         * inteiro o número que é o id do item checklist no banco
         */
        // projectHasChecklist
        const onlyItemsWithTrueChecklist = dataForm.projectHasChecklist.filter(
          // eslint-disable-next-line consistent-return
          (itemProjectHasChecklist: any) => {
            if (Object.values(itemProjectHasChecklist)[0]) {
              const idOfCheckInString = Object.keys(
                itemProjectHasChecklist,
              )[0].replace('check', '');
              return parseInt(idOfCheckInString, 10);
            }
          },
        );

        const itemsConvertedInIntChecklist = onlyItemsWithTrueChecklist.map(
          (itemProjectHasChecklist: any) => {
            const idOfCheckInString = Object.keys(
              itemProjectHasChecklist,
            )[0].replace('check', '');
            return parseInt(idOfCheckInString, 10);
          },
        );

        const newDataForm = {
          ...dataForm,
          /* Campos de desenho desabilitados por força de contrato
          scopeDrawings: newScopeDrawings,
          */
          projectHasChecklist: [...itemsConvertedInIntChecklist],
        };

        const config = {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress(progressEvent: any) {
            // Indica a porcentagem já enviada
            setPercentCompleted(
              Math.round((progressEvent.loaded * 100) / progressEvent.total),
            );
          },
        };

        const formData = new FormData();

        formData.append('clientId', `${newDataForm.clientId}`);
        formData.append('nameResponsible', newDataForm.nameResponsible);
        formData.append('title', newDataForm.title);
        formData.append('description', newDataForm.description);
        formData.append('quantity', `${newDataForm.quantity}`);

        /* Campos de desenho desabilitados por força de contrato

        if (newDataForm.scopeDrawings.length > 0) {
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < newDataForm.scopeDrawings.length; i++) {
            formData.append(
              'idDrawing[]',
              `${newDataForm.scopeDrawings[i].id}`,
            );
            formData.append(
              'drawingName[]',
              newDataForm.scopeDrawings[i].drawingName,
            );
            formData.append(
              'drawingNumber[]',
              newDataForm.scopeDrawings[i].drawingNumber,
            );
            formData.append(
              'revisionNumber[]',
              newDataForm.scopeDrawings[i].revisionNumber,
            );
            formData.append(
              'revisionDate[]',
              convertDateToDB(newDataForm.scopeDrawings[i].revisionDate),
            );

            formData.append('files', newDataForm.scopeDrawings[i].files);
          }
        }
        */

        if (newDataForm.projectHasChecklist.length > 0) {
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < newDataForm.projectHasChecklist.length; i++) {
            formData.append(
              'projectHasChecklist[]',
              `${newDataForm.projectHasChecklist[i]}`,
            );
          }
        } else {
          formData.append('projectHasChecklist[]', ``);
        }

        formData.append('requirementProduct', newDataForm.requirementProduct);
        formData.append('requirementClient', newDataForm.requirementClient);
        formData.append('requirementLegal', newDataForm.requirementLegal);
        formData.append(
          'forecastEndDate',
          convertDateToDB(newDataForm.forecastEndDate),
        );
        formData.append('observation', newDataForm.observation);
        formData.append('currentSector', newDataForm.currentSector);

        await api.post('commercial', formData, config);

        addToast({
          type: 'success',
          title: 'Cadastro realizado com sucesso',
        });

        reset();
        setPercentCompleted(0);
        setSaving(false);

        navigate('/commercial');
        handleOpenModalChecklist();
      } catch (error: any) {
        handleOpenModalChecklist();
        setPercentCompleted(0);
        setSaving(false);

        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);

          return;
        }

        if (error.response) {
          const { data } = error.response; // Error vindo do back está em data dentro de response

          addToast({
            type: 'error',
            title: 'Erro no cadastro',
            description: data.message,
          });
          // if (
          //   formRef.current?.getFieldRef(data.field) &&
          //   data.field.toString().indexOf('_id') === -1
          // ) {
          //   formRef.current?.getFieldRef(data.field).focus(); // Foca o campo que deu erro de acordo com o field retornado do back
          // }

          return;
        }

        addToast({
          type: 'error',
          title: 'Error',
          description: 'Erro ao cadastrar!',
        });
      }
    },
    [addToast, handleOpenModalChecklist, navigate],
  );

  const handleClearErrorDrawingDeleted = useCallback((index: number) => {
    formRef.current?.setFieldError(`scopeDrawings[${index}].drawingName`, '');
    formRef.current?.setFieldError(`scopeDrawings[${index}].drawingNumber`, '');
    formRef.current?.setFieldError(
      `scopeDrawings[${index}].revisionNumber`,
      '',
    );
    formRef.current?.setFieldError(`scopeDrawings[${index}].revisionDate`, '');
  }, []);

  const handleInputChangeDrawing = useCallback(
    // eslint-disable-next-line consistent-return
    (inputValue: any, index: number) => {
      handleClearErrorDrawingDeleted(index);
      if (formRef.current) {
        if (inputValue && inputValue.id) {
          formRef.current.setFieldValue(
            `scopeDrawings[${index}].id`,
            inputValue.id,
          );
          formRef.current.setFieldValue(
            `scopeDrawings[${index}].fileName`,
            inputValue.fileName,
          );
          formRef.current.setFieldValue(
            `scopeDrawings[${index}].drawingName`,
            inputValue.drawingName,
          );
          formRef.current.setFieldValue(
            `scopeDrawings[${index}].drawingNumber`,
            inputValue.drawingNumber,
          );
          formRef.current.setFieldValue(
            `scopeDrawings[${index}].revisionNumber`,
            inputValue.revisionNumber,
          );
          formRef.current.setFieldValue(
            `scopeDrawings[${index}].revisionDate`,
            convertDateToBr(inputValue.revisionDate),
          );

          setListDrawingsTheProject(formRef.current?.getData().scopeDrawings);

          // setDrawingFileSelected(inputValue.fileName);
        } else {
          formRef.current.setFieldValue(`scopeDrawings[${index}].id`, '');

          formRef.current.setFieldValue(
            `scopeDrawings[${index}].revisionNumber`,
            '',
          );
          formRef.current.setFieldValue(
            `scopeDrawings[${index}].revisionDate`,
            '',
          );

          setListDrawingsTheProject(formRef.current?.getData().scopeDrawings);
        }
      }
    },
    [handleClearErrorDrawingDeleted],
  );

  const handleOpenFile = useCallback(
    (index: number, nameButtonClicked: string) => {
      setLoadingFile(true);
      setButtonClicked(nameButtonClicked);
      if (
        formRef.current &&
        formRef.current.getFieldValue(`scopeDrawings[${index}].fileName`)
      ) {
        const fileName = formRef.current.getFieldValue(
          `scopeDrawings[${index}].fileName`,
        );

        const extensionFile = fileName.substring(fileName.lastIndexOf('.') + 1);

        let typeBlob = '';

        switch (extensionFile) {
          case 'pdf':
            typeBlob = `application/${extensionFile}`;
            break;
          case 'tiff':
            typeBlob = `image/${extensionFile}`;
            break;
          default:
            typeBlob = `image/${extensionFile}`;
        }
        api
          .get(`drawings/file/${fileName}`, {
            responseType: 'arraybuffer',
          })
          .then((response: any) => {
            const url = window.URL.createObjectURL(
              new Blob([response.data], { type: typeBlob }),
            );

            window.open(url);
            setLoadingFile(false);
            setButtonClicked('');
          })
          .catch(error => {
            setLoadingFile(false);
            setButtonClicked('');

            if (error.response.data) {
              const { data } = error.response; // Error vindo do back está em data dentro de response
              addToast({
                type: 'error',
                title: data.message,
              });
              return;
            }

            addToast({
              type: 'error',
              title: 'Erro ao Exibir!',
              description: `Error: ${error}`,
            });
          });
      }
    },

    [addToast],
  );

  const addNewDrawing = useCallback(() => {
    const formAll = formRef.current?.getData();
    const newListDrawingsTheProject = [...formAll?.scopeDrawings];
    setListDrawingsTheProject(() => [
      ...newListDrawingsTheProject,
      {} as ICommercialForm,
    ]);
  }, []);

  useEffect(() => {
    // Seta os campos a cada mudança na lista de drawings
    // eslint-disable-next-line array-callback-return
    listDrawingsTheProject.map((item: any, i) => {
      formRef.current?.setFieldValue(`scopeDrawings[${i}].id`, item.id);
      formRef.current?.setFieldValue(
        `scopeDrawings[${i}].fileName`,
        item.fileName,
      );
      formRef.current?.setFieldValue(
        `scopeDrawings[${i}].drawingName`,
        item.drawingName,
      );
      formRef.current?.setFieldValue(
        `scopeDrawings[${i}].drawingNumber`,
        item.drawingNumber,
      );
      formRef.current?.setFieldValue(
        `scopeDrawings[${i}].revisionNumber`,
        item.revisionNumber,
      );
      formRef.current?.setFieldValue(
        `scopeDrawings[${i}].revisionDate`,
        item.revisionDate,
      );
      if (item.files && item.files !== undefined) {
        formRef.current?.setFieldValue(`scopeDrawings[${i}].files`, item.files);
      } else {
        formRef.current?.clearField(`scopeDrawings[${i}].files`);
      }
    });
  }, [listDrawingsTheProject]);

  const handleRemoveDrawing = useCallback(
    (index: number) => {
      handleClearErrorDrawingDeleted(index);

      if (formRef.current && listDrawingsTheProject.length > 1) {
        const formAll = formRef.current.getData();
        const newListDrawingsTheProject = [...formAll.scopeDrawings];

        newListDrawingsTheProject.splice(index, 1);

        setListDrawingsTheProject([...newListDrawingsTheProject]);
      } else {
        formRef.current?.clearField(`scopeDrawings[0].id`);
        formRef.current?.clearField(`scopeDrawings[0].fileName`);
        formRef.current?.clearField(`scopeDrawings[0].drawingName`);
        formRef.current?.clearField(`scopeDrawings[0].drawingNumber`);
        formRef.current?.clearField(`scopeDrawings[0].revisionNumber`);
        formRef.current?.clearField(`scopeDrawings[0].revisionDate`);
        formRef.current?.clearField(`scopeDrawings[0].files`);

        setListDrawingsTheProject([{} as ICommercialForm]);
      }
    },
    [handleClearErrorDrawingDeleted, listDrawingsTheProject.length],
  );

  const [openModalFindDrawing, setOpenModalFindDrawing] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const handleOpenModalFindDrawing = useCallback(
    (selectedIndexProp: number) => {
      setOpenModalFindDrawing(!openModalFindDrawing);
      setSelectedIndex(selectedIndexProp);
    },
    [openModalFindDrawing],
  );

  const [divClicked, setDivClicked] = useState('HeadOpenProject');

  const handleDivClicked = useCallback(idDivClicked => {
    setDivClicked(oldValue => {
      return idDivClicked === oldValue ? '' : idDivClicked;
    });
  }, []);

  return (
    <Container>
      <Content>
        <Form ref={formRef} onSubmit={handleFormSubmit}>
          <HeadOpenProject
            id="title_open_project"
            showInformation={divClicked === 'HeadOpenProject'}
          >
            <SectionTitle onClick={() => handleDivClicked('HeadOpenProject')}>
              <a href="#title_open_project">Abertura de Projeto</a>
            </SectionTitle>

            <HeadProject
              showInformationInMobile={divClicked === 'HeadOpenProject'}
              listClients={listClients}
            />
          </HeadOpenProject>

          <TechnicalDrawings
            id="title_technical_drawings"
            showInformation={divClicked === 'TechnicalDrawings'}
          >
            <SectionTitle onClick={() => handleDivClicked('TechnicalDrawings')}>
              <a href="#title_technical_drawings">Desenho Técnico</a>
            </SectionTitle>
            <SplitTechnicalDrawings
              showInformation={divClicked === 'TechnicalDrawings'}
            >
              {listDrawingsSelect && (
                <>
                  {listDrawingsTheProject.map((item, index) => (
                    <Scope path={`scopeDrawings[${index}]`} key={String(index)}>
                      {/*
                      Campos de desenho desabilitados por força de contrato. Se for necessário habilitá-los
                      basta remover este comentários e apagar os campos falsos logo abaixo. No backend nada foi
                      alterado, portanto funcionando perfeitamente.

                      <SplitDrawing>
                        <InputHidden name="id" />
                        <InputHidden name="fileName" />
                        <Input
                          id="drawingName"
                          name="drawingName"
                          labelText={index === 0 ? 'Nome*' : ''}
                          placeholder=""
                          maxLength={45}
                          autoComplete="off"
                          readOnly={!!(item.id && item.id !== '')}
                        />

                        <Input
                          id="drawingNumber"
                          name="drawingNumber"
                          labelText={index === 0 ? 'Número*' : ''}
                          placeholder=""
                          autoComplete="off"
                          maxLength={45}
                          readOnly={!!(item.id && item.id !== '')}
                        />

                        <RevisionNumberAndRevisionDateAndFile>
                          <Input
                            id="revisionNumber"
                            name="revisionNumber"
                            type="text"
                            labelText={index === 0 ? 'Rev.*' : ''}
                            autoComplete="off"
                            readOnly={!!(item.id && item.id !== '')}
                          />
                          <InputMask
                            id="revisionDate"
                            name="revisionDate"
                            labelText={index === 0 ? 'Data da Rev.*' : ''}
                            mask="99/99/9999"
                            readOnly={!!(item.id && item.id !== '')}
                          />

                          {(item.id && item.id !== '' && (
                            <div>
                              {(loadingFile &&
                                buttonClicked === `openFile${index}` && (
                                  <Button
                                    name="buttonSpinner"
                                    type="button"
                                    onClick={e => {
                                      e.preventDefault();
                                    }}
                                  >
                                    <Loading size={24} color="white" />
                                  </Button>
                                )) || (
                                <>
                                  <Button
                                    name={`openFile${index}`}
                                    onClick={() =>
                                      handleOpenFile(index, `openFile${index}`)
                                    }
                                  >
                                    Exibir
                                  </Button>
                                  <div>
                                    <FiTrash2
                                      size={20}
                                      onClick={() => handleRemoveDrawing(index)}
                                    />
                                  </div>
                                </>
                              )}
                            </div>
                          )) || (
                            <>
                              <InputFileButton
                                id={`files${index}`}
                                name="files"
                                indexProp={index}
                                handleOpenModalFindDrawing={
                                  handleOpenModalFindDrawing
                                }
                                handleRemoveDrawing={handleRemoveDrawing}
                              />
                            </>
                          )}
                        </RevisionNumberAndRevisionDateAndFile>
                      </SplitDrawing> */}
                      <SplitDrawing>
                        <InputHidden name="id" />
                        <InputHidden name="fileName" />
                        <Input
                          id="drawingName"
                          name="drawingName"
                          labelText={index === 0 ? 'Nome*' : ''}
                          placeholder=""
                          maxLength={45}
                          autoComplete="off"
                          readOnly={!!(item.id && item.id !== '')}
                          disabled
                        />

                        <Input
                          id="drawingNumber"
                          name="drawingNumber"
                          labelText={index === 0 ? 'Número*' : ''}
                          placeholder=""
                          autoComplete="off"
                          maxLength={45}
                          readOnly={!!(item.id && item.id !== '')}
                          disabled
                        />

                        <RevisionNumberAndRevisionDateAndFile>
                          <Input
                            id="revisionNumber"
                            name="revisionNumber"
                            type="text"
                            labelText={index === 0 ? 'Rev.*' : ''}
                            autoComplete="off"
                            readOnly={!!(item.id && item.id !== '')}
                            disabled
                          />
                          <InputMask
                            id="revisionDate"
                            name="revisionDate"
                            labelText={index === 0 ? 'Data da Rev.*' : ''}
                            mask="99/99/9999"
                            readOnly={!!(item.id && item.id !== '')}
                            disabled
                          />

                          <Button
                            style={{
                              width: '11.7rem',
                              fontStyle: 'normal',
                              fontWeight: 'bold',
                              fontSize: '1.4rem',
                              background: 'var(--azul-5bits)',
                            }}
                            disabled
                          >
                            Carregar...
                          </Button>
                        </RevisionNumberAndRevisionDateAndFile>
                      </SplitDrawing>
                    </Scope>
                  ))}
                </>
              )}
              {/*
                      Botão de desenho desabilitado por força de contrato. Se for necessário habilitá-los
                      basta remover este comentários e apagar o botão falso logo abaixo. No backend nada foi
                      alterado, portanto funcionando perfeitamente.
              <Button
                name="newDrawing"
                type="button"
                onClick={e => {
                  e.preventDefault();
                  addNewDrawing();
                }}
              >
                Mais desenho
              </Button>
              */}
              <Button name="newDrawing" type="button" disabled>
                Mais desenho
              </Button>
            </SplitTechnicalDrawings>
          </TechnicalDrawings>

          <ChecklistProject
            listChecklistProp={listChecklist}
            loadingChecklistProp={loadingChecklist}
            projectHasChecklist={[]}
            // projectHasChecklist={project.projectHasChecklist}
          />

          <SplitFooter
            id="title_split_footer"
            showInformation={divClicked === 'SplitFooter'}
          >
            <SectionTitle onClick={() => handleDivClicked('SplitFooter')}>
              <a href="#title_split_footer">Informações Adicionais</a>
            </SectionTitle>

            <SplitFooterContent showInformation={divClicked === 'SplitFooter'}>
              <TextArea
                id="requirementProduct"
                name="requirementProduct"
                label="Requerimento dos produtos"
                disabled={saving}
              />
              <TextArea
                id="requirementClient"
                name="requirementClient"
                label="Requerimento do cliente"
                disabled={saving}
              />
              <TextArea
                id="requirementLegal"
                name="requirementLegal"
                label="Requerimentos legais"
                disabled={saving}
              />
              <TextArea
                id="observation"
                name="observation"
                label="Observações gerais"
                disabled={saving}
              />
            </SplitFooterContent>
          </SplitFooter>

          <SelectSectorAndButtonSave>
            <SimpleSelect
              id="currentSector"
              name="currentSector"
              labelText="Enviar projeto para o setor*:"
              placeholder=""
              options={currentSectorList.filter(
                item => item.value !== 'Produção',
              )}
              getOptionLabel={option => option.value}
              menuPlacement="auto"
              disabled={saving}
            />

            <Button type="button" onClick={verifyPermissions} disabled={saving}>
              {saving ? <Loading size={24} color="white" /> : 'Salvar'}
            </Button>
          </SelectSectorAndButtonSave>

          <ModalChecklist
            saving={saving}
            loadModalProp={openModalChecklist}
            listChecklistProp={listChecklist}
            projectHasChecklist={[]}
            // projectHasChecklist={project.projectHasChecklist}
            closeModalChecklist={handleOpenModalChecklist}
          />
        </Form>

        <ModalFindDrawing
          indexProp={selectedIndex}
          loadModalProp={openModalFindDrawing}
          handleInputChangeDrawingModal={handleInputChangeDrawing}
          closeAndClearModalFindDrawing={handleOpenModalFindDrawing}
        />
      </Content>
    </Container>
  );
};

export default CreateProject;
