import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;

  margin-top: 4.4rem;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 92%;

  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    header {
      width: 100%;
      font-weight: 500;
      font-size: 2.4rem;
      line-height: 3.2rem;

      margin-bottom: 3.6rem;

      > span {
        font-style: normal;
        font-size: 1.8rem;
        line-height: 2.4rem;

        color: var(--cinza-claro-5bits);
      }
    }
  }
`;

export const ContentSendEmail = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-top: 4.8rem;

  > div {
    width: 50%;
    margin-bottom: 3rem;

    > label {
      margin-left: 1.8rem;
    }
  }

  > div:last-child {
    width: 100%;
    margin-bottom: 0;
  }
`;

export const SplitFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
  padding-top: 5.6rem;
  padding-bottom: 3.125rem;

  //Button Enviar
  > div {
    width: 21.2rem;
  }
`;
