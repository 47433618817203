import styled from 'styled-components';

export const Container = styled.div`
  height: 65vh;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 90%;
  margin: 19px 46px 0;

  header {
    font-weight: 500;
    font-size: 2.4rem;

    margin-left: 1rem;
    margin-bottom: 1.6875rem;
  }

  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 90%;

    > div {
      width: 50%;

      > div {
        label {
          margin-left: 1.6rem;
        }
      }
    }

    > button {
      margin-top: 7.1rem;
    }
  }

  //Medias
  @media (min-width: 0px) and (max-width: 759px) {
    margin: 0;
    padding: 1.2rem;

    form {
      > div {
        width: 100%;
      }
    }
  }

  @media (min-width: 760px) and (max-width: 999px) {
  }
`;
