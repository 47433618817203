/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, memo } from 'react';
import { FormHandles, Scope } from '@unform/core';
import fincalc from 'fincalc';
import typy from 'typy';

import { FiTrash2 } from 'react-icons/fi';
import Tooltip from '../../../../../../../../components/Tooltip';

import SimpleSelect from '../../../../../../../../components/SimpleSelect';
import InputHidden from '../../../../../../../../components/InputHidden';
import InputCurrencyThreeDigits from '../../../../../../../../components/InputCurrencyThreeDigits';
import Input from '../../../../../../../../components/Input';
import InputCurrency from '../../../../../../../../components/InputCurrency';

import convertDateToBr from '../../../../../../../../utils/convertDateToBr';
import convertNumberFloatToBr from '../../../../../../../../utils/convertNumberFloatToBr';
import convertNumberFloatToDB from '../../../../../../../../utils/convertNumberFloatToDB';

import {
  SplitMaterialItem,
  QuantityPriceSubtotalMaterial,
  FiledTwoInputs,
  ObservationAndTrashIcon,
} from './styles';

type Props = {
  formRef: any | FormHandles;
  listMaterials: any[];
  listMaterialPlanningPagination: any[];
  disabled: boolean;
  sumGrandTotal: () => void;
  showModalConfirmationOfExclusionMaterial: (
    indexMaterial: number,
    show: boolean,
  ) => void;
  removeMaterial: (index: number) => void;
};

function ScopeMaterialPlanning({
  formRef,
  listMaterials,
  listMaterialPlanningPagination,
  disabled,
  sumGrandTotal,
  showModalConfirmationOfExclusionMaterial,
  removeMaterial,
}: Props): any {
  const calculateMaterial = useCallback(
    async (indexInScope: number) => {
      const quantityFloat = convertNumberFloatToDB(
        formRef.current.getFieldValue(
          `projectHasMaterials[${indexInScope}].quantity`,
        ),
      );

      const unitPriceFloat = convertNumberFloatToDB(
        formRef.current.getFieldValue(
          `projectHasMaterials[${indexInScope}].unitPrice`,
        ),
      );

      const totalPrice = fincalc(quantityFloat * unitPriceFloat);

      formRef.current.setFieldValue(
        `projectHasMaterials[${indexInScope}].totalPrice`,
        convertNumberFloatToBr(totalPrice),
      );
    },
    [formRef],
  );

  const calculateMaterialPrice = useCallback(
    async (indexInScope: number) => {
      await calculateMaterial(indexInScope);
      sumGrandTotal();
    },
    [calculateMaterial, sumGrandTotal],
  );

  const handleSumPrice = useCallback(
    (e, indexInScope: number) => {
      if (e.key !== 'Tab') {
        calculateMaterialPrice(indexInScope);
      }
    },
    [calculateMaterialPrice],
  );

  const handleInputChange = useCallback(
    (inputValue: any, index, providerId: any) => {
      const inputRefQuantity = formRef.current?.getFieldRef(
        `projectHasMaterials[${index}].quantity`,
      );
      const inputRefUnitOfMeasurement = formRef.current?.getFieldRef(
        `projectHasMaterials[${index}].unitOfMeasurement`,
      );
      if (inputValue) {
        /* Volta a cor normal do componente. Para isso tem qua mudar o
      style filter duas divs acima, ou seja, no avô do input */
        if (!typy(parseInt(providerId, 10)).isNumber && !disabled) {
          inputRefQuantity.style.color = 'var(--cinza-escuro-5bits)';
          inputRefQuantity.parentNode.style.filter = 'none';
          inputRefQuantity.readOnly = false;
          inputRefQuantity.disabled = false;

          inputRefUnitOfMeasurement.style.color = 'var(--cinza-escuro-5bits)';
          inputRefUnitOfMeasurement.parentNode.style.filter = 'none';
        } else {
          inputRefQuantity.style.color = 'var(--cinza-claro-5bits)';
          inputRefQuantity.parentNode.style.filter = 'brightness(0.9)';
          inputRefUnitOfMeasurement.style.color = 'var(--cinza-claro-5bits)';
          inputRefUnitOfMeasurement.parentNode.style.filter = 'brightness(0.9)';
        }

        formRef.current?.setFieldValue(
          `projectHasMaterials[${index}].unitOfMeasurement`,
          inputValue.unit.abbreviation,
        );

        formRef.current?.setFieldValue(
          `projectHasMaterials[${index}].unitPrice`,
          convertNumberFloatToBr(inputValue.price),
        );

        if (!typy(parseInt(providerId, 10)).isNumber) {
          calculateMaterialPrice(index);
        }
      } else {
        inputRefQuantity.style.color = 'var(--cinza-claro-5bits)';
        inputRefQuantity.parentNode.style.filter = 'brightness(0.9)';
        inputRefUnitOfMeasurement.style.color = 'var(--cinza-claro-5bits)';
        inputRefUnitOfMeasurement.parentNode.style.filter = 'brightness(0.9)';
      }
    },
    [calculateMaterialPrice, formRef, disabled],
  );

  // findLastQuoteProvider
  const findLastQuoteProviderAndReturnInputUnitPrice = useCallback(
    (material, index): any => {
      const materialOfListActivesWithLastQuoteProvider = listMaterials.find(
        materialItemOfListActives =>
          materialItemOfListActives.id === material.materialId,
      );

      if (
        materialOfListActivesWithLastQuoteProvider !== undefined &&
        materialOfListActivesWithLastQuoteProvider.providers.length > 0
      ) {
        const lastQuoteProvider = materialOfListActivesWithLastQuoteProvider.providers.find(
          (provider: any) =>
            provider.id ===
            materialOfListActivesWithLastQuoteProvider.lastQuoteProviderId,
        );

        if (lastQuoteProvider !== undefined) {
          const lastQuoteProviderName = lastQuoteProvider.fantasyName;

          // return `Última Cotação \n Forn.: ${lastQuoteProviderName} \n Date: ${convertDateToBr(
          //   materialOfListActivesWithLastQuoteProvider.dateLastQuote,
          // )}`;
          const textTooltip = `Última Cotação \n Forn.: ${lastQuoteProviderName} \n Date: ${convertDateToBr(
            materialOfListActivesWithLastQuoteProvider.dateLastQuote,
          )}`;

          return (
            <Tooltip
              style={{
                textTransform: 'capitalize',
                whiteSpace: 'pre',
                width: 'fit-content',
                left: '50%',
                bottom: `${
                  index === 0 ? 'calc(100% + -13px)' : 'calc(100% + 8px)'
                }`,
              }}
              colorBefore="var(--azul-5bits)"
              colorSpan="var(--azul-5bits)"
              title={textTooltip}
            >
              <InputCurrency
                id="unitPrice"
                name="unitPrice"
                type="text"
                labelText={index === 0 ? 'Valor UN' : ''}
                autoComplete="off"
                readOnly
                disabled={
                  disabled ||
                  (material.providerId &&
                    typy(parseInt(material.providerId, 10)).isNumber)
                }
              />
            </Tooltip>
          );
        }
      }

      return (
        <InputCurrency
          id="unitPrice"
          name="unitPrice"
          type="text"
          labelText={index === 0 ? 'Valor UN' : ''}
          autoComplete="off"
          readOnly
          disabled={
            disabled ||
            (material.providerId &&
              typy(parseInt(material.providerId, 10)).isNumber)
          }
        />
      );
    },
    [disabled, listMaterials],
  );

  return (
    <>
      {listMaterialPlanningPagination.length > 0 &&
        listMaterialPlanningPagination.map((item: any, index) => (
          <Scope path={`projectHasMaterials[${index}]`} key={String(index)}>
            <InputHidden id="id" name="id" />
            <InputHidden id="idNewItemPagination" name="idNewItemPagination" />
            <InputHidden id="providerId" name="providerId" />
            <SplitMaterialItem>
              <SimpleSelect
                id="materialId"
                name="materialId"
                labelText={index === 0 ? 'Nome' : ''}
                placeholder=""
                options={listMaterials}
                onChange={option => {
                  handleInputChange(option, index, item.providerId);
                }}
                isDisabled={
                  disabled ||
                  (item.providerId &&
                    typy(parseInt(item.providerId, 10)).isNumber)
                }
                disabled={
                  disabled ||
                  (item.providerId &&
                    typy(parseInt(item.providerId, 10)).isNumber)
                }
              />
              <QuantityPriceSubtotalMaterial>
                <FiledTwoInputs>
                  <InputCurrencyThreeDigits
                    id="quantity"
                    name="quantity"
                    type="text"
                    labelText={index === 0 ? 'Quantidade' : ''}
                    onKeyUp={e => {
                      if (!typy(parseInt(item.providerId, 10)).isNumber)
                        handleSumPrice(e, index);
                    }}
                    autoComplete="off"
                    readOnly
                    disabled={
                      disabled ||
                      typy(parseInt(item.providerId, 10)).isNumber ||
                      !typy(parseInt(item.materialId, 10)).isNumber
                    }
                  />
                  {/* Label apenas para manter o mesmo tamanho de quantity. visibility: hidden */}
                  <Input
                    id="unitOfMeasurement"
                    name="unitOfMeasurement"
                    labelText={index === 0 ? 'un' : ''}
                    type="text"
                    autoComplete="off"
                    disabled
                  />
                </FiledTwoInputs>

                <>{findLastQuoteProviderAndReturnInputUnitPrice(item, index)}</>

                <InputCurrency
                  id="totalPrice"
                  name="totalPrice"
                  type="text"
                  labelText={index === 0 ? 'Subtotal' : ''}
                  autoComplete="off"
                  disabled
                />
              </QuantityPriceSubtotalMaterial>

              <ObservationAndTrashIcon>
                <Input
                  id="observation"
                  name="observation"
                  type="text"
                  labelText={index === 0 ? 'Observação' : ''}
                  autoComplete="off"
                  disabled={
                    disabled ||
                    (item.providerId &&
                      typy(parseInt(item.providerId, 10)).isNumber)
                  }
                />
                {!disabled && (
                  <div>
                    <FiTrash2
                      size={20}
                      color="var(--cinza-claro-5bits)"
                      onClick={() => {
                        formRef.current.getFieldValue(
                          `projectHasMaterials[${index}].materialId`,
                        )
                          ? showModalConfirmationOfExclusionMaterial(
                              index,
                              true,
                            )
                          : removeMaterial(index);
                      }}
                    />
                  </div>
                )}
              </ObservationAndTrashIcon>
            </SplitMaterialItem>
          </Scope>
        ))}
    </>
  );
}

export default memo(ScopeMaterialPlanning);
