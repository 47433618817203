/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import api from '../../../services/api';
import { IUser, IUserResponse, IUsersHasMenus } from '../../../interfaces/user';
import getValidationErrors from '../../../utils/getValidationError';
import { useToast } from '../../../context/ToastContext';
import { useAuth } from '../../../context/AuthContext';

import Button from '../../../components/Button';
import Loading from '../../../components/Loading';
import PermissionsForm from './components/PermissionsForm';

import EditFormUser from './components/EditForm';

import { Container, Content, Split, SplitLeft, SplitRight } from './styles';

interface IUpdateUser {
  id?: string;
}

interface IUpdateUserFormData {
  name: string;
  phone: string;
  email: string;
  nickname: string;
  username: string;
  password?: string;
  isAdmin: boolean;
  isActive: boolean;
  usersHasMenus: IUsersHasMenus[];
}

const UpdateUser: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const navigate = useNavigate();

  const [saving, setSaving] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const { id } = useParams();
  const { user } = useAuth();
  const [userTarget, setUserTarget] = useState<Partial<IUser>>();
  const [
    userTargetWithUserHasMenusOrganized,
    setUserTargetWithUserHasMenusOrganized,
  ] = useState<Partial<IUser>>();

  useEffect(() => {
    const getUser = async (): Promise<void> => {
      try {
        setLoading(true);

        const response = await api.get<IUserResponse>(`users/${id}`);

        const userTargetObject = {
          id: response.data.id,
          name: response.data.name,
          phone: response.data.phone,
          email: response.data.email,
          nickname: response.data.nickname,
          username: response.data.username,
          isAdmin: response.data.isAdmin,
          isActive: response.data.isActive,
          usersHasMenus: response.data.usersHasMenus?.map(i => ({
            menuId: i.menuId,
            create: i.create,
            read: i.read,
            update: i.update,
            delete: i.delete,
          })),
        };

        user.isAdmin
          ? setUserTarget(userTargetObject)
          : setUserTargetWithUserHasMenusOrganized(userTargetObject);

        setLoading(false);
      } catch (error: any) {
        setLoading(false);
        // eslint-disable-next-line no-shadow
        if (error.response) {
          const { data } = error.response; // Error vindo do back está em data dentro de response
          addToast({
            type: 'error',
            title: data.message,
          });
          return;
        }

        addToast({
          type: 'error',
          title: 'Ocorreu um erro interno',
          description: `${error}`,
        });
      }
    };
    getUser();
  }, [addToast, id, user.isAdmin]);

  const handlePermissionUsers = useCallback(
    usersHasMenusFromScope => {
      if (
        usersHasMenusFromScope &&
        usersHasMenusFromScope.length > 0 &&
        userTarget
      ) {
        const userHasMenusOrganizedWithUsersHasMenusOfTheCurrentForm = usersHasMenusFromScope.map(
          (item: any) => {
            let listUsersHasMenusFind: any = [];
            if (userTarget.usersHasMenus) {
              listUsersHasMenusFind = userTarget.usersHasMenus.find(
                (element: any) => {
                  if (
                    parseInt(item.menuId, 10) === element.menuId ||
                    parseInt(item.menuMainId, 10) === element.menuId
                  ) {
                    return element;
                  }
                  return false;
                },
              );
            }
            return listUsersHasMenusFind;
          },
        );

        setUserTargetWithUserHasMenusOrganized({
          ...userTarget,
          usersHasMenus: userHasMenusOrganizedWithUsersHasMenusOfTheCurrentForm,
        });
      }
    },
    [userTarget],
  );

  const handleMainMenusThatHasSubMenus = useCallback(
    (listUsersHasMenus: any) => {
      let mainMenusThatHasSubMenusCorrect = [];

      // Filtra apenas os menus que tenham mainIdSubMenu e que tenham pelo menus uma permissão true
      const subMenusWithTrue = listUsersHasMenus.filter((item: any) => {
        if (item.mainIdSubMenu && Object.values(item).some(i => i === true)) {
          return item;
        }
        return null;
      });

      // Filtra apenas os menus que tenham menuMainId que são o menus com submenus
      const mainMenusThatHasSubMenus = listUsersHasMenus.filter((item: any) => {
        if (item.menuMainId) {
          return item;
        }
        return null;
      });

      //  Seta read true para os menus que tenham submenus com pelo menos uma permissão true
      if (mainMenusThatHasSubMenus.length > 0) {
        mainMenusThatHasSubMenusCorrect = mainMenusThatHasSubMenus.map(
          (item: any) => {
            if (
              subMenusWithTrue.some(
                (i: any) => i.mainIdSubMenu === item.menuMainId,
              )
            ) {
              return { menuId: item.menuMainId, read: true };
            }
            return { menuId: item.menuMainId, read: false };
          },
        );
      }

      return mainMenusThatHasSubMenusCorrect;
    },
    [],
  );

  const handleSubmit = useCallback(
    async (dataForm: IUpdateUserFormData) => {
      try {
        setSaving(true);

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('O Nome Completo é obrigatório'),
          nickname: Yup.string().required('O Codnome é obrigatório'),
          password:
            dataForm.password !== undefined
              ? Yup.string().required('A senha é obrigatória')
              : Yup.string(),
        });

        await schema.validate(dataForm, { abortEarly: false });

        let newDataForm: any;

        if (user.isAdmin) {
          const newMainMenusThatHasSubMenus = handleMainMenusThatHasSubMenus(
            dataForm.usersHasMenus,
          );

          const newUsersHasMenusWithoutMainMenu = dataForm.usersHasMenus.filter(
            (item: any) => !item.menuMainId,
          );

          const newUsersHasMenusWithSubMenuCorrect = newUsersHasMenusWithoutMainMenu.map(
            (item: any) => {
              if (item.mainIdSubMenu) {
                // eslint-disable-next-line no-param-reassign
                delete item.mainIdSubMenu;
              }
              return item;
            },
          );

          const usersHasMenusFinal = [
            ...newUsersHasMenusWithSubMenuCorrect,
            ...newMainMenusThatHasSubMenus,
          ];

          newDataForm = {
            id,
            name: dataForm.name,
            phone: dataForm.phone,
            email: dataForm.email,
            nickname: dataForm.nickname,
            isAdmin: dataForm.isAdmin,
            isActive: dataForm.isActive,
            usersHasMenus: usersHasMenusFinal,
            ...(dataForm.password ? { password: dataForm.password } : {}),
          };
        } else {
          newDataForm = {
            id,
            name: dataForm.name,
            phone: dataForm.phone,
            email: dataForm.email,
            nickname: dataForm.nickname,
            isAdmin: dataForm.isAdmin,
            isActive: dataForm.isActive,
            ...(dataForm.password ? { password: dataForm.password } : {}),
          };
        }

        await api.put('users', newDataForm);

        navigate('/users');
      } catch (error: any) {
        setSaving(false);

        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);

          return;
        }

        if (error.response) {
          const { data } = error.response; // Error vindo do back está em data dentro de response
          addToast({
            type: 'error',
            title: 'Error',
            description: data.message,
          });
          return;
        }

        addToast({
          type: 'error',
          title: 'Error na atualização',
        });
      }
    },
    [addToast, handleMainMenusThatHasSubMenus, navigate, id, user.isAdmin],
  );

  return (
    <Container>
      {loading ? (
        <p>carregando ...</p>
      ) : (
        <Content>
          <Form
            ref={formRef}
            onSubmit={handleSubmit}
            initialData={userTargetWithUserHasMenusOrganized}
          >
            <Split>
              <SplitLeft notShowBorder={!user.isAdmin}>
                <EditFormUser saving={saving} />

                <Button type="submit" disabled={saving}>
                  {saving ? <Loading size={24} color="white" /> : 'Salvar'}
                </Button>
              </SplitLeft>

              {user.isAdmin ? (
                <SplitRight>
                  <PermissionsForm
                    formRefProp={formRef}
                    handlePermissionUsersProp={handlePermissionUsers}
                  />
                </SplitRight>
              ) : null}
            </Split>
          </Form>
        </Content>
      )}
    </Container>
  );
};

export default UpdateUser;
