import styled from 'styled-components';

export const Container = styled.div``;

export const Content = styled.div`
  margin-top: 2.7rem;

  nav {
    position: relative;

    ul {
      display: flex;
      align-items: center;
      justify-content: center;

      list-style-type: none;

      li {
        margin: 0 0.75rem 0 0;
      }
    }
  }
`;

export const LiButtonControlsArrow = styled.li`
  button {
    height: 1.95rem;
    width: 1.95rem;
    border-radius: 50%;
    padding: 0%;
    font-size: 1.4rem;
    line-height: 1.9rem;
    font-weight: bold;
    text-align: center;
    background: var(--cinza-claro-5bits);
    transition: background-color 0.2s;
  }
`;
export const ButtonNormal = styled.div`
  button {
    height: 2.6rem;
    width: 2.6rem;
    border-radius: 50%;
    padding: 0%;
    font-size: 1.4rem;
    line-height: 1.9rem;
    font-weight: bold;
    background: var(--cinza-claro-5bits);
    transition: background-color 0.2s;
  }
`;
export const ButtonHighlighted = styled.div`
  button {
    height: 3.9rem;
    width: 3.9rem;
    border-radius: 50%;
    padding: 0%;
    font-size: 1.8rem;
    font-weight: bold;
    line-height: 2.4rem;
    background: var(--azul-5bits);
    transition: background-color 0.2s;
  }
`;
