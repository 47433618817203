import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const Content = styled.div`
  margin: 19px 46px 0;

  header {
    width: fit-content;
    font-weight: 500;
    font-size: 2.4rem;

    margin-left: 16px;
    margin-bottom: 27px;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;

    > button:last-child {
      width: 233px;
      margin: 25px 0 10px 0;
    }
  }

  //Medias
  @media (min-width: 0px) and (max-width: 759px) {
    margin: 12px 12px;

    form {
      > button:last-child {
        margin: 0 0 10px 0;
      }
    }
  }

  @media (min-width: 760px) and (max-width: 999px) {
  }
`;

export const Split = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;

  width: 100%;

  div {
    label {
      margin-left: 16px;
    }
  }

  //Medias
  @media (min-width: 0px) and (max-width: 759px) {
    flex-wrap: wrap;
    margin-bottom: 30px;
  }

  @media (min-width: 760px) and (max-width: 999px) {
  }
`;

export const SplitLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 50%;
  margin-right: 30px;

  > div {
    margin-bottom: 16px;
  }

  //Medias
  @media (min-width: 0px) and (max-width: 759px) {
    width: 100%;
    margin: 0;
  }

  @media (min-width: 760px) and (max-width: 999px) {
  }
`;

export const VerticalLine = styled.div`
  height: 31.9rem;
  border-right: 1px solid var(--cinza-claro-5bits);
  margin-top: 19px;

  //Medias
  @media (min-width: 0px) and (max-width: 759px) {
    display: none;
  }

  @media (min-width: 760px) and (max-width: 999px) {
  }
`;

export const SplitRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 50%;

  margin-left: 30px;

  > div {
    margin-bottom: 16px;
  }

  /* Div checkbox isActive */
  > div:last-child {
    margin-top: 1rem;
  }

  //Medias
  @media (min-width: 0px) and (max-width: 759px) {
    width: 100%;
    margin: 0;
  }

  @media (min-width: 760px) and (max-width: 999px) {
  }
`;

export const CityAndUF = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  > div:last-child {
    width: 30%;
    margin-left: 15px;

    input {
      width: 100%;
    }
  }

  //Medias
  @media (min-width: 0px) and (max-width: 759px) {
    /* Cidade */
    > div:first-child {
      width: 100%;
    }

    /* UF */
    > div:last-child {
      width: 100%;
      margin-left: 0;
      margin-top: 1.6rem;

      input {
        width: 100%;
      }
    }
  }

  @media (min-width: 760px) and (max-width: 999px) {
  }
`;
