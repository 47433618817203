/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import React, { useRef, useState, useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import api from '../../../services/api';

import getValidationErrors from '../../../utils/getValidationError';
import convertNumberFloatToDB from '../../../utils/convertNumberFloatToDB';
import { useToast } from '../../../context/ToastContext';

import Loading from '../../../components/Loading';
import Input from '../../../components/Input';
import InputCurrency from '../../../components/InputCurrency';
import InputCurrencyThreeDigits from '../../../components/InputCurrencyThreeDigits';
import Button from '../../../components/Button';
import SimpleSelect from '../../../components/SimpleSelect';
import SelectAnimated from '../../../components/SelectAnimated';

import { Container, Content, Split, Providers, WeightAndPrice } from './styles';

import { IProvider, IProviderResponse } from '../../../interfaces/provider';
import { IMaterial, IMaterialForm } from '../../../interfaces/material';
import InputHidden from '../../../components/InputHidden';
import convertNumberFloatToBr from '../../../utils/convertNumberFloatToBr';
import CheckboxInput from '../../../components/CheckboxInput';

import { IUnitMeasurement } from '../../../interfaces/unitMeasurement';

interface IMaterialDTO extends Omit<IMaterial, 'price'> {
  price: string;
}

const UpdateMaterial: React.FC = () => {
  const { id }: any = useParams();
  const formRef = useRef<FormHandles>(null);
  const navigate = useNavigate();
  const { addToast } = useToast();

  const [saving, setSaving] = useState<boolean>(false);
  const [material, setMaterial] = useState<IMaterialDTO>({} as IMaterialDTO);

  const [listProviders, setListProviders] = useState<IProvider[]>([]);
  const [loadingProviders, setLoadingProviders] = useState<boolean>(false);

  const [unitMeasurementList, setUnitMeasurementList] = useState<
    Array<IUnitMeasurement>
  >([]);

  const [
    loadingUnitMeasurementList,
    setLoadingUnitMeasurementList,
  ] = useState<boolean>(false);

  useEffect(() => {
    const getMaterials = async (): Promise<void> => {
      try {
        setLoadingProviders(true);

        const responseMaterials: any = await api.get(`materials/${id}`);

        // Formata o array de providers vindo do banco para selecionar os item no select
        // de providers.
        let providersIdFormatted: Array<IProvider> = [];
        if (
          responseMaterials.data.providers &&
          responseMaterials.data.providers.length > 0
        ) {
          providersIdFormatted = responseMaterials.data.providers.map(
            (i: IProvider) => ({
              value: i.id,
              label: i.fantasyName,
            }),
          );
        }
        // Fim providersIdFormatted

        const newResponseMaterialsFormatted: IMaterialDTO = {
          ...responseMaterials.data,
          price: convertNumberFloatToBr(responseMaterials.data.price),
          providersId: providersIdFormatted,
          unitId:
            responseMaterials.data.unit !== null
              ? {
                  ...responseMaterials.data.unit,
                  value: responseMaterials.data.unit.id,
                  label: responseMaterials.data.unit.name,
                }
              : responseMaterials.data.unit,
        };

        setMaterial(newResponseMaterialsFormatted);

        formRef.current?.setData(newResponseMaterialsFormatted);

        // Busca a lista de fornecedores
        const responseProviders: any = await api.get('providers/active');

        const responseProvidersFormatted = responseProviders.data.map(
          (i: IProviderResponse) => ({
            value: i.id,
            label: i.fantasyName,
          }),
        );

        setListProviders(responseProvidersFormatted);

        setLoadingProviders(false);
      } catch (error: any) {
        setLoadingProviders(false);

        // eslint-disable-next-line no-shadow
        if (error.response) {
          const { data } = error.response; // Error vindo do back está em data dentro de response
          addToast({
            type: 'error',
            title: 'Ocorreu um erro!',
            description: `Erro: ${data.message}`,
          });
          return;
        }

        addToast({
          type: 'error',
          title: 'Ocorreu um erro interno!',
          description: `Error: ${error}`,
        });
      }
    };
    getMaterials();
  }, [addToast, id]);

  // List Unit Measurement
  useEffect(() => {
    const getUnitMeasurementList = async (): Promise<void> => {
      try {
        setLoadingUnitMeasurementList(true);

        const response = await api.get<IUnitMeasurement[]>('units/active');

        const responseFormatted: any = response.data.map(i => ({
          ...i,
          value: i.id,
          label: i.name,
        }));

        setUnitMeasurementList(responseFormatted);

        setLoadingUnitMeasurementList(false);
      } catch (error: any) {
        setLoadingUnitMeasurementList(false);

        if (error.response && error.response.data) {
          const { data } = error.response; // Error vindo do back está em data dentro de response
          addToast({
            type: 'error',
            title: data.message,
          });
          return;
        }

        addToast({
          type: 'error',
          title: 'Error',
          description: 'Erro ao listar!',
        });
      }
    };
    getUnitMeasurementList();
  }, [addToast]);
  // End List Unit Measurement

  const handleFormSubmit = useCallback(
    async (dataForm: IMaterialForm, { reset }) => {
      try {
        setSaving(true);

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          id: Yup.string().required('O Id do Material é obrigatório'),
          name: Yup.string().required('O Nome do Material é obrigatório'),
          unitId: Yup.string().required('A unidade é obrigatória'),
        });

        await schema.validate(dataForm, { abortEarly: false });

        const newData = {
          ...dataForm,
          id: parseInt(id, 10),
          price: convertNumberFloatToDB(dataForm.price),
          weight: convertNumberFloatToDB(dataForm.weight),
        };

        await api.put('materials', newData);

        addToast({
          type: 'success',
          title: 'Atualização realizado com sucesso',
        });

        reset();
        setSaving(false);

        navigate('/materials');
      } catch (error: any) {
        setSaving(false);

        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);

          return;
        }

        if (error.response) {
          const { data } = error.response; // Error vindo do back está em data dentro de response

          addToast({
            type: 'error',
            title: 'Erro na atualização',
            description: data.message,
          });
          if (
            formRef.current?.getFieldRef(data.field) &&
            data.field.toString().indexOf('_id') === -1
          ) {
            formRef.current?.getFieldRef(data.field).focus(); // Foca o campo que deu erro de acordo com o field retornado do back
          }

          return;
        }

        addToast({
          type: 'error',
          title: 'Error',
          description: 'Erro ao atualizar!',
        });
      }
    },
    [addToast, navigate, id],
  );

  return (
    <Container>
      <Content>
        <header>
          <p>Atualização de Material</p>
        </header>
        <Form ref={formRef} initialData={material} onSubmit={handleFormSubmit}>
          <InputHidden type="hidden" name="id" />
          <Split>
            <Input
              id="name"
              name="name"
              type="text"
              labelText="Nome*"
              maxLength={100}
              disabled={saving}
            />

            {loadingUnitMeasurementList ? (
              <p style={{ height: '4.5rem', textAlign: 'center' }}>
                carregando ...
              </p>
            ) : (
              <SimpleSelect
                id="unitId"
                name="unitId"
                labelText="Unidade*"
                placeholder=""
                options={unitMeasurementList}
                disabled={saving}
              />
            )}

            <WeightAndPrice>
              <InputCurrencyThreeDigits
                id="weight"
                name="weight"
                type="text"
                labelText="Peso Kg"
                disabled={saving}
              />
              <InputCurrency
                id="price"
                name="price"
                type="text"
                labelText="Preço"
                disabled={saving}
              />
            </WeightAndPrice>

            <Input
              id="observation"
              name="observation"
              type="text"
              labelText="Observação"
              maxLength={100}
              disabled={saving}
            />
            <CheckboxInput
              id="isActive"
              name="isActive"
              label="Ativo?"
              position="left"
              disabled={saving}
            />
          </Split>

          {loadingProviders ? (
            <p>carregando ...</p>
          ) : (
            <Providers>
              <span>Fornecedores desse Material</span>
              <SelectAnimated
                id="providersId"
                name="providersId"
                placeholder=""
                options={listProviders}
                isMulti
                disabled={saving}
              />
            </Providers>
          )}

          <Button type="submit" style={{ width: '233px' }} disabled={saving}>
            {saving ? <Loading size={24} color="white" /> : 'Salvar'}
          </Button>
        </Form>
      </Content>
    </Container>
  );
};

export default UpdateMaterial;
