/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-plusplus */
import React, { useEffect, useState } from 'react';
import { TiInfoOutline } from 'react-icons/ti';
import { FormHandles, Scope } from '@unform/core';

import api from '../../../../../services/api';
import { IMenu, IMenuResponse } from '../../../../../interfaces/menu';

import CheckboxInput from '../../../../../components/CheckboxInput';
import InputHidden from '../../../../../components/InputHidden';

import { ButtonsGroup, Container, TableBox, CheckboxHidden } from './styles';
import { useToast } from '../../../../../context/ToastContext';

interface PermissionProp {
  formRefProp: FormHandles | any;
  handlePermissionUsersProp: (newList: Array<any>) => void;
}

const PermissionsForm: React.FC<PermissionProp> = ({
  formRefProp,
  handlePermissionUsersProp,
}) => {
  const { addToast } = useToast();
  const [loading, setLoading] = useState<boolean>(false);
  const [menus, setMenus] = useState<IMenu[]>([]);
  const [selectedMenu, setSelectedMenu] = useState<IMenu>();

  useEffect(() => {
    let isMounted = true;
    setLoading(true);

    api
      .get<IMenuResponse[]>('menus')
      .then(response => {
        if (isMounted) {
          setMenus(
            response.data.map(i => ({
              id: i.id,
              groupMenu: i.group_menu,
              label: i.label,
              route: i.route,
              action: i.action,
              isSubmenu: i.is_submenu,
              submenus: i.submenus?.map(s => ({
                id: s.id,
                groupMenu: s.group_menu,
                label: s.label,
                route: s.route,
                action: s.action,
                isSubmenu: s.is_submenu,
              })),
            })),
          );
          setLoading(false);
        }
      })
      .catch(error => {
        setLoading(false);

        if (error.response) {
          const { data } = error.response; // Error vindo do back está em data dentro de response
          addToast({
            type: 'error',
            title: data.message,
          });
          return;
        }

        addToast({
          type: 'error',
          title: 'Erro ao listar!',
          description: `Error: ${error}`,
        });
      });

    return (): void => {
      isMounted = false;
    };
  }, [addToast]);

  useEffect(() => {
    if (menus && menus.length > 0) {
      setSelectedMenu(menus[0]);
    }
  }, [menus]);

  useEffect(() => {
    if (formRefProp) {
      const usersHasMenusFromScope = formRefProp.current.getData()
        .usersHasMenus;
      if (
        usersHasMenusFromScope !== undefined &&
        usersHasMenusFromScope.length > 0
      ) {
        handlePermissionUsersProp(usersHasMenusFromScope);
      }
    }
  }, [formRefProp, handlePermissionUsersProp, loading]);

  const handleSelectMenu = (id: number): void => {
    const menu = menus.find(i => i.id === id);
    setSelectedMenu(menu);
  };

  /**
   * Não pode usar o index do map pois quando houver submenu o index não corresponderá
   */
  let indexControlled = 0;

  return (
    <Container>
      <h3>Permissões</h3>

      <p>
        <span>
          <TiInfoOutline size={16} color="var(--laranja-5bits)" />
        </span>
        Cada usuário registrado no sistema pode ter permissões diferentes. Aqui
        é onde você controla o que este usuário poderá visualizar, incluir,
        alterar ou excluir.&nbsp;
        <strong>Sugerimos cautela nessa etapa!</strong>
      </p>

      {loading ? (
        <p>carregando ...</p>
      ) : (
        <>
          <ButtonsGroup>
            {menus.map(m => (
              <button
                key={String(`btn-${m.id}`)}
                type="button"
                className={selectedMenu?.id === m.id ? 'active' : ''}
                onClick={() => handleSelectMenu(m.id)}
              >
                {m.label}
              </button>
            ))}
          </ButtonsGroup>

          <TableBox>
            {selectedMenu &&
              menus.map(m => (
                <div
                  key={String(`div-header-${m.id}`)}
                  className={selectedMenu.id === m.id ? '' : 'hidden'}
                >
                  <div>
                    <p>{m.label}</p>
                  </div>
                  <table>
                    <tbody>
                      {m?.submenus.length > 0 ? (
                        <>
                          <Scope path={`usersHasMenus[${indexControlled++}]`}>
                            <tr style={{ display: 'none' }}>
                              <td>
                                <InputHidden
                                  name="menuMainId"
                                  id={`menu-${m.id}`}
                                  value={m.id}
                                  readOnly
                                />
                                <CheckboxHidden>
                                  <CheckboxInput
                                    name="read"
                                    id={`menu-${m.id}-read`}
                                    position="right"
                                  />
                                </CheckboxHidden>
                              </td>
                            </tr>
                          </Scope>
                          {m.submenus.map(s => (
                            <Scope
                              key={`menu-${m.id}-submenu-${s.id}`}
                              path={`usersHasMenus[${indexControlled++}]`}
                            >
                              <tr>
                                <td>
                                  {m.label === s.label ? 'Gerenciar' : s.label}
                                  <InputHidden
                                    name="mainIdSubMenu"
                                    id={`menu-${m.id}-Sub-${s.id}`}
                                    value={m.id}
                                    readOnly
                                  />
                                  <InputHidden
                                    name="menuId"
                                    id={`menu-${m.id}-submenu-${s.id}`}
                                    value={s.id}
                                    readOnly
                                  />
                                </td>
                                <td>
                                  <CheckboxInput
                                    name="read"
                                    id={`menu-${m.id}-submenu-${s.id}-read`}
                                    position="right"
                                    label="Visualizar:"
                                  />
                                </td>
                                <td>
                                  <CheckboxInput
                                    name="create"
                                    id={`menu-${m.id}-submenu-${s.id}-create`}
                                    position="right"
                                    label="Incluir:"
                                  />
                                </td>
                                <td>
                                  <CheckboxInput
                                    name="update"
                                    id={`menu-${m.id}-submenu-${s.id}-update`}
                                    position="right"
                                    label="Alterar:"
                                  />
                                </td>
                                <td>
                                  <CheckboxInput
                                    name="delete"
                                    id={`menu-${m.id}-submenu-${s.id}-delete`}
                                    position="right"
                                    label="Excluir:"
                                  />
                                </td>
                              </tr>
                            </Scope>
                          ))}
                        </>
                      ) : (
                        <Scope path={`usersHasMenus[${indexControlled++}]`}>
                          <tr>
                            <td>
                              Gerenciar
                              <InputHidden
                                name="menuId"
                                id={`menu-${m.id}`}
                                value={m.id}
                                readOnly
                              />
                            </td>
                            <td>
                              <CheckboxInput
                                name="read"
                                id={`menu-${m.id}-read`}
                                position="right"
                                label="Visualizar:"
                              />
                            </td>
                            <td>
                              <CheckboxInput
                                name="create"
                                id={`menu-${m.id}-create`}
                                position="right"
                                label="Incluir:"
                              />
                            </td>
                            <td>
                              <CheckboxInput
                                name="update"
                                id={`menu-${m.id}-update`}
                                position="right"
                                label="Alterar:"
                              />
                            </td>
                            <td>
                              <CheckboxInput
                                name="delete"
                                id={`menu-${m.id}-delete`}
                                position="right"
                                label="Excluir:"
                              />
                            </td>
                          </tr>
                        </Scope>
                      )}
                    </tbody>
                  </table>
                </div>
              ))}
          </TableBox>
        </>
      )}
    </Container>
  );
};

export default PermissionsForm;
