import React, { useState, useEffect } from 'react';
import convertDateToBr from '../../../../utils/convertDateToBr';

import {
  Split,
  TitleProject,
  OtherInformation,
  DescriptionProject,
} from './styles';

import { IProject } from '../../../../interfaces/project';

interface HeaderProjectProp {
  projectProp: IProject;
  readOnlyProp?: boolean;
}

const HeaderProjectOnlyText: React.FC<HeaderProjectProp> = ({
  projectProp = {} as IProject,
}) => {
  const [project, setProject] = useState<IProject>({} as IProject);

  useEffect(() => {
    if (Object.entries(projectProp).length > 0) setProject(projectProp);
  }, [project, projectProp]);

  return (
    <Split>
      {Object.entries(project).length > 0 && (
        <>
          <TitleProject>
            <span>
              <strong>Título do Projeto:</strong> {project.title}
            </span>
          </TitleProject>
          <OtherInformation>
            <span>
              <strong>Cliente:</strong> {project.client.name}
            </span>
            <span>
              <strong>Responsável pelo projeto:</strong>{' '}
              {project.nameResponsible}
            </span>
            <span>
              <strong>Qtd. de peças:</strong> {project.quantity}
            </span>
            <span>
              <strong>Previsão de entrega:</strong>{' '}
              {convertDateToBr(project.forecastEndDate)}
            </span>
          </OtherInformation>
          <DescriptionProject>
            <span>
              <strong>Descrição do projeto:</strong>
            </span>
            <span>{project.description}</span>
          </DescriptionProject>
        </>
      )}
    </Split>
  );
};

export default HeaderProjectOnlyText;
